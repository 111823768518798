import { axios, SideNav, useEffect, useState, React, useNavigate, decryptData, Header, TrainingCalender, TaskStatus, SkillAlerts, Status, handleApiError } from "./../MentorImport";
import "./MentorDashboard.css";

const MentorDashboard = () => {
  const [StatusData, setStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  const navigate = useNavigate();

  function FailBTN() {
    document.getElementById("PassBTN").classList.remove("btnactive");
    document.getElementById("FailBTN").classList.add("btnactive");
    const element = document.querySelector(".notification-pass");
    if (element) {
      element.style.display = "none";
      const element2 = document.querySelector(".notification-fail");
      if (element2) {
        element2.style.display = "flex";
      }
    }
  }
  function PassBTN() {
    document.getElementById("FailBTN").classList.remove("btnactive");
    document.getElementById("PassBTN").classList.add("btnactive");
    const element = document.querySelector(".notification-fail");
    if (element) {
      element.style.display = "none";
      const element2 = document.querySelector(".notification-pass");
      if (element2) {
        element2.style.display = "flex";
      }
    }
  }

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/userCount`;
    const body = {
      batchId: selectedBatches,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.post(apiUrl, body, { headers });
      if (response.status === 200) {
        setStatusData(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedBatches]);

  return (
    <>
      <div className="" style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
        <SideNav />
      </div>
      <div className="responsivenessM ">
        <>
          <div className="row">
            <div className="col-md-4 col-xs-12 p-0 BatchesData-TrainingCalender">
              <div className="row ">
                <div className="col-md-6 col-xs-12 outer-row-info">
                  <Status data={StatusData} selectedBatches={selectedBatches} isLoading={isLoading} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-12 outer-row-info">
                  <TrainingCalender selectedBatches={selectedBatches} />
                </div>
                <div class="col-xs-12 for-mobile-view" id="Skills-Mobile-Vieww" style={{ marginBottom: "1.5rem" }}>
                  <div class="row">
                    <div class="col-12 p-0">
                      <p className="about-link pointer p-0 link-mentor-dash">Skill Alerts</p>
                    </div>
                    <div class="row BTN-SKILL">
                      <button class="col-6 PassFailBTN btnactive" id="PassBTN" onClick={PassBTN}>
                        Pass
                      </button>
                      <button class="col-6 PassFailBTN" id="FailBTN" onClick={FailBTN}>
                        Fail
                      </button>
                    </div>
                  </div>
                  <SkillAlerts selectedBatches={selectedBatches} />
                </div>
              </div>
            </div>

            <div className="col-md-7 col-xs-12 p-0" id="TaskStatus" style={{ marginLeft: "-1rem" }}>
              <div className="row task-status-cover">
                <TaskStatus selectedBatches={selectedBatches} />
              </div>
              <div className="row mt-3 for-desktop-view" id="SkillAlerts">
                <SkillAlerts selectedBatches={selectedBatches} />
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default MentorDashboard;
