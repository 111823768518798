import axios from "axios";
import React, { useEffect, useState, useContext, useReducer } from "react";
import { useNavigate, Link } from "react-router-dom";
import EmptyProjectView from "../../EmptyStates/EmptyProject/ProjectViewAll";
import { ReactComponent as ExpandMore } from "../../../../../Assets/expand_more.svg";
import { UserContext } from "../../../../../Context/Context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TechDropdown from "../../../../AdminPortal/Task/AssignTask/TechDropdown";
import { decryptData } from "../../../UserImport";
import TechActionType from "../../../../_common/TechActionType";
import { SuccessfullVector, UserActionType, UsersDropdown } from "../../../../AdminPortal/AdminImport";
import CustomModal from "../../../../Utilities/CommonModal/CustomModal";

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
  usersValue: "",
  selectedUserIds: [],
  selectedUserName: [],
  selectAllChecked: false,
};

const AddProject = (props) => {
  const { project } = useContext(UserContext);
  const navigate = useNavigate();
  const [nameError, setNameError] = useState(true);
  const [descError, setDescError] = useState(true);
  const [projLinkError, setProjLinkError] = useState(true);
  const [first, ...rest] = project ?? [];
  const [projName, setProjName] = useState("");
  const [projDescription, setProjDescription] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [hostedLink, setHostedLink] = useState("");
  const [membersError, setMembersError] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [memberNames, setMemberNames] = useState([]);
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  let loading = props.loading;
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [isProjectDescriptionValid, setIsProjectDescriptionValid] = useState(false);
  const [isProjectLinkValid, setIsProjectLinkValid] = useState(false);
  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [isHostedLinkValid, setIsHostedLinkValid] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const handleClose = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const handleProjectNameChange = (e) => {
    e.preventDefault();
    const name = e.target.value;
    setProjName(name);
    setIsProjectNameValid(name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/) ? true : false);
    if (!name || !name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const handleProjectDescriptionChange = (e) => {
    e.preventDefault();
    const description = e.target.value;
    setProjDescription(description);
    setIsProjectDescriptionValid(description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/) ? true : false);
    if (!description || !description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/)) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };

  const handleProjectLinkChange = (e) => {
    const link = e.target.value;
    setProjectLink(link);
    setIsProjectLinkValid(link.match(/^https?:\/\//) ? true : false);
    if (!link) {
      setProjLinkError(true);
    } else {
      setProjLinkError(false);
    }
  };

  const handleHostedLinkChange = (e) => {
    const link = e.target.value;
    setHostedLink(link);
    setIsHostedLinkValid(link.match(/^https?:\/\//) ? true : false);
  };

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setTextInput(inputText);
    const memberNamesArray = inputText.split(",").map((name) => name.trim());
    setMembersError(memberNamesArray?.length > 10);
    const membersObj = {};
    memberNamesArray.forEach((name, index) => {
      membersObj[`member${index + 1}`] = name;
    });
    isObjectEmpty(membersObj);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    navigate("/all-projects");
  };
  const clear = () => {
    setTextInput("");
    setProjName("");
    setProjDescription("");
    setProjectLink("");
    setHostedLink("");
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
    dispatch({ type: "RESET_STATE" });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const isObjectEmpty = (object) => {
    const memberNamesArray = Object.values(object).filter((value) => value.trim() !== "");
    setMemberNames(memberNamesArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const url = process.env.REACT_APP_API_URL_DOTNET + "/Project/create";
    const body = {
      name: projName,
      description: projDescription,
      projectLink,
      hostedLink,
      techNames: state.selectedTechIds,
      memberNames: state.selectedUserIds,
      members: [],
      technologyUsed: []
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 200) {
        setIsOpen(() => ({
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Project Added Successfully",
          modalMessage: "Project has been Added Successfully",
          icon: <SuccessfullVector />,
        }));
        props.setTaskVersion((prevVersion) => prevVersion + 1);
        clear();
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
    }
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };

  useEffect(() => {
    const texts = textInput.split(",").map((text) => text.trim());
    const membersObj = {};
    texts.forEach((text, index) => {
      membersObj[`member${index + 1}`] = text;
    });

    isObjectEmpty(membersObj);
  }, [textInput]);

  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = state.selectedUserName.join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  return (
    <>
      <div className="card-body pb-0">
        <div className="project-card-text-row">
          <p className="project-card-text">A star will be rewarded to you as a token of appreciation for your hardwork and dedication upon the successful completion of the project.</p>
        </div>
        <div className="project-idea">
          <div className="d-flex justify-content-between">
            <div className="d-flex pt-2">
              <p className="text mb-0 ms-1 fw-bold">Project</p>
            </div>
            <button
              type="button"
              onClick={(e) => {
                handleClick(e);
              }}
              className="view-all fw-bold"
            >
              View All
            </button>
          </div>
        </div>
        {loading ? (
          <div className="project-recipe-row pb-3">
            <div className="recipe-text project-recipe-name">
              <h5 className="fw-bold">
                <Skeleton width={252} />
              </h5>
              <div className="project-link-1">
                <p className="project-link-name">
                  <Skeleton width={190} height={10} highlightColor="#D3E1FA" borderRadius={10} />
                </p>
              </div>

              <div className="technology-used fw-bold pt-0">
                <Skeleton width={112} height={16} />
              </div>
              <div className="technology-badges">
                <div className="pe-2">
                  <Skeleton width={57} height={24} highlightColor="#D3E1FA" />
                </div>
                <div className="pe-2">
                  <Skeleton width={47} height={24} highlightColor="#D3E1FA" />
                </div>
                <div>
                  <Skeleton width={84} height={24} highlightColor="#D3E1FA" />
                </div>
              </div>
            </div>
          </div>
        ) : project && project?.length == 0 ? (
          <EmptyProjectView />
        ) : (
          <div className="project-recipe-row pb-3">
            <div className="recipe-text project-recipe-name">
              <h5 className="fw-bold">{first?.name}</h5>
              <div className="project-link-1">
                <p className="project-link-name">
                  <Link to={first?.projectLink} target="_blank">
                    {first?.projectLink?.length > 80 ? `${first?.projectLink.slice(0, 80)}...` : first?.projectLink}
                  </Link>
                </p>
              </div>

              <div className="technology-used fw-bold pt-0">Technology Used:</div>
              <div className="technology-badges">
                {first?.technologyUsed?.map((currElem, index) => {
                  if (currElem.techName != null && index < 2) {
                    return <div className="technology-badge-1">{currElem.techName}</div>;
                  }
                })}
                {first?.technologyUsed && first?.technologyUsed?.filter((item) => item.techName !== null).length > 2 && (
                  <div className="technology-badge-1" title={first?.technologyUsed.slice(2).map((tech) => {
                    return tech.techName
                  }).join(", ")}>+{first?.technologyUsed?.filter((item) => item.techName !== null).length - 2}</div>
                )}
              </div>
              <div className="members-div pt-0">
                {(first?.members && first?.members?.length > 0) && <div className="member fw-bold mb-2 mt-2">Members:</div>}
                <div className="project-members ml-0">
                  {first?.members?.length > 4 ? (
                    <>
                      {first?.members.slice(0, 4).map((member, index) => {
                        const initials = member?.name
                          .split(" ")
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase();
                        return (
                          <div className="project-idea-members" key={index}>
                            <p className="name-of-members" title={member?.name}>
                              {initials}
                            </p>
                          </div>
                        );
                      })}
                      <div className="count-of-members">
                        <p className="name-of-members" title={first?.members.slice(4).map((member) => {
                          return member.name
                        }).join(", ")}>
                          + {first?.members?.length - 4}
                        </p>
                      </div>
                    </>
                  ) : (
                    first?.members?.map((member, index) => {
                      const initials = member?.name
                        .split(" ")
                        .map((word) => word[0])
                        .join("")
                        .toUpperCase();
                      return (
                        <div className="project-idea-members" key={index}>
                          <p className="name-of-members" title={member?.name}>
                            {initials}
                          </p>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="add-new-idea-container">
          <div
            className="add-new-idea"
            data-bs-toggle="modal"
            data-bs-target="#projectExampleModal"
            data-bs-whatever="@mdo"
          >
            <p className="project-p mb-0 fw-bold">
              <span>+</span> Add Project
            </p>
          </div>
        </div>
      </div>
      <div className="modal fade" id="projectExampleModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="projectExampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 add-project-wrapper" id="projectExampleModalLabel">
                Add Project
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clear}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="project-name" className="col-form-label title-text">
                    Project Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input type="text" className="form-control" id="project-name" value={projName} placeholder="Enter Project Name" onChange={handleProjectNameChange} />
                  {!isProjectNameValid && projName && <span style={{ color: "red", fontSize: "11px" }}>Please enter a name with only letters and spaces, between 1 and 100 characters.</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="project-description" className="col-form-label title-text">
                    Project Description
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea className="form-control" id="project-description" value={projDescription} placeholder="Write Here..." onChange={handleProjectDescriptionChange} rows={3} />
                  {!isProjectDescriptionValid && projDescription && (
                    <span style={{ color: "red", fontSize: "11px" }}>Please Enter description only with letters, colon, comma and hyphen, between 150 and 750 characters.</span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="technology-used" className="col-form-label title-text" required>
                    Technology Used<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownToggle("techDropdown");
                      }}
                    >
                      <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Technology" value={state.techValue} disabled />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.techDropdown && <ul className="ul-styling">
                        <TechDropdown
                          dispatch={dispatch}
                          techDataComingChild={handleTechChange}
                          selectedTechIdsfromChild={handleTechIdChange}
                          selectedTechIds={state.selectedTechIds}
                          technologyNames={state.selectedTechNames}
                          selectedTech={""}
                        />
                      </ul>}
                    </div>
                  </div>
                  {state.selectedTechNames && state.selectedTechNames?.length > 10 && <span style={{ color: "red", fontSize: "12px" }}>Maximum 10 technologies allowed</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="Project Link" className="col-form-label title-text">
                    Project Link<span style={{ color: "red" }}>*</span>{" "}
                  </label>
                  <input className="form-control" id="project-link" placeholder="Enter Project Link" value={projectLink} onChange={handleProjectLinkChange} />
                  {!isProjectLinkValid && projectLink && <span style={{ color: "red", fontSize: "11px" }}>Invalid project link. Please enter a valid URL starting with http:// or https://.</span>}
                </div>
                <div className="mb-3">
                  <label htmlFor="Hosted Link(Optional)" className="col-form-label title-text">
                    Hosted Link
                  </label>
                  <input
                    className="form-control"
                    id="hosted-link"
                    placeholder="Enter Hosted Link"
                    value={hostedLink}
                    onChange={handleHostedLinkChange}
                  />
                  {!isHostedLinkValid && hostedLink && <span style={{ color: "red", fontSize: "11px" }}>Invalid hosted link. Please enter a valid URL starting with http:// or https://.</span>}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="Members(Optional)"
                    className="col-form-label title-text"
                  >
                    Members
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownToggle("userDropdown");
                      }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Members"
                        value={state.usersValue}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.userDropdown && <ul
                        className="ul-styling"
                      >
                        <UsersDropdown
                          dispatch={dispatch}
                          usersDataComingChild={usersDataComingFrmChild}
                          selectedUserIdsfromChild={handleUserIdChange}
                          selectedUserIds={state.selectedUserIds}
                          selectedUserName={state.selectedUserName}
                          selectAllUsersCheck={state.selectAllChecked}
                          selectedUsers={""}
                        />
                      </ul>}
                    </div>
                  </div>
                  {membersError && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please enter upto 10 members only
                    </span>
                  )}
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={clear}>
                <span className="cancel-text"> Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-primary save-button"
                data-bs-target="#projectExampleModal"
                data-bs-dismiss={!nameError && !descError && !projLinkError && state.selectedTechNames?.length !== 0 ? "modal" : ""}
                disabled={
                  nameError || descError || membersError || projLinkError || !isProjectLinkValid || state.selectedTechNames?.length === 0 || state.selectedTechNames?.length > 10 || !isProjectNameValid || (hostedLink != "" && !isHostedLinkValid)
                }
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                <span className="save-text"> Save</span>
              </button>
            </div>
          </div>
        </div>
        {isOpen.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: isOpen.icon,
              show: isOpen.isModalOpen,
              type: isOpen.modalType,
              title: isOpen.modalTitle,
              message: isOpen.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
      </div>
    </>
  );
};

export default AddProject;
