import { useState, useNavigate, SuccessfullVector, CalendarIcon, axios, Select, decryptData, handleApiError, AlertInfo, Modal,DatePicker} from "../AdminImport";
import "../Task/MentorList/AddMentorModal.css";

function CustomInput({ value, onClick }) {
  return (
    <div className="row addReviewer-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 addReviewer-date-filter-input m-0" readOnly />
      <span className="col-1 calendar-icon">
        <CalendarIcon />
      </span>
    </div>
  );
}
const formatDateToString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    marginBottom: "0.7rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: "scroll",
    maxHeight: "12rem",
    zIndex: "99",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const AddReviewer = (props) => {
  const { show, onHide, userList, setModalData, userId ,learnerName} = props;
  const [mentor, setMentor] = useState({
    mentorId: null,
    userName: null,
    endDate: "",
  });
  const navigate = useNavigate();

  const handleClickClear = () => {
    onHide();
    setMentor((prevState) => ({
      ...prevState,
      mentorId: null,
      userName: null,
      endDate: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    let userIdArray = [];
    let mentorId = [];
    let learnerUserName = [];
    userIdArray.push(userId);
    mentorId.push(mentor.mentorId);
    learnerUserName.push(learnerName);
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/assignReviewer`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      learnerUserId: userIdArray,
      mentorUserId: mentorId,
      learnerUserName: learnerUserName,
      endDate: formatDateToString(mentor.endDate),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 200) {
        setModalData((prevData) => ({
          ...prevData,
          modalShow : false,
          isModalOpen: true,
          modalType: "success",
          modalTitle: `${response.data.message}`,
          modalMessage: "",
          icon: <SuccessfullVector />,
        }));
        setMentor((prevState) => ({
            ...prevState,
            mentorId: null,
            userName: null,
            endDate: "",
          }));
      } else {
        setModalData((prevData) => ({
          ...prevData,
          modalShow : false,
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Error !",
          modalMessage: `${response.data.message}`,
          icon: <AlertInfo />,
        }));
        setMentor((prevState) => ({
            ...prevState,
            mentorId: null,
            userName: null,
            endDate: "",
          }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleUserChange = (e) => {
    setMentor({
      ...mentor,
      mentorId: e.value,
      userName: e.label,
    });
  };
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton onClick={handleClickClear}>
          <Modal.Title className="modalheading-text ms-2">Assign Reviewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="align-items-center">
              <div className="modal-body">
                <form>
                  <div>
                    Assigned To<span style={{ color: "red" }}> *</span>
                  </div>
                  <Select
                    styles={customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                    options={userList}
                    placeholder="Select Reviewer"
                    onChange={handleUserChange}
                    value={
                      mentor.mentorId != null && {
                        label: mentor.userName,
                        value: mentor.mentorId,
                      }
                    }
                  />
                  <div className="col">
                    <div>
                      End Date <span style={{ color: "red" }}> *</span>
                    </div>
                    <DatePicker
                      className=""
                      minDate={new Date()}
                      selected={mentor.endDate}
                      onChange={(date) => {
                        setMentor({
                          ...mentor,
                          endDate: date,
                        });
                      }}
                      popperPlacement="top-end"
                      dateFormat="MM-dd-yyyy"
                      customInput={<CustomInput />}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer border-top-0">
              <div>
                <button type="button" className="btn cancel-button fw-bold me-2" data-bs-dismiss="modal" onClick={handleClickClear}>
                  <span className="cancel-text">Cancel</span>
                </button>

                <button
                  type="button"
                  className="btn mentor-save-button fw-bold"
                  disabled={mentor.mentorId === null || mentor.endDate === ""}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <span className="save-text">Save</span>
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddReviewer;
