import "./MentorReview.css";
import { useState, useNavigate, useEffect, decryptData, Header, SideNav, axios, MentorReviewTable, handleApiError, MentorReviewTableSkeleton, SortByTableIcon } from "../MentorImport";

const MentorReview = () => {
  const [reviewSDE, setReviewSDE] = useState({
    isLoading: true,
    originalTableData: null,
    filterTableData: null,
    sortDirection: "asc",
    sortedColumn: "Name",
  });
  const [selectedBatches, setSelectedBatches] = useState(
    JSON.parse(sessionStorage.getItem("Batches")) || []
  );
  const navigate = useNavigate();

  const handleFiltersChange = (e) => {
    let query = e.target.value.toLowerCase();
    const getFilterItems = (orgTableData, query) => {
      if (!orgTableData) {
        return [];
      }
      if (query.length > 0) {
        return orgTableData.filter((item) => item.firstName.toLowerCase().includes(query));
      }
      return orgTableData;
    };

    const filterItems = getFilterItems(reviewSDE.originalTableData, query);

    setReviewSDE((preDev) => ({
      ...preDev,
      filterTableData: filterItems
    }));
  };

  const handleSort = (columnName) => {
    if (reviewSDE.sortedColumn === columnName) {
      setReviewSDE((prevState) => ({
        ...prevState,
        sortDirection: prevState.sortDirection === "asc" ? "desc" : "asc",
      }));
    } else {
      setReviewSDE((prevState) => ({
        ...prevState,
        sortDirection: "asc",
        sortedColumn: columnName,
      }));
    }
  };

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Review/internDetailsToBeReviewed`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.post(apiUrl, body, { headers });
      if (response.data.success == 200) {
        setReviewSDE((prevState) => ({
          ...prevState,
          originalTableData: response.data.result.sort((a, b) => a.firstName.localeCompare(b.firstName)),
          filterTableData: response.data.result.sort((a, b) => a.firstName.localeCompare(b.firstName)),
          isLoading: false
        }));
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedBatches]);
  return (
    <>
      <div style={{ marginBottom: "5rem" }}>
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div>
      <div className="review-parent-wrapper mx-3">
        <div className="review-child-wrapper" style={{ marginLeft: "1rem" }}>
          <div className="review-header">
            <span>Review SDEs</span>
          </div>
          <div className="review-filter-wrapper ">
            <div className="review-search">
              <div className="review-search-icon" />
              <input
                type="text"
                className="review-input-bar"
                placeholder="Search SDEs"
                onChange={(e) => handleFiltersChange(e)}
              />
            </div>
          </div>
          <div className="review-table-wrapper ">
            <div className="container-fluid review-container-table">
              <table className="review-table-report" cellPadding="0" cellSpacing="0">
                <thead style={{ zIndex: 50 }}>
                  <tr>
                    <th style={{ width: "32.375rem", cursor: "pointer" }} onClick={() => handleSort("Name")}>
                      <div className="d-flex align-items-center justify-content-between">
                        Name
                        <SortByTableIcon />
                      </div>
                    </th>
                    <th style={{ width: "21.5rem", cursor: "pointer" }} onClick={() => handleSort("Int ID")}>
                      <div className="d-flex align-items-center justify-content-between">
                        Int ID
                        <SortByTableIcon />
                      </div>
                    </th>
                    <th style={{ width: "50.875rem" }}>Technology Tags</th>
                    <th style={{ width: "11.375rem" }}>Review Status</th>
                  </tr>
                </thead>
                {
                  reviewSDE.isLoading ?
                    <>
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                      <MentorReviewTableSkeleton />
                    </>
                    : reviewSDE.filterTableData &&
                    <MentorReviewTable
                      tableData={reviewSDE.filterTableData}
                      sortColumnName={reviewSDE.sortedColumn}
                      sortDirection={reviewSDE.sortDirection}
                    />
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorReview;
