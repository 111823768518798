import { ArchiveModalIcon,RestoreIcon} from "../UploadCoursesImport";
import "../../Task/Successfull.css";
import "./TotalUploadedCourses.css";

const ArchiveCourse = (props) => {
  const { taskToArchive, setTaskToArchive, onConfirmArchive, onCancleClick, type} = props;
  return (
    <div className="archive-course-wrapper">
      <div className="archive-tech-talk-popup-wrapper">
        <div className="pp-cross-btn mt-2">
          <button
            type="button"
            className="btn-close me-2"
            onClick={onCancleClick}
          ></button>
        </div>
        <div style={{ padding: "0rem 2rem 2rem 2rem" }}>
          <div className="row mt-5">
            {taskToArchive.isActive ? (
              <ArchiveModalIcon />
            ) : (
              <RestoreIcon/>
            )}
          </div>
          <div className="row mt-3 d-flex ">
            <p className="d-flex justify-content-center archive-course-heading">
              {taskToArchive.isActive ? `Archive ${type}` : `Restore ${type}`}
            </p>
            <p className="archive-course-sub-heading">
               Are you sure you want to {" "}
              {taskToArchive.isActive ? (
                <>
                  archive{" "}
                  <span style={{ color: "#343435" }} className="fw-bold">
                    "{taskToArchive.title}".
                  </span>{" "}
                  You can restore this later, if needed.
                </>
              ) : (
                <>
                  Restore{" "}
                  <span style={{ color: "#343435" }} className="fw-bold">
                    “{taskToArchive.title}”
                  </span>
                  .
                </>
              )}
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="course-archive-cancel-btn me-1"
              onClick={onCancleClick}
            >
              No, Cancel
            </button>
            <button
              type="button"
              className="course-archive-yes-btn ms-1"
              onClick={() => onConfirmArchive()}
              disabled={taskToArchive.isLoading}
            >
              {taskToArchive.isActive ? "Yes, Archive" : "Yes, Restore"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveCourse;
