import { ArrowForwardIcon, VideoPlay, useNavigate } from "../../UploadCourses/UploadCoursesImport";

const TechTalkSession = (props) => {
  const { sessionCount } = props;
  const navigate = useNavigate()
  return (
    <>
      <div className="col-12">
        <div className="d-flex m-0 w-100">
          <div className="d-flex gap-1" onClick={() => {
            navigate("/admin/lms/all-techTalk");
          }}>
            <p className="d-flex mb-0  align-items-center total-courses-head">
              Tech Talk
            </p>
            <span className="d-flex align-items-center mx-1">
              <ArrowForwardIcon />
            </span>
            <span >
              <VideoPlay />
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <p className="mb-0 me-1 total-courses-sub-head">
              {sessionCount}
            </p>
            <span className="tt-session d-flex align-items-center">Sessions</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechTalkSession;
