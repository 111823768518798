import "./AssociateConsultant.css";
import { EmptySdeList, useState, useEffect, axios, decryptData, AssociateConsultantSkeleton, useNavigate, SearchIcon, DownArrow, UpArrow, GoldStarOri, SilverStarOri, BronzeStarOri, handleApiError } from "../../AdminImport";

function getInitials(name) {
  const names = name.split(" ");
  const initials = names.map((n) => n.charAt(0).toUpperCase());
  return initials.join("");
}

const AssociateConsultant = (props) => {
  const { selectedBatches } = props;
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [filterUserList, setFilterUserList] = useState({
    originalData: [],
    filteredData: [],
  });
  const [isClicked, setIsClicked] = useState({
    showAllTech: false,
    expandMore: false,
    isLoading: true,
  });

  const navigate = useNavigate();

  const handleOnclick = (id) => {
    sessionStorage.setItem("detailId", id);
    sessionStorage.setItem("chrumValue", "Dashboard");
    navigate(`/admin/sde/report/detail`);
  };

  const handleFiltersChange = () => {
    const getFilterItems = (items, searchValue) => {
      if (searchValue) {
        let fitlerData = items.filter((item) => item.name?.toLowerCase().startsWith(searchValue.toLowerCase()));
        return fitlerData;
      }
      return items;
    };
    let filteredUsers = getFilterItems(filterUserList.originalData, searchFilterValue);
    setFilterUserList({
      ...filterUserList,
      filteredData: filteredUsers,
    });
  };

  useEffect(() => {
    handleFiltersChange();
  }, [searchFilterValue]);

  const handleExpand = (intId) => {
    if (isClicked.expandMore === intId) {
      setIsClicked({
        ...isClicked,
        expandMore: false,
      });
    } else {
      setIsClicked({
        ...isClicked,
        expandMore: intId,
      });
    }
  };

  const renderStars = (level) => {
    if (level === "Beginner") {
      return <BronzeStarOri />;
    } else if (level === "Intermediate") {
      return <SilverStarOri />;
    } else if (level === "Advance") {
      return <GoldStarOri />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    fetchAc();
  }, [selectedBatches]);

  const fetchAc = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    let url = `${process.env.REACT_APP_API_URL_DOTNET}/ActivityLog/getLogsGroupByUserAndBatchName`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: user.name,
          levelName: user.levelName,
          techName: user.techName,
          cgiId: user.cgiId,
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });
        setIsClicked({
          ...isClicked,
          isLoading: false,
        });
        setFilterUserList({
          originalData: sortedUsers,
          filteredData: sortedUsers,
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  return (
    <>
      <div>
        <div className="about-associate" style={{ marginLeft: "0.938rem" }}>
          SDEs
        </div>
        <div className="associate-card">
          {filterUserList?.originalData.length !== 0 && (
            <div className="d-flex align-items-center ps-3 associate-search-wrapper">
              <SearchIcon />
              <input
                className="search-associate "
                type="text"
                value={searchFilterValue}
                placeholder="Search"
                onChange={(event) => {
                  event.preventDefault();
                  setSearchFilterValue(event.target.value);
                }}
              />
            </div>
          )}
          {isClicked.isLoading ? (
            <>
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
              <AssociateConsultantSkeleton />
            </>
          ) : filterUserList?.originalData.length === 0 ? (
            <div className="associate-card-empty d-flex justify-content-center align-items-center">
              <div className="d-flex align-items-center flex-column mb-3">
                <EmptySdeList className="empty-sde-state" />
                <p className="fw-bold mt-3 mb-1 empty-sde-text">No User information right now!</p>
                <p className="empty-sde-fade-text">Active users not found on the portal.</p>
              </div>
            </div>
          ) : filterUserList.filteredData.length > 0 ? (
            <div style={{ overflow: "auto" }}>
              {filterUserList.filteredData.map((userData) => {
                const initials = getInitials(userData.name);
                return (
                  <>
                    <div key={userData.cgiId} className="card associate-consultant-mapped-card">
                      <div className="row" style={{ width: "24.875rem" }}>
                        <div
                          className="frame"
                          onClick={() => {
                            handleOnclick(userData.id);
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "0.938rem",
                            }}
                          >
                            {initials}
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            handleOnclick(userData.id);
                          }}
                          className=" col-4"
                          style={{ width: "22rem" }}
                        >
                          <div className="frame-text">{userData.name}</div>
                          <div className="frame-id">{userData.cgiId}</div>
                        </div>
                        <span
                          style={{ cursor: "pointer", position: "absolute", left: "22.5rem", top: "0.8rem", width: "auto" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleExpand(userData.cgiId);
                          }}
                          className="expand-arrow p-0"
                        >
                          {isClicked.expandMore === userData?.cgiId ? <UpArrow /> : <DownArrow />}
                        </span>
                      </div>
                      {isClicked.expandMore === userData?.cgiId && (
                        <div className="row mt-3">
                          <div className="technology">
                            <p className="tech">Technology:</p>

                            {userData && userData.techName && userData.techName[0] ==null && <p>N.A</p>}
                            {userData &&
                              userData.techName &&
                              userData.techName.slice(0, 4).map(
                                (skill, skillIndex) =>
                                  skill && (
                                    <span key={skillIndex} className="tech-div-badge">
                                      {skill.toUpperCase()} &nbsp; {renderStars(userData.levelName[skillIndex])}
                                    </span>
                                  )
                              )}
                            {userData && userData.techName && userData.techName.length > 4 && (
                              <div className="tech-tags">
                                {isClicked.showAllTech ? (
                                  userData.techName.slice(4).map(
                                    (skill, skillIndex) =>
                                      skill && (
                                        <span key={skillIndex} className="tech-div-badge">
                                          {skill.toUpperCase()}
                                          &nbsp;
                                          {renderStars(userData.levelName[skillIndex + 4])}
                                        </span>
                                      )
                                  )
                                ) : (
                                  <div
                                    className="all-tech mt-1"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsClicked({
                                        ...isClicked,
                                        showAllTech: true,
                                      });
                                    }}
                                  >
                                    + {userData.techName.length - 4}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <div className="associate-card-empty d-flex justify-content-center align-items-center">
              <div className="d-flex align-items-center flex-column mb-3">
                <EmptySdeList className="empty-sde-state" />
                <p className="fw-bold mt-3 mb-1 empty-sde-text">No User information right now!</p>
                <p className="empty-sde-fade-text">Active users not found on the portal.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AssociateConsultant;
