import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GradeAssignmentTableSkeleton = () => {
  return (
      <tr>
        <td><Skeleton width={18} /></td>
        <td>
          <p className="mb-0 grade-assign-name-text">
          <Skeleton width={160} />
          </p>
        </td>
        <td><Skeleton width={160} /></td>
        <td className="d-flex justify-content-center align-items-center">
          <div><Skeleton width={100} /></div>
        </td>
      </tr>
  );
};

export default GradeAssignmentTableSkeleton;
