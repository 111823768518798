import "./Reporttable.css";
import { useState, useEffect,decryptData,handleApiError,axios,Sort, EmptyReport, FormatDate, useNavigate, GoldStarOri, SilverStarOri, BronzeStarOri, Form, Col, ReportTableSkeleton,Add,CustomModal,AddReviewer,ReviewCompleted,ReviewWarning,ReviewFlag} from "./../AdminImport";
const Reporttable = (props) => {
  const { tableData, isLoading, deployData, handleDeployChange,fetchData} = props;
  const [sort, setSort] = useState({
    selectedIndex : -1,
    column: null,
    direction: "asc",
  });
  const [modalData, setModalData] = useState({
    modalShow : false,
    isModalOpen: false,
    userId : null,
    learnerName : null,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [mentorData, setMentorData] = useState({
    mentor: [],
    userList: [],
  });
  const navigate = useNavigate();

  const handleOnclick = (index) => {
    sessionStorage.setItem("detailId", tableData[index].id);
    sessionStorage.setItem("chrumValue", "Report");
    navigate(`/admin/sde/report/detail`);
  };

  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAllUserByRole`;
    const body ={
      roleName : process.env.REACT_APP_USER_DES_MENTOR
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user.firstName || ""} ${user.lastName || ""}`,
          roleName: user.roleName
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        const userList = sortedUsers.filter(list => list.roleName === "Mentor").map(list => {
            if (list.roleName ==="Mentor")
              return ({ label: list.name, value: list.id });
          });
        setMentorData((prevState) => ({
          ...prevState,
          userList: userList
        }));

      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response: null;
      handleApiError(statusCode, navigate);
    }
  }

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleSort = (columnName) => {
    setSort((prevSort) => ({
      column: columnName,
      direction: prevSort.column === columnName ? (prevSort.direction === "asc" ? "desc" : "asc") : "asc",
    }));
  };

  const countLevels = (levels) => {
    const counts = {
      Beginner: 0,
      Intermediate: 0,
      Advanced: 0,
    };

    levels.forEach((level) => {
      counts[level]++;
    });

    return counts;
  };

  const compareLevelsCounts = (countsA, countsB) => {
    if (countsA.Beginner !== countsB.Beginner) {
      return countsA.Beginner - countsB.Beginner;
    }
    if (countsA.Intermediate !== countsB.Intermediate) {
      return countsA.Intermediate - countsB.Intermediate;
    }
    return countsA.Advanced - countsB.Advanced;
  };

  const handleClickClear = () => {
    setModalData(() => ({
      userId : null,
      learnerName : null,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
    fetchData();
  };

  const performSorting = (tableData) => {
    if (sort.column === "Name") {
      const sortedData = tableData.sort((a, b) => {
        const compareValue = a.name.localeCompare(b.name);

        return sort.direction === "asc" ? compareValue : -compareValue;
      });

      return sortedData;
    }

    if (sort.column === "Int ID") {
      const sortedData = tableData.sort((a, b) => {
        const aInt = parseInt(a.cgiId.split(" ")[1]);
        const bInt = parseInt(b.cgiId.split(" ")[1]);

        return sort.direction === "asc" ? aInt - bInt : bInt - aInt;
      });

      return sortedData;
    }

    if (sort.column === "Technology Achieved") {
      const sortedData = tableData.sort((a, b) => {
        const aTagsCount = a.techName.length;
        const bTagsCount = b.techName.length;
        const compareValue = aTagsCount - bTagsCount;
        return sort.direction === "asc" ? compareValue : -compareValue;
      });

      return sortedData;
    }

    if (sort.column === "Skills Achieved") {
      const sortedData = tableData.sort((a, b) => {
        const levelsCountA = countLevels(a.levelName);
        const levelsCountB = countLevels(b.levelName);
        const compareValue = compareLevelsCounts(levelsCountA, levelsCountB);
        return sort.direction === "asc" ? compareValue : -compareValue;
      });
      return sortedData;
    }

    if (sort.column === "Duration") {
      const sortedData = tableData.sort((a, b) => {
        const durationA = a.durationOfInternship;
        const durationB = b.durationOfInternship;
        const compareValue = durationA - durationB;
        return sort.direction === "asc" ? compareValue : -compareValue;
      });

      return sortedData;
    }

    if (sort.column === "StartDate") {
      const sortedData = tableData.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);

        return sort.direction === "asc" ? dateA - dateB : dateB - dateA;
      });

      return sortedData;
    }

    if (sort.column === "Batch") {
      const sortedData = tableData.sort((a, b) => {
        const batchNameA = a.batchName.toLowerCase();
        const batchNameB = b.batchName.toLowerCase();

        return sort.direction === "asc" ? batchNameA.localeCompare(batchNameB) : batchNameB.localeCompare(batchNameA);
      });

      return sortedData;
    }

    return tableData;
  };
  const sortedTableData = performSorting(tableData);

  return (
    <>
      <div className="container-fluid container-table" id="Desktop-view-report-table" >
      <div className={tableData && tableData.length !== 0 ? (isLoading ? "skeleton-table-container" : "table-container") : "table-container-disabled"}> 
          <table className="table-report table-striped" cellPadding="0" cellSpacing="0">
            <thead style={{ zIndex: "10" }}>
              <tr>
                <th className="b-right" style={{ width: "12.88rem" }}>
                  <span className="d-flex justify-content-between mt-1">
                    Name
                    <Sort
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSort("Name");
                      }}
                    />
                  </span>
                </th>
                <th className="b-right" style={{ width: "10rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Int ID
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("Int ID");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "10rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Start Date
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("StartDate");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "10rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    End Date
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("StartDate");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "11.375rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Duration
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("Duration");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "11.375rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Batch
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("Batch");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "20.875rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Technology Achieved
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("Technology Achieved");
                        }}
                      />
                    </div>
                  </span>
                </th>
                <th className="b-right" style={{ width: "23.375rem" }}>
                  <span className="d-flex justify-content-between mt-1 ">
                    Skills Achieved
                    <div>
                      <Sort
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSort("Skills Achieved");
                        }}
                      />
                    </div>
                  </span>
                </th>              
                <th className="b-right" style={{ width: "11.375rem" }}>
                  <span>Reviewer1</span>
                </th>
                <th className="b-right" style={{ width: "11.375rem" }}>
                  <span>Reviewer2</span>
                </th>
                <th className="b-right" style={{ width: "11rem" }}>
                  <span>Is Deployed</span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <>
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
                <ReportTableSkeleton />
              </>
            ) : tableData && tableData.length === 0 ? (
              <tbody className="empty-report-body">
                <tr>
                  <td colSpan="12" className="text-center">
                    <div className="d-flex justify-content-center align-items-center flex-column p-5">
                      <EmptyReport />
                      <p className="fw-bold mt-3 mb-1 empty-report-text">No user productivity reports available yet</p>
                      <p className="empty-report-fade-text">We’ll notify you when something arrives.</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sortedTableData?.map((val, ind) => {
                  let objectKeyCount = 0;
                  return (
                    <tr
                      className="report-table-tr"
                      key={ind}
                    >
                      <td>
                        <div className="name-column">
                          <div className="circle">
                            {val.name.split(' ')[0]?.toUpperCase().slice(0, 1)}
                            {val.name.split(' ')[1]?.toUpperCase().slice(0, 1)}
                          </div>
                          <div className="tags justify-content-center">
                            <div className="tag1" onClick={() => {handleOnclick(ind)}}>{`${val.name}`}</div>
                          </div>
                        </div>
                      </td>
                      <td className="tag2 tag2-adjustment">{val.cgiId}</td>
                      <td className="tag2 tag2-adjustment">{FormatDate(val.startDate)}</td>
                      <td className="tag2 tag2-adjustment">{FormatDate(val.endDate)}</td>
                      <td>
                        <div className="report-duration">
                          <span>{val?.duration <= 1 ? `0${val?.durationOfInternship} month  ` : `${val?.durationOfInternship} months`}</span>
                        </div>
                      </td>
                      <td className="tag2 tag2-adjustment">{val.batchName}</td>
                      <td>
                        <div className="tech-tags" 
                        onMouseLeave={() => 
                          setSort((prev) => ({
                          ...prev,
                          selectedIndex : -1
                        }))}>
                          {val?.techName?.length === 1 && val.techName[0] === null ? (
                            <div>NA</div>
                          ) : (
                            val?.techName?.slice(0, sort.selectedIndex === ind ? val?.techName?.length : 3).map((value, index) => {
                              objectKeyCount++;
                              return value === null ? (
                                <div key={index}></div>
                              ) : (
                                <div key={index} className="tag-tech d-flex justify-content-center align-items-center">
                                  <span>{value}</span>
                                  <div>{val.levelName[index] === "Beginner" ? <BronzeStarOri /> : val.levelName[index] === "Intermediate" ? <SilverStarOri /> : <GoldStarOri />}</div>
                                </div>
                              );
                            })
                          )}
                          {sort.selectedIndex !== ind && objectKeyCount > 2 && val?.techName.slice(3).length !== 0 && (
                            <div className="all-tech" 
                            onMouseEnter={() => setSort((prev) => ({
                              ...prev,
                              selectedIndex : ind
                            }))}>
                              <span>+ {val?.techName.slice(3).length}</span>
                            </div>
                          )}
                        </div>
                      </td>
                      
                      <td>
                        <div className="skills-wrapper">
                          <span className="skills">
                            {val?.beginnerCount === null ? "0" : val?.beginnerCount} Beginner &nbsp;
                            <span className="dot" /> &nbsp; {val?.intermediateCount === null ? "0" : val?.intermediateCount} Intermediate &nbsp;
                            <span className="dot" /> &nbsp; {val?.advancedCount === null ? "0" : val?.advancedCount} Advanced
                          </span>
                        </div>
                      </td>
                      <td>
                        {val.reviewerDetails && val.reviewerDetails.length >0 ? (
                        <>
                          {val.reviewerDetails[0].reviewStatus=== "Completed" ? (
                            <div className="review-status-card">
                              <ReviewCompleted />
                              Completed
                            </div>
                          ) : val.reviewerDetails[0].reviewStatus=== "Pending" ? (
                            <div className="review-status-card-pending">
                              <ReviewFlag />
                              Pending
                            </div>
                          ) : (
                            <div className="review-status-card-overdue">
                              <ReviewWarning />
                              Overdue
                            </div>
                          )}
                          <div className="mt-1 frame-id-task">{`${val.reviewerDetails[0].firstName} ${val.reviewerDetails[0].lastName}`}</div>
                        </>
                        ) : <button type="button" className="add-reviewer-button mb-2" onClick={() => {
                          setModalData((prevState) => ({
                            ...prevState,
                            modalShow : true,
                            userId : tableData[ind].id,
                            learnerName : tableData[ind].name 
                          }));
                        }}><Add className="add-reviewer-img"/></button>}
                      </td>
                      <td>
                        {val.reviewerDetails && val.reviewerDetails.length >=2 ? (
                          <>                          
                            {val.reviewerDetails[1].reviewStatus=== "Completed" ? (
                              <div className="review-status-card">
                                <ReviewCompleted />
                                Completed
                              </div>
                            ) : val.reviewerDetails[1].reviewStatus=== "Pending" ? (
                              <div className="review-status-card-pending">
                                <ReviewFlag />
                                Pending
                              </div>
                            ) : (
                              <div className="review-status-card-overdue">
                                <ReviewWarning />
                                Overdue
                              </div>
                            )}
                            <div className="mt-1 frame-id-task">{`${val.reviewerDetails[1].firstName} ${val.reviewerDetails[1].lastName}`}</div>
                          </>
                      ): <button type="button" className="add-reviewer-button mb-2" onClick={() => {
                        setModalData((prevState) => ({
                          ...prevState,
                          modalShow : true,
                          userId : tableData[ind].id,
                          learnerName : tableData[ind].name
                        }));
                      }}><Add className="add-reviewer-img"/></button>}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div>
                          <Col md={2}>
                            <Form.Check
                              type="switch"
                              checked={deployData.some((value) => value?.id === val?.id) ? deployData.find((value) => value?.id === val?.id).status : val.isDeployed}
                              onChange={() => handleDeployChange(val.id)}
                              className="status-input"
                            />
                          </Col>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>

      <div  id="table-mobile-view">
        <div  cellPadding="0" cellSpacing="0">
          <div class="associate-card-for-reports">
            {tableData?.map((val, ind) => {
              let objectKeyCount = 0;
              return (
                <div
                  className="card associate-consultant-mapped-card-for-reports"
                  key={ind}
                >
                  <div>
                    <div className="name-column">
                      <div className="circle">
                        {val.name?.toUpperCase().slice(0, 1)}
                        {val.name?.toUpperCase().slice(0, 1)}
                      </div>
                      <div className="tags">
                        <div className="tag1" onClick={() => { handleOnclick(ind)}}>{`${val.name} ${val.name === null ? "" : val.name}`}</div>
                        <div className="d-flex" style={{ gap: "0.5rem" }}>
                          <div className="tag2">{val.cgiId}</div>
                          <div className="duration tag2">
                            <span>{val?.durationOfInternship <= 1 ? `0${val?.durationOfInternship} month  ` : `${val?.durationOfInternship} months`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="tech-tags" 
                    onMouseLeave={() => 
                      setSort((prev) => ({
                        ...prev,
                        selectedIndex : -1
                      }))}>
                      {val?.techName?.slice(0, sort.selectedIndex === ind ? val?.techName?.length : 3).map((value, index) => {
                        objectKeyCount++;
                        return value === null ? (
                          <div key={index}></div>
                        ) : (
                          <div key={index} className="tag-tech d-flex justify-content-center align-items-center">
                            <span>{value}</span>
                            <div>{val.levelName === "Beginner" ? <BronzeStarOri /> : val.levelName === "Intermediate" ? <SilverStarOri /> : <GoldStarOri />}</div>
                          </div>
                        );
                      })}
                      {sort.selectedIndex !== ind && objectKeyCount > 2 && val?.techName.slice(3).length !== 0 && (
                        <div className="all-tech" 
                        onMouseEnter={() => 
                          setSort((prev) => ({
                            ...prev,
                            selectedIndex : ind
                          }))}>
                          <span>+ {val?.techName.slice(3).length}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>
                        <strong>Is Deployed</strong>
                      </p>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div>
                        <input
                          type="checkbox"
                          className="status-input"
                          onChange={() => {
                            handleDeployChange(val.userId);
                          }}
                          checked={
                            deployData.some((value) => value?.userId === val?.userId)
                              ? deployData.find((value) => {
                                  return value?.userId === val?.userId;
                                }).status
                              : val.isDeployed
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          ) : (
          <tbody className="no-data-body">
            <tr>
              <td>No Data</td>
            </tr>
          </tbody>
          )
        </div>
      </div>
      <AddReviewer
        setModalData={setModalData}
        userId={modalData.userId}
        show={modalData.modalShow}
        learnerName = {modalData.learnerName}
        onHide={() => {
          setModalData((prevState) => ({
            ...prevState,
            modalShow : false
          }));
        }}
        userList={mentorData.userList}
      />
      {modalData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: modalData.icon,
            show: modalData.isModalOpen,
            type: modalData.modalType,
            title: modalData.modalTitle,
            message: modalData.modalMessage,
            onCancel: handleClickClear,
            onConfirm : handleClickClear
          }}
        />
      )}
    </>
  );
};

export default Reporttable;
