import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DeleteProjectIdea from "./DeleteProjectIdea";
import { ReactComponent as EditButton } from "../../../../../Assets/Buttonedit.svg";
import { ReactComponent as DeleteButton } from "../../../../../Assets/Buttondelete.svg";
import EditProjectIdeaModal from "./EditProjectIdeaModal";
import EmptyIdea from "../../EmptyStates/EmptyProject/MyIdeaViewAll";

const IdeaDetails = ({ data, indexNumber, setIndexNumber, setTaskVersion, taskVersion }) => {
  const [projectIdToChild, setProjectIdToChild] = useState(0);
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [isOpen, setIsOpen] = useState(indexNumber === 0);
  const [textInput, setTextInput] = useState("");
  const truncate = (str, maxLength) => {
    if (str.length > maxLength) {
      if (str.indexOf(" ") === -1) {
        // Break the line at 80 characters without spaces
        let truncatedStr = "";
        for (let i = 0; i < str.length; i++) {
          truncatedStr += str[i];
          if ((i + 1) % 80 === 0) {
            truncatedStr += "\n";
          }
        }
        return truncatedStr;
      } else {
        // Break the line at the last space before 80 characters
        const lastSpaceIdx = str.lastIndexOf(" ", maxLength);
        return str.slice(0, lastSpaceIdx) + "\n" + str.slice(lastSpaceIdx + 1);
      }
    } else {
      return str;
    }
  };

  const deleteTask = (e, projectId, index) => {
    e.preventDefault();
    setProjectIdToChild(projectId);
    setShowDeleteTask(true);
    setIsOpen(true);
  };

  useEffect(() => {
      if (indexNumber === null) {
        indexNumber(0);
      }
  }, [data]);
  return (
    <>
      {showDeleteTask && <DeleteProjectIdea lastIndex={data.length-1} projectId={projectIdToChild} isOpen={isOpen} setIsOpen={setIsOpen} setShowDeleteTask={setShowDeleteTask} setTaskVersion={setTaskVersion} indexNumber={indexNumber} setIndexNumber={setIndexNumber} />}

      <div >
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "35rem" }}>
            <h5 className="project-detail-name">{data[indexNumber]?.name}</h5>
          </div>
          <div>
            <EditButton
              className="mx-3"
              data-bs-toggle="modal"
              data-bs-target="#editProjectIdeaModal"
              onClick={(e) => {
                setTextInput(data[indexNumber]?.members?.filter((member) => member !== null).join(","));
                setIndexNumber(indexNumber);
              }}
            />
            <EditProjectIdeaModal setTaskVersion={setTaskVersion} projectId={data[indexNumber]?.id} projectName={data[indexNumber]?.name} projectDescriptions={data[indexNumber]?.description} projectTechnology={data[indexNumber]?.technologyUsed?.filter((tech) => tech !== null)} memberName={data[indexNumber]?.members?.filter((member) => member !== null) || []} indexNumber={indexNumber} textInput={textInput} setTextInput={setTextInput} projectVersion={true} taskVersion={taskVersion} />

            <DeleteButton
              projectId={data[indexNumber]?.id}
              onClick={(e) => {
                deleteTask(e, data[indexNumber].id, indexNumber);
              }}
            />
          </div>
        </div>
        <p className="created-at">{data[indexNumber]?.createdDate && new Date(data[indexNumber]?.createdDate).toLocaleDateString("en-US")}</p>
        <p className="project-detail-text">
          {data[indexNumber]?.description}
        </p>
        {(data[indexNumber]?.technologyUsed && data[indexNumber]?.technologyUsed?.length > 0) && <p className="project-detail-technology-used mb-2">Technology Used:</p>}
        <div className="project-detail-technology-badges">
          {data[indexNumber]?.technologyUsed?.map &&
            data[indexNumber]?.technologyUsed?.map((tech) => {
              if (tech != null) {
                return <p className="technology-badge me-1"> {tech.techName} </p>;
              }
            })}
        </div>
        {data[indexNumber]?.projectLink && (
          <div>
            <p className="project-detail-link">Project Link:</p>
            <p className="project-link-name">
              <Link to={data[indexNumber]?.projectLink} target="_blank">
              {data[indexNumber]?.projectLink.length > 80 ? `${data[indexNumber]?.projectLink.slice(0, 80)}...` : data[indexNumber]?.projectLink}
              </Link>
            </p>
          </div>
        )}
        {data[indexNumber]?.hostedLink && (
          <div>
            <p className="project-detail-hosted-link">Hosted Link:</p>
            <p className="hosted-link-name text-decoration-none">
              <Link to={data[indexNumber].hostedLink} target="_blank">
                {data[indexNumber].hostedLink}
              </Link>
            </p>
          </div>
        )}
        <div className="members-div pt-0">
          {(data[indexNumber]?.members && data[indexNumber]?.members?.length > 0) && <div className="member fw-bold mb-2 mt-2">Members:</div>}
          <div className="project-members ml-0">
            {data[indexNumber]?.members?.length > 4 ? (
              <>
                {data[indexNumber]?.members.slice(0, 4).map((member, index) => {
                  const initials = member?.name?.split(" ")
                    .map((word) => word[0])
                    .join("")
                    .toUpperCase();
                  return (
                    <div className="project-idea-members" key={index}>
                      <p className="name-of-members" title={member?.name}>
                        {initials}
                      </p>
                    </div>
                  );
                })}
                <div className="count-of-members">
                  <p className="name-of-members" title={data[indexNumber]?.members.slice(4).map((member) => {
                          return member.name
                        }).join(", ")}
                  >
                    + {data[indexNumber]?.members?.length - 4}
                  </p>
                </div>
              </>
            ) : (
              data[indexNumber]?.members?.map((member, index) => {
                const initials = member?.name?.split(" ")
                  .map((word) => word[0])
                  .join("")
                  .toUpperCase();
                return (
                  <div className="project-idea-members" key={index}>
                    <p className="name-of-members" title={member?.name}>
                      {initials}
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default IdeaDetails;
