import { useState, React, Clock, History } from "../MentorImport";
import GradeAssignmentTableSection from "./GradeAssignmentTableSection";
import GradeHistoryModal from "./GradeHistoryModal";
import "./MentorAssignTask.css";

const AssignmentDetail = ({ detail, userList, isLoading }) => {
  const [modalstate, setModalState] = useState({
    modal: false,
    detailModal: false,
    showBrowse: true,
  });
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleDetailClose = () => {
    setModalState((prevData) => ({
      ...prevData,
      detailModal: false,
    }));
  };

  const DownloadAssignment = (sasUrl, fileName) => {
    const link = document.createElement("a");
    link.href = sasUrl;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncatedDescription = () => {
    const maxLength = 200;
    if (detail?.assignments[0]?.description.length > maxLength) {
      return detail?.assignments[0]?.description.slice(0, maxLength) + "...";
    }
    return detail?.assignments[0]?.description;
  };
  return (
    detail && (
      <div className="task-detail-wrapper">
        <div className="mentortask-heading-style">{detail?.assignments[0]?.title}</div>
        <div className="mentortask-duration-wrapper">
          <div className="mentortask-duration-style">
            {<Clock />}
            <span>{detail?.assignments[0]?.completionTime}</span>
            <span
              className="d-flex gap-2 align-items-center"
              onClick={() => {
                DownloadAssignment(detail?.assignments[0]?.assignmentAttachment[0].fullPath, detail?.assignments[0]?.assignmentAttachment[0].name);
              }}
            >
              <div className="course-det-dot" />
              <p className="mb-0 assignment-download-link">Download Assignment</p>
            </span>
          </div>
        </div>
        <div className="mentortask-description-style mb-4">
          {detail?.description.length > 200 ? (showFullDescription ? detail?.description : truncatedDescription()) : detail?.description}
          <span className="assign-desc-read-more fw-bold " onClick={toggleDescription}>
            {detail?.description.length > 200 ? (showFullDescription ? "...Read less" : " Read more") : ""}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <p className="grade-assign-table-head">Grade Assignment</p>
          <div
            className="d-flex gap-1"
            onClick={() =>
              setModalState((prevData) => ({
                ...prevData,
                detailModal: true,
              }))
            }
          >
            <History />
            <p className="grading-history-text mb-0">Grading History</p>
          </div>
        </div>
        <GradeAssignmentTableSection userList={userList.filter((user) => user.status === false)} isLoading={isLoading} />
        <GradeHistoryModal detailModal={modalstate.detailModal} handleClose={handleDetailClose} userList={userList.filter((user) => user.status === true)} />
      </div>
    )
  );
};

export default AssignmentDetail;
