import { useNavigate, Publish } from "../UploadCourses/UploadCoursesImport";

const FileUploadFooter = (props) => {
  const { uploadData, setUploadData, type, validationFunction, setIsModalOpen } = props;
  const navigate = useNavigate();

  const handlePrevious = () => {
    if (uploadData.currPage > 1) {
      if (type == "course") {
        setUploadData((prevData) => {
          const updatedModules = prevData.modules.filter((module) => module.moduleName !== "");
          return {
            ...prevData,
            modules: updatedModules,
            currentPage: prevData.currentPage - 1,
          };
        });
      }
      if (type == "Tech Talk" && uploadData.currPage == 4) {
        setUploadData((prevData) => ({
          ...prevData,
          currPage: prevData.currPage - 1,
        }));
      }
      setUploadData((prevData) => ({
        ...prevData,
        currPage: prevData.currPage - 1,
      }));
    }
  };
  const handleNext = () => {
    if (uploadData?.currPage < 4 && validationFunction) {
      if (type == "Tech Talk" && uploadData.currPage == 2) {
        setUploadData((prevData) => ({
          ...prevData,
          currPage: prevData.currPage + 1,
        }));
      }
      setUploadData((prevData) => ({
        ...prevData,
        currPage: prevData.currPage + 1,
      }));
    }
  };
  const handlePublish = () => {
    setIsModalOpen((prevData) => ({
      popUp: true,
      isLoading: false
    }));
  };
  return (
    <>
      <div className="card-footer upload-course-foot">
        <button type="button" className="btn cancel-button fw-bold" onClick={() => navigate("/admin/lms/dashboard")}>
          <span className="cancel-text">Cancel</span>
        </button>

        <div>
          {uploadData.currPage > 1 && (
            <button type="button" className="btn cancel-button mx-3" onClick={handlePrevious}>
              <span className="cancel-text">Previous</span>
            </button>
          )}
          {uploadData.currPage < 4 && (
            <button
              type="button"
              className="btn mentor-save-button fw-bold"
              disabled={validationFunction()}
              onClick={handleNext}
            >
              <span className="save-text">Next</span>
            </button>
          )}

          {uploadData.currPage == 4 && (
            <button type="button" className="btn publish-button fw-bold" onClick={handlePublish} disabled={validationFunction()}>
              <div className="d-flex">
                <Publish />
                <span className="publish-text mx-1">Publish</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploadFooter;
