import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../AdminPortal/AdminImport";

const UserAuthGuard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const navigateAccordingToPermission = (str) => {
    const path = location.pathname;
    const permissionArray = str?.split(",");
    let session, review, project;
    permissionArray?.forEach((element) => {
      if (element === "Session") session = true;
      if (element === "Project") project = true;
      if (element === "Review") review = true;
    });
    if (path === "/lms/dashboard" && session) {
      navigate(path);
    } else if ((path === "/mentor/assign-task" || path === "/mentor/project-rating" || path === "/mentor/gradeAssignment") && (project || session)) {
      navigate(path);
    } else if ((path === "/mentor/review-associates" || path === "/mentor/review-associates/inter-performance-review") && review) {
      navigate(path);
    } else {
      if (session) navigate("/lms/dashboard");
      else if (project) navigate("/mentor/assign-task");
      else if (review) navigate("/mentor/review-associates");
    }
  };

  const handleAuth = () => {
    if (localStorage.getItem("login")) {
      if (localStorage.getItem("login") === "false") {
        navigate("/");
        setIsAuthenticated(false);
      } else {
        const roleName = parsedObject.decoded.RoleName;
        const hrmsDesignationName = parsedObject.decoded.HrmsDesignationName?.toLowerCase();
        const permissionTypeName = parsedObject.decoded.PermissionTypeName;

        if (roleName === process.env.REACT_APP_USER_DES_ADMIN) {
          navigate("/admin/lms/dashboard");
        } else if (roleName === process.env.REACT_APP_USER_DES_USER) {
          if (hrmsDesignationName === "sde" || hrmsDesignationName === "intern") {
            navigate(location.pathname);
          } else {
            navigate("/lms/dashboard");
          }
        } else {
          navigateAccordingToPermission(permissionTypeName);
        }

        setIsAuthenticated(true);
      }
    } else {
      navigate("/");
      setIsAuthenticated(false);
    }
  };
  useEffect(() => {
    handleAuth();
  }, [isAuthenticated]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default UserAuthGuard;
