import {useState,Filter } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";

const UserCourseFilter = (props) => {
  const { data, filterState, setFilterState, onApplyFilters } = props;
  
  const [lastAppliedFilters, setLastAppliedFilters] = useState(filterState);

  const collectUniqueItems = (data) => {
  let uniqueInstructors = [];
  let uniqueTags = [];
  let uniqueCategories = [];

  data?.orgTableData?.forEach((course) => {
    const instructors = course?.instructorList?.map((instructor) => ({
      id: instructor.instructorId,
      firstName: instructor.firstName,
      lastName: instructor.lastName,
    }));

    instructors?.forEach((instructor) => {
      const existingInstructor = uniqueInstructors?.find((i) => i.id === instructor.id);
      if (!existingInstructor) {
        uniqueInstructors.push(instructor);
      }
    });

    const tags = course?.tags?.map((tag) => ({
      techId: tag.id,
      techName: tag.techName,
    }));

    tags?.forEach((tag) => {
      const existingTag = uniqueTags?.find((i) => i.techId === tag.techId);
      if (!existingTag) {
        uniqueTags.push(tag);
      }
    });

    const { categoryId, categoryName, subCategoryId, subCategoryName } = course?.categoryInfo?.[0] || {};
    if (categoryName && subCategoryName) {
      const existingCategoryIndex = uniqueCategories.findIndex((category) => category.categoryId === categoryId);

      if (existingCategoryIndex === -1) {
        uniqueCategories.push({
          categoryId,
          categoryName,
          subCategories: [
            {
              subCategoryId,
              subCategoryName,
            },
          ],
        });
      } else {
        const existingSubcategoryIndex = uniqueCategories[existingCategoryIndex].subCategories.findIndex((subcategory) => subcategory.subCategoryId === subCategoryId);

        if (existingSubcategoryIndex === -1) {
          uniqueCategories[existingCategoryIndex].subCategories.push({
            subCategoryId,
            subCategoryName,
          });
        }
      }
    }
  });

    return { uniqueInstructors, uniqueTags, uniqueCategories };
  };

  const { uniqueInstructors, uniqueTags, uniqueCategories } = collectUniqueItems(data);

  const handleCross = (e) => {
    e.preventDefault();
    setFilterState((prevState) => ({
      ...prevState,
      ...lastAppliedFilters,
      showFilterBase: false,
   }));
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (id, type) => {
    switch (type) {
      case "instructor":
        setFilterState((prevState) => ({
          ...prevState,
          selectedInsId: prevState.selectedInsId.includes(id) ? prevState.selectedInsId.filter((instructorId) => instructorId !== id) : [...prevState.selectedInsId, id],
        }));
        break;
      case "grade":
        setFilterState((prevState) => ({
          ...prevState,
          selectedGradeId: prevState.selectedGradeId.includes(id) ? prevState.selectedGradeId.filter((gradeId) => gradeId !== id) : [...prevState.selectedGradeId, id],
        }));
        break;
      case "level":
        setFilterState((prevState) => ({
          ...prevState,
          selectedLevelId: prevState.selectedLevelId.includes(id) ? prevState.selectedLevelId.filter((levelId) => levelId !== id) : [...prevState.selectedLevelId, id],
        }));
        break;
      default:
        break;
    }
  };

  // Function to handle select all
  const handleSelectAll = (type) => {
    setFilterState((prevState) => {
      switch (type) {
        case "instructor":
          return {
            ...prevState,
            selectedInsId: prevState.selectedInsId.length === uniqueInstructors.length ? [] : uniqueInstructors.map((instructor) => instructor.id),
          };
        default:
          return prevState;
      }
    });
  };

  // Function to handle apply filters
  const handleApplyFilters = () => {
    onApplyFilters();
    setLastAppliedFilters(filterState);
    setFilterState((prevState) => ({
      ...prevState,
      showFilterBase: false,
      filtersApplied: true,
    }));
  };

  // Function to handle clear all
  const handleClearAll = () => {
    setFilterState((prevState) => ({
      ...prevState,
      showFilterBase: false,
      selectedInsId: [],
      selectedSubCategoryId: [],
      selectedGradeId: [],
      selectedStatus: {
        archive: false,
        draft: false,
        published: false,
      },
      selectedTechId: [],
      selectedLevelId: [],
    }));
    onApplyFilters();
  };

  return (
    <div className="course-filter-wrapper">
      <div className="course-popup-wrapper">
        <div className="course-cross-btn mt-2">
          <div className="course-filter">
            <Filter /> Filter
          </div>
          <button type="button" className="btn-close me-2" onClick={handleCross}></button>
        </div>
        <div className="course-filter-accordion-body">
          <div className="course-filter-accordion">
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="instructorName">
                  <button
                    className="accordion-button course-filter-accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Instructor Name
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="instructorName">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="d-flex justify-content-between w-100">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="instructorNameselectAll"
                          checked={filterState?.selectedInsId?.length === uniqueInstructors.length}
                          onChange={() => handleSelectAll("instructor")}
                        />
                        <label className="form-check-label fw-bold pointer" htmlFor="instructorNameselectAll">
                          Select All
                        </label>
                      </div>
                    </div>
                    <div className="instructor-filter-accordion">
                      {uniqueInstructors?.map((instructor) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={instructor.id}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={instructor.id + instructor.firstName}
                            onChange={() => handleCheckboxChange(instructor.id, "instructor")}
                            checked={filterState?.selectedInsId?.includes(instructor.id)}/>
                          <label
                            className="d-block pointer form-check-label"
                            htmlFor={instructor.id + instructor.firstName}
                          >
                            <span>
                              {instructor?.firstName || ""} {instructor?.lastName || ""}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    Grade Type
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="instructor-filter-accordion">
                      {data?.apiGrade?.map((grade, index) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={index}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={grade.id + grade.name}
                            onChange={() => handleCheckboxChange(grade.id, "grade")}
                            checked={filterState.selectedGradeId.includes(grade.id)}
                          />
                          <label className="d-block pointer form-check-label" htmlFor={grade.id + grade.name}>
                            <span>{grade.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseSix"
                  >
                    Level
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="instructor-filter-accordion">
                      {data?.apiLevel?.map((level, index) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={index}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={level.id + level.name}
                            onChange={() => handleCheckboxChange(level.id, "level")}
                            checked={filterState?.selectedLevelId?.includes(level.id)}
                          />
                          <label className="d-block pointer form-check-label" htmlFor={level.id + level.name}>
                            <span>{level.name === "Advance" ? "Advanced" : level.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <button
              type="button"
              className="course-archive-cancel-btn me-1"
              onClick={handleClearAll}
            >
              Clear All
            </button>
            <button
              type="button"
              className="course-archive-yes-btn ms-1"
              onClick={handleApplyFilters}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCourseFilter;
