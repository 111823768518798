import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../Assets/Vectorchevron.svg";
import { ReactComponent as WhiteArrow } from "../../../Assets/right-white-arrow.svg";
import "./BreadCrumbs.css";
import decryptData from "../../_common/DecryptUserData";
const BreadCrumbs = (props) => {
  const { dashboardLink = undefined } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");
  let crumbSimplified = "";

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
  const roleName = parsedObject?.decoded?.RoleName;

  const capital = (word) => {
    return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
  };

  return (
    <div className="crumb">
      <Link to={roleName === process.env.REACT_APP_USER_DES_ADMIN ? dashboardLink !== undefined ? dashboardLink : "/admin/lms/dashboard" : dashboardLink !== undefined ? dashboardLink : "/lms/dashboard"} className={props.darkBg ? "crumb-parent-light" : "crumb-parent"}>
        Dashboard
      </Link>
      {crumbs.map((crumb, index) => {
        if (crumb === "admin") return null;
        if (crumb === "sde") return null;
        crumbSimplified += capital(crumb) + " ";
        let crumbPath;
        if (crumb === "lms") {
          crumbPath = "/admin/lms/dashboard";
        }

        else {
          crumbPath = "/" + crumbs.slice(0, index + 1).join("/");
        }
        return (
          <React.Fragment key={index}>
            &nbsp;&nbsp;
            {props.darkBg ? <WhiteArrow /> : <Chevron />}
            &nbsp;
            <Link to={crumbPath} className={props.darkBg ? "crumb-parent-light" : "crumb-parent"}>
              {capital(crumb)}
            </Link>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
