import "./Assignment.css";
import { useState,useRef, ColonImage, CloseBtn, PDFIcon, DOCIcon, UploadAttachment, AttachmentClock, SizeLimit,AlertInfo,CustomModal } from "../UploadCoursesImport";

const Assignment = (props) => {
  const { courseUploadData, setCourseUploadData, error, setError, editMode } = props;
  const [ratingData,setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const fileInputRef = useRef(null);


  const handleBrowseClick = () => {
    fileInputRef.current.click();
    if(courseUploadData?.attachment?.length>0){
      handleRemoveFile(0);
}
  };

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const handleChangeName = (e) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 .:-]{0,255}$/;
    if (!specialCharsRegex.test(inputName)) {
      setError((prevData) => ({
        ...prevData,
        assignmentNameError: "Please Enter a name only with letters, colon and hyphen and dot between 1 and 255 characters",
      }));
    } else {
      setError((prevData) => ({
        ...prevData,
        assignmentNameError: "",
      }));
    }
    setCourseUploadData((prevData) => ({
      ...prevData,
      assignmentName: e.target.value,
    }));
  };

  const handleDescription = (e) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*(?:,,|\-\-|\.\.|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{1,2000}$/;
    if (!specialCharsRegex.test(inputName)) {
      setError((prevData) => ({
        ...prevData,
        assignmentDescriptionError: "Please Enter description only with letters, colon, comma and hyphen, between 1 and 2000 characters",
      }));
    } else {
      setError((prevData) => ({
        ...prevData,
        assignmentDescriptionError: "",
      }));
    }
    setCourseUploadData((prevData) => ({
      ...prevData,
      assignmentDescription: e.target.value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "." || event.key === "-" || event.key.toLowerCase() === "e") {
      event.preventDefault();
    }
  };
  const handleAttachmentChange = (files) => {
    if (!files || files.length === 0) {
      return;
    }
    const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    const file = files[0]; // Only allow the first file in the array
  
    if (!allowedFileTypes.includes(file?.type)) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error!",
        modalMessage: "Please select a PDF, DOC, or DOCX file only",
        icon: <AlertInfo/>,
      }));
      return;
    }
  
    const fileWithSpaces = file.name.includes(" ");
    if (fileWithSpaces) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error!",
        modalMessage: "File name should not contain spaces",
        icon: <AlertInfo/>,
      }));
      return;
    }
  
    setCourseUploadData((prevData) => {
      const isDuplicateAcrossModules = prevData.modules.some((module) => module.selectedFiles.some((existingFile) => !existingFile.isDeleted && existingFile.file.name === file.name));
  
      const isDuplicate = prevData?.attachment?.some((existingFile) => existingFile?.name === file?.name);
  
      if (isDuplicate || isDuplicateAcrossModules) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error!",
          modalMessage: "A file with the same name already exists. Please choose a unique file.",
          icon: <AlertInfo/>,
        }));
        return prevData;
      }
      const deletedFiles = prevData?.attachment?.filter((existingFile) => existingFile.isDeleted);
      const updatedSelectedFiles = prevData?.attachment?.filter((existingFile) => !existingFile.isDeleted);

      const newFiles = [{
        file: file,
        isAdded: true,
        isDeleted: false,
      }];
      const updatedAttachment = [...updatedSelectedFiles,...newFiles, ...deletedFiles];

      return {
        ...prevData,
        fileError: "",
        attachment: updatedAttachment,
        totalAssignments: updatedAttachment.length,
      };
    });
  };

  const handleRemoveFile = (index) => {
    fileInputRef.current.value = "";
    setCourseUploadData((prevData) => {
      let updatedAttachments = prevData.attachment.map((file, i) => {
        if (i === index && editMode) {
          if (file.isAdded) {
            // If isAdded is true, remove the file from the array
            return null;
          } else {
            // If isAdded is false, mark the file as deleted
            return {
              ...file,
              isDeleted: true,
            };
          }
        } else {
          return file;
        }
      });
      updatedAttachments = updatedAttachments.filter(Boolean);
      if (!editMode) {
        updatedAttachments = updatedAttachments.filter((_, i) => i !== index);
      }
      const deletedFiles = updatedAttachments.filter((file) => file.isDeleted);
      const remainingFiles = updatedAttachments.filter((file) => !file.isDeleted);
      updatedAttachments = [...remainingFiles, ...deletedFiles];
      return {
        ...prevData,
        attachment: updatedAttachments,
        totalAssignments: updatedAttachments.length,
      };
    });
  };

  const getAttachmentIcon = (fileName) => {
    const fileExtension = fileName?.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      return <PDFIcon />;
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return <DOCIcon />;
    } else {
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;

    switch (name) {
      case "assignmentHrs":
        if (value < 0 || value > 23) {
          setRatingData((prevData) => ({
            ...prevData,
            isModalOpen: true,
            modalType: "error",
            modalTitle: "Error !",
            modalMessage: "Max Value for hours is 23",
            icon: <AlertInfo/>,
          }));
          validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 23);
        }
        break;
      case "assignmentMin":
        if (value < 0 || value > 59) {
          setRatingData((prevData) => ({
            ...prevData,
            isModalOpen: true,
            modalType: "error",
            modalTitle: "Error !",
            modalMessage: "Max Value for minutes is 59",
            icon: <AlertInfo/>,
          }));
          validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 59);
        }
        break;
      default:
        break;
    }
    setCourseUploadData({ ...courseUploadData, [name]: validatedValue });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <form>
            <div className="mb-3">
              <label htmlFor="assignmentName" className="form-label mb-1 assignment-sub-heading">
                Assignment Name<span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" onChange={(e) => handleChangeName(e)} id="assignmentName" placeholder="Enter Assignment Name" value={courseUploadData.assignmentName} />
              {courseUploadData.assignmentName && error?.assignmentNameError !== "" && <span className="text-danger error-msg">{error?.assignmentNameError}</span>}
            </div>

            <div className="mb-3">
              <label htmlFor="assignmentDescription" className="form-label mb-1 assignment-sub-heading">
                Assignment Description<span className="text-danger">*</span>
              </label>
              <textarea className="form-control" id="assignmentDescription" onChange={(e) => handleDescription(e)} rows="5" placeholder="Enter Assignment Description" value={courseUploadData.assignmentDescription}></textarea>
              {courseUploadData.assignmentDescription && error?.assignmentDescriptionError !== "" && <span className="text-danger error-msg">{error?.assignmentDescriptionError}</span>}
            </div>

            <div className="mb-3">
              <div className="d-flex align-items-center mb-1">
                <AttachmentClock className="d-flex align-items-center" />
                <label htmlFor="sideBySideInputs" className="ms-1 assignment-sub-heading">
                  Assignment Completion Time<span className="text-danger">*</span>
                </label>
              </div>
              <div className="row g-1 completion-time-div">
                <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="Day" name="assignmentDay" onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentDay} />
                </div>
                <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                  <ColonImage />
                </div>
                <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="hrs" name="assignmentHrs" onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentHrs} />
                </div>
                <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                  <ColonImage />
                </div>
                <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="min" name="assignmentMin" onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentMin} />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-center mb-1">
                <label htmlFor="sideBySideInputs" className="ms-1 assignment-sub-heading">
                  Upload Assignment
                </label>
              </div>
              <div className="mb-1 mt-2">
                <div className="file-ext-limit gap-2">
                  <SizeLimit />
                  <p className="limit-text">Only PDF & Doc file extensions allowed</p>
                </div>
              </div>
            </div>
            {courseUploadData?.attachment
              ?.filter((file) => !file.isDeleted)
              .map((file, index) => (
                <div>
                  <div key={index} className="d-flex align-items-center ps-1 attachment-search-wrapper mb-2">
                    <div className="assignment-progress-indicator-status">
                      {file && (
                        <div className="d-flex align-items-center attachment-inner-box">
                          {getAttachmentIcon(file?.file?.name)}
                          <div className="mx-1" data-toggle="tooltip" data-placement="top" title={file?.file?.name}>
                            {file?.file?.name.length > 50 ? `${file?.file?.name.substring(0, 40)}...` : file?.file?.name}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        handleRemoveFile(index);
                      }}
                    >
                      <CloseBtn />
                    </div>
                  </div>
                </div>
              ))}
            <div className="mb-3">
              <input className="d-none" type="file" ref={fileInputRef} onChange={(e) => handleAttachmentChange(e.target.files)} accept=".pdf, .doc, .docx" />
              <button
                type="button"
                onClick={() => {
                  handleBrowseClick();
                }}
                className="upload-attachment-btn"
              >
                <UploadAttachment />
                Upload Attachment
              </button>
            </div>
          </form>
        </div>
      </div>
      {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </div>
  );
};

export default Assignment;
