import { useState, useEffect,EmptyState, CertificateImage, BadgeImage, LinkIcon, FormatDate, BadgeIcon, UsersWithCertificatesSkeleton, useNavigate,EmptyCertificate,BadgeReportEmpty} from "../../AdminImport";
import "../UsersWithCertificates/UsersWithCertificates.css";
 
const UsersWithCertificates = (props) => {
  const { certificateData, certificateDataLoading } = props;
  const [selectedButtons, setSelectedButtons] = useState([]);
 
  const handleButtonClick = (index, button) => {
    setSelectedButtons((prevSelectedButtons) => {
      const newSelectedButtons = [...prevSelectedButtons];
      newSelectedButtons[index] = button;
      return newSelectedButtons;
    });
  };
  const navigate = useNavigate();
  const handleOnclick = (id) => {
      sessionStorage.setItem("uId", id);  
      navigate("/admin/lms/course-report");
  };
  useEffect(() => {
    setSelectedButtons(certificateData.map(() => "certificates"));
  }, [certificateData]);
 
  return (
    <>
      {certificateDataLoading ? (
        <div className="card empty-detailed-certificate p-0" >
          <div className="accordion w-100 cert-accordion ">
            {Array.from({ length: 9 }).map((_, index) => <UsersWithCertificatesSkeleton key={index} />)}
          </div>
        </div>
      ) : certificateData.length > 0 ? (
        <div className="card empty-detailed-certificate p-0">
          <div className="accordion w-100 cert-accordion " id="accordionPanelsStayOpenExample">
            {certificateData.map((data, index) => {
              const accordionId = `accordionPanel-${data.userId}-${index}`;
              const collapseId = `collapse-${data.userId}-${index}`;
              return (
                <div className="accordion-item border-top-0 cert-accordion-border-radius" key={collapseId}>
                  <h2 className="accordion-header" id={`panelsStayOpen-heading-${accordionId}`}>
                    <button
                      className="accordion-button collapsed cert-accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${collapseId}`}
                      aria-expanded="false"
                      aria-controls={collapseId}
                      data-bs-parent="#accordionPanelsStayOpenExample"
                    >
                      <div className="d-flex align-items-center">
                        <div className="cert-image-wrapper1">
                          <div className="image-box1">
                            <div className="d-flex justify-content-center noMentor-img">
                              <span className="initials">
                                {data.userFirstName
                                  .split(" ")
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join("")}
                                {data.userLastName
                                  .split(" ")
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join("")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="cert-text-wrapper1" >
                          <p className="m-0 cert-username-heading" 
                          onClick={() => {
                            handleOnclick(data.userId);
                          }}>
                            <b>
                              {data?.userFirstName || ""} {data?.userLastName || ""}
                            </b>
                          </p>
 
                          <p className="m-0 cert-pos-wrapper">{data.designation} </p>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <CertificateImage />
                          <p className="cert-count-text align-items-center mb-0 ms-1 fw-bold">{data?.certificateCount.toString().padStart(2, "0")} Certificates</p>
                        </div>
                        <div className="d-flex justify-content-center align-items-center ms-3">
                          <BadgeImage />
                          <p className="cert-count-text align-items-center mb-0 ms-1 fw-bold">{data?.badgesCount.toString().padStart(2, "0")} Badges</p>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div id={collapseId} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-heading-${accordionId}`} data-bs-parent="#accordionPanelsStayOpenExample">
                    <div className="accordion-body p-0">
                      <div className="cert-accordion-body d-flex justify-content-center">
                        <button
                          className={`certificates-btn ${selectedButtons[index] === "certificates" ? "selected-btn" : "non-selected-btn"} d-flex align-items-center border-0 ps-3`}
                          onClick={() => handleButtonClick(index, "certificates")}
                        >
                          <CertificateImage className={`certificate-img-btn ${selectedButtons[index] === "certificates" ? "certificate-img-btn-selected" : "certificate-img-btn-not-selected"}`} />
                          <p className="certificate-text m-0 ms-2">Certificates</p> <p className="p-4 certificate-count-text m-0">{data?.certificateCount.toString().padStart(2, "0")}</p>
                        </button>
                        <button
                          className={`certificates-btn ${selectedButtons[index] === "badge" ? "selected-btn" : "non-selected-btn"} d-flex align-items-center border-0 ps-3`}
                          onClick={() => handleButtonClick(index, "badge")}
                        >
                          <BadgeImage className={`certificate-img-btn  ${selectedButtons[index] === "badge" ? "certificate-img-btn-selected" : "certificate-img-btn-not-selected"}`} />
                          <p className="certificate-text m-0 ms-2">Badges</p> <p className="p-4 certificate-count-text m-0">{data?.badgesCount.toString().padStart(2, "0")}</p>
                        </button>
                      </div>
                      {selectedButtons[index] === "certificates" ? (
                        <div className={`user-certificate-details dp-scroll pt-0 ${data.certificateCount === 0 ? "d-flex align-items-center justify-content-center" : ""}`}>
                          {data.certificateCount === 0 && <h6 className="p-4 fw-bold d-grid justify-content-center gap-3 align-items-center"><EmptyCertificate style={{width:"100%"}}/>No Certificates Currently</h6>}
                          {data.certificates.map(
                            (certificate, index) =>
                              certificate.typeName === "Certificate" && (
                                <div className={`row border-bottom pb-2 pt-3`} key={index}>
                                  <div className="row">
                                    <div className="col">
                                      <p className="certificate-achieved-name">{certificate.certificateName}</p>
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-between">
                                    <div className="col">
                                      <p className="certificate-date">
                                        Issued on: <span className="certificate-date-number ms-1">{FormatDate(certificate.issuedDate)}</span>
                                      </p>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                      <p className="certificate-date">
                                        Expiry: <span className="certificate-date-number ms-1">{certificate.expiryDate!=null ? FormatDate(certificate.expiryDate) : "NA"}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <LinkIcon />
                                      <a className="link-offset-2 link-underline link-underline-opacity-0 ms-2" href={certificate.link} target="_blank">
                                        Certificate Link
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      ) : (
                        <div className={`user-certificate-details dp-scroll pt-0 ${data.badgesCount === 0 ? "d-flex align-items-center justify-content-center" : ""}`}>
                          {data.badgesCount === 0 && <h6 className="p-4 fw-bold d-grid justify-content-center gap-3 align-items-center"><BadgeReportEmpty style={{width : "100%"}}/>No Badges Currently</h6>}
                          {data.certificates.map(
                            (certificate, index) =>
                              certificate.typeName === "Badge" && (
                                <div className={`row border-bottom pb-2 pt-3`} key={index}>
                                  <div className="row">
                                    <div className="col">
                                      <p className="certificate-achieved-name">{certificate.certificateName}</p>
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-between">
                                    <div className="col">
                                      <p className="certificate-date">
                                        Issued on: <span className="certificate-date-number ms-1">{FormatDate(certificate.issuedDate)}</span>
                                      </p>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                      <p className="certificate-date">
                                        Expiry: <span className="certificate-date-number ms-1">{certificate.expiryDate!=null ? FormatDate(certificate.expiryDate) : "NA"}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col d-flex align-items-center justify-content-center">
                                    {certificate.link!=null && (certificate.link.includes("https") || certificate.link.includes("www") || certificate.link.includes("http"))?(<img src={certificate.link} width={72} height={72} />) : <BadgeIcon /> }
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="card empty-detailed-certificate d-flex justify-content-center p-0">
          <div className="col-12 d-flex justify-content-center">
            <EmptyState />
          </div>
          <div className="col-12 d-flex justify-content-center assign-task-empty">
            <p>No Certificate Category Selected! </p>
          </div>
        </div>
      )}
    </>
  );
};
 
export default UsersWithCertificates;