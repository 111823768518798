import { Skeleton } from "../../AdminImport";
import "react-loading-skeleton/dist/skeleton.css";

const UsersWithCertificatesSkeleton = () => {
  return (
    <div className="accordion-item border-top-0 cert-accordion-border-radius">
      <button className="accordion-button collapsed cert-accordion-button">
        <div className="d-flex align-items-center">
          <div className="cert-image-wrapper1">
            <div className="image-box1">
              <Skeleton width={40} height={40} circle />
            </div>
          </div>
          <div className="cert-text-wrapper1">
            <p className="m-0 cert-username-heading">
              <Skeleton width={130} />
            </p>

            <p className="m-0 cert-pos-wrapper">
              <Skeleton width={60} />{" "}
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Skeleton width={15} />
            <p className="cert-count-text align-items-center mb-0 ms-1 fw-bold">
              <Skeleton width={90} />
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center ms-3">
            <Skeleton width={15} />
            <p className="cert-count-text align-items-center mb-0 ms-1 fw-bold">
              <Skeleton width={90} />
            </p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default UsersWithCertificatesSkeleton;
