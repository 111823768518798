import { Skeleton } from "../UploadCoursesImport";
import "react-loading-skeleton/dist/skeleton.css";
import "./TotalUploadedCourses.css";

const EnrollmentStatusSkeleton = () => {
  return (
    <div className="form-check small checkbox gap-2 mb-2">
      <Skeleton
        className="form-check-input user-checkbox"
        type="checkbox"
        width={14}
      />
      <level className="d-flex justify-content-between w-100 pointer form-check-label">
      <Skeleton width={119} />
      <Skeleton width={50}/>
      </level>
    </div>
  );
};

export default EnrollmentStatusSkeleton;
