import { SuccessfullVector } from "../AdminImport";
import "./Successfull.css";

const SuccessfullTask = ({ setTasks }) => {

  const handleCross = (e) => {
    e.preventDefault();
    setTasks((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  return (
    <div className="delete-task-wrapper">
      <div className="pp-popup-wrapper">
        <div className="pp-cross-btn mt-2">
          <button
            type="button"
            className="btn-close me-2"
            onClick={(e) => handleCross(e)}
          ></button>
        </div>
        <div className="row mt-5">
          <SuccessfullVector />
        </div>
        <div className="row mt-3 d-flex ">
          <p className="d-flex justify-content-center fw-bold">Task Added Successfully </p>
        </div>
        <div className="row d-flex justify-content-center mb-3">
          <button
            type="button"
            className="taskAddedClass me-1 btn-primary"
            onClick={(e) => handleCross(e)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>

  );
};

export default SuccessfullTask;
