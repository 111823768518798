import React, { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import './Header.css'
import { handleApiError } from "../_common/HandleApiError";
import { useNavigate } from "react-router-dom";
const BatchSelect = (props) => {
  const [allBatches, setAllBatches] = useState([]);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [allSelected, setAllSelected] = useState(props.allSelected);
  const navigate = useNavigate();
  useEffect(() => {
    const secretkeyUser = process.env.REACT_APP_USER_KEY;
    var parsedObject;
    const data = localStorage.getItem("userData");
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
      const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
      parsedObject = JSON.parse(decryptedJsonString);
    } else {
      console.error("No data found.");
    }
    axios
      .get(process.env.REACT_APP_API_URL_DOTNET + `/Dashboard/getAllBatch?IncludeNotApplicable=${false}`, {
        headers: {
          Authorization: `Bearer ${parsedObject.token}`,
        },
      })
      .then((response) => {
        setAllBatches(response.data.result);
        setFilteredBatches(response.data.result);
      })
      .catch((error) => {
        handleApiError(error, navigate);
      });
  }, []);

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allUser = filteredBatches.map((c) => c.id);
      props.setSelectedBatches(allUser);
    } else {
      props.setSelectedBatches([]);
    }
  };

  const handleUsersChange = (e, c) => {
    if (e.target.checked) {
      props.setSelectedBatches([...props.selectedBatches, c.id]);
    } else if (!e.target.checked) {
      props.setSelectedBatches(
        props.selectedBatches.filter((item) => item !== c.id)
      );
    }
  };

  useEffect(() => {
    if (props?.selectedBatches && allBatches?.length > 0) {
      const selectedBatchNames = props.selectedBatches?.map((id) => {
        const foundBatch = allBatches.find((batch) => batch.id === id);
        return foundBatch ? foundBatch.batchName : ''; // If batch is found, return its name
      });
      props.batchDataComingChild(selectedBatchNames);
      sessionStorage.setItem("Batches", JSON.stringify(props.selectedBatches));
    }
  }, [props.selectedBatches, allBatches]);
  const handleSearchChange = (e) => {
    props.setSearchUserQuery(e.target.value);

    const filtered = allBatches.filter((user) =>
      user.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setFilteredBatches(filtered);
  };

  return (
    <>
      <div style={{ maxHeight: "10rem" }}>
        {!props.searchUserQuery && allSelected && (
          <div className="form-check small checkbox gap-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="selectAll"
              checked={props.selectedBatches?.length === filteredBatches?.length}
              onChange={handleCheckAllChange}
            />

            <label className="form-check-label" htmlFor="selectAll">
              Select all
            </label>
          </div>
        )}

        {filteredBatches.map((c, index) => (
          c.batchName.toLowerCase().trim() == "not applicable" ? null :
            <div className="form-check small checkbox gap-2" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                id={c.id}
                checked={props.selectedBatches?.includes(c.id)}
                onChange={(e) => handleUsersChange(e, c)}
              />

              <label className="form-check-label">
                {c.batchName}
              </label>
            </div>
        ))}
      </div>
    </>
  );
};

export default BatchSelect;
