import { Link, ArrowForwardIcon, EmptyCourseUpload } from "../UploadCoursesImport"
import "./TotalUploadedCourses.css";

const EmptyTotalUploadedCoursesTable = () => {
  return (
    <div className="container-fluid h-100 mb-3">
      <div className="row justify-content-center align-items-center empty-total-uploaded-table-height">
        <div className="col-6 text-center">
          <div className="d-flex justify-content-center align-items-center flex-column h-100 p-4">
            <EmptyCourseUpload className="empty-course-table-image" />
            <p className="fw-bold mt-3 mb-1 empty-task-text">No course has been uploaded yet!</p>
            <Link to={"/admin/lms/addCourse"} className="task-link">
              Upload New Course
              <ArrowForwardIcon className="ms-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyTotalUploadedCoursesTable;
