import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MentorReviewTableSkeleton = () => {
  return (
    <tbody>
      <tr className="report-table-tr">
        <td>
          <div className="review-name-column">
            <Skeleton
              width={38}
              height={38}
              highlightColor="#97c0c5"
              className="rounded-circle"
            />
            <div className="review-tags">
              <div className="review-tag1">
                <Skeleton width={130} />
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="review-int">
            <Skeleton width={70} />
          </div>
        </td>
        <td>
          <div className="review-tech-tags">
            <div className="skills-wrapper">
              <span className="skills">
                <Skeleton
                  width={83}
                  height={24}
                  highlightColor="#f4f6f5"
                />{" "}
                &nbsp;
                <Skeleton
                  width={83}
                  height={24}
                  highlightColor="#f4f6f5"
                />{" "}
                &nbsp;
                <Skeleton
                  width={83}
                  height={24}
                  highlightColor="#f4f6f5"
                />{" "}
                &nbsp;
                <Skeleton
                  width={22}
                  height={24}
                  highlightColor="#28519E"
                />
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className="review-status">
            <div>
              <div>
                <Skeleton highlightColor="#F98D23" />
              </div>
              <div className="review-status-date">
                <Skeleton width={102} highlightColor="#6d6d6d" />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export default MentorReviewTableSkeleton
