import { Skeleton, Usercircle, Mail,  Call } from "./../../../UploadCourses/UploadCoursesImport";
import "react-loading-skeleton/dist/skeleton.css";


const CourseReportSkeleton = () => {
  return (
    <div>
      <div className="detailrep-user-info">
        <div className="info-detail">
          <div className="user-name">
            <Usercircle />
            <Skeleton width={200} highlightColor="#B2B2B3" />
          </div>
          <div className="other-info">
            <div className="icon-pair">
              <Mail />
              <Skeleton width={150} highlightColor="#B2B2B3" />
            </div>
            <div className="det-dot" />
            <div className="icon-pair">
              <Call />
              <Skeleton width={100} highlightColor="#B2B2B3" />
            </div>
          </div>
        </div>
      </div>
      <div className="detailrep-second-child">
      </div>
    </div>
  );
};

export default CourseReportSkeleton;
