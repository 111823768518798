import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";

const LoginProcess = (token, setIsLoading, setIsAuthenticated, isLoading, navigate) => {
    localStorage.setItem("login", true);
    const decoded = jwtDecode(token);
    const secretKey = process.env.REACT_APP_USER_KEY;
    const userDataE = { token, decoded };
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userDataE), secretKey).toString();
    localStorage.setItem("userData", encryptedData);

    setIsLoading({
        ...isLoading,
        loader: true
    });

    let str = decoded.RoleName;
    setIsAuthenticated(true);
    switch (str) {
        case process.env.REACT_APP_USER_DES_USER:
            navigate("/lms/dashboard");
            break;
        case process.env.REACT_APP_USER_DES_ADMIN:
            navigate("/admin/lms/dashboard");
            break;
        default: navigate("/lms/dashboard");
            break;
    }
    setIsLoading({
        ...isLoading,
        loader: false
    });
    return
}
export default LoginProcess;