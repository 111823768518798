import { React, Header, useLocation, SideNav, BreadCrumbs, ExportExcel, Usercircle, Mail, Call, Select, useState, components, decryptData, axios, useNavigate, handleApiError, useEffect, TimeSpent, Certification, Badges, UserCourseDetails, TechTalkReport, FormatDate, useMemo, decode, saveAs, SelectedTech, CourseReportSkeleton, DownloadExcel } from "../../../UploadCourses/UploadCoursesImport";
import "./CourseReport.css";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "8.5rem",
    marginBottom: "0.7rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "10.625rem",
    overflowY: "auto",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const customStyle = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "10.5rem",
    marginTop: "0.37rem",
    marginBottom: "0.7rem",
    marginRight: "0.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: "10.625rem",
    overflowY: "auto",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2.5rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const additionalOptions = [
  { label: "Enrolled", value: "Enrolled" },
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
  { label: "Overdue", value: "Overdue" },
];

const CourseReport = () => {
  const [dataState, setDataState] = useState({
    TotalTimedata: [],
    CertificateAndBadge: [],
    orgCertificateAndBadge: [],
    BadgesData: [],
    orgBadgesData: [],
    EnrolledUsersData: [],
    orgEnrolledUsersData: [],
    TechTalkData: [],
    filteredTechTalkData: [],
    isEnrolledLoading: true,
    isCertificateBadgeLoading: true,
    isTechTalkLoading: true,
    isCourseReportLoading: true,
  });

  const [filters, setFilters] = useState({
    selectYear: [],
    selectTech: [],
    filterQuery: "",
    courseQuery: "",
    selectedProgress: [],
    buttonState: "Course",
  });

  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    resultsPerPage: 10,
  });

  const fileName = (dataState.EnrolledUsersData[0]?.firstName || "") + (dataState.EnrolledUsersData[0]?.lastName || "") + "_" + (dataState.EnrolledUsersData[0]?.email || "") + "_" + FormatDate(new Date()) + "_" + "CourseReport";

  const totalPaginationPages = filters.buttonState === "Course" ? Math.ceil(dataState.EnrolledUsersData?.length / paginationState.resultsPerPage) : Math.ceil(dataState.TechTalkData?.length / paginationState.resultsPerPage);
  const arrayStartIndex = (paginationState.currentPage - 1) * paginationState.resultsPerPage;
  const arrayEndIndex = arrayStartIndex + paginationState.resultsPerPage;
  const arrayCurrentResults = filters.buttonState === "Course" ? dataState.EnrolledUsersData?.slice(arrayStartIndex, arrayEndIndex) : dataState.TechTalkData?.slice(arrayStartIndex, arrayEndIndex);

  const storedUserId = sessionStorage.getItem("uId");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectTech = (val) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectTech: val.map((option) => option.value),
    }));
  };

  const applyFilter = () => {
    const filteredData = dataState.TechTalkData.filter((talk) => talk.title.toLowerCase().includes(filters.filterQuery.toLowerCase()));
    setDataState((prevData) => ({
      ...prevData,
      filteredTechTalkData: filteredData,
    }));
  };

  const handleYearChange = (val) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectYear: val.map((option) => option.value),
    }));
  };

  const startYear = 2000;
  const curYear = new Date().getFullYear();

  const yearOptions = [
    ...Array.from({ length: curYear - startYear + 1 }, (_, index) => ({
      value: `${curYear - index}`,
      label: `${curYear - index}`,
    })),
  ];

  useEffect(() => {
    applyFilter();
  }, [filters.filterQuery, dataState.TechTalkData]);

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchTotalTimeSpent = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getLearningStatsByUserId?userId=${storedUserId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDataState((prevData) => ({
          ...prevData,
          TotalTimedata: response.data.result,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchTotalCertificateAndBadges = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/getBadgesAndCertificateByUserId?userId=${storedUserId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDataState((prevData) => ({
          ...prevData,
          CertificateAndBadge: response?.data?.result?.mileStoneData,
          orgCertificateAndBadge: response?.data?.result?.mileStoneData,
          BadgesData: response.data?.result?.badgeResult,
          orgBadgesData: response.data?.result?.badgeResult,
          isCertificateBadgeLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };
  const totalEnrolledUsersData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/totalEnrolledUsers?userId=${storedUserId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setDataState((prevState) => ({
          ...prevState,
          EnrolledUsersData: response.data?.result,
          orgEnrolledUsersData: response.data?.result,
          isEnrolledLoading: false,
          isCourseReportLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const fetchTechTalkByUser = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getTechTalkByUserId?userId=${storedUserId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setDataState((prevState) => ({
          ...prevState,
          TechTalkData: response.data.result,
          isTechTalkLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleSelectProgress = (val) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedProgress: val.map((option) => option.value),
    }));
  };

  const handleAchievementFilters = () => {
    const getTechCertFilterItems = (items) => {
      const { selectTech } = filters;

      const allFiltersEmpty = selectTech?.length === 0;
      const filteredCertificates = items?.certificates?.filter((cert) => {
        if (allFiltersEmpty) return true;
        return cert.typeName === "Certificate" && selectTech.includes(cert.subCategoryName);
      });

      let certificateCount;
      if (allFiltersEmpty) {
        certificateCount = items?.certificateCount;
      } else {
        certificateCount = filteredCertificates ? filteredCertificates.length : 0;
      }

      return {
        ...items,
        certificates: allFiltersEmpty ? items?.certificates : filteredCertificates || [],
        certificateCount: certificateCount,
      };
    };

    const getTechBadgeFilterItems = (items) => {
      const { selectTech } = filters;

      const allFiltersEmpty = selectTech.length === 0;
      const filteredBadges = items?.badgeData?.filter((badge) => {
        if (allFiltersEmpty) return true;
        return selectTech.includes(badge.subCategoryName);
      });

      const totalBadgeCount = filteredBadges ? filteredBadges.reduce((total, badge) => total + badge.count, 0) : items?.totalBadgeCount;
      return {
        badgeData: allFiltersEmpty ? items?.badgeData : filteredBadges || [],
        totalBadgeCount: totalBadgeCount,
      };
    };

    const getYearCertFilterItems = (items) => {
      const { selectYear } = filters;

      const allFiltersEmpty = selectYear?.length === 0;
      const filteredCertificates = items.certificates?.filter((cert) => {
        const issuedYear = new Date(cert.issuedDate).getFullYear().toString();
        if (allFiltersEmpty) return true;
        return cert.typeName === "Certificate" && selectYear.includes(issuedYear);
      });

      let certificateCount;
      if (allFiltersEmpty) {
        certificateCount = items?.certificateCount;
      } else {
        certificateCount = filteredCertificates ? filteredCertificates.length : 0;
      }

      return {
        ...items,
        certificates: allFiltersEmpty ? items.certificates : filteredCertificates || [],
        certificateCount: certificateCount,
      };
    };

    const getYearBadgeFilterItems = (items) => {
      const { selectYear } = filters;

      const allFiltersEmpty = selectYear.length === 0;
      const filteredBadges = items.badgeData?.filter((badge) => {
        const issuedYear = new Date(badge.issuedDate).getFullYear().toString();
        if (allFiltersEmpty) return true;
        return selectYear.includes(issuedYear);
      });

      const totalBadgeCount = filteredBadges ? filteredBadges.reduce((total, badge) => total + badge.count, 0) : items.totalBadgeCount;
      return {
        badgeData: allFiltersEmpty ? items.badgeData : filteredBadges || [],
        totalBadgeCount: totalBadgeCount,
      };
    };

    const filterTechCertItem = getTechCertFilterItems(dataState.orgCertificateAndBadge);
    const filterTechBadgeItem = getTechBadgeFilterItems(dataState.orgBadgesData);

    const filterYearCertItem = getYearCertFilterItems(filterTechCertItem);
    const filterYearBadgeItem = getYearBadgeFilterItems(filterTechBadgeItem);

    setDataState({ ...dataState, CertificateAndBadge: filterYearCertItem, BadgesData: filterYearBadgeItem });
  };

  function convertSecondsToHoursAndMinutes(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    return { hours, minutes };
  }

  const { hours: totalHours, minutes: totalMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(dataState?.TotalTimedata?.totalTimeWorked || 0), [dataState?.TotalTimedata?.totalTimeWorked]);
  const { hours: currentWeekHours, minutes: currentWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(dataState?.TotalTimedata?.currentWeekTime || 0), [dataState?.TotalTimedata?.currentWeekTime]);
  const { hours: previousWeekHours, minutes: previousWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(dataState?.TotalTimedata?.previousWeekTime || 0), [dataState?.TotalTimedata?.previousWeekTime]);

  function combineAndExtractDataWithCertificates() {
    var extractedBadgeData = [];
    for (var i = 0; i < dataState?.BadgesData?.badgeData?.length; i++) {
      var originalBadgeObject = dataState.BadgesData.badgeData[i];
      var extractedBadgeObject = {
        Title: originalBadgeObject.subCategoryName,
        BadgeCount: originalBadgeObject.count,
        IssuedDate: FormatDate(originalBadgeObject.issuedDate),
      };
      extractedBadgeData.push(extractedBadgeObject);
    }

    var extractedTechTalkData = [];
    for (var j = 0; j < dataState?.TechTalkData?.length; j++) {
      var originalTechTalkObject = dataState?.TechTalkData[j];
      var firstName = originalTechTalkObject?.firstName || "";
      var lastName = originalTechTalkObject?.lastName || "";
      var extractedTechTalkObject = {
        Title: originalTechTalkObject?.title,
        StartDate: FormatDate(originalTechTalkObject?.startDate),
        SubCategory: originalTechTalkObject?.subCategoryName,
        InstructorName: firstName + " " + lastName,
      };
      extractedTechTalkData.push(extractedTechTalkObject);
    }

    var extractedCertificateData = [];
    for (var k = 0; k < dataState?.CertificateAndBadge?.certificates?.length; k++) {
      var originalCertificateObject = dataState?.CertificateAndBadge?.certificates[k];
      if (originalCertificateObject.typeName === "Certificate") {
        var extractedCertificateObject = {
          CertificateName: originalCertificateObject?.certificateName,
          Link: originalCertificateObject?.link,
          IssuedDate: FormatDate(originalCertificateObject?.issuedDate),
          ExpiryDate: FormatDate(originalCertificateObject?.expiryDate),
          levelName: originalCertificateObject?.levelName,
          SubCategoryName: originalCertificateObject?.subCategoryName,
        };
        extractedCertificateData.push(extractedCertificateObject);
      }
    }

    var extractedCourseData = [];
    for (var l = 0; l < dataState?.EnrolledUsersData[0]?.enrolledCourses.length; l++) {
      var originalCourseObject = dataState?.EnrolledUsersData[0]?.enrolledCourses[l];
      var extractedCourseObject = {
        CourseTitle: originalCourseObject?.courseTitle,
        StartDate: FormatDate(originalCourseObject?.startDate),
        EndDate: FormatDate(originalCourseObject?.endDate),
        Category: originalCourseObject?.categoryName,
        SubCategoryName: originalCourseObject?.subCategoryName,
        Duration: originalCourseObject?.duration,
        InstructorName: originalCourseObject?.instructorName,
        Graded: originalCourseObject?.gradeType,
        CourseStatus: originalCourseObject?.courseStatus,
      };
      extractedCourseData.push(extractedCourseObject);
    }

    var TotalTimeSpent = [];
    var extractedTimeObject = {
      TotalTime: totalHours + " " + "Hours" + " " + totalMinutes + " " + "Minutes",
      CurrentWeekTime: currentWeekHours,
      PreviousWeekTime: previousWeekHours,
      Trend: dataState?.TotalTimedata?.trendMessage,
    };
    TotalTimeSpent.push(extractedTimeObject);

    var resultObject = {
      BadgesDetails: extractedBadgeData,
      TechTalk: extractedTechTalkData,
      CertificateData: extractedCertificateData,
      CourseData: extractedCourseData,
      TotalWorkingTime: TotalTimeSpent,
    };

    return resultObject;
  }

  var combinedDataWithCertificates = combineAndExtractDataWithCertificates();

  const GenerateExcel = async () => {
    const uploadUrl = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/exportReport`;

    let formData = new FormData();
    formData.append("jsonString", JSON.stringify(combinedDataWithCertificates));
    formData.append("fileType", "xlsx");
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(uploadUrl, formData, { headers });

      if (response.status === 200) {
        DownloadExcel(response.data.result, fileName);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alert(`Data not Uploaded: \n${error}`);
    }
  };

  const handleCourseFilters = () => {
    const getSearchFilterItems = (items) => {
      const lowercaseQuery = filters.courseQuery.toLowerCase().trim();

      if (lowercaseQuery !== "") {
        return items
          .map((item) => ({
            ...item,
            enrolledCourses: item.enrolledCourses.filter((course) => course.courseTitle.toLowerCase().startsWith(lowercaseQuery)),
          }))
          .filter((item) => item.enrolledCourses.length > 0);
      }

      return items;
    };

    const getProgressFilterItems = (items) => {
      const { selectedProgress } = filters;

      if (selectedProgress.length !== 0) {
        const lowercaseProgress = selectedProgress.map((option) => option.toLowerCase());
        return items
          .map((item) => ({
            ...item,
            enrolledCourses: item.enrolledCourses.filter((course) => lowercaseProgress.includes(course.courseStatus.toLowerCase())),
          }))
          .filter((item) => item.enrolledCourses.length > 0);
      }

      return items;
    };

    const filterSearchItems = getSearchFilterItems(dataState.orgEnrolledUsersData);
    const filterProgressItems = getProgressFilterItems(filterSearchItems);

    setDataState({ ...dataState, EnrolledUsersData: filterProgressItems });
  };

  useEffect(() => {
    handleCourseFilters();
  }, [filters.courseQuery, filters.selectedProgress]);

  useEffect(() => {
    handleAchievementFilters();
  }, [filters.selectYear, filters.selectTech]);

  useEffect(() => {
    fetchTotalTimeSpent();
    fetchTotalCertificateAndBadges();
    totalEnrolledUsersData();
    fetchTechTalkByUser();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.removeItem("uId");
    };
    return () => {
      handleRouteChange();
    };
  }, [location.pathname]);

  const CheckboxOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
        <input type="checkbox" checked={isSelected} onChange={() => {}} />
        &nbsp;
        {children}
      </components.Option>
    );
  };

  return (
    <>
      <div style={{ marginBottom: "5rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="course-detailedrep-parent-wrapper">
        <div className="coursereport-child-wrapper">
          <div className="course-detailrep-header">
            <div className="course-detailrep-breadcrum">
              <BreadCrumbs />
            </div>
          </div>
          <div className="course-report-info-wrapper">
            <div className="d-flex justify-content-between">
              <div className="report-head ml-3">
                <span className="rep-detail-child">Report</span>
              </div>
              <div className="excel-sv">
                <div onClick={() => GenerateExcel()}>
                  <ExportExcel />
                </div>
              </div>
            </div>
            {dataState.isEnrolledLoading ? (
              <CourseReportSkeleton />
            ) : (
              <>
                <div className="course-detailrep-user-info">
                  <div className="course-info-detail">
                    <div className="d-flex gap-1">
                      <Usercircle />
                      <span>
                        {dataState.EnrolledUsersData[0]?.firstName || ""} {dataState.EnrolledUsersData[0]?.lastName || ""}
                      </span>
                    </div>
                    <div className="course-other-info">
                      <div className="course-other-Info-hide-for-mobile" id="Other-Info-Hide">
                        <div className="course-icon-pair">
                          <Mail />
                          <span>{dataState.EnrolledUsersData[0]?.email}</span>
                        </div>
                        <div className="course-det-dot" />
                        <div className="course-icon-pair">
                          <Call />
                          <span>{dataState.EnrolledUsersData[0]?.contactNumber}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <>
              <div className="report-child">
                <span className="course-detail-child course-head">Milestones</span>
                <div className="d-flex gap-2 mx-2">
                  <SelectedTech handleSelectTech={handleSelectTech} />
                  <Select
                    isMulti
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option: CheckboxOption,
                    }}
                    options={yearOptions}
                    menuShouldBlockScroll
                    placeholder="Select Year"
                    onChange={handleYearChange}
                  />
                </div>
              </div>
              <div className="course-detailrep-second-child">
                <div className=" course-detail-child spent-hours">
                  <div className="course-certi">
                    <Certification certificateData={dataState?.CertificateAndBadge} certLoadingState={dataState.isCertificateBadgeLoading} />
                  </div>
                </div>
                <div className="course-detail-child">
                  <div className="course-badge p-3">
                    <Badges certificateData={dataState?.BadgesData} badgeLoading={dataState.isCertificateBadgeLoading} />
                  </div>
                </div>
                <div className="course-detail-child" id="third-sub-child-of-second">
                  <div className="course-time">
                    <TimeSpent timeData={dataState.TotalTimedata} />
                  </div>
                </div>
              </div>
              <div className="ms-2">
                <div className="d-flex justify-content-between">
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <div className="course-report-cert-accordion-body d-flex justify-content-center">
                      <button
                        className={`w-50 border-0 ${filters.buttonState === "Course" ? `course-tech` : `tech-course`}`}
                        onClick={() =>
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            buttonState: "Course",
                          }))
                        }
                      >
                        <p className="certificate-text m-0 ms-2">Course</p>
                      </button>
                      <button
                        className={`w-50 border-0 ${filters?.buttonState === "TechTalk" ? `course-tech` : `tech-course`}`}
                        onClick={() =>
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            buttonState: "TechTalk",
                          }))
                        }
                      >
                        <p className="certificate-text m-0 ms-2">Tech Talk</p>
                      </button>
                    </div>
                  </div>
                  {filters.buttonState === "TechTalk" ? (
                    <div className="tech-search">
                      <div className="tech-search-icon" />
                      <input
                        type="text"
                        className="tech-talk-search"
                        placeholder="Search By Tech Talk Name"
                        value={filters.filterQuery}
                        onChange={(e) =>
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            filterQuery: e.target.value,
                          }))
                        }
                      />
                    </div>
                  ) : (
                    <div className="d-flex gap-1">
                      <div className="tech-search">
                        <div className="tech-search-icon" />
                        <input
                          type="text"
                          className="tech-talk-search"
                          placeholder="Search By Course Name"
                          value={filters.courseQuery}
                          onChange={(e) =>
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              courseQuery: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <Select
                        isMulti
                        styles={customStyle}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option: CheckboxOption,
                        }}
                        options={additionalOptions}
                        menuShouldBlockScroll
                        placeholder="Select Progress"
                        onChange={handleSelectProgress}
                      />
                    </div>
                  )}
                </div>
                {filters.buttonState === "Course" ? (
                  <>
                    <UserCourseDetails data={arrayCurrentResults[0]} isDetailed={true} isCourseReportLoading={dataState.isCourseReportLoading} />
                    <div className="d-flex justify-content-between align-items-center mb-3" style={{ height: "1rem" }}>
                      <div className="fw-bold" style={{ fontSize: "14px" }}>
                        Total Items: {dataState.EnrolledUsersData[0]?.enrolledCourses?.length}
                      </div>
                      <div className="d-flex justify-content-end t-items">
                        <div className="d-flex align-items-center">
                          <p className="me-2 mb-0 res-per-page">Results per page</p>
                          <select
                            className="form-select m-2 r-page"
                            value={paginationState.resultsPerPage}
                            onChange={(event) => {
                              setPaginationState((prevState) => ({
                                ...prevState,
                                resultsPerPage: event.target.value,
                                currentPage: 1,
                              }));
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </select>
                          <ul className="pagination mb-0">
                            <li className={`page-item me-1 ${paginationState.currentPage === 1 ? "page-item disabled" : ""}`}>
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState((prevState) => {
                                    if (prevState.currentPage !== 1) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage - 1,
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 1.5L1.5 7L7 12.5" stroke={paginationState.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                                </svg>
                              </button>
                            </li>
                            {Array.from({ length: totalPaginationPages }, (_, i) => i + 1)?.map((page) => (
                              <li key={page} className={`page-item me-1 ${page === paginationState.currentPage ? "active" : ""}`}>
                                <button
                                  className="page-link rounded pagination-styling fw-bold"
                                  onClick={() => {
                                    setPaginationState((prevState) => ({
                                      ...prevState,
                                      currentPage: page,
                                    }));
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  {page}
                                </button>
                              </li>
                            ))}
                            <li className={`page-item ${paginationState.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState((prevState) => {
                                    if (prevState.currentPage !== prevState.totalPaginationPages) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage + 1,
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 12.5L6.5 7L1 1.5" stroke={paginationState.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                                </svg>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <TechTalkReport TechTalkData={dataState.filteredTechTalkData} loadingState={dataState.isTechTalkLoading} />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="fw-bold" style={{ fontSize: "14px" }}>
                        Total Items: {dataState.filteredTechTalkData.length}
                      </div>
                      <div className="d-flex justify-content-end t-items">
                        <div className="d-flex align-items-center">
                          <p className="me-2 mb-0 res-per-page">Results per page</p>
                          <select
                            className="form-select m-2 r-page"
                            value={paginationState.resultsPerPage}
                            onChange={(event) => {
                              setPaginationState((prevState) => ({
                                ...prevState,
                                resultsPerPage: event.target.value,
                                currentPage: 1,
                              }));
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </select>
                          <ul className="pagination mb-0">
                            <li className={`page-item me-1 ${paginationState.currentPage === 1 ? "page-item disabled" : ""}`}>
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState((prevState) => {
                                    if (prevState.currentPage !== 1) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage - 1,
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7 1.5L1.5 7L7 12.5" stroke={paginationState.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                                </svg>
                              </button>
                            </li>
                            {Array.from({ length: totalPaginationPages }, (_, i) => i + 1)?.map((page) => (
                              <li key={page} className={`page-item me-1 ${page === paginationState.currentPage ? "active" : ""}`}>
                                <button
                                  className="page-link rounded pagination-styling fw-bold"
                                  onClick={() => {
                                    setPaginationState((prevState) => ({
                                      ...prevState,
                                      currentPage: page,
                                    }));
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  {page}
                                </button>
                              </li>
                            ))}
                            <li className={`page-item ${paginationState.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState((prevState) => {
                                    if (prevState.currentPage !== prevState.totalPaginationPages) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage + 1,
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 12.5L6.5 7L1 1.5" stroke={paginationState.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                                </svg>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseReport;
