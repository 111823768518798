import { Skeleton, UpcomingSessionsCircle } from "../../MentorImport";
import "react-loading-skeleton/dist/skeleton.css";
import "./TrainingCal.css";

const TrainingCalSkeleton = (props) => {
  const { id } = props;
  return (
    <div className="card cal-inner-card pt-0 pe-0">
      <div className="col d-flex justify-content-between w-100">
        <div className="pt-2 d-flex">
          <Skeleton className="sessionpic" />
          <Skeleton className="inner-cal-head fw-bold" width={150} height={10} />
        </div>
        <div className="d-flex p-0 justify-content-end">
          <Skeleton className="d-flex justify-content-center align-items-center training-today-session-skeleton" highlightColor={id == 1 ? "#2DC26B" : id == 2 ? "#FF8D06" : "#E03E2D"} />
        </div>
      </div>
      <div className="col d-flex justify-content-between w-100">
        <span className="session-margin-3">
          <Skeleton width={20} />
        </span>
        <span className="cal-date">
          <Skeleton width={100} />
        </span>
        <span className="session-margin-3">
          <Skeleton width={20} />
        </span>
        <span className="cal-time mx-2">
          <Skeleton width={100} />
        </span>
      </div>
      <div className="col d-flex w-100">
        <div className="attended-batch">
          <Skeleton width={90} />
          <div className="batch-list mt-1">
            <span className="cal-batch fw-bold">
              <Skeleton width={120} inline />
              <UpcomingSessionsCircle className="ms-2 me-2" />
              <Skeleton width={120} inline />
              <UpcomingSessionsCircle className="ms-2 me-2" />
              <Skeleton width={30} inline />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCalSkeleton;
