import { useEffect, useState, AlertInfo, useRef, Drag, Files, Video, Delete, CloudImage, CustomModal, SizeLimit, AdditionalInfoPptIcon, AdditionalInfoEbookIcon } from "../../UploadCourses/UploadCoursesImport";

const Videos = (props) => {
  const { uploadData, setUploadData } = props;
  const fileInputRef = useRef([]);

  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "mp4":
        return <Video />;
      case "pptx":
        return <AdditionalInfoPptIcon />;
      case "ppt":
        return <AdditionalInfoPptIcon />;
      case "pdf":
        return <AdditionalInfoEbookIcon />;
      default:
        return <Files />;
    }
  };
  const [ratingData, setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };
  const countFilesInModules = () => {
    let totalMP4 = 0;
    let totalPdf = 0;
    let totalPPT = 0;

    uploadData.selectedFiles.forEach((file) => {
      if (!file.isDeleted) {
        switch (file.file.type) {
          case "video/mp4":
            totalMP4++;
            break;
          case "application/pdf":
            totalPdf++;
            break;
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            totalPPT++;
            break;
          default:
            break;
        }
      }
    });
    return { totalMP4, totalPdf, totalPPT };
  };

  const countExistingFiles = () => {
    let totalMP4 = 0;
    let totalPdf = 0;
    let totalPPT = 0;

    uploadData?.selectedFiles?.forEach((file) => {
      const extension = file?.file.name?.split('.').pop().toLowerCase();
      if (!file.isDeleted) {
        if (extension === 'pdf') {
          totalPdf++;
        } else if (extension === 'ppt' || extension === 'pptx') {
          totalPPT++;
        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
          totalMP4++;
        }
      }
    });
    return { totalMP4, totalPdf, totalPPT };
  };

  const updateFileCounts = () => {
    const { totalMP4, totalPdf, totalPPT } = uploadData.id === null ? countFilesInModules() : countExistingFiles();
    setUploadData((prevData) => ({
      ...prevData,
      totalVideos: totalMP4,
      totalPdf: totalPdf,
      pdfPages: totalPdf === 0 ? "0" : prevData.pdfPages,
      totalPPT: totalPPT,
      PPTPages: totalPPT === 0 ? "0" : prevData.PPTPages,
    }));
  };

  const handleFileChange = (files) => {
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "video/mp4",
    ];

    const maxFileSizeInBytes = 1024 * 1024 * 1024; // 400 MB

    const invalidFiles = Array.from(files).filter((file) => {
      // Check if file type is allowed
      if (!allowedFileTypes.includes(file.type)) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Please select PDF, PPT, or MP4 files only",
          icon: <AlertInfo />,
        }));
        return true;
      }
      // Check if file size exceeds the limit
      if (file.size >= maxFileSizeInBytes) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "File size should be less than 1GB",
          icon: <AlertInfo />,
        }));
        return true;
      }
      return false;
    });

    if (invalidFiles.length > 0) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "File size should be less than 1GB",
        icon: <AlertInfo />,
      }));
      return;
    }

    const filteredFiles = Array.from(files).filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    const fileWithSpaces = filteredFiles.find((file) => file.name.includes(" "));
    if (fileWithSpaces) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "File name should not contain spaces",
        icon: <AlertInfo />,
      }));
      return;
    }
    setUploadData((prevData) => {
      const totalExistingFile = prevData.selectedFiles.filter((file) => file.isAdded && !file.isDeleted);
      const totalExistingFileSize = totalExistingFile.reduce((acc, file) => acc + file.file.size,0);
      const totalNewSize = filteredFiles.reduce((acc, file) => acc + file.size, 0);
      if (totalExistingFileSize + totalNewSize > maxFileSizeInBytes) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Total file size should not exceed 1GB",
          icon: <AlertInfo />,
        }));
        return prevData;
      }

      let isDuplicate = filteredFiles.some((newFile) => {
        return !newFile.isDeleted && prevData.selectedFiles.some((existingFile) => !existingFile.isDeleted && existingFile.file.name === newFile.name);
      });

      if (isDuplicate) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Files with the same names already exist. Please choose different files.",
          icon: <AlertInfo />,
        }));
        return prevData;
      }
      const newFiles = filteredFiles.map((file) => ({
        file: file,
        isAdded: true,
        isDeleted: false,
      }));
      return {
        ...prevData,
        selectedFiles: [...prevData.selectedFiles, ...newFiles],
        isSavedClicked: false,
      };
    });

    filteredFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (file.type === "video/mp4") {
          const video = document.createElement("video");
          video.src = URL.createObjectURL(file);
        }
      };
      reader.onerror = (e) => {
        console.error("Error reading the file:", e.target.error);
      };
      reader.readAsText(file);
      return reader;
    });
  };

  const handleRemoveFile = (fileIndex) => {
    fileInputRef.current.value = "";
    if (uploadData.id == null) {    // case add 
      setUploadData((prevData) => {
        const updatedSelectedFiles = prevData.selectedFiles.filter((fileInfo, index) => index !== fileIndex);
        return {
          ...prevData,
          selectedFiles: updatedSelectedFiles,
          isSavedClicked: false,
        };
      });
    } else {  // case edit 
      setUploadData((prevData) => {
        const updatedSelectedFiles = prevData.selectedFiles.map((file, index) => {
          if (index === fileIndex) {
            return {
              ...file,
              isDeleted: true,
              isAdded: false
            };
          }
          return file;
        });
        return {
          ...prevData,
          selectedFiles: updatedSelectedFiles,
          isSavedClicked: false,
        };
      });
    }
    updateFileCounts();
  };

  const handleBrowseClick = () => {
    fileInputRef?.current.click();
  };
  const handleDragStart = (e, currentIndex) => {
    e.dataTransfer.setData("currentIndex", currentIndex);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDropFile = (e, currentIndex) => {
    e.preventDefault();
    const prevFileIndex = e.dataTransfer.getData("currentIndex");
    const indexClicked = parseInt(prevFileIndex);
    const totalFile = [...uploadData.selectedFiles];
    const draggedFile = totalFile.splice(indexClicked, 1)[0];
    totalFile.splice(currentIndex, 0, draggedFile);

    const updatedFiles = totalFile.map((file, index) => ({
      ...file,
      orderNumber: index + 1// Assuming order starts from 1
    }));

    setUploadData({
      ...uploadData,
      selectedFiles: updatedFiles,
    });
  };
  const handleSaveClick = (e) => {
    e.preventDefault();
    updateFileCounts();
    setUploadData({
      ...uploadData,
      isSavedClicked: true,
    });
    if (uploadData.totalVideos === 0) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "Please upload atleast one video",
        icon: <AlertInfo />,
      }));
    }
  };
  useEffect(() => {
    updateFileCounts();
  }, [uploadData.selectedFiles]);

  return (
    <>
      <div className="container">
        <div className="row">
          <p className="p-0">Add File</p>
        </div>
        <div className="row border rounded">
          <div className="col-3">
            <div className="upload-modules-csv align-items-center justify-content-center mt-3 mb-3">
              <div className=" mt-2">
                <CloudImage />
              </div>
              <div className="mb-2">
                <span className="drag-drop-text d-flex justify-content-center fw-bold">Drag and drop here</span>
              </div>
              <div className="mb-2">
                <input className="d-none" type="file" ref={fileInputRef} onChange={(e) => handleFileChange(e.target.files)} accept=".pdf,.ppt,.pptx,.mp4" multiple />
                <button type="button" onClick={handleBrowseClick} className="modle-file-upload-button">
                  Select File
                </button>
              </div>
              <div className="mb-2">
                <div className="file-size-limit gap-2">
                  <SizeLimit />
                  <p className="limit-text">File Size Limit 400 MB</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9">
            {<p className="mt-3">File Name</p>}
            {uploadData?.selectedFiles?.map((file, fileIndex) => (
              !file.isDeleted && (
                <>
                  <div className="d-flex align-items-center ps-1 ast-search-wrapper" key={fileIndex}>
                    <div className="progress-indicator-status mx-2" draggable="true" onDragStart={(e) => handleDragStart(e, fileIndex)} onDrop={(e) => handleDropFile(e, fileIndex)} onDragOver={handleDragOver}>
                      <div className="d-flex align-items-center selected-module-files">
                        <div draggable="true" className="mx-2">
                          <Drag />
                        </div>
                        {getFileIcon(file?.file?.name)}
                        <div className="mx-1" data-toggle="tooltip" data-placement="top" title={file?.file?.name}>
                          {file?.file?.name.length > 50 ? `${file?.file?.name.substring(0, 40)}...` : file?.file?.name}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleRemoveFile(fileIndex);
                      }}
                    >
                      <Delete />
                    </div>
                  </div>
                </>
              )))}
            {uploadData.selectedFiles.filter(x => x.isDeleted == false).length !== 0 && (
              <div className="mt-3 mb-3">
                <button
                  type="button"
                  className="btn module-save-button"
                  disabled={
                    !(uploadData.selectedFiles.filter(x => x.isDeleted == false).length > 0)
                  }
                  onClick={(e) => handleSaveClick(e)}
                >
                  <span className="save-text">Save</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </>
  );
};

export default Videos;
