import "react-loading-skeleton/dist/skeleton.css";
import "../Status/status.css";
import { Skeleton, React, TotalIcon, ActiveIcon, InActiveIcon, OnProject } from "./../../MentorImport";

const Status = (props) => {
  const { data, isLoading } = props;
  const addLeadingZero = (count) => {
    return count < 10 ? `0${count}` : count;
  };

  return (
    <>
      <p className="about-link mb-2">Quick Stats</p>
      <div className="row first-row">
        <div className="col-6">
          {isLoading ? (
            <Skeleton width={190} height={90.72} highlightColor="#fff" className="row-status" />
          ) : (
            <div className="four-cards  pb-0">
              <div className="status-dash">
                <TotalIcon />
                <div className="count-info">
                  <p className="count">{addLeadingZero(data?.totalUsers)}</p>
                  <p className="status status-row">Total</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-6">
          {isLoading ? (
            <Skeleton width={190} height={90.72} highlightColor="#fff" className="status-skel2" />
          ) : (
            <div className="four-cards pb-0">
              <div className="status-dash">
                <ActiveIcon />
                <div className="count-info">
                  <p className="count">{addLeadingZero(data?.totalActiveUsers)}</p>
                  <p className="status d-flex justify-content-center status-dash2">Active</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row second-row">
        <div className="col-6">
          {isLoading ? (
            <Skeleton width={190} height={90.72} highlightColor="#fff" className="status-row2-skel" />
          ) : (
            <div className="four-cards pb-0">
              <div className="status-four-card">
                <InActiveIcon />
                <div className="count-info">
                  <p className="count">{addLeadingZero(data?.totalInactiveUsers)}</p>
                  <p className="status d-flex justify-content-center status-count">Inactive</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-6">
          {isLoading ? (
            <Skeleton width={190} height={90.72} highlightColor="#fff" className="status-on-proj" />
          ) : (
            <div className="four-cards pb-0 ">
              <div className="status-f-card">
                <OnProject />
                <div className="count-info">
                  <p className="count">{addLeadingZero(data?.totalUserOnproject)}</p>
                  <p className="status d-flex justify-content-center course-proj">On Project</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Status;
