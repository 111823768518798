import React from "react";
import EmptyTable from "../../../Assets/Frame 7617DailyUpdateEmpty.svg"
const EmptyGradeAssignmentTable = () => {
  return (
    <div className="container-fluid h-100 my-2">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-6 text-center">
            <img src={EmptyTable} alt=""/>
            <p className="mt-4 mb-2 fw-bold">No Submissions Yet !</p>
            <p>No Assignments submitted for grading right now.</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyGradeAssignmentTable;
