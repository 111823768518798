import { Header, SideNav, handleApiError, useState, useEffect, useLocation, useNavigate, BreadCrumbs, Dot, Video, AssignmentIcon, PptIcon, MilestoneIcon, DarkDot, decryptData, axios, CustomModal, EnrollIcon, InfoVector, SuccessfullVector, QuizOutline, ReactModal, Form, PDFIcon } from "../../UserImport";
import "./CourseDetailView.css";
import CourseDetailViewSkeleton from "./CourseDetailViewSkeleton";
const CourseDetailview = () => {
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState();
  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    successOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
    cancelText: "",
    readMoreModal: false,
    selectedItems: null,
  });
  const storedCourseId = sessionStorage.getItem("courseId");
  const navigate = useNavigate();
  const location = useLocation();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchCourseDetail = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getById?courseId=${storedCourseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setCourseDetails(response.data.result);
        setLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const StartCourse = async (storeCourseId) => {
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/startCourse?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("courseId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchCourseDetail();
  }, []);


  const countQuizzes = (modules) => {
    let quizCount = 0;
    modules?.forEach((module) => {
      if (module?.moduleContent) {
        module?.moduleContent?.forEach((content) => {
          if (content?.isQuiz) {
            quizCount++;
          }
        });
      }
    });
    return quizCount;
  };
  const handleClose = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      successOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
      cancelText: "",
    }));
  };
  const handleNavigate = () => {
    if (isOpen.modalType === "confirm" || isOpen.modalType === "info") {
      StartCourse(storedCourseId);
    }
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      successOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
      cancelText: "",
    }));
  };
  const handleConfirm = () => {
    handleAssignCourse();
  };
  const handleAssignCourse = async () => {
    setIsOpen((prev) => ({
      ...prev,
      modalType: "loader"
    }))
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/manageEnrollment`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      courseId: storedCourseId,
      userId: [parsedObject["decoded"]["Id"]],
      assignedById: parsedObject["decoded"]["Id"],
      title: courseDetails.title,
      description: courseDetails.description,
      isAssigned: true,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 200) {
        setIsOpen((prev) => ({
          ...prev,
          isModalOpen: false,
          successOpen: true,
          modalType: "confirm",
          modalTitle: courseDetails.title,
          modalMessage: "Welcome to the course. You can now access the course materials",
          icon: <SuccessfullVector />,
          cancelText: "Start Learning",
        }));
      } else if (response.data.success === 204) {
        setIsOpen((prev) => ({
          ...prev,
          isModalOpen: false,
          successOpen: true,
          modalType: "confirm",
          modalTitle: courseDetails.title,
          modalMessage: "Already enrolled in the course. You can access the course materials",
          icon: <InfoVector />,
          cancelText: "Go to Course",
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  const limitDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    } else {
      return description;
    }
  };
  const handleReadMore = (item) => {
    setIsOpen((prevState) => ({
      ...prevState,
      selectedItems: item,
      readMoreModal: true,
    }));
  };

  return (
    <>
      <div style={{ marginBottom: "3.5rem" }}>
        <Header />
        <SideNav />
      </div>
      {loading ? ( // Show skeleton loading if data is still loading
        <div className="skeleton-loading">
          <CourseDetailViewSkeleton />
        </div>
      ) : (
        <>
          {courseDetails && (
            <div>
              <div className="container p-0">
                <div className="row course-detail-bg-header">
                  <div className="mt-2 ms-4 pt-1 course-detail-breadcrumb">
                    <BreadCrumbs darkBg={true} />
                  </div>
                  <div className="row mt-4">
                    <div className="col-2 d-flex justify-content-center">
                      <div className="course-detail-image-box">
                        <img src={courseDetails.thumbnailUri} width={144} height={144} alt={courseDetails.title} />
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <div className="col-12 d-flex gap-1">
                        <p className="mb-1 course-detail-heading-level">{courseDetails.levelName}</p>
                        <div className="mb-1 d-flex align-items-center mx-1">
                          <Dot />
                        </div>
                        <p className="mb-1 course-detail-heading-level">{courseDetails.gradeType}</p>
                      </div>
                      <div className="col-12">
                        <p className="mb-1 mt-1 course-detail-heading-name" title={courseDetails.title}>
                          {limitDescription(courseDetails.title, 50)}
                        </p>
                      </div>
                      <div className="col-12">
                        <p className="mb-1 mt-2 course-detail-heading-info" title={courseDetails.description}>
                          {" "}
                          {limitDescription(courseDetails.description, 200)}
                          {courseDetails.description.length > 200 && (
                            <span
                              className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer"
                              onClick={() => {
                                handleReadMore(courseDetails);
                              }}
                            >
                              Read more
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="col-12">
                        {courseDetails.tags.slice(0, 6).map((tag, tagIndex) => (
                          <span key={tagIndex} className="badge tech-badge">
                            {tag.techName}{" "}
                          </span>
                        ))}
                        {courseDetails.tags.length > 6 && (
                          <span className="badge tech-badge" data-toggle="tooltip" data-placement="top" title={`${courseDetails.tags.slice(6, courseDetails.tags.length).map((tag, tagIndex) => {
                            return tag.techName + " ";
                          })}`}>
                            +{courseDetails.tags.slice(6).length}
                          </span>
                        )}
                      </div>

                      <div className="mt-2">
                        <button
                          className="enroll-course-button"
                          onClick={() => {
                            setIsOpen((prevState) => ({
                              ...prevState,
                              isModalOpen: true,
                              modalType: "confirm"
                            }));
                          }}
                        >
                          Enroll Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row course-detail-bg-body">
                  <div className="col-8 p-0">
                    <div className="card course-detail-level-card mb-4">
                      <div className="col-5">
                        <p className="mb-0 course-level-text">Level</p>
                        <p className="mb-0 course-level-data">{courseDetails.levelName}</p>
                      </div>
                      <div class="vl col-1 me-3"></div>
                      <div className="col-5">
                        <p className="mb-0 course-level-text">Duration</p>
                        <p className="mb-0 course-level-data">{courseDetails.courseCompletionTime}</p>
                      </div>
                    </div>
                    <p className="course-detail-module-card-head ">What you will learn</p>
                    <div className="card course-detail-module-card">
                      {courseDetails &&
                        courseDetails.modules
                          ?.sort((a, b) => a.orderNumber - b.orderNumber)
                          .map((module, index) => (
                            <div key={index} className="col-12 border p-2 ps-3">
                              <div className="col-12 d-flex justify-content-start">
                                <p className="mb-2 fw-bold">{module.name}</p>
                              </div>
                              <div className="col-12 d-flex gap-1">
                                <p className="mb-1 course-detail-module-detail">Module {module.orderNumber}</p>
                                <div className="mb-1 d-flex align-items-center mx-1">
                                  <DarkDot />
                                </div>
                                <p className="mb-1 course-detail-module-detail">{module.moduleContent.length} Content</p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card course-detail-card">
                      <div>
                        <p className="course-detail-module-card-head ms-2">This course includes</p>
                        <div className="d-flex flex-column border p-3">
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <Video className="course-detail-video-icon" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{courseDetails.totalVideoCount} Videos</p>
                          </div>
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <PDFIcon className="course-detail-pdf-icon" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{courseDetails.totalPdfCount} Pdf</p>
                          </div>
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <PptIcon className="course-detail-video-icon mb-1" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{courseDetails.totalPptCount} PPT’s</p>
                          </div>
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <QuizOutline className="course-detail-video-icon mb-1" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{countQuizzes(courseDetails.modules)} Quizzes</p>
                          </div>
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <AssignmentIcon className="course-detail-video-icon mb-1 ms-1" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{courseDetails.assignments[0].assignmentAttachment.length} Assignment</p>
                          </div>
                          <div className="mb-3 d-flex gap-1">
                            <span className="d-flex align-items-center svg-box">
                              <MilestoneIcon className="mb-1" />
                            </span>
                            <p className="mb-0 course-detail-content d-flex align-items-center">{courseDetails?.courseCompletionPoints} Milestones Points on Completion</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="course-detail-module-card-head ms-2">About The Instructor</p>
                        <div className="d-flex flex-row p-3">
                          <div className="image-wrapper1">
                            <div className="cert-image-box1">
                              <div className="d-flex justify-content-center noMentor-img">
                                <span className="initials">
                                  {courseDetails.instructorList[0].firstName
                                    .split(" ")
                                    .map((name) => name.charAt(0).toUpperCase())
                                    .join("")}
                                  {courseDetails.instructorList[0].lastName
                                    .split(" ")
                                    .map((name) => name.charAt(0).toUpperCase())
                                    .join("")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-wrapper1">
                            <p className="m-0">
                              <b>
                                {courseDetails.instructorList[0].firstName} {courseDetails.instructorList[0].lastName}
                              </b>
                            </p>

                            <p className="m-0 pos-wrapper">{courseDetails.instructorList[0].designation}</p>
                          </div>
                        </div>
                        <div className="p-3">
                          <p>{courseDetails.instructorList[0].about}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isOpen.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: <EnrollIcon />,
            show: isOpen.isModalOpen,
            type: isOpen.modalType,
            title: "Enroll Course",
            message: "Are you excited about learning new things? Just enroll and start a journey to discover and understand new technologies.",
            onCancel: handleClose,
            onConfirm: handleConfirm,
            cancelText: "No, Cancel",
            confirmText: "Yes, Enroll",
          }}
        />
      )}
      {isOpen.successOpen && (
        <CustomModal
          modalProps={{
            icon: isOpen.icon,
            show: isOpen.successOpen,
            type: isOpen.modalType,
            title: isOpen.modalTitle,
            message: isOpen.modalMessage,
            onCancel: handleClose,
            onConfirm: handleNavigate,
            cancelText: "Cancel",
            confirmText: "Start Learning",
          }}
        />
      )}
      <ReactModal
        size="lg"
        dialogClassName="modal-90w"
        centered
        show={isOpen.readMoreModal}
        onHide={() =>
          setIsOpen((prevState) => ({
            ...prevState,
            readMoreModal: false,
          }))
        }
      >
        <ReactModal.Header
          closeButton
          style={{
            borderBottom: "0.063rem",
          }}
        >
          <ReactModal.Title className="fw-bold" style={{ fontSize: "1.1rem" }}>
            Course Info
          </ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body>
          <p>
            <span className="fw-bold">Title :</span> <span className="opacity-75">{isOpen.selectedItems && isOpen.selectedItems.title}</span>
          </p>

          <Form.Group>
            <Form.Label>
              <span className="fw-bold">Description : </span>
            </Form.Label>
            <Form.Control className="opacity-75" disabled as="textarea" style={{ fontSize: "0.813rem" }} defaultValue={isOpen.selectedItems && isOpen.selectedItems.description} rows={4} cols={60}></Form.Control>
          </Form.Group>
        </ReactModal.Body>
      </ReactModal>
    </>
  );
};

export default CourseDetailview;
