import CourseFilter from "./Course/CourseFilter";
import TechTalkFilter from "./TechTalk/TechTalkFilter";
import ReportFilter from "./Report/ReportFilter";
import UserCourseFilter from "./UserCourses/UserCourseFilter";

const FilterBase = (props) => {
  const { filterType, data, filterState, setFilterState, onApplyFilters } = props;
  let filterComponent;

  switch (filterType) {
    case "Course":
      filterComponent = <CourseFilter data={data} filterState={filterState} setFilterState={setFilterState} onApplyFilters={onApplyFilters} />;
      break;
    case "TechTalk":
      filterComponent = <TechTalkFilter />;
      break;
    case "Report":
      filterComponent = <ReportFilter data={data} filterState={filterState} setFilterState={setFilterState} onApplyFilters={onApplyFilters} />;
      break;
    case "UserCourses":
      filterComponent = <UserCourseFilter data={data} filterState={filterState} setFilterState={setFilterState} onApplyFilters={onApplyFilters} />;
      break;
    default:
      filterComponent = null;
  }

  return <div>{filterComponent}</div>;
};

export default FilterBase;
