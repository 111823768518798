import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import { InfinitySpin } from "react-loader-spinner";
const CustomModal = ({ modalProps }) => {
  //  EXAMPLE FOR SENDING PROPS IN MODAL
  //  modalProps={{
  //   icon: YOUR SVG,
  //   show: BOOL,
  //   type: "",     //Types of Modal :- "success" , "info" , "error", "delete" , "confirm","loader"
  //   title: "",
  //   message: "",
  //   onCancel: YOUR FUNCTION,
  //   onConfirm: YOUR FUNCTION,
  //   cancelText: TEXT FOR CANCEL BUTTON,
  //   confirmText: TEXT FOR CONFIRM BUTTON
  //  }}
  const { show, type, title, message, onCancel, onConfirm, cancelText, confirmText, successText, icon: Icon } = modalProps;
  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  return (
    <>
      <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false} centered>
        {type === "loader" ? <Modal.Header className="border-bottom-0"></Modal.Header> : <Modal.Header className="border-bottom-0" closeButton></Modal.Header>}
        <Modal.Body className="d-flex justify-content-center flex-column align-items-center pt-1">
          {Icon}
          <Modal.Title id="example-modal-sizes-title-md" className=" fw-bold py-3 text-center">
            {title}
          </Modal.Title>
          <div className="text-center">{message}</div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center border-top-0">
          {type === "loader" ? (
            <div className="row d-flex justify-content-center draft-loader">
              <InfinitySpin className="d-flex align-items-center justify-content-center loader-svg " color="#ffc107" />
            </div>
          ) : type === "success" || type === "error" || type === "info" ? (
            <button type="button" className="taskAddedClass me-1 btn-primary" onClick={handleCancel}>
              {successText && successText !== "" ? successText : "Close"}
            </button>
          ) : (
            <>
              <button type="button" className="pp-no-btn me-1" onClick={handleCancel}>
                {cancelText}
              </button>
              {type === "delete" ? (
                <button type="button" className="pp-yes-btn ms-1" onClick={handleConfirm}>
                  {confirmText}
                </button>
              ) : (
                <button type="button" className="pp-proceed-btn ms-1" onClick={handleConfirm}>
                  {confirmText}
                </button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
