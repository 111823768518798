import { ArrowForwardIcon, Skeleton } from "../../UploadCourses/UploadCoursesImport";

const TotalCoursesSkeleton = () => {
  return (
    <div className="col-12 card total-courses-card mb-3">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex pointer">
            <p className="mb-0 me-1 total-courses-head">Courses Library</p>
            <span className="d-flex align-items-center">
              <ArrowForwardIcon />
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between">
          <div className="mb-0 ">
            <Skeleton height={20} width={50} />
            <p className="course-content-text mb-0 d-flex justify-content-center">Videos</p>
          </div>
          <div className="mb-0 ">
            <Skeleton height={20} width={50} />
            <p className="course-content-text mb-0 d-flex justify-content-center">Powerpoint</p>
          </div>
          <div className="mb-0 ">
            <Skeleton height={20} width={50} />
            <p className="course-content-text mb-0 d-flex justify-content-center">E-Books</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCoursesSkeleton;
