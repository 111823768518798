import { useState, useEffect, SideNav, Header, handleApiError, axios, decryptData, useNavigate } from "../UserImport";
import ViewAllCourses from "../LmsDashBoard/ViewAllCourses/ViewAllCourses"

const LmsEnrolledCourses = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
const [isLoading,setIsLoading]= useState(true);

  const totalEnrolledUsersData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    var userId = parsedObject["decoded"]["Id"];

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/totalEnrolledUsers?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setData(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    totalEnrolledUsersData();
  }, []);

  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="vh-100 ms-5 overflow-x-hidden">
        <ViewAllCourses
          viewAllProp={{
            title: "Enrolled Courses",
            resumeData: data[0]?.enrolledCourses,
            isCourse: true,
            componentType:"Resume",
            loadingState:isLoading,
            totalCoursesState: undefined,
            setTotalCoursesState: undefined
          }}
        />
      </div>
    </>
  );
};

export default LmsEnrolledCourses;
