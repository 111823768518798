import { ArrowForwardIcon, Skeleton, VideoPlay, useNavigate } from "../../UploadCourses/UploadCoursesImport";

const TechTalkSessionSkeleton = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="col-12">
        <div className="d-flex m-0 w-100">
          <div
            className="d-flex gap-1"
          >
            <p className="d-flex mb-0  align-items-center total-courses-head">Tech Talk</p>
            <span className="d-flex align-items-center mx-1">
              <ArrowForwardIcon />
            </span>
            <span>
              <VideoPlay />
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Skeleton height={20} width={140} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TechTalkSessionSkeleton;
