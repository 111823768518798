import AddNewIdea from "./Idea/AddNewIdea";
import React, { useEffect, useState, useContext } from "react";
import AddProject from "./Project/AddProject";
import "./ProjectComponent.css";
import "./Idea/AddNewIdea.css";
import "./Project/AddProject.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../Context/Context";
import { decryptData } from "../../UserImport"

const ProjectComponent = () => {
  const navigate = useNavigate();
  const { setIdea, setProject } = useContext(UserContext);
  const [taskVersion, setTaskVersion] = useState(0);
  const [pActive, setPActive] = useState(true);
  const [loading, setLoading] = useState({
      projectLoading : true,
      ideaLoading :true
  });

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

  const MyIdeaComponent = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL_DOTNET +
        `/ProjectIdea/getByUserId`,
        {
          headers: {
            Authorization: `Bearer ${parsedObject["token"]}`,
          },
        }
      );
      setIdea(response.data.result);
      setLoading((prevState) => ({
        ...prevState,
        ideaLoading : false
      }));
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.status === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.status === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.status === 404) {
        navigate("/error/statusCode=404");
      }
    }
  };
  const ProjectApi = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL_DOTNET + `/project/getByUserId`,
        {
          headers: {
            Authorization: `Bearer ${parsedObject["token"]}`,
          },
        }
      );

      setProject(response.data.result);
      setLoading((prevState) => ({
        ...prevState,
        projectLoading : false
      }));
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.status === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.status === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.status === 404) {
        navigate("/error/statusCode=404");
      }
    }
  };
  useEffect(() => {
    ProjectApi();
    MyIdeaComponent();
  }, [taskVersion]);
  return (
    <>
      <div className="card whole-card-wrapper px-0">
        <div className="border-bottom">
          <div className="card-title dtt-hfs-project m-0 d-flex  d-flex justify-content-center align-item-center">
            <div
              className={"project-idea-btn " + (pActive ? " p-active" : "")}
              onClick={() => {
                setPActive(true);
              }}
            >
              <button className="btn-1 p-0">My Idea</button>
            </div>
            <div
              className={"project-btn" + (pActive ? " " : " p-active")}
              onClick={() => {
                setPActive(false);
              }}
            >
              <button className="btn-2 p-0">Project</button>
            </div>
          </div>
        </div>
        {pActive ? <AddNewIdea taskVersion={taskVersion} setTaskVersion={setTaskVersion} loading={loading.ideaLoading}/> : <AddProject taskVersion={taskVersion} setTaskVersion={setTaskVersion} loading={loading.projectLoading}/>}
      </div>
    </>
  );
};
export default ProjectComponent;
