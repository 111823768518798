import "./Modals.css";
import { BreadCrumbs, useEffect, useState, SideNav, axios, useNavigate, Header, ManageSkillTestSkeleton, Confirmation, EmptySkillSet, DeleteStroke, VectorAdd, decryptData, handleApiError, AddNewSkillTest } from "../AdminImport";

const formatDate = (timestamp) => {
  const dateObj = new Date(timestamp);
  const formattedTime = `${(dateObj.getHours() % 12 || 12).toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')} ${dateObj.getHours() >= 12 ? 'PM' : 'AM'}`;
  return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}-${dateObj.getFullYear()} ${formattedTime}`;
};

const ManageSkillTest = () => {
  const [dataState, setDataState] = useState({
    filterTestData: [],
    originalTestData: [],
    isLoading: true,
    levelType: "",
    searchInnerValue: "",
    skillLevel: [],
  });

  const [deleteState, setDeleteState] = useState({
    showComponent: false,
    showConfirm: false,
    confirmId: -1,
  });

  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    resultsPerPage: 10,
  });

  const [selectedBatches, setSelectedBatches] = useState([]);

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchExamList = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Exam/examDetails`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDataState((prevData) => ({
          ...prevData,
          filterTestData: response.data.result,
          originalTestData: response.data.result,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDataState((prevData) => ({
          ...prevData,
          skillLevel: response.data.result,
          loading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  }

  const totalPaginationPages = Math.ceil(dataState.filterTestData?.length / paginationState.resultsPerPage);
  const arrayStartIndex = (paginationState.currentPage - 1) * paginationState.resultsPerPage;
  const arrayEndIndex = arrayStartIndex + paginationState.resultsPerPage;
  const arrayCurrentResults = dataState.filterTestData?.slice(arrayStartIndex, arrayEndIndex);

  const handleFiltersChange = () => {
    const getFilterItems = (items, searchValue) => {
      if (searchValue) {
        return items?.filter((item) =>
          item.techName.toLowerCase().startsWith(searchValue.toLowerCase())
        );
      }
      return items;
    };

    const filterDropDown = (items, dropDownValue) => {
      if (dropDownValue && dropDownValue !== "Select Level") {
        return items?.filter((item) => item.levelName === dropDownValue);
      }

      return items;
    };

    const filterItemsDropDown = filterDropDown(dataState.originalTestData, dataState.levelType);
    const filterItems = getFilterItems(filterItemsDropDown, dataState.searchInnerValue);
    setDataState(prevState => ({
      ...prevState,
      filterTestData: filterItems
    }));
  };

  useEffect(() => {
    handleFiltersChange();
  }, [dataState.searchInnerValue, dataState.levelType]);

  useEffect(() => {
    fetchExamList();
    fetchLevel();
  }, []);

  return (
    <div className="ManageSkill">
      {deleteState.showConfirm && (
        <Confirmation
          handleCancel={() => {
            setDeleteState(prevState => ({
              ...prevState,
              confirmId: -1,
              showConfirm: false
            }));
          }}
          id={deleteState.confirmId}
          handleDel={(id) => {
            setDataState(prevState => ({
              ...prevState,
              filterTestData: prevState.filterTestData.filter(item => item.examId !== id)
            }));
          }}
          confirmationValue="manageskill"
          fetchExamList={fetchExamList}
        />
      )}
      <div className="select-batches">
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div >
      <div className="manage-skill-test-container d-flex flex-column ms-4">

        <div className="row mb-2">
          <div className="col-12">
            <div>
              <BreadCrumbs dashboardLink={"/admin/sde/dashboard"} />
            </div>
          </div>
        </div>
        <div className="m-container">
          <div>
            <div className="row">
              <div className="col-12">
                <div className="manage-skill-test-heading d-flex justify-content-between mb-0">
                  <div>
                    <p>Manage Skill Set</p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="add-new-skill-test-button mb-4"
                      data-bs-toggle="modal"
                      data-bs-target="#newSkillModal"
                      onClick={() => {
                        setDeleteState(prevState => ({
                          ...prevState,
                          showComponent: true
                        }));
                      }}
                    >
                      <VectorAdd />
                      <span>Add New Skill Test</span>
                    </button>
                    <AddNewSkillTest fetchExamList={fetchExamList} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 manageskilltest-table-container">
            <div className="col-12 manage-skill-table-style p-0 ">
              <div
                className="table "
              ></div>
              <table id="example" className="table table-striped">
                <thead

                >
                  <tr >
                    <th className="color-table">Technology</th>
                    <th className="color-table">Name</th>
                    <th className="color-table">Level</th>
                    <th className="color-table">Questions</th>
                    <th className="color-table">Duration</th>
                    <th className="color-table">Uploaded On</th>
                    <th className="color-table">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <form
                          className="d-flex"
                          onSubmit={(event) => event.preventDefault()}
                        >
                          <input
                            id="search"
                            className="form-control search-bar"
                            type="text"
                            onChange={(e) => {
                              setDataState(prevState => ({
                                ...prevState,
                                searchInnerValue: e.target.value
                              }));
                            }}
                            placeholder="Search"
                          />
                        </form>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div>
                        <form className="d-flex">
                          <select
                            className="form-select dropdown-level ps-2"
                            id="level"
                            onChange={(e) => {
                              setDataState(prevState => ({
                                ...prevState,
                                levelType: e.target.value
                              }));
                            }}
                          >
                            <option value="">Select All</option>
                            {dataState.skillLevel.map((option) => (
                              <option key={option.name} value={option.name}>
                                {option.name === "Advance" ? "Advanced" : option.name}
                              </option>
                            ))}
                          </select>
                        </form>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {dataState.isLoading ? (
                    <>
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                      <ManageSkillTestSkeleton />
                    </>
                  ) : (
                    <>
                      {dataState.filterTestData?.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center ">
                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "65vh" }}>
                              <EmptySkillSet />
                              <p className="fw-bold mt-3 mb-1 empty-skill-text">No recent skill test data right now!</p>
                              <p className="empty-skill-fade-text">We’ll notify you when something arrives.</p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {arrayCurrentResults?.map((item, index) => {
                            const date = formatDate(item?.uploadedOn);
                            return (
                              <tr key={index}>
                                <td className="technology-rows">{item?.techName}</td>
                                <td className="name-row">
                                  <span className={item.examName.length > 30 ? "truncate-text" : ""} title={item.examName}>
                                    {item.examName.length > 30 ? `${item.examName.substring(0, 30)}...` : item.examName}
                                  </span>
                                </td>
                                <td className="levels-row">{item?.levelName === "Advance" ? "Advanced" : item?.levelName}</td>
                                <td className="questions-row">{item?.numberOfQuestion}</td>
                                <td className="duration-row">{`${item?.examDuration} mins`}</td>
                                <td className="uploaded-on-row">{`${date}`}</td>
                                <td className="delete-btn-row">
                                  <button
                                    className="delete-button"
                                    type="button"
                                    onClick={() => {
                                      setDeleteState((prevState) => ({
                                        ...prevState,
                                        confirmId: item?.examId,
                                        showConfirm: true,
                                      }));
                                    }}
                                  >
                                    <DeleteStroke />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </tbody>
                <tfoot>
                  <tr >
                    <td
                      className="fw-bold"
                      style={{ fontSize: "14px" }}
                      colSpan={2}
                    >
                      Total Items: {dataState.filterTestData?.length}
                    </td>
                    <td colSpan={6}>
                      <div
                        className="d-flex justify-content-end t-items"
                      >
                        <div className="d-flex align-items-center">
                          <p
                            className="me-2 mb-0 res-per-page"
                          >
                            Results per page
                          </p>
                          <select
                            className="form-select m-2 r-page"
                            value={paginationState.resultsPerPage}
                            onChange={(event) => {
                              setPaginationState(prevState => ({
                                ...prevState,
                                resultsPerPage: event.target.value,
                                currentPage: 1
                              }));
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </select>
                          <ul className="pagination mb-0">
                            <li
                              className={`page-item me-1 ${paginationState.currentPage === 1 ? "page-item disabled" : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState(prevState => {
                                    if (prevState.currentPage !== 1) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage - 1
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                  ;
                                }}
                              >
                                <svg
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7 1.5L1.5 7L7 12.5"
                                    stroke={
                                      paginationState.currentPage === 1
                                        ? "#A4A3A7"
                                        : "#28519E"
                                    }
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </button>
                            </li>
                            {Array.from(
                              { length: totalPaginationPages },
                              (_, i) => i + 1
                            ).map((page) => (
                              <li
                                key={page}
                                className={`page-item me-1 ${page === paginationState.currentPage ? "active" : ""
                                  }`}
                              >
                                <button
                                  className="page-link rounded pagination-styling fw-bold"
                                  onClick={() => {
                                    setPaginationState(prevState => ({
                                      ...prevState,
                                      currentPage: page
                                    }));
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  {page}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${paginationState.currentPage === totalPaginationPages
                                ? "page-item disabled"
                                : ""
                                }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => {
                                  setPaginationState(prevState => {
                                    if (prevState.currentPage !== prevState.totalPaginationPages) {
                                      return {
                                        ...prevState,
                                        currentPage: prevState.currentPage + 1
                                      };
                                    }
                                    return prevState;
                                  });
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <svg
                                  width="8"
                                  height="14"
                                  viewBox="0 0 8 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 12.5L6.5 7L1 1.5"
                                    stroke={
                                      paginationState.currentPage === totalPaginationPages
                                        ? "#A4A3A7"
                                        : "#28519E"
                                    }
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSkillTest;