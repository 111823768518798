import "./InterPerformanceReview.css";
import { useEffect, decryptData, useState, useNavigate, useLocation, Link, axios, Header, InterPerformanceReviewSection, Clock, Tick, ReviewYesGreen, ReviewInfoIcon, ReviewNoRed, ReviewNo, ReviewYes, Profile, Chevron, Usercircle, handleApiError, CustomModal, SideNav } from "../../MentorImport";

const inititalState = {
  firstName: null,
  lastName: null,
  cgiId: null,
  durationOfInternship: null,
  productivity: null,
  productivityRating: 0,
  quality: null,
  qualityRating: 0,
  behaviour: null,
  behaviourRating: 0,
  subject: null,
  subjectRating: 0,
  willingness: null,
  willingnessRating: 0,
  communication: null,
  communicationRating: 0,

  finalComment: null,
  commentOption: null,
  isSubmitted: false,
  save: false,
  showModal: false,
  saveModal : false,
  submitModal : false
}
const InterPerformanceReview = () => {
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [reviewData, setReviewData] = useState(inititalState);
  const navigate = useNavigate();
  const location = useLocation();

  const handleFinalCommentChange = (e) => {
    setReviewData((prevState) => ({
      ...prevState,
      finalComment: e.target.value
    }))
  };

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.clear();
    };
    return () => {
      handleRouteChange();
    };
  }, [location.pathname]);

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Review/reviewAssociate?reviewId=${sessionStorage.getItem("reviewId")}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success == 200) {
        let data = response.data.result[0];
        setReviewData((prevState) => ({
          ...prevState,
          firstName: data.fullName.split(" ")[0],
          lastName: data.fullName.split(" ").slice(1),
          cgiId: data.cgiId,
          durationOfInternship: data.durationOfInternship,
          productivity: data?.productivityRatingDescription,
          productivityRating: data?.productivityRating,
          quality: data?.qualityOfWorkRatingDescription,
          qualityRating: data?.qualityOfWorkRating,
          behaviour: data?.behaviourWorkRatingDescription,
          behaviourRating: data?.behaviourAtWorkRating,
          subject: data?.subjectKnowledgeRatingDescription,
          subjectRating: data?.subjectKnowledgeRating,
          willingness: data?.willingnessToLearnRatingDescription,
          willingnessRating: data?.willingnessToLearnRating,
          communication: data?.communicationRatingDescription,
          communicationRating: data?.willingnessToLearnRating,
          finalComment: data?.finalOfferDescription,
          commentOption: data?.finalOffer,
          isSubmitted: data?.isSubmitted,
          save: data?.isSave
        }));
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleButtonClick = (option) => {
    setReviewData({
      ...reviewData,
      commentOption: option
    })
  };
  const handleContinue = () => {
    navigate("/mentor/review-associates");
    setReviewData({
      ...reviewData,
      showModal: false,
      saveModal: false,
      submitModal : false
    })
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  const invalidData = () => {
    const isDataInvalid = reviewData.productivity === null || reviewData.productivity === "" || reviewData.productivityRating === 0 || reviewData.productivityRating === null ||  reviewData.quality === null || reviewData.quality === "" || reviewData.qualityRating === 0 || reviewData.qualityRating === null || reviewData.behaviour === null || reviewData.behaviour === "" || reviewData.behaviourRating === 0 || reviewData.behaviourRating === null || reviewData.subject === null || reviewData.subject === "" || reviewData.subjectRating === 0 || reviewData.subjectRating === null  || reviewData.willingness === null || reviewData.willingness === "" || reviewData.willingnessRating === 0 || reviewData.willingnessRating === null || reviewData.communication === null || reviewData.communication === "" || reviewData.communicationRating === 0 || reviewData.communicationRating === null || reviewData.finalComment === null || reviewData.finalComment === "" || reviewData.commentOption === null;
    return isDataInvalid;
  };

  const allowedCharactersRegExp = /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{1,750}$/;
  function isValidString(inputString) {
    return allowedCharactersRegExp.test(inputString);
  }

  const invalidInputs = () => !(
    (reviewData.productivity.length >= 100 && reviewData.productivity.length <= 750) &&
    (reviewData.quality.length >= 100 && reviewData.quality.length <= 750) &&
    (reviewData.behaviour.length >= 100 && reviewData.behaviour.length <= 750) &&
    (reviewData.subject.length >= 100 && reviewData.subject.length <= 750) &&
    (reviewData.willingness.length >= 100 && reviewData.willingness.length <= 750) &&
    (reviewData.communication.length >= 100 && reviewData.communication.length <= 750)
  );
  const invalidSaveData = () => {
    if ((reviewData.productivity === null || reviewData.productivity === "") && (reviewData.productivityRating === null || reviewData.productivityRating == 0) && (reviewData.quality === null || reviewData.quality === "") &&
      (reviewData.qualityRating === null || reviewData.qualityRating == 0) && (reviewData.behaviour === null || reviewData.behaviour === "") && (reviewData.behaviourRating === null || reviewData.behaviourRating == 0) &&
      (reviewData.subject === null || reviewData.subject === "") && (reviewData.subjectRating === null || reviewData.subjectRating == 0) && (reviewData.willingness === null || reviewData.willingness === "") && (reviewData.willingnessRating === null || reviewData.willingnessRating == 0) && (reviewData.communication === null || reviewData.communication === "") && (reviewData.communicationRating === null || reviewData.communicationRating == 0) &&
      (reviewData.finalComment === null || reviewData.finalComment === "") && (reviewData.commentRating === null || reviewData.commentRating == 0)) { return true; }
    else { return false; }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const dataToSend = {
      ReviewId: parseInt(sessionStorage.getItem("reviewId")),
      MentorId: parsedObject["decoded"].Id,
      ProductivityRatingDescription: reviewData.productivity || null,
      ProductivityRating: reviewData.productivityRating || null,
      QualityOfWorkRatingDescription: reviewData.quality || null,
      QualityOfWorkRating: reviewData.qualityRating || null,
      BehaviourWorkRatingDescription: reviewData.behaviour || null,
      BehaviourAtWorkRating: reviewData.behaviourRating || null,
      subjectKnowledgeRatingDescription: reviewData.subject || null,
      SubjectKnowledgeRating: reviewData.subjectRating || null,
      WillingnessToLearnRatingDescription: reviewData.willingness || null,
      WillingnessToLearnRating: reviewData.willingnessRating || null,
      CommunicationRatingDescription: reviewData.communication || null,
      CommunicationRating: reviewData.communicationRating || null,
      ReviewSubmitDate: formattedDate || null,
      FinalOfferDescription: reviewData.finalComment || null,
      FinalOffer: reviewData.commentOption,
      IsSave: true,
      IsSubmit: false,
    };
    try {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/Review/saveReviewForm`;
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      }
      const response = await axios.post(url, dataToSend, { headers });
      if (response.data.success == 200) {
        setReviewData({
          ...reviewData,
          showModal: true,
          saveModal : true
        })
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      ReviewId: sessionStorage.getItem("reviewId"),
      MentorId: parsedObject["decoded"].Id,
      ProductivityRatingDescription: reviewData.productivity,
      ProductivityRating: reviewData.productivityRating,
      QualityOfWorkRatingDescription: reviewData.quality,
      QualityOfWorkRating: reviewData.qualityRating,
      BehaviourWorkRatingDescription: reviewData.behaviour,
      BehaviourAtWorkRating: reviewData.behaviourRating,
      subjectKnowledgeRatingDescription: reviewData.subject,
      SubjectKnowledgeRating: reviewData.subjectRating,
      WillingnessToLearnRatingDescription: reviewData.willingness,
      WillingnessToLearnRating: reviewData.willingnessRating,
      CommunicationRatingDescription: reviewData.communication,
      CommunicationRating: reviewData.communicationRating,
      ReviewSubmitDate: formattedDate,
      FinalOfferDescription: reviewData.finalComment,
      FinalOffer: reviewData.commentOption,
      IsSave: true,
      IsSubmit: false,
    };

    if (invalidData()) {
      return alert("Please fill all the Fields before Submitting");
    }
    try {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/Review/submitReviewForm`;
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      }
      const response = await axios.post(url, dataToSend, { headers });
      if (response.data.success == 200) {
        setReviewData({
          ...reviewData,
          showModal: true,
          submitModal : true,
          saveModal : false
        })
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  return (
    <>
      <div style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
        <SideNav/>
      </div>
      <div className="interper-parent-wrapper ms-3">
        <div className="interper-child-wrapper">
          <div className="interper-header">
            <div className="interper-breadcrum">
              <Link to={"/mentor/review-associates"} className="crumb-parent">
                Review Associate
              </Link>
              <Chevron />
              <span>Inter Performance Review</span>
            </div>
          </div>
          <div className="user-detail-info-wrapper">
            <div className="interper-user-info">
              <div className="interper-use-info">
                <span>Inter Performance Review</span>
              </div>
              <div className="interper-info-detail">
                <div className="interper-user-name">
                  <Usercircle />
                  <span>{reviewData.firstName}&nbsp;{reviewData.lastName}</span>
                </div>
                <div className="interper-other-info">
                  <div className="interper-icon-pair">
                    <Profile />
                    <span>{reviewData.cgiId}</span>
                  </div>
                  <div className="interper-det-dot" />
                  <div className="interper-icon-pair">
                    <Clock />

                    <span>{reviewData.durationOfInternship} Months</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="interper-second-child d-flex flex-column interper-shadow-box">
              <div className="row interper-blue-info mx-3 mt-3">
                <div className="row">
                  <div className="col ps-0">
                    <ReviewInfoIcon />
                    <span className="interper-blue-info-text ms-2">Please rate the SDEs in each area using the following guidelines:</span>
                  </div>
                </div>

                <div className="row interper-blue-info-text ms-0" style={{ fontWeight: "500" }}>
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>1</span> - Not Acceptable
                  </div>
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>2</span> - Needs Improvement
                  </div>
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>3</span> - Meets Requirements
                  </div>
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>4</span> - Often Exceeds Expectations
                  </div>
                  <div className="col">
                    <span style={{ fontWeight: "600" }}>5</span> - Consistently Exceeds Expectations
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center" >
                <div className="row performance-parameter">
                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.productivityRating}
                    name={"productivityRating"}
                    comment={reviewData.productivity}
                    setComment={setReviewData}
                    heading="Productivity"
                    text="Meets productivity standards; completes work in a quick and timely manner; strives to increase productivity."
                    isFirst="true"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        productivity: comment,
                        productivityRating: selectedValue
                      }));
                    }}
                  />

                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.qualityRating}
                    comment={reviewData.quality}
                    name={"qualityRating"}

                    setComment={setReviewData}
                    heading="Quality of Work"
                    text="Demonstrates accuracy and thoroughness; displays commitment to excellence; looks for ways to improve and promote 
                    quality; shows good attention to detail."
                    isFirst="false"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        quality: comment,
                        qualityRating: selectedValue
                      }));
                    }}
                  />
                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.behaviourRating}
                    name={"behaviourRating"}
                    comment={reviewData.behaviour}
                    setComment={setReviewData}
                    heading="Behavior at Work"
                    text="Demonstrates a good work ethic, displays positive outlook and pleasant manner; Is disciplined, offers assistance and 
                    support to coworkers; willing to go the extra mile; makes maximum use of time."
                    isFirst="false"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        behaviour: comment,
                        behaviourRating: selectedValue
                      }));
                    }}
                  />
                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.subjectRating}
                    name={"subjectRating"}
                    comment={reviewData.subject}
                    setComment={setReviewData}
                    heading="Subject Knowledge"
                    text="Competent in required job skills and knowledge; exhibits ability to learn and apply new skills; keeps abreast of current 
                    developments in the project; requires minimal supervision."
                    isFirst="false"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        subject: comment,
                        subjectRating: selectedValue
                      }));
                    }}
                  />
                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.willingnessRating}
                    name={"willingnessRating"}
                    comment={reviewData.willingness}
                    setComment={setReviewData}
                    heading="Willingness to Learn"
                    text="Shows an openness and eagerness to learn from others; accepts criticism and feedback; applies feedback to improve 
                    performance; effectively deals with issues before they become problems; asks for help when needed."
                    isFirst="false"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        willingness: comment,
                        willingnessRating: selectedValue
                      }));
                    }}
                  />
                  <InterPerformanceReviewSection
                    submitted={reviewData.isSubmitted}
                    rating={reviewData.communicationRating}
                    name={"communicationRating"}
                    comment={reviewData.communication}
                    setComment={setReviewData}
                    heading="Communication"
                    text="Exhibits good listening and comprehension; expresses ideas and thoughts effectively; keeps other adequately informed;
                    resolves conflict effectively and timely."
                    isFirst="false"
                    onDataSent={(comment, selectedValue) => {
                      setReviewData((prevState) => ({
                        ...prevState,
                        communication: comment,
                        communicationRating: selectedValue
                      }));
                    }}
                  />
                  <div className="row px-0 mx-0" style={{ marginTop: "24px" }}>
                    <div className="row px-0 mx-0">
                      <h6 className="productivity-parameter-text mb-0 ps-0 mx-1">Comment on Final Offer (Yes/No):</h6>
                    </div>
                    <div className="row px-0 mx-0" style={{ marginTop: "6px" }}>
                      <div className="col-8 px-0 ms-1 mb-1" style={{ width: "45rem" }}>
                        <textarea
                          disabled={reviewData.isSubmitted}
                          defaultValue={reviewData.finalComment}
                          className="form-control"
                          name="comment"
                          id="comment"
                          cols="30"
                          rows="4"
                          onChange={handleFinalCommentChange}
                        ></textarea>
                        {reviewData.finalComment?.length > 0 && !isValidString(reviewData.finalComment) && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            Please Enter description only with letters, colon, comma and hyphen, between 1 and 750 characters
                          </span>
                        )}
                      </div>
                      <div className="col-4 px-0" style={{ marginLeft: "100px", width: "343px" }}>
                        <div className="card final-rating-card rating-card">
                          <div className="d-flex justify-content-between">
                            <button
                              disabled={reviewData.isSubmitted}
                              className="btn final-review-btn bg-white"
                              onClick={() => handleButtonClick(true)}
                              style={{
                                border: `${reviewData.commentOption == true ? "1px solid var(--other-green, #2DC26B)" : "1px solid var(--primary-gray, #343435)"}`,
                              }}
                            >
                              {reviewData.commentOption == true ? <ReviewYesGreen /> : <ReviewYes />}
                              <span style={{ marginLeft: "16px" }}>Yes</span>
                            </button>
                            <button
                              disabled={reviewData.isSubmitted}
                              className="btn final-review-btn bg-white"
                              onClick={() => handleButtonClick(false)}
                              style={{
                                border: `${reviewData.commentOption == false ? "1px solid var(--other-red, #E03E2D)" : "1px solid var(--primary-gray, #343435)"}`,
                              }}
                            >
                              {reviewData.commentOption == false ? <ReviewNoRed /> : <ReviewNo />}
                              <span style={{ marginLeft: "16px" }}>No</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="interper-footer d-flex flex-row justify-content-end">
        <button className="btn btn-outline-primary review-outline-btn" onClick={() => handleContinue()}>
          Cancel
        </button>
        <button
          disabled={invalidSaveData() || reviewData.isSubmitted}
          className="btn btn-primary mx-3 review-btns"
          onClick={(e) => {
            handleSave(e);
          }}
        >
          Save
        </button>
        <button
          disabled={reviewData.isSubmitted || invalidData() || invalidInputs()}
          className="btn btn-primary review-btns"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </div>


      {
        reviewData.showModal &&
        <CustomModal
          modalProps={{
            icon: <Tick />,
            show: reviewData.showModal,
            type: "success",
            title: reviewData.saveModal ? "Review Saved Successfully" : "Review Submitted Successfully",
            message: "",
            onCancel: handleContinue,
            confirmText: "OK",
          }} />
      }
    </>
  );
};

export default InterPerformanceReview;
