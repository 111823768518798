import React, { useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./components/Accounts/Login/LoginScreen";
import SignUpScreen from "./components/Accounts/SignUp/SignUpScreen";
import RegistrationSuccessfulScreen from "./components/Accounts/SignUp/RegistrationSuccessfulScreen";
import ForgotPasswordScreen from "./components/Accounts/ForgotPassword/ForgotPasswordScreen";
import CreateNewPasswordScreen from "./components/Accounts/ForgotPassword/CreateNewPasswordScreen";
import PasswordChangedScreen from "./components/Accounts/ForgotPassword/PasswordChangedSuccessfulScreen";
import { UserContext } from "./Context/Context";
import SessionCalendar from "./components/_utilities/SessionCalendar";

//LMS Import
import ActivityDetailedView from "./components/UserPortal/LMS/ActivityView/ActivityDetailedView";
import Milestones from "./components/UserPortal/UserMilestones/UserMilestones";
import EnrolledCourses from "./components/UserPortal/LmsDashBoard/EnrolledCourses/EnrolledCourses";
import LmsEnrolledCourses from "./components/UserPortal/LmsEnrolledCourses/LmsEnrolledCourses";
import CourseDetailview from "./components/UserPortal/LmsDashBoard/CourseDetailView/CourseDetailview";
import CoursesByCategory from "./components/UserPortal/LmsDashBoard/CoursesByCategory/CoursesByCategory";

//user Import
import Dashboard from "./components/UserPortal/Dashboard/Dashboard";
import ViewAllProjects from "./components/UserPortal/Dashboard/ProjectIdea/Project/ViewAllProject/ViewAllProjects";
import UserAuthGuard from "./components/_guard/UserAuthGuard";
import TakeYourTest from "./components/UserPortal/SkillManagement/TakeYourTest/TakeYourTest";
import DailyUpdateTable from "./components/UserPortal/DailyUpdateTable/DailyUpdateTable";
import SkillManagement from "./components/UserPortal/SkillManagement/SkillManagement";
import ViewAllIdeas from "./components/UserPortal/Dashboard/ProjectIdea/Idea/ViewAllIdea/ViewAllIdeas";
import TakeTest from "./components/UserPortal/SkillManagement/TakeTest/TakeTest";

// admin import
import Report from "./components/AdminPortal/Report/Report";
import Task from "./components/AdminPortal/Task/Task";
import DashboardA from "./components/AdminPortal/Dashboard/DashboardA";
import Detailedreport from "./components/AdminPortal/Report/Detailedreport/Detailedreport";
import AdminAuthGuard from "./components/_guard/AdminAuthGuard";
import MentorAuthGuard from "./components/_guard/MentorAuthGuard";
import ManageSkillTest from "./components/AdminPortal/SkillTest/ManageSkillTest";
import Log from "./components/AdminPortal/Logs/Log";
import UploadCourses from "./components/AdminPortal/UploadCourses/UploadCourses";
import LMSDashboard from "./components/AdminPortal/LMSDashboard/AdminLMSDashboard";
import TotalUploadedCourses from "./components/AdminPortal/UploadCourses/TotalUploadedCourses/TotalUploadedCourses";
import EnrolledUser from "./components/AdminPortal/UploadCourses/TotalUploadedCourses/EnrolledUser/EnrolledUser";
import CourseReport from "./components/AdminPortal/LMSDashboard/CertificationsAchieved/CourseReport/CourseReport";
import TechTalk from "./components/AdminPortal/TechTalk/TechTalk";
import TechTalkTable from "./components/AdminPortal/TechTalk/Table/TechTalkTable";
import CertificatesAchieved from "./components/AdminPortal/CertificationsAchieved/CertificationsAchieved";

//mentor import
import MentorDashboard from "./components/MentorPortal/MentorDashboard/MentorDashboard";
import MentorAssignTask from "./components/MentorPortal/MentorAssignTask/MentorAssignTask";
import MentorReview from "./components/MentorPortal/MentorReviewAssociates/MentorReview";
import InstructorRating from "./components/MentorPortal/MentorAssignTask/RateAssignment/InstructorRating";
import GradeAssignment from "./components/MentorPortal/MentorAssignTask/GradeAssignment/GradeAssignment";
import InterPerformanceReview from "./components/MentorPortal/MentorReviewAssociates/InterPerformanceReview/InterPerformanceReview";
import { MsalProvider } from "@azure/msal-react";
import AuthRedirect from "./components/Accounts/Auth/AuthRedirect";
import TotalEnrolledUsers from "./components/AdminPortal/LMSDashboard/EnrolledUsers/TotalEnrolledUsers/TotalEnrolledUsers";

//Error Page
import Error_400 from "./components/ErrorPage/Error_400";
import Error_500 from "./components/ErrorPage/Error_500";
import Error_404 from "./components/ErrorPage/Error_404";
import SessionExpired from "./components/ErrorPage/SessionExpired";
import AdminLMSDashboard from "./components/AdminPortal/LMSDashboard/AdminLMSDashboard";
import UserLmsDashboard from "./components/UserPortal/LmsDashBoard/UserLmsDashboard";

function App({ msalInstance }) {
  const [dataFromDailyUpdate, setDataFromDailyUpdate] = useState("");

  const { isAuthenticated } = useContext(UserContext);

  const handleDataFromDailyUpdate = (data) => {
    setDataFromDailyUpdate(data);
  };


  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
        />
        <Routes >
          <Route path="/" element={<LoginScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/sign-up" element={<SignUpScreen />} />
          <Route path="/success" element={<RegistrationSuccessfulScreen />} />
          <Route path="/change-password" element={<CreateNewPasswordScreen />} />
          <Route path="/change-success" element={<PasswordChangedScreen />} />
          <Route path="/auth-redirect" element={<AuthRedirect />} />

          <Route path="/error/statusCode=400" element={isAuthenticated ? <Error_400 /> : <Navigate to="/" />} />
          <Route path="/error/statusCode=500" element={isAuthenticated ? <Error_500 /> : <Navigate to="/" />} />
          <Route path="/error/statusCode=401" element={isAuthenticated ? <SessionExpired /> : <Navigate to="/" />} />

          {/* Common Routes for all Roles */}
          <Route path="/lms/dashboard" element={isAuthenticated ? <UserLmsDashboard /> : <Navigate to="/" />} />
          <Route path="/lms/milestones" element={isAuthenticated ? <Milestones /> : <Navigate to="/" />} />
          <Route path="/lms/enrolled-courses" element={isAuthenticated ? <EnrolledCourses /> : <Navigate to="/" />} />
          <Route path="/lms/:type" element={isAuthenticated ? <ActivityDetailedView /> : <Navigate to="/" />} />
          <Route path="/lms/course-detail" element={isAuthenticated ? <CourseDetailview /> : <Navigate to="/" />} />
          <Route path="/lms/course-by-category/:subCategoryId" element={isAuthenticated ? <CoursesByCategory /> : <Navigate to="/" />} />
          <Route path="/lms/course-by-category/:searchQuery" element={isAuthenticated ? <CoursesByCategory /> : <Navigate to="/" />} />
          <Route path="/lms/user-enrolled-courses" element={isAuthenticated ? <LmsEnrolledCourses /> : <Navigate to="/" />} />
          <Route path="/session-calendar" element={isAuthenticated ? <SessionCalendar /> : <Navigate to="/" />} />

          {/* User Role Routes */}
          <Route element={<UserAuthGuard />}>
            <Route path="/dashboard" element={<Dashboard sendDataToDashboard={dataFromDailyUpdate} />} />
            <Route path="/daily-update" element={<DailyUpdateTable sendDataToDailyUpdate={handleDataFromDailyUpdate} />} />
            <Route path="/all-projects" element={<ViewAllProjects />} />
            <Route path="/project-idea" element={<ViewAllIdeas />} />
            <Route path="/skill-management" element={<SkillManagement />} />
            <Route exact path="/take-test" component={<TakeTest />} />
            <Route path="/take-your-test" element={<TakeYourTest />} />
            <Route path="/project-idea-projects" element={<ViewAllIdeas />} />
          </Route>

          {/* Admin Role routes */}
          <Route element={<AdminAuthGuard />}>
            <Route path="/admin/sde/dashboard" element={<DashboardA />} />
            <Route path="/admin/sde/report" element={<Report />} />
            <Route path="/admin/sde/report/detail" element={<Detailedreport />} />
            <Route path="/admin/sde/assign-task" element={<Task />} />
            <Route path="/admin/sde/skill-test" element={<ManageSkillTest />} />
            <Route path="/admin/sde/logs" element={<Log />} />
            <Route path="/admin/lms/dashboard" element={<AdminLMSDashboard />} />
            <Route path="/admin/lms/enrolled-user" element={<EnrolledUser />} />
            <Route path="/admin/lms/course-report" element={<CourseReport />} />
            <Route path="/admin/lms/certificates-achieved" element={<CertificatesAchieved />} />
            <Route path="/admin/lms/addCourse" element={<UploadCourses editMode={false} />} />
            <Route path="/admin/lms/editCourse" element={<UploadCourses editMode={true} />} />
            <Route path="/admin/lms/total-uploaded-courses" element={<TotalUploadedCourses />} />
            <Route path="/admin/lms/all-techTalk/:status" element={<TechTalk />} />
            <Route path="/admin/lms/all-techTalk" element={<TechTalkTable />} />
            <Route path="/admin/lms/total-enrolled-users" element={<TotalEnrolledUsers />} />
          </Route>

          {/* Mentor Role routes */}
          <Route element={<MentorAuthGuard />}>
            <Route path="/mentor/dashboard" element={<MentorDashboard />} />
            <Route path="/mentor/assign-task" element={<MentorAssignTask />} />
            <Route path="/mentor/review-associates" element={<MentorReview />} />
            <Route path="/mentor/project-rating" element={<InstructorRating />} />
            <Route path="/mentor/gradeAssignment" element={<GradeAssignment />} />
            <Route path="/mentor/review-associates/inter-performance-review" element={<InterPerformanceReview />} />
            <Route path="/mentor/lms/dashboard" element={<LMSDashboard />} />
          </Route>

          <Route path="*" element={<Error_404 />} />
        </Routes>
      </div>
    </MsalProvider >
  );
}

export default App;
