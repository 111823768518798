import {React,useEffect,useState,axios,decryptData,CryptoJS,Header,Link,useNavigate,SideNav,Chevron,InfoIcon,handleApiError,CustomModal,SuccessfullVector,InfoVector} from "../../MentorImport";
import "./InstructorRating.css";

const InstructorRating = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  const [ratingData,setRatingData] = useState({
    originalData :[],
    tableData : [],
    selectedRow : -1,
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
    cancelText:"",
    confirmText:""
  });
  const [editedRows, setEditedRows] = useState(new Set());
  const navigate = useNavigate();
  const ratingArray = ["Not Acceptable", "Needs Improvement", "Meets Requirement", "Exceeds Expectations"];
  const data = localStorage.getItem("userData");
  let parsedObject;
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  const taskIdValue = parseInt(CryptoJS.AES.decrypt(sessionStorage.getItem("taskId"), process.env.REACT_APP_TASK_ID).toString(CryptoJS.enc.Utf8));
  const taskTitle = CryptoJS.AES.decrypt(sessionStorage.getItem("title"), process.env.REACT_APP_TASK_ID).toString(CryptoJS.enc.Utf8);
  const cancelToken = axios.CancelToken.source();

  const fetchData = async () => {
      let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/taskReview`;
    const body = {
      id:taskIdValue,
      batchId : selectedBatches
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      cancelToken : cancelToken.token,
    };

    try {
    const response = await axios.post(apiUrl, body, { headers });
    if (response.status === 200) {
      setRatingData((prevState) => ({
        ...prevState,
        originalData:response.data.result.map((value) => {
            return { ...value };
          }),
          tableData:response.data.result.map((value) => {
            return { ...value };
          }) 
      }));
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    handleApiError(statusCode, navigate);
  }
  };

  const areOtherFieldsEdited = (rowData, column) => {
    const otherColumns = Object.keys(rowData).filter((key) => key !== "firstName" && key !== "lastName" && key !== "userId" && key !== "overall");
    return otherColumns.some((col) => col === column && rowData[col] !== "");
  };

  const handleInputChange = (e, rowIndex, column) => {
    let value = e.target.value;
    value = value.replace(/[^1-4]/g, "").slice(0, 1);
    const updatedTableData = [...ratingData.tableData];
    updatedTableData[rowIndex][column] = value;
    const sum = Object.keys(updatedTableData[rowIndex]).reduce((acc, key) => {
      if (key !== "userId" && key !== "firstName" && key !== "lastName" && key !== "overall") {
        const rowData = updatedTableData[rowIndex];
        if (rowData && rowData[key]) {
          const firstChar = rowData[key][0];
          if (firstChar !== undefined) {
            const intValue = parseInt(firstChar, 10);
            if (!isNaN(intValue)) {
              return acc + intValue;
            }
          }
        }
      }
      return acc;
    }, 0);

    updatedTableData[rowIndex]["overall"] = sum > 0 ? ((sum / 36) * 100).toFixed(2) + "%" : sum === 0 ? "0%" : "--%";
    setRatingData((prevState) => ({
      ...prevState,
        tableData:updatedTableData
    }));
    const isRowEdited = areOtherFieldsEdited(ratingData.tableData[rowIndex], column);
    setEditedRows((prevEditedRows) => {
      const updatedEditedRows = new Set(prevEditedRows);
      if (isRowEdited) {
        updatedEditedRows.add(rowIndex);
      } else {
        updatedEditedRows.delete(rowIndex);
      }
      return updatedEditedRows;
    });
  };

  const isDataValid = () => {
    let isError = true; 
    const editedRowsData = Array.from(editedRows).map((index) => ratingData.tableData[index]);
    editedRowsData.forEach((obj) => {
      if (obj.learningAgility !== 0 && obj.attitude !== 0 && obj.progress !== 0 && obj.listeningSkill !== 0 && obj.language !== 0 && obj.problemSolving !== 0 && obj.teamWorkSkill !== 0 && obj.businessAcumen !== 0 && obj.currentAssignment !== 0) {
        isError = false; 
      } else {
        isError = true; 
      }
    });
    return isError; 
  }
  const sendRatedData = async () => {
    const editedRowsData = Array.from(editedRows).map((index) => ratingData.tableData[index]);
    const taskReview = editedRowsData.map((obj) => {
      return {
        id: taskIdValue,
        userId: obj.id,
        learningAgility: obj.learningAgility !== 0 ? obj.learningAgility : "0",
        attitude: obj.attitude !== 0 ? obj.attitude : "0",
        progress: obj.progress !== 0 ? obj.progress : "0",
        listeningSkill: obj.listeningSkill !== 0 ? obj.listeningSkill : "0",
        language: obj.language !== 0 ? obj.language : "0",
        problemSolving: obj.problemSolving !== 0 ? obj.problemSolving : "0",
        teamWorkSkill: obj.teamWorkSkill !== 0 ? obj.teamWorkSkill : "0",
        businessAcumen: obj.businessAcumen !== 0 ? obj.businessAcumen : "0",
        currentAssignment: obj.currentAssignment !== 0 ? obj.currentAssignment : "0",
        overall: parseFloat(obj.overall),
      };
    });

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL_DOTNET + `/Tasks/addTaskReview`, taskReview, {
        headers: { Authorization: `Bearer ${parsedObject["token"]}` },
        cancelToken: cancelToken.token,
      });
      if (response.status === 200) {
        fetchData();
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Review Added Successfully",
          modalMessage: "",
          icon: <SuccessfullVector/>,
          cancelText:"",
          confirmText:""
        }));
      }
    } catch (error) {
      console.error("This is error in report:", error);
    }
    setEditedRows(new Set());
  };
  useEffect(() => {
    fetchData();
    return () => {
      cancelToken.cancel("request cancelled");
    };
  }, [selectedBatches]);
  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };
  return (
    <div>
      <div style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
        <SideNav />
      </div>
      <div className="inst-rating-parent-wrapper">
        <div className="inst-rating-breadChrum">
          <Link to="/mentor/assign-task" className="crumb-parent">
            AssignTask
          </Link>
          <Chevron />
          <span>Instructor Rating</span>
        </div>
        <div className="inst-rating-data-wrapper">
          <div className="inst-heading-style">
            <h3>Instructor Rating - {taskTitle}</h3>
          </div>
          <div className="inst-table-box-style">
            <div className="table-description-style">
              <div className="desc-child-one">
                <InfoIcon />
              </div>
              <div className="desc-child-two">
                <p className="mb-0 fw-bold">Please rate SDEs in each area using the following guidelines:</p>
                <div className="rate-info-wrapper">
                  {ratingArray.map((rateVal, rateInd) => {
                    return (
                      <div className="desc-value-wrapper">
                        <span className="fw-bold">{`${rateInd + 1}`}</span> &nbsp;
                        <span>{`- ${rateVal}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="table-container-parent-wrapper">
              <div className="table-container">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th className="align-left">SDEs</th>
                      <th>Learning Agility</th>
                      <th>Attitude</th>
                      <th>Progress</th>
                      <th>Listening Skills</th>
                      <th>Communication Skills</th>
                      <th>Problem Solving</th>
                      <th>Interpersonal and Team Work Skill</th>
                      <th style={{ minWidth: "150px" }}>Business Acumen (Underset and the project business details)</th>
                      <th>Current Assignment</th>
                      <th>Overall(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingData.tableData &&
                      ratingData.tableData.map((rowData, rowIndex) => {
                        const isRowEditable =
                          ratingData.originalData[rowIndex].overall === "--%" ||
                          ratingData.originalData[rowIndex].overall === null ||
                          ratingData.originalData[rowIndex].overall === "" ||
                          ratingData.originalData[rowIndex].overall === 0;
                        return (
                          <tr
                            key={rowIndex}
                            style={{ opacity: isRowEditable ? 1 : 0.5 }}
                            onClick={() =>
                              setRatingData((prevState) => ({
                                ...prevState,
                                selectedRow: isRowEditable ? (ratingData.selectedRow === rowIndex ? null : rowIndex) : null,
                              }))
                            }
                          >
                            <td className="align-left fw-bold">{`${rowData?.firstName} ${rowData?.lastName}`}</td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.learningAgility}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    handleInputChange(e, rowIndex, "learningAgility");
                                  }}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.learningAgility
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.attitude}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "attitude")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.attitude
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.progress}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    handleInputChange(e, rowIndex, "progress");
                                  }}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.progress
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.listeningSkill}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "listeningSkill")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.listeningSkill
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.language}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "language")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.language
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.problemSolving}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "problemSolving")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.problemSolving
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.teamWorkSkill}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "teamWorkSkill")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.teamWorkSkill
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.businessAcumen}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "businessAcumen")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.businessAcumen
                              )}
                            </td>
                            <td>
                              {ratingData.selectedRow === rowIndex ? (
                                <input
                                  type="text"
                                  value={rowData.currentAssignment}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => handleInputChange(e, rowIndex, "currentAssignment")}
                                  readOnly={!isRowEditable}
                                />
                              ) : (
                                rowData.currentAssignment
                              )}
                            </td>
                            <td>{rowData?.overall !== "" && rowData?.overall !== null ? rowData.overall : "--%"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="inst-save-button-wrapper" style={{ alignSelf: "end" }}>
              <button
                onClick={() => {
                  setRatingData((prevData) => ({
                    ...prevData,
                    isModalOpen: true,
                    modalType: "confirm",
                    modalTitle: "Are You Sure?",
                    modalMessage: "Do you want to save these records? This process cannot be undone.",
                    icon: <InfoVector/>,
                    cancelText:"No, Cancel",
                    confirmText:"Yes, Sure"
                  }));
                }}
                disabled={isDataValid()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
            onConfirm: sendRatedData,
            cancelText:ratingData.cancelText,
    confirmText:ratingData.confirmText
          }}
        />
      )}
    </div>
  );
};

export default InstructorRating;
