import { useState, useEffect, useNavigate, axios, decryptData, handleApiError, CalendarIcon, ArrowForwardIcon, FormatDate, LmsNotificationsSkeleton, EmptyMentorNotifications, SuccessfullVector } from "../../UserImport";
import "./LmsNotifications.css";

const LmsNotifications = (props) => {
  const { enrolledCoursesData } = props;
  const [notificationsData, setNotificationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getNotificationsData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/userCourseCompletionNotification`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        const combinedArray = [...response.data.result.recentlyUploadedCourses, ...response.data.result.courseCompletedNotification];
        combinedArray.forEach((item) => {
          if (item.courseCompletedDate) {
            item.sortDate = new Date(item.courseCompletedDate);
          } else {
            item.sortDate = new Date(item.createdDate);
          }
        });
        combinedArray.sort((a, b) => b.sortDate - a.sortDate);
        setNotificationsData(combinedArray);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  useEffect(() => {
    getNotificationsData();
  }, []);

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between lms-dashboard-notification-container">
        <p>Notifications</p>
      </div>

      <div className="card lms-dasboard-card-container">
        <div className="lms-dashboard-notification-card vh-100 dp-scroll me-2">
        <div className={`card-body h-100 p-0 ${isLoading ? "lms-notification-skeleton-width" : ""}`}>
          {isLoading ?
            Array.from({ length: 8 }).map((_, index) => <LmsNotificationsSkeleton key={index} isCourseSkeleton={index % 2 === 0}/>)
             : notificationsData && notificationsData.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <EmptyMentorNotifications />
                <p className="fw-bold mt-3 mb-1 empty-certificate-text">No Notifications Right Now!</p>
                <p className="empty-certificate-fade-text mt-1">We’ll notify you when something arrives.</p>
              </div>
            ) : 
            notificationsData?.map((notification, index) => (
            <div key={index} className={`d-flex gap-2 p-2 border-bottom ${notification?.courseCompletedDate ? "" : "lms-dashboard-notification-inner-enroll-card"}`}>
              <div>
                {notification?.courseCompletedDate ? (
                  <p className="lms-user-img">
                    {notification?.firstName?.split(" ")
                      .map((name) => name.charAt(0).toUpperCase())
                      .join("")}
                    {notification?.lastName?.split(" ")
                      .map((name) => name.charAt(0).toUpperCase())
                      .join("")}
                  </p>
                ) : (
                  <img className="lms-notification-image" src={notification?.thumbnailUri} alt="course-img" width={40} height={40} />
                )}
              </div>
              <div className="d-grid gap-1 w-100">
                {notification?.courseCompletedDate ? (
                  <div className="lms-notification-name">
                    {notification?.firstName || ""} {notification?.lastName || ""}
                  </div>
                ) : (
                  <div className="d-flex gap-1">
                    <div className="user-lms-new-course-badge user-lms-new-course-badge-triangular d-flex justify-content-center align-items-center"><p className="user-lms-new-course-text me-3 mb-0">New Course</p></div>
                    <div className="lms-dashboard-notification-badge">
                      <p className="lms-dashboard-notification-badge-text m-0">{notification?.levelName}</p>
                    </div>
                  </div>
                )}
                {notification?.courseCompletedDate ? (
                  <div className="lms-notification-desc">
                    Successfully completed the ‘{notification?.courseLevel} Level’ of <b title={notification?.courseTitle}>{truncateDescription(notification?.courseTitle, 30)}</b>
                  </div>
                ) : (
                  <div className="lms-notification-desc" title = {notification?.title}>
                    <b>{truncateDescription(notification?.title, 64)}</b>
                  </div> 
                )}
                <div className="d-flex justify-content-between">
                  {notification?.courseCompletedDate ? (
                    <div className="lms-notification-date d-flex gap-1 align-items-center">
                      <CalendarIcon />
                      {FormatDate(notification?.courseCompletedDate)}
                    </div>
                  ) : (
                    <div className="lms-notification-date d-flex gap-1 align-items-center">
                      <CalendarIcon />
                      {FormatDate(notification?.createdDate)}
                    </div>
                  )}
                  {!notification?.courseCompletedDate && (
                    enrolledCoursesData?.some(course => course.id === notification.id) ? (
                      <div className="d-flex gap-1 justify-content-center align-items-center lms-notification-enrolled">
                      <SuccessfullVector className="lms-notification-enrolled-vector" />Enrolled
                    </div>
                    ) : (
                      <div className="d-flex gap-1 align-items-center lms-notification-enroll pointer"
                    onClick={() => {
                      sessionStorage.setItem("courseId", notification.id);
                      navigate('/lms/course-detail')
                    }}>
                      Enroll Now <ArrowForwardIcon className="lms-notification-enroll-chev" />
                    </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </>
  );
};

export default LmsNotifications;
