import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AdminMentorListSkeleton = () => {
  return (
        <div className="card mentor-head">
          <div className="mentor-wrapper">
            <div className="image-wrapper1">
              <div className="image-box1">
                <Skeleton circle={true} width={38} height={38} />
              </div>
            </div>
            <div className="text-wrapper1">
              <Skeleton className="m-0" width={250} />
              <Skeleton className="m-0 " width={120} />
            </div>
          </div>
        </div>
  );
};

export default AdminMentorListSkeleton;
