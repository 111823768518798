import { Tick, HorizontalLine, ActiveCourseInfo, ActiveUploadContent, InactiveUploadContent, ActiveAdditionalInfo, InactiveAdditionalInfo, InactiveAssignment, ActiveAssignment } from "../UploadCourses/UploadCoursesImport";

const FileUploadProgress = (props) => {
  const { currPage, type } = props;
  return (
    <>
      <div className="card-header upload-course-head mt-3">
        <div className="course-step">
          <div className="row">
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <div className="row">
                {currPage == 1 ? <ActiveCourseInfo /> : <Tick />}
              </div>
              <div
                className={`row  course-step-navigator-text ${currPage == 1 ? "fw-bold" : "permissions-disabled-text"
                  }`}
              >
                {type} Info
              </div>
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <HorizontalLine />
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <div className="row">
                {currPage == 2 ? (
                  <ActiveUploadContent />
                ) : currPage > 2 ? (
                  <Tick />
                ) : (
                  <InactiveUploadContent />
                )}
              </div>
              <div
                className={`row course-step-navigator-text  ${currPage == 2 ? "fw-bold" : "permissions-disabled-text"
                  }`}
              >
                {type === "Tech Talk" ? "Upload Videos" : "Upload Modules"}
              </div>
            </div>
            {type !== "Tech Talk" && (
              <>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <HorizontalLine />
                </div>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="row">
                    {currPage == 3 ? (
                      <ActiveAssignment />
                    ) : currPage > 3 ? (
                      <Tick />
                    ) : (
                      <InactiveAssignment />
                    )}
                  </div>
                  <div
                    className={`row course-step-navigator-text  ${currPage == 3 ? "fw-bold" : "permissions-disabled-text"
                      }`}
                  >
                    Assignment
                  </div>
                </div>
              </>
            )}
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <HorizontalLine />
            </div>
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <div className="row">
                {currPage == 4 ? (
                  <ActiveAdditionalInfo />
                ) : currPage > 4 ? (
                  <Tick />
                ) : (
                  <InactiveAdditionalInfo />
                )}
              </div>
              <div
                className={`row course-step-navigator-text  ${currPage == 4 ? "fw-bold" : "permissions-disabled-text"
                  }`}
              >
                Additional Info
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUploadProgress;
