import {CSVPreview } from "../UploadCoursesImport";
import Modal from "react-bootstrap/Modal";


const PreviewModal = ({ editMode, file, isOpen, onClose }) => {
  return (
    <>
      <Modal size="lg" show={isOpen} onHide={onClose} backdrop="static" keyboard={false} centered>
        <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center pt-1 course-player-modal">
          {file && 
            (editMode && !file?.isAdded ? (
              <>
                {file.file.fullPath.includes(".mp4") ? (
                  <video controls className="preview-video">
                    <source src={file.file.fullPath} />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <iframe title="File Preview" src={file.file.fullPath.includes(".csv")||file.file.fullPath.includes(".ppt") ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.file.fullPath)}&wdOrigin=BROWSELINK` : file.file.fullPath} className="iframe-player" />
                )}
              </>
            ) : (
              <>
                {file.file.type.startsWith("video") ? (
                  <video controls className="preview-video">
                    <source src={URL.createObjectURL(file.file)} type={file.file.type} />
                    Your browser does not support the video tag.
                  </video>
                ) : file.file.type.startsWith("text/csv") ? (
                    <CSVPreview file={file.file}/>
                ) : 
                file.file.type.startsWith("application/pdf") ? (
                  <iframe title="File Preview" src={URL.createObjectURL(file.file)} className="iframe-player" />
              ):
                (
                  <>
                  <iframe title="File Preview" src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(URL.createObjectURL(file.file))}&wdOrigin=BROWSELINK`} className="iframe-player" />
                  </>
                )}
              </>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewModal;
