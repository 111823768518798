import TechActionType from "../../../_common/TechActionType";
import { useEffect, axios, useNavigate, useState, SearchIcon, handleApiError, decryptData } from "../../../AdminPortal/AdminImport";
import '../../../AdminPortal/Task/AssignTask/TechnologyDropdown.css';

const ProjectTechDropdown = (props) => {
  const [techOptions, setTechOptions] = useState([]);
  const [filteredTechOptions, setFilteredTechOptions] = useState([]);
  const { dispatch, technologyNames, techDataComingChild, selectedTechIds, selectedTech, selectedTechIdsfromChild = defaultTechIdHandler, isCancelClick = false } = props;
  const navigate = useNavigate();

  function defaultTechIdHandler(techIdData) { }

  const fetchTechOptions = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Project/technology`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.get(url, { headers });
      const sortedOptions = response.data?.result.sort((a, b) => {
        const nameA = a.techName.toUpperCase();
        const nameB = b.techName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setTechOptions(sortedOptions);
      setFilteredTechOptions(sortedOptions);
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  // Update selected tech IDs when component mounts or selectedTech changes
  useEffect(() => {
    if (selectedTech) {
      // Extract IDs from selectedTech and update state.selectedTechIds
      let selectedTechSimplified = selectedTech.map(tech => tech)
      const selectedTechIds = selectedTech.map(tech => tech.id);

      dispatch({ type: TechActionType.SET_TECH_ID, payload: selectedTechIds });
      dispatch({ type: TechActionType.SET_TECH_NAME, payload: selectedTechSimplified })
    }
  }, [selectedTech]);

  useEffect(() => {
    if(isCancelClick) {
      handleSearchChange();
    }
  }, [isCancelClick])
  

  useEffect(() => {
    fetchTechOptions();
  }, []);

  const handleOptionClick = (e, tech) => {
    if (Array.isArray(technologyNames)) {
      if (e.target.checked) {
        dispatch({
          type: TechActionType.SET_TECH_NAME,
          payload: [...technologyNames, tech]
        })
        dispatch({
          type: TechActionType.SET_TECH_ID,
          payload: [...selectedTechIds, tech.id]
        })
      } else if (!e.target.checked) {
        dispatch({
          type: TechActionType.SET_TECH_NAME,
          payload: technologyNames.filter((item) => item.id !== tech.id)
        })
        dispatch({
          type: TechActionType.SET_TECH_ID,
          payload: selectedTechIds.filter((item) => item !== tech.id)
        })
      }
    }
  };

  const handleSearchChange = (e) => {
    if(e === undefined) {
      setFilteredTechOptions(techOptions);
      let searchBar = document.querySelector(".search-dropdowns");
      searchBar.value = "";
    } else {
      const filtered = techOptions.filter((tech) =>
        tech.techName.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setFilteredTechOptions(filtered);
    }
  };

  useEffect(() => {
    if (technologyNames) {
      const selectedTechNames = technologyNames?.map((tech) => tech);
      techDataComingChild(selectedTechNames);
    }
    if (selectedTechIds) {
      const selectedTechId = selectedTechIds?.map(id => id);
      selectedTechIdsfromChild(selectedTechId);
    }
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center ps-1 associate-search-log-wrapper mb-2"
        style={{ width: "95%" }}
      >
        <SearchIcon />
        <input
          className="search-dropdowns"
          type="text"
          placeholder="Search tech..."
          onChange={handleSearchChange}
        />
      </div>
      <div style={{ maxHeight: "6rem", overflow: "auto" }} className="dp-scroll">
        {filteredTechOptions?.map((tech) => (
          <div key={tech.id} className="form-check small checkbox tech-wrap">
            <label
              className="form-check-label tech-label"
              htmlFor={tech.id + tech.techName}
              style={{ display: "block", cursor: "pointer" }}
              onClick={(e) => {
                handleOptionClick(e, tech)
              }}
            >
              <span>{tech.techName}</span>
              <input
                readOnly
                className="form-check-input tech-checkbox"
                type="checkbox"
                id={tech.id}
                value={tech.techName}
                checked={selectedTechIds?.includes(tech.id) || technologyNames?.includes(tech.techName)}
              />
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectTechDropdown;