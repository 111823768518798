import "./Skillsachieved.css";
import { decryptData, axios, SilverStarOri, GoldStarOri, handleApiError, BronzeStarOri, useNavigate, useContext, useState, useEffect, DetailedProvider } from "../../AdminImport";

const SkillsAchieved = () => {
  const [apiTechnology, setApiTechnology] = useState([]);
  const { data } = useContext(DetailedProvider);

  let beginnerSkills = [];
  let intermediateSkills = [];
  let advancedSkills = [];
  const navigate = useNavigate();

  useEffect(() => {
    fetchLevel();
  }, []);

  const fetchLevel = async () => {
    var parsedObject;
    const parsedData = localStorage.getItem("userData");
    parsedData ? (parsedObject = decryptData(parsedData, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setApiTechnology(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  var skills = data.userSkills?.userSkills;
  skills.forEach((s) => {
    if (s.examLevel === apiTechnology[0]?.id) {
      beginnerSkills.push(s.techName);
    } else if (s.examLevel === apiTechnology[1]?.id) {
      intermediateSkills.push(s.techName);
    } else if (s.examLevel === apiTechnology[2]?.id) {
      advancedSkills.push(s.techName);
    }
  });
  let totalStarAvhieved = beginnerSkills.length + intermediateSkills.length + advancedSkills.length;
  return (
    <div className="skill-parent-wrapper">
      <div className="skill-star-count dp-scroll">
        <div className="skill-first-container">
          <div className="total-stars">
            <span className="count">{totalStarAvhieved}</span>
            <span>Stars Achieved</span>
          </div>
          <div className="seperated-stars">
            <div>
              <span className="count">{data.userSkills.beginerLevelCount}</span>
              <span>Beginner</span>
            </div>
            <div>
              <span className="count">{data.userSkills.intermediateLevelCount}</span>
              <span>Intermediate</span>
            </div>
            <div>
              <span className="count">{data.userSkills.advancedLevelCount}</span>
              <span>Advanced</span>
            </div>
          </div>
        </div>
        <div className="skill-second-container">
          <div>Technology:</div>
          <div className="tag-container">
            {beginnerSkills?.map((val, index) => {
              return (
                <div key={index} className="tag-tech">
                  <span>{val}</span>
                  <BronzeStarOri />
                </div>
              );
            })}
            {intermediateSkills?.map((val, index) => {
              return (
                <div key={index} className="tag-tech">
                  <span>{val}</span>
                  <SilverStarOri />
                </div>
              );
            })}
            {advancedSkills?.map((val, index) => {
              return (
                <div key={index} className="tag-tech">
                  <span>{val}</span>
                  <GoldStarOri />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="star-info">
        <div>
          <BronzeStarOri />
        </div>
        <span>Beginner</span>
        <div>
          <SilverStarOri />
        </div>
        <span>Intermediate</span>
        <div>
          <GoldStarOri />
        </div>
        <span>Advanced</span>
      </div>
    </div>
  );
};

export default SkillsAchieved;
