export const handleApiError = (error, navigate) => {
  if (error && error.status == 401) {
    navigate(`/error/statusCode=${error.status}`);
    return;
  } else if (error && error.response && error.response.status !== undefined && error.response.status !== null) {
    navigate(`/error/statusCode=${error.response.status}`);
  } else if (error && error.request === undefined) {
    navigate(`/error/statusCode=${error}`);
  } else {
    navigate(`/error/statusCode=500`);
  }
};