// import "./EnrolledCourses.css";
import { React, SideNav, Header, handleApiError, axios, decryptData, useNavigate, useLocation, useParams, Filter } from "./../../UserImport";
import ViewAllCourses from "./../ViewAllCourses/ViewAllCourses";
import { useEffect, useState } from "react";
import { FilterBase } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";

const CoursesByCategory = () => {
  const [totalCoursesState, setTotalCoursesState] = useState({
    tableData: [],
    orgTableData: [],
    apiLevel: [],
    apiGrade: [],
    isLoading: true,
  });
  const [filterState, setFilterState] = useState({
    showFilterBase: false,
    filtersApplied: false,
    selectedInsId: [],
    selectedGradeId: [],
    selectedLevelId: [],
  });
  const navigate = useNavigate();
  const location = useLocation(); // Accessing the location object

  let isTechTalk = sessionStorage.getItem("isTechTalk") === "true";
  let subCategoryName = sessionStorage.getItem("subCategoryName");

  const { subCategoryId } = useParams(); // Extracting the subCategoryId from the route parameters
  const { searchQuery } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const totalEnrolledUsersData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    var userId = parsedObject["decoded"]["Id"];

    const url = !isTechTalk ? `${process.env.REACT_APP_API_URL_DOTNET}/Course/getCourseByCategory?subCategoryId=${subCategoryId}` : `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getAll`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        let filteredData = response.data.result;
        if (isTechTalk) {
          filteredData = filteredData.filter(course => course.subCategoryName === subCategoryName && course.isActive === true && course.isPublished === true);
        } else {
          if (subCategoryId) {
            filteredData = filteredData.filter(course => course.categoryInfo[0].subCategoryId === subCategoryId && course.isPublished === true && course.isActive === true);
          } else if (searchQuery) {
            filteredData = filteredData.filter(course => course.categoryInfo[0].subCategoryName.toLowerCase().startsWith(searchQuery.toLowerCase()) && course.isPublished === true && course.isActive === true);
          }
        }
        setData(filteredData);
        setTotalCoursesState((prevState) => ({
          ...prevState,
          tableData: filteredData,
          orgTableData: filteredData,
          isLoading: false,
          apiLevel: prevState.apiLevel,
          apiGrade: prevState.apiGrade,
        }));
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleFiltersChange = () => {
    const getInsFilterItems = (items) => {
      if (filterState?.selectedInsId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedInsId?.some((selectedId) => item?.instructorList?.some((instructor) => instructor?.instructorId === selectedId));
        });
      }
      return items;
    };
    const getGradeFilterItems = (items) => {
      if (filterState?.selectedGradeId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedGradeId?.some((selectedId) => item.gradeInfo === selectedId);
        });
      }
      return items;
    };

    const getLevelFilterItems = (items) => {
      if (filterState?.selectedLevelId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedLevelId?.some((selectedId) => item.levelId === selectedId);
        });
      }
      return items;
    };

    if (filterState.selectedInsId.length !== 0 || filterState.selectedGradeId.length !== 0 || filterState.selectedLevelId.length !== 0) {
      const filterInsItems = getInsFilterItems(totalCoursesState.orgTableData);
      const filterGradeItems = getGradeFilterItems(filterInsItems);
      const filterLevelItems = getLevelFilterItems(filterGradeItems);

      setTotalCoursesState({ ...totalCoursesState, tableData: filterLevelItems });
    } else {
      setTotalCoursesState({ ...totalCoursesState, tableData: totalCoursesState?.orgTableData });
    }
  };

  useEffect(() => {
    totalEnrolledUsersData();
    setIsLoading(true);
  }, [subCategoryId]);

  useEffect(() => {
    if (totalCoursesState !== undefined) {
      handleFiltersChange();
    }
  }, [filterState.showFilterBase]);

  return (
    <>
      {totalCoursesState !== undefined && filterState.showFilterBase && <FilterBase filterType="UserCourses" data={totalCoursesState} filterState={filterState} setFilterState={setFilterState} onApplyFilters={handleFiltersChange} />}

      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="ms-5 overflow-hidden">

        <ViewAllCourses
          viewAllProp={{
            title: sessionStorage.getItem("subCategoryName"),
            resumeData: totalCoursesState.tableData,
            isCourse: isTechTalk ? false : true,
            componentType: isTechTalk ? "Play" : "Enrolled",
            loadingState: isLoading,
            totalCoursesState: totalCoursesState,
            setTotalCoursesState: setTotalCoursesState,
            filterState: filterState,
            setFilterState: setFilterState
          }}
        />
      </div>
    </>
  );
};

export default CoursesByCategory;