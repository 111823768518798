import { React, useEffect, useState, axios, decryptData, Header, Link, useNavigate, SideNav, Chevron, InfoIcon, handleApiError, CustomModal, SuccessfullVector, InfoVector, FormatDate, useLocation,AlertInfo} from "../../MentorImport";
import "./GradeAssignment.css";

const GradeAssignment = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  const [ratingData, setRatingData] = useState({
    tableData: [],
    isRowEditable: false,
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
    cancelText: "",
    confirmText: "",
  });

  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const { id, firstName, lastName, submissionDate, assignmentLink, hostedLink } = userDetails ?? {};

  const navigate = useNavigate();
  const location = useLocation();
  const ratingArray = ["Not Acceptable", "Needs Improvement", "Meets Requirement", "Exceeds Expectations"];
  const data = localStorage.getItem("userData");
  let parsedObject;
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  const cancelToken = axios.CancelToken.source();

  const fetchData = async () => {
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Review/getUserCourseAssignmentReviewDetails?userReviewId=${id}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      cancelToken: cancelToken.token,
    };
    try {
      const response = await axios.get(apiUrl, { headers });
      if (response.data.success === 200) {
        setRatingData((prevState) => ({
          ...prevState,
          tableData: response.data.result,
          isRowEditable: !response.data.result.isSubmitted,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  function getInitials(name) {
    const names = name.split(" ");
    const initials = names.map((n) => n.charAt(0).toUpperCase());
    return initials.join("");
  }

  const handleInputChange = (e, column) => {
    let value = e.target.value;
    value = value.replace(/[^1-4]/g, "").slice(0, 1);

    const updatedTableData = { ...ratingData.tableData, [column]: value };
    const sum = Object.keys(updatedTableData).reduce((acc, key) => {
      if (key !== "id" && key !== "overall" && key !== "isSubmitted" && key !== "isActive" && key !== "createdDate" && key !== "message" && key !== "modifiedDate" && key !== "success" && key !== "updatedBy") {
        const firstChar = updatedTableData[key][0];
        if (firstChar !== undefined) {
          const intValue = parseInt(firstChar, 10);
          if (!isNaN(intValue)) {
            return acc + intValue;
          }
        }
      }
      return acc;
    }, 0);

    updatedTableData["overall"] = sum > 0 ? `${((sum / 36) * 100).toFixed(2)}%` : sum === 0 ? "0%" : "--%";
    setRatingData((prevState) => ({
      ...prevState,
      tableData: updatedTableData,
    }));
  };

  const isDataValid = () => {
    const obj = ratingData.tableData;
    if (ratingData.isRowEditable && obj.learningAgility && obj.learningAgility !== 0 && obj.attitude && obj.attitude !== 0 && obj.progress && obj.progress !== 0 && obj.listeningSkills && obj.listeningSkills !== 0 && obj.language && obj.language !== 0 && obj.problemSolving && obj.problemSolving !== 0 && obj.teamWorkSkill && obj.teamWorkSkill !== 0 && obj.businessAcumen && obj.businessAcumen !== 0 && obj.currentAssignment && obj.currentAssignment !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const sendRatedData = async () => {
    const editedRowData = ratingData.tableData;
    const taskReview = {
      id: id,
      learningAgility: editedRowData.learningAgility !== 0 ? editedRowData.learningAgility : "0",
      attitude: editedRowData.attitude !== 0 ? editedRowData.attitude : "0",
      progress: editedRowData.progress !== 0 ? editedRowData.progress : "0",
      listeningSkills: editedRowData.listeningSkills !== 0 ? editedRowData.listeningSkills : "0",
      language: editedRowData.language !== 0 ? editedRowData.language : "0",
      problemSolving: editedRowData.problemSolving !== 0 ? editedRowData.problemSolving : "0",
      teamWorkSkill: editedRowData.teamWorkSkill !== 0 ? editedRowData.teamWorkSkill : "0",
      businessAcumen: editedRowData.businessAcumen !== 0 ? editedRowData.businessAcumen : "0",
      currentAssignment: editedRowData.currentAssignment !== 0 ? editedRowData.currentAssignment : "0",
      overall: parseFloat(editedRowData.overall),
    };

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL_DOTNET + `/Review/submitUserCourseAssignmentReview`, taskReview, { headers: { Authorization: `Bearer ${parsedObject["token"]}` }, cancelToken: cancelToken.token });
      if (response.data.success === 200) {
        fetchData();
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: response.data.message,
          modalMessage: "",
          icon: <SuccessfullVector />,
          cancelText: "",
          confirmText: "",
        }));
      }
      else {
        fetchData();

        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error!",
          modalMessage: response.data.message,
          icon: <AlertInfo />,
        }));
      }
    } catch (error) {
      console.error("This is error in report:", error);
    }
  };

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const fullName = (firstName || "") + " " + (lastName || "");
  const initials = getInitials(fullName);

  useEffect(() => {
    fetchData();
    return () => {
      cancelToken.cancel("request cancelled");
    };
  }, [selectedBatches]);

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.removeItem("userDetails");
    };
    return () => {
      handleRouteChange();
    };
  }, [location.pathname]);

  return (
    <div>
      <div style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
        <SideNav />
      </div>
      <div className="grade-assignment-parent-wrapper">
        <div className="grade-assignment-breadChrum">
          <Link to="/mentor/assign-task" className="crumb-parent">
            Instructor Graded Assignment
          </Link>
          <Chevron />
          <span>Grade Assignment</span>
        </div>
        <div className="grade-assignment-data-wrapper">
          <div className="grade-assignment-heading-style">
            <h3>Grade Assignment</h3>
          </div>
          <div className="grade-assignment-table-box-style">
            <div className="row">
              <div className="frame">
                <p className="d-flex justify-content-center align-items-center m-0 grade-assignment-initials">{initials}</p>
              </div>
              <div className="d-flex align-items-center col-4">
                <div className="frame-text grade-assignment-name">{fullName}</div>
              </div>
            </div>
            <div className="grade-assignment-border">
              <div className="d-grid gap-2">
                <div className="d-flex justify-content-between">
                  <div className="grade-assignment-link-name">
                    Assignment Link:
                    <a href={assignmentLink} className="grade-assignment-link pointer ms-2">
                      {assignmentLink.length < 50 ? assignmentLink : assignmentLink.slice(0, 50).concat("...")}
                    </a>
                  </div>
                  <div className="grade-assignment-submission">
                    Submission Date: <span className="grade-assignment-submission-date">{FormatDate(submissionDate)}</span>
                  </div>
                </div>
                <div className="grade-assignment-link-name">
                  Hosted Link:
                  {hostedLink === null || hostedLink === "" ? <span className="ms-2 grade-assignment-no-link">NA</span> : <a href={hostedLink} className="grade-assignment-link pointer ms-2">
                    {hostedLink.length < 50 ? hostedLink : hostedLink.slice(0, 50).concat("...")}
                  </a>}
                </div>
              </div>
            </div>
            <div className="grade-assignment-table-description-style">
              <div className="desc-child-one">
                <InfoIcon />
              </div>
              <div className="grade-assignment-desc-child-two">
                <p className="mb-0 fw-bold">Please rate Associates in each area using the following guidelines:</p>
                <div className="grade-assignment-rate-info-wrapper">
                  {ratingArray.map((rateVal, rateInd) => {
                    return (
                      <div className="desc-value-wrapper">
                        <span className="fw-bold">{`${rateInd + 1}`}</span> &nbsp;
                        <span>{`- ${rateVal}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="table-container-parent-wrapper">
              <div className="grade-assignment-table-container">
                <table className="grade-assignment-custom-table">
                  <thead>
                    <tr>
                      <th className="align-left ps-3">CG Students</th>
                      <th>Learning Agility</th>
                      <th>Attitude</th>
                      <th>Progress</th>
                      <th>Listening Skills</th>
                      <th>Language (English)</th>
                      <th>Problem Solving</th>
                      <th>Interpersonal and Team Work Skill</th>
                      <th className="grade-assignment-business-column">Business Acumen (Underset and the project business details)</th>
                      <th>Current Assignment</th>
                      <th>Percentage (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingData.tableData && (
                      <tr style={{ opacity: ratingData.isRowEditable ? 1 : 0.5 }}>
                        <td className="align-left fw-bold ps-3">{`${fullName}`}</td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.learningAgility}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => { handleInputChange(e, "learningAgility") }}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.learningAgility
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.attitude}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "attitude")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.attitude
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.progress}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => { handleInputChange(e, "progress") }}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.progress
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.listeningSkills}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "listeningSkills")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.listeningSkills
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.language}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "language")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.language
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.problemSolving}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "problemSolving")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.problemSolving
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.teamWorkSkill}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "teamWorkSkill")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.teamWorkSkill
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.businessAcumen}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "businessAcumen")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.businessAcumen
                          )}
                        </td>
                        <td>
                          {ratingData.isRowEditable ? (
                            <input
                              type="text"
                              value={ratingData.tableData.currentAssignment}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(e) => handleInputChange(e, "currentAssignment")}
                              readOnly={!ratingData.isRowEditable}
                            />
                          ) : (
                            ratingData.tableData.currentAssignment
                          )}
                        </td>
                        <td>{ratingData.tableData?.overall !== "" && ratingData.tableData?.overall !== null ? ratingData.tableData.overall : "--%"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="grade-assignment-save-button-wrapper" style={{ alignSelf: "end" }}>
              <button
                onClick={() => {
                  setRatingData((prevData) => ({
                    ...prevData,
                    isModalOpen: true,
                    modalType: "confirm",
                    modalTitle: "Are You Sure?",
                    modalMessage: "Do you want to save these records? This process cannot be undone.",
                    icon: <InfoVector />,
                    cancelText: "No, Cancel",
                    confirmText: "Yes, Sure",
                  }));
                }}
                disabled={isDataValid()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
            onConfirm: sendRatedData,
            cancelText: ratingData.cancelText,
            confirmText: ratingData.confirmText,
          }}
        />
      )}
    </div>
  );
};

export default GradeAssignment;
