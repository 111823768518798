import { React, useMemo, DownTrend, UpTrend, NoChangeTimeVector } from "./../../../../UploadCourses/UploadCoursesImport";
import "./TimeSpent.css";

const TimeSpent = (props) => {
  const { timeData } = props;

  function convertSecondsToHoursAndMinutes(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    return { hours, minutes };
  }

  const { hours: totalHours, minutes: totalMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(timeData.totalTimeWorked || 0), [timeData.totalTimeWorked]);
  const { hours: currentWeekHours, minutes: currentWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(timeData.currentWeekTime || 0), [timeData.currentWeekTime]);
  const { hours: previousWeekHours, minutes: previousWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(timeData.previousWeekTime || 0), [timeData.previousWeekTime]);

  const absoluteDifferenceHours = Math.abs(currentWeekHours - previousWeekHours) + Math.abs((currentWeekMinutes - previousWeekMinutes) / 60);

  return (
    <>
      <div className="time-main-container">
        <div className="total-time">
          <div className="t-heading">Total Time Spent</div>
          <div className="minute-hour-container">
            {totalHours} hours {totalMinutes} min
          </div>
        </div>
        <div className="d-flex justify-content-between week-container">
          <div>
            <div className="week-heading">This Week</div>
            <div className="week-minute-hour-container">{currentWeekHours}</div>
            <div className="hours">Hours</div>
          </div>
          <div className="mt-4 me-5">
            <div className="difference-minute-hour-container d-flex gap-1 justify-content-center align-item-center">
              <div className="mt-1 me-1">
                {timeData.trendMessage === "upTrend" && <UpTrend />}
                {timeData.trendMessage === "downTrend" && <DownTrend />}
                {timeData.trendMessage === "noChange" && <NoChangeTimeVector />}
              </div>
              <div className="mt-1">{absoluteDifferenceHours.toFixed(2)} hours</div>
            </div>
            <div className="vs-lastWeek mt-1">vs last Week</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSpent;
