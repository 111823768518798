import {useState,useEffect,useNavigate,CalendarIcon,TechActionType,UserActionType,decryptData,useReducer,axios,ExpandMore,UsersDropdown,TechDropdown,DatePicker,SuccessfullVector,CustomModal} from "../MentorImport";
import "./MentorAssignNewTask.css";

function CustomInput({ value, onClick }) {
  return (
    <div className="row addTask-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 addTask-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">
        <CalendarIcon />
      </span>
    </div>
  );
}
const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECT_ALL_USER_CHECKED:
      return { ...state, selectAllChecked: action.payload };
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };

    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };

    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectAllChecked: false,
  selectedUserIds: [],
  selectedUserName: [],
  usersValue: "",
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};
const MentorAssignNewTask = (props) => {
  const {mentorId,fetchData,selectedBatches} = props;
  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const [task, setTask] = useState({
    taskName: "",
    taskDescription: "",
    startDate: "",
    endDate: "",
    error: {
      nameError: false,
      descriptionError: false,
    },
    ratedTask : false
  });

  const regEx = {
    nameRegex: /^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/,
    descriptionRegex: /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{50,500}$/,
    stringRegex: /[^,]+/g,
  };
  const navigate = useNavigate();
  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = Object.values(state.selectedUserName).join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  const handleClose = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({
        type: TechActionType.SET_TECH_VALUE,
        payload: techValueString,
      });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({
      type: UserActionType.SET_SELECTED_USER_IDS,
      payload: userIdData,
    });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const isFieldValid = (value, regex) => {
    return value && regex.test(value);
  };
  const isArrayValid = (value, regex) => {
    return value && value.match(regex);
  };
  const areAllFieldsFilled = () => {
    return (
      isFieldValid(task.taskName, regEx.nameRegex) &&
      isFieldValid(task.taskDescription, regEx.descriptionRegex) &&
      task.startDate &&
      task.endDate &&
      state.selectedTechIds.length !== 0 &&
      state.selectedUserIds.length !== 0 &&
      !(state.selectedTechNames && state.selectedTechNames.length > 10)
    );
  };
  const handleClickClear = () => {
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
    setTask((prevState) => ({
      ...prevState,
      taskName: "",
      taskDescription: "",
      startDate: "",
      endDate: "",
      error: {
        nameError: false,
        descriptionError: false,
      },
      ratedTask: false
    }));
    dispatch({ type: "RESET_STATE" });
    const userCheckboxes = document.querySelectorAll(".user-checkbox");
    userCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    if (
      !isFieldValid(task.taskName, regEx.nameRegex) ||
      !isFieldValid(task.taskDescription, regEx.descriptionRegex) ||
      !isArrayValid(state.techValue, regEx.string) ||
      !isArrayValid(state.usersValue, regEx.string)
    ) {
      alert("Please fill out the necessary fields");
    } else {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/add`;
      const body = {
        taskName: task.taskName,
        taskDescription: task.taskDescription,
        ratedTask:task.ratedTask,
        assignedBy: mentorId,
        TechId: state.selectedTechIds,
        UserId: state.selectedUserIds,
        startDate: formatDateToString(task.startDate),
        endDate: formatDateToString(task.endDate),
        techNames: [],
        name: [],
      };
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      };
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 201) {
        setIsOpen(() => ({
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Task Added Successfully",
          modalMessage: "Task has been Added Successfully",
          icon: <SuccessfullVector />,
        }));
        handleClickClear();
        const userCheckboxes = document.querySelectorAll(".user-checkbox");
        userCheckboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        const checkboxes = document.querySelectorAll(".tech-checkbox");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        alert(response.data.result.message);
      }
      fetchData();
    }
  };

  const handleInputChnage = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "title":
        if (isFieldValid(value, regEx.nameRegex)) {
          setTask((prevState) => ({
            ...prevState,
            taskName: e.target.value,
            error: {
              ...prevState.error,
              nameError: false,
            },
          }));
        } else {
          setTask((prevState) => ({
            ...prevState,
            taskName: e.target.value,
            error: {
              ...prevState.error,
              nameError: true,
            },
          }));
        }
        break;
      case "description":
        if (isFieldValid(value, regEx.descriptionRegex)) {
          setTask((prevState) => ({
            ...prevState,
            taskDescription: e.target.value,
            error: {
              ...prevState.error,
              descriptionError: false,
            },
          }));
        } else {
          setTask((prevState) => ({
            ...prevState,
            taskDescription: e.target.value,
            error: {
              ...prevState.error,
              descriptionError: true,
            },
          }));
        }
        break;
    }
  };
  return (
    <>
      <div className="modal fade" id="addTaskModal" tabIndex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-1">
              <h5 className="modal-title modalheading-text" id="skillModalLabel">
                Add New Task
              </h5>

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => handleClickClear(e)}></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="task-title" className="col-form-label form-title-names">
                    Task Title<span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" id="task-title" placeholder="Enter task name" value={task.taskName} name="title" onChange={handleInputChnage} />
                  {task.taskName.length > 0 && task.error.nameError && (
                    <span className ="text-error">Please Enter a name only with letter and space, between 1 and 100 characters</span>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="col-form-label form-title-names">
                    Description<span className="text-danger">* </span>
                  </label>

                  <textarea className="form-control" id="description" rows={3} placeholder="Enter description" value={task.taskDescription} name="description" onChange={handleInputChnage}></textarea>
                  {task.taskDescription.length > 0 && task.error.descriptionError && (
                    <span className="text-error">Please Enter description only with letters, colon, comma and hyphen, between 50 and 500 characters</span>
                  )}
                </div>

                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="start-date" className="col-form-label form-title-names">
                        Start Date<span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        minDate={new Date()}
                        selected={task.startDate}
                        popperPlacement="top-end"
                        onChange={(date) => {
                          setTask((prevState) => ({
                            ...prevState,
                            startDate: date,
                            endDate: new Date(task.endDate) >= new Date(date) ? new Date(task.endDate) : "",
                          }));
                        }}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="end-date" className="col-form-label form-title-names">
                        End Date<span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        minDate={task.startDate != "" ? task.startDate : new Date()}
                        selected={task.endDate}
                        onChange={(date) => {
                          setTask((prevState) => ({
                            ...prevState,
                            endDate: date,
                          }));
                        }}
                        popperPlacement="top-end"
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label className="col-form-label form-title-names" htmlFor="technology-tag">
                    Technology Tag<span className="text-danger">*</span>
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={() => {
                        handleDropdownToggle("techDropdown");
                      }}
                    >
                      <input type="text" className="custom-input tech-value" placeholder="Select Technology" value={state.techValue} disabled />
                      <div
                        className="modal-abs modal-style"
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.techDropdown && (
                        <div className="ul-styling" style={{height:"10rem"}}>
                          <TechDropdown
                            dispatch={dispatch}
                            techDataComingChild={handleTechChange}
                            selectedTechIdsfromChild={handleTechIdChange}
                            selectedTechIds={state.selectedTechIds}
                            technologyNames={state.selectedTechNames}
                            selectedTech={""}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {state.selectedTechNames && state.selectedTechNames.length > 10 && <span className="text-error">Maximum 10 technologies allowed</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="assigned-to" className="col-form-label form-title-names">
                    Assigned To<span className="text-danger">*</span>
                  </label>

                  <div className="container border p-0">
                    <div className="input-with-button">
                      <input type="text" className="custom-input tech-value" placeholder="Select Assigned To" value={state.usersValue} disabled />
                      <div
                        className="modal-abs user-dropdown"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDropdownToggle("userDropdown");
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.userDropdown && (<ul
                        style={{
                          height: "10rem",
                        }}
                        className="ul-styling"
                      >
                        <UsersDropdown
                          dispatch={dispatch}
                          selectedBatches={selectedBatches}
                          usersDataComingChild={usersDataComingFrmChild}
                          selectedUserIdsfromChild={handleUserIdChange}
                          selectedUserIds={state.selectedUserIds}
                          selectedUserName={state.selectedUserName}
                          selectAllUsersCheck={state.selectAllChecked}
                          selectedUsers={""}
                        />
                      </ul>)}
                    </div>
                  </div>
                </div>

                <div className="row mb-3 align-items-center">
                  <div className="col-md-10">
                    <label className="mb-1 custom-form-label">This is a Rated Task</label>
                  </div>
                  <div className="col-md-2">
                    <div className="form-check form-switch custom-switch-container">
                      <input type="checkbox" className="form-check-input" id="ratedTaskSwitch" 
                        checked={task.ratedTask} 
                        onChange={() => setTask((prevState) => ({
                          ...prevState,
                          ratedTask: !task.ratedTask,
                        }))} />
                      <label className="form-check-label" htmlFor="ratedTaskSwitch"></label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-top-0">
              <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={(e) => handleClickClear(e)}>
                <span className="cancel-text">Cancel</span>
              </button>

              <button
                type="button"
                className="btn btn-primary save-button"
                data-bs-dismiss={areAllFieldsFilled() ? "modal" : ""}
                data-bs-target="#addTaskModal"
                onClick={(e) => handleSubmit(e)}
                disabled={!areAllFieldsFilled()}
              >
                <span className="save-text-field">Save</span>
              </button>
            </div>
          </div>
        </div>
        {isOpen.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: isOpen.icon,
              show: isOpen.isModalOpen,
              type: isOpen.modalType,
              title: isOpen.modalTitle,
              message: isOpen.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
      </div>
    </>
  );
};
export default MentorAssignNewTask;
