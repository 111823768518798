import { useState, useEffect,axios, useNavigate,decryptData,handleApiError,Masonry} from "../AdminPortal/AdminImport";
import "./Header.css";
import "./CourseCategoriesDropdown.css";


const CourseCategoriesDropdown = (props) => {
  const { setCourseCategoryDropdown } = props;
  const [categoryData, setCategoryData] = useState([]);

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  const fetchCourseDetail = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/getAllCategoryAndSubCategory`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        setCategoryData(response.data.result.filter((category) => category?.subCategories?.length > 0));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  useEffect(() => {
    fetchCourseDetail();
  }, []);

  const handleSubCategoryClick = (category, subCategoryId, subCategoryName) => {
    let isTechTalk = false;
    if (category?.name === "Tech Talk") {
      isTechTalk = true;
    }
    sessionStorage.setItem("isTechTalk", isTechTalk);
    sessionStorage.setItem("subCategoryName", subCategoryName);
    navigate(`/lms/course-by-category/${subCategoryId}`);
    setCourseCategoryDropdown(false);
  };

  return (
    <>
      <Masonry
        breakpointCols={{ default: 4 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {categoryData?.map((category, index) => (
          <div key={index} className="category">
            <h5 className="category-name-bg fw-bold mb-0">{category.name}</h5>
            <div className="sub-categories">
              {category?.subCategories?.map((subCategory, subIndex) => (
                <p key={subIndex} onClick={() => handleSubCategoryClick(category, subCategory.id, subCategory.name)}>
                  {subCategory.name}
                </p>
              ))}
            </div>
          </div>
        ))}
      </Masonry>
    </>
  );
};

export default CourseCategoriesDropdown;
