import React from "react";
import Skeleton from "react-loading-skeleton";

const QuizLoadingSkeleton = () => {
  return (
    <div className="card insidecard">
      <div>
        <div className="main-heading">
          <Skeleton className="ms-4" count={2} width={1000} height={32} />
        </div>
        <div className="quiz-description"></div>
      </div>
      <div>
        <div className="ques-of-quiz"></div>
        <div className="start-input">
          <label className="checkbox-option">
            <Skeleton count={3} width={650} height={30} />
          </label>
        </div>
      </div>
      <div className="submit-button">
        <Skeleton width={500} height={50} />
      </div>
    </div>
  );
};

export default QuizLoadingSkeleton;
