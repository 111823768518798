import { useState, useEffect } from "react";
import "./ProjectDetail.css";
import { Link } from "react-router-dom";
import { ReactComponent as EditButton } from "../../../../../Assets/Buttonedit.svg";
import { ReactComponent as DeleteButton } from "../../../../../Assets/Buttondelete.svg";
import ProjectModalEdit from "./EditModalProject";
import DeleteProject from "./DeleteProject";
import { ReactComponent as Complete } from "../../../../../Assets/ButtonCompleteTag.svg";
import { ReactComponent as Clock } from "../../../../../Assets/clock-regular 1logClock.svg";

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

const truncate = (str, maxLength) => {
  if (str.length > maxLength) {
    if (str.indexOf(" ") === -1) {
      // Break the line at 80 characters without spaces
      let truncatedStr = "";
      for (let i = 0; i < str.length; i++) {
        truncatedStr += str[i];
        if ((i + 1) % 80 === 0) {
          truncatedStr += "\n";
        }
      }
      return truncatedStr;
    } else {
      // Break the line at the last space before 80 characters
      const lastSpaceIdx = str.lastIndexOf(" ", maxLength);
      return str.slice(0, lastSpaceIdx) + "\n" + str.slice(lastSpaceIdx + 1);
    }
  } else {
    return str;
  }
};

const ProjectDetail = ({
  data,
  mentorApiData,
  indexNumber,
  mentorIndexNumber,
  setTaskVersion,
  taskVersion,
}) => {
  const [projectIdToChild, setProjectIdToChild] = useState(0);
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [isOpen, setIsOpen] = useState(indexNumber === 0);
  const [textInput, setTextInput] = useState("");
  const [isCancelClick, setIsCancelClick] = useState(false);

  const props = {
    idProp: (data[indexNumber]?.name) ? "id" : "taskId",
    idValue: data[indexNumber]?.id,
    nameProp: data[indexNumber]?.id ? "name" : "taskName",
    descriptionProp: data[indexNumber]?.id
      ? "descriptions"
      : "taskDescription",
  };

  const deleteTask = (e, projectId, index) => {
    e.preventDefault();
    setProjectIdToChild(projectId);
    setShowDeleteTask(true);
    setIsOpen(true);
  };
  useEffect(() => {
    setIsOpen(true);
  }, [data]);

  return (
    <>
      {/* /dit or delte */}
      {showDeleteTask && (
        <DeleteProject
          projectId={projectIdToChild}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setShowDeleteTask={setShowDeleteTask}
          setTaskVersion={setTaskVersion}
        />
      )}

      {data !== null &&
        data.length > indexNumber &&
        mentorIndexNumber === 0 && (
          <div >
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div
                style={{ display: "flex", flexWrap: "wrap", maxWidth: "35rem" }}
              >
                <h5 className="project-detail-name">
                  {data[indexNumber]?.name}{" "}
                  {data[indexNumber]?.taskName}
                </h5>
              </div>
              <div>
                <EditButton
                  className="mx-3 clickable"
                  data-bs-toggle="modal"
                  data-bs-target="#editProjectModal"
                  onClick={(e) => {
                    setTextInput(
                      data[indexNumber]?.members
                        ?.filter((tech) => tech !== null)
                        .join(",")
                    );
                    setIsCancelClick(false);
                  }}
                />

                <ProjectModalEdit
                  {...props}
                  isAssignedByMentor={
                    data[indexNumber]?.taskName ? true : false
                  }
                  setTaskVersion={setTaskVersion}
                  taskVersion={taskVersion}
                  projectName={
                    data[indexNumber]?.name ||
                    data[indexNumber]?.taskName
                  }
                  projectDescriptions={
                    data[indexNumber]?.description ||
                    data[indexNumber]?.taskDescription
                  }
                  projectTechnology={
                    data[indexNumber]?.technologyUsed?.filter(
                      (tech) => tech.techName !== null
                    ) || data[indexNumber]?.techNames
                  }
                  projectLinks={data[indexNumber]?.projectLink || ""}
                  hostedLinks={data[indexNumber]?.hostedLink || ""}
                  memberName={
                    data[indexNumber]?.members?.filter(
                      (tech) => tech !== null
                    ) || ""
                  }
                  textInput={textInput}
                  setTextInput={setTextInput}
                  indexNumber={indexNumber}
                  projectVersion={true}
                  isCancelClick={isCancelClick}
                  setIsCancelClick={setIsCancelClick}
                />
                {!data[indexNumber]?.taskName &&
                  (
                    <DeleteButton
                      className="clickable"
                      projectId={data[indexNumber]?.id}
                      onClick={(e) => {
                        deleteTask(e, data[indexNumber]?.id, indexNumber);
                      }}
                    />
                  )}
              </div>
            </div>
            <div className="task-deadline d-flex gap-2">
              <Clock />
              <p>
                {data[indexNumber]?.createdDate
                  ? formatDate(data[indexNumber]?.createdDate)
                  : formatDate(data[indexNumber]?.startDate)}
              </p>
              {data[indexNumber]?.endDate && <p>-</p>}
              {data[indexNumber]?.endDate &&
                <p>
                  {formatDate(data[indexNumber]?.endDate)}
                </p>
              }
            </div>
            <p className="project-detail-text">
              {data[indexNumber]?.description?.length > 100
                ? truncate(data[indexNumber]?.description, 100)
                : data[indexNumber]?.description}
              {data[indexNumber]?.taskDescription?.length > 100
                ? truncate(data[indexNumber]?.taskDescription, 100)
                : data[indexNumber]?.taskDescription}
            </p>
            {(data[indexNumber]?.technologyUsed && data[indexNumber]?.technologyUsed?.length > 0) && (
              <p className="project-detail-technology-used mb-2">
                Technology Used:
              </p>
            )}
            {data[indexNumber]?.techNames &&
              !data[indexNumber]?.techNames?.every(
                (value) => value === null
              ) && (
                <p className="project-detail-technology-used mb-2">
                  Technology Used:
                </p>
              )}

            <div className="project-detail-technology-badges">
              {data[indexNumber]?.technologyUsed?.map &&
                data[indexNumber]?.technologyUsed?.map((tech) => {
                  if (tech != null) {
                    return <p className="technology-badge me-1"> {tech.techName} </p>;
                  }
                })}
              {data[indexNumber]?.techNames?.map &&
                data[indexNumber]?.techNames?.map((tech) => {
                  if (tech != null) {
                    return <p className="technology-badge me-1"> {tech} </p>;
                  }
                })}
            </div>

            {data[indexNumber]?.projectLink && (
              <>
                <p className="project-detail-link">Project Link:</p>
                <p className="project-link-name">
                  <Link to={data[indexNumber]?.projectLink} target="_blank">
                    {data[indexNumber]?.projectLink.length > 80 ? `${data[indexNumber]?.projectLink.slice(0, 80)}...` : data[indexNumber]?.projectLink}
                  </Link>
                </p>
              </>
            )}
            {data[indexNumber]?.hostedLink && (
              <>
                <p className="project-detail-hosted-link">Hosted Link:</p>
                <p className="hosted-link-name text-decoration-none">
                  <Link to={data[indexNumber].hostedLink} target="_blank">
                    {data[indexNumber]?.hostedLink.length > 80 ? `${data[indexNumber]?.hostedLink.slice(0, 80)}...` : data[indexNumber]?.hostedLink}
                  </Link>
                </p>
              </>
            )}
            <div className="members-div pt-0">
              {(data[indexNumber]?.members && data[indexNumber]?.members?.length > 0) && <div className="member fw-bold mb-2 mt-2">Members:</div>}
              <div className="project-members ml-0">
                {data[indexNumber]?.members?.length > 4 ? (
                  <>
                    {data[indexNumber]?.members.slice(0, 4).map((member, index) => {
                      const initials = member?.name?.split(" ").map((word) => word[0]).join("").toUpperCase();
                      return (
                        <div className="project-idea-members" key={index}>
                          <p className="name-of-members" title={member?.name}>
                            {initials}
                          </p>
                        </div>
                      );
                    })}
                    <div className="count-of-members">
                      <p className="name-of-members" title={data[indexNumber]?.members.slice(4).map((member) => {
                        return member.name
                      }).join(", ")}>
                        + {data[indexNumber]?.members?.length - 4}
                      </p>
                    </div>
                  </>
                ) : (
                  data[indexNumber]?.members?.map((member, index) => {
                    const initials = member?.name?.split(" ").map((word) => word[0]).join("").toUpperCase();
                    return (
                      <div className="project-idea-members" key={index}>
                        <p className="name-of-members" title={member.name}>
                          {initials}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className="members-div mt-4">
              {data[indexNumber]?.taskName && data[indexNumber]?.projectLink && (
                <Complete />
              )}
            </div>
          </div>
        )}
      {mentorIndexNumber !== null && mentorApiData !== null && (
        <>
          <h5 className="project-detail-name">
            {mentorApiData[mentorIndexNumber]?.taskName}
          </h5>
          <p className="created-at">
            {mentorApiData[mentorIndexNumber]?.assignedDate}
          </p>
          <p className="project-detail-text">
            {mentorApiData[mentorIndexNumber]?.taskDescription}
          </p>
          {mentorApiData[mentorIndexNumber]?.techNames && (
            <p className="project-detail-technology-used mb-2">
              Technology Used:
            </p>
          )}
          <div className="project-detail-technology-badges">
            {mentorApiData[mentorIndexNumber]?.techNames?.map &&
              mentorApiData[mentorIndexNumber]?.techNames?.map((tech) => {
                if (tech != null) {
                  return <p className="technology-badge me-1"> {tech} </p>;
                }
              })}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectDetail;
