import React, { useState, useEffect, createContext, useMemo } from "react";

export const UserContext = createContext();
const Context = (props) => {
  const [score, setScore] = useState(-1);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(
    parseInt(localStorage.getItem("elapsedTimeMain")) || 0
  );
  const [isStopPressed, setIsStopPressed] = useState(false);
  const [projectApiData, setProjectApiData] = useState();
  const [startTime, setStartTime] = useState(
    localStorage.getItem("startTime")
      ? parseInt(localStorage.getItem("startTime"))
      : null
  );
  const [idea, setIdea] = useState([]);
  const [project, setProject] = useState([]);
  const [examName, setExamName] = useState("");
  const [examLevel, setExamLevel] = useState("");
  const [dateOfTest, setDateOfTest] = useState("");
  const [timeOfTest, setTimeOfTest] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const resetTimer = () => {
    setElapsedTime(0);
    setScore(-1);
    setIsRunning(false);
    setIsPaused(false);
    localStorage.removeItem("elapsedTimeMain");
  };

  useEffect(() => {
    let interval = null;
    let timerStartTime;

    if (isRunning) {
      if (isPaused) {
        if (interval) {
          clearInterval(interval);
        }
      } else {
        timerStartTime = Date.now() - elapsedTime;
        interval = setInterval(() => {
          const currentElapsedTime = Date.now() - timerStartTime;
          setElapsedTime(currentElapsedTime);
          localStorage.setItem(
            "elapsedTimeMain",
            currentElapsedTime.toString()
          );
        }, 1000);
      }
    } else {
      localStorage.removeItem("elapsedTimeMain");
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning, isPaused]);

  useEffect(() => {
    if (!isRunning && elapsedTime === 0) {
      localStorage.removeItem("elapsedTimeMain");
    }
  }, [isRunning, elapsedTime]);

  useEffect(() => {
    const storedElapsedTime = parseInt(localStorage.getItem("elapsedTimeMain"));
    const loginState = localStorage.getItem("login");
    if (
      isRunning &&
      !isPaused &&
      !isNaN(storedElapsedTime) &&
      loginState == "true"
    ) {
      setElapsedTime(storedElapsedTime);
    }
  }, []);

  useEffect(() => {
    let data = localStorage.getItem("userData");
    if (data) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, []);

  useMemo(() => {
    let data = localStorage.getItem("userData");
    if (data) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{
          score,
          setScore,
          isRunning,
          setIsRunning,
          isPaused,
          setIsPaused,
          elapsedTime,
          setElapsedTime,
          projectApiData,
          setProjectApiData,
          startTime,
          setStartTime,
          idea,
          setIdea,
          project,
          setProject,
          resetTimer,
          examName,
          setExamName,
          examLevel,
          setExamLevel,
          dateOfTest,
          setDateOfTest,
          timeOfTest,
          setTimeOfTest,
          isStopPressed,
          setIsStopPressed,
          setIsAuthenticated,
          isAuthenticated
        }}
      >
        {props.children}
      </UserContext.Provider>
    </>
  );
};

export default Context;
