import { React, Skeleton } from "./../../../../UploadCourses/UploadCoursesImport";

const CertificateSkeleton = () => {
  return (
    <div>
      <div className="ms-3 me-4 border-bottom">
        <div className="d-flex gap-1 ms-3 mt-2 align-item-center">
          <Skeleton width={20} height={20} />
          <div className="sub-name mt-1">
            <Skeleton width={150} />
          </div>
        </div>
        <div className="ms-3 mt-1 certification-name">
          <Skeleton />
        </div>
        <div className="d-flex gap-1">
          <span className="issued ms-3 mt-1">
            <Skeleton />
          </span>
          <div className="issue-date mt-1">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateSkeleton;
