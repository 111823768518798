import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import "./ViewAllIdea.css";
import Header from "../../../../../Header/Header";
import EmptyIdea from "../../../EmptyStates/EmptyProject/MyIdeaViewAll";
import DetailsLeft from "../../ViewDetails/DetailsLeft";
import { ReactComponent as ExpandMore } from "../../../../../../Assets/expand_more.svg";
import axios from "axios";
import BreadCrumbs from "../../../../../_utilities/BreadCrumbs/BreadCrumbs";
import IdeaDetails from "../../ViewDetails/IdeaDetails";
import { ReactComponent as VectorAdd } from "../../../../../../Assets/Vectoradd.svg";
import TechDropdown from "../../../../../AdminPortal/Task/AssignTask/TechDropdown";
import { decryptData } from "../../../../UserImport";
import TechActionType from "../../../../../_common/TechActionType";
import { SideNav, SuccessfullVector, UserActionType, UsersDropdown } from "../../../../../AdminPortal/AdminImport";
import CustomModal from "../../../../../Utilities/CommonModal/CustomModal";
import ProjectLoadingSkeleton from '../../ViewDetails/DetailsLeftSkeleton';
import ProjectDetailLoadingSkeleton from '../../ViewDetails/ProjectDetailsSkeleton';

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
  usersValue: "",
  selectedUserIds: [],
  selectedUserName: [],
  selectAllChecked: false,
};

const ViewAllIdeas = () => {
  const [idea, setIdea] = useState([]);
  const [projectIndex, setProjectIndex] = useState(0);
  const [nameError, setNameError] = useState(true);
  const [descError, setDescError] = useState(true);
  const [projName, setProjName] = useState("");
  const [membersError, setMembersError] = useState(false);
  const [projDescription, setProjDescription] = useState("");
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const [textInput, setTextInput] = useState("");
  const [memberNames, setMemberNames] = useState([]);
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [isProjectDescriptionValid, setIsProjectDescriptionValid] = useState(false);
  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [loading, setLoading] = useState(true);
  const [taskVersion, setTaskVersion] = useState(0);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = state.selectedUserName.join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const handleClose = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const navigate = useNavigate();

  const handelIndex = (index) => {
    setProjectIndex(index);
  };
  const handleClickClear = () => {
    setTextInput("");
    setProjName("");
    setProjDescription("");
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
    dispatch({ type: "RESET_STATE" });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };
  const handleChangeProjNameError = (e) => {
    e.preventDefault();
    const name = e.target.value;
    setProjName(name);
    setIsProjectNameValid(name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/) ? true : false);
    if (!name || !name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const handleChangeProjDescriptionError = (e) => {
    e.preventDefault();
    const description = e.target.value;
    setProjDescription(description);
    setIsProjectDescriptionValid(
      description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/) ? true : false
    );
    if (!description || !description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/)) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setTextInput(inputText);
    const memberNamesArray = inputText.split(",").map((name) => name.trim());
    setMembersError(memberNamesArray?.length > 10);
    const membersObj = {};
    memberNamesArray.forEach((name, index) => {
      membersObj[`member${index + 1}`] = name;
    });
    isObjectEmpty(membersObj);
  };

  const isObjectEmpty = (object) => {
    const memberNamesArray = Object.values(object).filter((value) => value.trim() !== "");
    setMemberNames(memberNamesArray);
  };

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/projectIdea/create`;
    const body = {
      name: projName,
      description: projDescription,
      techNames: state.selectedTechIds,
      memberNames: state.selectedUserIds,
      members: [],
      technologyUsed: []
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 200) {
        setIsOpen(() => ({
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Project Idea Added Successfully",
          modalMessage: "Project Idea has been Added Successfully",
          icon: <SuccessfullVector />,
        }));
        setTaskVersion((prevVersion) => prevVersion + 1);
        setProjectIndex(0);
        handleClickClear();
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
    }
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };


  useEffect(() => {
    const texts = textInput.split(",").map((text) => text.trim());
    const membersObj = {};
    texts.forEach((text, index) => {
      membersObj[`member${index + 1}`] = text;
    });

    isObjectEmpty(membersObj);
  }, [textInput]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL_DOTNET + `/projectIdea/getByUserId`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      })
      .then((response) => {
        setIdea(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/error/statusCode=401");
        }
        if (error.response.status === 400) {
          navigate("/error/statusCode=400");
        }
        if (error.response.status === 500) {
          navigate("/error/statusCode=500");
        }
        if (error.response.status === 404) {
          navigate("/error/statusCode=404");
        }
        console.error("Error fetching tasks:", error);
      });
  }, [taskVersion]);

  useEffect(() => {
    const texts = textInput.split(",").map((text) => text.trim());
    var membersObj = {};
    texts.forEach((text, index) => {
      membersObj[`member${index + 1}`] = text;
    });
    isObjectEmpty(membersObj);
  }, [textInput, state.selectedTechNames]);

  return (
    <>
      <div style={{ marginBottom: "4rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container page-color">
        <div className="view-all-nav-bar pt-4">
          <BreadCrumbs dashboardLink={"/dashboard"} />
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <p className="sub-text ps-0">My Idea</p>
          </div>

          <div className="add-new-project-wrapper me-0" data-bs-toggle="modal" data-bs-target="#viewAllAddModal">
            <p className="add-new-project">
              <VectorAdd /> Add New Idea
            </p>
          </div>

          <div className="modal fade" id="viewAllAddModal" tabIndex="-1" aria-labelledby="viewAllAddModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5 add-project-wrapper" id="viewAllAddModalLabel">
                    Add New Idea
                  </h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClickClear}></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="project-name" className="col-form-label title-text">
                        Project Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input type="text" className="form-control" value={projName} id="project-name" placeholder="Enter Project Name" onChange={handleChangeProjNameError} />
                      {!isProjectNameValid && projName && <span style={{ color: "red", fontSize: "11px" }}>Please enter a name with only letters and spaces, between 1 and 100 characters.</span>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="project-description" className="col-form-label title-text">
                        Project Description
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        value={projDescription}
                        id="project-description"
                        placeholder="Write Here.."
                        onChange={(e) => handleChangeProjDescriptionError(e)}
                        rows={3}
                      />
                      {!isProjectDescriptionValid && projDescription && (
                        <span style={{ color: "red", fontSize: "11px" }}>
                          Please Enter description only with letters, colon, comma and hyphen, between 150 and 750 characters.
                        </span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="technology-used" className="col-form-label title-text" required>
                        Technology Used<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="container border p-0">
                        <div
                          className="input-with-button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDropdownToggle("techDropdown");
                          }}
                        >
                          <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Technology" value={state.techValue} disabled />
                          <div
                            className="modal-abs"
                            style={{
                              position: "absolute",
                              left: "0",
                              right: "0",
                              top: "0",
                              bottom: "0",
                            }}
                          ></div>
                          <ExpandMore />
                        </div>
                        <div>
                          {dropDown.techDropdown && <ul
                            className="ul-styling"
                          >
                            <TechDropdown
                              dispatch={dispatch}
                              techDataComingChild={handleTechChange}
                              selectedTechIdsfromChild={handleTechIdChange}
                              selectedTechIds={state.selectedTechIds}
                              technologyNames={state.selectedTechNames}
                              selectedTech={""}
                            />
                          </ul>}
                        </div>
                      </div>
                      {state.selectedTechNames && state.selectedTechNames.length > 10 && <span style={{ color: "red", fontSize: "12px" }}>Maximum 10 technologies allowed</span>}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="Members(Optional)"
                        className="col-form-label title-text"
                      >
                        Members
                      </label>
                      <div className="container border p-0">
                        <div
                          className="input-with-button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDropdownToggle("userDropdown");
                          }}
                        >
                          <input
                            style={{ width: "27.5rem", outline: "none" }}
                            type="text"
                            className="custom-input"
                            placeholder="Select Members"
                            // value={state.usersValue && state.selectedUserName.length != 0 ? state.usersValue : ""}
                            value={state.usersValue}
                            disabled
                          />
                          <div
                            className="modal-abs"
                            style={{
                              position: "absolute",
                              left: "0",
                              right: "0",
                              top: "0",
                              bottom: "0",
                            }}
                          ></div>
                          <ExpandMore />
                        </div>
                        <div>
                          {dropDown.userDropdown && <ul
                            className="ul-styling"
                          >
                            <UsersDropdown
                              dispatch={dispatch}
                              usersDataComingChild={usersDataComingFrmChild}
                              selectedUserIdsfromChild={handleUserIdChange}
                              selectedUserIds={state.selectedUserIds}
                              selectedUserName={state.selectedUserName}
                              selectAllUsersCheck={state.selectAllChecked}
                              selectedUsers={""}
                            />
                          </ul>}
                        </div>
                      </div>
                      {membersError && (
                        <span style={{ color: "red", fontSize: "11px" }}>
                          Please enter upto 10 members only
                        </span>
                      )}
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={handleClickClear}>
                    <span className="cancel-text"> Cancel </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary save-button"
                    data-bs-target="#viewAllAddModal"
                    disabled={nameError || descError || state.selectedTechNames?.length === 0 || state.selectedTechNames?.length > 10 || membersError || !isProjectNameValid}
                    data-bs-dismiss={!nameError && !descError && state.selectedTechNames?.length !== 0 ? "modal" : ""}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <span className="save-text"> Save </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {idea == null || idea.length === 0 ? <EmptyIdea /> : <>
          <div className="all-project-idea-wrapper entire-component ms-0" style={{ overFlowY: "scroll" }}>
            {loading ? <ProjectLoadingSkeleton /> : (
              <div >
                <DetailsLeft project={idea} projectDetails={handelIndex} indexNumber={projectIndex} setIndexNumber={setProjectIndex} />
              </div>)}
            {loading ? <ProjectDetailLoadingSkeleton /> : (
              <div className="project-detail">
                <IdeaDetails data={idea} indexNumber={projectIndex} setIndexNumber={setProjectIndex} setTaskVersion={setTaskVersion} taskVersion={taskVersion} />
              </div>)}
          </div>
        </>}
        {isOpen.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: isOpen.icon,
              show: isOpen.isModalOpen,
              type: isOpen.modalType,
              title: isOpen.modalTitle,
              message: isOpen.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ViewAllIdeas;
