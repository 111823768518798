import { useMsal } from "@azure/msal-react";
import {ReactComponent as Microsoftwhitesigninlogo} from "../../../Assets/Microsoft-white-svg.svg";
import { loginRequest } from "./authconfig";
const Microsoftsigninbutton = ({isDisabled}) => {
  const { instance } = useMsal();
  const handleSigninWithMicrosoft = () => {
    instance.loginRedirect({
      loginRequest,
    });
  };

  return (
    <>
      <button
        type="submit"
        className="border btn d-flex justify-content-center align-items-center fw-bold single-sign-button mb-3"
        onClick={handleSigninWithMicrosoft} disabled={isDisabled}
      >
        <Microsoftwhitesigninlogo/> &nbsp;Sign in with Microsoft
      </button>
    </>
  );
};
export default Microsoftsigninbutton;
