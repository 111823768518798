import { useState, useEffect, useNavigate, decryptData, EmptyTodaySession, Skeleton, axios, handleApiError, Carousel, DefaultTechnology } from "../../AdminImport";
import "./TodaySession.css";
import "react-loading-skeleton/dist/skeleton.css";

const TodaySession = (props) => {
  const { selectedBatches, sendFunctionToDashboardA } = props;
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const sendFunction = () => {
    sendFunctionToDashboardA(fetchSessions);
  };

  useEffect(() => {
    fetchSessions();
  }, [selectedBatches]);

  const fetchSessions = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/SessionCalendar/getSessionDetails`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        setSessions(response.data.result);
        setIsLoading(false);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  function convertTo12HourFormat(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${minutes < 10 ? "0" : ""}${minutes} ${period} `;
    return formattedTime;
  }

  const todaySessions =
    sessions &&
    sessions.filter((session) => {
      const sessionDate = new Date(session.sessionStartDate);
      const today = new Date();
      return sessionDate.toDateString() === today.toDateString();
    });

  return (
    <>
      <div className="my-carousel-wrapper">
        <div className="today-session-outer-row-info">
          <div className="col sess-head">Today's Session</div>
          <div className="card Today-Session">
            <div className="box-shadow d-flex justify-content-center align-item-center">
              {isLoading ? (
                <div>
                  <div className="card-body p-2" style={{ width: "370px", height: "142px" }}>
                    <div className="session-first-container">
                      <div className="row">
                        <div className="col-6 sess-detail">
                          <div className="sess-time">
                            <Skeleton width={126.61} height={13} key="1" />
                          </div>
                        </div>
                        <div className="sess-name">
                          <Skeleton width={106.61} height={13} key="2" inline />
                        </div>
                        <div className="col-6">
                          <Skeleton width={56.61} height={13} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="sess-mentor-detail">
                        <div className="sess-mentor-wrapper">
                          <div className="sess-mentor-img">
                            <Skeleton circle={true} width={25} height={25} key="3" />
                          </div>
                          <Skeleton width={106.61} height={13} key="4" />
                        </div>
                        <div className="mentor-pos-wrapper">
                          <Skeleton width={106.61} height={13} key="5" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : todaySessions && todaySessions.length === 0 ? (
                <div className="d-flex justify-content-start align-items-center flex-column">
                  <EmptyTodaySession className="d-flex justify-content-start align-items-center calender-svg" />
                  <p className="pcNoData fw-bold m-0">No sessions scheduled for today</p>
                  <p className="mt-1 task-link m-0" data-bs-toggle="modal" data-bs-target="#UploadSessionCsvModal" onClick={() => sendFunction()}>
                    Upload Session Calendar
                  </p>
                </div>
              ) : (
                <Carousel controls={false}>
                  {todaySessions &&
                    todaySessions?.map((session, index) => (
                      <Carousel.Item>
                        <div key={index} className={`carousel-styling border`}>
                          <div className="card-body p-2" style={{ width: "370px", height: "142px" }}>
                            <div className="session-first-container">
                              <div className="row">
                                <div className="col-6 sess-detail">
                                  <div className="sess-time">
                                    {convertTo12HourFormat(session.sessionStartTime)} -{convertTo12HourFormat(session.sessionEndTime)}
                                  </div>
                                  <div className="sess-name">{session.sessionName}</div>
                                </div>
                                <div className="col-6">
                                  {session.batchDetails && session.batchDetails.length > 2 ? (
                                    <>
                                      {session.batchDetails.slice(0, 2).map((today, index) => (
                                        <div className="show-more" key={index}>
                                          {today}
                                        </div>
                                      ))}
                                      <div className="count-of-batches">
                                        <p className="name-of-batches" title={session?.batchDetails?.slice(2).join(", ")}>
                                          + {session?.batchDetails?.length - 2}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    session.batchDetails &&
                                    session.batchDetails.map((today, index) => (
                                      <div className="show-more" key={index}>
                                        {today}
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-3">
                              <div className="sess-mentor-detail">
                                <div className="sess-mentor-wrapper">
                                  {session.mentorImage ? (
                                    <div className="sess-mentor-box">{session.techImage =="Default" ? <DefaultTechnology /> : <img key={session.sessionId} src={session.techImage} alt="" />}</div>
                                  ) : (
                                    <div className="sess-mentor-img">
                                      {session.mentorName
                                        .split(" ")
                                        .map((name) => name.charAt(0).toUpperCase())
                                        .join("")}
                                    </div>
                                  )}
                                  {session.mentorName}
                                </div>
                                <div className="mentor-pos-wrapper">{session.mentorDesignation}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                </Carousel>
              )}
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default TodaySession;
