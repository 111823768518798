import { Header, SideNav, useNavigate, useState, useEffect, decryptData, axios, handleApiError, EnrolledCourses, InstructorsInfo, LmsNotifications, MilestoneCard, MostPopularCourses, PopularTags, TechTalkSection, MilestoneAchievementModal } from "../UserImport"
import "./LmsDashBoard.css"

const UserLmsDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    mileStoneData: undefined,
    mileStoneDetail: undefined,
    totalTimeSpent: undefined,
    enrolledCoursesData: undefined,
    isModalShown: undefined,
    userCourseAchievementPoints : undefined,
    loading :true
  });
  const [isModalClosed, setIsModalClosed] = useState(false);
  
  const handleModalClosed = (value) => {
    setIsModalClosed(value);
  };
  
  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getLMSDashboardData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/UserLMSDashboard`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setDashboardData((prevState) => ({
          ...prevState,
          mileStoneData: response.data.result.userLMSDashboardItems,
          mileStoneDetail: response.data.result.milestoneDetail,
          totalTimeSpent: response.data.result.totalTimeSpent,
          enrolledCoursesData: response.data.result.userLMSDashboardItems[0]?.enrolledCourses,
          userCourseAchievementPoints : response.data.result.courseAchievementPoints,
          loading : false
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const showMilestoneModal = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/isMilestoneAchievementModalShown`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        if(response.data.result !== null) {
          setDashboardData((prevState) => ({
            ...prevState,
            isModalShown: response.data.result.isModalShown,
          }));
        } else {
          setDashboardData((prevState) => ({
            ...prevState,
            isModalShown: true,
          }));
        }
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
   getLMSDashboardData();
  }, []);

  useEffect(() => {
    showMilestoneModal();
   }, [isModalClosed]);

   return (
    <div className="lms-user-dashboard-bg p-0 m-0 vh-100 vw-100 overflow-hidden">
    <div style={{ marginBottom: "5rem" }}>
        <Header />
        <SideNav/>
      </div>
    {!(dashboardData.mileStoneData === undefined || dashboardData.mileStoneData?.length === 0) ? dashboardData?.isModalShown === false ? <MilestoneAchievementModal onModalClose={handleModalClosed} milestoneLevel={dashboardData.userCourseAchievementPoints.milestoneLevel} fullName={(dashboardData.mileStoneDetail?.userFirstName || "") + " " + (dashboardData.mileStoneDetail?.userLastName || "")}/> : "" : null}
    <div className="container ">
      <div className="row">
        <div className="d-grid gap-3 col-8">
            <div className="row flex-nowrap d-flex gap-3 milestone-dashboard-card">
                <MilestoneCard mileStoneData={dashboardData.mileStoneData} milestoneDetail={dashboardData.mileStoneDetail} totalTimeSpent={dashboardData.totalTimeSpent} isLoading={dashboardData.loading} userCourseAchievementPoints = {dashboardData.userCourseAchievementPoints}/>
            </div>
            <div className="d-grid gap-5 row lms-dashboard-scroll">
              <EnrolledCourses enrolledCoursesData={dashboardData.enrolledCoursesData} isLoading={dashboardData.loading}/>
              <span className={`d-flex gap-5 flex-column px-0 ${!dashboardData.enrolledCoursesData || dashboardData.enrolledCoursesData?.length === 0 ? "flex-column-reverse" : ""}`}>
              <PopularTags />
              <MostPopularCourses enrolledCoursesData={dashboardData.enrolledCoursesData}/>
              </span>
              <TechTalkSection />
              <InstructorsInfo />
            </div>
        </div>
        <div className="col-4 px-4">
          <LmsNotifications enrolledCoursesData={dashboardData.enrolledCoursesData}/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default UserLmsDashboard