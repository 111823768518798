import "./ProjectList.css";
import { EmptyState, useContext, useState, ExpandMore, ShowLess, DetailedProvider } from "../../AdminImport";

const ProjectList = () => {
  const { data } = useContext(DetailedProvider) || {};
  const [show, setShow] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);

  const val = data?.userProject && data.userProject.length != 0;
  return (
    <>
      {val ? (
        <div className="list-parent-wrapper">
          {data?.userProject?.map((val, index) => {
            return (
              <div key={index} className="list-desp-wrapper">
                <div className="list-child-wrapper">
                  <div className="list-project-expand">
                    <div>{val?.name}</div>
                    {show && expandIndex === index ? (
                      <ShowLess
                        className="pointer"
                        onClick={() => {
                          setShow(false);
                          setExpandIndex(-1);
                        }}
                      />
                    ) : (
                      <ExpandMore
                        className="pointer"
                        onClick={() => {
                          setShow(true);
                          setExpandIndex(index);
                        }}
                      />
                    )}
                  </div>
                  {show && expandIndex === index ? (
                    <div className="detail-desp">
                      <div className="list-description">{val?.description}</div>
                      <div className="tech-used">
                        <div className="link-title">Technology Used:</div>
                        <div className="list-tech">
                          {val?.technologyUsed.map((tech, index) =>
                            tech !== null ? (
                              <div key={index} className="skill-tag-tech">
                                {tech.techName}
                              </div>
                            ) : (
                              <></>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="brief-desp">{val?.description?.length > 55 ? `${val?.description.slice(0, 55)}...` : val?.description}</div>
                  )}
                  <div className="list-link project-link">
                    <div className="link-title">Project Link:</div>
                    <a href={val?.projectLink} target="_blank">{val?.projectLink}</a>
                  </div>
                  {val.hostedLink != null && show && expandIndex === index && (
                    <div className="list-link other-link">
                      <div className="link-title" target="_blank">Hosted Link:</div>
                      <a href={val?.hostedLink}>{val?.hostedLink}</a>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="no-data-wrapper">
          <EmptyState />
          <h1 className="h-empty">No data found</h1>
        </div>
      )}
    </>
  );
};

export default ProjectList;
