import { useState, UserActionType, TechActionType, useNavigate, useEffect, useReducer, axios, decryptData, UsersDropdown, ExpandMore, CalendarIcon } from "../../AdminImport";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TechDropdown from "./TechDropdown";

function CustomInput({ value, onClick }) {
  return (
    <div className="row addTask-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 addTask-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">
        <CalendarIcon />
      </span>
    </div>
  );
}
const formatDateToString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function formatEditDate(dateString) {
  const date = dateString === undefined ? new Date() : new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECT_ALL_USER_CHECKED:
      return { ...state, selectAllChecked: action.payload };
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };

    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };

    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  selectAllChecked: false,
  selectedUserIds: [],
  selectedUserName: [],
  usersValue: "",
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};

const EditTaskModal = (props) => {
  var { taskToEdit, setTaskToEdit } = props;
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const regEx = {
    nameRegex: /^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/,
    descriptionRegex: /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{50,500}$/,
    stringRegex: /[^,]+/g,
  };
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = Object.values(state.selectedUserName).join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames).length !== 0;
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const isFieldValid = (value, regex) => {
    return value && regex.test(value);
  };
  const isArrayValid = (value, regex) => {
    return value && value.match(regex);
  };

  const handleCancel = () => {
    taskToEdit = props.taskToEdit;
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: taskToEdit.techNames });
    dispatch({ type: TechActionType.SET_TECH_ID, payload: [] });
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: taskToEdit.name });
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: taskToEdit.name });
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !isFieldValid(taskToEdit.taskName, regEx.nameRegex) ||
      !isFieldValid(taskToEdit.taskDescription, regEx.descriptionRegex) ||
      !isArrayValid(state.techValue, regEx.string) ||
      !isArrayValid(state.usersValue, regEx.string)
    ) {
      alert("Please fill out the necessary fields");
    } else {
      let parsedObject;
      const data = localStorage.getItem("userData");
      data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

      const url = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/update`;

      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      };
      const body = {
        id: taskToEdit.id,
        taskName: taskToEdit.taskName,
        taskDescription: taskToEdit.taskDescription,
        startDate: formatDateToString(new Date(taskToEdit.startDate)),
        endDate: formatDateToString(new Date(taskToEdit.endDate)),
        techId: state.selectedTechIds ? state.selectedTechIds : taskToEdit.techId, // Send the array of tech IDs
        userId: state.selectedUserIds ? state.selectedUserIds : taskToEdit.userId, // Send the array of user IDs
        assignedBy: parsedObject.userId,
        ratedTask: true,
      };
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        setTaskToEdit((prevState) => ({
          ...prevState,
          version: prevState.version + 1,
        }));
        setDropDown((prevState) => ({
          ...prevState,
          techDropdown: false,
          userDropdown: false,
        }));
        navigate("/admin/sde/assign-task");
      } else {
        alert(response.data.result.message);
      }
    }
  };
  return (
    <div>
      <div className="modal fade " id="editTaskModal" tabIndex="-1" aria-labelledby="editTaskModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-1">
              <h5 className="modal-title modalheading-text" id="editTaskModalLabel">
                Edit Task
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="edit-task-title" className="col-form-label form-title-names">
                    Task Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="edit-task-title"
                    placeholder="Enter task name"
                    value={taskToEdit?.taskName || ""}
                    onChange={(e) => {
                      setTaskToEdit((prevState) => ({
                        ...prevState,
                        task: {
                          ...prevState.task,
                          taskName: e.target.value,
                        },
                      }));
                    }}
                  />
                  {taskToEdit.taskName?.length > 0 && !isFieldValid(taskToEdit.taskName, regEx.nameRegex) && (
                    <span style={{ color: "red", fontSize: "12px" }}>Please Enter a name only with letter and space, between 1 and 100 characters</span>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="edit-description" className="col-form-label form-title-names">
                    Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="edit-description"
                    rows={3}
                    placeholder="Enter description"
                    value={taskToEdit?.taskDescription || ""}
                    onChange={(e) => {
                      setTaskToEdit((prevState) => ({
                        ...prevState,
                        task: {
                          ...prevState.task,
                          taskDescription: e.target.value,
                        },
                      }));
                    }}
                  ></textarea>
                  {taskToEdit.taskDescription?.length > 0 && !isFieldValid(taskToEdit.taskDescription, regEx.descriptionRegex) && (
                    <span style={{ color: "red", fontSize: "12px" }}>Please Enter description only with letters, colon, comma and hyphen, between 50 and 500 characters</span>
                  )}
                </div>

                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="start-date" className="col-form-label form-title-names">
                        Start Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        minDate={new Date()}
                        popperPlacement="top-end"
                        selected={taskToEdit?.startDate ? new Date(taskToEdit?.startDate) : null}
                        onChange={(date) => {
                          setTaskToEdit((prevState) => ({
                            ...prevState,
                            task: {
                              ...prevState.task,
                              startDate: formatEditDate(date),
                              endDate: new Date(taskToEdit.endDate) >= new Date(date) ? new Date(taskToEdit.endDate) : ""
                            },
                          }));
                        }}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="end-date" className="col-form-label form-title-names">
                        End Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        minDate={new Date(taskToEdit.startDate) <= new Date() ? new Date() : new Date(taskToEdit.startDate)}
                        popperPlacement="top-end"
                        selected={taskToEdit?.endDate ? new Date(taskToEdit?.endDate) : null}
                        onChange={(date) => {
                          setTaskToEdit((prevState) => ({
                            ...prevState,
                            task: {
                              ...prevState.task,
                              endDate: formatEditDate(date),
                            },
                          }));
                        }}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="technology-tag" className="col-form-label form-title-names">
                    Technology Tag<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={() => {
                        handleDropdownToggle("techDropdown");
                      }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Technology"
                        value={state.selectedTechNames ? state.selectedTechNames : taskToEdit?.techNames}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      <div className="ul-styling" style={{
                        height: "10rem",
                        display: dropDown.techDropdown ? "" : "none"
                      }}>
                        <TechDropdown
                          dispatch={dispatch}
                          techDataComingChild={handleTechChange}
                          selectedTechIdsfromChild={handleTechIdChange}
                          selectedTechIds={state.selectedTechIds}
                          technologyNames={state.selectedTechNames}
                          selectedTech={taskToEdit?.techNames}
                        />
                      </div>
                    </div>
                  </div>
                  {state.selectedTechNames && state.selectedTechNames.length > 10 && <span style={{ color: "red", fontSize: "12px" }}>Maximum 10 technologies allowed</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="assigned-to" className="col-form-label form-title-names">
                    Assigned To<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="container border p-0">
                    <div className="input-with-button">
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Assigned To"
                        value={state.selectedUserName ? state.selectedUserName : taskToEdit?.name}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                          zIndex: 2,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDropdownToggle("userDropdown");
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>

                      <ul style={{
                        height: "10rem",
                        display: dropDown.userDropdown ? "" : "none"
                      }}
                        className="ul-styling">
                        <UsersDropdown
                          dispatch={dispatch}
                          selectedBatches={props.selectedBatches}
                          usersDataComingChild={usersDataComingFrmChild}
                          selectedUserIdsfromChild={handleUserIdChange}
                          selectedUserIds={state.selectedUserIds}
                          selectedUserName={state.selectedUserName}
                          selectedUsers={taskToEdit?.name}
                          selectAllUsersCheck={state.selectAllChecked}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-top-0">
              <button type="button" className="btn modal-cancel-button fw-bold" data-bs-dismiss="modal" onClick={handleCancel}>
                <span className="cancel-text">Cancel</span>
              </button>

              <button
                type="button"
                className="btn btn-primary save-button"
                data-bs-target="#editTaskModal"
                disabled={
                  !isFieldValid(taskToEdit?.taskName, regEx.nameRegex) ||
                  !isFieldValid(taskToEdit?.taskDescription, regEx.descriptionRegex) ||
                  !isArrayValid(state.techValue, regEx.string) ||
                  !isArrayValid(state.usersValue, regEx.string) ||
                  state.selectedTechNames.length === 0 ||
                  state.selectedTechNames.length > 10 ||
                  state.selectedUserName.length === 0 ||
                  taskToEdit.startDate == "" || taskToEdit.endDate == ""
                }
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                <span className="save-text-field">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};
export default EditTaskModal;
