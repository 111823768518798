import React, { useState } from "react";
import DailyTaskTracker from "./DailyTaskTracker/DailyTaskTracker";
import ProjectComponent from "./ProjectIdea/ProjectComponent";
import DashboardGraph from "./ReportGraph/DashboardGraph";
import { NotificationComponent } from "./Notification/Notifications";
import MentorComponent from "./MentorList/MentorList";

import "./dashboard.css";
import Header from "../../Header/Header";
import { SideNav } from "../UserImport";

const Dashboard = () => {
  return (
    <>
      <div  style={{ marginBottom: "5rem" }}>
        <Header />
        <SideNav/>
      </div>

      <div className="responsiveness">
        <>
          <div className="container-fluid">
            <div className="mt-3 das-card-wrapper-row1">
              <div >
                <DailyTaskTracker />
              </div>
              <div >
                <ProjectComponent />
              </div>
              <div >
                <MentorComponent />
              </div>
            </div>
            <div className="mt-2 das-card-wrapper-row2">
              <div  style={{ height: "23.5rem" }}>
                <DashboardGraph />
              </div>
              <div
                className="mt-2"
                style={{ height: "23.625rem", width: "23.65rem" }}
              >
                <NotificationComponent />
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Dashboard;
