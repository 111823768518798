import { FormatDate, useState, useEffect, useNavigate, decryptData, Link, ArrowForwardIcon, Completed, Ongoing, EmptyMentorTask, Overdue, TaskStatusSkeleton, axios, handleApiError } from "../../MentorImport";
import "./TaskStatus.css";

function getInitials(name) {
  const names = name?.split(" ");
  const initials = names?.map((n) => n.charAt(0).toUpperCase());
  return initials?.join("");
}

const TaskStatus = (props) => {
  const [taskStatus, setTaskStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/taskStatus`;
    const body = { batchId: props.selectedBatches.length == 0 ? null : props.selectedBatches };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        setTaskStatus(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.selectedBatches]);

  return (
    <>
      <p className="p-0 m-0">
        <Link to="/mentor/assign-task" className="about-link p-0">
          Manage SDEs Task Status <ArrowForwardIcon />
        </Link>
      </p>
      {isLoading ? (
        <div className="card task-status-card">
          <TaskStatusSkeleton />
          <TaskStatusSkeleton />
        </div>
      ) : taskStatus?.length === 0 ? (
        <div className="card task-status-card">
          <div className="d-flex justify-content-center align-items-center flex-column h-100 p-4">
            <EmptyMentorTask />
            <p className="fw-bold mt-3 mb-1 empty-task-text">No task has been assigned recently for review!</p>
            <Link to={"/mentor/assign-task"} className="task-link">
              Assign New Task
            </Link>
          </div>
        </div>
      ) : (
        <div className="card task-status-card dp-scroll">
          {taskStatus &&
            taskStatus?.map((task) => (
              <div key={task.taskId} className="each-task mb-2" style={{ width: "100%" }}>
                <h5 className="mb-3">{task.taskName}</h5>
                <div className="accordion " id={`accordion-${task.taskId}`} style={{ width: "100%" }}>
                  {task.batchUsersList?.map((batchUser, index) => (
                    <div className="mb-3 batch-accord" key={index}>
                      <h2 className="accordion-header" id={`heading-${task.taskId}-${index}`}>
                        <button className="accordion-button batch-accord-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${task.taskId}-${index}`} aria-expanded="false" aria-controls={`collapse-${task.taskId}-${index}`}>
                          <p className="task-status-batch-name mb-0">{batchUser.batchName}</p>
                        </button>
                      </h2>
                      <div id={`collapse-${task.taskId}-${index}`} className="accordion-collapse collapse " aria-labelledby={`heading-${task.taskId}-${index}`} data-bs-parent={`#accordion-${task.taskId}`}>
                        <div className="accordion-body">
                          {batchUser?.users.map((user, userIndex) => (
                            <div key={user.user_id} className={(user?.taskdone && user?.taskcompletedate != null ) || !user?.taskdone && FormatDate(new Date()) <= FormatDate(task.endDate) ? "card associate-mapped-card-task" : "card associate-mapped-card-task-overdue"}>
                              <div className="row d-flex justify-content-space-between">
                                <div className="col-8 d-flex align-items-center">
                                  <div className="row">
                                    <div className="col-4 frame pointer">
                                      <p className="frame-para">{getInitials(user.name)}</p>
                                    </div>
                                    <div className="col-4 pointer">
                                      <div className="frame-text">{user.name}</div>
                                      <div className="frame-id-task">{user.internId}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4 task-status">
                                  {user?.taskdone && user?.taskcompletedate != null ? (
                                    <>
                                      <Completed />
                                      <div className="mt-1 frame-id-task">{FormatDate(user.taskcompletedate)}</div>
                                    </>
                                  ) : !user?.taskdone && FormatDate(new Date()) <= FormatDate(task.endDate) ? (
                                    <>
                                      <Ongoing />
                                      <div className="mt-1 frame-id-task">{FormatDate(task.endDate)}</div>
                                    </>
                                  ) : (
                                    <>
                                      <Overdue />
                                      <div className="mt-1 frame-id-task">{FormatDate(task.endDate)}</div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};
export default TaskStatus;
