import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "../Notification/Notification.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ReactComponent as EmptyMentorAssigned } from "../../../../Assets/EmptyMentorAssigned.svg";

const MentorAssignedAlerts = (props) => {
  const [mentorTask, setMentorTask] = useState([]);
  const [notification, setNotification] = useState(false);
  const navigate = useNavigate();
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const handleNotificationClick = () => {
    setNotification(!notification);
    const currentDate = new Date();
    const lastReadDate = new Date(localStorage.getItem('lastReadDate'));
    
    // Check if the last read date is before the current date
    if (!lastReadDate || lastReadDate.toDateString() !== currentDate.toDateString()) {
      localStorage.setItem('lastReadDate', currentDate.toISOString());
    }
  };
  

  const secretkeyUser = process.env.REACT_APP_USER_KEY;
  var parsedObject;
  const data = localStorage.getItem("userData");
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
    const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
    parsedObject = JSON.parse(decryptedJsonString);
  } else {
console.error("No data found.");  }
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let body = {
      batchId: null
    };
  
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL_DOTNET + '/Tasks/mentorAssigned',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${parsedObject["token"]}`,
          },
          body: JSON.stringify(body),
        }
      );
  
      if (!response.ok) {
        const error = new Error('Request failed');
        error.statusCode = response.status;
        throw error;
      }
  
      const data = await response.json();
      const filteredTasks = data.result.filter(task => !task.isTaskDone);
      setMentorTask(filteredTasks);
    } catch (error) {
      if (error.statusCode === 401) {
        navigate("/error/statusCode=401");
      } else if (error.statusCode === 400) {
        navigate("/error/statusCode=400");
      } else if (error.statusCode === 500) {
        navigate("/error/statusCode=500");
      } else if (error.statusCode === 404) {
        navigate("/error/statusCode=404");
      } else {
        console.error('Error:', error);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faBell}
          shake={mentorTask.some(task => isToday(new Date(task.assignedDate))) && !notification && !localStorage.getItem('lastReadDate')}
          size="lg"
          onClick={handleNotificationClick}
          style={{
            color: "#002c3f",
            cursor: "pointer",
            marginRight: "2rem",
          }}
        />
        <div
          style={{
            backgroundColor: mentorTask.some(task => isToday(new Date(task.assignedDate))) && !notification && !localStorage.getItem('lastReadDate')? "red" : "",
            width: "0.5rem",
            height: "0.5rem",
            borderRadius: "50%",
            position: "absolute",
            right: "10.813rem",
            top: "1.313rem",
          }}
        ></div>
      </div>
      <div style={{ display: notification ? "block" : "none" }}>
        {mentorTask?.length !== 0 ? (
          <div
            className="card"
            style={{
              position: "absolute",
              right: "1.5rem",
              top: "3.8rem",
              width: "21rem",
              zIndex: 4,
              boxShadow: "0 0.25rem 1.25rem rgba(40, 52, 73, 0.15)",
              borderRadius: "0.5rem",
            }}
          >
            <div className="card-header p-0 border-0">
              <div
                className="border-bottom p-0"
                style={{
                  borderRadius: "0.438rem 0.438rem 0 0",
                  position: "sticky",
                  top: "0",
                  zIndex: "3",
                  backgroundColor: "#fff",
                }}
              >
                <h5
                  className="card-title p-3 m-0"
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: "1rem",
                    lineHeight: "1.18rem",
                    color: "#343435",
                  }}
                >
                  Mentor Assigned Task
                </h5>
              </div>
            </div>
            <div
              className="card-body pt-0 pb-0"
              style={{ height: "20rem", overflow: "scroll" }}
            >
              {mentorTask?.map((data, key) => {
                return (
                  <div
                    className="notification-wrapper px-0"
                    style={{ width: "18.9rem", alignItems: "center", cursor: "pointer" }}
                    key={key}
                    onClick={() => {navigate("/all-projects")}}
                  >
                    <div
                      
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="background-set">
                        {data.mentorFirstName?.toUpperCase().slice(0, 1)}
                        {data.mentorLastName?.toUpperCase().slice(0, 1)}
                      </div>
                    </div>
                    <div className="text-wrapper ps-0 ">
                      <p className="card-text ">
                        <b>
                          {data.mentorFirstName} {data.mentorLastName}
                        </b>{" "}
                        has assigned you <b>{data.taskName}</b> task
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div
            className="card"
            style={{
              position: "absolute",
              right: "1.5rem",
              top: "3.8rem",
              maxWidth: "21rem",
              zIndex: 4,
              boxShadow: "0 0.25rem 1.25rem rgba(40, 52, 73, 0.15)",
              borderRadius: "0.5rem",
            }}
          >
            <div className="card-header p-0 border-0">
              <div
                className="border-bottom p-0"
                style={{
                  borderRadius: "0.438rem 0.438rem 0 0",
                  position: "sticky",
                  top: "0",
                  zIndex: "3",
                  backgroundColor: "#fff",
                }}
              >
                <h5
                  className="card-title p-3 m-0"
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: "1rem",
                    lineHeight: "1.18rem",
                    color: "#343435",
                  }}
                >
                  Mentor Assigned Task
                </h5>
              </div>
            </div>
            <div
              className="card-body d-flex justify-content-center align-items-center"
              style={{ height: "20rem" }}
            >
              <div className="row">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <EmptyMentorAssigned />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h2
                      className="text-center"
                      style={{
                        fontFamily: "'Roboto'",
                        marginTop: "0.75rem",
                        fontStyle: "normal",
                        width: "18.9rem",
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        lineHeight: "1.438rem",
                        color: "#343435",
                      }}
                    >
                      No Notifications
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MentorAssignedAlerts;
