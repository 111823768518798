import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cginfinitylogo from "../../../Assets/Cginfinitylogo.svg";
import Cginfinitylogoresponsive from "../../../Assets/Cginfinitylogoresponsive.svg";
import CarouselImage1 from "../../../Assets/CarouselImage1.svg";
import CarouselImage2 from "../../../Assets/CarouselImage2.svg";
import CarouselImage3 from "../../../Assets/CarouselImage3.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import Microsoftsigninbutton from "../Auth/Microsoftsigninbutton";
import decryptData from "../../_common/DecryptUserData";
import "../Accounts.css";
import { UserContext } from "../../../Context/Context";
import LoginProcess from "../Auth/LoginProcess";
import { useCookies } from 'react-cookie';
import { InfinitySpin } from "react-loader-spinner";

const LoginScreen = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setIsAuthenticated, isAuthenticated } = useContext(UserContext)
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [cookies, setCookie, , removeCookie] = useCookies();
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [isLoading, setIsLoading] = useState({
    ssoStatus: false,
    loader: false,
  });
  const [mainLoader, setMainLoader] = useState(false);
  const getBrowserTimeZone = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
  };
  const browserTimeZone = getBrowserTimeZone();
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const autoLoginToken = cookies[process.env.REACT_APP_API_CGU_CGONE_TOKEN_NAME]

  const handleAutoLogin = async () => {
    setIsLoading({
      ...isLoading,
      loader: true
    });
    setMainLoader(true);
    const headers = {
      Authorization: `Bearer ${autoLoginToken}`,
    };

    let loginStatus = await axios.post(`${process.env.REACT_APP_API_URL_DOTNET}/Sso/ssoLogin?clientTimezone=${browserTimeZone}`, {}, { headers })

    if (loginStatus.data.success == 200) {
      LoginProcess(loginStatus.data.result, setIsLoading, setIsAuthenticated, isLoading, navigate);
      setCookie(process.env.REACT_APP_API_CGU_CGONE_TOKEN_NAME, undefined, { path: '/', domain: ".cginfinity.com" });
    };
    setMainLoader(false)
  }
  useEffect(() => {
    if (autoLoginToken)
      handleAutoLogin()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading({
      ...isLoading,
      loader: true
    });
    let body = {
      userName: email,
      password,
      clientTimeZone: browserTimeZone,
    }

    try {
      const loginStatus = await axios.post(process.env.REACT_APP_API_URL_DOTNET + "/Authentication/login", body)
      if (loginStatus.data.success == 200)
        setCookie(process.env.REACT_APP_API_CGU_CGONE_TOKEN_NAME, "dawdawd")
      LoginProcess(loginStatus.data.result, setIsLoading, setIsAuthenticated, isLoading, navigate);
      throw loginStatus
    } catch (error) {
      if (error.data.message == "Wrong Credentials") {
        setIsEmailValid(false);
        setIncorrectEmail(true);
      } else {
        setIsPasswordValid(false);
      }
    } finally {
      setIsLoading({
        ...isLoading,
        loader: false
      });
    }
  };

  const handleSSO = async () => {
    var url = `${process.env.REACT_APP_API_URL_DOTNET}/Sso/ssoStatus`;
    try {
      const response = await axios.get(url);
      if (response.data.success === 200) {
        return setIsLoading({
          ...isLoading,
          ssoStatus: !(response.data.result == "yes")
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    handleSSO();
  }, [])

  useEffect(() => {
    let login = localStorage.getItem("login");
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : console.error("No data found.");
    const roleName = parsedObject?.decoded?.RoleName;
    if (login && data) {
      navigate("/lms/dashboard");
    }
  }, []);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIncorrectEmail(false);
    setIsEmailValid(value.match(/^[\w.-]+@cginfinity\.com$/) ? true : false);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setIsPasswordValid(true);
  };


  return (
    <>
      {
        mainLoader &&
        <div className="vh-100 vw-100 d-flex justify-content-center align-items-center position-absolute" style={{ background: "rgba(255, 255, 255, 0.5)", zIndex: 999 }}>
          <InfinitySpin color="#ffc107" />
        </div>
      }

      <div className="container-fluid login-screen-body div-xs">
        <div className="row pos m-0 d-inline">
          <div className="d-flex justify-content-center  align-items-center flex-row">
            <div className="col-md-5 div-bg-height-width-fixed div-hidden-xs">
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="row cglogoimg m-0 h-100">
                  <img className="p-0" src={Cginfinitylogo} alt="CG-Infinity Logo" />
                </div>
                <div className="row card-left-heading">
                  <p>CG University</p>
                </div>
                <div id="carouselExampleIndicators" className="carousel slide mt-3" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active div-width-16-fixed">
                      <img src={CarouselImage1} className="d-block div-width-mar-fixed" alt="..." />
                      <p className="carousel-text ms-4">Record your daily work items</p>
                    </div>

                    <div className="carousel-item div-width-16-fixed">
                      <img src={CarouselImage2} className="d-block div-width-mar-fixed" alt="..." />
                      <p className="carousel-text">Enhance your skills via assessments</p>
                    </div>
                    <div className="carousel-item div-width-17-fixed">
                      <img src={CarouselImage3} className="d-block div-width-mar-fixed" alt="..." />
                      <p className="carousel-text">Get certificate and share achievement</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7 bg-white p-4 div-height-35-fixed">
              {
                isLoading.ssoStatus &&
                (
                  <>
                    <div className="row div-hidden-xs">
                      <p className="right-container-heading">Login</p>
                    </div>
                    <div className="row div-hidden d-md-none">
                      <div className="row cglogoimg">
                        <img className="p-0 cglogoimg-xs" src={Cginfinitylogoresponsive} alt="CG-Infinity Logo" />
                      </div>
                      <div className="row">
                        <p className="right-container-heading">CG University</p>
                      </div>
                    </div>

                    <div className="row div-height-156-fixed">
                      <form onSubmit={handleSubmit}>
                        {/* <form > */}
                        <div className="div-hight106-martop-fixed">
                          <div className="d-flex flex-column">
                            <label className="input-label-text" htmlFor="exampleInputEmail1">
                              Email ID
                            </label>
                            <div className="div-input">
                              <input className="input-login w-100" type="email" id="exampleInputEmail1" value={email} onChange={handleEmailChange} placeholder="Enter Your Email ID" required />
                              {/* <input className="input-login w-100" type="email" id="exampleInputEmail1" value={email} placeholder="Enter Your Email ID" required /> */}
                            </div>
                            {!isEmailValid && email && <span className="sign-up-warning">{incorrectEmail ? "Email does not exist" : "Please make use of CG-Infinity email only"}</span>}
                          </div>
                          <div className="d-flex flex-column">
                            <label className="input-label-text div-martop-fixed" htmlFor="exampleInputPassword1">
                              Password
                            </label>
                            <div className="input-group">
                              <div className="div-input pass-input-div w-100">
                                <input className="input-login w-100" type={showPassword ? "password" : "text"} id="exampleInputPassword1" placeholder="Enter Your Password" value={password} required onChange={handlePasswordChange} />
                                {/* <input className="input-login w-100" type={showPassword ? "password" : "text"} id="exampleInputPassword1" placeholder="Enter Your Password" value={password} required /> */}
                                <button className="btn password-toggle-button btn-border-none-fixed" type="button" onClick={handleTogglePasswordVisibility}>
                                  {/* <button className="btn password-toggle-button btn-border-none-fixed" type="button" > */}
                                  {showPassword ? (
                                    <i>
                                      <svg width="16" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5ZM11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8C6 6.67392 6.52678 5.40215 7.46447 4.46447C8.40215 3.52678 9.67392 3 11 3C12.3261 3 13.5979 3.52678 14.5355 4.46447C15.4732 5.40215 16 6.67392 16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5Z" fill="#343435" />
                                      </svg>
                                    </i>
                                  ) : (
                                    <i>
                                      <svg width="16" height="16" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.83 6L14 9.16C14 9.11 14 9.05 14 9C14 8.20435 13.6839 7.44129 13.1213 6.87868C12.5587 6.31607 11.7956 6 11 6C10.94 6 10.89 6 10.83 6ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.77 8 9C8 9.79565 8.31607 10.5587 8.87868 11.1213C9.44129 11.6839 10.2044 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C9.67392 14 8.40215 13.4732 7.46447 12.5355C6.52678 11.5979 6 10.3261 6 9C6 8.21 6.2 7.47 6.53 6.8ZM1 1.27L3.28 3.55L3.73 4C2.08 5.3 0.78 7 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.81 16.08L18.73 19L20 17.73L2.27 0M11 4C12.3261 4 13.5979 4.52678 14.5355 5.46447C15.4732 6.40215 16 7.67392 16 9C16 9.64 15.87 10.26 15.64 10.82L18.57 13.75C20.07 12.5 21.27 10.86 22 9C20.27 4.61 16 1.5 11 1.5C9.6 1.5 8.26 1.75 7 2.2L9.17 4.35C9.74 4.13 10.35 4 11 4Z" fill="#343435" />
                                      </svg>
                                    </i>
                                  )}
                                </button>
                              </div>
                            </div>
                            {!isPasswordValid && password && <span className="sign-up-warning">Incorrect Password</span>}
                          </div>
                        </div>

                        <button type="submit" className="btn btn-warning border-0 sign-up-btn mt-0 w-100 login fw-bold" disabled={!isEmailValid || isLoading.loader}>
                          {isLoading.loader ? (
                            <>
                              Loading... &nbsp;&nbsp;
                              <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden"></span>
                              </div>
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form>
                    </div>
                  </>
                )
              }
              <div className={`${!isLoading.ssoStatus ? "d-flex h-100 align-items-center" : "microsoft-single-sign-on"}`}>
                <Microsoftsigninbutton isDisabled={mainLoader} />
              </div>
              {
                isLoading.ssoStatus &&
                (
                  <>
                    <div className="row mt-0 ">
                      <Link className="right-container-link mb-3 mt-1" to="/forgot-password">
                        Forgot Password?
                      </Link>
                      <div className="d-flex justify-content-center">
                        <span className="fw-bold">First time user? &nbsp;</span>
                        <Link className="mt-0 right-container-link div-marbottom-fixed" to="/sign-up">
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
