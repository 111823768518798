import { Skeleton } from "../../UserImport";

const LmsNotificationsSkeleton = (props) => {
  const { isCourseSkeleton } = props;

  return (
    <div className={`d-flex gap-2 p-2 border-bottom ${isCourseSkeleton ? "lms-dashboard-notification-inner-enroll-card": ""}`}>
      <div>{isCourseSkeleton ? <Skeleton width={40} height={40} /> : <Skeleton circle={true} width={40} height={40} />}</div>
      <div className="d-grid gap-1 w-100">
        {isCourseSkeleton ? (
          <div className="d-flex gap-1">
            <Skeleton width={80} highlightColor="#FFB81C" />
            <div className="lms-dashboard-notification-badge-skeleton">
              <p className="lms-dashboard-notification-badge-text m-0">
                <Skeleton width={80} />
              </p>
            </div>
          </div>
        ) : (
          <div className="lms-notification-name">
            <Skeleton width={100} />
          </div>
        )}
        <div className="lms-notification-desc">
          <Skeleton count={2} />
        </div>
        <div className="d-flex justify-content-between">
          <div className="lms-notification-date d-flex gap-1 align-items-center">
            <Skeleton width={70} />
          </div>
          {isCourseSkeleton && (
            <div className="d-flex gap-1 align-items-center lms-notification-enroll pointer">
              <Skeleton width={80} className="lms-notification-enroll-chev" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LmsNotificationsSkeleton;
