import React from "react";
import "./DailyUpdateTable.css";
import Header from "../../Header/Header";
import BreadCrumbs from "../../_utilities/BreadCrumbs/BreadCrumbs";
import DailyUpdateTableSection from "./DailyUpdateTableSection";
import { SideNav, decryptData, useNavigate } from "../UserImport";

const DailyUpdateTable = (props) => {
  const navigate = useNavigate()
  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
  var userId = parsedObject["decoded"]["Id"];
  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container-fluid daily-update-table-container d-flex flex-column">
        <div className="daily-update-table-wrapper" style={{ marginLeft: "1rem" }}>
          <div className="row ">
            <div className="col-12">
              <div className="daily-update-nav-bar">
                <BreadCrumbs dashboardLink={"/dashboard"} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="daily-update-heading">
                <p>Daily Update</p>
              </div>
            </div>
          </div>
          <DailyUpdateTableSection userId={userId} />
        </div>
      </div>
    </>
  );
};

export default DailyUpdateTable;
