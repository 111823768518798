import { React, Modal, useState, useNavigate } from "../MentorImport";

const GradedHistoryModal = (props) => {
  const { detailModal, handleClose, userList } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);

  const navigate = useNavigate();
  const totalPaginationPages = Math.ceil(userList?.length / resultsPerPage);
  const arrayStartIndex = (currentPage - 1) * resultsPerPage;
  const arrayEndIndex = arrayStartIndex + resultsPerPage;
  const arrayCurrentResults = userList?.slice(arrayStartIndex, arrayEndIndex);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const handlePrevPageChange = () => {
    if (currentPage != 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };
  const handleNextPageChange = () => {
    if (currentPage !== totalPaginationPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <Modal size="lg" dialogClassName="modal-100w" backdrop="static" centered show={detailModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton className="border-0">
        <p className="modal-head">Grading History</p>
      </Modal.Header>

      <Modal.Body className="d-flex justify-content-center flex-column align-items-center">
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="col grade-assign-column-id daily-update-table-head">#</th>
                <th className="col session-user-table-head session-progress-col">Name</th>
                <th className="col session-user-table-head session-start-col text-center">Submission Date</th>
                <th className="col session-user-table-head session-end-col text-center">Status</th>
                <th className="col session-user-table-head session-action-col text-center">Percentage (%)</th>
              </tr>
            </thead>
            <tbody>
              <>
                {arrayCurrentResults?.map((val, ind) => (
                  <tr className="grade-assign-table-row" key={ind}>
                    <td>{arrayStartIndex + ind + 1}</td>
                    <td className={`tag2 tag2-adjustment progress-height `}>
                      <div>
                        <p
                          className="mb-0 grade-assign-name-text"
                          onClick={() => {
                            sessionStorage.setItem("userDetails", JSON.stringify(val));
                            navigate("/mentor/gradeAssignment");
                          }}
                        >
                          {val.firstName} {val.lastName}
                        </p>
                      </div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height `}>
                      <div>
                        <p className="mb-0 grade-assign-date-text">{dateFormat(val.submissionDate)}</p>
                      </div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height d-flex justify-content-center align-items-center`}>
                      <div className="status-rated pt-1 pb-1">Rated</div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height `}>{val.totalAssignmentReviewPercentage}%</td>
                  </tr>
                ))}
              </>
            </tbody>
            <tfoot className="dut-foot">
              <tr>
                <td className="fw-bold" colSpan={2}>
                  Total Items: {userList?.length}
                </td>
                <td colSpan={3}>
                  <div className="d-flex justify-content-end" style={{ color: "#706F73" }}>
                    <div className="d-flex align-items-center">
                      <p className="me-2 mb-0" style={{ whiteSpace: "nowrap" }}>
                        Results per page
                      </p>
                      <select style={{ color: "#706F73" }} className="form-select m-2" value={resultsPerPage} onChange={handleResultsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                      <ul className="pagination mb-0">
                        <li className={`page-item me-1 ${currentPage === 1 ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handlePrevPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 1.5L1.5 7L7 12.5" stroke={currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                        {Array.from({ length: totalPaginationPages }, (_, i) => i + 1).map((page) => (
                          <li key={page} className={`page-item me-1 ${page === currentPage ? "active" : ""}`}>
                            <button className="page-link rounded pagination-styling" onClick={() => handlePageChange(page)}>
                              {page}
                            </button>
                          </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handleNextPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 12.5L6.5 7L1 1.5" stroke={currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="d-flex flex-row-reverse w-100 mt-5">
            <button type="button" className="taskAddedClass me-1 btn-primary " onClick={handleClose}>
              Close
            </button>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default GradedHistoryModal;
