import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/Context";
import { ReactComponent as SDEDashboard } from "../../../Assets/SdeDashbord.svg";
import { ReactComponent as Task } from "../../../Assets/Assign Task.svg";
import { ReactComponent as Review } from "../../../Assets/Review associate Icon.svg";
import { ReactComponent as SkillAssessment } from "../../../Assets/Skill assessment.svg";
import { ReactComponent as Report } from "../../../Assets/Report.svg";
import { ReactComponent as Logs } from "../../../Assets/Logs.svg";
import { ReactComponent as Calander } from "../../../Assets/Session Calendar.svg";
import { ReactComponent as LMSDashboard } from "../../../Assets/LMS Dashboard.svg";
import { ReactComponent as Milestones } from "../../../Assets/MilestoneIcon.svg";
import { ReactComponent as Expand } from "../../../Assets/Navigation Expand.svg";
import { ReactComponent as Collapse } from "../../../Assets/Property 1=Collapsecollapse.svg";
import { ReactComponent as Ham } from "../../../Assets/Vectorham.svg";
import { ReactComponent as Close } from "../../../Assets/close.svg";
import { ReactComponent as SignOut } from "../../../Assets/SignOut.svg";
import { ReactComponent as AdminDashboard } from "../../../Assets/AdminDashboard.svg";
import { handleApiError } from "../../_common/HandleApiError";
import decryptData from "../../_common/DecryptUserData";
import axios from "axios";
import { ReactComponent as LmsEnrolledCourses } from "../../../Assets/EnrolledCourses.svg";
import "./SideNav.css";

const SideNav = () => {
  const [userData, setUserData] = useState({});
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  let permission = new Set();
  const { resetTimer } = useContext(UserContext);

  useEffect(() => {
    const data = localStorage.getItem("userData");
    data ? setUserData(decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  }, []);

  if (userData?.decoded?.PermissionTypeName !== undefined) {
    const arr = userData?.decoded?.PermissionTypeName?.split(",");
    arr?.forEach((element) => {
      permission.add(`${element}`);
    });
  }

  const handleLogout = async (e) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Sso/logoff`;
    const headers = {
      Authorization: `Bearer ${userData.token}`,
    };

    try {
      const response = await axios.post(url, { headers });
      if (response.status == 200) {
        localStorage.removeItem("userData");
        localStorage.removeItem("tD8kFi5j");
        localStorage.clear();
        resetTimer();
        navigate("/");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  return (
    <>
      <div className="mobile-collapse">
        <div
          className={`nav-item link-wrapper-side-bar   ${isExpand ? "nav-wrapper-expand collapse-icon  " : "nav-wrapper"}`}
          onClick={(e) => {
            e.preventDefault();
            setIsExpand(!isExpand);
          }}
        >
          <span className=" sm-close">{!isExpand ? <Ham /> : <Close />}</span>
        </div>
      </div>
      <div className={`${isExpand ? "navigation-slide" : "navigation"}  flex-column `}>
        {isExpand && (
          <div className="d-sm-none d-flex align-items-center user-wrapper border-bottom">
            <div className="dropdown  background-set">
              <Link id="profileDropDownSideNav" className="text-decoration-none ">
                {userData.firstName?.toUpperCase().slice(0, 1)}
                {userData.lastName?.toUpperCase().slice(0, 1)}
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0">
                <b> {`${userData.firstName}  ${userData?.lastName || ""}`} </b>
                <br /> {userData.email}
              </p>
            </div>
          </div>
        )}
        <ul>
          {userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_ADMIN && (
            <div className={`${isExpand && "d-flex flex-column  align-content-center align-items-center side-height dp-scroll"}`}>
              <div className={`${isExpand && "lms"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>
                    <span>LMS</span>
                    <span className="admin-mode" >ADMIN MODE</span>
                  </p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review ">
                    <NavLink to="/admin/lms/dashboard" className={` ${isExpand ? location.pathname.includes("/admin/lms") && "active-nav-expand active" : location.pathname.includes("/admin/lms") ? "active-nav active" : ""}`}>
                      <span className="nav-icon">
                        < AdminDashboard />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Dashboard </span>
                    </NavLink>
                  </li>
                </div>
              </div>
              <div className={`${isExpand && "lms"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>
                    <span>LMS</span>
                    <span className="user-mode" >USER MODE</span>
                  </p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review ">
                    <NavLink to="/lms/dashboard" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <LMSDashboard />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>LMS Dashboard </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar">
                    <NavLink to="/lms/user-enrolled-courses" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <LmsEnrolledCourses />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Enrolled Courses </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar ">
                    <NavLink to="/lms/milestones" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Milestones />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Milestones </span>
                    </NavLink>
                  </li>
                </div>
              </div>
              <div className={`${isExpand && "sde"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>SDE</p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review">
                    <NavLink to="/admin/sde/dashboard" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <SDEDashboard />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>SDE Dashboard </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review d-none d-sm-flex">
                    <NavLink to="/admin/sde/assign-task" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Task />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Assign Task </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review d-none d-sm-flex">
                    <NavLink to="/admin/sde/skill-test" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <SkillAssessment />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Skill Assessment </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review">
                    <NavLink to="/admin/sde/report" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Report />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Report </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review d-none d-sm-flex">
                    <NavLink to="/admin/sde/logs" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Logs />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Logs </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar mentor-side-nav-review">
                    <NavLink to="/session-calendar" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Calander />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Session Calendar </span>
                    </NavLink>
                  </li>
                </div>
              </div>
            </div>
          )}
          {userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_USER && (
            <div className={`${isExpand && "d-flex flex-column  align-content-center align-items-center"}`}>
              <div className={`${isExpand && "lms"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>LMS</p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  <li className="nav-item link-wrapper-side-bar ">
                    <NavLink to="/lms/dashboard" className={`${isExpand ? "active-nav-expand" : (location.pathname.includes("/lms/course") || location.pathname.includes("/lms/techtalk") || location.pathname.includes("/lms/dashboard")) && "active-nav active"}`}>
                      <span className="nav-icon">
                        <LMSDashboard />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>LMS Dashboard </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar">
                    <NavLink to="/lms/user-enrolled-courses" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <LmsEnrolledCourses />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Enrolled Courses </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar ">
                    <NavLink to="/lms/milestones" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Milestones />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Milestones </span>
                    </NavLink>
                  </li>
                </div>
              </div>
              {
                (userData?.decoded?.HrmsDesignationName.toLowerCase() == "sde" || userData?.decoded?.HrmsDesignationName.toLowerCase() == "intern") && (
                  <div className={`${isExpand && "sde"}`}>
                    <div className="nav-name">
                      <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>SDE</p>
                    </div>
                    <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                      <li className="nav-item link-wrapper-side-bar mentor-side-nav-review ">
                        <NavLink to="/dashboard" className={`${isExpand ? "active-nav-expand" : (location.pathname.includes("/project-idea") || location.pathname == ("/dashboard") || location.pathname.includes("/all-projects")) && "active-nav active"}`}>
                          <span className="nav-icon">
                            <SDEDashboard />
                          </span>
                          <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>SDE Dashboard </span>
                        </NavLink>
                      </li>
                      <li className="nav-item link-wrapper-side-bar mentor-side-nav-review d-none d-sm-flex">
                        <NavLink to="/daily-Update" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                          <span className="nav-icon">
                            <Task />
                          </span>
                          <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Daily Update </span>
                        </NavLink>
                      </li>
                      <li className="nav-item link-wrapper-side-bar mentor-side-nav-review d-none d-sm-flex">
                        <NavLink to="/skill-Management" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                          <span className="nav-icon">
                            <SkillAssessment />
                          </span>
                          <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Skill Management </span>
                        </NavLink>
                      </li>
                      <li className="nav-item link-wrapper-side-bar mentor-side-nav-review ">
                        <NavLink to="/session-calendar" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                          <span className="nav-icon">
                            <Calander />
                          </span>
                          <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Session Calendar </span>
                        </NavLink>
                      </li>
                    </div>
                  </div>
                )
              }
            </div>
          )}
          {userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_MENTOR && (
            <div className={`${isExpand && "d-flex flex-column  align-content-center align-items-center"}`}>
              <div className={`${isExpand && "lms"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>LMS</p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  <li className="nav-item link-wrapper-side-bar ">
                    <NavLink to="/lms/dashboard" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <LMSDashboard />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>LMS Dashboard </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar">
                    <NavLink to="/lms/user-enrolled-courses" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <LmsEnrolledCourses />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Enrolled Courses </span>
                    </NavLink>
                  </li>
                  <li className="nav-item link-wrapper-side-bar ">
                    <NavLink to="/lms/milestones" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                      <span className="nav-icon">
                        <Milestones />
                      </span>
                      <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Milestones </span>
                    </NavLink>
                  </li>
                </div>
              </div>

              <div className={`${isExpand && "sde"}`}>
                <div className="nav-name">
                  <p className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}></p>
                </div>
                <div className={`${isExpand ? "nav-wrapper-expand" : "nav-wrapper"}`}>
                  {permission.has("Session") && (
                    <li className="nav-item link-wrapper-side-bar">
                      <NavLink to="/mentor/dashboard" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                        <span className="nav-icon">
                          <SDEDashboard />
                        </span>
                        <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Dashboard </span>
                      </NavLink>
                    </li>
                  )}
                  {(permission.has("Session") || permission.has("Project")) && (
                    <li className="nav-item link-wrapper-side-bar">
                      <NavLink to="/mentor/assign-task" className={` ${isExpand ? (location.pathname.includes("/mentor/assign-task") || location.pathname.includes("/mentor/project-rating")) && "active-nav-expand active" : (location.pathname.includes("/mentor/assign-task") || location.pathname.includes("/mentor/project-rating")) ? "active-nav active" : ""}`}>
                        <span className="nav-icon">
                          <Task />
                        </span>
                        <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Assign Task </span>
                      </NavLink>
                    </li>
                  )}
                  {permission.has("Review") && (
                    <li className="nav-item link-wrapper-side-bar">
                      <NavLink to="/mentor/review-associates" className={` ${isExpand ? (location.pathname.includes("/mentor/review-associates")) && "active-nav-expand active" : (location.pathname.includes("/mentor/review-associates")) && "active-nav active"}`}>
                        <span className="nav-icon">
                          <Review />
                        </span>
                        <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Review SDEs</span>
                      </NavLink>
                    </li>
                  )}
                  {permission.has("Session") && (
                    <li className="nav-item link-wrapper-side-bar ">
                      <NavLink to="/session-calendar" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                        <span className="nav-icon">
                          <Calander />
                        </span>
                        <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Session Calendar </span>
                      </NavLink>
                    </li>
                  )}
                  {permission.has("Course") && (
                    <li className="nav-item link-wrapper-side-bar ">
                      <NavLink to="/mentor/lms/dashboard" className={` ${isExpand ? "active-nav-expand" : "active-nav"}`}>
                        <span className="nav-icon">
                          <LMSDashboard />
                        </span>
                        <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Upload Course </span>
                      </NavLink>
                    </li>
                  )}
                </div>
              </div>
            </div>
          )}

          <li
            className={`nav-item link-wrapper-side-bar pointer   ${isExpand ? "nav-wrapper-expand collapse-icon big-collapse " : "nav-wrapper"}`}
            onClick={(e) => {
              e.preventDefault();
              setIsExpand(!isExpand);
            }}
          >
            <span className="nav-icon">{isExpand ? <Collapse /> : <Expand />}</span>
            <span className={`${isExpand ? "show-name fw-bold" : "hide-name"}`}>Collapse </span>
          </li>
          {isExpand && (
            <div
              className="logout-wrapper d-sm-none"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              <span className="logout d-flex flex-row align-items-center">
                <span className="pe-1">
                  <SignOut />
                </span>
                <h6>
                  <b>Logout</b>
                </h6>
              </span>
            </div>
          )}
        </ul >
      </div >
    </>
  );
};

export default SideNav;