import { React, Badge, BadgeReportEmpty ,BadgesSkeleton,TotalCourses} from "./../../../../UploadCourses/UploadCoursesImport";
import "./Badges.css";

const Badges = (props) => {
  const { certificateData, badgeLoading } = props;
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array?.length; i += size) {
      chunkedArray.push(array?.slice(i, i + size));
    }
    return chunkedArray;
  };

  const chunkedCertifications = chunkArray(certificateData?.badgeData, 4);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-item-center mt-1">
          <Badge />
          <span className="ms-2 badges-heading">All Badges</span>
        </div>
        <div className="course-report-badge-count">{certificateData?.totalBadgeCount !== undefined ? certificateData?.totalBadgeCount : 0}</div>
      </div>

      {certificateData?.badgeData?.length === 0 || certificateData == null ? (
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <BadgeReportEmpty />
          <p className="fw-bold mt-3 mb-1 empty-course-carousel-text">No badges has been achieved yet</p>
        </div>
      ) : (
        <>
          <div className="mt-2" style={{ height: "12rem" }}>
            <div id="carouselExampleIndicators" className="carousel slide course-report-badge-caro-slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {badgeLoading ? (
                  <div className="d-flex justify-content-between">
                    {Array.from({ length: 2 }).map((_, index) => (
                      <BadgesSkeleton key={index} width={25} />
                    ))}{" "}
                  </div>
                ) : (
                  <>
                    {chunkedCertifications?.map((chunk, index) => (
                      <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                        <div className="d-flex flex-wrap justify-content-between">
                          {chunk.map((certification) => (
                            <div key={certification.subCategoryId} className="d-flex align-items-center fw-600 course-report-badge-milestone-card">
                              <div>
                                {certification.subCategoryImage !=null && certification.subCategoryImage!= "" ? <img src={`data:image/svg+xml;base64,${certification.subCategoryImage}`} alt={certification.subCategoryName} width={28} height={28}/> : <div><TotalCourses /><span>{certification.subCategoryName}</span></div>}
                              </div>
                              <div>
                                <p className="course-report-badge-count mb-0">{certification.count}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="carousel-indicators course-report-badge-carousel-indicator">
                {chunkedCertifications?.map((chunk, index) => (
                  <button key={index} type="button" style={{ backgroundColor: "#000" }} data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : undefined} aria-label={`Slide ${index + 1}`}></button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Badges;
