import { Skeleton } from "../../MentorImport";
import "react-loading-skeleton/dist/skeleton.css";

const TaskStatusSkeleton = () => {
  return (
    <div className="each-task mb-2" style={{ width: "100%" }}>
      <h5 className="mb-3">
        <Skeleton width={400} />
      </h5>
      <div className="mb-3" style={{ width: "100%" }}>
        <Skeleton height={52} count={2} />
      </div>
    </div>
  );
};

export default TaskStatusSkeleton;
