import { React, useEffect, useState, useNavigate, axios, decryptData, Arrorforward, MentorAssignNewTask, MentorTaskDetail, EmptyMentorTask, MentorAssignTaskSkeletonLoading, SideNav, Header, handleApiError, MentorTaskDetailsSkeleton, LeftSideArrow, AssignmentDetail } from "../MentorImport";
import "./MentorAssignTask.css";

const MentorAssignTask = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  const [tasks, setTasks] = useState({
    adminTask: [],
    taskInd: 0,
    isLoading: true,
    assignmentLoading: true,
    userListLoading: true,
    assignments: [],
    assignmentIndex: 0,
    userList: []
  });
  const [selectedButton, setSelectedButton] = useState(sessionStorage.getItem("activeToggle") || "sde");

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  const mentorId = parsedObject["decoded"]["Id"];
  const cancelToken = axios.CancelToken.source();
  const fetchData = async () => {
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/mentorAssignedTask`;
    const body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      cancelToken: cancelToken.token,
    };

    try {
      const response = await axios.post(apiUrl, body, { headers });
      if (response.status === 200) {
        setTasks((prevState) => ({
          ...prevState,
          adminTask: response.data.result,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const fetchAssignmentData = async () => {
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getAll`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      cancelToken: cancelToken.token,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      if (response.status === 200) {
        const filteredAssignments = response.data.result.filter(task => task.assignments !== null && task.gradeType === "Instructor Grade" && task?.instructorList[0]?.instructorId == mentorId);
        setTasks((prevState) => ({
          ...prevState,
          assignments: filteredAssignments,
          assignmentLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const userCourseAssignmentList = async (index) => {
    setTasks((prevState) => ({
      ...prevState,
      assignmentIndex: index,
      userListLoading: true,
    }))
    let courseId = tasks?.assignments[index]?.id;
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Review/userCourseAssignmentList?courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      cancelToken: cancelToken.token,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      if (response.status === 200) {
        setTasks((prevState) => ({
          ...prevState,
          userList: response.data.result,
          userListLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    if (tasks.assignments.length !== 0) {
      userCourseAssignmentList(0);
    }
  }, [tasks.assignments]);
  function hideDetailHeading() {
    const ele = document.getElementById("task-heading-wrapper-mobile-view");
    if (ele) {
      ele.style.display = "flex";
      const ele2 = document.getElementById("task-details-wrapper-mobile-view");
      if (ele2) {
        ele2.style.display = "none";
      }
    }
  }
  function hideTaskHeading() {
    const ele = document.getElementById("task-heading-wrapper-mobile-view");
    if (ele) {
      ele.style.display = "none";
      const ele2 = document.getElementById("task-details-wrapper-mobile-view");
      if (ele2) {
        ele2.style.display = "flex";
      }
    }
  } 
  useEffect(() => {
    fetchData();
    fetchAssignmentData();
    return () => {
      cancelToken.cancel("request cancelled");
    };
  }, [selectedBatches]);

  return (
    <div className="mentortask-parent-div">
      <div style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
        <SideNav />
      </div>
      <div className="mentortask-parent-wrapper" id="mentortask-desktop-view">
        <div className="mentortask-header-wrapper">
          <span>Manage Task</span>
          <div className="manage-task-toggle d-flex">
            <button className={`top-enrolled-left-btn ${selectedButton === "sde" ? "top-enrolled-selected-btn" : "top-enrolled-non-selected-btn"} d-flex align-items-center justify-content-center border-0`} onClick={() => { sessionStorage.removeItem("activeToggle"); setSelectedButton("sde"); }}>
              <p className="top-enrolled-text m-0">SDE's</p>
            </button>
            <button className={`top-enrolled-rt-btn ${selectedButton === "cgu" ? "top-enrolled-selected-btn" : "top-enrolled-non-selected-btn"} d-flex align-items-center justify-content-center border-0`} onClick={() => setSelectedButton("cgu")}>
              <p className="top-enrolled-text m-0">CG University</p>
            </button>
          </div>
        </div>
        {selectedButton === "sde" && <>
          <div className="col-12 mentortask-data-wrapper">
            {tasks.isLoading ? (
              <div className="col-xs-12 p-2 pe-4 col-4 task-heading-wrapper border-end">
                {Array.from({ length: 8 }).map((_, index) => (
                  <MentorAssignTaskSkeletonLoading key={index} />
                ))}
              </div>
            ) : tasks.adminTask?.length === 0 ? (
              <div className="d-block">
                <EmptyMentorTask className="matCal-NoData" />
                <p className="fw-bold mt-3 mb-1 empty-task-text MatNoData">No task has been assigned recently for review!</p>
                <p className="task-link MatNoDataLink" data-bs-toggle="modal" data-bs-target="#addTaskModal">
                  Assign New Task
                </p>
                <MentorAssignNewTask mentorId={mentorId} fetchData={fetchData} selectedBatches={selectedBatches} />
              </div>
            ) : (
              <>
                <div className="col-xs-12 p-2 pe-4 col-4 task-heading-wrapper border-end">
                  <button className="mentor-task-btn" data-bs-toggle="modal" data-bs-target="#addTaskModal">
                    <span className="plus-style">+</span>
                    <span>Assign New Task</span>
                  </button>
                  <MentorAssignNewTask mentorId={mentorId} fetchData={fetchData} selectedBatches={selectedBatches} />

                  <div className="overflow-auto">
                    {tasks.adminTask &&
                      tasks.adminTask.map((task, index) => {
                        return (
                          <div
                            key={index}
                            className={`mb-2 task-heading-inner-wrapper ${tasks.taskInd !== index ? "task-heading-inactive-wrapper" : ""}`}
                            onClick={() =>
                              setTasks((prevState) => ({
                                ...prevState,
                                taskInd: index,
                              }))
                            }
                          >
                            {tasks.taskInd === index && <div className="selected-task-style" />}
                            <div className="task-heading-head">
                              <h5 className="mb-0">{task.taskName}</h5>
                              <Arrorforward />
                            </div>
                            <div className="task-heading-body">
                              <p className="mb-0">{task.taskDescription.length > 80 ? `${task.taskDescription.slice(0, 80)}...` : task.taskDescription}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-xs-0 ms-2 p-2 col-8 overflow-auto">{tasks.isLoading ? <MentorTaskDetailsSkeleton /> : <MentorTaskDetail detail={tasks.adminTask[tasks.taskInd]} selectedBatches={selectedBatches} />}</div>
              </>
            )}
          </div>
        </>}
        {selectedButton === "cgu" && <>
          <div className="col-12 mentortask-data-wrapper">
            {tasks.assignmentLoading ? (
              <div className="col-xs-12 p-2 pe-4 col-4 task-heading-wrapper border-end">
                {Array.from({ length: 8 }).map((_, index) => (
                  <MentorAssignTaskSkeletonLoading key={index} />
                ))}
              </div>
            ) : tasks.assignments?.length === 0 ? (
              <div className="d-block">
                <EmptyMentorTask className="matCal-NoData" />
                <p className="fw-bold mt-3 mb-1 empty-task-text MatNoData">No assignment has been assigned recently for review!</p>
              </div>
            ) : (
              <>
                <div className="col-xs-12 p-2 pe-4 col-4 task-heading-wrapper border-end">
                  <div className="overflow-auto">
                    {tasks.assignments &&
                      tasks.assignments.map((task, index) => {
                        return (
                          <div
                            key={index}
                            className={`mb-2 task-heading-inner-wrapper ${tasks.assignmentIndex !== index ? "task-heading-inactive-wrapper" : ""}`}
                            onClick={() =>
                              userCourseAssignmentList(index)
                            }
                          >
                            {tasks.assignmentIndex === index && <div className="selected-task-style" />}
                            <div className="task-heading-head">
                              <h5 className="mb-0" title={task.title}>{task.title.length > 90 ? `${task.title.slice(0, 90)}...` : task.title}</h5>
                              <Arrorforward />
                            </div>
                            <div className="task-heading-body">
                              <p className="mb-0">{task.description.length > 80 ? `${task.description.slice(0, 80)}...` : task.description}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-xs-0 ms-2 p-2 col-8 dp-scroll">
                  {tasks.isLoading ? <MentorTaskDetailsSkeleton /> :
                    <AssignmentDetail detail={tasks.assignments[tasks.assignmentIndex]} userList={tasks.userList} isLoading={tasks.userListLoading} />}</div>
              </>
            )}
          </div>
        </>}
      </div>
      {selectedButton === "sde" && <>
        <div class="mentortask-parent-wrapper-mobile-view" id="mentortask-mobile-view">
          <div className="mentortask-parent-wrapper">
            <div className="mentortask-header-wrapper">
              <span>Assign Task</span>
              <MentorAssignNewTask mentorId={mentorId} fetchData={fetchData} selectedBatches={selectedBatches} />
            </div>
            <div className="row mentortask-data-wrapper">
              {tasks.adminTask?.length === 0 ? (
                <div className="d-block">
                  <EmptyMentorTask className="matCal-NoData" />
                  <p className="fw-bold mt-3 mb-1 empty-task-text MatNoData">No task has been assigned recently for review!</p>
                  <p className="task-link MatNoDataLink" data-bs-toggle="modal" data-bs-target="#addTaskModal">
                    Assign New Task
                  </p>
                </div>
              ) : (
                <div className="col-xs-12 col-md-4 task-heading-wrapper" id="task-heading-wrapper-mobile-view" onClick={hideTaskHeading}>
                  {tasks.adminTask &&
                    tasks.adminTask.map((task, index) => {
                      return (
                        <div
                          key={index}
                          className="mb-2 task-heading-inner-wrapper"
                          onClick={() =>
                            setTasks((prevState) => ({
                              ...prevState,
                              taskInd: index,
                            }))
                          }
                        >
                          {tasks.taskInd === index && <div className="selected-task-style" />}
                          <div className="task-heading-head">
                            <h5 className="mb-0" title={task.taskName}>{task.taskName.length > 90 ? `${task.taskName.slice(0, 90)}...` : task.taskName}</h5>
                            <Arrorforward />
                          </div>
                          <div className="task-heading-body">
                            <p className="mb-0">{task.taskDescription.length > 80 ? `${task.taskDescription.slice(0, 80)}...` : task.taskDescription}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}

              <div className="col-xs-12 col-md-7 p-0" style={{ margin: "0" }} id="task-details-wrapper-mobile-view">
                <LeftSideArrow class="bi bi-arrow-left" onClick={hideDetailHeading} />
                {selectedButton === "sde" && <MentorTaskDetail detail={tasks.adminTask[tasks.taskInd]} />}
                {selectedButton === "cgu" && <AssignmentDetail detail={tasks.assignments[tasks.assignmentIndex]} />}
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};

export default MentorAssignTask;
