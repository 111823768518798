import { decryptData, handleApiError, FilterBase, useState, useEffect, Filter, Reporttable, axios, Header, SideNav, Confirmation, useNavigate } from "../../AdminPortal/AdminImport";
import "./Report.css";

const Report = () => {
  const [filters, setFilters] = useState({
    selectedBatches: JSON.parse(sessionStorage.getItem("Batches")) || [],
    showFilterBase: false,
    filtersApplied: false,
    query: "",
    selectedLevelName: [],
    selectedTechName: [],
    selectedOption: { project: false, onBench: false },
  });

  const [loadingStates, setLoadingStates] = useState({
    isLoading: true,
    confirmChange: false,
  });

  const [dataStates, setDataStates] = useState({
    tableData: [],
    orgTableData: [],
    deployData: [],
    apiLevel: []
  });

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleDeployChange = (id) => {
    setDataStates((prevData) => {
      const index = prevData.orgTableData.findIndex((item) => item.id === id);
      const containValue = prevData.deployData?.some((value) => value?.id === prevData.orgTableData[index].id);

      let tempData;
      if (containValue) {
        tempData = prevData.deployData.filter((value) => value.id !== id);
      } else {
        tempData = [...prevData.deployData];
        tempData.push({
          id: prevData.orgTableData[index].id,
          status: !prevData.orgTableData[index].isDeployed,
        });
      }

      return { ...prevData, deployData: tempData };
    });
  };

  const handleCancel = () => {
    setDataStates((prevData) => {
      let tempDeployedTable = [];
      prevData.orgTableData.forEach((value) => {
        tempDeployedTable.push({ ...value });
      });
      return {
        ...prevData,
        tableData: tempDeployedTable,
        confirmChange: false,
        deployData: [],
      };
    });

    setLoadingStates((prevState) => ({ ...prevState, confirmChange: false }));
  };

  const handleConfirm = async () => {
    setLoadingStates(() => ({ confirmChange: false, isLoading: true }));

    const transformedData = {
      ids: dataStates.deployData.map((value) => value.id),
      updatedBy: parsedObject["decoded"]["Id"],
    };

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Reports/changeDeploymentStatus`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(url, transformedData, { headers });
      if (response?.status === 200) {
        fetchData();
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const handleFiltersChange = () => {
      const getFilterItems = (items, query) => {
        if (query !== "") {
          return items.filter((item) => item.name.toLowerCase().startsWith(query.toLowerCase()));
        }
        return items;
      };
  
      const getfilterTech = (items) => {
        if (filters.selectedTechName?.length > 0 && filters.selectedLevelName?.length > 0) {
          const filteredData = items?.filter((data) => {
            return data?.techName?.some((element) => filters.selectedTechName.includes(element))
                && data?.levelName?.some((element) => filters.selectedLevelName.includes(element));
        });
        return filteredData;
        } else if (filters.selectedTechName?.length > 0) {
          const filteredData = items?.filter((data) => {
              return data?.techName?.some((element) => filters.selectedTechName.includes(element));
          });
          return filteredData;
        } else if (filters.selectedLevelName?.length > 0) {
          const filteredData = items?.filter((data) => {
              return (
                  (data.beginnerCount !== null && data.beginnerCount > 0 && filters.selectedLevelName.includes("Beginner")) ||
                  (data.intermediateCount !== null && data.intermediateCount > 0 && filters.selectedLevelName.includes("Intermediate")) ||
                  (data.advancedCount !== null && data.advancedCount > 0 && filters.selectedLevelName.includes("Advance"))
              );
          });
          return filteredData;
        }
        return items;
      };
  
      const getfilterDep = (items) => {
        const { project, onBench } = filters.selectedOption;
        if (!project && !onBench) return items;
  
        let filteredItems = [];
        if (project) {
          const filteredProjectItems = items.filter((item) => item.isDeployed);
          if (filteredProjectItems.length > 0) {
            filteredItems = filteredItems.concat(filteredProjectItems);
          }
        }
        if (onBench) {
          const filteredBenchItems = items.filter((item) => !item.isDeployed);
          if (filteredBenchItems.length > 0) {
            filteredItems = filteredItems.concat(filteredBenchItems);
          }
        }
  
        return filteredItems;
      };
  
      const filterItems = getFilterItems(dataStates.orgTableData, filters.query);
      const filterTech = getfilterTech(filterItems);
      const filterDep = getfilterDep(filterTech);
  
      setDataStates((prevData) => ({ ...prevData, tableData: filterDep }));
    };

  const fetchData = async () => {
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/ActivityLog/getLogsGroupByUserAndBatchName`;
    let body = {
      batchId: filters.selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(apiUrl, body, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setDataStates((prevData) => ({
          ...prevData,
          deployData:prevData.deployData,
          orgTableData: response?.data?.result?.sort((a, b) => a.name.localeCompare(b.name)),
          tableData: response?.data?.result?.sort((a, b) => a.name.localeCompare(b.name)),
        }));

        setLoadingStates((prevLoading) => ({
          ...prevLoading,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setDataStates((prevState) => ({
          ...prevState,
          apiLevel: response.data.result,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  useEffect(() => {
    fetchLevel();
    fetchData();
  }, [filters.selectedBatches]);

  useEffect(() => {
    handleFiltersChange();
  }, [filters.showFilterBase, filters.query]);

  return (
    <>
      {loadingStates.confirmChange && <Confirmation confirmationValue={"report"} handleCancel={handleCancel} handleConfirm={handleConfirm} />}
      <div className="report-main-div">
        <Header selectedBatches={filters.selectedBatches} setSelectedBatches={(selectedBatches) => setFilters((prevFilters) => ({ ...prevFilters, selectedBatches }))} />
        <SideNav />
      </div>
      {filters.showFilterBase && <FilterBase filterType="Report" data={dataStates} filterState={filters} setFilterState={setFilters} onApplyFilters={handleFiltersChange} />}
      <div className="report-parent-wrapper ms-5">
        <div className="report-child-wrapper">
          <div className="report-header">
            <span>Report</span>
            {dataStates.deployData?.length > 0 && (
              <button
                className="report-deploy-btn"
                onClick={() => {
                  setLoadingStates((prevLoading) => ({
                    ...prevLoading,
                    confirmChange: true,
                  }));
                }}
              >
                Update Status
              </button>
            )}
          </div>
          <div className="report-filter-wrapper ">
            <div className="report-search">
              <div className="report-search-icon" />
              <input
                type="text"
                className="report-input-bar"
                placeholder="Search SDE"
                value={filters.query}
                onChange={(e) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    query: e.target.value,
                  }))
                }
              />
            </div>
            <div className="inner-filter pointer">
              <button
                  className="fw-bold filter-button"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      showFilterBase: true,
                    });
                  }}
                >
                <Filter className="blue-filter-icon"/> Filter
                </button>
            </div>
          </div>
          <div className="report-table-wrapper ">
            <Reporttable tableData={dataStates.tableData} isLoading={loadingStates.isLoading} deployData={dataStates.deployData} handleDeployChange={handleDeployChange} fetchData ={fetchData}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
