import React from 'react'
import { Skeleton } from "../../UserImport";

const ActivityDetailedSkeleton = ({ activityType }) => {
    const skeletons = [];
    for (let index = 0; index < 3; index++) {
        skeletons.push(
            <>
                <div className="module my-2" key={index}>
                    <Skeleton className="module-name-wrapper" />
                </div>
                <Skeleton count={3} />
            </>);
    }
    return (
        <div className="container-fluid activity-parent-wrapper activity-parent-wrapper-bg">
            <div className={`d-flex flex-row activity-wrapper mb-2 pb-2  ${activityType.toLocaleLowerCase() == "techtalk" && "tech-talk-bg"}`}>
                <div className={`col-8 ms-5 d-flex gap-3 ps-0`}>
                    <div className="activity-image-wrapper">
                        <Skeleton />
                    </div>
                    <div className="activity-content">
                        <Skeleton />
                        <div className="actity-title">
                            <h5><Skeleton /></h5>
                        </div>
                        <div className="activity-description">
                            <p><Skeleton count={3} /> </p>
                        </div>
                        <Skeleton />
                    </div>
                </div>
                <div className="col-4 mentor-card-wrapper p-3 ">
                    <div className="mentor-info-wrapper d-flex gap-3">
                        <div className="mentor-img-wrapper">
                            <Skeleton circle={true} width={40} height={40} />
                        </div>
                        <div className="mentor-name-wrapper">
                            <Skeleton count={3} />
                        </div>
                    </div>
                    <div className="mentor-about-wrapper mt-0">
                        <p className="my-0"><Skeleton count={3} /></p>
                    </div>
                </div>
            </div>
            {
                activityType.toLocaleLowerCase() == "techtalk" && (<div className="row ms-5 duration-wrapper">
                    <p className="mb-0">
                        <Skeleton />
                    </p>
                    <span><Skeleton /></span>
                </div>)
            }

            <div className="row ms-5 d-flex flex-row content-wrapper p-2 mb-3 overflow-hidden">

                <div className="col-8 content-viewer p-0">
                    <Skeleton baseColor="#343435" height={470} width={730} />
                </div>
                <div className="col-4 module-wrapper m-3 p-0">
                    <div className="d-flex justify-content-around  align-content-center module-start-end">
                        <div className="d-flex flex-column ">
                            <span><Skeleton /></span>
                            <span><Skeleton /></span>
                        </div>
                        <div className="d-flex flex-column ">
                            <span><Skeleton /></span>
                            <span><Skeleton /></span>
                        </div>
                    </div>
                    {skeletons}
                </div>
            </div>

        </div >
    )
}

export default ActivityDetailedSkeleton;