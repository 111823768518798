import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Accounts.css";
import Cginfinitylogo from "../../../Assets/Cginfinitylogo.svg";
import Cginfinitylogoresponsive from "../../../Assets/Cginfinitylogoresponsive.svg";
import CarouselImage1 from "../../../Assets/CarouselImage1.svg";
import CarouselImage2 from "../../../Assets/CarouselImage2.svg";
import CarouselImage3 from "../../../Assets/CarouselImage3.svg";
import CryptoJS from "crypto-js";


const SignUpScreen = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [incorrectemail, setIncorrectemail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const secretKey = process.env.REACT_APP_USER_KEY;

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIncorrectemail(false);
    setIsEmailValid(value.match(/^[\w.-]+@cginfinity\.com$/) ? true : false);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setIsPasswordValid(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value)
        ? true
        : false
    );
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    axios
      .post(process.env.REACT_APP_API_URL_DOTNET+"/Authentication/signUp", {
        userName: email,
        password,
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success === 409) {
          throw response; // This will send the response to the catch block
      }
        setIsSuccess(true);
        setSuccessMsg(response.data.message);
        // navigate("/success");
      })
      .catch((error) => {
        if (error.data.message) {
          setIsEmailValid(false);
          setIncorrectemail(true);
          setErrorMsg(error.data.message)
        }
        setIsLoading(false);
        if(error.data.success == 400) {
          navigate('/error?statusCode=400')
        } 
         if(error.data.success == 500) {
          navigate('/error?statusCode=500')
        } 
      });
  };

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="container-fluid login-screen-body div-xs">
      <div className="row pos">
        <div className="d-flex justify-content-center align-items-center flex-row">
          <div
            className="col-md-5 div-bg-height-width-fixed div-hidden-xs"
            
          >
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <div className="row cglogoimg h-100">
                <img
                  className="p-0"
                  src={Cginfinitylogo}
                  alt="CG-Infinity Logo"
                />
              </div>
              <div className="row card-left-heading">
                <p>CG University</p>
              </div>
              <div
                id="carouselExampleIndicators"
                className="carousel slide mt-3"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div
                    
                    className="carousel-item active div-width-16-fixed"
                  >
                    <img
                      src={CarouselImage1}
                      className="d-block div-width-mar-fixed "
                      alt="..."                 
                    />
                    <p className="carousel-text ms-4">
                      Record your daily work items
                    </p>
                  </div>

                  <div                    
                    className="carousel-item div-width-16-fixed"
                  >
                    <img
                      src={CarouselImage2}
                      className="d-block div-width-mar-fixed"
                      alt="..."
                      
                    />
                    <p className="carousel-text">
                      Enhance your skills via assessments
                    </p>
                  </div>
                  <div                    
                    className="carousel-item div-width-17-fixed"
                  >
                    <img
                      src={CarouselImage3}
                      className="d-block div-width-mar-fixed"
                      alt="..."
                      
                    />
                    <p className="carousel-text">
                      Get certificate and share achievement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 bg-white p-4 div-hight35-width30-fixed" >
            <div className="row div-hidden-xs ">
              <p className="right-container-heading">Sign Up</p>        
            </div>
            <div className="row  div-hidden d-md-none">
            <div className="row cglogoimg">
                <img
                  className="p-0 cglogoimg-xs"
                  src={Cginfinitylogoresponsive}
                  alt="CG-Infinity Logo"                 
                />
              </div>
              <div className="row">
              <p className="right-container-heading">CG University</p>
              </div>
            </div>          
            <div className="row div-height-166-fixed" >
              <form 
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="div-hight-martop-fixed ">
                  <div className="d-flex flex-column ">
                    <label
                      className="input-label-text"
                      htmlFor="exampleInputEmail1"
                    >
                      Email ID
                    </label>
                    <div className="div-input w-md-75">
                      <input
                      className="input-login w-md-75"
                      type="email"
                      id="exampleInputEmail1"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter Your Email ID"
                      required
                    />
                    </div>
                    {!isEmailValid ? (email && (
                      <span className="sign-up-warning">
                        {incorrectemail
                          ? errorMsg
                          : "Please make use of CG-Infinity email only"}
                      </span>
                    )) : (isSuccess && <span className="sign-up-success">
                    {successMsg}
                  </span>)}
                  </div>
                  <div className="d-flex flex-column">
                    <label className="input-label-text div-martop-fixed"
                      htmlFor="exampleInputPassword1"
                    >
                      Password
                    </label>
                    <div className="input-group">
                      <div className="div-input pass-input-div w-100">
                        <input
                      className="input-login w-100"
                      type={showPassword ? "password" : "text"}
                      id="exampleInputPassword1"
                      placeholder="Enter Your Password"
                      value={password}
                      required
                      onChange={handlePasswordChange}
                    />
                     <button
                        className="btn password-toggle-button btn-border-none-fixed"
                        
                        type="button"
                        onClick={handleTogglePasswordVisibility}
                      >
                        {showPassword ? (
                            <i>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 22 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5ZM11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8C6 6.67392 6.52678 5.40215 7.46447 4.46447C8.40215 3.52678 9.67392 3 11 3C12.3261 3 13.5979 3.52678 14.5355 4.46447C15.4732 5.40215 16 6.67392 16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5Z"
                                  fill="#343435"
                                />
                              </svg>
                            </i>
                          ) : (
                            <i>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.83 6L14 9.16C14 9.11 14 9.05 14 9C14 8.20435 13.6839 7.44129 13.1213 6.87868C12.5587 6.31607 11.7956 6 11 6C10.94 6 10.89 6 10.83 6ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.77 8 9C8 9.79565 8.31607 10.5587 8.87868 11.1213C9.44129 11.6839 10.2044 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C9.67392 14 8.40215 13.4732 7.46447 12.5355C6.52678 11.5979 6 10.3261 6 9C6 8.21 6.2 7.47 6.53 6.8ZM1 1.27L3.28 3.55L3.73 4C2.08 5.3 0.78 7 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.81 16.08L18.73 19L20 17.73L2.27 0M11 4C12.3261 4 13.5979 4.52678 14.5355 5.46447C15.4732 6.40215 16 7.67392 16 9C16 9.64 15.87 10.26 15.64 10.82L18.57 13.75C20.07 12.5 21.27 10.86 22 9C20.27 4.61 16 1.5 11 1.5C9.6 1.5 8.26 1.75 7 2.2L9.17 4.35C9.74 4.13 10.35 4 11 4Z"
                                  fill="#343435"
                                />
                              </svg>
                            </i>
                          )}
                      </button>
                      </div>
                    </div>
                    {!isPasswordValid && password && (
                      <span className="sign-up-warning">
                        Atleast 8 characters, one uppercase, number & special
                        characters required.
                      </span>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-warning border-0 sign-up-btn width-inherit-fixed  w-100 fw-bold"
                  disabled={!isEmailValid || !isPasswordValid || isLoading}
                >
                  {isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light login "
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>{sessionStorage.removeItem("otpTimer")} Sign Up</>
                  )}
                </button>
              </form>
            </div>
            <div className="row p-3">
              <Link className="right-container-link" to="/">
                Already have an account?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
