
import "./Task.css";
import { useState, AssignTask, MentorList, Header, SideNav } from "../AdminImport"

const Task = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  return (
    <>
      <div  style={{ marginBottom: "5rem" }}>
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div>

      <div className="assign-task-page">
        <div className="container-fluid ">
          <div className="row mt-4">
            <div className=" col-md-7 p-0" style={{ marginLeft: "56px" }}>
              <AssignTask selectedBatches={selectedBatches} />
            </div>
            <div className=" col-md-4 p-0 " style={{ marginLeft: "16px" }}>
              <MentorList selectedBatches={selectedBatches} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
