import { React,Modal, CheckCircle, SessionWarning } from "../../../AdminPortal/AdminImport";
import "./../SessionDetails/SessionDetails.css";

const SessionDetails = (props) => {
  const { detailModal, handleClose, sessionDetailsData } = props;
  
  return (
    <Modal size="xl" dialogClassName="modal-100w" backdrop="static" centered show={detailModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton className="border-0">
        <p className="modal-head">CSV Details</p>
      </Modal.Header>

      <Modal.Body className="d-flex justify-content-center flex-column align-items-center">
        <>
          <table className="table">
            <thead>
              <tr>
                <th className="col session-user-table-head session-name-col">Technology</th>
                <th className="col session-user-table-head session-progress-col">Mentor</th>
                <th className="col session-user-table-head session-start-col text-center">Position</th>
                <th className="col session-user-table-head session-end-col text-center">Date</th>
                <th className="col session-user-table-head session-action-col text-center">Time</th>
                <th className="col session-user-table-head session-action-col text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <>
                {sessionDetailsData?.map((val, ind) => (
                  <tr key={ind}>
                    <td className={`${val.sessionId === null ? "row-color" : ""}`} style={{ verticalAlign: "middle", height: "40px" }}>
                      <div className="name-column">{val.sessionTechnology}</div>
                    </td>
                    <td className={`tag2 tag2-adjustment progress-height ${val.sessionId === null ? "row-color" : ""}`}>
                      <div>{val.mentorName}</div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height ${val.sessionId === null ? "row-color" : ""}`}>
                      <div>{val.mentorDesignation}</div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height ${val.sessionId === null ? "row-color" : ""}`}>{val.addSessionDate}</td>
                    <td className={`tag2 text-center tag2-adjustment td-height ${val.sessionId === null ? "row-color" : ""}`}>{val.addSessionStartTime}</td>
                    <td className={`tag2 text-center tag2-adjustment td-height ${val.sessionId === null ? "row-color" : ""}`}>
                      {val.sessionId == null ? (
                        <div className="d-flex justify-content-center " data-toggle="tooltip" data-placement="top" title={`${val?.message?.split(":")[1]}`}>
                        <SessionWarning />
                          <div className="ms-1 fail">Failed</div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <CheckCircle />
                          <div className="ms-1 success">Success</div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
          <div className="d-flex flex-row-reverse w-100 mt-5">
            <button type="button" className="taskAddedClass me-1 btn-primary " onClick={handleClose}>
              Close
            </button>
          </div>
        </>
      </Modal.Body>
    </Modal>

  );
};

export default SessionDetails;
