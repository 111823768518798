import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { ReactComponent as GoldStarOri } from "../../../../Assets/Star-Icon-gold-ori.svg";
import { ReactComponent as SilverStarOri } from "../../../../Assets/Star-Icon-silver-ori.svg";
import { ReactComponent as BronzeStarOri } from "../../../../Assets/Star-Icon-bronze-ori.svg";
import CryptoJS from "crypto-js";
import { ReactComponent as SearchIcon } from "../../../../Assets/search.svg";
import { ReactComponent as DownloadScoreDisabled } from "../../../../Assets/DownloadScoreDisabled.svg";
import { ReactComponent as DownloadScoreEnabled } from "../../../../Assets/DownloadScoreEnabled.svg";
import { ReactComponent as TakeTestSmallCheck } from "../../../../Assets/TakeTestSmallCheck.svg";
import "./TakeTest.css";
import { BsClock } from "react-icons/bs";
import { MdOutlineBallot } from "react-icons/md";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../Context/Context";
import TakeTestSkeleton from "./TakeTestSkeleton";
import { TestContext } from "../SkillManagement";
import { ReactComponent as SkillAddedEmpty } from "../../../../Assets/SkillAddedEmpty.svg";

const scoreIndex = {
  Beginner: 0,
  Intermediate: 1,
  Advance: 2,
  Project: 3,
};

const TakeTest = () => {
  const [activeButton, setActiveButton] = useState("all");
  const { setScore, setExamName, setExamLevel, setDateOfTest, setTimeOfTest } = useContext(UserContext);
  const [originalTests, setOriginalTests] = useState([]);
  const [data, setdata] = useState();
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const { tests, setTests, resultInfo } = useContext(TestContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      fetchTests();
    }, 1000);
  }, []);

  const fetchTests = async () => {
    const secretkeyUser = process.env.REACT_APP_USER_KEY;
    var parsedObject;
    const data = localStorage.getItem("userData");
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
      const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
      parsedObject = JSON.parse(decryptedJsonString);
    } else {
      console.error("No data found.");
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL_DOTNET + `/Exam/examDetails`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/error/statusCode=401");
        } else if (response.status === 400) {
          navigate("/error/statusCode=400");
        } else if (response.status === 500) {
          navigate("/error/statusCode=500");
        } else if (response.status === 404) {
          navigate("/error/statusCode=404");
        }
      }
      const data = await response.json();
      setTests(data.result);
      setOriginalTests(data.result);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching exam details:", error);
    }
  };

  const handleFiltersChange = () => {
    const getFilterItems = (items, searchValue) => {
      if (searchValue) {
        return items.filter((item) => item.examName?.toLowerCase().startsWith(searchValue.toLowerCase()) || item.techName?.toLowerCase().startsWith(searchValue.toLowerCase()));
      }
      return items;
    };

    const filters = getFilterItems(originalTests, searchFilterValue);

    let filteredTests = [];

    if (activeButton === "all") {
      filteredTests = filters;
    } else if (activeButton === "beginner") {
      filteredTests = filters.filter((test) => test.levelName === "Beginner");
    } else if (activeButton === "inter") {
      filteredTests = filters.filter((test) => test.levelName === "Intermediate");
    } else if (activeButton === "advanced") {
      filteredTests = filters.filter((test) => test.levelName === "Advance");
    }

    setTests(filteredTests);
  };

  const handleDownload = (currentScore, examName, examLevel, dateOfTest, timeOfTest) => {
    setScore(currentScore);
    setExamName(examName);
    setExamLevel(examLevel);
    const newDateOfTest = `${dateOfTest.getMonth() + 1}/${dateOfTest.getDate()}/${dateOfTest.getFullYear()}`;
    setDateOfTest(newDateOfTest);
    setTimeOfTest(timeOfTest);
  };

  useEffect(() => {
    handleFiltersChange();
  }, [searchFilterValue, activeButton]);
  const clickHandler = (examId, examName, examDuration, numberOfQuestion, techName, levelName) => {
    setdata({
      examId: examId,
      examName: examName,
      examDuration: examDuration,
      numberOfQuestion: numberOfQuestion,
      techName: techName,
      levelName: levelName,
    });
  };
  const clickCont = () => {
    let timeCLicked = Date.now() + 40 * 1000;
    navigate(`/take-your-test#${timeCLicked}`);
    sessionStorage.setItem("examData", JSON.stringify(data));

  };

  return (
    <>
      <div className="TTheading">
        <p>Test Your Skills</p>
      </div>
      <div className="card maincard">
        <div className="card-header-nav">
          <div className="d-flex">
            <div
              className={`nav-btn pointer ${activeButton === "all" ? "activated" : ""}`}
              onClick={() => {
                setActiveButton("all");
              }}
            >
              <button className="btn-nav p-0">All</button>
            </div>
            <div
              className={`nav-btn pointer ${activeButton === "beginner" ? "activated" : ""}`}
              onClick={() => {
                setActiveButton("beginner");
              }}
            >
              <button className="btn-nav p-0">Beginner</button>
            </div>
            <div
              className={`nav-btn pointer ${activeButton === "inter" ? "activated" : ""}`}
              onClick={() => {
                setActiveButton("inter");
              }}
            >
              <button className="btn-nav p-0">Intermediate</button>
            </div>
            <div
              className={`nav-btn pointer ${activeButton === "advanced" ? "activated" : ""}`}
              onClick={() => {
                setActiveButton("advanced");
              }}
            >
              <button className="btn-nav p-0">Advanced</button>
            </div>
          </div>
          <div className="d-flex align-items-center ps-1 takeTest-search-wrapper">
            <SearchIcon />
            <input
              className="sea"
              type="text"
              value={searchFilterValue}
              placeholder="Search By Technology Or Exam Name"
              onChange={(event) => {
                event.preventDefault();
                setSearchFilterValue(event.target.value);
              }}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="card-body testCard">
            <div className="row cards main-card-inside mx-0">
              <div className="row">
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
                <TakeTestSkeleton />
              </div>
            </div>
          </div>
        ) : tests?.length === 0 ? (
          <div className="card-body testCard d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center m-auto">
              <SkillAddedEmpty />
              <p className="empty-skill-heading">No Test Available Right Now !!</p>
            </div>
          </div>
        ) : (
          <div className="card-body testCard">
            <div className="row cards main-card-inside mx-0 dp-scroll">
              <div className="row mb-4">
                {tests?.map((test, key) => {
                  const currentScore = resultInfo.find((result) => result.examName === test.examName)?.examScorePercentage[scoreIndex[test.levelName]];
                  const currentDate = new Date();
                  const dateOfTest = new Date(resultInfo.find((result) => result.examName === test.examName)?.examTakenDate[scoreIndex[test.levelName]]);
                  const timeOfTest = resultInfo.find((result) => result.examName === test.examName)?.examTakenTime[scoreIndex[test.levelName]];
                  currentDate.setDate(currentDate.getDate() - 2);
                  return (
                    <div className="exam" style={{ marginLeft: "9px", marginRight: "8px" }} key={key}>
                      <div className="card outer-card">
                        {!test.canRetakeTest && !test.isPassed && test.remainingDays > 0 && (
                          <div className="test-badge" key={test.examName}>
                            <p className="test-badge-text">{`${test.remainingDays} Days left to retake`}</p>
                          </div>
                        )}{" "}
                        {test.isPassed && test.canStartExam && (
                          <div
                            className="test-badge d-flex flex-row"
                            style={{
                              width: "99px",
                              backgroundColor: "rgba(45, 194, 107, 1)",
                              left: "18.7rem",
                            }}
                            key={test.examName}
                          >
                            <TakeTestSmallCheck style={{ marginTop: "2px" }} />
                            <p className="test-badge-text">Completed</p>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <div className="ml-3 w-100">
                            <div className="d-flex justify-content-start ">
                              <div className="imagespace">
                                <img src={test.techImageLink} className="imageLogo" width="1.875rem" height="2.188rem" />
                              </div>
                              <div>
                                <div className="d-flex">
                                  <div className="Category_box justify-content-center">
                                    <span className="Category">
                                      {test.levelName} &nbsp;
                                      {test.levelName === "Beginner" ? <BronzeStarOri /> : test.levelName === "Intermediate" ? <SilverStarOri /> : test.levelName === "Advance" ? <GoldStarOri /> : null}
                                    </span>
                                  </div>
                                  {test.isPassed && test.canStartExam ? <div className="download-btn">
                                    <DownloadScoreEnabled onClick={() => handleDownload(currentScore, test.examName, test.levelName, dateOfTest, timeOfTest)} />
                                  </div> : test.numberOfAttempts > 0 ? <div className="download-btn">
                                    <DownloadScoreEnabled onClick={() => handleDownload(currentScore, test.examName, test.levelName, dateOfTest, timeOfTest)} />
                                  </div> : <div className="download-btn pe-none">
                                    <DownloadScoreDisabled />
                                  </div>}
                                </div>
                                <div className=" About_box justify-content-center">
                                  <span className={test.examName.length > 40 ? "truncate-text About" : "About"} title={test.examName}>
                                    {test.examName.length > 40 ? `${test.examName.substring(0, 40)}...` : test.examName}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className=" col d-flex justify-content-between eounded text-grey quesTimeClick ">
                              <div className="d-flex flex-column justify-content-center noOfQues">
                                <div className="articles d-flex justify-content-center">
                                  <MdOutlineBallot style={{ marginRight: "0.313rem" }} />
                                  {test.numberOfQuestion} Questions
                                </div>
                              </div>
                              <div className="d-flex flex-column justify-content-center testTime">
                                <div className="articles d-flex justify-content-center ">
                                  <BsClock style={{ marginRight: "0.313rem" }} />
                                  {test.examDuration} mins
                                </div>
                              </div>
                              <div className="d-flex flex-column">
                                <Button
                                  onClick={() => clickHandler(test.examId, test.examName, test.examDuration, test.numberOfQuestion, test.techName, test.levelName)}
                                  className="testbtnclick"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  disabled={!test.canStartExam}
                                  style={{
                                    backgroundColor:
                                      (test.canStartExam && test.canRetakeTest && test.remainingDays === 0 && !test.isPassed) || (test.isPassed)
                                        ? "rgba(255, 184, 28, 1)" //yellow
                                        : !test.canStartExam && !test.canRetakeTest && !test.remainingDays === 0
                                          ? "rgba(178, 178, 179, 1)" //gray
                                          : "rgba(40, 81, 158, 1)",
                                    color: (test.canStartExam && test.canRetakeTest && test.remainingDays === 0 && !test.isPasses) || test.isPassed ? "rgba(52, 52, 53, 1)" : !test.canStartExam && !test.canRetakeTest && !test.remainingDays === 0 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 1)",
                                    borderColor: (test.canStartExam && test.canRetakeTest && test.remainingDays === 0 && !test.isPassed) || test.isPassed ? "rgba(255, 184, 28, 1)" : !test.canStartExam && !test.canRetakeTest && !test.remainingDays === 0 ? "rgba(178, 178, 179, 1)" : "rgba(40, 81, 158, 1)",
                                  }}
                                >
                                  {test.isPassed && test.canStartExam ? "Retake Test" : test.canStartExam && test.remainingDays <= 0 && test.canRetakeTest ? "Retake Test" : "Start Test"}
                                </Button>
                              </div>
                              <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <span className="modal-title instruction fw-bold " id="staticBackdropLabel">
                                        Instructions
                                      </span>
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                      <ol style={{ listStyleType: "decimal" }}>
                                        <li> It is important to adhere to the given time limit and complete all the required tasks within the period</li>
                                        <li> To receive a star as a recognition of your performance, you need to meet a requirement of achieving a score of 80% or higher.</li>
                                        <li>If you do not achieve a minimum score of 80%, you will be required to take the test again after a period of 2 days.</li>
                                      </ol>
                                    </div>
                                    <div className="modal-footer">
                                      <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                                        Cancel
                                      </button>
                                      <button type="button" onClick={() => clickCont()} data-bs-dismiss="modal" className="btn btn-primary continuebtn">
                                        Continue
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TakeTest;
