import "../ManageSkillSet/manageSkillSet.css";
import "../ManageSkillSet/Modals.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState, useNavigate, axios, Link, Skeleton, ArrowForwardIcon, Add, AddNewSkillTest, decryptData,handleApiError } from "../../AdminImport";

const ManageSkillSet = (prop) => {
  const { selectedBatches } = prop;
  const [isLoading, setIsLoading] = useState(false);
  const [TestTakenData, setTestTakenData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTestData();
  }, [selectedBatches]);

  const fetchTestData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/totalTestTaken`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(url, body, { headers });
      setTestTakenData(response.data.result);
      setIsLoading(false);
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  return (
    <>
      <div className="about">
        <Link to="/admin/sde/skill-test" className="about-link">
          Manage Skill Set <ArrowForwardIcon />
        </Link>
        <div className="about-div-total-skill">Total Test Taken</div>
      </div>
      <div className="row addNew-manage ">
        <div className="add-new-skill-test col-5" data-bs-toggle="modal" data-bs-target="#newSkillModal">
          <Add /> &nbsp; Add New Skill Test
        </div>

        <div className="beg-int-advance col-7 d-flex ">
          {isLoading ? (
            <Skeleton width={633.6} height={59} highlightColor="#fff" style={{ marginBottom: "0.3rem" }} />
          ) : (
            <>
              <div className="col-4 insideContent-totalTest">
                <div className="row inside-manage-skills d-flex align-items-center justify-content-center">
                  <div className="col-2 inside-manage-skills-number">{TestTakenData?.beginner}</div>
                  <div className="col-10 inside-manage-skills-text">Beginner</div>
                </div>
              </div>
              <div style={{ width: 0, height: "1.375rem", border: "1px solid black" }}></div>
              <div className="col-4 insideContent-totalTest">
                <div className="row inside-manage-skills d-flex align-items-center justify-content-center">
                  <div className="col-2 inside-manage-skills-number">{TestTakenData?.intermediate}</div>
                  <div className="col-10 inside-manage-skills-text">Intermediate</div>
                </div>
              </div>
              <div style={{ width: 0, height: "1.375rem", border: "1px solid black", marginLeft: "0.5rem" }}></div>
              <div className="col-4 insideContent-totalTest">
                <div className="row inside-manage-skills d-flex align-items-center justify-content-center">
                  <div className="col-2 inside-manage-skills-number px-0">{TestTakenData?.advanced}</div>
                  <div className="col-10 inside-manage-skills-text px-0 ms-0">Advanced</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <AddNewSkillTest fetchExamList={fetchTestData} />
    </>
  );
};
export default ManageSkillSet;
