import { useEffect, axios, decryptData, useNavigate, useState, SearchIcon, handleApiError } from '../../AdminImport';
import UserActionType from "../../../_common/UserActionType";

const UsersDropdown = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { dispatch, selectedUserName, selectAllUsersCheck, selectedBatches, usersDataComingChild, selectedUserIds, filterSdeOnly, selectedUsers, selectedUserIdsfromChild = defaultUserIdHandler } = props;
  const navigate = useNavigate();
  function defaultUserIdHandler(techIdData) {
  }

  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAllUserByRole`; 
    const body ={
      roleName : process.env.REACT_APP_USER_DES_USER,
      batchId : selectedBatches
    }   
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          designation: user?.designation
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });
        setAllUsers(sortedUsers.filter((user) => {return (user.designation == "SDE" || user.designation == "Intern")})) 
        setFilteredUsers(sortedUsers.filter((user) => {return (user.designation == "SDE" || user.designation == "Intern")}))
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  }

  useEffect(() => {
    fetchUserList();
  }, [selectedBatches]);

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allUser = filteredUsers.map((c) => c.name);
      dispatch({
        type: UserActionType.SET_SELECTED_USERS_NAME,
        payload: allUser,
      });
      const allUserId = filteredUsers.map((c) => c.id);
      dispatch({
        type: UserActionType.SET_SELECTED_USER_IDS,
        payload: allUserId,
      });
    } else {
      dispatch({
        type: UserActionType.SET_SELECTED_USERS_NAME,
        payload: [],
      });
      dispatch({
        type: UserActionType.SET_SELECTED_USER_IDS,
        payload: [],
      });
    }
  };

  const handleUsersChange = (e, c) => {
    if (Array.isArray(selectedUserName)) {
      if (e.target.checked) {
        dispatch({
          type: UserActionType.SET_SELECTED_USERS_NAME,
          payload: [...selectedUserName, c.name],
        });
        dispatch({
          type: UserActionType.SET_SELECTED_USER_IDS,
          payload: [...selectedUserIds, c.id],
        });
      } else if (!e.target.checked) {
        dispatch({
          type: UserActionType.SET_SELECTED_USERS_NAME,
          payload: selectedUserName.filter((item) => item !== c.name),
        });
        dispatch({
          type: UserActionType.SET_SELECTED_USER_IDS,
          payload: selectedUserIds.filter((item) => item !== c.id),
        });
      }
    }
  };

  useEffect(() => {
    if (selectedUsers) {
      const selecteduserName = selectedUsers.map((x) => x);
      usersDataComingChild(selecteduserName);

      const selectedUserIds = filteredUsers.map((user) => {
        const selectedUserItem = selectedUsers.find((selectedUserItem) => {
          return selectedUserItem === user.name
        });
        return selectedUserItem ? user.id : null;
      }).filter((id) => id !== null);

      selectedUserIdsfromChild(selectedUserIds)

    }
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedUserName) {
      const selecteduserName = selectedUserName?.map((x) => x);
      usersDataComingChild(selecteduserName);
    }
    if (selectedUserIds) {
      const selectedUserId = selectedUserIds?.map(id => id);
      selectedUserIdsfromChild(selectedUserId)
    }
  }, [selectAllUsersCheck]);

  const handleSearchChange = (e) => {
    const filtered = allUsers.filter((user) =>
      user.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
 
  return (
    <>
      <div
        className="d-flex align-items-center ps-1 associate-search-log-wrapper mb-2"
        style={{ width: "95%" }}
      >
        <SearchIcon />
        <input
          className="search-dropdowns"
          type="text"
          placeholder="Search users..."
          onChange={handleSearchChange}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
        {filteredUsers?.length != 0 && <div className="form-check small checkbox gap-2">
          <input
            className="form-check-input user-checkbox"
            type="checkbox"
            id="userDropDownselectAll"
            checked={selectedUserIds?.length=== filteredUsers?.length}
            onChange={handleCheckAllChange}
          />
          <label className="form-check-label" htmlFor="userDropDownselectAll">
            Select all
          </label>
        </div>}
        
        <div style={{ maxHeight: "5rem", overflowY: "scroll",paddingLeft:"5px", width: "100%" }} 
        className="dp-scroll">
        {filteredUsers && filteredUsers.map((c) => (
          <div className="form-check small checkbox gap-2" key={c.id}>
            <input
              className="form-check-input user-checkbox"
              type="checkbox"
              id={c.id + c.name}
              onChange={(e) => handleUsersChange(e, c)}
              checked={selectedUserIds?.includes(c.id) ||selectedUserName?.includes(c.name) }
            />
            <label className="form-check-label d-block pointer"
              htmlFor={c.id + c.name}
            >
              <span>{c.name}</span>
            </label>
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default UsersDropdown;
