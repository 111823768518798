import { React, Skeleton } from "./../../../../UploadCourses/UploadCoursesImport";

const LoadingSkeleton = () => {
  const rows = Array.from({ length: 2 });

  return (
    <>
      <div className="mt-2" style={{ height: "12rem" }}>
        <div id="carouselExampleIndicators" className="carousel slide course-report-badge-caro-slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {rows.map((_, rowIndex) => (
              <div key={rowIndex} className={`carousel-item ${rowIndex === 0 ? "active" : ""}`}>
                <div className="d-flex flex-wrap justify-content-between">
                  {Array.from({ length: 2 }).map((_, colIndex) => (
                    <div key={colIndex} className="d-flex align-items-center fw-600 course-report-badge-milestone-card">
                      <div>
                        <Skeleton height={35} width={35} />
                      </div>
                      <div>
                        <Skeleton height={24} width={40} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingSkeleton;
