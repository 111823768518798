import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CertificationAchievedFileSkeleton = () => {
  return (
    <div className="d-flex align-items-center fw-600 milestone-card">
      <div className="milestone-image">
        <Skeleton circle height={35} width={33} />
      </div>
      <div className="d-flex milestone-name-count">
        <p className="m-name mb-0"><Skeleton height={26}/></p>
        <p className="m-count mb-0"><Skeleton width={30}height={26}/></p>
      </div>
    </div>
  );
};

export default CertificationAchievedFileSkeleton;
