import "./MentorReviewTable.css";
import { useState, useNavigate, decryptData, GoldStarOri, SilverStarOri, BronzeStarOri, ReviewCompleted, ReviewWarning, ReviewFlag, EmptyMentorReview } from "../MentorImport";

const MentorReviewTable = (props) => {
  const { tableData, sortDirection, sortColumnName } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleOnclick = (index) => {
    sessionStorage.setItem("reviewId", sortedTableData[index].reviewId);
    navigate(`/mentor/review-associates/inter-performance-review`);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const sortedTableData = [...tableData].sort((a, b) => {
    if (sortColumnName === "Name") {
      const nameA = a.firstName.toUpperCase();
      const nameB = b.firstName.toUpperCase();
      return sortDirection === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    } else if (sortColumnName === "Int ID") {
      const idA = parseInt(a.cgiId.replace(/\D/g, ""), 10); // Extract numeric part
      const idB = parseInt(b.cgiId.replace(/\D/g, ""), 10);
      return sortDirection === "asc" ? idA - idB : idB - idA;
    }
    return 0;
  });
  return (
    <tbody>
      {sortedTableData == null || sortedTableData.length === 0 ? (
        <tr className="empty-review-associates-table">
          <td colSpan="6" className="text-center">
            <div className="d-flex justify-content-center align-items-center flex-column p-4">
              <EmptyMentorReview />
              <p className="fw-bold mt-3 mb-1 empty-task-text">You do not have any users to review!</p>
            </div>
          </td>
        </tr>
      ) : (
        sortedTableData.map((val, ind) => {
          let objectKeyCount = 0;
          return (
            <tr className="report-table-tr" key={ind}
              onClick={() => {
                handleOnclick(ind);
              }}
            >
              <td>
                <div className="review-name-column">
                  <div className="circle">
                    {val.firstName?.toUpperCase().slice(0, 1)}
                    {val.lastName?.toUpperCase().slice(0, 1)}
                  </div>
                  <div className="review-tags">
                    <div className="review-tag1">{`${val.firstName} ${val.lastName === null ? "" : val.lastName}`}</div>
                  </div>
                </div>
              </td>
              <td>
                <div className="review-int">{val.cgiId} </div>
              </td>
              <td>
                <div className="review-tech-tags" onMouseLeave={() => setSelectedIndex(-1)}>
                  {val?.techNames?.slice(0, selectedIndex === ind ? val?.techNames?.length : 3).map((value, index) => {
                    objectKeyCount++;
                    return value === null ? (
                      <div key={index}></div>
                    ) : (
                      <div key={index} className="review-tag-tech d-flex justify-content-center align-items-center">
                        <span>{value}</span>
                        <div>{val.level[index] === "Beginner" ? <BronzeStarOri /> : val.level[index] === "Intermediate" ? <SilverStarOri /> : <GoldStarOri />}</div>
                      </div>
                    );
                  })}
                  {selectedIndex !== ind && objectKeyCount > 2 && val?.techNames.slice(3).length !== 0 && (
                    <div className="review-all-tech" onMouseEnter={() => setSelectedIndex(ind)}>
                      <span>+ {val?.techNames.slice(3).length}</span>
                    </div>
                  )}
                  {val.techNames.length == 0 && "NA"}
                </div>
              </td>
              <td>
                <div className="review-status">
                  <div style={{ width: "102px" }}>
                    {val.status == "Completed" ? (
                      <>
                        <div className="review-status-card">
                          <ReviewCompleted />
                          Completed
                        </div>
                        <div className="review-status-date">{formatDate(val.reviewSubmittedDate)}</div>
                      </>
                    ) : val.status == "Pending" ? (
                      <>
                        <div className="review-status-card-pending">
                          <ReviewFlag />
                          Pending
                        </div>
                        <div className="review-status-date">{formatDate(val.reviewEndDate)}</div>
                      </>
                    ) : (
                      <>
                        <div className="review-status-card-overdue">
                          <ReviewWarning />
                          Overdue
                        </div>
                        <div className="review-status-date">{formatDate(val.reviewEndDate)}</div>
                      </>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          );
        })
      )}
    </tbody >
  )
}

export default MentorReviewTable;
