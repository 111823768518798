import { useState, useEffect, useNavigate, decryptData, axios, handleApiError, Skeleton } from "../../UserImport";
import "./PopularTags.css";

const PopularTags = () => {
  const [popularTagsData, setPopularTagsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getPopularTags = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/getTopFiveCategories`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setPopularTagsData(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    getPopularTags();
  }, []);

  return (
    popularTagsData && popularTagsData.length !== 0 && (<div className="d-grid gap-3 px-4 lms-popular-tags-container">
      <div className="d-flex justify-content-center align-items-center lms-popular-tags-heading">Popular Tags</div>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-3 justify-content-center">
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => <div><Skeleton key={index} highlightColor="#002C3F" width={150} height={43}/></div>)
            : popularTagsData?.slice(0, 3)?.map((tags) => <div className="lms-popular-tags-badge">{tags.name}</div>)}
        </div>
        <div className="d-flex gap-3 justify-content-center">
        {isLoading
            ? Array.from({ length: 2 }).map((_, index) => <div ><Skeleton key={index} highlightColor="#002C3F" width={150} height={43}/></div>)
            : popularTagsData?.slice(3, 5)?.map((tags) => (
              <div className="lms-popular-tags-badge">{tags.name}</div>
            ))}
        </div>
      </div>
    </div>)
  );
};

export default PopularTags;
