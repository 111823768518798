import React from 'react';
import { Skeleton} from "../../UploadCourses/UploadCoursesImport"
import "react-loading-skeleton/dist/skeleton.css";

const TechTalkSkeltonLoading = ({ rowSize }) => {
    const rows = [];
    for (let i = 0; i < rowSize; i++) {
        rows.push(
            <tr key={i}>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={100} height={20} />
                </td>
                <td>
                    <Skeleton width={30} height={30} />
                </td>
                <td>
                    <Skeleton width={30} height={30} />
                </td>
            </tr>
        );
    }

    return <React.Fragment>{rows}</React.Fragment>;
};

export default TechTalkSkeltonLoading;
