import { Header, SideNav, useState, useEffect, BreadCrumbs, Select, components, decryptData, axios, useNavigate, handleApiError, FormatDate, Revoke, RevokeArrow, CustomModal, ProgressBar, EnrolledUserSkeleton, SuccessfullVector, DeleteWarning, EnrolledUserEmpty } from "../../../UploadCourses/UploadCoursesImport";
import "./EnrolledUser.css";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "12rem",
    marginBottom: "0.7rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "10.625rem",
    overflowY: "auto",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const additionalOptions = [
  { label: "Enrolled", value: "Enrolled" },
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
  { label: "Overdue", value: "Overdue" },
];

const EnrolledUser = () => {
  const [dataState, setDataState] = useState({
    data: [],
    selectedFilterOptions: [],
    originalData: [],
    isLoading: true,
  });

  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    resultsPerPage: 10,
  });

  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
    userId: 0,
    modalState: ""
  });

  const storedCourseId = sessionStorage.getItem("cId");
  const storedCourseName = sessionStorage.getItem("cName");

  const navigate = useNavigate();

  const CheckboxOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
        <input type="checkbox" checked={isSelected} />
        &nbsp;
        {children}
      </components.Option>
    );
  };

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchEnrolledUserList = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getEnrolledUsersByCourseId?courseId=${storedCourseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        let filteredData = response.data.result;
        setDataState((prevData) => ({
          ...prevData,
          data: filteredData,
          originalData: filteredData,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const handleCancel = () => {
    setIsOpen((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
      userId: 0,
    }));
    if (isOpen.modalState === "success") {
      if (arrayCurrentResults?.length === 0) {
        navigate("/admin/lms/total-uploaded-courses");
      }
      setIsOpen((prevData) => ({
        ...prevData,
        modalState: "",
      }));
    }
  };

  const handleConfirm = async (e) => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/manageEnrollment`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      courseId: storedCourseId,
      userId: [isOpen.userId],
      assignedById: parsedObject["decoded"]["Id"],
      isAssigned: false,
      courseName: storedCourseName
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        handleCancel();
        setIsOpen((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: "User Revoked",
          modalMessage: "User Revoked Successfully",
          icon: <SuccessfullVector />,
          modalState: "success"
        }));
        setDataState((prevData) => ({
          ...prevData,
          data: prevData.data.filter((obj) => obj.userId != response.data.result[0].id),
          originalData: prevData.data.filter((obj) => obj.userId != response.data.result[0].id),
        }));
      } else {
        setIsOpen((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "User Not Revoked",
          modalMessage: "Error Occured While Revoking User",
          icon: <DeleteWarning />,
        }));
        handleCancel();
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleFilters = () => {
    setDataState((prevData) => {
      const { originalData, selectedFilterOptions } = prevData;

      if (selectedFilterOptions.length === 0) {
        return {
          ...prevData,
          data: originalData,
        };
      } else {
        const filterItems = originalData.filter((val) => selectedFilterOptions.includes(val.courseStatusName));
        return {
          ...prevData,
          data: filterItems,
        };
      }
    });
  };

  const handleFilterChange = (selectedOptions) => {
    setDataState((prevData) => ({
      ...prevData,
      selectedFilterOptions: selectedOptions?.map((option) => option.value),
    }));
  };

  const handleclickfunc = (userId) => {
    setIsOpen((prevData) => ({
      ...prevData,
      isModalOpen: true,
      modalType: "confirm",
      modalTitle: "Revoke",
      modalMessage: "Are you sure you want to revoke this user? The user will be revoked from the enrolled list",
      icon: <RevokeArrow />,
      userId: userId,
    }));
  };

  const totalPaginationPages = Math.ceil(dataState.data?.length / paginationState.resultsPerPage);
  const arrayStartIndex = (paginationState.currentPage - 1) * paginationState.resultsPerPage;
  const arrayEndIndex = arrayStartIndex + paginationState.resultsPerPage;
  const arrayCurrentResults = dataState.data?.slice(arrayStartIndex, arrayEndIndex);

  useEffect(() => {
    fetchEnrolledUserList();
  }, [additionalOptions]);

  useEffect(() => {
    handleFilters();
  }, [dataState.selectedFilterOptions]);

  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container-fluid enrolled-user-table-container d-flex flex-column">
        <div style={{ marginLeft: "1rem" }}>
          <div className="row ">
            <div className="col-12">
              <div className="enrolled-user-course-nav-bar">
                <BreadCrumbs />
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="enrolled-user-courses-heading">
                <p>Enrolled Users</p>
              </div>
              <div className="d-flex">
                <p className="filter-head">Course Progress</p>
                <div className="enrolled-user-courses-inner-filter">
                  <Select
                    styles={customStyles}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option: ({ children, ...innerProps }) => <CheckboxOption {...innerProps}>{children}</CheckboxOption>,
                    }}
                    options={additionalOptions}
                    menuShouldBlockScroll
                    placeholder="Select Progress..."
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <table className="table table-striped-custom">
            <thead>
              <tr>
                <th className="col enroll-user-table-head revoke-name-col">Name</th>
                <th className="col enroll-user-table-head revoke-progress-col">Course Progress</th>
                <th className="col enroll-user-table-head revoke-start-col text-center">Start Date</th>
                <th className="col enroll-user-table-head revoke-end-col text-center">End Date</th>
                <th className="col enroll-user-table-head revoke-action-col text-center">Revoke</th>
              </tr>
            </thead>
            <tbody>
              {dataState.isLoading ? (
                <>
                  <EnrolledUserSkeleton />
                  <EnrolledUserSkeleton />
                  <EnrolledUserSkeleton />
                  <EnrolledUserSkeleton />
                </>
              ) : arrayCurrentResults.length === 0 ? (
                <>
                  <tr>
                    <td></td>
                    <td colSpan="6" className=" d-flex align-items-center justify-content-center">
                      <div className="enrolled-empty-state">
                        <EnrolledUserEmpty />
                        <div className="enrolled-empty-heading">No user has been enrolled yet</div>
                        <div className="enrolled-empty">We’ll notify you when new user arrives.</div>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </>
              ) : (
                arrayCurrentResults.map((val, ind) => (
                  <tr className={`report-table-tr ${ind % 2 === 0 ? "enrolled-striped-even-rows" : "enrolled-striped-odd-rows"}`} key={ind}>
                    <td className="bg-transparent" style={{ verticalAlign: "middle", height: "40px" }}>
                      <div className="name-column">
                        <div className="revoke-tag justify-content-center">
                          <div
                            className="revoke-tag1"
                            onClick={(e) => {
                              e.preventDefault();
                              sessionStorage.setItem("uId", val.userId);
                              navigate("/admin/lms/course-report");
                            }}
                          >
                            {val?.firstName || ""} {val?.lastName || ""}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className={`tag2 tag2-adjustment progress-height bg-transparent`}>
                      <div>
                        <div>
                          <ProgressBar completedCount={val.completedModuleCount} totalCount={val.totalModuleCount} courseStatus={val.courseStatusName} />
                        </div>
                      </div>
                    </td>
                    <td className={`tag2 text-center tag2-adjustment td-height bg-transparent`}>{FormatDate(val.courseStartDate)}</td>
                    <td className={`tag2 text-center tag2-adjustment td-height bg-transparent`}>{FormatDate(val.courseEndDate)}</td>
                    <td className={`tag2 text-center tag2-adjustment td-height bg-transparent`}>
                      <button className="btn p-0 m-0 border-0" type="button" disabled={!(val.courseStatusName === "Enrolled" || val.courseStatusName === "Completed" || val.courseStatusName === "Overdue")}>
                        <Revoke
                          className={`${val.courseStatusName === "Enrolled" || val.courseStatusName === "Completed" || val.courseStatusName === "Overdue" ? "" : "revoke-user-image"}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleclickfunc(val.userId);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="fw-bold" style={{ fontSize: "14px" }} colSpan={2}>
                  Total Items: {dataState.data?.length}
                </td>
                <td colSpan={6}>
                  <div className="d-flex justify-content-end t-items">
                    <div className="d-flex align-items-center">
                      <p className="me-2 mb-0 res-per-page">Results per page</p>
                      <select
                        className="form-select m-2 r-page"
                        value={paginationState.resultsPerPage}
                        onChange={(event) => {
                          setPaginationState((prevState) => ({
                            ...prevState,
                            resultsPerPage: event.target.value,
                            currentPage: 1,
                          }));
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                      <ul className="pagination mb-0">
                        <li className={`page-item me-1 ${paginationState.currentPage === 1 ? "page-item disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={() => {
                              setPaginationState((prevState) => {
                                if (prevState.currentPage !== 1) {
                                  return {
                                    ...prevState,
                                    currentPage: prevState.currentPage - 1,
                                  };
                                }
                                return prevState;
                              });
                              window.scrollTo(0, 0);
                            }}
                          >
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 1.5L1.5 7L7 12.5" stroke={paginationState.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                        {Array.from({ length: totalPaginationPages }, (_, i) => i + 1)?.map((page) => (
                          <li key={page} className={`page-item me-1 ${page === paginationState.currentPage ? "active" : ""}`}>
                            <button
                              className="page-link rounded pagination-styling fw-bold"
                              onClick={() => {
                                setPaginationState((prevState) => ({
                                  ...prevState,
                                  currentPage: page,
                                }));
                                window.scrollTo(0, 0);
                              }}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                        <li className={`page-item ${paginationState.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={() => {
                              setPaginationState((prevState) => {
                                if (prevState.currentPage !== prevState.totalPaginationPages) {
                                  return {
                                    ...prevState,
                                    currentPage: prevState.currentPage + 1,
                                  };
                                }
                                return prevState;
                              });
                              window.scrollTo(0, 0);
                            }}
                          >
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 12.5L6.5 7L1 1.5" stroke={paginationState.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {isOpen.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: isOpen.icon,
            show: isOpen.isModalOpen,
            type: isOpen.modalType,
            title: isOpen.modalTitle,
            message: isOpen.modalMessage,
            cancelText: "No, Cancel",
            confirmText: "Yes, Revoke",
            onCancel: handleCancel,
            onConfirm: handleConfirm,
          }}
        />
      )}
    </>
  );
};

export default EnrolledUser;
