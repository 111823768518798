import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserCourseDetailsSkeleton = () => {
  return (
    <tr>
      <td className={`user-course-details-title-col-data`}>
        <Skeleton />
      </td>

      <td className={`user-course-details-cat-col-data`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-subcat-col-data`}>
        <Skeleton />
      </td>

      <td className={`user-course-details-progress-col-data`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-start-col-data text-center`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-end-col-data text-center`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-duration-col-data text-center`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-instructor-col-data`}>
        <Skeleton />
      </td>
      <td className={`user-course-details-grade-col-data`}>
        <Skeleton />
      </td>
    </tr>
  );
};

export default UserCourseDetailsSkeleton;
