import { useState, useRef, useNavigate, Modal, Button, axios, BatchSelect, decryptData, Form, Col, CloudImage, CloseBtn, CSVIcon, ExpandMore, Check, ArrowForwardIcon, SessionDetails, DownloadIcon, InfinitySpin } from "../AdminPortal/AdminImport";

export const UploadSessionModal = ({ fetchedCal }) => {
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [sessionCalendar, setSessionCalendar] = useState({
    file: null,
    batches: [],
    selectAllChecked: false,
    batchDropdown: false,
    sessionNullCount: 0,
    sessionNotNullCount: 0,
    sessionDetailsData: [],
  });

  const [modalstate, setModalState] = useState({
    modal: false,
    detailModal: false,
    showBrowse: true,
    loaderState: false,
  });

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const batchDataComingFrmChild = (data) => {
    setSessionCalendar((prevState) => ({
      ...prevState,
      batches: data,
    }));
  };

  function calculateNullAndNonNullCount(sessionArray) {
    let nullCount = 0;
    let nonNullSessionIdCount = 0;
    sessionArray.forEach((session) => {
      if (session.sessionId === null) {
        nullCount++;
      }
      if (session.sessionId !== null) {
        nonNullSessionIdCount++;
      }
    });

    setSessionCalendar((prevState) => ({
      ...prevState,
      sessionNullCount: nullCount,
      sessionNotNullCount: nonNullSessionIdCount,
    }));
  }

  const handleCancelClick = () => {
    setSelectedBatches([]);
    setSessionCalendar(() => ({
      file: null,
      batches: [],
      selectAllChecked: false,
      batchDropdown: false,
    }));
    handleRemoveFile();
    sessionStorage.setItem("Batches","[]");
  };

  const handleSaveClick = async (e) => {
    setModalState((prevData) => ({
      ...prevData,
      loaderState: true,
    }));
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    var formdata = new FormData();

    formdata.append("File", fileInputRef.current.files[0]);
    formdata.append("BatchIds", selectedBatches);
    formdata.append("IsAllSelected", sessionCalendar.selectAllChecked);
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/SessionCalendar/postSessions`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.post(url, formdata, { headers });
      if (response.data.success === 206 || response.data.success === 200 || response.data.success === 204) {
        calculateNullAndNonNullCount(response.data.result);
        setSessionCalendar((prevData) => ({
          ...prevData,
          sessionDetailsData: response.data.result,
        }));
        setModalState((prevData) => ({
          ...prevData,
          showBrowse: false,
          loaderState: false,
        }));
        fetchedCal();
        setSelectedBatches([]);
        sessionStorage.setItem("Batches","[]")
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFileChange(droppedFile);
  };

  const handleFileChange = (file) => {
    setSessionCalendar((prevState) => ({
      ...prevState,
      file: file.name,
    }));
    if (!file) {
      alert("No file selected.");
      return;
    }
  };

  const handleRemoveFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setModalState((prevData) => ({
      ...prevData,
      showBrowse: true,
    }));
    setSessionCalendar((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  const handleClose = () => {
    setModalState((prevData) => ({
      ...prevData,
      modal: false,
    }));
    setSessionCalendar(() => ({
      file: null,
      batches: [],
      selectAllChecked: false,
      batchDropdown: false,
    }));
    handleRemoveFile();
  };

  const handleDetailClose = () => {
    setModalState((prevData) => ({
      ...prevData,
      detailModal: false,
    }));
  };

  return (
    <div>
      <div className="modal fade" id="UploadSessionCsvModal" tabIndex="-1" aria-labelledby="uploadCsvLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content ">
            <div className="modal-header border-bottom-1">
              <h5 className="modal-title modalheading-text" id="uploadCsvLabel">
                Upload CSV
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancelClick}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className={`fw-bold ${sessionCalendar.selectAllChecked ? "d-none" : ""}`}>Batch</div>
                <div className={`container border p-0 ${sessionCalendar.selectAllChecked ? "d-none" : ""}`}>
                  <div
                    className="input-with-button"
                    onClick={() => {
                      setSessionCalendar((prevState) => ({
                        ...prevState,
                        batchDropdown: !prevState.batchDropdown,
                      }));
                    }}
                  >
                    <input type="text" className="session-modal-custom-input" placeholder="Select Batch" value={Object.values(sessionCalendar.batches)} disabled />
                    <div className="modal-abs"></div>
                    <ExpandMore />
                  </div>
                  {sessionCalendar.batchDropdown && (
                    <div className="ul-styling p-2">
                      <BatchSelect batchDataComingChild={batchDataComingFrmChild} setSelectedBatches={setSelectedBatches} selectedBatches={selectedBatches} allSelected={false} />
                    </div>
                  )}
                </div>
                <div className="usmc">
                  <div className="batch-head">Select All Batch</div>
                  <Col md={2}>
                    <Form.Check
                      type="switch"
                      checked={sessionCalendar.selectAllChecked}
                      onChange={() =>
                        setSessionCalendar((prevState) => ({
                          ...prevState,
                          selectAllChecked: !prevState.selectAllChecked,
                        }))
                      }
                      className="custom-switch-container"
                    />
                  </Col>
                </div>
                <div className="information-text mb-0">
                  To add a session calendar, please upload the CSV file.
                </div>
                <div className="d-flex justify-content-between sample-csv mt-4">
                  <span>Download Sample CSV Template</span>
                  <a href={process.env.REACT_APP_SAMPLE_CALENDAR} className="fw-bold"><DownloadIcon /> Session Calendar</a>
                </div>
                {modalstate.showBrowse && (
                  <div
                    className="upload-csv-text align-items-center justify-content-center"
                    onDrop={handleDrop}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className=" mt-4">
                      <CloudImage />
                    </div>
                    <div className="mb-3">
                      <span className="drag-drop-text d-flex fw-bold">
                        Drag and drop here <br /> or click to select a file from your computer
                      </span>
                    </div>
                    <div className="mb-4">
                      <div>
                        <div>
                          <input type="file" ref={fileInputRef} onChange={(e) => handleFileChange(e.target.files[0])} accept=".csv" className="d-none" />
                          <button
                            type="button"
                            className="csv-upload-button"
                            onClick={() => {
                              handleBrowseClick();
                            }}
                          >
                            Browse from your computer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {sessionCalendar.file && (
                  <div>
                    <div className="d-flex align-items-center ps-1 ast-search-wrapper">
                      <div className="progress-indicator-status">
                        <div className="d-flex align-items-center">
                          <CSVIcon />
                          <div className="ms-1">{sessionCalendar.file}</div>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          handleRemoveFile();
                        }}
                      >
                        <CloseBtn />
                      </div>
                    </div>
                  </div>
                )}
              </form>
              {modalstate.loaderState && <div className="d-flex align-items-center justify-content-center infinity"><InfinitySpin color="#ffc107" /></div>}
              {!modalstate.showBrowse && (
                <>
                  <div>
                    <div className="success-block">
                      <div className="d-flex align-item-center ms-1 count-modal">{sessionCalendar.sessionNotNullCount} records uploaded successfully</div>
                    </div>
                    <div className="error-block">
                      <div className="d-flex ms-1 align-item-center count-modal">{sessionCalendar.sessionNullCount} record contains error</div>
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    onClick={() =>
                      setModalState((prevData) => ({
                        ...prevData,
                        detailModal: true,
                      }))
                    }
                  >
                    <p className="show-details mt-2 ms-1">Show Details </p>
                    <ArrowForwardIcon className="ms-1" style={{ marginTop: "0.6rem" }} />
                  </div>
                </>
              )}

              <div className="saveCancel border-top-0 pb-0 row ">
                {modalstate.showBrowse ? (
                  <div className="row mt-3 d-flex justify-content-end">
                    <button data-bs-dismiss="modal" className="btn upload-csv-cancel-btn" onClick={handleCancelClick}>
                      Cancel
                    </button>
                    <button onClick={(e) => handleSaveClick(e)} disabled={!sessionCalendar.file || (sessionCalendar.batches?.length === 0 && !sessionCalendar.selectAllChecked) || modalstate.loaderState} className="btn upload-csv-save-btn ms-2">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row mt-3 d-flex justify-content-end">
                    <button onClick={handleCancelClick} disabled={!sessionCalendar.file} className="btn upload-csv-save-btn ms-2">
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modalstate.modal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="border-0"></Modal.Header>

        <Modal.Body className="d-flex justify-content-center flex-column align-items-center">
          <Check />
          <Modal.Title id="example-modal-sizes-title-lg" className="text-success fw-bold py-4">
            Session Created Successfully
          </Modal.Title>
          <Button variant="success" onClick={handleClose}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
      <SessionDetails detailModal={modalstate.detailModal} handleClose={handleDetailClose} sessionDetailsData={sessionCalendar.sessionDetailsData} />
    </div>
  );
};
