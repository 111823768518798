import { useState, Header, CertificatesLists, UsersWithCertificates, SideNav ,BreadCrumbs} from "../AdminImport";
import "../CertificationsAchieved/CertificationsAchieved.css";

const CertificationsAchieved = () => {
  const [certificateData, setCertificateData] = useState([]);
  const [certificateDataLoading, setCertificateDataLoading] = useState(true);
  return (
    <>
      <div style={{ marginBottom: "3.7rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="certification-page">
        <div className="container-fluid">
          <div className="row mt-4 bg-light">
            <div className="col-12">
              <div className="ms-5 mt-2 mb-2">
                <BreadCrumbs />
              </div>
            </div>
            <div className=" col-4 p-0 ms-5">
              <CertificatesLists setCertificateData={setCertificateData} setCertificateDataLoading={setCertificateDataLoading}/>
            </div>
            <div className=" col-7 p-0 certificate-details">
              <UsersWithCertificates certificateData={certificateData} certificateDataLoading={certificateDataLoading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificationsAchieved;
