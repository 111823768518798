import React, { Fragment } from "react";
import { Select, components, useState, useEffect, axios, useNavigate, CustomModal, BluePlus, handleApiError, decryptData, DeleteWarning, SuccessfullVector, InfoVector } from "../AdminImport";
const CategoryMenu = (props) => {
  const { isSubCategory, categoryFilter, hideButtoms } = props;
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
          {(!hideButtoms && (isSubCategory || categoryFilter === null)) && (
            <button type="button" className="btn cat-subcat-btn ps-1" data-bs-toggle="modal" data-bs-target={isSubCategory ? "#subCategoryModal" : "#categoryModal"}>
              <BluePlus />
              <span className="add-csv-text mx-1">{isSubCategory ? "Add New Sub-Category" : "Add New Category"}</span>
            </button>
          )}
        </div>
      </components.Menu>
    </Fragment>
  );
};
const CategoryDropdown = (props) => {
  const { selectedCategory, setSelectedCategory, categoryLabel, subCategoryLabel, categoryEnabled, subCategoryEnabled, categoryFilter, hideButtoms } = props;
  const [categories, setCategories] = useState({
    categories: [],
    subCategories: [],
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [newCategory, setNewCategory] = useState({
    newCategoryName: "",
    categoryId: "",
    newSubCategoryName: "",
    selectedCategoryModal: null,
    categoryError: "",
    subCategoryError: ""
  });

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchCategories = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/getAllCategories`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const categoriesResponse = await axios.get(url, { headers });
      if (categoriesResponse.data.success == 200) {
        const categories = categoriesResponse.data.result;
        setCategories((prevState) => ({
          ...prevState,
          categories: categories,
          subCategories: [],
        }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    setNewCategory((prevData) => ({
      ...prevData,
      categoryId: selectedCategory?.category?.id
    }))
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.category && selectedCategory.category.id) {
      if (selectedCategory.category.value.toLowerCase() == "tech talk") {
        let categoryId =categories?.categories?.filter(x => x.name == selectedCategory.category.id)[0]?.id || selectedCategory.category.id
        fetchSubCategories(categoryId)
      } else {
        fetchSubCategories(selectedCategory.category.id);
      }
    }
  }, [selectedCategory?.category?.id]);


  const fetchSubCategories = async (categoryId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/getAllSubCategories?categoryId=${categoryId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const subCategoriesResponse = await axios.get(url, { headers });
      if (subCategoriesResponse.data.success === 200) {
        const subCategories = subCategoriesResponse.data.result;
        setCategories((prevState) => ({
          ...prevState,
          subCategories: subCategories,
        }));
        return subCategories;
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleCategoryChange = async (selectedOption) => {
    setNewCategory((prevData) => ({
      ...prevData,
      selectedCategoryModal: selectedOption,
      categoryId: selectedOption?.id
    }))
    setSelectedCategory((prevData) => ({
      ...prevData,
      category: selectedOption,
      subCategory: null,
    }));
    if (selectedOption) {
      await fetchSubCategories(selectedOption.id);
    } else {
      setCategories((prevState) => ({
        ...prevState,
        subCategories: [],
      }));
    }
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSelectedCategory((prevData) => ({
      ...prevData,
      subCategory: selectedOption,
    }));
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
    }),

    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "auto",
      overflow: "auto",
    }),
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      maxHeight: "8rem",
      "::-webkit-scrollbar": {
        width: "0.5rem",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#d9d9d9",
        border: "1px solid rgba(255, 255, 255, 0.1475)",
        borderRadius: "0.875rem",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#bfbfbf",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightGrey" : "white",
      color: state.isSelected ? "black" : "black",
    }),
  };

  const resetNewCategory = () => {
    setIsSubCategory(false);
    setNewCategory((prevState) => ({
      ...prevState,
      newCategoryName: "",
      newSubCategoryName: "",
    }))
  };
  const handleSaveButtonClick = async () => {
    if (isSubCategory) {
      try {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/addSubCategory`;
        const headers = {
          Authorization: `Bearer ${parsedObject["token"]}`,
        };
        const requestBody = {
          id: newCategory?.categoryId,
          name: newCategory.newSubCategoryName,
        };

        const response = await axios.post(url, requestBody, { headers });
        if (response.data.success === 201) {
          setCategories((prevState) => ({
            ...prevState,
            isModalOpen: true,
            modalType: "success",
            modalTitle: "Sub-Category Added Successfully",
            modalMessage: "New Sub-Category has been added Successfully",
            icon: <SuccessfullVector />,
          }));
        } else {
          setCategories((prevState) => ({
            ...prevState,
            isModalOpen: true,
            modalType: "info",
            modalTitle: `Sub-Category Not Added`,
            modalMessage: `${response.data.message}`,
            icon: <InfoVector />,
          }));
        }
      } catch (error) {
        setCategories((prevState) => ({
          ...prevState,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error Adding Sub-Category",
          modalMessage: "Error occured while Adding Sub-Category",
          icon: <DeleteWarning />,
        }));
        const statusCode = error.response ? error.response.status : null;
        handleApiError(statusCode, navigate);
      }
    } else {
      try {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Category/addCategory`;
        const headers = {
          Authorization: `Bearer ${parsedObject["token"]}`,
        };

        const requestBody = {
          name: newCategory.newCategoryName,
        };

        const response = await axios.post(url, requestBody, { headers });
        if (response.data.success === 201) {
          await fetchCategories();
          setCategories((prevState) => ({
            ...prevState,
            isModalOpen: true,
            modalType: "success",
            modalTitle: "Category Added Successfully",
            modalMessage: "New Category has been added Successfully",
            icon: <SuccessfullVector />,
          }));
        } else {
          setCategories((prevState) => ({
            ...prevState,
            isModalOpen: true,
            modalType: "info",
            modalTitle: `Category Not Added`,
            modalMessage: `${response.data.message}`,
            icon: <InfoVector />,
          }));
        }
      } catch (error) {
        setCategories((prevState) => ({
          ...prevState,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error Adding Category",
          modalMessage: "Error occured while Adding Category",
          icon: <DeleteWarning />,
        }));
        const statusCode = error.response ? error.response.status : null;
        handleApiError(statusCode, navigate);
      }
    }
  };
  const handleClose = () => {
    if (selectedCategory.category) {
      fetchSubCategories(selectedCategory.category.id);
    } else {
      fetchCategories();
    }
    resetNewCategory();
    setCategories((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };
  const handleCategoryChangeName = (e) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*(\-\-|\/\/|\.\.|\s{2}))(?!.*:{2})[a-zA-Z0-9 .\-/]{1,50}$/

    if (!specialCharsRegex.test(inputName)) {
      setNewCategory((prevData) => ({
        ...prevData,
        categoryError: "Please Enter only letters, hyphen, period and slash, between 1 and 50 characters",
      }));
    } else {
      setNewCategory((prevData) => ({
        ...prevData,
        categoryError: "",
      }));
    }
    setNewCategory((prevData) => ({
      ...prevData,
      newCategoryName: inputName,
    }));
  };
  const handleSubCategoryChangeName = (e) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*(\-\-|\/\/|\.\.|\s{2}))(?!.*:{2})[a-zA-Z0-9 .\-/]{1,50}$/
    if (!specialCharsRegex.test(inputName)) {
      setNewCategory((prevData) => ({
        ...prevData,
        subCategoryError: "Please Enter only letters, hyphen, period and slash, between 1 and 50 characters",
      }));
    } else {
      setNewCategory((prevData) => ({
        ...prevData,
        subCategoryError: "",
      }));
    }
    setNewCategory((prevData) => ({
      ...prevData,
      newSubCategoryName: inputName,
    }));
  };

  return (
    <>
      <div>
        <div className="mb-3 d-flex gap-3 category-subCategory">
          <div className="col-6">
            <label htmlFor="name" className="col-form-label form-title-names">
              {categoryLabel} Category<span className="text-danger">*</span>
            </label>
            <Select
              styles={customStyles}
              isClearable={true}
              options={categories.categories?.map((option) => ({
                value: option.name,
                label: option.name,
                id: option.id,
              }))}
              isSearchable={true}
              onChange={(selectedOption) => {
                handleCategoryChange(selectedOption);
              }}
              components={{
                Menu: (props) => <CategoryMenu {...props} setIsSubCategory={setIsSubCategory(false)} isSubCategory={isSubCategory} categoryFilter={categoryFilter} hideButtoms={hideButtoms} />,
              }}
              value={selectedCategory?.category}
              placeholder="Select Category"
              isDisabled={!categoryEnabled}
              filterOption={categoryFilter != null ? (option) => option.label === categoryFilter : undefined}
            />
          </div>
          <div className="col-6">
            <label htmlFor="name" className="col-form-label form-title-names">
              {subCategoryLabel} Sub-Category<span className="text-danger">*</span>
            </label>
            <Select
              styles={customStyles}
              isClearable={true}
              options={categories.subCategories?.map((option) => ({
                value: option.name,
                label: option.name,
                id: option.id,
              }))}
              isSearchable={true}
              components={{
                Menu: (props) => <CategoryMenu {...props} setIsSubCategory={setIsSubCategory(true)} isSubCategory={isSubCategory} categoryFilter={categoryFilter} hideButtoms={hideButtoms} />,
              }}
              onChange={handleSubCategoryChange}
              value={selectedCategory?.subCategory}
              placeholder="Select Sub-Category"
              isDisabled={!subCategoryEnabled}
            />
          </div>
        </div>
        <div className="modal fade" id={isSubCategory ? "subCategoryModal" : "categoryModal"} tabindex="-1" aria-labelledby="archiveModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom">
                <h1 className="modal-title add-category-head">{isSubCategory ? "Add New Sub-Category" : "Add New Category"}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetNewCategory()}></button>
              </div>
              <div className="modal-body p-0">
                <div className="modal-body pt-0 pb-0">
                  <div className="mb-3">
                    <label htmlFor="name" className="col-form-label form-title-names">
                      {isSubCategory ? "Category" : "Category Name"}
                    </label>
                    {isSubCategory ? (
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        options={categories.categories?.map((option) => ({
                          value: option.name,
                          label: option.name,
                          id: option.id,
                        }))}
                        isSearchable={true}
                        onChange={(e) =>
                          setNewCategory((prevData) => ({
                            ...prevData,
                            categoryId: e?.id,
                          }))
                        }
                        defaultValue={selectedCategory.category}
                        placeholder={`Select ${isSubCategory ? "Category" : "Category Name"}`}
                        filterOption={categoryFilter != null ? (option) => option.label === categoryFilter : undefined}
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          id="categoryName"
                          placeholder={`Enter ${isSubCategory ? "Category" : "Category Name"}`}
                          onChange={(e) => handleCategoryChangeName(e)}
                          value={newCategory.newCategoryName}
                        />
                        {newCategory.newCategoryName && newCategory?.categoryError !== "" && (
                          <span className="text-danger error-msg">{newCategory?.categoryError}</span>
                        )}
                      </>
                    )}
                  </div>
                  {isSubCategory && (
                    <div className="mb-3">
                      <label htmlFor="name" className="col-form-label form-title-names">
                        Sub-Category Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Sub-Category"
                        onChange={(e) => handleSubCategoryChangeName(e)}
                        value={newCategory.newSubCategoryName}
                      />
                      {newCategory.newSubCategoryName && newCategory?.subCategoryError !== "" && (
                        <span className="text-danger error-msg">{newCategory?.subCategoryError}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer border-top gap-2">
                <button type="button" className="add-category-no-btn me-1" data-bs-dismiss="modal" onClick={() => resetNewCategory()}>
                  Cancel
                </button>
                <button type="button" className="add-category-yes-btn ms-1" data-bs-dismiss="modal" disabled={isSubCategory ? newCategory.subCategoryError !== "" : newCategory.categoryError !== ""} onClick={() => handleSaveButtonClick()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {categories.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: categories.icon,
              show: categories.isModalOpen,
              type: categories.modalType,
              title: categories.modalTitle,
              message: categories.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
      </div>
    </>
  );
};

export default CategoryDropdown;
