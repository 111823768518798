const DownloadExcel = (base64Data, fileName) => {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  const fileNameExt = `${fileName}}.xlsx`;

  downloadLink.href = linkSource;
  downloadLink.download = fileNameExt;
  downloadLink.click();
};
export default DownloadExcel;
