import React from 'react';
import { Skeleton, BreadCrumbs, Dot, Video, AssignmentIcon, PptIcon, MilestoneIcon, DarkDot, QuizOutline } from "../../UserImport";


const CourseDetailViewSkeleton = () => {
  const skeletonItems = Array.from({ length: 4 }, (_, index) => (
    <div className="col-12 border p-2 ps-3" key={index}>
      <div className="col-12 d-flex justify-content-start">
        <p className="mb-2 fw-bold"><Skeleton width={144} /></p>
      </div>
      <div className="col-12 d-flex gap-1">
        <p className="mb-1 course-detail-module-detail"><Skeleton width={144} /></p>
        <div className="mb-1 d-flex align-items-center mx-1">
        </div>
        <p className="mb-1 course-detail-module-detail"><Skeleton width={144} /></p>
      </div>
    </div>
  ));
  return (
    <>
      <div>
        <div className="container p-0">
          <div className="row course-detail-bg-header">
            <div className="mt-2 ms-4 pt-1 course-detail-breadcrumb">
              <BreadCrumbs darkBg={true} />
            </div>
            <div className="row mt-4">
              <div className="col-2 d-flex justify-content-center">
                <div className="course-detail-image-box">
                  <Skeleton width={144} height={144} />
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="col-12 d-flex gap-1">
                  <p className="mb-1 course-detail-heading-level"><Skeleton width={60} /></p>
                  <div className="mb-1 d-flex align-items-center mx-1">
                    <Dot />
                  </div>
                  <p className="mb-1 course-detail-heading-level"><Skeleton width={60} /></p>
                </div>
                <div className="col-12">
                  <p className="mb-3 mt-1 course-detail-heading-name"><Skeleton width={164} /></p>
                </div>
                <div className="col-12">
                  <p className="mb-1 mt-1 course-detail-heading-name"> <Skeleton width={544} /></p>
                  <p className="mb-1 mt-1 course-detail-heading-name"> <Skeleton width={544} /></p>
                </div>
                <div className="col-12 d-flex gap-2">
                  <Skeleton width={100} height={16} /><Skeleton width={100} height={16} />
                </div>
                <div className="mt-2">
                  <Skeleton className='' highlightColor='#ffb81c' width={128} height={32} />
                </div>
              </div>
            </div>
          </div>

          <div className="row course-detail-bg-body">
            <div className="col-8 p-0">
              <div className="card course-detail-level-card mb-4">
                <div className="col-6">
                  <p className="mb-0 course-level-text">Level</p>
                  <p className="mb-0 course-level-data"><Skeleton width={144} /></p>
                </div>
                <div className="col-6">
                  <p className="mb-0 course-level-text">Duration</p>
                  <p className="mb-0 course-level-data"><Skeleton width={144} /></p>
                </div>
              </div>
              <p className="course-detail-module-card-head ">What you will learn</p>
              <div className="card course-detail-module-card">
                {skeletonItems}
              </div>
            </div>
            <div className="col-4">
              <div className="card course-detail-card">
                <div>
                  <p className="course-detail-module-card-head ms-2">This course includes</p>
                  <div className="d-flex flex-column border p-3">
                    <div className="mb-3 d-flex gap-1">
                      <span className="d-flex align-items-center svg-box">
                        <Video className="course-detail-video-icon" />
                      </span>
                      <p className="mb-0 course-detail-content d-flex align-items-center"><Skeleton width={144} /> </p>
                    </div>
                    <div className="mb-3 d-flex gap-1">
                      <span className="d-flex align-items-center svg-box">
                        <PptIcon className="course-detail-video-icon mb-1" />
                      </span>
                      <p className="mb-0 course-detail-content d-flex align-items-center"><Skeleton width={144} /></p>
                    </div>
                    <div className="mb-3 d-flex gap-1">
                      <span className="d-flex align-items-center svg-box">
                        <QuizOutline className="course-detail-video-icon mb-1" />
                      </span>
                      <p className="mb-0 course-detail-content d-flex align-items-center"><Skeleton width={144} /></p>
                    </div>
                    <div className="mb-3 d-flex gap-1">
                      <span className="d-flex align-items-center svg-box">
                        <AssignmentIcon className="course-detail-video-icon mb-1 ms-1" />
                      </span>
                      <p className="mb-0 course-detail-content d-flex align-items-center"><Skeleton width={144} /></p>
                    </div>
                    <div className="mb-3 d-flex gap-1">
                      <span className="d-flex align-items-center svg-box">
                        <MilestoneIcon className="mb-1" />
                      </span>
                      <p className="mb-0 course-detail-content d-flex align-items-center"><Skeleton width={144} /></p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="course-detail-module-card-head ms-2">About The Instructor</p>
                  <div className="d-flex flex-row p-3">
                    <div className="image-wrapper1">
                      <div className="cert-image-box1">
                        <div className="d-flex justify-content-center noMentor-img">
                          <span className="initials">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-wrapper1">
                      <p className="m-0">
                        <b>
                          <Skeleton width={144} />
                        </b>
                      </p>

                      <p className="m-0 pos-wrapper"><Skeleton width={144} /></p>
                    </div>
                  </div>
                  <div className="p-3">
                    <Skeleton width={344} />
                    <Skeleton width={344} />
                    <Skeleton width={344} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetailViewSkeleton;
