import axios from "axios";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import CryptoJS from "crypto-js";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../../_common/HandleApiError";
import { InfinitySpin } from "react-loader-spinner";
import { jwtDecode } from "jwt-decode";
import { UserContext } from "../../../Context/Context";
import LoginProcess from "./LoginProcess";


const AuthRedirect = () => {
  const { setIsAuthenticated } = useContext(UserContext)
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({
    ssoStatus: true,
    loader: false
  });
  const fetchData = async () => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      const silentRequest = {
        account: currentAccount,
      };

      const response = await instance.acquireTokenSilent(silentRequest);
      const tokenS = response.accessToken;

      const getBrowserTimeZone = () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return userTimeZone;
      };
      const browserTimeZone = getBrowserTimeZone();

      var url = `${process.env.REACT_APP_API_URL_DOTNET}/Sso/authenticateSso?ClientTimeZone=${browserTimeZone}`;
      const headers = {
        Authorization: `Bearer ${tokenS}`,
      };
      try {
        const response = await axios.get(url, { headers });
        if (response.data.success == 200) {
          LoginProcess(response.data.result, setIsLoading, setIsAuthenticated, isLoading, navigate);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        const statusCode = error.response ? error.response : null;
        handleApiError(statusCode, navigate);
      }
    }
  };

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, instance]);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 vw-100">
      <InfinitySpin className="d-flex align-items-center justify-content-center" color="#ffc107" />
    </div>
  );
};

export default AuthRedirect;
