import {React,Skeleton} from "./../../../../UploadCourses/UploadCoursesImport"

const TechTalkReportSkeleton = () => {
    return (
        <tr className="tech-table-tr" >
        <td className="tech-heigh-vert">
          <div className="name-column">
            <div className="tags justify-content-center">
              <div className="tag1"><Skeleton width={150}/></div>
            </div>
          </div>
        </td>
        <td className={`tag2 tag2-adjustment tech-height-vertical`}>
          <div>
            <div><Skeleton/></div>
          </div>
        </td>
        <td className={`tag2 text-center tag2-adjustment tech-heigh-vert`}>
        <Skeleton/>
        </td>
        <td className={`tag2 text-center tag2-adjustment tech-heigh-vert `}>
        <Skeleton/>
        </td>
        <td className={`tag2 text-center tag2-adjustment tech-heigh-vert `}>
          <div><Skeleton/></div>
        </td>
      </tr>
      );
}

export default TechTalkReportSkeleton