import React from 'react';
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component from the library

const CourseCardSkeleton = () => {
  return (
    <div className="main-container mt-2">
      <div className="card-main-container">
        <div className={`first-container`}>
          <div className="first-left">
            <Skeleton width={144} height={144} />
          </div>
          <div className="first-right">
            <div className="d-flex justify-content-between position-relative">
              <div className="d-flex align-items-center gap-1 ms-1">
                <Skeleton width={50} height={16} />
                <Skeleton width={50} height={16} />
                <Skeleton width={50} height={16} />
              </div>

            </div>
            <div className="d-grid gap-1">
              <div className=" w-100 ms-1">
                <Skeleton height={24} />
              </div>
              <div>
                <div className="card-description">
                  <Skeleton count={3} />
                </div>
              </div>
              <div className="lms-enroll-progress-bar">
                <Skeleton height={20} />
              </div>
            </div>
          </div>
        </div>
        <div className="second-container">
          <div className="d-flex align-items-center justify-content-center gap-3">
            <div className="d-flex align-items-center justify-content-center gap-1">
              <Skeleton width={20} height={20} />
              <Skeleton width={100} height={16} />
            </div>
            <div className="d-flex align-items-center justify-content-center gap-1">
              <Skeleton width={20} height={20} />
              <Skeleton width={100} height={16} />
            </div>
            <div className="d-flex align-items-center justify-content-center gap-1">
              <Skeleton width={20} height={20} />
              <Skeleton width={100} height={16} />
            </div>
          </div>
          <Skeleton width={80} height={30} />
        </div>
      </div>
    </div>
  );
};

export default CourseCardSkeleton;
