import { useState,axios,useEffect,decryptData,useNavigate,handleApiError,Header,SideNav,UserMilestoneCount,MilestoneAchievementModal,UserMilestoneDetails,AddCertificationModal,MilestoneAchievement,Add } from "../UserImport";
import "../UserMilestones/UserMilestones.css";

const UserMilestones = () => {
  const [certificateData, setCertificateData] = useState([]);
  const [certificateDataLoading, setCertificateDataLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({
    isModalClosed: true,
    milestoneLevel: "",
    fullName: "",
    version: 0,
    userCourseAchievedPoints : undefined
  });

  const handleModalClosed = (value) => {
    setModalInfo((prevState) => ({
      ...prevState,
      isModalClosed: value,
    }));
  };

  const handleModalOpen = (milestoneLevel, fullName) => {
    setModalInfo((prevState) => ({
      ...prevState,
      isModalClosed: false,
      milestoneLevel: milestoneLevel,
      fullName: parsedObject["decoded"]["FirstName"] + (parsedObject["decoded"]["LastName"] ? " " + parsedObject["decoded"]["LastName"] : "")
    }));
  };

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getUserCourseAchievementPoints = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getUserCourseAchievementPoints`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setModalInfo((prevState) => ({
          ...prevState,
          userCourseAchievedPoints : response.data.result,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    getUserCourseAchievementPoints();
   }, []);

  return (
    <>
      <div style={{ marginBottom: "4.5rem" }}>
        <Header />
        <SideNav />
      </div>
      {!(modalInfo.isModalClosed) ? <MilestoneAchievementModal onModalClose={handleModalClosed} milestoneLevel={modalInfo?.milestoneLevel} fullName={modalInfo?.fullName} calledFromMilestone={true} /> : ""}
      <div className="user-certification-page">
        <div className="container-fluid ms-3">
        <div className="ms-3 mb-2 user-milestone-heading-container">
        <p className="milestone-header">My Achievements</p>
        <button type="button" className="add-cert-badge-button mb-2 p-2" data-bs-toggle="modal" data-bs-target="#addCertModal">
          <Add className="add-certificate-img"/> Add Certificates / Badges
        </button>
      </div>
          <div className="row bg-light">
            <div className=" col-4 p-0 ms-3">
              <UserMilestoneCount setCertificateData={setCertificateData} setCertificateDataLoading={setCertificateDataLoading} version={modalInfo.version}/>
            </div>
            <div className=" col-5 p-0 user-milestone-details">
              <UserMilestoneDetails certificateData={certificateData} certificateDataLoading={certificateDataLoading} />
            </div>
            <div className=" col-3 p-0 achievement">
              <MilestoneAchievement certificateData={modalInfo.userCourseAchievedPoints} handleModalOpen={handleModalOpen}/>
            </div>
          </div>
        </div>
        <AddCertificationModal version={modalInfo.version} setVersion={setModalInfo}/>
      </div>
    </>
  );
};

export default UserMilestones;
