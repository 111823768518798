import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { decryptData } from "../../UserImport";

const cgData = ["Angular", "React", "DotNet", "CSharp", "Salesforce", "MsSQL", "JavaScript", "Azure"];

const TopicSelect = (props) => {
  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const { onChange, disabled, resetSelect, learningType, topicName } = props;
  const [topics, setTopics] = useState([]);
  const [myProjects, setMyProjects] = useState();
  const navigate = useNavigate();

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "21.68rem",
      margin: "auto",
      marginTop: "0.37rem",
      height: "2.5rem",
    }),

    menu: (provided) => ({
      ...provided,
      width: "21.68rem",
      margin: "auto",
      maxHeight: "13rem",
      overflow: "auto",
    }),

    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      maxHeight: "13rem",
      "::-webkit-scrollbar": {
        width: "0.5rem",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#d9d9d9",
        border: "1px solid rgba(255, 255, 255, 0.1475)",
        borderRadius: "0.875rem",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#bfbfbf",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightGrey" : "white",
      color: state.isSelected ? "black" : "black",
    }),
  };

  useEffect(() => {
    Projects();
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL_DOTNET + "/Project/technology", {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });
      setTopics(
        response.data?.result.sort((a, b) => {
          const nameA = a.techName.toUpperCase();
          const nameB = b.techName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        })
      );
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.status === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.status === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.status === 404) {
        navigate("/error/statusCode=404");
      }
      console.error("Error fetching topics:", error);
    }
  };

  const Projects = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL_DOTNET + `/Project/getByUserId`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });
      setMyProjects(response.data.result);
    } catch (error) {
      if (error.response.success === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.success === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.success === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.success === 404) {
        navigate("/error/statusCode=404");
      }
      console.error("Error fetching projects:", error);
    }
  };

  const learningTypeSelected = (val) => {
    switch (val) {
      case "CG Learning Videos":
        return (
          <>
            <label htmlFor="topic" className="form-label dtt-t">
              Topic <span style={{ color: "red" }}>* </span>
            </label>
            <Select
              styles={customStyles}
              isClearable={true}
              key={resetSelect ? "topicReset" : "topicName"}
              options={cgData.map((topic) => ({
                value: topic,
                label: topic,
              }))}
              isDisabled={disabled}
              onChange={onChange}
              value={topicName ? { value: topicName, label: topicName } : null}
              placeholder="Select Topic"
            />
          </>
        );

      case "Mentor Assigned Task":
        return (
          <>
            <label htmlFor="topic" className="form-label dtt-t">
              Task Name <span style={{ color: "red" }}>*</span>
            </label>
            <input type="text" id="taskName" disabled={disabled} value={topicName} className="form-control dtt-selector" placeholder="Enter Task Name" onChange={onChange} />
            {topicName?.length > 100 && <span style={{ color: "red", fontSize: "12px", marginLeft: "15px" }}>Exceeded maximum word limit of 100</span>}
          </>
        );

      case "Project":
        return (
          <>
            <label htmlFor="topic" className="form-label dtt-t">
              Project Name <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              styles={customStyles}
              isClearable={true}
              key={resetSelect ? "topicReset" : "topicName"}
              options={myProjects?.map((topic) => ({
                value: topic.name,
                label: topic.name,
              }))}
              isDisabled={disabled}
              onChange={onChange}
              value={topicName ? { value: topicName, label: topicName } : null}
              placeholder="Select Project" // Placeholder text
            />
          </>
        );

      case "Session":
        return (
          <>
            <label htmlFor="topic" className="form-label dtt-t">
              Session Name <span style={{ color: "red" }}>*</span>
            </label>
            <input type="text" id="taskName" disabled={disabled} value={topicName} className="form-control dtt-selector" placeholder="Enter Session Name" onChange={onChange} />
            {topicName?.length > 100 && <span style={{ color: "red", fontSize: "12px", marginLeft: "15px" }}>Exceeded maximum word limit of 100</span>}
          </>
        );

      default:
        return (
          <>
            <label htmlFor="topic" className="form-label dtt-t">
              Topic <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              styles={customStyles}
              key={resetSelect ? "topicReset" : "topicName"}
              isClearable={true}
              options={topics.sort().map((topic) => ({
                value: topic.techName,
                label: topic.techName,
              }))}
              isDisabled={disabled}
              onChange={onChange}
              value={topicName ? { value: topicName, label: topicName } : null}
              placeholder="Select Topic" // Placeholder text
            />
          </>
        );
    }
  };

  return learningTypeSelected(learningType);
};

export default TopicSelect;
