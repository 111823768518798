import { useNavigate, CourseCard, CourseCardSkeleton, ArrowForwardIcon, axios, decryptData, handleApiError } from "../../UserImport";
import "./EnrolledCourses.css";

const EnrolledCourses = (props) => {
  const { enrolledCoursesData, isLoading } = props;
  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  var userId = parsedObject["decoded"]["Id"];
  const sortedCourses = enrolledCoursesData?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  const StartCourse = async (storeCourseId) => {
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/startCourse?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("courseId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const ResumeCourse = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/resumeCourse?userId=${userId}&courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", courseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  return isLoading ? (
    <div className="d-grid gap-1 p-0">
      <div className="d-flex justify-content-between">
        <div className="lms-dashboard-enrolled-course-heading">Enrolled Courses</div>
        <div
          className="d-flex align-items-center lms-dashboard-enrolled-course-show-all pointer"
          onClick={() => {
            navigate("/lms/user-enrolled-courses");
          }}
        >
          Show All <ArrowForwardIcon className="ms-2" />
        </div>
      </div>
      <div className="d-grid gap-2">
        {Array.from({ length: 4 }).map((_, index) => (
          <CourseCardSkeleton key={index} />
        ))}
      </div>
    </div>
  ) : (
    sortedCourses && sortedCourses?.length !== 0 && (
      <div className="d-grid gap-1 p-0">
        <div className="d-flex justify-content-between">
          <div className="lms-dashboard-enrolled-course-heading">Enrolled Courses</div>
          <div
            className="d-flex align-items-center lms-dashboard-enrolled-course-show-all pointer"
            onClick={() => {
              navigate("/lms/user-enrolled-courses");
            }}
          >
            Show All <ArrowForwardIcon className="ms-2" />
          </div>
        </div> 
        <div className="d-grid gap-2">
          {sortedCourses?.slice(0, 4)?.map((course) => (
            <CourseCard
              courseCardProp={{
                courseType: true,
                activityType: course?.courseStatus === "Enrolled" ? "Play" : course?.courseStatus === "Completed" ? "Completed" : "Resume",
                courseImageLink: course?.thumbnailUri,
                activityLevel: course?.levelName,
                activityGradeType: course?.gradeType,
                activityInstructor: course?.instructorName,
                activityName: course?.courseTitle,
                activityDescription: course?.description,
                activityCompleteCount: course?.completedModuleCount,
                activityTotalCount: course?.moduleCount,
                activityCourseStatus: course?.courseStatus,
                activityModuleCount: course?.moduleCount,
                activityDuration: course?.duration,
                activityStartDate: course?.startDate,
                activityResume: () => ResumeCourse(course?.id),
                activityPlay: () => StartCourse(course?.id),
              }}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default EnrolledCourses;
