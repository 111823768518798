import React from "react";
import { useEffect, useState, useContext } from "react";
import "./TakeYourTest.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as DotTYT } from "../../../../Assets/Ellipsestakeyourtest.svg";
import { UserContext } from "../../../../Context/Context";
import { decryptData, handleApiError, axios } from "../../UserImport";
import QuizLoadingSkeleton from "./QuestionsSkeleton";
import {CustomModal,AlertInfo} from "../../UserImport";
const TakeYourTest = () => {
  const { setScore, setExamName, setExamLevel, setDateOfTest, setTimeOfTest } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  var parsedObject;
  const examDetails = JSON.parse(sessionStorage.getItem("examData"));

  const result = localStorage.getItem("userData");
  result ? (parsedObject = decryptData(result, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  let examId = null;
  let examName = null;
  let examDuration = null;
  let numberOfQuestion = null;
  let techName = null;
  let level = null;
  if (examDetails === null) {
    navigate("/skill-Management");
  } else {
    examId = examDetails.examId;
    examName = examDetails.examName;
    examDuration = examDetails.examDuration;
    numberOfQuestion = examDetails.numberOfQuestion;
    techName = examDetails.techName;
    level = examDetails.levelName;
  }
  const [Ques, setTestsQues] = useState([]);
  const [allQuesData, setAllQuesData] = useState([]);
  const [activeRadioButtons, setActiveRadioButtons] = useState();
  const [userAnswers, setUserAnswers] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiTechnology, setApiTechnology] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeUp, setTimeUp] = useState(false);
  const [ratingData, setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const clickHandler = () => {
    navigate("/skill-Management");
    setFullscreen(false);
  };

  const [time, setTime] = useState(0);
  let timer;

  const startTimer = () => {
    const examDurationInSeconds = parseInt(examDuration) * 60;
    setTime(examDurationInSeconds);
    timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          setTimeUp(true);
          return prevTime;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    fetchTests();
    fetchLevel();
    handleAnswerSelect();
    setFullscreen(true);
    startTimer();
    return () => {
      clearInterval(timer);
    };
  }, []);

  //refresh
  useEffect(() => {
    let refreshCount = parseInt(sessionStorage.getItem("refreshCount")) || 0;
    if (refreshCount > 1) {
      window.location.href = "/skill-Management";
      sessionStorage.removeItem("refreshCount");
      sessionStorage.removeItem("examData");
    } else {
      window.onbeforeunload = function () {
        refreshCount++;
        sessionStorage.setItem("refreshCount", refreshCount);
      };
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, []);

  // to prevent user to change the url
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     window.confirm("Are you sure want to leave this page?")
  //   };
  //   const handleUnload = () => {
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("unload", handleUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     localStorage.removeItem("questionToken");
  //     sessionStorage.removeItem("examData");
  //     localStorage.removeItem("refreshCount");
  //   };
  // }, [window.location.pathname]);

  function requestFullscreen() {
    var element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const convertTime = (inputTime) => {
    const [hours, minutes] = inputTime.split(":");
    const date = new Date(0, 0, 0, hours, minutes);
    let formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  };

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setApiTechnology(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchTests = async () => {
    let Quesdata;
    try {
      const response = await fetch(process.env.REACT_APP_API_URL_DOTNET + `/Exam/startExam?examId=${examId}`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });
      Quesdata = await response.json();
      setAllQuesData(Quesdata);
      setTestsQues(Quesdata.result);
      setLoading(false);
      window.location.hash = "no-back-button";
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      };
      window.onbeforeunload = function () {
        let refreshCount = sessionStorage.getItem("refreshCount");
        refreshCount++;
        sessionStorage.setItem("refreshCount", refreshCount);
      };
      requestFullscreen();
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.status === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.status === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.status === 404) {
        navigate("/error/statusCode=404");
      }
    }
  };

  const mapLevelNameToId = (levelName) => {
    const level = apiTechnology.find((item) => item.name === levelName);
    return level ? level.id : null;
  };

  const handleAnswerSelect = (questionId, selectedAnswer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedAnswer,
    }));
    setActiveRadioButtons(getActiveRadioCount());
  };

  const getActiveRadioCount = () => {
    let count = 0;
    Object.values(userAnswers).forEach((answer) => {
      if (answer) {
        count++;
      }
    });
    return count;
  };

  const radioButtons = document.querySelectorAll('input[type="radio"]');
  const activeRadioCount = getActiveRadioCount();
  radioButtons.forEach((radioButton) => {
    radioButton.addEventListener("change", () => { });
  });

  const submitTest = async () => {
    let submitQuesData;
    const api = process.env.REACT_APP_API_URL_DOTNET + "/Exam/submitExam";
    try {
      const mappedAnswers = Object.entries(userAnswers)?.map(([questionId, selectedAnswer]) => ({
        qId: parseInt(questionId), //questionId
        choosenOpt: selectedAnswer, //selectedOptionId
      }));
      const levelId = mapLevelNameToId(level);
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
        body: JSON.stringify({
          techName: techName,
          techId: 0,
          levelName: "",
          levelId: levelId,
          examId: 0,
          examName: "",
          questionPool: mappedAnswers.splice(0, mappedAnswers.length - 1),
        }),
      });
      submitQuesData = await response.json();
      setScore(Math.floor(submitQuesData.result.scorePercentage));
      setExamName(submitQuesData.result.examName);
      setExamLevel(submitQuesData.result.levelName);
      const dateOfTest = new Date(submitQuesData.result.testDate);
      const newDateOfTest = `${dateOfTest.getMonth() + 1}/${dateOfTest.getDate()}/${dateOfTest.getFullYear()}`;
      setDateOfTest(newDateOfTest);
      setTimeOfTest(submitQuesData.result?.testTime);
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("questionToken");
      sessionStorage.removeItem("examData");
      sessionStorage.removeItem("refreshCount");
    }
  };

  const handleCopy = (event) => {
    event.preventDefault();
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: true,
      modalType: "error",
      modalTitle: "Error !",
      modalMessage: "Copying content is disabled on this page.",
      icon: <AlertInfo />,
    }));
  };
  const handlePaste = (event) => {
    event.preventDefault();
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: true,
      modalType: "error",
      modalTitle: "Error !",
      modalMessage: "Pasting content is disabled on this page.",
      icon: <AlertInfo />,
    }));
  };

  useEffect(() => {
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    if (timeUp == true) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "Time's Up!",
        icon: <AlertInfo />,
      }));
      submitTest();
      clickHandler();
      clearInterval(timer);
    }
  }, [timeUp]);

  const renderQuestions = () => {
    return (
      <div>
        {Ques?.map((quest, index) => (
          <div key={quest.questionId}>
            <div className="ques-of-quiz">
              {index + 1} {"."} {quest.question}
            </div>
            <div>
              <div className="d-flex flex-column">
                <div className="start-input">
                  <label className="checkbox-option">
                    <input
                      type="radio"
                      name={`question_${quest.questionId}`}
                      value={quest.option1}
                      onChange={() => handleAnswerSelect(quest.questionId, quest.option1)}
                      checked={userAnswers[quest.questionId] === quest.option1}
                      className="mr-5"
                    />
                    {`   ` + quest.option1}
                  </label>
                </div>
                <div className="start-input">
                  <label className="checkbox-option">
                    <input
                      type="radio"
                      name={`question_${quest.questionId}`}
                      value={quest.option2}
                      onChange={() => handleAnswerSelect(quest.questionId, quest.option2)}
                      checked={userAnswers[quest.questionId] === quest.option2}
                      className="mr-5"
                    />
                    {`   ` + quest.option2}
                  </label>
                </div>
                <div className="start-input">
                  <label className="checkbox-option">
                    <input
                      type="radio"
                      name={`question_${quest.questionId}`}
                      value={quest.option3}
                      onChange={() => handleAnswerSelect(quest.questionId, quest.option3)}
                      checked={userAnswers[quest.questionId] === quest.option3}
                      className="mr-5"
                    />
                    {`   ` + quest.option3}
                  </label>
                </div>
                <div className="start-input">
                  <label className="checkbox-option">
                    <input
                      type="radio"
                      name={`question_${quest.questionId}`}
                      value={quest.option4}
                      onChange={() => handleAnswerSelect(quest.questionId, quest.option4)}
                      checked={userAnswers[quest.questionId] === quest.option4}
                      className="mr-5"
                    />
                    {`   ` + quest.option4}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="d-flex flex-column submit-button">
          <button className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Submit Quiz
          </button>
        </div>
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <span className="modal-title instruction fw-bold" id="staticBackdropLabel">
                  Submit Test{" "}
                </span>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body"> Are you sure? Want to submit the test?</div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                  Cancel
                </button>{" "}
                <button
                  type="button"
                  onClick={() => {
                    setFullscreen(false);
                    submitTest();
                    clickHandler();
                  }}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="resp">
        <div className="row testhHeading-and-Timer-Div">
          <div className="col-12">
            <div className="main-heading">
              <p> {examName} </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 Timer-and-attemtedQues">
              <div className="col-8 quiz-description">
                Quiz &nbsp; <DotTYT /> &nbsp; {examDuration} mins &nbsp; <DotTYT /> &nbsp;
                {numberOfQuestion} Questions
              </div>
              <div className="col-1 timer-Box">
                <p>{formatTime(time)}</p>
              </div>
              <div className="col-3 active-Radio-Buttons attempted-Ques">
                &nbsp; Attempted Questions: {activeRadioCount}/{numberOfQuestion}
              </div>
            </div>
          </div>
        </div>
        {loading ? <QuizLoadingSkeleton /> : <div className="card insidecard">{fullscreen && !submitted && <div> {renderQuestions()} </div>}</div>}
        {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
      </div>
    </>
  );
};
export default TakeYourTest;
