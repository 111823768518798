import "./EmptyGraph.css";
import { ReactComponent as NoGraph } from "../../../../../Assets/Group 3EmpGraph.svg";
 
const EmptyGraph = () => {
  return (
    <>
        <div className="card-body">
          <div
            className="col-12 d-flex justify-content-center"
          >
            <NoGraph />
          </div>
          <div className="col-12 d-flex justify-content-center noRep">
            <p>No Reports Found! </p>
          </div>
          <div className="col-12 d-flex justify-content-center p2">
            <p>Sorry, there is no report available to display at this time.</p>
          </div>
        </div>
    </>
  );
};
 
export default EmptyGraph;