import "./DetailedLog.css";
import { useState, CalendarIcon, Clock, EmptyLogs, Filter, uploadImage, VerticalLine, DetailedLogSkeleton, DatePicker } from "../../AdminImport";
import "react-datepicker/dist/react-datepicker.css";

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

function makeWordsInQuotesBold(activity) {
  const regex = /'([^']+)'/g;
  activity = activity.replace(regex, "<strong>$1</strong>");
  return activity;
}

function getInitials(name) {
  const names = name?.split(" ");
  const initials = names?.map((n) => n.charAt(0).toUpperCase());
  return initials?.join("");
}

function convertTo12HourFormat(time) {
  const [hours, minutes] = time.split(":");
  let period = "AM";
  let convertedHours = parseInt(hours, 10);

  if (convertedHours === 0) {
    convertedHours = 12;
  } else if (convertedHours === 12) {
    period = "PM";
  } else if (convertedHours > 12) {
    convertedHours -= 12;
    period = "PM";
  }
  convertedHours = convertedHours.toString().padStart(2, "0");

  return `${convertedHours}:${minutes} ${period}`;
}

function CustomInput({ value, onClick }) {
  return (
    <div className="row log-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 log-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">{value ? "" : <CalendarIcon />}</span>
    </div>
  );
}

const DetailedCard = (props) => {
  const { logData, selectedUser } = props;
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const currentDate = new Date(); // Get the current date

  return (
    <>
      {selectedUser.detailLogLoading ? (
        <div className="card empty-log-state d-flex skeleton-log">
          <DetailedLogSkeleton />
        </div>
      ) : (
        <>
          {selectedUser.mentor !== 0 ? (
            <div className="card empty-log-state d-flex " style={{ marginTop: "39px" }}>
              <div className="log-header-detailed">
                <div className="row mentor-wrapper">
                  <div className="col-4 frame pointer">
                    <div className="image-box1">{props.selectedMentor.imageUrl && props.selectedMentor.imageUrl.includes("https://") ? <img key={props.selectedMentor.mentorId} src={props.selectedMentor?.imageUrl || uploadImage} width={38} alt="" /> : {}}</div>
                  </div>
                  <div className="col-4 pointer">
                    <div className="frame-text">{props.selectedMentor.mentorName}</div>
                    <div className="frame-id">{props.selectedMentor.designation}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : selectedUser.user !== 0 ? (
            <div className="card empty-log-state d-flex " style={{ marginTop: "39px" }}>
              <div className="log-header-detailed">
                <div className=" row mentor-wrapper">
                  <div className="col-4 frame pointer">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0.938rem",
                      }}
                    >
                      {getInitials(selectedUser && selectedUser.user?.name)}
                    </p>
                  </div>
                  <div className=" col-4 pointer">
                    <div className="frame-text">{selectedUser && selectedUser.user?.name}</div>
                    <div className="frame-id">{selectedUser && selectedUser.user?.cgiId}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col p-0 report-filter mt-1">
                    <Filter /> Filter:
                  </div>
                  <div className="col filter-container">
                    <DatePicker popperPlacement="top-end" selected={selectedDateFilter} onChange={(date) => setSelectedDateFilter(date)} dateFormat="MM/dd/yyyy" customInput={<CustomInput />} isClearable={true} />
                  </div>
                </div>
              </div>
              <div className="card-body p-0" style={{ maxHeight: "100vh", overflow: "auto", width: "725px" }}>
                {logData != null && logData?.length > 0 ? (
                  logData?.filter((log) => (selectedDateFilter ? formatDate(log.logDate) === formatDate(selectedDateFilter) : true)).length > 0 ? (
                    logData
                      ?.filter((log) => (selectedDateFilter ? formatDate(log.logDate) === formatDate(selectedDateFilter) : true))
                      .map((log, index) => (
                        <div className="pb-3 mb-4" style={{ borderBottom: "1px solid #E9ECEB" }} key={index}>
                          <p className="log-date">{formatDate(log.logDate)}</p>
                          {log.logTime.map((timeActivity, i) => {
                            const { time, userActivity } = timeActivity; // Destructuring assignment
                            const convertedTime = convertTo12HourFormat(time);
                            const boldActivity = makeWordsInQuotesBold(userActivity);

                            const showLine = i < log.logTime.length - 1;

                            return (
                              <div className="row mx-2" key={i}>
                                <div className="clock-icon col-1 p-0">
                                  <Clock />
                                </div>
                                <div className="clock-time col-2 p-0">{convertedTime}</div>
                                <div className="log-item col-9 p-0" dangerouslySetInnerHTML={{ __html: boldActivity.length > 80 ? boldActivity.slice(0, 80).concat("...") : boldActivity }} title={boldActivity}></div>
                                {showLine && (
                                  <div>
                                    <VerticalLine />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ))
                  ) : (
                    <div className=" empty-log-state d-flex justify-content-center h-100 w-100">
                      <div className="d-flex align-items-center flex-column">
                        <EmptyLogs />
                        <p className="fw-bold mt-3 mb-1 empty-sde-text">No user activity logs available for selected date!</p>
                        <p className="empty-sde-fade-text">We’ll notify you when any activity occurs.</p>
                      </div>
                    </div>
                  )
                ) : (
                  <div className=" empty-log-state d-flex justify-content-center h-100 w-100">
                    <div className="d-flex align-items-center flex-column">
                      <EmptyLogs />
                      <p className="fw-bold mt-3 mb-1 empty-sde-text">No user activity logs available for selected date!</p>
                      <p className="empty-sde-fade-text">We’ll notify you when any activity occurs.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="card empty-log-state d-flex justify-content-center w-100" style={{ marginTop: "39px" }}>
              <div className="d-flex align-items-center flex-column">
                <EmptyLogs />
                <p className="fw-bold mt-3 mb-1 empty-sde-text">No user activity logs available right now!</p>
                <p className="empty-sde-fade-text">We’ll notify you when any activity occurs.</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DetailedCard;
