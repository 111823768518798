import "./OtherModals.css";
import { useState, useEffect, useNavigate, CalendarIcon, TechActionType, UserActionType, decryptData, useReducer, axios, ExpandMore, UsersDropdown, Select, handleApiError, Form, Col } from "../../AdminImport";
import TechDropdown from "./TechDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),

  menu: (provided) => ({
    ...provided,
    width: "100%",
    margin: "auto",
    maxHeight: "13rem",
    overflow: "auto",
  }),
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    maxHeight: "13rem",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),
};

function CustomInput({ value, onClick }) {
  return (
    <div className="row addTask-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 addTask-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">
        <CalendarIcon />
      </span>
    </div>
  );
}
const formatDateToString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECT_ALL_USER_CHECKED:
      return { ...state, selectAllChecked: action.payload };
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };

    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };

    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectAllChecked: false,
  selectedUserIds: [],
  selectedUserName: [],
  usersValue: "",
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};
export const AddNewTask = (props) => {
  const { setTaskToEdit, setTasks } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });

  const [instructorsdata, setInstructorsdata] = useState({
    showInstructor: false,
    instructorList: [],
  });

  const [task, setTask] = useState({
    taskName: "",
    taskDescription: "",
    startDate: "",
    endDate: "",
    error: {
      nameError: false,
      descriptionError: false,
    },
    instructor: "",
    instructorId: "",
    assignedBy: "Admin Assigned",
  });

  const regEx = {
    nameRegex: /^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/,
    descriptionRegex: /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{50,500}$/,
    stringRegex: /[^,]+/g,
  };
  const navigate = useNavigate();
  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = Object.values(state.selectedUserName).join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const onInstructorChange = (e) => {
    setTask((prevData) => ({
      ...prevData,
      instructor: e?.value,
      instructorId: e?.id,
    }));
  };
  const handleOptionChange = (event) => {
    const selectedGrade = event.target.value;
    if (selectedGrade === "Admin Assigned") {
      setTask((prevData) => ({
        ...prevData,
        assignedBy: "Admin Assigned",
      }));
    } else if (selectedGrade === "Mentor Assigned") {
      setTask((prevData) => ({
        ...prevData,
        assignedBy: "Mentor Assigned",
      }));
    }
  };

  const isFieldValid = (value, regex) => {
    return value && regex.test(value);
  };
  const isArrayValid = (value, regex) => {
    return value && value.match(regex);
  };
  const areAllFieldsFilled = () => {
    if (task.assignedBy === "Admin Assigned"){
    return isFieldValid(task.taskName, regEx.nameRegex) && isFieldValid(task.taskDescription, regEx.descriptionRegex) && task.startDate && task.endDate && state.selectedTechIds.length !== 0 && state.selectedUserIds.length !== 0 && !(state.selectedTechNames && state.selectedTechNames.length > 10) ;
    }else{
      return isFieldValid(task.taskName, regEx.nameRegex) && isFieldValid(task.taskDescription, regEx.descriptionRegex) && task.startDate && task.endDate && state.selectedTechIds.length !== 0 && state.selectedUserIds.length !== 0 && !(state.selectedTechNames && state.selectedTechNames.length > 10) && task.instructorId !== undefined && task.instructorId !=="";
    }
  };
  const handleClickClear = () => {
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
    setTask((prevState) => ({
      ...prevState,
      taskName: "",
      taskDescription: "",
      startDate: "",
      endDate: "",
      error: {
        nameError: false,
        descriptionError: false,
      },
      instructor: "",
      instructorId: "",
      assignedBy: "Admin Assigned",
    }));
    dispatch({ type: "RESET_STATE" });
    const userCheckboxes = document.querySelectorAll(".user-checkbox");
    userCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAllUserByRole`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = { roleName: process.env.REACT_APP_USER_DES_MENTOR };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setInstructorsdata((prevData) => ({
          ...prevData,
          instructorList: sortedUsers,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedStartDate = formatDateToString(task.startDate);
    const formattedEndDate = formatDateToString(task.endDate);
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    if (!isFieldValid(task.taskName, regEx.nameRegex) || !isFieldValid(task.taskDescription, regEx.descriptionRegex) || !isArrayValid(state.techValue, regEx.string) || !isArrayValid(state.usersValue, regEx.string)) {
      alert("Please fill out the necessary fields");
    } else {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/add`;
      const body = {
        taskName: task.taskName,
        taskDescription: task.taskDescription,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        techId: state.selectedTechIds,
        userId: state.selectedUserIds,
        assignedBy: task.assignedBy === "Admin Assigned" ? parsedObject.decoded.Id : task.instructorId,
        name: [],
        techNames: [],
        ratedTask: true,
      };
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      };
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 201) {
        setTasks((prevState) => ({
          ...prevState,
          isOpen: true,
        }));
        setTaskToEdit((prevState) => ({
          ...prevState,
          version: prevState.version + 1,
        }));
        handleClickClear();
        const userCheckboxes = document.querySelectorAll(".user-checkbox");
        userCheckboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        const checkboxes = document.querySelectorAll(".tech-checkbox");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        alert(response.data.message);
      }
    }
  };

  return (
    <div>
      <div className="modal fade" id="addTaskModal" tabIndex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-1">
              <h5 className="modal-title modalheading-text" id="skillModalLabel">
                Add New Task
              </h5>

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => handleClickClear(e)}></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3 ">
                  <label className=" me-3">
                    <input className="form-check-input color-of-radio me-1" type="radio" name="options" id="Admin Assigned" value="Admin Assigned" checked={task.assignedBy === "Admin Assigned"} onChange={handleOptionChange} />
                    Assigned by Me
                  </label>
                  <label>
                    <input className="form-check-input color-of-radio me-1" type="radio" name="options" id="Mentor Assigned" value="Mentor Assigned" checked={task.assignedBy === "Mentor Assigned"} onChange={handleOptionChange} />
                    Assigned on Behalf of Mentor
                  </label>
                </div>
                {task.assignedBy === "Mentor Assigned" && (
                  <div className="mb-3">
                    <label htmlFor="instructor" className="additional-info-sub-heading mb-1">
                      Mentor<span className="text-danger">*</span>
                    </label>
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      options={instructorsdata?.instructorList?.map((option) => ({
                        value: option.name,
                        label: option.name,
                        id: option.id,
                      }))}
                      isSearchable={false}
                      onChange={(e) => onInstructorChange(e)}
                      value={
                        task.instructor
                          ? {
                              value: task.instructor,
                              label: task.instructor,
                            }
                          : null
                      }
                      placeholder="Select Instructor"
                    />
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="task-title" className="col-form-label form-title-names">
                    Task Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="task-title"
                    placeholder="Enter task name"
                    value={task.taskName}
                    onChange={(e) => {
                      setTask((prevState) => ({
                        ...prevState,
                        taskName: e.target.value,
                      }));
                    }}
                  />
                  {task.taskName.length > 0 && !isFieldValid(task.taskName, regEx.nameRegex) && <span style={{ color: "red", fontSize: "12px" }}>Please Enter a name only with letter and space, between 1 and 100 characters</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="col-form-label form-title-names">
                    Description<span style={{ color: "red" }}>* </span>
                  </label>

                  <textarea
                    className="form-control"
                    id="description"
                    rows={3}
                    placeholder="Enter description"
                    value={task.taskDescription}
                    onChange={(e) => {
                      setTask((prevState) => ({
                        ...prevState,
                        taskDescription: e.target.value,
                      }));
                    }}
                  ></textarea>
                  {task.taskDescription.length > 0 && !isFieldValid(task.taskDescription, regEx.descriptionRegex) && <span style={{ color: "red", fontSize: "12px" }}>Please Enter description only with letters, colon, comma and hyphen, between 50 and 500 characters</span>}
                </div>

                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="start-date" className="col-form-label form-title-names">
                        Start Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        minDate={new Date()}
                        selected={task.startDate}
                        popperPlacement="top-end"
                        onChange={(date) => {
                          setTask((prevState) => ({
                            ...prevState,
                            startDate: date,
                            endDate: new Date(task.endDate) >= new Date(date) ? new Date(task.endDate) : "",
                          }));
                        }}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="end-date" className="col-form-label form-title-names">
                        End Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        minDate={task.startDate != "" ? task.startDate : new Date()}
                        selected={task.endDate}
                        onChange={(date) => {
                          setTask((prevState) => ({
                            ...prevState,
                            endDate: date,
                          }));
                        }}
                        popperPlacement="top-end"
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="col-form-label form-title-names" htmlFor="technology-tag">
                    Technology Tag<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={() => {
                        handleDropdownToggle("techDropdown");
                      }}
                    >
                      <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Technology" value={state.techValue} disabled />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.techDropdown && (
                        <div className="ul-styling" style={{height:"10rem"}}>
                          <TechDropdown dispatch={dispatch} techDataComingChild={handleTechChange} selectedTechIdsfromChild={handleTechIdChange} selectedTechIds={state.selectedTechIds} technologyNames={state.selectedTechNames} selectedTech={""} />
                        </div>
                      )}
                    </div>
                  </div>
                  {state.selectedTechNames && state.selectedTechNames.length > 10 && <span style={{ color: "red", fontSize: "12px" }}>Maximum 10 technologies allowed</span>}
                </div>

                <div className="mb-3">
                  <label htmlFor="assigned-to" className="col-form-label form-title-names">
                    Assigned To<span style={{ color: "red" }}>*</span>
                  </label>

                  <div className="container border p-0">
                    <div className="input-with-button">
                      <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Assigned To" value={state.usersValue} disabled />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                          zIndex: 2,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDropdownToggle("userDropdown");
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.userDropdown && <ul
                        style={{
                          height: "10rem",
                        }}
                        className="ul-styling"
                      >
                        <UsersDropdown 
                        dispatch={dispatch} 
                        selectedBatches={props.selectedBatches}
                        usersDataComingChild={usersDataComingFrmChild} 
                        selectedUserIdsfromChild={handleUserIdChange} 
                        selectedUserIds={state.selectedUserIds} 
                        selectedUserName={state.selectedUserName} 
                        selectAllUsersCheck={state.selectAllChecked} 
                        selectedUsers={""} />
                      </ul>}
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-top-0">
              <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={(e) => handleClickClear(e)}>
                <span className="cancel-text">Cancel</span>
              </button>

              <button type="button" className="btn btn-primary save-button" data-bs-dismiss={areAllFieldsFilled() ? "modal" : ""} data-bs-target="#addTaskModal" onClick={(e) => handleSubmit(e)} disabled={!areAllFieldsFilled()}>
                <span className="save-text-field">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
