import { React, Skeleton } from "./../../UploadCoursesImport";

const EnrolledUserSkeleton = () => {
  return (
    <tr className="report-table-tr">
      <td style={{ verticalAlign: "middle", height: "40px" }}>
        <div className="name-column">
          <div className="tags justify-content-center">
            <div className="tag1">
              <Skeleton width={175} />
            </div>
          </div>
        </div>
      </td>
      <td className={`tag2 tag2-adjustment progress-height`}>
        <div>
          <div>
            <Skeleton width={350} />
          </div>
        </div>
      </td>
      <td className={`tag2 text-center tag2-adjustment td-height`}>
        <Skeleton />
      </td>
      <td className={`tag2 text-center tag2-adjustment td-height`}>
        <Skeleton />
      </td>
      <td className={`tag2 text-center tag2-adjustment td-height`}>
        <button className="btn p-0 m-0 border-0 " type="button">
          <Skeleton className="revoke-user-image" width={20} height={20} />
        </button>
      </td>
    </tr>
  );
};

export default EnrolledUserSkeleton;
