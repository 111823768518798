import { useState, EmptyTopTech, Pie,TopEnrolledCoursesSkeleton } from "../../AdminImport";
import "./TopEnrolledCourses.css";

const TopEnrolledCourses = (props) => {
    const {pData,isLoading} = props;

  const [selectedButton, setSelectedButton] = useState("thisMonth");

  const techDataWithCounts = {
    thisMonth: {},
    previousMonth: {},
  };

  if (pData) {
    Object.keys(pData).forEach((month) => {
      if (!techDataWithCounts[month]) {
        techDataWithCounts[month] = {};
      }
      pData[month]?.forEach((tech) => {
        techDataWithCounts[month][tech.subCategoryName] = tech.percentage;
      });
    });
  }
  

  const colorArray = ["#4670B6", "#5B9BD4", "#71AE47", "#EE7D31", "#B2B2B3"];
  const formatMonthData = (monthData, colorArray) => {
    return (
      monthData?.map((item, index) => ({
        subCategoryName: item.subCategoryName,
        enrolledCount: item.courseCount,
        color: colorArray[index % colorArray.length],
      })) || []
    );
  };

  const techDataWithColor = {
    thisMonth: formatMonthData(pData?.thisMonth || [], colorArray),
    previousMonth: formatMonthData(pData?.previousMonth || [], colorArray),
  };

  const data = {
    labels: [],
    datasets: [
      {
        radius: "90%",
        data: [],
        backgroundColor: colorArray,
        borderWidth: 2,
      },
    ],
  };

  if (selectedButton === "thisMonth" && techDataWithCounts.thisMonth) {
    data.labels = Object.keys(techDataWithCounts.thisMonth);
    data.datasets[0].data = Object.values(techDataWithCounts.thisMonth);
  } else if (selectedButton === "previousMonth" && techDataWithCounts.previousMonth) {
    data.labels = Object.keys(techDataWithCounts.previousMonth);
    data.datasets[0].data = Object.values(techDataWithCounts.previousMonth);
  }

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 17,
          boxHeight: 17,
          generateLabels: (chart) => {
            let array = techDataWithCounts ? Object.keys(techDataWithCounts) : [];
            return array?.map((val, ind) => {
              const dataset = chart.data.datasets[0];
              return {
                text: `${val} (${techDataWithCounts[val]}%)`,
                fontColor: "#343435",
                fillStyle: chart.data.datasets[0].backgroundColor[ind],
                lineCap: dataset.borderCapStyle,
                lineDash: dataset.borderDash,
                lineDashOffset: dataset.borderDashOffset,
                lineJoin: dataset.borderJoinStyle,
                strokeStyle: "#fff",
                pointStyle: dataset.pointStyle,
                rotation: dataset.rotation,
              };
            });
          },
          fontColor: "#343435",
          responsive: true,
          maintainAspectRatio: false,
          width: 300,
          height: 300,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const currentValue = dataset.data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];
            return `${label}: ${currentValue}%`;
          },
        },
      },
    },
  };

  return isLoading?(<TopEnrolledCoursesSkeleton/>):(
    <>
    <div className="w-100 h-100">
      <div className="top-enrolled-body d-flex ">
        <button
          className={`top-enrolled-left-btn ${
            selectedButton === "thisMonth" ? "top-enrolled-selected-btn" : "top-enrolled-non-selected-btn"
          } d-flex align-items-center justify-content-center border-0`}
          onClick={() => setSelectedButton("thisMonth")}
        >
          <p className="top-enrolled-text m-0">This Month</p>
        </button>
        <button
          className={`top-enrolled-rt-btn ${
            selectedButton === "previousMonth" ? "top-enrolled-selected-btn" : "top-enrolled-non-selected-btn"
          } d-flex align-items-center justify-content-center border-0`}
          onClick={() => setSelectedButton("previousMonth")}
        >
          <p className="top-enrolled-text m-0">Previous Month</p>
        </button>
      </div>
      <div>
        {selectedButton === "thisMonth" ? (
          techDataWithColor?.thisMonth?.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
              <EmptyTopTech className="top-enrolled-empty-top-tech" />
              <p className="fw-bold mt-3 mb-1 top-enrolled-empty-text">No course information right now!</p>
              <p className="top-enrolled-empty-fade-text">Active users not found on the portal.</p>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center w-100 top-enrolled-pie-height">
                <Pie data={data} options={options} />
              </div>
              <div className="top-enrolled-pie-val-wrapper">
                {techDataWithColor?.thisMonth?.map((val, index) => (
                  <div className="top-enrolled-pie-value" key={index}>
                    <div className="top-enrolled-pie-value-child">
                      <div className="top-enrolled-col-wrapper" style={{ backgroundColor: val?.color }} />
                      <div className="top-enrolled-val-text">{val?.subCategoryName}</div>
                    </div>
                    <div className="top-enrolled-val-text">{val?.enrolledCount}</div>
                  </div>
                ))}
              </div>
            </>
          )
        ) : techDataWithColor?.previousMonth?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center flex-column mt-5">
            <EmptyTopTech className="top-enrolled-empty-top-tech" />
            <p className="fw-bold mt-3 mb-1 top-enrolled-empty-text">No course information right now!</p>
            <p className="top-enrolled-empty-fade-text">Active users not found on the portal.</p>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center w-100 top-enrolled-pie-height">
              <Pie data={data} options={options} />
            </div>
            <div className="top-enrolled-pie-val-wrapper">
              {techDataWithColor?.previousMonth?.map((val, index) => (
                <div className="top-enrolled-pie-value" key={index}>
                  <div className="top-enrolled-pie-value-child">
                    <div className="top-enrolled-col-wrapper" style={{ backgroundColor: val?.color }} />
                    <div className="top-enrolled-val-text">{val?.subCategoryName}</div>
                  </div>
                  <div className="top-enrolled-val-text">{val?.enrolledCount}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
    </>
  );
};

export default TopEnrolledCourses;