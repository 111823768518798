import { useState, useEffect, useNavigate, axios, decryptData, handleApiError, Carousel, Skeleton } from "../../UserImport";
import "./InstructorsInfo.css";

const InstructorsInfo = () => {
  const [instructorData, setInstructorData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getInstructorData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getInstructorInfo`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setInstructorData(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  useEffect(() => {
    getInstructorData();
  }, []);

  return (
    <div className="d-grid gap-2 lms-instructor-info-container">
      <div className="d-flex justify-content-center lms-instructor-info-heading">Our Instructors</div>
      <div className="d-flex justify-content-center lms-instructor-info-subheading">Meet the dedicated instructors who bring their expertise ensuring you receive the best education possible.</div>
      <div className="d-flex justify-content-center">
        {isLoading ? <div className="user-lms-instructor-info-width">
            <div>
              <div className="d-flex lms-instructor-info">
                <div>
                <p>
                    <Skeleton className="d-flex" width={160} height={170}/>
                  </p>
                </div>
                <div className="d-grid align-items-center gap-2 p-3 w-100">
                  <div className="lms-instructor-info-desc"><Skeleton count={2}/></div>
                  <div>
                    <div className="lms-instructor-info-sign">
                      <Skeleton width={250}/>
                    </div>
                    <div className="lms-instructor-info-designation"><Skeleton width={150}/></div>
                  </div>
                </div>
              </div>
            </div>
        </div> : <Carousel className="user-lms-instructor-info-width" controls={false} indicators={true}>
          {instructorData?.map((data, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex lms-instructor-info">
                <div>
                <p className="user-lms-instructor-img">
                    {data?.firstName?.split(" ")
                      .map((name) => name.charAt(0).toUpperCase())
                      .join("")}
                    {data?.lastName?.split(" ")
                      .map((name) => name.charAt(0).toUpperCase())
                      .join("")}
                  </p>
                </div>
                <div className="d-grid align-items-center gap-2 p-3">
                  <div className="lms-instructor-info-desc" title={data?.description}>{truncateDescription(data?.description, 110)}</div>
                  <div>
                    <div className="lms-instructor-info-sign">
                      {data?.firstName || ""} {data?.lastName || ""}
                    </div>
                    <div className="lms-instructor-info-designation">{data.designation}</div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>}
      </div>
    </div>
  );
};

export default InstructorsInfo;
