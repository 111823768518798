import "./AddNewIdea.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useReducer } from "react";
import axios from "axios";
import EmptyProject from "../../EmptyStates/EmptyProject/MyIdea";
import { ReactComponent as ExpandMore } from "../../../../../Assets/expand_more.svg";
import { UserContext } from "../../../../../Context/Context";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TechDropdown from "../../../../AdminPortal/Task/AssignTask/TechDropdown";
import { decryptData } from "../../../UserImport"
import TechActionType from "../../../../_common/TechActionType";
import { SuccessfullVector, UserActionType, UsersDropdown } from "../../../../AdminPortal/AdminImport";
import CustomModal from "../../../../Utilities/CommonModal/CustomModal";
const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
      case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
      case "RESET_STATE":
        return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
  usersValue: "",
  selectedUserIds: [],
  selectedUserName: [],
  selectAllChecked: false,
};
const AddNewIdea = (props) => {
  const { idea } = useContext(UserContext);
  const navigate = useNavigate();
  const [nameError, setNameError] = useState(true);
  const [descError, setDescError] = useState(true);
  const [membersError, setMembersError] = useState(false);
  const [first, ...rest] = idea ?? [];
  const [projName, setProjName] = useState("");
  const [projDescription, setProjDescription] = useState("");
  const [memberNameInput, setMemberNameInput] = useState("");
  const [memberNames, setMemberNames] = useState({});
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const [projNameError, setProjNameError] = useState("");
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [isProjectDescriptionValid, setIsProjectDescriptionValid] = useState(false);
  const [isOpen, setIsOpen] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  let memberCount = 0;
  const loading = props.loading;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(', ');
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }

  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = state.selectedUserName.join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const handleClose = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };
  const handleClickClear = () => {
    setMemberNameInput("");
    setProjName("");
    setProjDescription("");
    setDropDown((prevState) => ({
      ...prevState,
      techDropdown: false,
      userDropdown: false,
    }));
    setProjNameError("");
    dispatch({ type: "RESET_STATE" });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleChangeProjNameError = (event) => {
    event.preventDefault();
    const name = event.target.value;
    setProjName(name);
    setIsProjectNameValid(name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/) ? true : false);
    if (!name || !name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const handleChangeProjDescriptionError = (event) => {
    event.preventDefault();
    const description = event.target.value;
    setProjDescription(description);
    setIsProjectDescriptionValid(
      description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/) ? true : false
    );
    if (!description || !description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/)) {
      setDescError(true);
    } else {
      setDescError(false);
    }
  };

  const truncate = (str, maxLength) => {
    if (str.length > maxLength) return str.slice(0, maxLength) + "...";
    else return str;
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setMemberNameInput(inputText);
    const memberNamesArray = inputText.split(",").map((name) => name.trim());
    setMembersError(memberNamesArray.length > 10);
    const membersObj = {};
    memberNamesArray.forEach((name, index) => {
      membersObj[`member${index + 1}`] = name;
    });
    isObjectEmpty(membersObj);
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/project-idea");
  };

  const isObjectEmpty = (object) => {
    const memberNamesArray = Object.values(object).filter(
      (value) => value.trim() !== ""
    );
    setMemberNames(memberNamesArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
    await axios
      .post(
        process.env.REACT_APP_API_URL_DOTNET + "/projectIdea/create",
        {
          name: projName,
          description: projDescription,
          techNames: state.selectedTechIds,
          memberNames: state.selectedUserIds,
          members: [],
          technologyUsed: []
        },
        {
          headers: {
            Authorization: `Bearer ${parsedObject["token"]}`,
          }
        }
      )
      .then((res) => {
        if(res.data.success === 200){
          setIsOpen(() => ({
            isModalOpen: true,
            modalType: "success",
            modalTitle: "Project Idea Added Successfully",
            modalMessage: "Project Idea has been Added Successfully",
            icon: <SuccessfullVector />,
          }));
          handleClickClear();
          props.setTaskVersion((prevVersion) => prevVersion + 1);
        }
        else {
          throw new Error(`Unexpected response status: ${res.status}`);
        }
      })
      .catch((err) => {
        alert(err.message)
      });
  };


  useEffect(() => {
    const memberArray = memberNameInput.split(",").map((text) => text.trim());
    const membersObj = {};
    memberArray.forEach((text, index) => {
      membersObj[`member${index + 1}`] = text;
    });
    isObjectEmpty(membersObj);
  }, [memberNameInput]);

  return (
    <>
      <div className="card-body pb-0">
        <div className="text-row-1">
          <p className="card-textt">
            Simply share your project ideas with us, and our experts will review
            it and provide feedback and guidance on how to take it to the next
            level.
          </p>
        </div>
        <div className="share-project">
          <div className="d-flex align-item-center justify-content-between mb-2 ">
            <div className="d-flex">
              <p className="text mb-0 fw-bold">Got Any Idea ?</p>
            </div>
            <button
              className="view-all fw-bold"
              onClick={(e) => handleClick(e)}
            >
              View All
            </button>
          </div>
        </div>
        {loading ? (
          <div className="recipe-row">
            <div className="recipe-text">
              <h5 className="fw-bold">
                <Skeleton width={252} />
              </h5>
              <p className="fw-normal mb-1">
                <Skeleton height={60} />
              </p>
              <div className="members-div pt-0">
                <div className="member mb pt-1 fw-bold mb-2">
                  <Skeleton width={84} height={16} />
                </div>
                <div className="project-members ml-0">
                  <div className="project-idea-members-skeleton">
                    <p className="name-of-members m-0">
                      <Skeleton
                        width={40}
                        height={40}
                        circle
                        highlightColor="#fff"
                      />
                    </p>
                  </div>
                  <div className="project-idea-members-skeleton">
                    <p className="name-of-members m-0">
                      <Skeleton
                        width={40}
                        height={40}
                        circle
                        highlightColor="#fff"
                      />
                    </p>
                  </div>
                  <div className="project-idea-members-skeleton">
                    <p className="name-of-members m-0">
                      <Skeleton
                        width={40}
                        height={40}
                        circle
                        highlightColor="#fff"
                      />
                    </p>
                  </div>
                  <div className="project-idea-members-skeleton">
                    <p className="name-of-members m-0">
                      <Skeleton
                        width={40}
                        height={40}
                        circle
                        highlightColor="#fff"
                      />
                    </p>
                  </div>
                  <div className="project-idea-members-skeleton">
                    <p className="name-of-members m-0">
                      <Skeleton
                        width={40}
                        height={40}
                        circle
                        highlightColor="rgba(40, 81, 158, 0.2)"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : idea?.length === 0 ? (
          <EmptyProject />
        ) : (
          <div className="recipe-row">
            <div className="recipe-text">
              <h5 className="fw-bold">{first?.name}</h5>
              <p className="fw-normal mb-1">
                {first?.description?.length > 100
                  ? truncate(first?.description, 80)
                  : first?.description}
              </p>
              <a
                href="#"
                className="view-all "
                onClick={(e) => handleClick(e)}
              >
                See More
              </a>
              <div className="members-div pt-0">
                {(first?.members && first?.members?.length > 0) && <div className="member fw-bold mb-2 mt-2">Members:</div>}
                <div className="project-members ml-0">
                  {first?.members?.length > 4 ? (
                    <>
                      {first?.members.slice(0, 4).map((member, index) => {
                        const initials = member?.name
                          .split(" ")
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase();
                        return (
                          <div className="project-idea-members" key={index}>
                            <p className="name-of-members" title={member?.name}>
                              {initials}
                            </p>
                          </div>
                        );
                      })}
                      <div className="count-of-members">
                        <p className="name-of-members" title={first?.members.slice(4).map((member) => {
                          return member.name
                        }).join(", ")}>
                          + {first?.members?.length - 4}
                        </p>
                      </div>
                    </>
                  ) : (
                    first?.members?.map((member, index) => {
                      const initials = member.name
                        .split(" ")
                        .map((word) => word[0])
                        .join("")
                        .toUpperCase();
                      return (
                        <div className="project-idea-members" key={index}>
                          <p className="name-of-members" title={member?.name}>
                            {initials}
                          </p>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="add-new-idea-container">
          <div
            className="add-new-idea "
            data-bs-toggle="modal"
            data-bs-target="#myIdeaModal"
          >
            <p className="project-p mb-0 fw-bold">
              <span>+</span> Add New Idea
            </p>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="myIdeaModal"
        tabIndex="-1"
        aria-labelledby="myIdeaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5 add-project-wrapper"
                id="myIdeaModalLabel"
              >
                Add New Idea
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClickClear}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    htmlFor="project-name"
                    className="col-form-label title-text"
                  >
                    Project Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={projName}
                    id="project-name"
                    placeholder="Enter Project Name"
                    onChange={handleChangeProjNameError}
                  />
                  {!isProjectNameValid && projName && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please enter a name with only letters and spaces, between
                      1 and 100 characters.
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="project-description"
                    className="col-form-label title-text"
                  >
                    Project Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    value={projDescription}
                    id="project-description"
                    placeholder="Write Here.."
                    onChange={(e) => handleChangeProjDescriptionError(e)}
                    rows={3}
                  ></textarea>
                  {!isProjectDescriptionValid && projDescription && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please Enter description only with letters, colon, comma and hyphen, between 150 and 750 characters.
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="technology-used"
                    className="col-form-label title-text"
                    required
                  >
                    Technology Used<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownToggle("techDropdown");
                      }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Technology"
                        value={state.techValue}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.techDropdown  && <ul
                        className="ul-styling"
                      >
                        <TechDropdown
                          dispatch={dispatch}
                          techDataComingChild={handleTechChange}
                          selectedTechIdsfromChild={handleTechIdChange}
                          selectedTechIds={state.selectedTechIds}
                          technologyNames={state.selectedTechNames}
                          selectedTech={""}
                        />
                      </ul>}
                    </div>
                  </div>
                  {state.selectedTechNames && state.selectedTechNames.length > 10 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Maximum 10 technologies allowed
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="Members(Optional)"
                    className="col-form-label title-text"
                  >
                    Members
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownToggle("userDropdown");
                      }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Members"
                        value={state.usersValue}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      {dropDown.userDropdown && <ul
                        className="ul-styling"
                      >
                        <UsersDropdown 
                        dispatch={dispatch} 
                        usersDataComingChild={usersDataComingFrmChild} 
                        selectedUserIdsfromChild={handleUserIdChange} 
                        selectedUserIds={state.selectedUserIds} 
                        selectedUserName={state.selectedUserName} 
                        selectAllUsersCheck={state.selectAllChecked} 
                        selectedUsers={""} 
                        />
                      </ul>}
                    </div>
                  </div>
                  {membersError && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please enter upto 10 members only
                    </span>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn cancel-button"
                data-bs-dismiss="modal"
                onClick={handleClickClear}
              >
                <span className="cancel-text"> Cancel </span>
              </button>
              <button
                type="button"
                class="btn btn-primary save-button"
                disabled={
                  nameError ||
                  descError ||
                  state.selectedTechNames.length === 0 ||
                  state.selectedTechNames.length > 10 ||
                  membersError || !isProjectNameValid
                }
                data-bs-target="#myIdeaModal"
                data-bs-dismiss={
                  !nameError && !descError && state.selectedTechNames.length !== 0
                    ? "modal"
                    : ""
                }
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                <span className="save-text"> Save </span>
              </button>
            </div>
          </div>
        </div>
        {isOpen.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: isOpen.icon,
              show: isOpen.isModalOpen,
              type: isOpen.modalType,
              title: isOpen.modalTitle,
              message: isOpen.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
      </div>
    </>
  );
};

export default AddNewIdea;
