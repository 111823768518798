import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjectDetailLoadingSkeleton = () => {
  return (
    <div className="ms-4">
      <div className="d-flex mt-4 " style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "35rem" }}>
          <h5 className="project-detail-name">
            <Skeleton width={500} height={20} />
          </h5>
        </div>
      </div>
      <p className="created-at">
        <Skeleton width={100} height={20} />
      </p>
      <p className="project-detail-text">
        <Skeleton count={2} />
      </p>
      <p className="project-detail-technology-used mb-2">
        <Skeleton width={200} height={20} />
      </p>
      <p className="project-detail-hosted-link">
        <Skeleton width={100} height={20} />
      </p>
      <p className="hosted-link-name">
        <Skeleton width={200} height={20} />
      </p>
      <div className="members-div pt-0">
        <div className="member fw-bold mb-2 mt-2">
          <Skeleton width={100} height={20} />
        </div>
        <div className="project-members ml-0 mt-4">
          {[...Array(5)].map((_, index) => (
            <div className="project-idea-members" key={index}>
              <Skeleton circle={true} width={30} height={30} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailLoadingSkeleton;
