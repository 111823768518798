import "./Modals.css";
import { useEffect, Select, useState, useRef, useNavigate, CloudImage, SuccessfullVector, CloseBtn, CSVIcon, CustomModal, axios, decryptData, handleApiError, AlertInfo, Download, DownloadIcon } from "../AdminImport";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "auto",
    margin: "auto",
    marginTop: "0.37rem",
    height: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    maxHeight: "12rem",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",

  }),
};

const DurQuesStyle = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "13rem",
    margin: "auto",
    marginTop: "0.37rem",
    height: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const initialState = {
  technologyId: "",
  technologyLabel: "",
  name: "",
  fileValue: "",
  question: "",
  duration: "",
  beginnerChecked: false,
  intermediateChecked: false,
  advancedChecked: false,
  fileError: "",
  error: false,
  filePath: "",
  level: "",
  file: null,
  progress: 0,
  modal: false
}
const AddNewSkillTest = (props) => {
  const { fetchExamList } = props;
  const [formData, setFormData] = useState(initialState);
  const [getApiData, setGetApiData] = useState({
    apiTechnology: [],
    skillLevel: [],
    loading: true,
  });
  const [modalData, setModalData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setGetApiData((prevData) => ({
          ...prevData,
          skillLevel: response.data.result,

          loading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  }
  const fetchTopics = async () => {
    const projUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Project/technology`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(projUrl, { headers });
      if (response.status === 200) {
        setGetApiData((prevData) => ({
          ...prevData,
          apiTechnology: response.data.result,
          loading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  useEffect(() => {
    fetchTopics();
    fetchLevel();
  }, []);

  const Validation = (field, regEx) => {
    let regExData = new RegExp(regEx);
    if (regExData.test(field)) {
      return true;
    }
    return false;
  }

  const handleFileChange = (selectedFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;

      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (fileExtension === "csv") {
        setFormData((prevState) => ({
          ...prevState,
          fileValue: contents,
          fileError: false
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          fileValue: contents,
          fileError: true
        }));
      }

    };
    reader.onerror = (e) => {
      setModalData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "Error inj reading file",
        icon: <AlertInfo />,
      }));
    };
    reader.readAsText(selectedFile);

    setFormData((prevState) => ({
      ...prevState,
      file: selectedFile,
      progress: 0,
      filePath: URL.createObjectURL(selectedFile)
    }));
  };

  const handleChangeName = (e) => {
    if (Validation(e.target.value, /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{0,100}$/) || e.target.value === "") {
      setFormData((prevState) => ({
        ...prevState,
        error: false,
        name: e.target.value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        error: true,
        name: e.target.value,
      }));
    }
  };

  const handleRemoveFile = () => {
    setFormData((prevState) => ({
      ...prevState,
      file: null,
      fileValue: "",
      filePath: "",
      progress: 0,
      fileError: false,
    }));
    fileInputRef.current.value = "";
  };

  const handleClickClear = (e) => {
    e.preventDefault();
    setFormData(initialState);
    handleRemoveFile();
  };

  const handleSave = async (e) => {
    const { technologyId, name, question, duration, level, filePath } = formData;
    if (technologyId.length === 0 || name.length < 2) {
      setModalData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "Please fill out the necessary details",
        icon: <AlertInfo />,
      }));
      return;
    }
    let body = new FormData();
    body.append("TechId", technologyId);
    body.append("LevelId", level);
    body.append("ExamName", name);
    body.append("NumberOfQuestion", question);
    body.append("DurationOfExam", duration);
    body.append("File", fileInputRef.current.files[0], filePath);

    const uploadUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Exam/uploadQuestion`
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${parsedObject["token"]}`
    }

    try {
      const response = await axios.post(uploadUrl, body, { headers });
      if (response.data.success === 200) {
        const flexRadioDefault1 = document.getElementById("flexRadioDefault1");
        if (flexRadioDefault1) {
          flexRadioDefault1.checked = false;
        }
        fileInputRef.current.value = "";
        fetchExamList();
        setModalData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: "New Skill Test Uploaded Successfully",
          modalMessage: "",
          icon: <SuccessfullVector />,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setModalData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: `Data not Uploaded: \n${error}`,
        icon: <AlertInfo />,
      }));
    }
  };

  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
    setFormData(initialState)
  }
  return (
    <div>
      <div
        className="modal fade"
        id="newSkillModal"
        tabIndex="-1"
        aria-labelledby="newSkillModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title modalheading-text"
                id="newSkillModalLabel"
              >
                Add New Skill Test
              </h5>
              <button
                type="button"
                className="cnsl-btn btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ fontSize: "1rem" }}
                onClick={(e) => handleClickClear(e)}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label
                    htmlFor="technology"
                    className="col-form-label form-title-names"
                  >
                    Technology<span className="req-1">*</span>
                  </label>
                  <Select
                    styles={customStyles}
                    isClearable={true}
                    onChange={(selectedOption) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        technologyId: selectedOption?.value,
                        technologyLabel: selectedOption?.label
                      }));
                    }}
                    options={
                      getApiData.apiTechnology &&
                      getApiData.apiTechnology.map((tech) => ({
                        value: tech.id,
                        label: tech.techName,
                      }))
                    }
                    value={
                      formData.technologyId
                        ? {
                          label: formData.technologyLabel,
                          value: getApiData.apiTechnology &&
                            getApiData.apiTechnology.find(option => option.name === formData.technologyLabel)?.id
                        }
                        : null
                    }

                    placeholder="Select Technology"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="col-form-label form-title-names"
                  >
                    Skill Test Name<span className="req-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Add Skill Test Name"
                    onChange={(e) => handleChangeName(e)}
                    value={formData.name}
                  />
                  {formData.error && <span className="formData-Error">Please Enter a name only with letter and space, comma, colon and hyphen between 1 and 100 characters</span>}
                </div>
                <span className="d-flex mb-2 form-title-names">
                  Level<span className="req-1">*</span>
                </span>
                <div className="d-flex">
                  <div className="form-check">
                    {getApiData.skillLevel && getApiData.skillLevel.map((level) => {

                      return (
                        <label key={level.id} className="beginner-form-label">
                          <input
                            className="form-check-input color-of-radio flexRadioDefault1"
                            type="radio"
                            name={level.name}
                            value={level.id}
                            onChange={() => {
                              setFormData((prevState) => ({
                                ...prevState,
                                level: level.id,
                                beginnerChecked: level.name.toLowerCase() === 'beginner',
                                intermediateChecked: level.name.toLowerCase() === 'intermediate',
                                advancedChecked: level.name.toLowerCase() === 'advanced',
                              }));
                            }}
                            checked={formData.level === level.id}
                          />
                          {level.name === "Advance" ? "Advanced" : level.name}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex justify-content-between sample-csv mt-4">
                  <span>Download Sample CSV Template</span>
                  <a href={process.env.REACT_APP_SAMPLE_QUIZ} className="fw-bold"><DownloadIcon /> Skill Test</a>
                </div>
                <span className="d-flex mt-4 mb-3 form-title-names">
                  Upload CSV<span className="req-1">*</span>
                </span>
                <div
                  className="upload-csv-text align-items-center justify-content-center"
                  onDrop={(e) => {
                    e.preventDefault();
                    handleFileChange(e.dataTransfer.files[0]);
                  }}
                  onDragOver={(e) => { e.preventDefault(); }}
                >
                  <div className=" mt-4">
                    <CloudImage />
                  </div>
                  <div className="mb-3">
                    <span className="drag-drop-text d-flex align-items-center justify-content-center fw-bold">
                      <p>Drag and drop here <br /> or click to select a file from your computer</p>
                    </span>
                  </div>
                  <div className="mb-4">
                    <div>
                      <div>
                        <input
                          type="file"
                          className="d-none"
                          ref={fileInputRef}
                          onChange={(e) => handleFileChange(e.target.files[0])}
                          accept=".csv"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                          className="add-new-skill-test-btn">
                          Browse from your computer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {formData.file && (
                  <div>
                    <div className="d-flex align-items-center ps-1 ast-search-wrapper">
                      <div className="progress-indicator-status">
                        {formData.file && (
                          <div className="d-flex align-items-center formData-svg">
                            <CSVIcon />
                            <div className="formData-File-Name">
                              {formData.file.name}
                            </div>
                          </div>
                        )}
                      </div>

                      <div

                        onClick={() => {
                          handleRemoveFile();
                        }}
                      >
                        <CloseBtn />
                      </div>
                    </div>
                  </div>
                )}
                {formData.fileError && (
                  <p className="formData-Error">
                    Please select only csv files.
                  </p>
                )}
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <label
                      htmlFor="questions"
                      className="col-form-label form-title-names pb-0"
                    >
                      Questions<span className="req-1">*</span>
                    </label>
                    <Select
                      styles={DurQuesStyle}
                      onChange={(selectedOption) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          question: selectedOption?.value || '',
                        }));
                      }}
                      value={formData.question ? { value: formData.question, label: formData.question } : null}
                      options={[
                        { value: '5', label: '5' },
                        { value: '10', label: '10' },
                        { value: '15', label: '15' },
                        { value: '20', label: '20' },
                        { value: '25', label: '25' },
                        { value: '30', label: '30' },
                      ]}
                      menuPlacement="auto"
                      placeholder="Select Questions"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="duration"
                      className="col-form-label form-title-names pb-0"
                    >
                      Duration<span className="req-1">*</span>
                    </label>
                    <Select
                      styles={DurQuesStyle}
                      onChange={(selectedOption) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          duration: selectedOption?.value || '',
                        }));
                      }}
                      value={formData.duration ? { value: formData.duration, label: `${formData.duration} mins` } : null}
                      options={[
                        { value: '10', label: '10 mins' },
                        { value: '15', label: '15 mins' },
                        { value: '20', label: '20 mins' },
                        { value: '25', label: '25 mins' },
                        { value: '30', label: '30 mins' },
                      ]}
                      menuPlacement="auto"
                      placeholder="Select Duration"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                id="skill-cancel-button"
                className="btn add-new-skill-test-cancel-btn"
                data-bs-dismiss="modal"
                onClick={(e) => handleClickClear(e)}
              >
                <span className="cancel-text">Cancel</span>
              </button>
              <button
                type="button"
                id="skill-save-button"
                className="btn save-button btn-primary"
                data-bs-dismiss="modal"
                disabled={
                  !formData.file ||
                  !formData.name ||
                  !formData.technologyId ||
                  !formData.duration ||
                  !formData.question ||
                  !formData.level ||
                  formData.error ||
                  formData.fileError
                }
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div >
      {modalData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: modalData.icon,
            show: modalData.isModalOpen,
            type: modalData.modalType,
            title: modalData.modalTitle,
            message: modalData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </div >
  );
};
export default AddNewSkillTest;