import React, { useEffect, useState, createContext, useContext } from "react";
import EmptySkillsAdded from "./EmptySkillsAdded/EmptySkillsAdded";
import { ReactComponent as GoldStar } from "../../../../Assets/Star-Icon-gold.svg";
import { ReactComponent as SilverStar } from "../../../../Assets/Star-Icon-silver.svg";
import { ReactComponent as BronzeStar } from "../../../../Assets/Star-Icon-bronze.svg";
import "./SkillsAdded.css";
import { ReactComponent as EmptyStar } from "../../../../Assets/emptystar.svg";
import SkillsAddedSkeleton from "./SkillsAddedSkeleton";
import { TestContext } from "../SkillManagement";
import { useNavigate } from "react-router-dom";
import { decryptData, axios, handleApiError } from "../../UserImport";

const SkillsAdded = () => {
  const { resultInfo, isLoading } = useContext(TestContext);
  const [apiTechnology, setApiTechnology] = useState([]);

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const navigate = useNavigate();
  useEffect(() => {
    fetchLevel();
  }, []);

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setApiTechnology(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  return (
    <>
      <div className="heading">
        <p>Skills Added</p>
      </div>
      {isLoading ? (
        <>
          <div
            className="card skill-added-card skill-card-innner"
          >
            <SkillsAddedSkeleton />
            <SkillsAddedSkeleton />
            <SkillsAddedSkeleton />
          </div>
        </>
      ) : (
        <div
          className="card skill-added-card skill-card-inner dp-scroll"
        >
          {(resultInfo && resultInfo?.length === 0) || resultInfo.every((element) => element.examScorePercentage < 80) ? (
            <EmptySkillsAdded />
          ) : (
            resultInfo &&
            resultInfo?.map((dataUsed, key) => (
              <div style={{ width: "17.9rem" }} key={key}>
                {dataUsed.examScorePercentage[0] >= 80 && (
                  <div
                    className="p-0"
                    style={{
                      maxHeight: "calc(100vh - 30vh)",
                      borderBottom: "1px solid #E9ECEB",
                    }}
                  >
                    <div className="row cards">
                      <div className="col-12 d-flex mainImg">
                        <img src={dataUsed.techImageLink} alt="" />
                        <p>{dataUsed.techName}</p>
                      </div>
                      <div className="d-flex p-0 stars">
                        <div
                          data-toogle="tooltip"
                          data-placement="bottom"
                          title={`No Of Attempts: ${dataUsed.examLevel[0] === apiTechnology[0]?.id ? dataUsed.noOfAttempt[0] : 0}`}
                          className={`col d-flex flex-column ${dataUsed.examLevel[0] === apiTechnology[0]?.id ? (dataUsed.examScorePercentage[0] >= 80 ? "block" : "grey") : "grey"}`}
                        >
                          <div className="my-spacing">
                            {dataUsed.examLevel[0] === apiTechnology[0]?.id ? dataUsed.examScorePercentage[0] >= 80 ? <BronzeStar style={{ fontSize: "3.125rem" }} /> : <EmptyStar /> : <EmptyStar />}
                          </div>

                          <p className="m-0">Beginner</p>
                          {dataUsed.examLevel[0] === apiTechnology[0]?.id ? (
                            dataUsed.examScorePercentage[0] >= 80 ? (
                              <p className="m-0 per">{dataUsed.examScorePercentage[0]}%</p>
                            ) : (
                              <p className="m-0">0%</p>
                            )
                          ) : (
                            <p className="m-0">0%</p>
                          )}
                        </div>

                        <div
                          data-toogle="tooltip"
                          data-placement="bottom"
                          title={`No Of Attempts: ${dataUsed.examLevel[1] == apiTechnology[1]?.id ? dataUsed.noOfAttempt[1] : 0}`}
                          className={`col d-flex flex-column ${dataUsed.examLevel[1] === apiTechnology[1]?.id ? (dataUsed.examScorePercentage[1] >= 80 ? "block" : "grey") : "grey"}`}
                        >
                          <div className="my-spacing">
                            {dataUsed.examLevel[1] === apiTechnology[1]?.id ? dataUsed.examScorePercentage[1] >= 80 ? <SilverStar style={{ fontSize: "3.125rem" }} /> : <EmptyStar /> : <EmptyStar />}
                          </div>
                          <p className="m-0">Intermediate</p>
                          {dataUsed.examLevel[1] === apiTechnology[1]?.id ? (
                            dataUsed.examScorePercentage[1] >= 80 ? (
                              <p className="m-0 per">{dataUsed.examScorePercentage[1]}%</p>
                            ) : (
                              <p className="m-0">0%</p>
                            )
                          ) : (
                            <p className="m-0">0%</p>
                          )}
                        </div>

                        <div
                          data-toogle="tooltip"
                          data-placement="bottom"
                          title={`No Of Attempts: ${dataUsed.examLevel[2] == apiTechnology[2]?.id ? dataUsed.noOfAttempt[2] : 0}`}
                          className={`col d-flex flex-column ${dataUsed.examLevel[2] === apiTechnology[2]?.id ? (dataUsed.examScorePercentage[2] >= 80 ? "block" : "grey") : "grey"}`}
                        >
                          <div className="my-spacing">
                            {dataUsed.examLevel[2] === apiTechnology[2]?.id ? dataUsed.examScorePercentage[2] >= 80 ? <GoldStar style={{ fontSize: "3.125rem" }} /> : <EmptyStar /> : <EmptyStar />}
                          </div>
                          <p className="m-0">Advance</p>
                          {dataUsed.examLevel[2] === apiTechnology[2]?.id ? (
                            dataUsed.examScorePercentage[2] >= 80 ? (
                              <p className="m-0 per">{dataUsed.examScorePercentage[2]}%</p>
                            ) : (
                              <p className="m-0">0%</p>
                            )
                          ) : (
                            <p className="m-0">0%</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default SkillsAdded;
