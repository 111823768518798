import { useState, useLocation, useMemo, useEffect, Player, FormatDate, ActivityDetailedSkeleton, Assignment, Header, SideNav, BreadCrumbs, decryptData, handleApiError, axios, useNavigate, PDFIcon, Video, Chevron, ReactModal, Form, PptIcon, UpArrow, Completed, CourseQuiz, Incorrect, View, Retry, ClockFill, ContentMenu, DOCIcon } from "../../UserImport";
import "./ActivityDetailed.css";


const ActivityDetailedView = () => {
    const [activityInfo, setActivityInfo] = useState({
        details: null,
        activityHistory: null,
        activityHistoryId: window.location.pathname.includes("course") ? sessionStorage.getItem('_historyId') : window.location.pathname.includes("techtalk") && sessionStorage.getItem('_historyId'),
        videoIndex: 0,
        playList: [],
        currentVideoSrc: null,
        activeModuleId: null,
        activityName: window.location.pathname.includes("course") ? "Course" : window.location.pathname.includes("techtalk") && "TechTalk",
        activityId: window.location.pathname.includes("course") ? sessionStorage.getItem('_activityId') : window.location.pathname.includes("techtalk") && sessionStorage.getItem('_activityId'),
        currentContentId: null,
        response: null,
        MappingId: null,
        isLoding: true,
        allTechTalk: null,
        assignmentDetails: null
    });
    const [isReadMoreModal, setIsReadMoreModal] = useState({
        showModal: false,
        selectedItems: null
    });
    const [quiz, setQuiz] = useState({
        isLoding: true,
        quizData: null,
        choosenAns: [],
        correctAns: [],
        isReset: undefined,
        isSubmited: false
    });
    const navigate = useNavigate();
    const location = useLocation();

    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const StartTechTalk = async (storeCourseId) => {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/startTechTalk?userId=${parsedObject["decoded"].Id}`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        const formData = new FormData();
        formData.append("techTalkId", storeCourseId);
        try {
            const response = await axios.post(url, formData, { headers });
            if (response.data.success === 201) {
                sessionStorage.setItem("_historyId", response.data.result);
                sessionStorage.setItem("_activityId", storeCourseId);
                window.location.reload();
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    };


    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Activity/userActivityDetails`;
        const body = {
            userId: parsedObject["decoded"].Id,
            activityName: activityInfo.activityName.toLocaleLowerCase(),
            activityId: activityInfo.activityId,    //course - tech talk id 
            activityHistoryId: activityInfo.activityHistoryId
        };
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        try {
            const response = await axios.post(url, body, { headers });
            if (response.data.success == 200) {
                if (activityInfo.activityName == "Course") {
                    // Extract relevant data for modules, content, and activity history
                    const { modules } = response.data.result.courseInfo;
                    const { activityHistory } = response.data.result;

                    // Sort modules by orderNumber and flatten
                    const sortedModules = modules.sort((a, b) => a.orderNumber - b.orderNumber);

                    // Flatten and sort moduleContent and contentAttachment within modules
                    const playList = sortedModules.flatMap((module) => {
                        // Sort moduleContent by orderNumber and flatten
                        const sortedModuleContent = module.moduleContent.sort((a, b) => a.orderNumber - b.orderNumber);
                        return sortedModuleContent.flatMap((content) => {
                            // Sort contentAttachment by orderNumber and map to playlist items
                            const sortedContentAttachment = content.contentAttachment.sort((a, b) => a.orderNumber - b.orderNumber);
                            return sortedContentAttachment.map((contentAttachment) => {
                                // Find corresponding historical details for the contentAttachment
                                const contentDetails = activityHistory.courseActivityHistories.contentDetails || [];
                                const matchingDetail = contentDetails.find((detail) => detail.moduleInfo && detail.moduleInfo.some((info) => info.contentId === contentAttachment.id));
                                let SeekDuration = "";
                                let isCompleted = false;
                                let moduleMappingId = undefined

                                if (matchingDetail) {
                                    const contentDetail = matchingDetail.moduleInfo.find((info) => info.contentId === contentAttachment.id);
                                    SeekDuration = contentDetail.seekDuration || "";
                                    isCompleted = contentDetail.completionStatus || false;
                                    moduleMappingId = contentDetail.moduleMappingId || undefined
                                }
                                // Construct the playlist item
                                return {
                                    contentTitle: contentAttachment.name,
                                    path: contentAttachment.fullPath.includes(".pptx") || contentAttachment.fullPath.includes(".ppt") ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(contentAttachment.fullPath)}&wdOrigin=BROWSELINK` : contentAttachment.fullPath,
                                    moduleId: module.id,
                                    contentId: contentAttachment.id,
                                    SeekDuration,
                                    isCompleted,
                                    moduleMappingId
                                };
                            });
                        });
                    }).filter((item) => item !== null);

                    let courseInfo = response.data.result.courseInfo
                    setActivityInfo((prevState) => ({
                        ...prevState,
                        details: courseInfo,
                        activityHistory: response.data.result.activityHistory.courseActivityHistories,
                        playList: playList,
                        isLoding: false,
                    }));
                } else {
                    let techtalkPlaylist = response.data.result.techtalkInfo.videoDetails.map((video) => {
                        let activityHistory = response.data.result.activityHistory;
                        let historyDetails = activityHistory.techTalkActivityHistories?.contentDetails || [];
                        let SeekDuration = null;
                        let isCompleted = false;

                        // Check if there are any history details
                        if (historyDetails.length > 0) {
                            // Find the matching videoId in the activity history
                            let contentDetail = historyDetails.find((detail) => detail?.videoId === video.videoId);

                            if (contentDetail) {
                                SeekDuration = contentDetail.seekDuration || "";
                                isCompleted = contentDetail.completionStatus || false;
                            }
                        }

                        return {
                            contentId: video.videoId,
                            contentTitle: video.videoName,
                            path: video.filePath.includes(".pptx") || video.filePath.includes(".ppt") ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(video.filePath)}&wdOrigin=BROWSELINK` : video.filePath,
                            SeekDuration: SeekDuration,
                            isCompleted: isCompleted,
                        };
                    });
                    setActivityInfo((prevState) => ({
                        ...prevState,
                        details: response.data.result.techtalkInfo || {},
                        activityHistory: response.data.result.activityHistory?.techTalkActivityHistories || [],
                        playList: techtalkPlaylist,
                        isLoding: false
                    }));
                }

            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    };

    useEffect(() => {
        fetchData();
        setQuiz({
            ...quiz,
            choosenAns: []
        });
    }, []);
    
    const removeSpecialCharacters = (filename, length = undefined) => {
        const fileExtension = filename.split('.').pop().toLowerCase();
        let icon;
        switch (fileExtension) {
            case "mp4":
                icon = <Video />
                break;
            case "pptx":
                icon = <PptIcon />
                break;
            case "pdf":
                icon = <PDFIcon />
                break;
            case "csv":
                icon = <CourseQuiz />
                break;
            case "docx":
                icon = <DOCIcon />
                break;
            default:
                break;
        }
        const fileNameWithoutExtension = filename.replace(/\.[^/.]+$/, "");
        let cleanFileName = fileNameWithoutExtension.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/gi, ' ');
        if (length) {
            if (cleanFileName.length > length) {
                cleanFileName = cleanFileName.slice(0, length) + '...';
            }
        }
        return { cleanFileName, icon };
    };

    const getAllTechTalk = async () => {
        let url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getAll?isArchived=1`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        try {
            const response = await axios.get(url, { headers });
            if (response.data.success == 200) {
                setActivityInfo({
                    ...activityInfo,
                    isloading: false,
                    allTechTalk: response.data.result,
                });
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response.status : null;
            handleApiError(statusCode, navigate);
        }
    };

    const handleQuizAns = (optionId, quizId, name) => {
        let ans = { optionId: optionId, questionId: quizId, optionName: name };
        setQuiz((prevState) => {
            const existingAnswer = prevState.choosenAns.find((answer) => answer.quizId === quizId);
            if (existingAnswer) {
                const updatedChoosenAns = prevState.choosenAns.map((answer) => {
                    if (answer.quizId === quizId) {
                        return { ...answer, optionId: optionId, optionName: name };
                    } else {
                        return answer;
                    }
                });
                return {
                    ...prevState,
                    choosenAns: updatedChoosenAns
                };
            } else {
                let updatedChoosenAns = prevState.choosenAns.filter(x => x.questionId != ans.questionId);
                return {
                    ...prevState,
                    choosenAns: [...updatedChoosenAns, ans]
                };
            }
        });
    };

    const handleQuizData = async () => {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Activity/getModuleQuiz?ModuleId=${activityInfo.activeModuleId}`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        try {
            const response = await axios.get(url, { headers });
            if (response.data.success == 200) {
                setQuiz({
                    ...quiz,
                    isLoding: false,
                    quizData: response.data.result,
                    isReset: undefined,
                    choosenAns: [],
                    isSubmited: response.data.result.some(x => x.selectedOption)
                });
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    }

    const submitQuiz = async () => {
        setQuiz({
            ...quiz,
            isSubmited: true
        });
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Activity/submitQuiz`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        const body = {
            ChoosenAns: quiz.choosenAns,
            courseId: activityInfo.activityId,
            moduleId: activityInfo.activeModuleId,
            contentAttachmentId: activityInfo.currentContentId,
            moduleActivityHistoryId: activityInfo.activityHistoryId,
        }
        try {
            const response = await axios.post(url, body, { headers })
            if (response.data.success == 200) {
                setQuiz({
                    ...quiz,
                    correctAns: response.data.result,
                    isSubmited: true
                });
                fetchData();
                handleQuizData();
            }

        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    }

    const selectVideo = (index, moduleId = undefined, mappingId = undefined, videoId = undefined, historyId = undefined, name = undefined) => {
        if (moduleId != undefined && !name.toString().includes(".csv")) {
            let contentLink = activityInfo.playList.filter(x => x.moduleId == moduleId)[--index];
            setActivityInfo((prevState) => ({
                ...prevState,
                videoIndex: index,
                activeModuleId: moduleId,
                currentVideoSrc: contentLink,
                currentContentId: contentLink.contentId,
                activityHistoryId: historyId,
                MappingId: mappingId
            }))
        } else if (name.toString().includes(".csv")) {
            let contentLink = activityInfo.playList.filter(x => x.moduleId == moduleId)[--index];
            setActivityInfo((prevState) => ({
                ...prevState,
                currentVideoSrc: contentLink,
                currentContentId: contentLink.contentId,
                MappingId: mappingId,
                activityHistoryId: historyId,
                activeModuleId: contentLink.moduleId
            }))
            handleQuizData();
        } else {
            let contentLink = activityInfo.playList[index];
            setActivityInfo((prevState) => ({
                ...prevState,
                videoIndex: index,
                currentContentId: videoId,
                MappingId: mappingId,
                activityHistoryId: historyId,
                currentVideoSrc: contentLink
            }));
        }
    };


    const handleModuleChange = (id) => {
        setActivityInfo((prevState) => ({
            ...prevState,
            activeModuleId: prevState.activeModuleId == id ? null : id,
        }));
    }

    const handlePlay = () => {
        const video = document.querySelector("video");
        if (video.requestFullscreen) {
            //video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen(); // Safari
        } else if (video.msRequestFullscreen) {
            video.msRequestFullscreen(); // IE/Edge
        }
    };

    const markContentComplete = (contentId) => {
        const SeekDuration = document.querySelector("#contentPlayer");
        let IsCompleted = SeekDuration?.currentTime == SeekDuration?.duration
        let markComplete = activityInfo.playList.find(x => x.contentId == contentId);
        if (markComplete)
            markComplete.isCompleted = IsCompleted;
        return;
    }
    const checkLockedContent = (content, index, activityType) => {
        if (!content) return false;
        const contentId = activityType.toLowerCase() === "course" ? content.id : content.videoId;
        const contentIndex = activityInfo.playList.findIndex(x => x.contentId === contentId);
        if (contentIndex === -1) return false;
        const prevContentIndex = index >= 0 ? contentIndex - 1 : -1;
        const prevContentData = prevContentIndex >= 0 ? activityInfo.playList[prevContentIndex] : null;
        const isContentLocked = activityInfo.playList[contentIndex]?.isCompleted;
        const isPrevContentLocked = prevContentData ? prevContentData.isCompleted : true;
        if (!isContentLocked && !isPrevContentLocked) {
            return false;
        }
        return true;
    }
    const handleReadMore = (item) => {
        setIsReadMoreModal((prevState) => ({
            ...prevState,
            selectedItems: item,
            showModal: true
        }));
    };

    const controlPlayBack = async (isDocMarkedCompleted = false) => {
        const SeekDuration = document.querySelector("#contentPlayer");
        let isCompleted = false;
        if (!isDocMarkedCompleted) {
            isCompleted = SeekDuration?.currentTime == SeekDuration.duration
            markContentComplete(activityInfo.currentContentId);
        }
        setActivityInfo({
            ...activityInfo,
            response: null
        })
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Activity/controlVideoSeek`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        };
        const body = {
            "userId": parsedObject["decoded"].Id,
            "activityName": activityInfo.activityName,
            "ModuleId": activityInfo.activeModuleId,
            "ContentMappingId": activityInfo.playList.find(mappingId => mappingId.contentId == activityInfo.currentContentId)?.moduleMappingId || "00000000-0000-0000-0000-000000000000",
            "ActivityHistoryId": activityInfo.activityHistoryId,
            "SeekDuration": SeekDuration?.currentTime.toString() || "",
            "IsCompleted": isCompleted || isDocMarkedCompleted,
            "ContentId": activityInfo.currentContentId
        }
        const response = await axios.post(url, body, { headers });
        if (response.data.success == 200) {
            setActivityInfo((prevState) => ({
                ...prevState,
                videoIndex: ++prevState.videoIndex,
                response: response.data.result
            }));
            fetchData();
        }
    }

    const shouldApplyContentViewerBg = (currentVideoSrc) => {
        if (currentVideoSrc == null) return 'content-viewer-bg';
        if (currentVideoSrc && !currentVideoSrc?.path?.includes(".csv") && !currentVideoSrc?.path?.includes(".pdf") && !currentVideoSrc?.path?.includes(".pptx") && !currentVideoSrc?.path?.includes(".ppt")) {
            return 'content-viewer-bg';
        }
        return '';
    }

    const disabledItems = (attachmentId, currentIndex, moduleId, moduleIndex = undefined) => {
        if (moduleIndex == undefined) { //tech talk disabled items 
            let currentPlaylistItem = activityInfo.playList.find(x => x.contentId === attachmentId);
            if (currentPlaylistItem.isCompleted) {
                return '';
            }
            if (activityInfo.playList[0].contentId == attachmentId) return '';

            let lastModuleIndex = activityInfo.playList.findIndex(x => x.moduleId == moduleId) - 1
            if (currentIndex == 0 && lastModuleIndex >= 0 && activityInfo.playList[lastModuleIndex].isCompleted) return '';

            let prevIndex = currentIndex - 1;
            if (prevIndex >= 0 && activityInfo.playList[prevIndex].isCompleted && activityInfo.playList[prevIndex].moduleId == moduleId) {
                return '';
            }
            return 'disable-items';
        } else {    //course disabled Items 
            if (currentIndex == 0 && moduleIndex == 0) return "";
            if (activityInfo.playList.find(x => x.contentId == attachmentId).isCompleted) {
                return '';
            } else {
                let moduleData = activityInfo.playList.filter(x => x.moduleId == moduleId);
                let previousItemIndex = activityInfo.playList.findIndex(x => x.contentId == attachmentId) - 1
                if (moduleData[--currentIndex]?.isCompleted || (activityInfo.playList[previousItemIndex]?.isCompleted && previousItemIndex > 0)) {
                    return ''
                } else {
                    return 'disable-items';

                }
            }
        }
    };

    const handleAssignmentClick = () => {
        const isAllcompleted = activityInfo.playList.find(x => x.isCompleted == false);
        if (isAllcompleted) {
            return true;
        }
        return true;
    }
    const selectAssignment = (assignmentSrc) => {
        setActivityInfo({
            ...activityInfo,
            currentVideoSrc: assignmentSrc,
            assignmentDetails: activityInfo.details.assignments[0]
        })
    }
    const aboutText = activityInfo?.details?.about || (activityInfo?.details?.instructorList[0]?.about || '');
    const displayAbout = aboutText.length > 50

    useEffect(() => {
        if (quiz.isReset) {
            const radioButtons = document.querySelectorAll("input[type='radio']");
            radioButtons.forEach((radio) => {
                radio.checked = false;
            });
        }
    }, [quiz.isReset]);

    useEffect(() => {
        const handleRouteChange = () => {
            sessionStorage.removeItem("_historyId");
            sessionStorage.removeItem("_activityId");
        };
        return () => {
            handleRouteChange();
        };
    }, [location.pathname]);

    useEffect(() => {
        if (activityInfo.activityName == "TechTalk" && activityInfo.playList.length !== 0 && activityInfo.playList[activityInfo.playList.length - 1].isCompleted) {
            getAllTechTalk();
        }
    }, [activityInfo.playList[activityInfo.playList.length - 1]?.isCompleted])

    return (
        <>
            <div>
                <Header />
                <SideNav />
            </div>
            <div className={`container-fluid mt-5  ${activityInfo.activityName.toLocaleLowerCase() == "techtalk" ? "primary-bg" : "activity-parent-wrapper-bg "}`}>
                <div className="row">
                    <div className="col-12">
                        <div className="ms-5 mt-4 mb-2 activityCrumb">
                            <BreadCrumbs />
                        </div>
                    </div>
                </div>
            </div>
            {activityInfo.isLoding ? <ActivityDetailedSkeleton activityType={activityInfo.activityName} /> :
                <div className="container-fluid activity-parent-wrapper activity-parent-wrapper-bg">
                    <div className={`d-flex flex-row justify-content-center  activity-wrapper mb-2 pb-2 gap-4 ${activityInfo.activityName.toLocaleLowerCase() == "techtalk" && "tech-talk-bg"} `}>
                        <div className="col-8 d-flex gap-3" >
                            <div className="col-2 activity-image-wrapper">
                                <img src={activityInfo.details?.thumbnailUri} alt="thumbnail" />
                            </div>
                            <div className="col-8 activity-content">
                                {activityInfo.details?.gradeType !== undefined && (
                                    <div className="activity-grade-wrapper">
                                        <ul typeof="disk" className="d-flex mt-2 mb-1 ps-0">
                                            <li>{activityInfo?.details?.gradeType}</li>
                                            <li className="mx-4">{activityInfo?.details?.levelName}</li>
                                        </ul>
                                    </div>
                                )}
                                <div className="actity-title" title={activityInfo.details?.title}>
                                    <h5>{activityInfo.details?.title?.length > 50 ?
                                        <>{activityInfo.details?.title.slice(0, 50)}<span> ...</span></> :
                                        activityInfo.details?.title}</h5>
                                </div>
                                <div className={`${activityInfo.activityName.toLocaleLowerCase() == "techtalk" ? "tt" : "activity-description"}`}>
                                    <p>{activityInfo.details?.description?.length > 200 ?
                                        <>{activityInfo.details?.description.slice(0, 200)}<span className="text-primary" onClick={() => { handleReadMore(activityInfo.details) }}> ...Read More</span></> :
                                        activityInfo.details?.description}
                                    </p>
                                </div>
                                <div className={`activity-tags d-flex ${activityInfo.details?.gradeType == undefined && "mt-3"}`}>
                                    {
                                        activityInfo.details.tags.length > 6 ?
                                            <>
                                                {activityInfo?.details?.tags?.slice(0, 6).map((tagName, index) => (
                                                    <span key={index}>{tagName?.techName}</span>
                                                ))}
                                                <span title={activityInfo?.details?.tags?.slice(6).map((tagName) => (" " + tagName?.techName))}>
                                                    +{activityInfo?.details?.tags?.slice(6).length} </span>
                                            </> : activityInfo?.details?.tags?.map((tagName, index) => (
                                                <span key={index}>{tagName?.techName}</span>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={`col-4 mentor-card-wrapper p-3 ${activityInfo.activityName.toLocaleLowerCase() == "techtalk" && "tech-talk-mentor-card"} `}>
                            <div className="mentor-info-wrapper d-flex gap-3">
                                <div className="mentor-img-wrapper">
                                    {
                                        activityInfo?.details?.imageUrl ?
                                            <img
                                                src={activityInfo?.details?.imageUrl}
                                                alt="thumbnail"
                                            /> : activityInfo?.details?.instructorName?.split(" ").slice(0).toString().slice(0, 1) + activityInfo?.details?.instructorName?.split(" ").slice(1).toString().slice(0, 1) ||
                                            activityInfo?.details?.instructorList[0]?.firstName.slice(0, 1) +
                                            activityInfo?.details?.instructorList[0]?.lastName.slice(0, 1)
                                    }
                                </div>
                                <div className="mentor-name-wrapper">
                                    <p className="my-0">
                                        {activityInfo?.details?.instructorName ||
                                            activityInfo?.details?.instructorList[0]?.firstName +
                                            " " +
                                            activityInfo?.details?.instructorList[0]?.lastName}
                                    </p>
                                    <p className="my-0">
                                        {activityInfo?.details?.designation || activityInfo?.details?.instructorList[0]?.designation}
                                    </p>
                                </div>
                            </div>
                            {displayAbout ? <div title={aboutText}>{aboutText.slice(0, 100)}<span>...</span></div> : <div title={aboutText}> {aboutText}</div>}
                        </div>
                    </div >
                    {
                        activityInfo.activityName.toLocaleLowerCase() == "techtalk" && (<div className="row ms-5 duration-wrapper">
                            <p className="mb-0">
                                Duration
                            </p>
                            <span>
                                {activityInfo.details.totalVideosLength}
                            </span>
                        </div>)
                    }

                    <div className="row d-flex ms-5 flex-row content-wrapper p-2 mb-3 dp-scroll" >
                        <div className={`col-8 p-0 content-viewer ${shouldApplyContentViewerBg(activityInfo.currentVideoSrc) ? 'content-viewer-bg' : ""}`}>
                            {activityInfo.currentVideoSrc && !activityInfo.currentVideoSrc?.path?.includes(".csv") ?
                                activityInfo.currentVideoSrc == "assignment" ?
                                    <Assignment assignmentDetails={activityInfo} /> :
                                    <Player
                                        poster={"activityInfo.details?.thumbnailUri"}
                                        activityInfo={activityInfo}
                                        onPlay={() => { handlePlay() }}
                                        onEnd={() => { controlPlayBack() }}
                                        onPause={() => { controlPlayBack() }}
                                        onDocMarkCompleted={() => { controlPlayBack(true) }}
                                    /> : activityInfo.currentVideoSrc && activityInfo.currentVideoSrc.path.includes(".csv") &&
                                <div className="col-8 p-0 quiz-wrapper ">
                                    {
                                        !quiz.isReset && quiz.quizData && quiz.quizData.some(x => x.selectedOption != null) &&
                                        <div className="reset-quiz-detailed-view mb-2  d-flex  justify-content-between align-items-center">
                                            <p className="mb-0">
                                                <span>
                                                    <Retry />
                                                </span>&nbsp;&nbsp;
                                                Are you interested in giving the quiz another shot?
                                            </p>
                                            <button className="btn retry-btn me-3" onClick={() => {
                                                setQuiz({
                                                    ...quiz,
                                                    isReset: true,
                                                    isSubmited: false
                                                })
                                            }}>Retry</button>
                                        </div>
                                    }
                                    {
                                        quiz.isLoding == false && quiz.quizData && quiz.quizData.map((quizinfo, index) => (
                                            <div key={quizinfo.questionId}>
                                                <h5 className="activity-quiz">{index + 1}. &nbsp;{quizinfo.questionText}</h5>

                                                {quizinfo.options.map((option, optionIndex) => (
                                                    <div key={optionIndex} className="option-container">
                                                        <input type="radio" id={option.optionId} name={`quiz_${index}`} onClick={() => {
                                                            handleQuizAns(option.optionId, quizinfo.questionId, option.optionText)
                                                        }} defaultChecked={quizinfo.selectedOption === option.optionId} disabled={quiz.isSubmited} /> &nbsp;
                                                        <label htmlFor={option.optionId}>{option.optionText}</label>
                                                    </div>
                                                ))}
                                                {
                                                    !quiz.isReset && quiz.quizData && quiz.quizData.some(x => x.selectedOption != null) ? (
                                                        quizinfo.wasMarkedCorrect !== null ? (
                                                            quizinfo.wasMarkedCorrect == 1 ? (
                                                                <div className="option-marker option-marker-correct">
                                                                    <span>
                                                                        <Completed />&nbsp;Correct
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="option-marker option-marker-incorrect">
                                                                    <span>
                                                                        <Incorrect />&nbsp;Incorrect
                                                                    </span>
                                                                </div>
                                                            )
                                                        ) : <div className="option-marker option-marker-incorrect">
                                                            <span>
                                                                <Incorrect />&nbsp;Incorrect
                                                            </span>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        (quiz.quizData && quiz.quizData.some(x => x.selectedOption == null)) ?
                                            <button className="btn btn-primary mt-3" disabled={quiz.choosenAns?.length != quiz.quizData?.length} onClick={() => { submitQuiz() }}>Submit</button> :
                                            quiz.isReset && <button className="btn btn-primary mt-3" disabled={quiz.choosenAns?.length != quiz.quizData?.length} onClick={() => { submitQuiz() }}>Submit</button>
                                    }
                                </div>
                            }
                        </div>

                        <div className="col-4 module-wrapper m-3 p-0 activity-scroll">
                            {
                                activityInfo.activityName.toLocaleLowerCase() == "course" &&
                                <div className="d-flex justify-content-around  align-content-center module-start-end">
                                    <div className="d-flex flex-column ">
                                        <span><ClockFill />&nbsp;&nbsp;Start Date </span>
                                        <span>{FormatDate(activityInfo.activityHistory?.startDate)}</span>
                                    </div>
                                    <div className="mt-2">
                                        -------
                                    </div>
                                    <div className="d-flex flex-column ">
                                        <span><ClockFill />&nbsp;&nbsp;Estimated End Date </span>
                                        <span>{FormatDate(activityInfo.activityHistory?.endDate)}</span>
                                    </div>
                                </div>
                            }

                            {activityInfo.details &&
                                activityInfo.details.hasOwnProperty("videoDetails") &&
                                activityInfo.details.videoDetails ? (
                                <>
                                    <>
                                        <div div className="module my-2">
                                            <div className="module-name-wrapper">
                                                {Array.isArray(activityInfo.details.videoDetails) &&
                                                    activityInfo.details.videoDetails.length ? (
                                                    <>
                                                        <p className="mb-0 d-flex justify-content-between" >Video <span><UpArrow /></span></p>
                                                        <p className="mb-0">
                                                            <ContentMenu />&nbsp;{activityInfo.details.videoDetails.length} Content
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p className="mb-0">No content available</p>
                                                )}
                                            </div>
                                        </div>
                                        {Array.isArray(activityInfo.details.videoDetails)
                                            ? activityInfo.details.videoDetails.map((video, index) => (
                                                <div
                                                    className="module-content-wrapper d-flex justify-content-center my-2"
                                                    onClick={() => {
                                                        let historyDetails = activityInfo.activityHistory.contentDetails && activityInfo.activityHistory.contentDetails.find(x => x.videoId == video.videoId)
                                                        checkLockedContent(video, index, activityInfo.activityName) &&
                                                            selectVideo(index, undefined, historyDetails?.mappingId, video.videoId, activityInfo.activityHistory.activityHistoryId, video.videoName)
                                                    }}
                                                    key={index}
                                                >
                                                    <div className={`content ${disabledItems(video.videoId, index)}`} title={removeSpecialCharacters(video.videoName).cleanFileName}>
                                                        {video.orderNumber}.&nbsp;&nbsp;
                                                        {removeSpecialCharacters(video.videoName).icon}&nbsp;{removeSpecialCharacters(video.videoName, 33).cleanFileName}
                                                        <span className="float-end">
                                                            {activityInfo.activityHistory?.contentDetails?.some(details => {
                                                                return details.videoId === video.videoId && details.completionStatus;
                                                            }) && <Completed />}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                            : null}
                                    </>
                                    <div className="row flex-column gap-2">
                                        {
                                            activityInfo.allTechTalk ? (
                                                activityInfo.allTechTalk.filter(techtalk => techtalk.id !== sessionStorage.getItem("_activityId")).filter(techTalk => techTalk.isPublished).length === 0 ? (
                                                    <span className="text-center">No more tech talk found! <br /> we will inform you as soon as it arrive </span>
                                                ) : (
                                                    activityInfo.allTechTalk
                                                        .filter(techtalk => techtalk.id !== sessionStorage.getItem("_activityId"))
                                                        .sort(() => Math.random() - 0.5)
                                                        .slice(0, 5)
                                                        .map((techTalk, index) => (
                                                            <div className="col" key={index}>
                                                                <div className="card">
                                                                    <div className="card-body tech-talk-player-wrapper">
                                                                        <div className="views-details">
                                                                            <View /> {techTalk.viewsCount}
                                                                        </div>
                                                                        <p className="tech-talk-title mb-1">{techTalk.instructorName}</p>
                                                                        <h1 className="card-text">
                                                                            {techTalk.title.length > 51 ? `${techTalk.title.slice(0, 50)}...` : techTalk.title}
                                                                        </h1>
                                                                        <p className="card-text">
                                                                            {techTalk.description.length > 50 ? `${techTalk.description.slice(0, 50)}...` : techTalk.description}
                                                                        </p>
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span className="duration">
                                                                                <ClockFill /> {techTalk.totalVideosLength}
                                                                            </span>
                                                                            <button className="btn btn-primary" onClick={() => {
                                                                                sessionStorage.removeItem("_historyId");
                                                                                StartTechTalk(techTalk.id)
                                                                            }}>Play</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                )
                                            ) : null
                                        }
                                    </div>
                                </>
                            ) : activityInfo.details &&
                            activityInfo.details.hasOwnProperty("modules") &&
                            activityInfo.details.modules.sort((a, b) => a.orderNumber - b.orderNumber).map((module, moduleIndex) => (
                                <>
                                    <div className="my-2 pointer" key={module.id}>
                                        <div className="module-name-wrapper" onClick={() => {
                                            // Incase want to lock module in  Future 

                                            // const uniqueModuleIds = new Set(activityInfo.playList.map(item => item.moduleId));
                                            // const uniqueModuleIdsArray = [...uniqueModuleIds];
                                            // const currentIndex = uniqueModuleIdsArray.indexOf(module.id);
                                            // const prevModuleId = currentIndex > 0 ? uniqueModuleIdsArray[currentIndex - 1] : null;
                                            // const lastItemWithModuleId = activityInfo.playList.find(item => item.moduleId === prevModuleId);

                                            // if (currentIndex === 0 || (prevModuleId && (lastItemWithModuleId.IsCompleted || activityInfo.activityHistory.contentDetails.some(x => x.moduleId === prevModuleId && x.moduleCompletionStatus)))) {
                                            handleModuleChange(module.id);
                                            // }

                                        }}>
                                            <p className="mb-0 d-flex justify-content-between" title={removeSpecialCharacters(module.name).cleanFileName}>
                                                <span>{module.orderNumber}.&nbsp;&nbsp;{removeSpecialCharacters(module.name, 33).cleanFileName}</span>
                                                {
                                                    activityInfo.activeModuleId == module.id ? <span><UpArrow /></span> : <span><Chevron /></span>
                                                }
                                            </p>
                                            {Array.isArray(module.moduleContent) &&
                                                module.moduleContent.length ? (
                                                <>
                                                    <p className="mb-0">
                                                        <ContentMenu />&nbsp;{module.moduleContent.length} Content
                                                    </p>
                                                </>

                                            ) : (
                                                <p className="mb-0">No content available</p>
                                            )}
                                        </div>
                                        {activityInfo.activeModuleId == module.id && module.moduleContent.sort((a, b) => a.orderNumber - b.orderNumber).map((content, contentAttachmentIndex) => {
                                            return content.contentAttachment.sort((a, b) => a.orderNumber - b.orderNumber).map((attachment) => (
                                                <div className="module-content-wrapper d-flex justify-content-center my-2"
                                                    onClick={() => {
                                                        checkLockedContent(attachment, contentAttachmentIndex, activityInfo.activityName) &&
                                                            selectVideo(attachment.orderNumber, module.id, undefined, undefined, activityInfo.activityHistory.activityHistoryId, attachment.name)
                                                    }}
                                                    key={attachment.orderNumber}
                                                >
                                                    <div className={`content ${disabledItems(attachment.id, contentAttachmentIndex, module.id, moduleIndex)}`} title={removeSpecialCharacters(attachment.name).cleanFileName}>
                                                        {removeSpecialCharacters(attachment.name).icon}&nbsp;{removeSpecialCharacters(attachment.name, 30).cleanFileName}
                                                        <span className="float-end">
                                                            {activityInfo.activityHistory.contentDetails && activityInfo.activityHistory.contentDetails.some(details => {
                                                                return details.moduleInfo?.some(info => info.contentId == attachment.id && info.completionStatus);
                                                            }) && <Completed />}
                                                        </span>
                                                    </div>
                                                </div>
                                            ));

                                        })}
                                    </div >
                                </>
                            ))}
                            {
                                activityInfo.details.assignments && activityInfo.details.assignments.map((assignment) => (
                                    <div className="my-2 pointer" key={assignment.id}>
                                        <div className="module-name-wrapper" onClick={() => {
                                            handleModuleChange(assignment.id);
                                        }}>
                                            <p className="mb-0 d-flex justify-content-between ">
                                                <span>{removeSpecialCharacters(assignment.title, 40).cleanFileName}</span>{
                                                    activityInfo.activeModuleId == module.id ? <span><UpArrow /></span> : <span><Chevron /></span>
                                                }
                                            </p>
                                            <p>{assignment.assignmentAttachment.length}&nbsp;Assignment</p>
                                        </div>
                                        {activityInfo.activeModuleId === assignment.id && activityInfo.details.assignments.map((content, contentAttachmentIndex) => {
                                            return content.assignmentAttachment.map((attachment) => (
                                                <div key={content.id} className="module-content-wrapper d-flex justify-content-center my-2">
                                                    <div className="content" onClick={() => {
                                                        handleAssignmentClick(contentAttachmentIndex) &&
                                                            selectAssignment("assignment")
                                                    }} title={attachment.name}>
                                                        <p className="mb-0 "><span className="assignment-icon">{removeSpecialCharacters(attachment.name).icon}</span>&nbsp;&nbsp;{removeSpecialCharacters(attachment.name, 33).cleanFileName} <span className="float-end">{attachment.isCompleted && <Completed />}</span> </p>
                                                    </div>
                                                </div>
                                            ));
                                        })}
                                    </div>
                                ))
                            }
                        </div>
                    </div >
                </div >}

            <ReactModal size="lg" dialogClassName="modal-90w" centered show={isReadMoreModal.showModal}
                onHide={() => setIsReadMoreModal((prevState) => ({
                    ...prevState,
                    showModal: false
                }))}>
                <ReactModal.Header
                    closeButton
                    style={{
                        borderBottom: "0.063rem solid var(--bs-modal-header-border-color)",
                    }}
                >
                    <ReactModal.Title className="h6">{activityInfo.activityName} Info</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                    <p>
                        <span className="fw-bold">Title :</span> <span className="opacity-75">{isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.title}</span>
                    </p>

                    <Form.Group>
                        <Form.Label>
                            <span className="fw-bold">Description : </span>
                        </Form.Label>
                        <Form.Control className="opacity-75" disabled as="textarea" style={{ fontSize: "0.813rem" }} defaultValue={isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.description} rows={4} cols={60}></Form.Control>
                    </Form.Group>
                </ReactModal.Body>
            </ReactModal>
        </>
    );
};

export default ActivityDetailedView;
