import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjectLoadingSkeleton = () => {
  return (
    <div className="all-project-names border-end">
      <div className="child-wrapper-1">
        {[...Array(5)].map((_, index) => (
          <div className="project-names-wrapper-skeleton mt-3 pb-0 d-flex justify-content-between" key={index}>
            <div className="d-flex justify-content-between w-100">
              <h5 className="project-names">
                <Skeleton width={200} height={20} />
              </h5>
              <span className="click-arrow">
                <Skeleton width={20} height={20} />
              </span>
            </div>
            <div className="task-deadline d-flex">
              <Skeleton circle={true} width={20} height={20} />
              <p className="mb-2 mx-2">
                <Skeleton width={100} height={20} />
              </p>
            </div>
            <div>
              <p className="project-text flex-grow-1">
                <Skeleton count={3} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectLoadingSkeleton;
