import React, { useState, useEffect } from "react";
import Select from "react-select";

const LearningTypeDropDown = ({ dropdownfunc }) => {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "13rem",
      margin: "auto",
      height: "2.5rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      overflow: "visible",
    }),
    menu: (provided) => ({
      ...provided,
      margin: "auto",
      maxHeight: "13rem",
      overflow: "scroll",
      zIndex: 999
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightGrey" : "white",
      color: state.isSelected ? "black" : "black",
    }),
  };
  const learningTypeOptions = [
    "CG Learning Videos",
    "Self Learning",
    "Mentor Assigned Task",
    "Project",
    "Session",
  ];
  const [learningType, setLearningType] = useState("");

  useEffect(() => {
    dropdownfunc(learningType); //Passing learningType from here to DailyUpdateTable to set the value of dropdownFilterValue
  }, [learningType]);

  return (
    <div className="container">
      <form className="d-flex">
        <Select
          styles={customStyles}
          options={learningTypeOptions.map((option) => ({
            value: option,
            label: option,
          }))}
          isDisabled={false}
          isClearable={true}
          isSearchable={false}
          className="m-0 pos-6"
          id="learning"
          aria-label=""
          onChange={(event) => {
            if (event == null) {
              return setLearningType("");
            }
            setLearningType(event.value);
          }}
          value={
            learningType ? { value: learningType, label: learningType } : null
          }
          placeholder="Select Learning Type" // Placeholder text
        />
      </form>
    </div>
  );
};

export default LearningTypeDropDown;
