import {Skeleton} from "../MentorImport"

const MentorTaskDetailsSkeleton = () => {
  return (
    <div className="task-detail-wrapper">
      <div className="mentortask-heading-style">
        <Skeleton width={200} />
      </div>
      <div
        className="mentortask-description-style mb-6"
      >
        <Skeleton />
      </div>
      <div className="mentortask-duration-wrapper">
        <div className="mentortask-meta-heading">
          <Skeleton width={80} />
        </div>
        <div className="mentortask-duration-style">
          <Skeleton width={150} />
        </div>
      </div>
      <div className="mentortask-technology-wrapper">
        <div className="mentortask-meta-heading">
          <Skeleton width={100} />{" "}
        </div>
        <div className="tech-used-wrapper">
          <div>
            <Skeleton
              width={80}
              height={30}
              highlightColor="#d3e1fa"
              count={4}
              inline
              className="me-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorTaskDetailsSkeleton;
