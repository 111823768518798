import { axios, useNavigate, decryptData, handleApiError, useState, useEffect, Select, components } from "../.././AdminImport";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "10rem",
    marginTop: "0.37rem",
    maxHeight: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: "12rem",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2.5rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const CheckboxOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex",
    gap: "0.6rem"
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
      <input type="checkbox" checked={isSelected} onChange={() => { }} />&nbsp;
      {children}
    </components.Option>
  );
};

const SelectedTech = (props) => {
  const { handleSelectTech } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [techOptions, setTechOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTechOptions();
  }, []);

  useEffect(() => {
    handleSelectTech(selectedOptions);
  }, [selectedOptions]);

  const fetchTechOptions = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Project/technology`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      const sortedOptions = response.data?.result.sort((a, b) => a.techName.localeCompare(b.techName)).map((tech) => ({ label: tech.techName, value: tech.techName }));
      setTechOptions(sortedOptions);
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleCheckboxChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  return (
    <div className="report-drop-down-tech">
      <Select
        isMulti
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleCheckboxChange}
        options={techOptions}
        components={{
          Option: CheckboxOption,
        }}
        className="m-0"
        menuShouldBlockScroll
        placeholder="Select Tech."
      />
    </div>
  );
};

export default SelectedTech;
