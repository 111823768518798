import { ArrowForwardIcon, Skeleton, TotalEnrolledUsers } from "../../AdminImport";
import "./EnrolledUsers.css"

const EnrolledUserSkeleton = () => {
  return (
<>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex pointer">
            <p
              className="d-flex mb-0 align-items-center enrolled-users-head total-courses-head" 
            >
              Total Enrolled Users
            </p>
            <span className="d-flex align-items-start mx-1">
              <ArrowForwardIcon />
            </span>
            </div>
            <span>
              <TotalEnrolledUsers />
            </span>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center meu-6">
            <Skeleton height={30} width={140}/>
        </div>
      </div>
    </>  )
}

export default EnrolledUserSkeleton