import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const PieChartSkeleton = () => {
  return (
    <div className="w-100 h-100">
      <div>
        <div className="d-flex justify-content-center w-100 ">
          <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5">
            <div className="mt-3   overflow-hidden pie-chart-skeleton">
              <Skeleton width={200} height={200} circle={true} />
            </div>{" "}
          </SkeletonTheme>
        </div>
        <div className="top-enrolled-pie-val-wrapper mt-2">
          {Array.from({ length: 5 }).map((index) => (
            <div className="top-enrolled-pie-value" key={index}>
              <div className="top-enrolled-pie-value-child">
                <div className="top-enrolled-col-wrapper ms-1">
                  <Skeleton width={30} height={30} />
                </div>
                <div className="top-enrolled-val-text ms-2 mt-3">
                  <Skeleton width={80} />
                </div>
              </div>
              <div className="top-enrolled-val-text me-2 mt-3">
                <Skeleton width={30} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartSkeleton;
