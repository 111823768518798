import { useState, useNavigate, GradeAssignmentTableSkeleton, EmptyGradeAssignmentTable } from "../MentorImport"

const GradeAssignmentTableSection = (props) => {
  const { userList, isLoading } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);

  const totalPaginationPages = Math.ceil(userList?.length / resultsPerPage);
  const arrayStartIndex = (currentPage - 1) * resultsPerPage;
  const arrayEndIndex = arrayStartIndex + resultsPerPage;
  const arrayCurrentResults = userList?.slice(arrayStartIndex, arrayEndIndex);
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const handlePrevPageChange = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };
  const handleNextPageChange = () => {
    if (currentPage !== totalPaginationPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="mb-3">
      <div className="col-12 daily-update-table-style-grade-assignment p-0">
        <div className="table-responsive" style={{ overflow: "visible" }}>
          <table className="table table-striped" id="taskTable">
            <thead className="dut-head">
              <tr className="border-bottom-0">
                <th className="grade-assign-column-id daily-update-table-head">#</th>
                <th className="grade-assign-column-name daily-update-table-head">Name</th>
                <th className="grade-assign-column-date daily-update-table-head">Submission Date</th>
                <th className="grade-assign-column-status daily-update-table-head text-center">Status</th>
              </tr>
            </thead>
            <tbody className="mb-3">
              {isLoading ? (
                <>
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                  <GradeAssignmentTableSkeleton />
                </>
              ) : arrayCurrentResults === undefined || arrayCurrentResults?.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <EmptyGradeAssignmentTable />
                  </td>
                </tr>
              ) : (
                arrayCurrentResults?.map((item, index) => {
                  return (
                    <tr className="grade-assign-table-row border-bottom" key={index}>
                      <td>{arrayStartIndex + index + 1}</td>
                      <td className="">
                        <p
                          className="mb-0 grade-assign-name-text d-flex justify-content-start align-items-center"
                          onClick={() => {
                            sessionStorage.setItem("userDetails", JSON.stringify(item));
                            sessionStorage.setItem("activeToggle","cgu")
                            navigate("/mentor/gradeAssignment");
                          }}
                        >
                          {item.firstName} {item.lastName}
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 grade-assign-date-text d-flex justify-content-start align-items-center">{dateFormat(item.submissionDate)}</p>
                      </td>
                      <td className="d-flex justify-content-center align-items-center border-bottom-0">
                        <p className="status-pending mb-0">Pending</p>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
            <tfoot className="dut-foot">
              <tr>
                <td className="fw-bold" colSpan={2}>
                  Total Items: {userList?.length}
                </td>
                <td colSpan={2}>
                  <div className="d-flex justify-content-end" style={{ color: "#706F73" }}>
                    <div className="d-flex align-items-center">
                      <p className="me-2 mb-0" style={{ whiteSpace: "nowrap" }}>
                        Results per page
                      </p>
                      <select style={{ color: "#706F73" }} className="form-select m-2" value={resultsPerPage} onChange={handleResultsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                      <ul className="pagination mb-0">
                        <li className={`page-item me-1 ${currentPage === 1 ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handlePrevPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 1.5L1.5 7L7 12.5" stroke={currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                        {Array.from({ length: totalPaginationPages }, (_, i) => i + 1).map((page) => (
                          <li key={page} className={`page-item me-1 ${page === currentPage ? "active" : ""}`}>
                            <button className="page-link rounded pagination-styling" onClick={() => handlePageChange(page)}>
                              {page}
                            </button>
                          </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handleNextPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 12.5L6.5 7L1 1.5" stroke={currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GradeAssignmentTableSection;
