import { Header, SideNav, CourseUploadHistory, useEffect, axios, handleApiError, decryptData, useNavigate, useState, EnrolledUsers, TotalCoursesFile, CertificationsAchievedFile, TechTalkSession, TopEnrolledCourses, TechTalkSessionSkeleton, TotalCoursesSkeleton, WallOfFame, EnrolledUserSkeleton } from "../AdminImport";

import "./LMSDashboard.css";


const AdminLMSDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalCourses: undefined,
    enrolledUsers: undefined,
    courseUploadData: undefined,
    topEnrolledData: undefined,
    techTalkSessionCount: 0,
    userMilestoneCount: undefined,
    wallOfFame: undefined,
    loading: true
  });
  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const getLMSDashboardData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/LMSDashboard`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      "Content-Type": "multipart/form-data",
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        const filteredData = response.data.result.courseUploadHistory.filter((item) => item.isPublished && !item.isDeleted);
        setDashboardData({
          totalCourses: response.data.result.totalCourseCount,
          enrolledUsers: response.data.result.totalEnrolledUsers,
          courseUploadData: filteredData,
          topEnrolledData: response.data.result.topEnrolledCourse,
          techTalkSessionCount: response.data.result.techTalkSessionCount,
          userMilestoneCount: response.data.result.milestonesCount,
          wallOfFame: response.data.result.courseCompletedNotification,
          loading: false
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    getLMSDashboardData();
  }, []);
  return (
    <>
      <div style={{ marginBottom: "5rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <p className="lms-dashboard-head">LMS Dashboard</p>
          </div>
          <div className="col-4 ">
            {dashboardData.loading ? <TotalCoursesSkeleton /> : <TotalCoursesFile totalCourses={dashboardData.totalCourses} />}
            <div className="col-12 d-flex justify-content-between w-100 gap-3">
              <div className="col-5 card total-enrolled-users-card tech-talk-card pointer ">{dashboardData.loading ? <TechTalkSessionSkeleton /> : <TechTalkSession sessionCount={dashboardData.techTalkSessionCount} />}</div>
              <div className="col-5 card total-enrolled-users-card tech-talk-card enrolledcard ">{dashboardData.loading ? <EnrolledUserSkeleton /> : <EnrolledUsers enrolledUsers={dashboardData.enrolledUsers} />}</div>
            </div>
          </div>
          <div className="col-8">
            <CertificationsAchievedFile originalMilestoneData={dashboardData.userMilestoneCount} loading={dashboardData.loading} />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <p className="top-enrolled-courses-head">Top Enrolled Courses</p>
            <div className="card top-enrolled-courses-card pb-0">
              <TopEnrolledCourses pData={dashboardData?.topEnrolledData} isLoading={dashboardData.loading} />
            </div>
          </div>
          <div className="col-4">
            <p className="course-upload-history-head">Course Upload History</p>
            <div className={`card course-upload-history-card ${!dashboardData.loading && "dp-scroll"}`}>
              <CourseUploadHistory courseUploadData={dashboardData.courseUploadData} isLoading={dashboardData.loading} />
            </div>
          </div>
          <div className="col-4">
            <p className="wall-of-fame-head">Wall Of Fame</p>
            <div className={`wall-of-fame-card overflow-scroll ${!dashboardData.loading && "dp-scroll"} `}>
              <WallOfFame fameData={dashboardData.wallOfFame} isLoading={dashboardData.loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLMSDashboard;
