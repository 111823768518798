import "./UploadCourses.css";
import { CustomModal, DeleteWarning, SuccessfullVector, useEffect, useLocation, Header, SideNav, TechActionType, BreadCrumbs, handleApiError, useState, axios, useReducer, useNavigate, decryptData, CourseInfo, UploadContent, Assignment, AdditionalInfo, PublishPopUp, FileUploadProgress, FileUploadFooter, HorizontalLine, ActiveCourseInfo, InactiveUploadContent, InactiveAssignment, InactiveAdditionalInfo, Tick, ActiveUploadContent, ActiveAssignment, ActiveAdditionalInfo, Publish, Draft, UploadTips, useRef } from "./UploadCoursesImport";

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    default:
      return state;
  }
};

const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};

const courseState = {
  currPage: 1,
  thumbnail: null,
  thumbnailFile: null,
  IsThumbNailChanged: false,
  deletedThumbnail: null,
  isPublished: false,
  courseId: "",
  name: "",
  description: "",
  level: "",
  levelId: "",
  modules: [{ id: "", selectedFiles: [], moduleName: "", quizFile: null, isAdded: true, isDeleted: false, isSaved: false }],
  assignmentId: "",
  assignmentName: "",
  assignmentDescription: "",
  assignmentDay: "",
  assignmentHrs: "",
  assignmentMin: "",
  attachment: [],
  assignmentIsAdded: false,
  instructor: "",
  instructorId: null,
  courseGrade: "",
  totalVideos: "",
  videoHrs: "",
  videoMins: "",
  totalPdf: "",
  pdfPages: "",
  totalPPT: "",
  PPTPages: "",
  totalAssignments: "",
  quizQuestions: 0,
  courseDay: "",
  courseHrs: "",
  courseMin: "",
  category: null,
  subCategory: null,
  autoArchive: false,
};

const UploadCourses = (props) => {
  const { editMode } = props;
  const [isOpen, setIsOpen] = useState({
    popUp: false,
    isLoading: false,
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const [courseUploadData, setCourseUploadData] = useState(courseState);
  const [error, setError] = useState({
    nameError: "",
    descError: "",
    moduleIndex: null,
    moduleNameError: "",
    assignmentNameError: "",
    assignmentDescriptionError: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const resetCourseData = () => {
    setCourseUploadData(courseState);
    dispatch({ type: "RESET" });
  };

  useEffect(() => {
    if (!editMode) {
      resetCourseData();
    }
  }, [editMode]);

  const parseDuration = (durationString) => {
    const parts = durationString?.split(" ");
    let days = 0,
      hours = 0,
      minutes = 0;
    for (let i = 0; i < parts.length; i += 2) {
      const value = parseInt(parts[i]);
      const unit = parts[i + 1];
      if (unit.includes("days")) {
        days = value;
      } else if (unit.includes("hrs")) {
        hours = value;
      } else if (unit.includes("mins") || unit.includes("min")) {
        minutes = value;
      }
    }
    return {
      days: days !== 0 ? days.toString() : "",
      hours: hours !== 0 ? hours.toString() : "",
      minutes: minutes !== 0 ? minutes.toString() : "",
    };
  };

  const fetchCourseData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getById?courseId=${sessionStorage.getItem("courseId")}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        const editCourseData = response.data.result;
        const tagIds = editCourseData.tags.map((tag) => tag.id);
        const tagNames = editCourseData.tags.map((tag) => tag.techName);
        dispatch({ type: TechActionType.SET_TECH_NAME, payload: tagNames });
        dispatch({ type: TechActionType.SET_TECH_ID, payload: tagIds });
        const parsedCourseTime = editCourseData?.courseCompletionTime != null ? parseDuration(editCourseData?.courseCompletionTime) : null;
        const parsedAssignmentTime = editCourseData.assignments != null && editCourseData?.assignments[0].completionTime != null ? parseDuration(editCourseData?.assignments[0].completionTime) : null;
        const parsedVideoLength = editCourseData?.totalVideosLength != null ? parseDuration(editCourseData?.totalVideosLength) : null;
        const sortedModules = editCourseData?.modules != null ? editCourseData?.modules?.sort((a, b) => a.orderNumber - b.orderNumber) : [];
        const modulesWithSortedContent = sortedModules.map((module) => ({
          ...module,
          moduleContent: module.moduleContent.sort((a, b) => a.orderNumber - b.orderNumber),
        }));
        const sumNumberOfQuestions = modulesWithSortedContent.reduce((acc, module) => {
          return acc + module.moduleContent.filter(content => content.isQuiz).reduce((acc, content) => {
            return acc + (content.numberOfQuestions || 0);
          }, 0);
        }, 0);
        setCourseUploadData({
          ...courseState,
          isPublished: editCourseData?.isPublished,
          courseId: editCourseData?.id,
          courseGrade: editCourseData?.gradeType === "Not Selected" ? "" : editCourseData?.gradeInfo,
          name: editCourseData?.title,
          description: editCourseData?.description,
          levelId: editCourseData?.levelId,
          category: {
            value: editCourseData.categoryInfo[0].categoryName,
            label: editCourseData.categoryInfo[0].categoryName,
            id: editCourseData.categoryInfo[0].categoryId,
          },
          subCategory: {
            value: editCourseData.categoryInfo[0].subCategoryName,
            label: editCourseData.categoryInfo[0].subCategoryName,
            id: editCourseData.categoryInfo[0].subCategoryId,
          },
          thumbnail: editCourseData?.thumbnailUri ? editCourseData?.thumbnailUri : null,
          deletedThumbnail: editCourseData?.thumbnailUriName ? editCourseData?.thumbnailUriName : null,
          instructorId: editCourseData?.instructorList != null ? editCourseData?.instructorList[0]?.instructorId : null,
          instructor: editCourseData?.instructorList != null ? `${editCourseData?.instructorList[0]?.firstName} ${editCourseData?.instructorList[0]?.lastName}` : "",
          autoArchive: editCourseData?.autoArchive,
          totalVideos: editCourseData?.totalVideoCount?.toString(),
          videoHrs: editCourseData.totalVideosLength != null ? parsedVideoLength.hours : "",
          videoMins: editCourseData.totalVideosLength != null ? parsedVideoLength.minutes : "",
          totalPdf: editCourseData?.totalPdfCount?.toString(),
          pdfPages: editCourseData?.totalPdfPages?.toString(),
          totalPPT: editCourseData?.totalPptCount?.toString(),
          PPTPages: editCourseData?.totalPptPages?.toString(),
          assignmentIsAdded: editCourseData?.assignments == null ? true : false,
          totalAssignments: editCourseData?.assignments != null ? editCourseData?.assignments[0]?.assignmentAttachment?.length.toString() : "",
          quizQuestions: sumNumberOfQuestions,
          courseDay: parsedCourseTime == null ? "" : parsedCourseTime?.days,
          courseHrs: parsedCourseTime == null ? "" : parsedCourseTime?.hours,
          courseMin: parsedCourseTime == null ? "" : parsedCourseTime?.minutes,
          modules: modulesWithSortedContent?.map((module) => ({
            id: module?.id ? module?.id : "00000000-0000-0000-0000-000000000000",
            moduleName: module?.name,
            isSaved: true,
            selectedFiles: module?.moduleContent
              ?.filter((content) => !content.isQuiz)
              ?.map((content) => ({
                file: {
                  id: content?.id || "00000000-0000-0000-0000-000000000000",
                  name: content?.name,
                  path: content?.contentAttachment[0]?.path,
                  contentAttachId: content?.contentAttachment[0]?.id || "00000000-0000-0000-0000-000000000000",
                  fullPath: content?.contentAttachment[0]?.fullPath
                },
              })),
            quizFile: module?.moduleContent?.find((content) => content.isQuiz)
              ? {
                file: {
                  id: module?.moduleContent?.find((content) => content.isQuiz)?.id || "00000000-0000-0000-0000-000000000000",
                  name: module?.moduleContent?.find((content) => content.isQuiz)?.contentAttachment[0]?.name || null,
                  path: module?.moduleContent?.find((content) => content.isQuiz)?.contentAttachment[0]?.path || null,
                  contentAttachId: module?.moduleContent?.find((content) => content.isQuiz)?.contentAttachment[0]?.id || "00000000-0000-0000-0000-000000000000",
                  fullPath: module?.moduleContent?.find((content) => content.isQuiz)?.contentAttachment[0]?.fullPath,
                  numberOfQuestions: module?.moduleContent?.find((content) => content.isQuiz)?.numberOfQuestions
                },
              }
              : null,
          })),
          assignmentId: editCourseData?.assignments != null ? editCourseData?.assignments[0]?.id : "",
          assignmentName: editCourseData?.assignments != null ? editCourseData?.assignments[0]?.title : "",
          assignmentDescription: editCourseData?.assignments != null ? editCourseData?.assignments[0]?.description : "",
          assignmentDay: editCourseData?.assignments != null && editCourseData?.assignments[0].completionTime != null ? parsedAssignmentTime.days : "",
          assignmentHrs: editCourseData?.assignments != null && editCourseData?.assignments[0].completionTime != null ? parsedAssignmentTime.hours : "",
          assignmentMin: editCourseData?.assignments != null && editCourseData?.assignments[0].completionTime != null ? parsedAssignmentTime.minutes : "",
          attachment:
            editCourseData?.assignments != null && editCourseData?.assignments[0]?.assignmentAttachment[0].id != null
              ? editCourseData?.assignments[0]?.assignmentAttachment?.map((attachment) => ({
                file: {
                  id: attachment?.id || "00000000-0000-0000-0000-000000000000",
                  name: attachment?.name,
                  path: attachment?.path,
                },
              }))
              : [],
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  useEffect(() => {
    if (editMode) {
      fetchCourseData();
    }
  }, [editMode]);

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  var userId = parsedObject["decoded"]["Id"];
  const handleCourseAction = async (isPublished) => {
    if (isPublished) {
      setIsOpen((prev) => ({
        ...prev,
        isLoading: true,
      }));
    }
    const draftData = {
      id: courseUploadData?.courseId ? courseUploadData?.courseId : "00000000-0000-0000-0000-000000000000",
      gradeInfo: courseUploadData?.courseGrade ? courseUploadData?.courseGrade : "00000000-0000-0000-0000-000000000000",
      totalVideoCount: courseUploadData?.totalVideos ? parseInt(courseUploadData?.totalVideos) : 0,
      totalPptCount: courseUploadData?.totalPPT ? parseInt(courseUploadData?.totalPPT) : 0,
      totalPdfCount: courseUploadData?.totalPdf ? parseInt(courseUploadData?.totalPdf) : 0,
      totalPdfPages: courseUploadData?.pdfPages ? parseInt(courseUploadData?.pdfPages) : 0,
      totalPptPages: courseUploadData?.PPTPages ? parseInt(courseUploadData?.PPTPages) : 0,
      totalVideosLength: courseUploadData?.videoHrs && courseUploadData?.videoMins ? `${courseUploadData?.videoHrs} hrs ${courseUploadData?.videoMins} min` : null,
      courseCompletionTime: courseUploadData?.courseDay || courseUploadData?.courseHrs || courseUploadData?.courseMin ? `${courseUploadData?.courseDay || "0"} days ${courseUploadData?.courseHrs || "0"} hrs ${courseUploadData?.courseMin || "0"} mins` : null,
      assignmentCompletionTime: courseUploadData?.assignmentDay || courseUploadData?.assignmentHrs || courseUploadData?.assignmentMin ? `${courseUploadData?.assignmentDay || "0"} days ${courseUploadData?.assignmentHrs || "0"} hrs ${courseUploadData?.assignmentMin || "0"} mins` : null,
      title: courseUploadData?.name,
      categoryInfo: courseUploadData?.category ? [{ categoryId: courseUploadData?.category.id, subCategoryId: courseUploadData?.subCategory.id }] : null,
      autoArchive: courseUploadData.autoArchive,
      description: courseUploadData?.description,
      levelId: courseUploadData?.levelId,
      thumbnailUri: courseUploadData.IsThumbNailChanged && editMode ? courseUploadData.deletedThumbnail : null,
      IsThumbNailChanged: courseUploadData.IsThumbNailChanged,
      uploadedBy: userId,
      tags:
        state.selectedTechIds?.length !== 0
          ? state.selectedTechIds?.map((id) => ({
            id: id,
          }))
          : null,
      instructorList: courseUploadData?.instructorId ? [{ instructorId: courseUploadData?.instructorId }] : null,
      assignments:
        courseUploadData?.assignmentName !== ""
          ? [
            {
              id: courseUploadData.assignmentId ? courseUploadData.assignmentId : "00000000-0000-0000-0000-000000000000",
              title: courseUploadData.assignmentName,
              description: courseUploadData.assignmentDescription,
              assignmentAttachment:
                courseUploadData.attachment?.length !== 0
                  ? courseUploadData.attachment.map((attachment, attachmentIndex) => ({
                    id: attachment.file.id ? attachment.file.id : "00000000-0000-0000-0000-000000000000",
                    name: attachment.file.name,
                    path: attachment.file.path ? attachment.file.path : null,
                    orderNumber: attachmentIndex + 1,
                    isDeleted: attachment.isDeleted,
                    isAdded: attachment.isAdded,
                  }))
                  : null,
              isAdded: courseUploadData.assignmentIsAdded,
            },
          ]
          : null,
      modules:
        courseUploadData?.modules?.length !== 0 && courseUploadData?.modules[0]?.moduleName
          ? courseUploadData.modules
            .filter((module) => module.moduleName !== null && module.selectedFiles?.length !== 0)
            .map((module, index) => {
              let moduleContentData = module.selectedFiles.map((file, fileIndex) => ({
                id: file.file.id ? file.file.id : "00000000-0000-0000-0000-000000000000",
                name: file.file.name,
                description: null,
                orderNumber: fileIndex + 1,
                contentAttachment: [
                  {
                    id: file.file.contentAttachId ? file.file.contentAttachId : "00000000-0000-0000-0000-000000000000",
                    name: file.file.name,
                    path: file.file.path ? file.file.path : null,
                    orderNumber: fileIndex + 1,
                    isQuiz: false,
                  },
                ],
                isDeleted: file.isDeleted,
                isAdded: file.isAdded,
                isQuiz: false,
              }));
              if (module.quizFile != null) {
                let quizData = {
                  id: module.quizFile?.file.id ? module.quizFile?.file.id : "00000000-0000-0000-0000-000000000000",
                  name: module.quizFile?.file.name,
                  description: null,
                  orderNumber: moduleContentData.length + 1,
                  contentAttachment: [
                    {
                      id: module.quizFile.file.contentAttachId ? module.quizFile.file.contentAttachId : "00000000-0000-0000-0000-000000000000",
                      name: module.quizFile?.file.name,
                      path: module.quizFile?.file.path ? module.quizFile?.file.path : null,
                      orderNumber: moduleContentData.length + 1,
                      isQuiz: true,
                    },
                  ],
                  isDeleted: module.quizFile?.isDeleted,
                  isAdded: module.quizFile?.isAdded,
                  isQuiz: true,
                };
                moduleContentData.push(quizData);
              }
              return module.moduleName !== null
                ? {
                  id: module.id ? module.id : "00000000-0000-0000-0000-000000000000",
                  name: module.moduleName,
                  description: null,
                  moduleContent: [...moduleContentData],
                  orderNumber: index + 1,
                  isDeleted: module.isDeleted,
                  isAdded: module.isAdded,
                }
                : null;
            })
          : null,
      isPublished,
    };
    const formData = new FormData();
    courseUploadData?.modules.forEach((module) => {
      module.selectedFiles.forEach((file, index) => {
        if (file.isAdded) {
          formData.append(`moduleAttachmentFiles`, file.file);
        }
      });
      if (module.quizFile && module.quizFile.isAdded) {
        formData.append(`moduleAttachmentFiles`, module.quizFile.file);
      }
    });

    courseUploadData?.attachment?.forEach((file, index) => {
      if (file.isAdded) {
        formData.append(`assignmentAttachmentFiles`, file.file);
      }
    });
    formData.append("courseInfoJSON", JSON.stringify(draftData));
    if (courseUploadData.IsThumbNailChanged) {
      formData.append("thumbnailFile", courseUploadData?.thumbnailFile);
    }

    let url;
    if (editMode) {
      url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/update?isPublish=${isPublished}`;
    } else {
      url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/create`;
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 200) {
        if (isPublished) {
          setIsOpen(() => ({
            popUp: false,
            isLoading: false,
            isModalOpen: true,
            modalType: "success",
            modalTitle: "Course Uploaded Successfully",
            modalMessage: "Course has been Uploaded Successfully",
            icon: <SuccessfullVector />,
          }));
        } else {
          setIsOpen(() => ({
            popUp: false,
            isLoading: false,
            isModalOpen: true,
            modalType: "success",
            modalTitle: "Course Saved Successfully",
            modalMessage: "Course has been Saved Successfully",
            icon: <SuccessfullVector />,
          }));
        }
        setCourseUploadData((prevData) => ({
          ...prevData,
          currPage: 1,
        }));
      } else {
        setIsOpen(() => ({
          popUp: false,
          isLoading: false,
          isModalOpen: true,
          modalType: "info",
          modalTitle: "Course Not Uploaded",
          modalMessage: `${response.data.message}`,
          icon: <DeleteWarning />,
        }));
      }
    } catch (error) {
      setIsOpen(() => ({
        popUp: false,
        isLoading: false,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Course Not Uploaded",
        modalMessage: "Error Occured While Uploading Course",
        icon: <DeleteWarning />,
      }));
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleClose = () => {
    if (isOpen.modalType === "success") {
      navigate("/admin/lms/total-uploaded-courses");
    }
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const handleSaveAsDraft = async () => {
    setIsOpen(() => ({
      popUp: false,
      isLoading: false,
      isModalOpen: true,
      modalType: "loader",
      modalTitle: "Saving Course",
      icon: <Draft className="draft-icon mb-3" />,
    }));
    await handleCourseAction(false);
  };

  const confirmPublish = async () => {
    await handleCourseAction(true);
  };

  const isNextButtonDisabled = () => {
    switch (courseUploadData?.currPage) {
      case 1:
        return !courseUploadData?.name || courseUploadData.category == null || courseUploadData.subCategory == null || !courseUploadData?.description || state.selectedTechIds.length === 0 || state.selectedTechIds.length > 10 || (!editMode && !courseUploadData?.thumbnailFile) || !courseUploadData?.levelId || error?.nameError !== "" || error?.descError !== "";
      case 2:
        return courseUploadData?.modules?.length === 0 || courseUploadData?.modules?.some((module) => !module.isSaved) || courseUploadData?.modules?.every((module) => !module.isSaved || module.isDeleted || module.selectedFiles.length === 0 || error?.moduleNameError !== "");
      case 3:
        return !courseUploadData?.assignmentName || !courseUploadData?.assignmentDescription || courseUploadData?.attachment?.filter(attachment => !attachment.isDeleted).length === 0 || error?.assignmentNameError !== "" || error?.assignmentDescriptionError !== "" || (courseUploadData.assignmentDay === "" && courseUploadData.assignmentHrs === "" && courseUploadData.assignmentMin === "") || (courseUploadData.assignmentDay == "0" && courseUploadData.assignmentHrs == "0" && courseUploadData.assignmentMin == "0") || (courseUploadData.assignmentDay == "0" && courseUploadData.assignmentHrs == "" && courseUploadData.assignmentMin == "") || (courseUploadData.assignmentDay == "" && courseUploadData.assignmentHrs == "0" && courseUploadData.assignmentMin == "") || (courseUploadData.assignmentDay == "" && courseUploadData.assignmentHrs == "" && courseUploadData.assignmentMin == "0")||(courseUploadData.assignmentDay == "" && courseUploadData.assignmentHrs == "0" && courseUploadData.assignmentMin == "0")||(courseUploadData.assignmentDay == "0" && courseUploadData.assignmentHrs == "" && courseUploadData.assignmentMin == "0")||(courseUploadData.assignmentDay == "0" && courseUploadData.assignmentHrs == "0" && courseUploadData.assignmentMin == "");
      case 4:
        return !courseUploadData?.instructorId || !courseUploadData?.courseGrade || (parseInt(courseUploadData.totalVideos) > 0 && ((courseUploadData.videoHrs == "" && courseUploadData.videoMins == "") || (courseUploadData.videoHrs == "0" && courseUploadData.videoMins == "") || (courseUploadData.videoHrs == "" && courseUploadData.videoMins == "0") || (courseUploadData.videoHrs == "0" && courseUploadData.videoMins == "0"))) || courseUploadData.pdfPages == "" || (parseInt(courseUploadData.totalPdf) > 0 && courseUploadData.pdfPages === "0") || courseUploadData.PPTPages === "" || (parseInt(courseUploadData.totalPPT) > 0 && courseUploadData.PPTPages === "0") || courseUploadData.totalAssignments == "" || (courseUploadData.courseDay == "0" || courseUploadData.courseHrs == "0" || courseUploadData.courseMin == "0") || (courseUploadData.courseDay == "" && courseUploadData.courseHrs == "" && courseUploadData.courseMin == "")|| (courseUploadData.courseDay == "0" && courseUploadData.courseHrs == "" && courseUploadData.courseMin == "")|| (courseUploadData.courseDay == "" && courseUploadData.courseHrs == "0" && courseUploadData.courseMin == "")|| (courseUploadData.courseDay == "" && courseUploadData.courseHrs == "" && courseUploadData.courseMin == "0")|| (courseUploadData.courseDay == "" && courseUploadData.courseHrs == "0" && courseUploadData.courseMin == "0")|| (courseUploadData.courseDay == "0" && courseUploadData.courseHrs == "" && courseUploadData.courseMin == "0")|| (courseUploadData.courseDay == "0" && courseUploadData.courseHrs == "0" && courseUploadData.courseMin == "");
      default:
        return false;
    }
  };
  const uploadBodyRef = useRef(null);

  const resetScrollPosition = () => {
    if (uploadBodyRef.current) {
      uploadBodyRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    resetScrollPosition();
  }, [courseUploadData.currPage]);
  return (
    <>
      <div style={{ marginBottom: "4rem" }}>
        <Header />
        <SideNav />
      </div>
      <div>
        <div className="container upload-courses-container-navigator">
          <div className="row">
            <div className="detailrep-header">
              <div className="detailrep-breadcrum">
                <BreadCrumbs />
              </div>
            </div>
            <div className="col-9">
              <div className="upload-courses-container">
                {editMode ? <p>Edit Course</p> : <p>Upload Course</p>}
                <button disabled={(!courseUploadData?.name || courseUploadData.category == null || courseUploadData.subCategory == null || !courseUploadData?.description || state.selectedTechIds.length === 0 || (!editMode && !courseUploadData?.thumbnailFile) || !courseUploadData?.levelId || error?.nameError !== "" || error?.descError !== "") || (editMode && courseUploadData.isPublished) ? true : false} type="button" className="btn save-as-draft-btn mb-2" onClick={() => handleSaveAsDraft()}>
                  <Draft />
                  Save As Draft
                </button>
              </div>
              <div className="card add-course-card d-flex">

                <FileUploadProgress currPage={courseUploadData.currPage} type={"Course"} />
                <div className="card-body mx-3 upload-course-body" ref={uploadBodyRef}>
                  {(() => {
                    switch (courseUploadData?.currPage) {
                      case 1:
                        return <CourseInfo selectedCategory={courseUploadData} setSelectedCategory={setCourseUploadData} error={error} setError={setError} courseUploadData={courseUploadData} setCourseUploadData={setCourseUploadData} state={state} dispatch={dispatch} />;
                      case 2:
                        return <UploadContent editMode={editMode} error={error} setError={setError} courseUploadData={courseUploadData} setCourseUploadData={setCourseUploadData} />;
                      case 3:
                        return <Assignment editMode={editMode} error={error} setError={setError} courseUploadData={courseUploadData} setCourseUploadData={setCourseUploadData} />;
                      case 4:
                        return <AdditionalInfo type="course" error={error} setError={setError} uploadData={courseUploadData} setUploadData={setCourseUploadData} />;
                      default:
                        return null;
                    }
                  })()}
                </div>

                <FileUploadFooter uploadData={courseUploadData} setUploadData={setCourseUploadData} type={"course"} setIsModalOpen={setIsOpen} validationFunction={isNextButtonDisabled} />
              </div>
            </div>
            <div className="col-3">
              <UploadTips type="Course" />
            </div>
          </div>
          {isOpen.popUp && <PublishPopUp confirmPublish={confirmPublish} isOpen={isOpen} setIsOpen={setIsOpen} type={"Course"} />}
          {isOpen.isModalOpen && (
            <CustomModal
              modalProps={{
                icon: isOpen.icon,
                show: isOpen.isModalOpen,
                type: isOpen.modalType,
                title: isOpen.modalTitle,
                message: isOpen.modalMessage,
                onCancel: handleClose,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UploadCourses;
