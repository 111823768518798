import { Skeleton, Usercircle, Beginner, Intermediate, Advance, Clock, Mail, Profile, Call, ExpandMore, EmptyState, Select } from "./../../AdminImport";
import "react-loading-skeleton/dist/skeleton.css";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "10rem",
    margin: "auto",
    marginTop: "0.37rem",
    height: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const Detailreportskeleton = () => {
  return (
    <div>
      <div className="detailrep-user-info">
        <div className="use-info">
          <span>User Information</span>
        </div>
        <div className="info-detail">
          <div className="user-name">
            <Usercircle />
            <Skeleton width={200} highlightColor="#B2B2B3" />
          </div>
          <div className="other-info">
            <div className="icon-pair">
              <Profile />
              <Skeleton width={50} highlightColor="#B2B2B3" />
            </div>
            <div className="det-dot" />
            <div className="icon-pair">
              <Clock />
              <Skeleton width={100} highlightColor="#B2B2B3" />
            </div>
            <div className="det-dot" />
            <div className="icon-pair">
              <Mail />
              <Skeleton width={150} highlightColor="#B2B2B3" />
            </div>
            <div className="det-dot" />
            <div className="icon-pair">
              <Call />
              <Skeleton width={100} highlightColor="#B2B2B3" />
            </div>
          </div>
        </div>
      </div>
      <div className="detailrep-second-child">
        <div className="detail-child spent-hours">
          <span>Most Spent Hours</span>
          <div className="most-skill-hr">
            <div className="container" style={{ height: "inherit" }}>
              <div className="row">
                <div style={{ padding: "0", margin: "0" }}>
                  <div className="dropdown d-flex align-items-center p2">
                    <Select styles={customStyles} />

                    <Select styles={customStyles} />
                  </div>
                  <div>
                    <div className="dr-skeleton">
                      <div className="no-data-div">
                        <EmptyState className="custom-empty-state" />
                        <h1 className="h-nodata">No data found</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail-child">
          <span>Skills Achieved</span>
          <div className="skills-achieved">
            <div className="skill-parent-wrapper">
              <div className="skill-star-count">
                <div className="skill-first-container">
                  <div className="total-stars">
                    <span className="count">0</span>
                    <span>Stars Achieved</span>
                  </div>
                  <div className="seperated-stars">
                    <div>
                      <span className="count">0</span>
                      <span>Beginner</span>
                    </div>
                    <div>
                      <span className="count">0</span>
                      <span>Intermediate</span>
                    </div>
                    <div>
                      <span className="count">0</span>
                      <span>Advanced</span>
                    </div>
                  </div>
                </div>
                <div className="skill-second-container">
                  <div>Technology:</div>
                  <div className="tag-container">
                    <Skeleton width={73} height={24} highlightColor="#B2B2B3" /> &nbsp;
                    <Skeleton width={73} height={24} highlightColor="#B2B2B3" /> &nbsp;
                    <Skeleton width={73} height={24} highlightColor="#B2B2B3" /> &nbsp;
                    <Skeleton width={73} height={24} highlightColor="#B2B2B3" />{" "}
                  </div>
                </div>
              </div>
              <div className="star-info">
                <div>
                  <Beginner />
                </div>
                <span>Beginner</span>
                <div>
                  <Intermediate />
                </div>
                <span>Intermediate</span>
                <div>
                  <Advance />
                </div>
                <span>Advanced</span>
              </div>
            </div>
          </div>
        </div>
        <div className="detail-child">
          <span>Project Details</span>
          <div className="project-details">
            <div className="list-parent-wrapper">
              <div className="list-desp-wrapper">
                <div className="list-child-wrapper">
                  <div className="list-project-expand">
                    <Skeleton width={150} highlightColor="#B2B2B3" />
                    <ExpandMore className="pointer" />
                  </div>
                  <Skeleton width="90%" highlightColor="#B2B2B3" />
                  <div className="list-link project-link">
                    <div className="link-title">Project Link:</div>

                    <Skeleton width={190} height={12} highlightColor="#28519E" />
                  </div>
                </div>
              </div>
            </div>
            <div className="list-parent-wrapper">
              <div className="list-desp-wrapper">
                <div className="list-child-wrapper">
                  <div className="list-project-expand">
                    <Skeleton width={150} highlightColor="#B2B2B3" />
                    <ExpandMore className="pointer" />
                  </div>
                  <Skeleton width="90%" highlightColor="#B2B2B3" />
                  <div className="list-link project-link">
                    <div className="link-title">Project Link:</div>

                    <Skeleton width={190} height={12} highlightColor="#28519E" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailreportskeleton;
