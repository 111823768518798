import { useState, Filter, ExpandMore, Chevron, CloseBtn } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";

const TechTalkFilter = (props) => {
  const { instructorsData, subCategoryData, tagsData, closeFilter, archiveStatus, filterData, setFilterData } = props;
  const [allChecked, setAllCheck] = useState({
    instructors: instructorsData.length === (filterData.mentorId.length),
    subCategory: subCategoryData.length === (filterData.subCategoryName.length),
    tags: tagsData.length === (filterData.tagId.length),
  });
  const [currentState, setCurrentState] = useState({
    mentorId: filterData.mentorId,
    subCategoryName: filterData.subCategoryName,
    tagId: filterData.tagId,
    getIsArchive: filterData.getIsArchive,

    instructors: instructorsData.length === (filterData.mentorId.length),
    subCategory: subCategoryData.length === (filterData.subCategoryName.length),
    tags: tagsData.length === (filterData.tagId.length),
  });

  const handleClose = () => {
    setCurrentState({
      mentorId: [],
      subCategoryName: [],
      tagId: [],
      getIsArchive: []
    })
    closeFilter((prevState) => ({
      ...prevState,
      isFlterOpen: false
    }));

  }
  const handelReset = () => {
    closeFilter((prevState) => ({
      ...prevState,
      isFlterOpen: false
    }));
    setFilterData({
      ...filterData,
      mentorId: [],
      subCategoryName: [],
      tagId: [],
    })
    setCurrentState({
      ...currentState,
      mentorId: [],
      subCategoryName: [],
      tagId: [],
      getIsArchive: []
    })
  }

  const handelApply = () => {
    setFilterData({
      ...filterData,
      mentorId: currentState.mentorId,
      subCategoryName: currentState.subCategoryName,
      tagId: currentState.tagId,
      getIsArchive: currentState.getIsArchive
    })
    closeFilter((prevState) => ({
      ...prevState,
      isFlterOpen: false
    }));
  }
  const handleFilterChange = (e) => {
    const filter = e.currentTarget.getAttribute("data-filter");
    if (filter) {
      setFilterData({
        ...filterData,
        activeFilter: filterData.name == filter ? "" : filter
      });
    }

  }

  const handleSelectAllCheck = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "mentorSelectAll":
        debugger
        if (value == "allChecked" && !allChecked.instructors) {
          setAllCheck((prevState) => ({
            ...prevState,
            instructors: true
          }));
          setCurrentState((prevState) => {
            const existingMentorIds = prevState.mentorId || [];
            const allMentorIds = instructorsData.map(element => element.id);
            const filteredMentorIds = allMentorIds.filter(id => !existingMentorIds.includes(id));
            const updatedMentorIds = existingMentorIds.concat(filteredMentorIds);
            return {
              ...prevState,
              mentorId: updatedMentorIds
            };
          });
        } else {
          setAllCheck((prevState) => ({
            ...prevState,
            instructors: false
          }));
          setCurrentState({
            ...currentState,
            mentorId: []
          })
        }
        break;
      case "subCategorySelectAll":
        if (value == "allChecked" && !allChecked.subCategory) {
          setAllCheck((prevState) => ({
            ...prevState,
            subCategory: true
          }))
          setCurrentState((prevState) => {
            const existingSubCategoryName = prevState.subCategoryName || [];
            const allSubCategoryName = subCategoryData.map(element => element.subCategoryName);
            const filteredSubCategoryName = allSubCategoryName.filter(id => !existingSubCategoryName.includes(id));
            const updatedSubCategoryName = existingSubCategoryName.concat(filteredSubCategoryName);
            return {
              ...prevState,
              subCategoryName: updatedSubCategoryName
            };
          });
        } else {
          setAllCheck((prevState) => ({
            ...prevState,
            subCategory: false
          }));
          setCurrentState({
            ...currentState,
            subCategoryName: []
          })
        }
        break;
      case "tagsSelectAll":
        if (value == "allChecked" && !allChecked.tags) {
          setAllCheck((prevState) => ({
            ...prevState,
            tags: true
          }))
          setCurrentState((prevState) => {
            const existingTags = prevState.tagId || [];
            const allTags = tagsData.map(element => element.id);
            const filteredTags = allTags.filter(id => !existingTags.includes(id));
            const updatedTags = existingTags.concat(filteredTags);
            return {
              ...prevState,
              tagId: updatedTags
            };
          });
        } else {
          setAllCheck((prevState) => ({
            ...prevState,
            tags: false
          }));
          setCurrentState({
            ...currentState,
            tagId: []
          })
        }
        break;
    }
  }

  const handleIndiudalOptionCheck = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "mentorList":
        setCurrentState((prevState) => {
          const mentorIdValue = parseInt(value);
          const updatedMentorId = prevState.mentorId.includes(mentorIdValue)
            ? prevState.mentorId.filter(x => x !== mentorIdValue)
            : [...prevState.mentorId, mentorIdValue];
          return {
            ...prevState,
            mentorId: updatedMentorId,
          };
        });
        // setAllCheck((prevState) => ({
        //   ...prevState,
        //   instructors: instructorsData.length === (currentState.mentorId.length + 1)
        // }))
        break;
      case "subCategoryList":
        setCurrentState((prevState) => {
          const updatedSubCategoryName = prevState.subCategoryName?.includes(value)
            ? prevState.subCategoryName.filter(x => x !== value)
            : prevState.subCategoryName.length !== 0
              ? [...prevState.subCategoryName, value]
              : [value];
          return {
            ...prevState,
            subCategoryName: updatedSubCategoryName,
            // subCategory: subCategory
          };
        });
        // const subCategory = subCategoryData.every(item => {
        //   return currentState.subCategoryName.includes(item.subCategoryName);
        // });

        // setAllCheck((prevData) => ({
        //   ...prevData,
        //   subCategory: subCategory
        // }));

        break;
      case "tagsList":
        setCurrentState((prevState) => {
          const updatedTagsIds = prevState.tagId?.includes(parseInt(value))
            ? prevState.tagId.filter(x => x !== parseInt(value))
            : prevState.tagId.length !== 0
              ? [...prevState.tagId, parseInt(value)]
              : [parseInt(value)];
          return {
            ...prevState,
            tagId: updatedTagsIds
          };
        });
        // setAllCheck((prevState) => ({
        //   ...prevState,
        //   tags: tagsData.length === (currentState.tagId.length + 1)
        // }))
        break;
      case "archive":
        setCurrentState((prevState) => ({
          ...prevState,
          getIsArchive: value
        }));
        break;
    }
  }
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="d-flex flex-column align-items-end tt-filter-wrapper">
          <div className="tt-filter-body rounded ">
            <div className="row filter-body-header-wrapper border-bottom rounded m-0">
              <p className="mb-0">
                <Filter /> Filter
                <button type="button" className="btn-close float-end " onClick={handleClose}></button>
              </p>
            </div>
            <div className="filter-body-wrapper flex-column ">
              {/* Mentor filter */}
              <div className={`row p-0 mt-0 ${filterData.activeFilter == "instructor" ? "filter-body" : "filter-body-collapse"}`}>
                <div className="instructor-filter-wrapper border p-0">
                  <div className="filter-name" data-filter="instructor"
                    onClick={handleFilterChange}>
                    <p className="ms-2">Instructor Name</p>
                    <span className="me-1">{filterData.activeFilter == "instructor" ? <ExpandMore /> : <Chevron />}</span>
                  </div>
                  <div className="filter-data ">
                    <div className="d-flex">
                      <input type="checkbox"
                        name="mentorSelectAll"
                        id="allSelected"
                        checked={currentState.mentorId.length == filterData.instructorData.length}
                        className="tt-select-checkbox"
                        onChange={handleSelectAllCheck}
                        value="allChecked" />&nbsp;&nbsp;
                      <label htmlFor="allSelected">Select All</label>
                    </div>
                    {instructorsData && instructorsData.map((instructor) => (
                      <div className="d-flex" key={instructor.id}>
                        <input type="checkbox" name="mentorList"
                          className="tt-select-checkbox"
                          value={instructor.id}
                          id={instructor.id}
                          onChange={handleIndiudalOptionCheck}
                          checked={allChecked.instructors || currentState.mentorId?.includes(parseInt(instructor.id))} />&nbsp;&nbsp;
                        <label htmlFor={instructor.id}>{instructor.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Sub Category filter */}
              <div className={`row p-0 mt-0 ${filterData.activeFilter == "subCategory" ? "filter-body" : "filter-body-collapse"}`}>
                <div className="instructor-filter-wrapper border p-0">
                  <div className="filter-name" data-filter="subCategory"
                    onClick={handleFilterChange}
                  >
                    <p className="ms-2">Sub Category Name</p>
                    <span className="me-1">
                      {filterData.activeFilter == "subCategory" ? <ExpandMore /> : <Chevron />}
                    </span>
                  </div>
                  <div className="filter-data ">
                    <div className="d-flex">
                      <input type="checkbox"
                        name="subCategorySelectAll"
                        id="subCategory"
                        className="tt-select-checkbox"
                        // checked={allChecked.subCategory || (filterData.subCategoryName.length > 0 && currentState.subCategoryName.length == filterData.subCategoryNameData.length ? true : false)}
                        checked={currentState.subCategoryName.length == filterData.subCategoryNameData.length}
                        onChange={handleSelectAllCheck}
                        value="allChecked" />&nbsp;&nbsp;
                      <label htmlFor="subCategory">Select All</label>
                    </div>
                    {subCategoryData && subCategoryData.map((subCategory, index) => (
                      <div className="d-flex" key={index}>
                        <input type="checkbox" name="subCategoryList"
                          className="tt-select-checkbox"
                          value={subCategory.subCategoryName}
                          id={subCategory.subCategoryName}
                          onChange={handleIndiudalOptionCheck}
                          checked={allChecked.subCategory || currentState.subCategoryName.includes(subCategory.subCategoryName)} />&nbsp;&nbsp;
                        <label htmlFor={subCategory.subCategoryName}>{subCategory.subCategoryName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Tags filter */}
              <div className={`row p-0 mt-0 ${filterData.activeFilter == "tags" ? "filter-body" : "filter-body-collapse"}`}>
                <div className="instructor-filter-wrapper border p-0">
                  <div className="filter-name" data-filter="tags"
                    onClick={handleFilterChange}>
                    <p className="ms-2">Tags Name</p>
                    <span className="me-1">{filterData.activeFilter == "tags" ? <ExpandMore /> : <Chevron />}</span>
                  </div>
                  <div className="filter-data ">
                    <div className="d-flex">
                      <input type="checkbox"
                        name="tagsSelectAll"
                        id="tags"
                        className="tt-select-checkbox"
                        checked={currentState.tagId.length == filterData.tagsData.length}
                        onChange={handleSelectAllCheck}
                        value="allChecked" />&nbsp;&nbsp;
                      <label htmlFor="tags">Select All</label>
                    </div>
                    {tagsData && tagsData.map((tag) => (
                      <div className="d-flex" key={tag.id}>
                        <input type="checkbox" name="tagsList"
                          className="tt-select-checkbox"
                          value={tag.id}
                          id={tag.id}
                          onChange={handleIndiudalOptionCheck}
                          checked={allChecked.tags || currentState.tagId.includes(parseInt(tag.id))} />&nbsp;&nbsp;
                        <label htmlFor={tag.id}>{tag.techName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Archive Status filter */}
              <div className={`row p-0 mt-0 ${filterData.activeFilter == "archiveStatus" ? "filter-body" : "filter-body-collapse"}`}>
                <div className="instructor-filter-wrapper border p-0">
                  <div className="filter-name" data-filter="archiveStatus"
                    onClick={handleFilterChange}>
                    <p className="ms-2">Archive Status </p>
                    <span className="me-1">{filterData.activeFilter == "archiveStatus" ? <ExpandMore /> : <Chevron />}</span>
                  </div>
                  <div className="filter-data ">
                    {archiveStatus && archiveStatus.map((archive) => (
                      <div className="d-flex" key={archive.id}>
                        <input type="checkbox" name="archive"
                          className="tt-select-checkbox"
                          value={archive.id}
                          id={archive.status}
                          onChange={handleIndiudalOptionCheck}
                          checked={currentState.getIsArchive.includes(parseInt(archive.id))} />&nbsp;&nbsp;
                        <label htmlFor={archive.status}>{archive.status}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center filter-btn">
              <button type="button" className="pp-no-btn me-1" onClick={handelReset}>
                Clear All
              </button>
              <button type="button" className="publish-yes-btn ms-1" onClick={handelApply}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechTalkFilter;