import React, {useContext} from "react";
import { ReactComponent as Sor } from "../../../../Assets/EmojiSorry.svg";
import "./Sorry.css";
import { UserContext } from "../../../../Context/Context";
import CryptoJS from "crypto-js";
import html2canvas from "html2canvas";

const Sorry = (props) => {
  const {skillAddedData} = props;
  const { score, setScore, examName, examLevel, dateOfTest, timeOfTest } =
    useContext(UserContext);

  const secretkeyUser = process.env.REACT_APP_USER_KEY;
  var parsedObject;
  const data = localStorage.getItem("userData");
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
    const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
    parsedObject = JSON.parse(decryptedJsonString);
  } else {
console.error("No data found.");  }
var userName = parsedObject.decoded.FirstName + " " + parsedObject.decoded.LastName;

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}
  const continueHandler = () => {
    setScore(-1);
    exitFullscreen();
    skillAddedData();
  };

  const handleDownload = () => {
    const sorryComponent = document.getElementById("sorryComponent");
    sorryComponent.classList.add("screenshot-capture");

    html2canvas(sorryComponent, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${examName}_${examLevel}.jpeg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      sorryComponent.classList.remove("screenshot-capture");
    });
    exitFullscreen();
    skillAddedData();
  };

  return (
    <div>
      <div className="sorry-parent-wrapper">
        <div id="sorryComponent" className="sorry-child-wrapper">
          <div className="row crossBtn">
            <button
              type="button"
              className="btn-close"
              onClick={continueHandler}
            ></button>
          </div>
          <div className="row sEmoji">
            <Sor />
          </div>
          <div className="row s1">
            <p>Ohh Sorry!</p>
          </div>
          <p className="d-flex justify-content-center mb-0 sorry-username">
            {userName}
          </p>
          <div className="row s2 mt-2">
            <p>Your Score</p>
          </div>
          <div className="row s3">
            <p>
              {score}
              <span className="text-dark">/100</span>
            </p>
          </div>
          <div className="row s4">
            <p>
              Looks like you didn't score high enough to collect{" "}
              <b>
                {examName}: {examLevel} Level Star
              </b>{" "}
              in your skillset at{" "}
              <b>
                {dateOfTest} {timeOfTest}.
              </b>{" "}
              Keep practicing and try again to improve your score.
            </p>
          </div>
          <div className="row Scontinue">
            <button
              type="button"
              className="continueBtn"
              onClick={handleDownload}
              id="sorrydownloadButton"
            >
              Download
            </button>
            <button
              type="button"
              className="sorry-view-result-btn"
              onClick={continueHandler}
              id="sorrycloseButton"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sorry;
