import { useNavigate, useState, useEffect, useReducer, decryptData, CustomModal,handleApiError, axios, Link, ArrowForwardIcon, UsersDropdown, ReviewerDropdown, ExpandMore, AssignImage, CalendarIcon, SuccessfullVector, AlertInfo } from "../../AdminImport";
import "./AssignReviewer.css";
import AdminActionType from "../../AdminActionType";
import UserActionType from "../../../_common/UserActionType";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomInput({ value, onClick }) {
  return (
    <div className="row review-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 review-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">{value ? "" : <CalendarIcon />}</span>
    </div>
  );
}

const initialState = {
  reviewers: {},
  selectedUserIds: [],
  selectedUserName: [],
  selectedUsers: [],
  selectAllChecked: false,
  selectedReviewerIds: [],
  selectedReviewers: [],
  searchReviewerQuery: "",
  endDate: "",
  usersValue: "",
  reviewerValue: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_SELECT_ALL_USER_CHECKED:
      return { ...state, selectAllChecked: action.payload };
    case AdminActionType.SET_REVIEWERS:
      return { ...state, reviewers: action.payload };
    case AdminActionType.SET_SELECTED_REVIEWER_IDS:
      return { ...state, selectedReviewerIds: action.payload };
    case AdminActionType.SET_SELECTED_REVIEWERS:
      return { ...state, selectedReviewers: action.payload };
    case AdminActionType.SET_END_DATE:
      return { ...state, endDate: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case AdminActionType.SET_REVIEWER_VALUE:
      return { ...state, reviewerValue: action.payload };
    default:
      return state;
  }
};

const AssignReviewer = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dropDown, setDropDown] = useState({
    reviewerDropDown: false,
    userDropdown: false,
  });
  const [modalData,setModalData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });

  const handleClose = () => {
    setModalData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
    dispatch({ type: AdminActionType.SET_END_DATE, payload: "" });
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: [] });
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: [] });
    dispatch({ type: AdminActionType.SET_SELECTED_REVIEWERS, payload: [] });
    dispatch({ type: AdminActionType.SET_SELECTED_REVIEWER_IDS, payload: [] });
    dispatch({ type: UserActionType.SET_USERS_VALUE, payload: "" });
    dispatch({ type: AdminActionType.SET_REVIEWER_VALUE, payload: "" });
    setDropDown((prevState) => ({
      ...prevState,
      reviewerDropDown: false,
      userDropdown: false,
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const isUserEmpty = Array.isArray(state.selectedUserName) && state.selectedUserName.length !== 0;
    if (isUserEmpty) {
      const userValueString = state.selectedUserName.join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  useEffect(() => {
    const reviewerValueString = Object.values(state.reviewers).join(", ");
    dispatch({
      type: AdminActionType.SET_REVIEWER_VALUE,
      payload: reviewerValueString,
    });
  }, [state.reviewers]);

  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const reviewersDataComingFrmChild = (data) => {
    dispatch({ type: AdminActionType.SET_REVIEWERS, payload: data });
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };

  const formatDateToString = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/assignReviewer`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      learnerUserId: state.selectedUserIds,
      mentorUserId: state.selectedReviewerIds,
      learnerUserName:state.selectedUserName,
      endDate: formatDateToString(state.endDate),
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success === 200) {
        setModalData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: `${response.data.message}`,
          modalMessage: "",
          icon: <SuccessfullVector/>,
        }));
      } else {
        setModalData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "success",
          modalTitle: "Error !",
          modalMessage: `${response.data.message}`,
          icon: <AlertInfo/>,
        }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate >= currentDate) {
      dispatch({
        type: AdminActionType.SET_END_DATE,
        payload: date,
      });
    } else if (date == null) {
      dispatch({
        type: AdminActionType.SET_END_DATE,
        payload: "",
      });
    } else {
      dispatch({
        type: AdminActionType.SET_END_DATE,
        payload: "",
      });
    }
  };

  return (
    <div className="container">
      <Link to="/admin/sde/report" className="about-link">
        Assign Reviewer <ArrowForwardIcon style={{ marginBottom: "2px" }} />
      </Link>
      <div className="row assign-review-box ms-0 px-3 pt-2">
        <div className="row pe-0 assign-review-outer">
          <div className="col px-0">
            <label className="form-label assign-review-text mb-0">Select SDEs</label>
            <div
              className="input-with-button"
              style={{
                height: "38px",
                backgroundColor: "#fff",
                color: "#000",
                borderRadius: "4px",
                border: "1px solid var(--secondary-gray, #B2B2B3)",
              }}
            >
              <input style={{ width: "22.5rem" }} type="text" className="custom-input" placeholder="Select SDEs" value={state.usersValue && state.selectedUserName.length != 0 ? state.usersValue : ""} disabled />
              <div
                className="modal-abs"
                style={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  zIndex: 2,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDropdownToggle("userDropdown");
                }}
              ></div>
              <ExpandMore />
            </div>
            <div>
            {dropDown.userDropdown &&(<ul
                style={{
                  height: "10rem",
                  width: "24rem",
                }}
                className="ul-styling m-0 p-3"
              >
                <UsersDropdown dispatch={dispatch} selectedBatches={props.selectedBatches} usersDataComingChild={usersDataComingFrmChild} selectedUserIdsfromChild={handleUserIdChange} selectedUserIds={state.selectedUserIds} selectedUserName={state.selectedUserName} selectAllUsersCheck={state.selectAllChecked} selectedUsers={""} filterSdeOnly={true}/>
              </ul>)}
            </div>
          </div>
        </div>
        <div className="row pe-0 assign-review-outer">
          <div className="col ps-0">
            <label className="form-label assign-review-text mb-0">Assigned To</label>
            <div
              className="input-with-button"
              style={{
                height: "38px",
                backgroundColor: "#fff",
                color: "#000",
                borderRadius: "4px",
                border: "1px solid var(--secondary-gray, #B2B2B3)",
              }}
            >
              <input style={{ width: "10rem" }} type="text" className="custom-input" placeholder="Select Reviewer" value={state.reviewerValue} disabled />
              <div
                className="modal-abs"
                style={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  zIndex: 2,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDropdownToggle("reviewerDropDown");
                }}
              ></div>
              <ExpandMore />
            </div>
            <div>
            {dropDown.reviewerDropDown && (<ul
                style={{
                  height: "maxContent",
                  width: "11.6rem",
                }}
                className="ul-styling m-0 p-3"
              >
                <ReviewerDropdown dispatch={dispatch} reviewersDataComingChild={reviewersDataComingFrmChild} selectedReviewerIds={state.selectedReviewerIds} selectedReviewers={state.selectedReviewers} />
              </ul>)}
            </div>
          </div>
          <div className="col px-0">
            <label className="form-label assign-review-text mb-0">End Date</label>
            <div className="col filter-container">
              <DatePicker popperPlacement="top-end" selected={state.endDate} onChange={(date) => handleDateChange(date)} dateFormat="MM-dd-yyyy" minDate={new Date()} customInput={<CustomInput />} isClearable={state.endDate ? true : false} />
            </div>
          </div>
        </div>
        <div className="row pe-0 assign-review-outer">
          <div className="col px-0">
            <button
              className="btn btn-primary assign-review-button"
              disabled={state.selectedUserIds.length === 0 || state.selectedReviewerIds.length === 0 || !state.endDate}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              <AssignImage /> &nbsp;&nbsp;Assign
            </button>
          </div>
        </div>
      </div>
      {modalData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: modalData.icon,
            show: modalData.isModalOpen,
            type: modalData.modalType,
            title: modalData.modalTitle,
            message: modalData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </div>
  );
};

export default AssignReviewer;
