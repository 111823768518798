import { useNavigate, useState, useEffect, axios, SearchIcon, decryptData, handleApiError } from "../../AdminImport";
import AdminActionType from "../../AdminActionType";

const ReviewerDropdown = (props) => {
  const { dispatch, selectedReviewers, selectedReviewerIds, reviewersDataComingChild } = props;
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const navigate = useNavigate();

  const fetchReviewerList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAllUserByRole`;
    const body ={
      roleName : process.env.REACT_APP_USER_DES_MENTOR
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user.firstName || ""} ${user.lastName || ""}`,
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setAllUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response: null;
      handleApiError(statusCode, navigate);
    }
  }

  useEffect(() => {
    fetchReviewerList();
  }, []);

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allUser = filteredUsers.map((c) => c.name);
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWERS,
        payload: allUser
      });
      const allUserId = filteredUsers.map((c) => c.id);
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWER_IDS,
        payload: allUserId
      });
    } else {
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWER_IDS,
        payload: []
      });
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWERS,
        payload: []
      });
    }
  };

  const handleUsersChange = (e, c) => {
    if (e.target.checked) {
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWERS,
        payload: [...selectedReviewers, c.name]
      });
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWER_IDS,
        payload: [...selectedReviewerIds, c.id]
      });
    } else if (!e.target.checked) {
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWERS,
        payload: selectedReviewers.filter((item) => item !== c.name)
      });
      dispatch({
        type: AdminActionType.SET_SELECTED_REVIEWER_IDS,
        payload: selectedReviewerIds.filter((item) => item !== c.id)
      });
    }
  };

  useEffect(() => {
    if (selectedReviewers) {
      reviewersDataComingChild(selectedReviewers);
    }
  }, [selectedReviewers]);

  const handleSearchChange = (e) => {
    const filtered = allUsers.filter((user) => {
      return user.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    });
    setFilteredUsers(filtered);
  };

  return (
    <>
      <div
        className="d-flex align-items-center ps-1 associate-search-log-wrapper mb-2"
        style={{ width: "100%" }}
      >
        <SearchIcon />
        <input
          className="search-dropdowns"
          type="text"
          placeholder="Search..."
          onChange={handleSearchChange}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
      {filteredUsers?.length != 0 &&
        <div className="form-check small checkbox gap-1">
          <input
            className="form-check-input"
          type="checkbox"
            id="ReviewerSelectAll"
            checked={selectedReviewers?.length === filteredUsers.length}
            onChange={handleCheckAllChange}
          />
          <label className="form-check-label" htmlFor="ReviewerSelectAll">
            Select all
          </label>
        </div>}

        <div className="dp-scroll" style={{ maxHeight: "6rem", overflowY: "scroll" , paddingLeft:"5px", width: "100%"}}>
        {filteredUsers.map((c) => (
          <div className="form-check small checkbox gap-1" key={c.id}>
            <input
              className="form-check-input"
              type="checkbox"
              id={c.id}
              checked={selectedReviewerIds?.includes(c.id)}
              onChange={(e) => handleUsersChange(e, c)}
            />
            <label className="form-check-label" htmlFor={c.id}>
              {c.name}
            </label>
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default ReviewerDropdown;
