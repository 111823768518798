import { axios, decryptData, useNavigate, DeleteWarning, handleApiError } from "../AdminImport"
import "./Successfull.css";


const DeleteTask = (props) => {
  const { id, version, setDeleteTask } = props;
  const navigate = useNavigate();

  const handleCross = (e) => {
    e.preventDefault();
    setDeleteTask((prevState) => ({
      ...prevState,
      isModalOpen: false,
      showDeleteModal: false
    }));
  };

  const confirmDelete = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/delete?taskId=${id}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }

    try {

      const response = await axios.delete(url, { headers });
      if (response.status == 200) {
        version((prevState) => ({
          ...prevState,
          version: prevState.version + 1
        }));
        setDeleteTask((prevState) => ({
          ...prevState,
          isModalOpen: false,
          showDeleteModal: false
        }))
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  }
  return (
    <div className="delete-task-wrapper">
      <div className="pp-popup-wrapper">
        <div className="pp-cross-btn mt-2">
          <button
            type="button"
            className="btn-close me-2"
            onClick={(e) => handleCross(e)}
          ></button>
        </div>
        <div className="row mt-5">
          <DeleteWarning />
        </div>
        <div className="row mt-3 d-flex ">
          <p className="d-flex justify-content-center fw-bold">Delete Task? </p>
          <p className="d-flex justify-content-center">
            Are you sure you want to delete the task?
          </p>
        </div>
        <div className="row d-flex justify-content-center mb-4">
          <button
            type="button"
            className="pp-no-btn me-1"
            onClick={(e) => handleCross(e)}
          >
            No, Cancel
          </button>
          <button
            type="button"
            className="pp-yes-btn ms-1"
            onClick={() => confirmDelete()}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteTask;
