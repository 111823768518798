import { useEffect, useRef, useState, useReducer, useNavigate, axios, decryptData, Select, TechActionType, ExpandMore, Info, CameraIcon, ProfileDetails, PermissionsDisabled, HorizontalLine, ProfileDetailsDone, PermissionsEnabled, uploadImage } from "../../AdminImport";
import { Modal } from "react-bootstrap";
import TechDropdown from "../AssignTask/TechDropdown";
import { storage } from "../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./AddMentorModal.css";

const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    default:
      return state;
  }
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    margin: "auto",
    marginTop: "0.37rem",
    height: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    width: "100%",
    margin: "auto",
    maxHeight: "13rem",
    overflow: "auto",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),
};
const position = [
  { id: "94461ab9-0999-4b7c-ac79-14409a14061c", name: 'Vice President 1' },
  { id: "6c3a38ec-f526-434f-b0e6-85efe344e140", name: 'Vice President 2' },
  { id: "a245c98d-d603-4619-acee-df6e00db2fa1", name: 'Principal 1' },
  { id: "e9733a66-fa07-4096-9405-68cfe2b1f8d8", name: "Principal 2" },
  { id: "aded1246-b803-4c32-9477-80717d48f0bc", name: 'Principal 3' },
  { id: "29784b2a-00d1-4d76-9bcd-c9ea3beb770d", name: 'Manager 1' },
  { id: "dddf0295-aaeb-4390-ba93-b9c61c4ba947", name: 'Manager 2' },
  { id: "81d1f0b8-81b7-4213-86af-1c97bde6b99b", name: 'Manager 3' },
  { id: "72564d55-1ca5-412e-aa96-2ba399eb3933", name: 'Associate 1' },
  { id: "d659fb0c-9794-4b86-a014-a7e2330ff568", name: 'Associate 2' },
  { id: "8004491d-a629-4c0d-9b05-f0206dabb3d6", name: 'Consultant 1' },
  { id: "5b78a445-f073-4fed-a8cf-320618bb6d33", name: 'Consultant 2' },
  { id: "bf730af8-9dd4-4fe8-90be-24cb19e33e8b", name: 'SDE' },
  { id: "71db6917-c950-4042-8be3-003b68b2b326", name: 'Intern' },
];
const EditMentorModal = (prop) => {
  const { selectedMentor, show, onHide, fetchMentorList, fetchUserList } = prop;
  const [state, dispatch] = useReducer(reducer, initialState);

  const [editMentor, setEditMentor] = useState({
    id: selectedMentor.id,
    mentorName: `${selectedMentor.firstName} ${selectedMentor?.lastName}`,
    email: `${selectedMentor.email}`,
    designationName: `${selectedMentor.designation}`,
    designationId: `${selectedMentor.designationId}`,
    technologyNames: selectedMentor.skills,
    permissions: `${selectedMentor.userPermission !== "Inactive" ? selectedMentor.userPermission : ""} `,
    isMentorActive: selectedMentor?.userPermission==null || selectedMentor?.userPermission==="" ?false:true,
    techDropdown: false
  });

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const handleClickClear = () => {
    onHide();
    setEditMentor((prevState) => ({
      ...prevState,
      id: "",
      mentorName: "",
      email: "",
      designationName: "",
      designationId: "",
      technologyNames: "",
      imageUrl: "",
      permissions: "",
      isMentorActive: false,
      techDropdown: false,
      error: {
        name: false,
        email: false
      },
    }))
    setCurrentPage(1);
  };

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames).length !== 0;
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(', ');
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }

  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleFormSubmit = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/UpdateUserRole`;
    const body = {
      id: editMentor.id,
      skillsId: state.selectedTechIds,
      isLoginAllowed: editMentor.isMentorActive,
      userPermission: selectedMentor.userPermission !== "Inactive" ? editMentor.permissions.trim() : editMentor.permissions.substring(0, editMentor.permissions.lastIndexOf(",")),
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    }
    const response = await axios.post(url, body, { headers });
    if (response.status == 200) {
      setCurrentPage(1);
      handleClickClear();
      fetchMentorList();
      fetchUserList();
    }
    else {
      alert(response.data.result.message)
    }
  };

  const handleEdit = (e) => {
    const { name, value, ariaLabel } = e.target;
    switch (name) {
      case "IsLoginAllowed":
        setEditMentor((prevState) => ({
          ...prevState,
          isMentorActive: !prevState.isMentorActive,
        }));
        break;
      case "permission":
        if (editMentor.permissions.includes(ariaLabel)) {
          setEditMentor((prevState) => ({
            ...prevState,
            permissions: editMentor.permissions.split(',').filter(permission => permission.trim() !== ariaLabel)
              .join(',')
              .trim()
          }));
        } else {
          setEditMentor((prevState) => ({
            ...prevState,
            permissions: (editMentor.permissions.length == 0 ? "" : ariaLabel + "," + prevState.permissions)
          }));
        }
        break
    }
  };
  useEffect(() => {
    fetchUserList();
  }, [])
  
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton onClick={handleClickClear}>
        <Modal.Title className="modalheading-text">Edit Mentor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <>
            <div className="mentor-step">
              <div className="row">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="row">{<ProfileDetails />}</div>
                  <div
                    className="row fw-bold permissions-text"
                    style={{ width: "6rem" }}
                  >
                    Profile Details
                  </div>
                </div>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <HorizontalLine />
                </div>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="row">{<PermissionsDisabled />}</div>
                  <div
                    className={`row permissions - text permissions - disabled - text`}
                    style={{ width: "6rem" }}
                  >
                    Permissions
                  </div>
                </div>
              </div>
            </div>
            <div className="align-items-center">
              <div className="modal-body">
                <form>
                  <div className="mb-2">
                    <label
                      htmlFor="validationServer01"
                      className="col-form-label d-flex"
                    >
                      Mentor Name<span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="validationServer01"
                      name="mentorName"
                      placeholder="Enter Mentor Name"
                      disabled
                      value={editMentor.mentorName || ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="skills" className="col-form-label d-flex">
                      Technology Tags<span style={{ color: "red" }}>*</span>
                    </label>

                    <div
                      className="container border p-0"
                      style={{ borderRadius: "0.375rem" }}
                    >
                      <div
                        className="input-with-button"
                        onClick={() => {
                          setEditMentor((prevState) => ({
                            ...prevState,
                            techDropdown: !prevState.techDropdown
                          }))
                        }}
                      >
                        <input
                          style={{ width: "25.3rem" }}
                          type="text"
                          className="custom-input"
                          placeholder="Select Technology"
                          value={state.techValue ? state.techValue : editMentor.technologyNames}
                          disabled
                        />
                        <div
                          className="modal-abs"
                          style={{
                            position: "absolute",
                            left: "0",
                            right: "0",
                            top: "0",
                            bottom: "0",
                          }}
                        ></div>
                        <ExpandMore />
                      </div>
                      <div>
                        <ul
                          style={{
                            display: editMentor.techDropdown ? "" : "none",
                            width: "27.2rem",
                          }}
                          className="ul-styling"
                        >
                          <TechDropdown
                            dispatch={dispatch}
                            techDataComingChild={handleTechChange}
                            selectedTechIdsfromChild={handleTechIdChange}
                            selectedTechIds={state.selectedTechIds}
                            technologyNames={state.selectedTechNames}
                            selectedTech={selectedMentor.skills}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn cancel-button fw-bold"
                onClick={handleClickClear}
              >
                <span className="cancel-text">Cancel</span>
              </button>

              <button
                type="button"
                className="btn mentor-save-button fw-bold"
                onClick={() => {
                  setCurrentPage(2)
                }}
                disabled={
                  state.selectedTechIds.length === 0
                }
              >
                <span className="save-text">Next</span>
              </button>
            </div>
          </>
        )}
        {currentPage === 2 && (
          <>
            <div className="mentor-step">
              <div className="row">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="row">{<ProfileDetailsDone />}</div>
                  <div
                    className="row fw-bold permissions-text"
                    style={{ width: "6rem" }}
                  >
                    Profile Details
                  </div>
                </div>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <HorizontalLine />
                </div>
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <div className="row">{<PermissionsEnabled />}</div>
                  <div
                    className={`row permissions - text fw - bold`}
                    style={{ width: "6rem" }}
                  >
                    Permissions
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "28rem",
                }}
              >
                <h5
                  className="m-0 modalheading-text"
                  style={{ fontSize: "14px" }}
                >
                  Activate Mentor
                </h5>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input small me-2"
                    type="checkbox"
                    id="toggleButton"
                    name="IsLoginAllowed"
                    checked={editMentor.isMentorActive}
                    value={editMentor.isMentorActive}
                    onChange={handleEdit}
                  />
                </div>
              </div>

              <div className="alert alert-info mb-0 box-for-alert-message mt-2 ms-0" role="alert">
                <div className="d-flex justify-content-center align-items-center">
                  <Info />
                </div>
                <div className="text-for-alert">
                  <p className="m-0 mx-2">
                    Activating a mentor will require to select any permission
                    level from below.
                  </p>
                </div>
              </div>

              <div className="mt-3">

                <div className="mt-2">
                  <input
                    className="form-check-input small me-2"
                    type="checkbox"
                    id="checkbox1"
                    name="permission"
                    aria-label="Session"
                    checked={editMentor.permissions.includes("Session")}
                    onChange={(e) => {
                      handleEdit(e);
                    }}
                    disabled={!editMentor.isMentorActive}
                  />
                  <label
                    className="form-check-label "
                    style={{ fontWeight: "600", fontSize: "14px" }}
                    htmlFor="checkbox1"
                  >
                    Training Session
                  </label>
                  <ul
                    style={{
                      listStyleType: "disc",
                      margin: "0 0 0 20px",
                      paddingLeft: 10,
                      fontSize: "13px",
                      color: editMentor.isMentorActive ? "inherit" : "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <li>Dashboard access</li>
                    <li>
                      Can conduct training sessions and assign & review tasks
                    </li>
                    <li>Can see the session calendar</li>
                    <li>Know your mentor will be automatically activated</li>
                  </ul>
                </div>

                <div className=" mt-2">
                  <input
                    className="form-check-input small me-2"
                    type="checkbox"
                    id="checkbox2"
                    checked={editMentor.permissions.includes("Project")}
                    name="permission"
                    aria-label="Project"
                    onChange={(e) => {
                      handleEdit(e)
                    }}
                    disabled={!editMentor.isMentorActive}
                  />
                  <label
                    className="form-check-label"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                    htmlFor="checkbox2"
                  >
                    Project
                  </label>
                  <ul
                    style={{
                      listStyleType: "disc",
                      margin: "0 0 0 20px",
                      paddingLeft: 10,
                      fontSize: "13px",
                      color: editMentor.isMentorActive ? "inherit" : "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <li>Can assign tasks to SDEs</li>
                    <li>Can give ratings as per the task</li>
                    <li>Know your mentor will be automatically activated</li>
                  </ul>
                </div>

                <div className=" mt-2">
                  <input
                    className="form-check-input small me-2"
                    type="checkbox"
                    id="checkbox3"
                    checked={editMentor.permissions.includes("Review")}
                    name="permission"
                    aria-label="Review"
                    onChange={(e) => {
                      handleEdit(e)
                    }}
                    disabled={!editMentor.isMentorActive}
                  />
                  <label
                    className="form-check-label"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                    htmlFor="checkbox3"
                  >
                    Final Review
                  </label>
                  <ul
                    style={{
                      listStyleType: "disc",
                      margin: "0 0 0 20px",
                      paddingLeft: 10,
                      fontSize: "13px",
                      color: editMentor.isMentorActive ? "inherit" : "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <li>Only review the SDEs</li>
                  </ul>
                </div>

                <div className=" mt-2">
                  <input
                    className="form-check-input small me-2"
                    type="checkbox"
                    id="checkbox4"
                    checked={editMentor.permissions.includes("Know Your Mentor")}
                    name="permission"
                    aria-label="Know Your Mentor"
                    onChange={(e) => {
                      handleEdit(e)
                    }}
                    disabled={!editMentor.isMentorActive}
                  />
                  <label
                    className="form-check-label"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                    htmlFor="checkbox4"
                  >
                    Know Your Mentor - Widget
                  </label>
                  <ul
                    style={{
                      listStyleType: "disc",
                      margin: "0 0 0 20px",
                      paddingLeft: 10,
                      fontSize: "13px",
                      color: editMentor.isMentorActive ? "inherit" : "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <li>
                      Only the mentor's details will be displayed on user portal
                    </li>
                    <li>
                      Will be automatically activated for Training Session and
                      Project access
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="modal-footer border-top-0">
              <div
                className="d-flex justify-content-between"
                style={{ width: "466px" }}
              >
                <button
                  type="button"
                  className="btn cancel-button"
                  onClick={() => {
                    setCurrentPage(1)
                  }}
                >
                  <span className="cancel-text">Back</span>
                </button>

                <div >
                  <button
                    type="button"
                    className="btn cancel-button fw-bold me-2"
                    data-bs-dismiss="modal"
                    onClick={handleClickClear}
                  >
                    <span className="cancel-text">Cancel</span>
                  </button>

                  <button
                    type="button"
                    className="btn mentor-save-button fw-bold"
                    onClick={handleFormSubmit}
                  >
                    <span className="save-text">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal >
  );
};

export default EditMentorModal;
