import { ExpandIcon, Skeleton } from "../../../AdminImport";
import "react-loading-skeleton/dist/skeleton.css";

const TotalEnrolledUsersTableSkeleton = () => {
  return (
    <tr>
      <td className="total-enroll-user-table-height total-enroll-arrow-col text-center">
        <ExpandIcon />
      </td>
      <td className="total-enroll-user-table-height total-enroll-name-col total-enroll-name-text">
        <Skeleton width={250} />
      </td>
      <td className="total-enroll-user-table-height total-enroll-course-col total-enroll-course-text text-center">
        <Skeleton width={100} />
      </td>
      <td className="total-enroll-user-table-height total-enroll-milestone-col total-enroll-milestone-text">
        <div className="d-flex align-items-center justify-content-center">
          <Skeleton className="me-2" width={130} count={2} inline />
        </div>
      </td>
    </tr>
  );
};

export default TotalEnrolledUsersTableSkeleton;
