import { useState, useEffect, axios, decryptData, handleApiError, useNavigate, Pass, Fail, EmptyMentorNotifications, SkillAlertSkeleton } from "../../MentorImport";
import "./SkillAlerts.css";

const SkillAlerts = (props) => {
  const { selectedBatches } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const formattedDate = dateString.split("T")[0];
    const [year, month, day] = formattedDate.split("-");

    const formatDate = `${month}-${day}-${year}`;
    return formatDate;
  };

  const fetchNotifications = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/skillAlert`;
    let body = {
      batchId: selectedBatches.length === 0 ? null : selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        setNotifications(response.data.result);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const passNotifications = notifications?.filter((notification) => notification?.examResult === "Pass");
  const failNotifications = notifications?.filter((notification) => notification?.examResult === "Fail");

  useEffect(() => {
    fetchNotifications();
  }, [selectedBatches]);

  return (
    <>
      <p className="about-link p-0 mobile-view-skill-alert">Skill Alerts</p>
      {isLoading ? (
        <>
          <div className="notification-pass card">
            <div className="pass-head">
              <Pass />
              <h5 className="card-title skill-alert-head">Pass</h5>
            </div>
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
          </div>
          <div className="notification-fail card">
            <div className="fail-head">
              <Fail />
              <h5 className="card-title skill-alert-head">Fail</h5>
            </div>
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
            <SkillAlertSkeleton />
          </div>
        </>
      ) : (
        <div class="row" style={{ margin: "0", padding: "0" }}>
          <>
            <div className="col-xs-12 col-md-6 notification-pass card">
              <div className="pass-head">
                <Pass />
                <h5 className="card-title skill-alert-head">Pass</h5>
              </div>
              <div className="skill-alert-cover dp-scroll">
                {passNotifications?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center flex-column p-5">
                    <EmptyMentorNotifications />
                    <p className="fw-bold mt-3 mb-1 empty-report-text">No Recent Skill Test Data Right Now!</p>
                    <p className="empty-report-fade-text">We’ll notify you when something arrives.</p>
                  </div>
                ) : (
                  passNotifications?.map((notification, index) => (
                    <div className="notification-pass-wrapper" key={index}>
                      <div className="image-wrapper mt-1">
                        <div className="image-box">
                          <img src={notification.techimage} alt={notification.techName} width={32} />
                        </div>
                      </div>
                      <div className="text-wrapper mt-2">
                        <p className="m-0">
                          <b>{`${notification.firstName} ${notification.lastName}`}</b> has achieved <b>{notification.levelName}</b> skill on <b>{notification.techName}</b>
                        </p>
                        <p className="m-0 date-wrapper">{formatDate(notification.examDate)}</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="col-xs-12 col-md-6 notification-fail card">
              <div className="fail-head">
                <Fail />
                <h5 className="card-title skill-alert-head">Fail</h5>
              </div>
              <div className="skill-alert-cover dp-scroll">
                {failNotifications?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center flex-column p-5">
                    <EmptyMentorNotifications />
                    <p className="fw-bold mt-3 mb-1 empty-report-text">No Recent Skill Test Data Right Now!</p>
                    <p className="empty-report-fade-text">We’ll notify you when something arrives.</p>
                  </div>
                ) : (
                  failNotifications?.map((notification, index) => (
                    <div className="notification-pass-wrapper" key={index}>
                      <div className="image-wrapper mt-1">
                        <div className="image-box">
                          <img src={notification.techimage} alt={notification.techName} width={32} />
                        </div>
                      </div>
                      <div className="text-wrapper mt-2">
                        <p className="m-0">
                          <b>{`${notification.firstName} ${notification.lastName}`}</b> couldn't achieve <b>{notification.levelName}</b> skill on <b>{notification.techName}</b>
                        </p>
                        <p className="m-0 date-wrapper">{formatDate(notification.examDate)}</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
export default SkillAlerts;
