import { useState, useEffect, useNavigate, decryptData, axios, handleApiError, ChevronDown, ChevronUp, CourseCard, CourseCardSkeleton, ComingSoonEmptyState } from "../../UserImport";
import "./TechTalkSection.css";

const TechTalkSection = () => {
  const [showAllTechTalk, setShowAllTechTalk] = useState(false);
  const [selectedSection, setSelectedSection] = useState("popular");
  const [techTalkData, setTechTalkData] = useState({ popularData: [], recentData: [], isLoading: true });

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  var userId = parsedObject["decoded"]["Id"];

  const getTechTalkData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getAll?isArchived=1`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        const result = response.data.result.filter(techtalk => techtalk.isPublished && techtalk.isActive);
        const sortedResult = result.slice().sort((a, b) => b.viewsCount - a.viewsCount);
        setTechTalkData({
          popularData: sortedResult,
          recentData: result,
          isLoading: false,
        });
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const StartTechTalk = async (storeCourseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/startTechTalk?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("techTalkId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/techtalk");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  
  const ResumeTechTalk = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/resumeTechTalk?userId=${userId}&courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", courseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const toggleShowTechTalk = () => {
    setShowAllTechTalk(!showAllTechTalk);
  };

  const displayedPopularTechTalk = techTalkData?.popularData?.slice(0, 4);
  const displayedRecentTechTalk = techTalkData?.recentData?.slice(0, 4);

  useEffect(() => {
    getTechTalkData();
  }, []);

  return (
    <div className="d-grid gap-3 p-0">
      <div className="d-grid gap-2 justify-content-center">
        <div className="d-flex justify-content-center lms-tech-talk-heading">Tech Talk</div>
        <div className="d-flex justify-content-center lms-tech-talk-switch pointer">
          <div
            className={`d-flex justify-content-center align-items-center lms-popular-b-radius w-50 ${selectedSection === "popular" ? "lms-tech-talk-popular" : "lms-tech-talk-recent"}`}
            onClick={() => handleSectionClick("popular")}
          >
            Most Popular
          </div>
          <div
            className={`d-flex justify-content-center align-items-center lms-recent-b-radius w-50 ${selectedSection === "recent" ? "lms-tech-talk-popular" : "lms-tech-talk-recent"}`}
            onClick={() => handleSectionClick("recent")}
          >
            Recently Uploaded
          </div>
        </div>
      </div>
      <div
        className={`d-grid gap-2 ${selectedSection === "popular" ? showAllTechTalk ? displayedPopularTechTalk?.length === 3 ? "read-less-wrap-third" : "read-less-wrap" : displayedPopularTechTalk?.length > 2 ? "read-more-wrap-height" : "read-more-wrap" : showAllTechTalk ? displayedRecentTechTalk?.length === 3 ? "read-less-wrap-third" : "read-less-wrap" : displayedRecentTechTalk?.length > 2 ? "read-more-wrap-height" : "read-more-wrap"}`}>
        {selectedSection === "popular" ? (
          techTalkData.isLoading ? (
            Array.from({ length: 2 }).map((_, index) => <CourseCardSkeleton key={index} />)
          ) : techTalkData.popularData && techTalkData?.popularData?.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <ComingSoonEmptyState />
              <p className="fw-bold mt-3 mb-1 empty-lms-techtalk-text">Coming Soon!</p>
              <p className="empty-lms-techtalk-fade-text mt-1">Stay tuned, tech talk is just around the corner.</p>
            </div>
          ) : (
            displayedPopularTechTalk?.map((course, index) => (
              <div className={`${index > 1 && !showAllTechTalk ? "read-more-target" : "read-less-target"}`}>
                <CourseCard
                  key={index}
                  courseCardProp={{
                    courseType: false,
                    activityType: "Play",
                    courseImageLink: course?.thumbnailUri,
                    activityInstructor: course?.instructorName,
                    activityEnrolledOrViews: course?.viewsCount,
                    activityName: course?.title,
                    activityDescription: course?.description,
                    activityDuration: course?.totalVideosLength,
                    activityStartDate: course?.createdDate,
                    activityResume: () => ResumeTechTalk(course?.id),
                    activityPlay: () => StartTechTalk(course?.id),
                  }}
                />
              </div>
            ))
          )
        ) : techTalkData.isLoading ? (
          Array.from({ length: 2 }).map((_, index) => <CourseCardSkeleton key={index} />)
        ) : techTalkData.recentData && techTalkData?.recentData?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center flex-column h-100">
            <ComingSoonEmptyState />
            <p className="fw-bold mt-3 mb-1 empty-lms-techtalk-text">Coming Soon!</p>
            <p className="empty-lms-techtalk-fade-text mt-1">Stay tuned, tech talk is just around the corner.</p>
          </div>
        ) : (
          displayedRecentTechTalk?.map((course, index) => (
            <div className={`${index > 1 && !showAllTechTalk ? "read-more-target" : "read-less-target"}`}>
              <CourseCard
                key={index}
                courseCardProp={{
                  courseType: false,
                  activityType: "Play",
                  courseImageLink: course?.thumbnailUri,
                  activityInstructor: course?.instructorName,
                  activityEnrolledOrViews: course?.viewsCount,
                  activityName: course?.title,
                  activityDescription: course?.description,
                  activityDuration: course?.totalVideosLength,
                  activityStartDate: course?.createdDate,
                  activityResume: () => ResumeTechTalk(course?.id),
                  activityPlay: () => StartTechTalk(course?.id),
                }}
              />
            </div>
          ))
        )}
      </div>
      {!techTalkData.isLoading && !(techTalkData?.popularData && techTalkData?.popularData?.length === 0) && !(techTalkData?.recentData && techTalkData.recentData?.length === 0) && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          {selectedSection === "popular" && displayedPopularTechTalk.length > 2 && (
            <div className="d-flex align-items-center gap-2 pointer" onClick={toggleShowTechTalk}>
              <div className="m-0 lms-show-less-more">{showAllTechTalk ? "Show Less" : "Show More"}</div>
              <div>{showAllTechTalk ? <ChevronUp className="chevron-lms" /> : <ChevronDown className="chevron-lms" />}</div>
            </div>
          )}
          {selectedSection === "recent" && displayedRecentTechTalk.length > 2 && (
            <div className="d-flex align-items-center gap-2 pointer" onClick={toggleShowTechTalk}>
              <div className="m-0 lms-show-less-more">{showAllTechTalk ? "Show Less" : "Show More"}</div>
              <div>{showAllTechTalk ? <ChevronUp className="chevron-lms" /> : <ChevronDown className="chevron-lms" />}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TechTalkSection;
