import React from 'react';
import App from "./App";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css'
import 'bootstrap';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './components/Accounts/Auth/authconfig';
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";
import Context from "./Context/Context";

export const pca = new PublicClientApplication(msalConfig);
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Context>
    <Router>
      <App msalInstance={pca} />
    </Router>
  </Context>
  //  </React.StrictMode>
);
document.onkeydown = function(e) {
    if(e.keyCode == 123) {
     return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
     return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
     return false;
    }
    if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
     return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)){
     return false;
    }      
 }