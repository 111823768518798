import {Skeleton} from "../MentorImport";

const MentorAssignTaskSkeletonLoading = () => {
  return (
    <div className="task-heading-inner-wrapper">
      <div className="task-heading-head">
        <h5 className="mb-0">
          <Skeleton width={290} height={20} />
        </h5>
      </div>
      <div className="task-heading-body">
        <p className="mb-0">
          <Skeleton width={329} height={32} />
        </p>
      </div>
    </div>
  );
};

export default MentorAssignTaskSkeletonLoading;
