export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_POSTLOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
    },
};
export const loginRequest = {
    scopes: ["User.Read"]
};