import { ArrowForwardIcon, useNavigate, CertificationsAchievedSkeleton, EmptyCertificate,TotalCourses} from "../../AdminImport";
import "../CertificationsAchieved/CertificationAchievedFile.css";

const CertificationsAchievedFile = (props) => {
  const { originalMilestoneData, loading } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/admin/lms/certificates-achieved");
  };
  
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array?.length; i += size) {
      chunkedArray.push(array?.slice(i, i + size));
    }
    return chunkedArray;
  };

  const chunkedCertifications = chunkArray(originalMilestoneData, 6);
  return (
    <div className="card certifications-achieved">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex pointer" onClick={handleClick}>
            <p className="mb-0 me-1 total-courses-head">Certification Achieved </p>
            <span className="d-flex align-items-center">
              <ArrowForwardIcon />
            </span>
          </div>
        </div>
      </div>
      <div id="carouselExampleIndicators" className="carousel slide certi-caro-slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {loading ? (
            <div className="d-flex justify-content-between">
              {Array.from({ length: 6 }).map((_, index) => (
                <CertificationsAchievedSkeleton key={index} />
              ))}{" "}
            </div>
          ) : originalMilestoneData?.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <EmptyCertificate />
              <p className="fw-bold mt-3 mb-1 empty-insights-text">No certificates have been uploaded by users at this moment</p>
              <p className="empty-insights-fade-text mt-1">We’ll notify you when something arrives.</p>
            </div>
          ) : (
            chunkedCertifications.map((chunk, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                <div className="d-flex justify-content-between">
                  {chunk.map((certification) => (
                    <div key={certification.subCategoryId} className="d-flex align-items-center pointer fw-600 milestone-card" onClick={handleClick}>
                      <div className="milestone-image">
                        <img src={certification.subCategoryImageLink!=null && certification.subCategoryImageLink!="" ? `data:image/svg+xml;base64,${certification.subCategoryImageLink}` : TotalCourses} width={29} height={29} alt={certification.subCategoryName} />
                      </div>
                      <div className="d-flex milestone-name-count pointer">
                        <p className="m-name mb-0">{certification.subCategoryName}</p>
                        <p className="m-count mb-0">{certification.totalCertificationCount.toString().padStart(2, "0")}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="carousel-indicators certification-carousel-indicators">
          {chunkedCertifications.map((chunk, index) => (
            <button
              key={index}
              type="button"
              style={{ backgroundColor: "#000" }}
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : undefined}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CertificationsAchievedFile;
