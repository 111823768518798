import React from 'react'
import Skeleton from "react-loading-skeleton";

const CourseUploadHistorySkeleton = () => {
  return (
    <div className="col-12 border-bottom">
      <div className="col-12 d-flex justify-content-between">
        <div className="pointer">
          <div className="col-12 d-flex gap-1">
            <Skeleton width={60} height={15} />
            <div className="mb-1 d-flex align-items-center">
              <Skeleton circle={true} width={8} height={8} />
            </div>
            <Skeleton width={100} height={15} />
          </div>
          <div className="col-12">
            <Skeleton width={200} height={20} />
          </div>
        </div>
        <div className="image-box">
          <Skeleton circle={true} width={32} height={32} />
        </div>
      </div>

      <div className="col-12 d-flex justify-content-between mb-2 mt-1">
        <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
          <Skeleton width={80} height={15} />
        </div>
        <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
          <Skeleton width={100} height={15} />
        </div>
        <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
          <Skeleton width={100} height={15} />
        </div>
      </div>
    </div>
  )
}

export default CourseUploadHistorySkeleton