import { Header, SideNav, BreadCrumbs, useEffect, axios, useState, useNavigate, TotalUploadedCoursesTable, ArchiveCourse, toast, Filter, FilterBase, decryptData, handleApiError} from "../UploadCoursesImport";
import "./TotalUploadedCourses.css";
import "react-toastify/dist/ReactToastify.css";

const TotalUploadedCourses = () => {
  const [taskToArchive, setTaskToArchive] = useState({
    isOpen: false,
    courseVersion: 0,
    courseIdToChild: 0,
    title: "",
    isActive: false,
  });
  const [totalCoursesState, setTotalCoursesState] = useState({
    tableData: [],
    orgTableData: [],
    apiLevel: [],
    apiGrade: [],
    isLoading: true,
  });
  const [filterState, setFilterState] = useState({
    showFilterBase: false,
    filtersApplied: false,
    selectedInsId: [],
    selectedSubCategoryId: [],
    selectedGradeId: [],
    selectedStatus: { archive: false, draft: false, published: false },
    selectedTechId: [],
    selectedLevelId: [],
    query: "",
  });

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const totalUploadedCoursesData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getAll`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setTotalCoursesState((prevState) => ({
          ...prevState,
          tableData: response.data.result,
          orgTableData: response.data.result,
          isLoading: false,
          apiLevel: prevState.apiLevel,
          apiGrade: prevState.apiGrade,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const confirmArchive = async () => {
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/archive?userId=${userId}&courseId=${taskToArchive.courseIdToChild}&isArchive=${taskToArchive.isActive}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.delete(url, { headers });
      if (response.status == 200) {
        setTaskToArchive((prevState) => ({
          ...prevState,
          isOpen: false,
          courseVersion: prevState.courseVersion + 1,
        }));
        taskToArchive.isActive ? toast.success("Course Archived Successfully !") : toast.success("Course Restored Successfully !");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setTotalCoursesState((prevState) => ({
          ...prevState,
          apiLevel: response.data.result,
          apiGrade: prevState.apiGrade,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchGrade = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getGradeType`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setTotalCoursesState((prevState) => ({
          ...prevState,
          apiGrade: response.data.result,
          apiLevel: prevState.apiLevel,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const handleFiltersChange = () => {
    const getSearchFilterItems = (items) => {
      if (filterState.query != "") {
        return items.filter((item) => item.title.toLowerCase().startsWith(filterState.query.toLowerCase()));
      }
      return items;
    };

    const getInsFilterItems = (items) => {
      if (filterState?.selectedInsId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedInsId?.some((selectedId) => item?.instructorList?.some((instructor) => instructor?.instructorId === selectedId));
        });
      }
      return items;
    };

    const getSubCatFilterItems = (items) => {
      if (filterState?.selectedSubCategoryId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedSubCategoryId?.some((selectedId) => item?.categoryInfo?.[0]?.subCategoryId === selectedId);
        });
      }
      return items;
    };

    const getGradeFilterItems = (items) => {
      if (filterState?.selectedGradeId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedGradeId?.some((selectedId) => item.gradeInfo === selectedId);
        });
      }
      return items;
    };

    const getStatusFilterItems = (items) => {
      const { archive, draft, published } = filterState.selectedStatus;
      if (!archive && !draft && !published) return items;

      let filteredItems = [];
      if (archive) {
        const filteredArchiveItems = items.filter((item) => !item.isActive);
        if (filteredArchiveItems.length > 0) {
          filteredItems = filteredItems.concat(filteredArchiveItems);
        }
      }
      if (draft) {
        const filteredDraftItems = items.filter((item) => !item.isPublished && item.isActive);
        if (filteredDraftItems.length > 0) {
          filteredItems = filteredItems.concat(filteredDraftItems);
        }
      }
      if (published) {
        const filteredPublishedItems = items.filter((item) => item.isPublished && item.isActive);
        if (filteredPublishedItems.length > 0) {
          filteredItems = filteredItems.concat(filteredPublishedItems);
        }
      }

      return filteredItems;
    };

    const getTagsFilterItems = (items) => {
      if (filterState.selectedTechId.length !== 0) {
        const filteredData = items.filter((item) => item?.tags?.some((tag) => filterState.selectedTechId.includes(tag.id)));
        return filteredData;
      }
      return items;
    };

    const getLevelFilterItems = (items) => {
      if (filterState?.selectedLevelId?.length !== 0) {
        return items?.filter((item) => {
          return filterState?.selectedLevelId?.some((selectedId) => item.levelId === selectedId);
        });
      }
      return items;
    };

    if (filterState.query !== "" || filterState.selectedInsId.length !== 0 || filterState.selectedSubCategoryId.length !== 0 || filterState.selectedGradeId.length !== 0 || filterState.selectedStatus.archive === true || filterState.selectedStatus.draft === true || filterState.selectedStatus.published === true || filterState.selectedTechId.length !== 0 || filterState.selectedLevelId.length !== 0) {
      const filterSearchItems = getSearchFilterItems(totalCoursesState.orgTableData);
      const filterInsItems = getInsFilterItems(filterSearchItems);
      const filterSubCatItems = getSubCatFilterItems(filterInsItems);
      const filterGradeItems = getGradeFilterItems(filterSubCatItems);
      const filterStatusItems = getStatusFilterItems(filterGradeItems);
      const filterTagItems = getTagsFilterItems(filterStatusItems);
      const filterLevelItems = getLevelFilterItems(filterTagItems);

      setTotalCoursesState({ ...totalCoursesState, tableData: filterLevelItems });
    } else {
      setTotalCoursesState({ ...totalCoursesState, tableData: totalCoursesState.orgTableData });
    }
  };

  const archiveCourse = (e, courseId, courseTitle, isActive) => {
    e.preventDefault();
    setTaskToArchive({
      ...taskToArchive,
      isOpen: true,
      courseIdToChild: courseId,
      title: courseTitle,
      isActive: isActive,
    });
  };

  const cancleArchive = () => {
    setTaskToArchive((prevState) => ({
      ...prevState,
      isOpen: false,
      showArchiveCourse: false,
    }));
  }

  useEffect(() => {
    totalUploadedCoursesData();
  }, [taskToArchive.courseVersion]);

  useEffect(() => {
    handleFiltersChange();
  }, [filterState.showFilterBase, filterState.query]);

  useEffect(() => {
    fetchLevel();
    fetchGrade();
  }, []);

  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      {taskToArchive.isOpen && <ArchiveCourse type={"Course"} taskToArchive={taskToArchive} setTaskToArchive={setTaskToArchive} onConfirmArchive={confirmArchive} onCancleClick={cancleArchive} />}
      {filterState.showFilterBase && <FilterBase filterType="Course" data={totalCoursesState} filterState={filterState} setFilterState={setFilterState} onApplyFilters={handleFiltersChange} />}
      <div className={`container-fluid total-uploaded-courses-table-container d-flex flex-column ${filterState.showFilterBase ? "overflow-hidden" : ""}`}>
        <div style={{marginLeft:"1rem"}} >
          <div className="row ">
            <div className="col-12">
              <div className="total-uploaded-courses-nav-bar">
                <BreadCrumbs />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="total-uploaded-courses-heading">
                <p>Course Library</p>
              </div>
            </div>
          </div>

          <div className="total-uploaded-courses-filter-wrapper">
            <div className="total-uploaded-courses-search">
              <div className="total-uploaded-courses-search-icon" />
              <input
                type="text"
                className="total-uploaded-courses-input-bar"
                placeholder="Search by Course Name "
                value={filterState.query}
                onChange={(e) =>
                  setFilterState({
                    ...filterState,
                    query: e.target.value,
                  })
                }
              />
            </div>
            <div className="total-uploaded-courses-inner-filter">
                <button
                  className="fw-bold filter-button"
                  onClick={() => {
                    setFilterState({
                      ...filterState,
                      showFilterBase: true,
                    });
                  }}
                >
                <Filter className="blue-filter-icon"/> Filter
                </button>
            </div>
          </div>
          <TotalUploadedCoursesTable
            tableData={totalCoursesState.tableData}
            archiveCourse={archiveCourse}
            isLoading={totalCoursesState.isLoading}
            taskToArchive={taskToArchive}
            setTaskToArchive={setTaskToArchive}
          />
        </div>
      </div>
    </>
  );
};

export default TotalUploadedCourses;
