export const COLUMNS = [
  {
    Header: "",
    accessor: "id1",
    width: "42",
  },
  {
    Header: "Title",
    accessor: "title",
    width: "330",
  },
  {
    Header: "Category",
    accessor: (row) => {
      if (row.categoryInfo && row.categoryInfo.length > 0) {
        return row.categoryInfo[0].categoryName || "NA";
      } else {
        return "NA";
      }
    },
    width: "200",
  },
  {
    Header: "Sub-Category",
    accessor: (row) => {
      if (row.categoryInfo && row.categoryInfo.length > 0) {
        return row.categoryInfo[0].subCategoryName || "NA";
      } else {
        return "NA";
      }
    },
    width: "200",
  },
  {
    Header: "Description",
    accessor: "description",
    width: "316",
  },
  {
    Header: "Level",
    accessor: "levelName",
    width: "150",
  },
  {
    Header: "Tags",
    accessor: "tags",
    width: "207",
  },
  {
    Header: "#Content",
    accessor: "moduleCount",
    width: "116",
  },
  {
    Header: "Duration",
    accessor: "courseCompletionTime",
    width: "150",
  },
  {
    Header: "Instructor",
    accessor: "instructorList",
    width: "160",
  },
  {
    Header: "Grade Type",
    accessor: "gradeType",
    width: "160",
  },
  {
    Header: "Uploaded By",
    accessor: (row) => `${row.uploadedByFirstName} ${row.uploadedByLastName}`,
    width: "160",
  },
  {
    Header: "Upload Date",
    accessor: "createdDate",
    width: "150",
  },
  {
    Header: "Last Modified",
    accessor: "modifiedDate",
    width: "150",
  },
  {
    Header: "Status",
    accessor: "isPublished",
    width: "149",
  },
  {
    Header: "#Enrolled User",
    accessor: "userEnrolledCount",
    sticky: "right",
    width: "130",
  },
  {
    Header: "Action",
    accessor: "action",
    sticky: "right",
    width: "136",
  },
];
