import { useState, Header, LogCard, DetailedCard, SideNav } from "../AdminImport"

const Log = () => {
  const [selectedBatches, setSelectedBatches] = useState(
    JSON.parse(sessionStorage.getItem("Batches")) || []
  );
  const [selectedUser, setSelectedUser] = useState({
    detailLogLoading:true,
    user: 0,
    mentor: 0
  });
  const [logData, setLogData] = useState([]);

  return (
    <>
      <div style={{ marginBottom: "5rem" }}>
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div>
      <div className="assign-task-page">
        <div className="container-fluid ">
          <div className="row mt-4">
            <div className=" col-md-4 p-0 "
              style={{ marginLeft: "56px" }}
            >
              <LogCard
                selectedBatches={selectedBatches}
                setSelectedBatches={setSelectedBatches}
                setSelectedUser={setSelectedUser}
                setLogData={setLogData}
              />
            </div>
            <div className=" col-md-7 p-0 " style={{ marginLeft: "-16px" }}>
              <DetailedCard
                selectedUser={selectedUser}
                logData={logData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Log;
