import { decryptData, useState, useEffect, handleApiError, axios, useNavigate, EmptyState, Pie, Select } from "../../AdminImport";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./PieChart.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    width: "12rem",
    margin: "auto",
    marginTop: "0.37rem",
    height: "2.5rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    maxHeight: "13rem",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const PieChart = (props) => {
  const [tableData, setTableData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currYear, setCurrentYear] = useState(new Date().getFullYear());

  const piechartId = sessionStorage.getItem("detailId");
  const navigate = useNavigate();

  const data = {
    labels: [],
    datasets: [
      {
        radius: "90%",
        label: "Total Hours",
        data: [],
        backgroundColor: ["#4670B6", "#5B9BD4", "#71AE47", "#EE7D31", "#8DC8F8", "#B2B2B3"],
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMonthClick = (e) => {
    setCurrentMonth(e.value);
  };

  const handleYearClick = (e) => {
    setCurrentYear(e.value);
  };

  const startYear = 2000;
  const curYear = new Date().getFullYear();

  const yearOptions = [
    ...Array.from({ length: curYear - startYear + 1 }, (_, index) => ({
      value: curYear - index,
      label: `${curYear - index}`,
    })),
  ];

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index,
    label: getMonthName(index),
  }));

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const getBrowserTimeZone = () => {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return userTimeZone;
    };
    const browserTimeZone = getBrowserTimeZone();

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/DailyUpdate/timerRecords?userId=${piechartId}&timeZone=${browserTimeZone}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        setTableData(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  function getMonthName(monthNumber) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if (monthNumber >= 0 && monthNumber <= 11) {
      return months[monthNumber];
    }
  }

  function getWorkingDays(year, month) {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    let workingDays = 0;

    for (let date = new Date(firstDay); date <= lastDay; date.setDate(date.getDate() + 1)) {
      const dayOfWeek = date.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        workingDays++;
      }
    }
    return workingDays;
  }

  const getTotalWorkingHoursForCurrentMonth = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const totalWorkingDays = getWorkingDays(currentYear, currentMonth);
    return totalWorkingDays * 8;
  };

  function convertToPercentageMonthly(timeString, totalWorkingHours, totalWorkingYear) {
    const regex = /(\d+)\s*hrs\s*(\d+)\s*min/;
    const matches = timeString.match(regex);
    const hours = parseInt(matches != null ? matches[1] : 0);
    const minutes = parseInt(matches != null ? matches[2] : 0);
    const totalMinutes = hours * 60 + minutes;
    let percentage = 0;
    if (totalWorkingHours != null && totalWorkingHours != undefined && totalWorkingHours != 0) {
      percentage = (totalMinutes / (totalWorkingHours * 60)) * 100;
    } else if (totalWorkingYear != null && totalWorkingYear != undefined && totalWorkingYear != 0) {
      percentage = (totalMinutes / (totalWorkingHours * 60 * 12)) * 100;
    }
    return Number.isInteger(percentage) ? percentage : percentage.toFixed(2);
  }

  function convertToTimeFormat(percentage, totalWorkingHours) {
    const totalMinutes = (percentage / 100) * (totalWorkingHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${hours} hrs ${minutes.toString().padStart(2, "0")} min`;
  }

  function convertHoursToDecimal(hoursString) {
    const regex = /(\d+)\s*hrs\s*(\d+)\s*min/;
    const match = hoursString?.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);
      const decimalHours = hours + minutes / 60;
      return decimalHours;
    }
  }

  function convertDecimalToHours(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    const hoursString = hours + " hrs";
    const minutesString = minutes.toString().padStart(2, "0") + " min ";

    return hoursString + " " + minutesString;
  }

  const startDate = new Date(currYear, currentMonth, 1);
  const endDate = new Date(currYear, currentMonth + 1, 0);

  const startDateFormatted = startDate.toISOString().split("T")[0];
  const endDateFormatted = endDate.toISOString().split("T")[0];
  const monthlyRecords = tableData?.filter((record) => {
    const recordDate = new Date(record.startDate);
    const recordDateFormatted = recordDate.toISOString().split("T")[0];
    return recordDateFormatted >= startDateFormatted && recordDateFormatted <= endDateFormatted;
  });

  let totalProjectHours = 0;
  let totalCgHours = 0;
  let totalSelfHours = 0;
  let totalMentorHours = 0;
  let totalSessionHours = 0;
  monthlyRecords?.forEach((record) => {
    let formattedTotalTime = convertHoursToDecimal(record.totalTime);
    if (record.learningType === "Project") {
      totalProjectHours += formattedTotalTime;
    } else if (record.learningType === "CG Learning Videos") {
      totalCgHours += formattedTotalTime;
    } else if (record.learningType === "Mentor Assigned Task") {
      totalMentorHours += formattedTotalTime;
    } else if (record.learningType === "Session") {
      totalSessionHours += formattedTotalTime;
    } else {
      totalSelfHours += formattedTotalTime;
    }
  });
  totalProjectHours = convertDecimalToHours(totalProjectHours);
  totalCgHours = convertDecimalToHours(totalCgHours);
  totalSelfHours = convertDecimalToHours(totalSelfHours);
  totalMentorHours = convertDecimalToHours(totalMentorHours);
  totalSessionHours = convertDecimalToHours(totalSessionHours);
  const totalWorkingHours = getTotalWorkingHoursForCurrentMonth();

  const techPercentages = {
    Project: parseFloat(convertToPercentageMonthly(totalProjectHours, totalWorkingHours)),
    "CG Learning Video": parseFloat(convertToPercentageMonthly(totalCgHours, totalWorkingHours)),
    "Self Learning": parseFloat(convertToPercentageMonthly(totalSelfHours, totalWorkingHours)),
    "Task By Mentor": parseFloat(convertToPercentageMonthly(totalMentorHours, totalWorkingHours)),
    Session: parseFloat(convertToPercentageMonthly(totalSessionHours, totalWorkingHours)),
    Idle: Number.isInteger(100 - convertToPercentageMonthly(convertDecimalToHours(convertHoursToDecimal(totalProjectHours) + convertHoursToDecimal(totalCgHours) + convertHoursToDecimal(totalSelfHours) + convertHoursToDecimal(totalMentorHours) + convertHoursToDecimal(totalSessionHours)), totalWorkingHours)) ? 100 - convertToPercentageMonthly(convertDecimalToHours(convertHoursToDecimal(totalProjectHours) + convertHoursToDecimal(totalCgHours) + convertHoursToDecimal(totalSelfHours) + convertHoursToDecimal(totalMentorHours) + convertHoursToDecimal(totalSessionHours)), totalWorkingHours) : (100 - convertToPercentageMonthly(convertDecimalToHours(convertHoursToDecimal(totalProjectHours) + convertHoursToDecimal(totalCgHours) + convertHoursToDecimal(totalSelfHours) + convertHoursToDecimal(totalMentorHours) + convertHoursToDecimal(totalSessionHours)), totalWorkingHours)).toFixed(2),
  };

  useEffect(() => {
    props.handlePieData(techPercentages);
  }, [currentMonth, currYear]);

  data.labels = Object.keys(techPercentages).map((techName) => techName);

  const legendValues = Object.values(techPercentages);
  const newValue = legendValues.map((val) => (val > 0 ? val : 0));
  data.datasets[0].data = newValue;
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "middle",
        labels: {
          boxWidth: 17,
          boxHeight: 17,
          padding: 16,
          font: {
            family: "Roboto",
            style: "normal",
            weight: "bold",
            size: 12,
            lineHeight: 16,
          },
          generateLabels: (chart) => {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const dataset = data.datasets[0];
                const value = legendValues[i];
                return {
                  text: `${label} (${value}%)`,
                  fontColor: "#343435",
                  fillStyle: data.datasets[0].backgroundColor[i],
                  hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                  lineCap: dataset.borderCapStyle,
                  lineDash: dataset.borderDash,
                  lineDashOffset: dataset.borderDashOffset,
                  lineJoin: dataset.borderJoinStyle,
                  strokeStyle: "#fff",
                  pointStyle: dataset.pointStyle,
                  rotation: dataset.rotation,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const value = dataset.data[context.dataIndex];
            const label = dataset.label;
            const formattedValue = convertToTimeFormat(value, totalWorkingHours);
            return `${label}: ${formattedValue}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 410,
    height: 220,
  };
  return (
    <div className="container pie-chart-container">
      <div className="row">
        <div className="p-0 m-0">
          <div className="dropdown d-flex align-items-center pie-chart-month-dropdown">
            <Select
              styles={customStyles}
              placeholder="Select Month"
              options={monthOptions}
              defaultValue={monthOptions.find((option) => option.value === currentMonth)}
              onChange={(e) => {
                handleMonthClick(e);
              }}
            />
            <Select
              styles={customStyles}
              placeholder="Select Year"
              options={yearOptions}
              defaultValue={yearOptions.find((option) => option.value === currYear)}
              onChange={(e) => {
                handleYearClick(e);
              }}
            />
          </div>
          <div>
            {Object.values(techPercentages).every((percentage) => percentage === 0) ? (
              <div className="no-data-div">
                <EmptyState className="custom-empty-state" />
                <h1 className="empty-state-text">No data found</h1>
              </div>
            ) : (
              <div className="empty-pie-size">
                <Pie data={data} options={options} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
