import { React, useNavigate, CryptoJS, Clock, Completecircle, Overduewarning, Ongoingflag, Skeleton } from "../MentorImport";
import "./MentorAssignTask.css";

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate().toString().padStart(2, "0"))}-${date.getFullYear()}`;
}

const MentorTaskDetail = ({ detail }) => {
  const navigate = useNavigate();

  let startDate = formatDate(detail?.startDate);
  let endDate = formatDate(detail?.endDate);
  const todayDateFormatted = formatDate(new Date());

  const checkOverDue = () => {

    const endDateValue = endDate.split("-");
    if (parseInt(endDateValue[2]) > new Date().getFullYear()) return false;
    if (parseInt(endDateValue[2]) === new Date().getFullYear() && parseInt(endDateValue[0]) > new Date().getMonth())
      return false;
    if (parseInt(endDateValue[0]) === new Date().getMonth() && parseInt(endDateValue[1]) > new Date().getDate())
      return false;
    return true;
  };

  const areAllTasksOngoing = () => {
    if (!detail?.userInfo) {
      return true; // No tasks to check, assume all tasks are ongoing
    }

    return !detail.userInfo.some((info) => {
      const isTaskDone = info?.taskDone === true;
      const isOverdue = checkOverDue() && new Date(info?.taskCompletionDateExpected) < new Date();
      return isTaskDone || isOverdue;
    });
  };

  const handleNavigate = () => {
    const taskId = CryptoJS.AES.encrypt(
      (detail?.id).toString(),
      process.env.REACT_APP_TASK_ID
    ).toString();
    const taskName = CryptoJS.AES.encrypt(
      detail?.taskName,
      process.env.REACT_APP_TASK_ID
    ).toString();
    sessionStorage.setItem("taskId", taskId);
    sessionStorage.setItem("title", taskName);
    navigate("/mentor/project-rating");
  };
  return (
    detail && (
      <div className="task-detail-wrapper">
        <div className="mentortask-heading-style">{detail?.taskName}</div>
        <div className="mentortask-duration-wrapper">
          <div className="mentortask-duration-style">
            {<Clock />}
            <span>{`${startDate} - ${endDate}`}</span>
          </div>
        </div>
        <div className="mentortask-description-style mb-4"
        >
          {detail?.taskDescription}
        </div>
        <div className="mentortask-technology-wrapper">
          <div className="mentortask-meta-heading">
            {<span>Technology Used:</span>}
          </div>
          <div className="tech-used-wrapper">
            {detail?.technology?.map((tech, index) => {
              return (
                <div key={index} className="tech-used-div-box ">
                  <span>{tech}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mentortask-assigned-wrapper">
          <div className="mentortask-meta-heading assign-header-style">
            <span>Assigned To:</span>
            {detail?.ratedTask === true ? (
              <button disabled={areAllTasksOngoing()} onClick={handleNavigate} >Rate Assignment</button>) :
              (<button disabled>Unrated Task</button>)}
          </div>

          <div className="assigned-list-wrapper">
            {detail?.userInfo?.map((info, index) => {
              return (
                <div key={index} className="assigned-to-wrapper">
                  <div className="name-status-wrapper">
                    <div className="name-percentage-wrapper">
                      <span>{info?.name}</span>
                      {info?.overallScore && (
                        <div>{`${info?.overallScore} %`}</div>
                      )}
                    </div>
                    {info?.taskDone ? (
                      <div className="task-status-wrapper">
                        <div
                          className="task-completed-wrapper"
                          style={{
                            background: "#CEF0DC",
                          }}
                        >
                          <Completecircle />
                          <span>{"Completed On"}</span>
                        </div>
                        <div>{formatDate(info?.taskCompletedDateActual)}</div>
                      </div>
                    ) : checkOverDue() && formatDate(new Date(info?.taskCompletionDateExpected).toLocaleDateString()) < formatDate(new Date())?.toLocaleDateString() ? (
                      <div className="task-status-wrapper">
                        <div
                          className="task-overdue-wrapper"
                          style={{
                            background: "#FAD3CE",
                          }}
                        >
                          <Overduewarning />
                          <span>{"Overdue"}</span>
                        </div>
                        <div>{formatDate(detail?.endDate)}</div>
                      </div>
                    ) : (
                      <div className="task-status-wrapper">
                        <div
                          className="task-ongoing-wrapper"
                          style={{
                            background: "#F9E1C9",
                          }}
                        >
                          <Ongoingflag />
                          <span>{"Ongoing"}</span>
                        </div>
                        <div>{todayDateFormatted}</div>
                      </div>
                    )}
                  </div>
                  {info?.taskLink !== null &&
                    info?.taskLink !== undefined && (
                      <div>
                        <span>GitHub Link:</span>
                        <a href="#">{info?.taskLink.length > 50 ? `${info?.taskLink.slice(0, 50)}...` : info?.taskLink}</a>
                      </div>
                    )}
                  {info?.hostedLink &&
                    (
                      <div>
                        <span>Hosted Link:</span>
                        <a href="#">{info?.hostedLink.length > 50 ? `${info?.hostedLink.slice(0, 50)}...` : info?.hostedLink}</a>
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default MentorTaskDetail;
