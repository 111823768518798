import { useState, useEffect, Header, SideNav, TotalEnrolledUsersTable, BreadCrumbs, useNavigate, axios, decryptData, handleApiError } from "../../../AdminImport";
import "./TotalEnrolledUsers.css";

const TotalEnrolledUsers = () => {
  const [totalEnrolledState, setTotalEnrolledState] = useState({
    tableData: [],
    orgTableData: [],
    isLoading: true,
  });
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const totalEnrolledUsersData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/totalEnrolledUsers?userId=0`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setTotalEnrolledState((prevState) => ({
          ...prevState,
          tableData: response.data.result,
          orgTableData: response.data.result,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleFiltersChange = () => {
    const getSearchFilterItems = (items) => {
      if (query != "") {
        return items.filter((item) => item.firstName.toLowerCase().startsWith(query.toLowerCase()));
      }
      return items;
    };

    const filterSearchItems = getSearchFilterItems(totalEnrolledState.orgTableData);
    setTotalEnrolledState({ ...totalEnrolledState, tableData: filterSearchItems });
  };

  useEffect(() => {
    handleFiltersChange();
  }, [query]);

  useEffect(() => {
    totalEnrolledUsersData();
  }, []);

  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container-fluid total-uploaded-courses-table-container d-flex flex-column">
        <div style={{marginLeft:"1rem"}}>
          <div className="row ">
            <div className="col-12">
              <div className="total-enrolled-user-course-nav-bar">
                <BreadCrumbs />
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between pb-1">
              <div className="total-enrolled-user-courses-heading">
                <p>Total Enrolled Users</p>
              </div>
              <div className="d-flex">
                <div className="total-uploaded-courses-inner-filter">
                  <div className="report-search">
                    <div className="search-icon" />
                    <input type="text" className="report-input-bar" placeholder="Search by user name" value={query} onChange={(e) => setQuery(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TotalEnrolledUsersTable tableData={totalEnrolledState.tableData} isLoading={totalEnrolledState.isLoading}/>
        </div>
      </div>
    </>
  );
};

export default TotalEnrolledUsers;
