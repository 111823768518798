import { useEffect, useState, useReducer } from "react";
import { ReactComponent as ExpandMore } from "../../../../../Assets/expand_more.svg";
import axios from "axios";
import { decryptData, useNavigate } from "../../../UserImport";
import TechActionType from "../../../../_common/TechActionType";
import ProjectTechDropdown from "../ProjectTechDropdown";
import { UserActionType } from "../../../../AdminPortal/AdminImport";
import ProjectMemberDropdown from "../ProjectMemberDropdown";

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
    case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
  usersValue: "",
  selectedUserIds: [],
  selectedUserName: [],
  selectAllChecked: false,
};

const ProjectModalEdit = ({ isAssignedByMentor, setTaskVersion, idProp, idValue, projectName, projectDescriptions, indexNumber, projectTechnology, projectLinks, hostedLinks, memberName, projectVersion, isCancelClick, setIsCancelClick }) => {
  const [projName, setProjName] = useState(projectName);
  const [proVersion, setProVersion] = useState(false);
  const [description, setDescription] = useState(projectDescriptions);
  const [isProjectDescriptionValid, setIsProjectDescriptionValid] = useState(true);
  const [projectLink, setProjectLink] = useState(projectLinks);
  const [hostedLink, setHostedLink] = useState(hostedLinks);
  const [memberNames, setMemberNames] = useState(memberName);
  const [membersError, setMembersError] = useState(false);
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const [projNameError, setProjNameError] = useState(false);
  const [desError, setDesError] = useState(false);
  const [projLinkError, setProjLinkError] = useState(false);
  const [technologyNames, setTechnologyNames] = useState(projectTechnology);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isProjectLinkValid, setIsProjectLinkValid] = useState(true);
  const [isHostedLinkValid, setIsHostedLinkValid] = useState(true);
  const [editProject, setEditProject] = useState({});
  const [projectToEdit, setProjectToEdit] = useState(null);

  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const handleProjectNameChange = (e) => {
    e.preventDefault();
    const name = e.target.value;
    setProjName(name);
    if (!name || !name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/)) {
      setProjNameError(true);
    } else {
      setProjNameError(false);
    }
    setIsProjectNameValid(e.target.value.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/) ? true : false);
  };

  const handleProjectDescriptionChange = (event) => {
    event.preventDefault();
    const description = event.target.value;
    setDescription(description);
    setIsProjectDescriptionValid(
      description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/) ? true : false
    );
    if (!description || !description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/)) {
      setDesError(true);
    } else {
      setDesError(false);
    }
  };

  const handleProjectLinkChange = (e) => {
    const link = e.target.value;
    setProjectLink(link);
    setIsProjectLinkValid(link.match(/^https?:\/\//) ? true : false);
    if (!link) {
      setProjLinkError(true);
    } else {
      setProjLinkError(false);
    }
  };

  const handleHostedLinkChange = (e) => {
    const link = e.target.value;
    setHostedLink(link);
    setIsHostedLinkValid(link.match(/^https?:\/\//) ? true : false);
  };

  const handleSubmit = async (e) => {
    setDropDown(() => ({
      techDropdown: false,
      userDropdown: false,
    }));
    try {
      const data = localStorage.getItem("userData");
      if (!data) {
        navigate("/error/statusCode=400");
        return;
      }

      const parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY);
      const taskUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/updateUserTask`;
      const projectUrl = `${process.env.REACT_APP_API_URL_DOTNET}/Project/update`;

      const projectBody = {
        id: idValue,
        name: projName,
        description,
        projectLink,
        hostedLink,
        techNames: state.selectedTechIds,
        memberNames: state.selectedUserIds,
        members: [],
        technologyUsed: []
      };

      const taskBody = {
        id: idValue,
        taskLink: projectLink,
        hostedLink: hostedLink,
      };

      const headers = {
        Authorization: `Bearer ${parsedObject.token}`,
      };

      let response;

      if (idProp === "taskId") {
        response = await axios.post(taskUrl, taskBody, { headers });
      } else {
        response = await axios.post(projectUrl, projectBody, { headers });
      }
      if (response.data.success === 200) {
        setTaskVersion((prevVersion) => prevVersion + 1);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
    }
    finally {
      setDropDown(() => ({
        techDropdown: false,
        userDropdown: false,
      }));
    }
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };

  const handleEditCloseModal = () => {
    setProjectToEdit(null);
    setProjName(projectName);
    setDescription(projectDescriptions);
    setProjectLink(projectLinks);
    setHostedLink(hostedLinks);
    setMemberNames(memberName);
    setProjNameError(false);
    setDesError(false);
    setProjLinkError(false);
    setIsProjectNameValid(true);
    setIsProjectLinkValid(true);
    setIsProjectDescriptionValid(true);
    setIsHostedLinkValid(true);
    setTechnologyNames(projectTechnology);
    setDropDown(() => ({
      techDropdown: false,
      userDropdown: false,
    }));
    setIsCancelClick(true);
  };

  useEffect(() => {
    setEditProject(projectToEdit);
  }, [projectToEdit]);

  useEffect(() => {
    setProjName(projectName);
    setDescription(projectDescriptions);
    setProjectLink(projectLinks);
    setHostedLink(hostedLinks);
    setMemberNames(memberName);
    setTechnologyNames(projectTechnology);
    setProVersion(projectVersion);
  }, [indexNumber, proVersion]);

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  useEffect(() => {
    const isUserEmpty = Object.keys(state.selectedUserName);
    if (isUserEmpty) {
      const userValueString = Object.values(state.selectedUserName).join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  useEffect(() => {
    setTechnologyNames(projectTechnology);
  }, [dropDown.techDropdown])

  return (
    <div className="modal fade" id="editProjectModal" tabIndex="-1" aria-labelledby="editProjectModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="editProjectModalLabel">
              Edit your project
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleEditCloseModal()} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmfor="edit-project-name" className="col-form-label title-text">
                  Project Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  disabled={isAssignedByMentor ? true : false}
                  type="text"
                  className="form-control"
                  id="project-name"
                  value={projName}
                  placeholder="Enter Project Name"
                  onChange={(e) => {
                    handleProjectNameChange(e);
                  }}
                />
                {!isProjectNameValid && projName && <span style={{ color: "red", fontSize: "11px" }}>Please enter a name with only letters and spaces, between 1 and 100 characters.</span>}
              </div>
              <div className="mb-3">
                <label htmlFor="project-description" className="col-form-label title-text">
                  Project Description
                  <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  disabled={isAssignedByMentor ? true : false}
                  className="form-control"
                  id="project-description"
                  value={description}
                  placeholder="Write Here..."
                  onChange={handleProjectDescriptionChange}
                  rows={3}
                />
                {!isProjectDescriptionValid && description && (
                  <span style={{ color: "red", fontSize: "11px" }}>
                    Please Enter description only with letters, colon, comma and hyphen, between 150 and 750 characters.
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="technology-used" className="col-form-label title-text" required>
                  Technology Used<span style={{ color: "red" }}>*</span>
                </label>
                <div className="container border p-0" >
                  <div className={`input-with-button ${isAssignedByMentor && "disabled-background-project"}`}
                    onClick={(e) => { !isAssignedByMentor && handleDropdownToggle("techDropdown") }}
                  >
                    <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input"
                      placeholder="Select Technology" value={isAssignedByMentor ? projectTechnology : state.selectedTechNames.map((tech) => tech.techName)} />
                    <div
                      className="modal-abs  "
                      style={{
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "0",
                        bottom: "0",
                      }}
                    ></div>
                    <ExpandMore />
                  </div>
                  <div>
                    <ul style={{ display: dropDown.techDropdown ? "" : "none" }} className="ul-styling">
                      <ProjectTechDropdown dispatch={dispatch} techDataComingChild={handleTechChange}
                        selectedTechIdsfromChild={handleTechIdChange} selectedTechIds={state.selectedTechIds}
                        technologyNames={state.selectedTechNames} selectedTech={technologyNames} isCancelClick={isCancelClick} />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="Project Link" className="col-form-label title-text">
                  Project Link<span style={{ color: "red" }}>*</span>{" "}
                </label>
                <input className="form-control" id="project-link" placeholder="Enter Project Link" value={projectLink} onChange={handleProjectLinkChange} />
                {!isProjectLinkValid && projectLink && <span style={{ color: "red", fontSize: "11px" }}>Invalid project link. Please enter a valid URL starting with http:// or https://.</span>}
              </div>
              <div className="mb-3">
                <label htmlFor="Hosted Link(Optional)" className="col-form-label title-text">
                  Hosted Link
                </label>
                <input
                  className="form-control"
                  id="hosted-link"
                  placeholder="Enter Hosted Link"
                  value={hostedLink}
                  onChange={handleHostedLinkChange}
                />
                {!isHostedLinkValid && hostedLink && <span style={{ color: "red", fontSize: "11px" }}>Invalid hosted link. Please enter a valid URL starting with http:// or https://.</span>}
              </div>
              {idProp === "taskId" ? (
                ""
              ) : (
                <div className="mb-3">
                  <label
                    htmlFor="Members(Optional)"
                    className="col-form-label title-text"
                  >
                    Members
                  </label>
                  <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => { !isAssignedByMentor && e.preventDefault(); handleDropdownToggle("userDropdown") }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Members"
                        value={state.selectedUserName}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                      <ul style={{ display: dropDown.userDropdown ? "" : "none" }} className="ul-styling">
                        <ProjectMemberDropdown
                          dispatch={dispatch}
                          usersDataComingChild={usersDataComingFrmChild}
                          selectedUserIdsfromChild={handleUserIdChange}
                          selectedUserIds={state.selectedUserIds}
                          selectedUserName={state.selectedUserName}
                          selectAllUsersCheck={state.selectAllChecked}
                          selectedUsers={memberNames}
                          isCancelClick={isCancelClick}
                        />
                      </ul>
                    </div>
                  </div>
                  {membersError && (
                    <span style={{ color: "red", fontSize: "11px" }}>
                      Please enter upto 10 members only
                    </span>
                  )}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={() => handleEditCloseModal()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-button"
              data-bs-target="#editProjectModal"
              data-bs-dismiss={
                !projNameError &&
                  !desError &&
                  !projLinkError &&
                  state.selectedTechNames?.length !== 0
                  ? "modal"
                  : ""
              }
              disabled={
                projNameError ||
                desError || !isProjectDescriptionValid ||
                (!isAssignedByMentor && state.selectedTechNames?.length === 0) ||
                membersError || projLinkError || !isProjectLinkValid || !isProjectNameValid
              }
              onClick={() => handleSubmit()}
            >
              {" "}
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectModalEdit;
