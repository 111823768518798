import { useEffect, axios, handleApiError, decryptData, useNavigate, useState, Link, Skeleton, TotalIcon, ActiveIcon, InActiveIcon, OnProjectIcon, ArrowForwardIcon } from '../../AdminImport';
import "./status.css";
import "react-loading-skeleton/dist/skeleton.css";


const Status = ({ prop }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [prop.batch]);

  const addLeadingZero = (count) => {
    if(count>=0)
      return count < 10 ? `0${count}` : count;
    else 
      return count;
  };

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/userCount`;
    let body = {
      batchId: prop.batch
    }

    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        setUserInfo(response.data.result);
        setIsLoading(false);
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  return (
    <>
      <Link to="/admin/sde/report" id='Manage-BTN' className="about-link">
        Manage SDEs <ArrowForwardIcon />
      </Link>
      <div className="row first-row pb-0">
        <div className="col-6">
          {isLoading ? <Skeleton width={190} height={90.72} count={1} highlightColor="#fff" style={{ marginTop: "10px", boxShadow: "0px 0.25rem 1.25rem rgba(40, 52, 73, 0.15)" }} /> : <div className="four-cards pb-0">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.625rem",
              }}
            >
              <TotalIcon />
              <div
                className="count-info"
              >
                <p className="count">{addLeadingZero(userInfo?.totalUsers)}</p>
                <p
                  className="status"
                  style={{ marginLeft: "0.625rem", marginTop: "0.28rem" }}
                >
                  Total
                </p>
              </div>
            </div>
          </div>}
        </div>
        <div className="col-6">
          {isLoading ? <Skeleton width={190} height={90.72} count={1} highlightColor="#fff" style={{ marginTop: "10px", boxShadow: "0px 0.25rem 1.25rem rgba(40, 52, 73, 0.15)" }} /> : <div className="four-cards pb-0">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.625rem",
              }}
            >
              <ActiveIcon />
              <div
                className="count-info"
              >
                <p className="count">{addLeadingZero(userInfo?.totalActiveUsers)}</p>
                <p
                  className="status d-flex justify-content-center"
                  style={{ marginLeft: "0.625rem", marginTop: "0.28rem" }}
                >
                  Active
                </p>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="row second-row">
        <div className="col-6">
          {isLoading ? <Skeleton width={190} height={90.72} count={1} highlightColor="#fff" style={{ marginTop: "10px", boxShadow: "0px 0.25rem 1.25rem rgba(40, 52, 73, 0.15)" }} /> : <div className="four-cards  pb-0">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.625rem",
              }}
            >
              <InActiveIcon />
              <div
                className="count-info"
              >
                <p className="count">{addLeadingZero(userInfo?.totalInactiveUsers)}</p>
                <p
                  className="status d-flex justify-content-center"
                  style={{ marginLeft: "0.625rem", marginTop: "0.28rem" }}
                >
                  Inactive
                </p>
              </div>
            </div>
          </div>}
        </div>
        <div className="col-6">
          {isLoading ? <Skeleton width={190} height={90.72} count={1} highlightColor="#fff" style={{ marginTop: "10px", boxShadow: "0px 0.25rem 1.25rem rgba(40, 52, 73, 0.15)" }} /> : <div className="four-cards  pb-0">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.625rem",
              }}
            >
              <OnProjectIcon />
              <div className="count-info"
              >
                <p className="count">{addLeadingZero(userInfo?.totalUserOnproject)}</p>
                <p
                  className="status d-flex justify-content-center"
                  style={{ marginLeft: "0.625rem", marginTop: "0.28rem" }}
                >
                  On Project
                </p>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
}

export default Status;