import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AssignTaskSkeleton = () => {
  return (
    <div className="card task-card">
      <div className="card-title mb-0">
        <Skeleton width={300} />
        <div className="d-flex mt-2 task-timeStamp">
          <Skeleton width={166} />
        </div>
      </div>
      <div className="card-body p-0 mt-2">
        <Skeleton width={66} />
        <Skeleton width={704} />
        <Skeleton width={91} />
        <Skeleton
          inline
          count={3}
          width={74}
          className="badge tech-badge mt-2"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
          }}
        >
          <div>
            <Skeleton width={72} />
            <div className="mentor-wrapper">
              <div className="image-wrapper1">
                <div className="assignedBy-img">
                  <Skeleton />
                </div>
              </div>
              <div className="text-wrapper1">
                <p className="m-0">
                  <Skeleton width={70} />
                </p>
                <Skeleton width={50} />
              </div>
            </div>
          </div>
          <div>
            <Skeleton width={72} />

            <div className="assigned-to ml-0">
              <Skeleton circle width={40} height={40} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTaskSkeleton;
