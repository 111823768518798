import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DetailedLogSkeleton = () => {
  const skeletonItems = Array.from({ length: 4 }, (_, index) => (
    <div className="d-flex mt-2" key={index}>
      <Skeleton inline count={1} width={15} className="mx-2 mt-2" />
      <Skeleton inline count={1} width={74} className="me-2" />
      <Skeleton inline count={1} width={500} className="me-2" />
    </div>
  ));

  const cardBody = Array.from({ length: 2 }, (_, index) => (
    <div className="card-body p-0 mt-2" key={index}>
      <Skeleton width={80} />
      {skeletonItems}
    </div>
  ));

  return (
    <>
      <div className="card-title mb-0">
        <div className="name-column">
          <Skeleton width={38} height={38} borderRadius={50} highlightColor="#97c0c5" />
          <div className="tags">
            <div className="tag1">
              <Skeleton width={100} height={12} />
            </div>
            <div className="tag2">
              <Skeleton width={50} height={10} />
            </div>
          </div>
        </div>
      </div>
      {cardBody}
    </>
  );
};

export default DetailedLogSkeleton;
