import React, { useEffect, useState } from "react";
import "./DailyUpdateTable.css";
import DatePicker from "./DatePicker";
import SearchBar from "./SearchBar";
import LearningTypeDropDown from "./LearningTypeDropDown";
import { Modal, Form } from "react-bootstrap";
import EmptyDailyUpdateTable from "./EmptyDailyUpdateTable";
import DurationClock from "../../../Assets/DurationClock.svg";
import ImageTooltip from "./ImageTooltip";
import DailyUpdateTableSectionSkeleton from "./DailyUpdateTableSectionSkeleton";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../UserPortal/UserImport";

const DailyUpdateTableSection = (props) => {
  const [tableData, setTableData] = useState([]);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [dropdownFilterValue, setDropdownFilterValue] = useState("");
  const [dateFilterValue, setDateFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalSaveFlag, setModalSaveFlag] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
      fetchData();
  }, []);

  const fetchData = async () => {
    var parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    await fetch(process.env.REACT_APP_API_URL_DOTNET + `/DailyUpdate/timerRecords?userId=${props.userId}`, {
      headers: {
        Authorization: `Bearer ${parsedObject["token"]}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        const filteredResult = data.result.filter((item) => item.totalTime !== "0" && item.totalTime !== "0hrs 0min");
        setTableData(filteredResult);
        setOriginalTableData(filteredResult);
        setIsLoading(false);
      })
      .catch((error) => {
        const err = parseInt(error.message);
        if (err === 401) {
          navigate("/error/statusCode=401");
        }
        if (err === 400) {
          navigate("/error/statusCode=400");
        }
        if (err === 500) {
          navigate("/error/statusCode=500");
        }
        if (err === 404) {
          navigate("/error/statusCode=404");
        }
      });
  };

  const handleReadMore = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const totalPaginationPages = Math.ceil(tableData?.length / resultsPerPage);
  const arrayStartIndex = (currentPage - 1) * resultsPerPage;
  const arrayEndIndex = arrayStartIndex + resultsPerPage;
  const arrayCurrentResults = tableData?.slice(arrayStartIndex, arrayEndIndex);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const handlePrevPageChange = () => {
    if (currentPage != 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };
  const handleNextPageChange = () => {
    if (currentPage != totalPaginationPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const truncate = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  const dateFormat = (dateString) => {
    const dateObject = new Date(dateString);
    let formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate.replace(/\//g, "-");
  };

  const convertTime = (timeString) => {
    if (!timeString || typeof timeString !== "string") {
      return "Invalid Time";
    }

    const timeComponents = timeString.split(":");

    if (timeComponents.length < 3) {
      return "Invalid Time Format";
    }

    let hour = parseInt(timeComponents[0]);
    const minute = timeComponents[1];
    const secondWithMilliseconds = timeComponents[2].split(".");
    const second = secondWithMilliseconds[0];
    const milliseconds = secondWithMilliseconds[1] || "000";

    let meridiem = "AM";

    if (hour >= 12) {
      meridiem = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    const convertedTime = `${hour}:${minute} ${meridiem}`;
    return convertedTime;
  };

  const handleFiltersChange = (e) => {
    const getFilterItems = (items, searchValue) => {
      if (searchValue) {
        return items?.filter((item) => item.topicName.toLowerCase().startsWith(searchValue.toLowerCase()));
      }

      return items;
    };

    const filterDropDown = (items, dropDownValue) => {
      if (dropDownValue && dropDownValue !== "") {
        return items?.filter((item) => item.learningType === dropDownValue);
      }
      return items;
    };

    const getFilterDate = (items, dateFilterValue) => {
      if (dateFilterValue !== "") {
        const dateObject = new Date(dateFilterValue);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        return items?.filter((item) => {
          const tempDateString = item.startDate;
          const tempDateArr = tempDateString.split("-");
          return year === parseInt(tempDateArr[0]) && month === parseInt(tempDateArr[1]);
        });
      }

      return items;
    };

    const filterItemsDropDown = filterDropDown(originalTableData, dropdownFilterValue);
    const filterItems = getFilterItems(filterItemsDropDown, searchFilterValue);
    const filterDate = getFilterDate(filterItems, dateFilterValue);
    setTableData(filterDate);
  };

  useEffect(
    (e) => {
      handleFiltersChange(e);
    },
    [dropdownFilterValue, searchFilterValue, dateFilterValue, originalTableData]
  );

  useEffect(() => {
    if (props.handleSkillsData) {
      props.handleSkillsData(tableData);
    }
  }, [props.handleSkillsData, tableData]);

  return (
    <div className="mb-3">
      <div className="col-12 daily-update-table-style p-0">
        <div className="table-responsive" style={{ overflow: "visible" }}>
          <table id="example" className="table table-striped">
            <thead className="dut-head">
              <tr>
                <th className="dut-column-id daily-update-table-head">#</th>
                <th className="dut-column-date daily-update-table-head">Date</th>
                <th className="dut-column-learning daily-update-table-head">Learning Type</th>
                <th className="dut-column-topic daily-update-table-head">Topic</th>
                <th className="dut-column-comment daily-update-table-head">Comment</th>
                <th className="dut-column-duration daily-update-table-head">Duration</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              <tr>
                <td></td>
                <td>
                  <DatePicker
                    popperPlacement="top-end"
                    datefunc={(dateFilterValue) => {
                      setDateFilterValue(dateFilterValue);
                    }}
                  />
                </td>
                <td>
                  <LearningTypeDropDown dropdownfunc={(dropdownFilterValue) => setDropdownFilterValue(dropdownFilterValue)} />
                </td>
                <td>
                  <SearchBar searchfunc={(searchFilterValue) => setSearchFilterValue(searchFilterValue)} />
                </td>
                <td></td>
                <td></td>
              </tr>
              {isLoading ? (
                <>
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                  <DailyUpdateTableSectionSkeleton />
                </>
              ) : arrayCurrentResults == undefined || arrayCurrentResults?.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <EmptyDailyUpdateTable />
                  </td>
                </tr>
              ) : (
                arrayCurrentResults?.map((item, index) => {
                  const isLastTooltip = index === arrayCurrentResults?.length - 1;
                  const isSecondLastTooltip = index === arrayCurrentResults?.length - 2;
                  const tooltipClassName = isLastTooltip || isSecondLastTooltip;
                  const activityLength = item?.activityTime?.length;

                  return (
                    <tr key={index}>
                      <td>{arrayStartIndex + index + 1}</td>
                      <td>{dateFormat(item.startDate)}</td>
                      <td>{item.learningType}</td>
                      <td>
                        {item.topicName.length < 25 ? (
                          item.topicName
                        ) : (
                          <>
                            {truncate(item.topicName, 25)}
                            <span
                              className="text-decoration-underline"
                              style={{ color: "#28519E", cursor: "pointer" }}
                              onClick={() => {
                                setModalSaveFlag(true);
                                handleReadMore(item);
                              }}
                            >
                              Read more
                            </span>
                          </>
                        )}
                      </td>
                      <td>
                        {truncate(item.comment, 35)}
                        <span
                          className="text-decoration-underline"
                          style={{ color: "#28519E", cursor: "pointer" }}
                          onClick={() => {
                            setModalSaveFlag(true);
                            handleReadMore(item);
                          }}
                        >
                          Read more
                        </span>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between me-2">
                          <div>{item?.totalTime}</div>
                          <ImageTooltip
                            src={DurationClock}
                            alt="Clock Icon"
                            tooltipHead="Activity Time"
                            firstActivity={item.start1 == null ? convertTime(item.startTime) + " - " + convertTime(item.endTime) : convertTime(item.startTime) + " - " + convertTime(item.pause1)}
                            secondActivity={item.start2 == null ? (item.start1 == null ? "" : convertTime(item.start1) + " - " + convertTime(item.endTime)) : convertTime(item.start1) + " - " + convertTime(item.pause2)}
                            thirdActivity={item.start3 == null ? (item.start2 == null ? "" : convertTime(item.start2) + " - " + convertTime(item.endTime)) : convertTime(item.start2) + " - " + convertTime(item.pause3)}
                            fourthActivity={item.start4 == null ? (item.start3 == null ? "" : convertTime(item.start3) + " - " + convertTime(item.endTime)) : convertTime(item.start3) + " - " + convertTime(item.pause4)}
                            fifthActivity={item.start4 == null ? (item.start3 == null ? "" : convertTime(item.start3) + " - " + convertTime(item.endTime)) : convertTime(item.start4) + " - " + convertTime(item.endTime)}
                            styleclassName={tooltipClassName}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}

              <Modal size="lg" dialogClassName="modal-90w" centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header
                  closeButton
                  style={{
                    borderBottom: "0.063rem solid var(--bs-modal-header-border-color)",
                  }}
                >
                  <Modal.Title style={{ fontSize: "1.1rem", fontWeight: "600" }}>Daily Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    <span className="fw-bold">Learning Type:</span> <span className="opacity-75">{selectedItem && selectedItem.learningType}</span>
                  </p>
                  <p>
                    <span className="fw-bold">Topic:</span>
                    <span className="ms-1 opacity-75" title={selectedItem && selectedItem?.topicName}>
                      {selectedItem && selectedItem?.topicName?.length > 50 ? selectedItem?.topicName?.slice(0, 50) + "..." : selectedItem?.topicName}
                    </span>
                  </p>

                  <Form.Group>
                    <Form.Label>
                      <span className="fw-bold">Comment: </span>
                    </Form.Label>
                    <Form.Control className="opacity-75" disabled as="textarea" style={{ fontSize: "0.813rem" }} defaultValue={selectedItem && selectedItem.comment} rows={4} cols={60}></Form.Control>
                  </Form.Group>
                </Modal.Body>
              </Modal>
            </tbody>
            <tfoot className="dut-foot">
              <tr>
                <td className="f-bold" colSpan={2}>
                  Total Items: {tableData?.length}
                </td>
                <td></td>
                <td></td>
                <td colSpan={2}>
                  <div className="d-flex justify-content-end" style={{ color: "#706F73" }}>
                    <div className="d-flex align-items-center">
                      <p className="me-2 mb-0" style={{ whiteSpace: "nowrap" }}>
                        Results per page
                      </p>
                      <select style={{ color: "#706F73" }} className="form-select m-2" value={resultsPerPage} onChange={handleResultsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                      <ul className="pagination mb-0">
                        <li className={`page-item me-1 ${currentPage === 1 ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handlePrevPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 1.5L1.5 7L7 12.5" stroke={currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                        {Array.from({ length: totalPaginationPages }, (_, i) => i + 1).map((page) => (
                          <li key={page} className={`page-item me-1 ${page === currentPage ? "active" : ""}`}>
                            <button className="page-link rounded pagination-styling" onClick={() => handlePageChange(page)}>
                              {page}
                            </button>
                          </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                          <button className="page-link" onClick={handleNextPageChange}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 12.5L6.5 7L1 1.5" stroke={currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DailyUpdateTableSection;
