import "./PublishPopup.css";
import { InfinitySpin,PublishPop,ReactModal} from "./UploadCoursesImport";

const PublishTask = (props) => {
  const { confirmPublish,isOpen, setIsOpen,type } = props;
  const handleCross = (e) => {
    setIsOpen(() => ({
      popUp: false,
      isLoading: false
    }));
  };
  return (
    <>
      <ReactModal show={isOpen.popUp} onHide={(e) => handleCross(e)} backdrop="static" keyboard={false} centered>
        <ReactModal.Header className="border-bottom-0"></ReactModal.Header>
        <ReactModal.Body className="d-flex justify-content-center flex-column align-items-center pt-1">
          <PublishPop />
          <ReactModal.Title id="example-modal-sizes-title-md" className=" fw-bold py-3">
            Publish {type}
          </ReactModal.Title>
          <div className="text-center">
            Are you sure you want to Publish {type}? <br></br> 
            {type === "Tech Talk" ? "The Tech Talk will be live and available for users." : "The Course will be live and available for users to enroll."}
          </div>
        </ReactModal.Body>
        <ReactModal.Footer className="d-flex justify-content-center border-top-0">
          {isOpen.isLoading ? (
            <div className="row d-flex justify-content-center draft-loader">
              <InfinitySpin className="d-flex align-items-center justify-content-center loader-svg " color="#ffc107" />
            </div>
          ) : (
            <div className="row d-flex justify-content-center mb-3">
              <button type="button" className="pp-no-btn me-1" onClick={(e) => handleCross(e)}>
                No, Cancel
              </button>
              <button type="button" className="publish-yes-btn ms-1" onClick={() => confirmPublish()}>
                Yes, Publish
              </button>
            </div>
          )}
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default PublishTask;
