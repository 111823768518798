import { useState, useReducer, useNavigate, SuccessfullVector, useEffect, axios, TechActionType, Select, Info, ExpandMore, ProfileDetails, HorizontalLine, PermissionsDisabled, ProfileDetailsDone, PermissionsEnabled, decryptData } from "../../AdminImport";
import { Modal } from "react-bootstrap";
import TechDropdown from "../AssignTask/TechDropdown";
import "./AddMentorModal.css";
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "400",
    marginBottom: "0.7rem",
  }),

  menu: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "var(--bs-body-color)",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    zIndex: 30,
  }),
  menuList: (provided) => ({
    ...provided,
    overflowY: "scroll",
    maxHeight: "12rem",
    zIndex: "99",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: "2rem",
    overflow: "auto",
    marginleft: "1rem",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),

  placeholder: (provided) => ({
    ...provided,
    display: "block",
    width: "100%",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "grey",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    default:
      return state;
  }
};
const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};

const AddMentorModal = (props) => {
  const { show, onHide, fetchMentorList, fetchUserList, userList, setModalData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [mentor, setMentor] = useState({
    technologyNames: [],
    isLoginAllowed: false,
    permissions: [],
    userList: userList,
    userId: null,
    userName: null,
    techDropdown: false,
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  
  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames).length !== 0;
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleClickClear = () => {
    onHide();
    setMentor((prevState) => ({
      ...prevState,
      technologyNames: [],
      userId: null,
      isLoginAllowed: false,
      permissions: [],
    }));
    setCurrentPage(1);
    dispatch({ type: TechActionType.SET_TECH_ID, payload: [] });
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: [] });
    dispatch({ type: TechActionType.SET_TECH_VALUE, payload: "" });
    const checkboxes = document.querySelectorAll(".tech-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleFormSubmit = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/UpdateUserRole`;
    const body = {
      id: mentor.userId,
      skillsId: state.selectedTechIds,
      isLoginAllowed: mentor.isLoginAllowed,
      userPermission: mentor.permissions.length == 0 ? "" : mentor.permissions,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    };

    const response = await axios.post(url, body, { headers });
    if (response.status == 200) {
      setModalData(() => ({
        isModalOpen: true,
        modalType: "success",
        modalTitle: "Mentor Added Successfully",
        modalMessage: "",
        icon: <SuccessfullVector />,
      }));
      handleClickClear();
      await fetchUserList();
      await fetchMentorList();
      onHide();
    } else {
      alert(response.data.result.message);
    }
  };
  const handleSave = (e) => {
    const { name, value, ariaLabel } = e.target;
    switch (name) {
      case "IsLoginAllowed":
        setMentor((prevState) => ({
          ...prevState,
          isLoginAllowed: !prevState.isLoginAllowed,
        }));
        break;
      case "permission":
        if (mentor.permissions.includes(ariaLabel)) {
          setMentor((prevState) => ({
            ...prevState,
            permissions: mentor.permissions
              .split(",")
              .filter((permission) => permission.trim() !== ariaLabel)
              .join(",")
              .trim(),
          }));
        } else {
          setMentor((prevState) => ({
            ...prevState,
            permissions: mentor.permissions.length == 0 ? ariaLabel : prevState.permissions + "," + ariaLabel,
          }));
        }
        break;
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleUserChange = (e) => {
    setMentor({
      ...mentor,
      userId: e.value,
      userName: e.label,
    });
  };
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton onClick={handleClickClear}>
          <Modal.Title className="modalheading-text ">Assign Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentPage === 1 && (
            <>
              <div className="mentor-step">
                <div className="row">
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <div className="row">{<ProfileDetails />}</div>
                    <div className="row fw-bold permissions-text">Select User</div>
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <HorizontalLine />
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <div className="row">{<PermissionsDisabled />}</div>
                    <div className={`row permissions-text permissions-disabled-text`}>Permissions</div>
                  </div>
                </div>
              </div>

              <div className="align-items-center">
                <div className="modal-body">
                  <form>
                    <div>
                      Select User <span style={{ color: "red" }}>*</span>
                    </div>
                    <Select
                      styles={customStyles}
                      className="basic-single"
                      classNamePrefix="select"
                      options={userList}
                      placeholder="Select User To Assign Role"
                      onChange={handleUserChange}
                      value={
                        mentor.userId != null && {
                          label: mentor.userName,
                          value: mentor.userId,
                        }
                      }
                    />
                    <div className="mb-3">
                      <label htmlFor="skills" className="col-form-label d-flex">
                        Technology Tags <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="container border p-0" style={{ borderRadius: "0.375rem" }}>
                        <div
                          className="input-with-button"
                          onClick={() => {
                            setMentor((prevState) => ({
                              ...prevState,
                              techDropdown: !prevState.techDropdown,
                            }));
                          }}
                        >
                          <input
                            style={{ width: "25.3rem" }}
                            type="text"
                            className="custom-input"
                            placeholder="Select Technology"
                            value={state.selectedTechIds.length !== 0 ? state.techValue : ""}
                            disabled
                          />
                          <div
                            className="modal-abs"
                            style={{
                              position: "absolute",
                              left: "0",
                              right: "0",
                              top: "0",
                              bottom: "0",
                            }}
                          ></div>
                          <ExpandMore />
                        </div>
                        <div>
                          <ul style={{ display: mentor.techDropdown ? "" : "none", width: "27rem" }} className="ul-styling">
                            <TechDropdown
                              dispatch={dispatch}
                              techDataComingChild={handleTechChange}
                              selectedTechIdsfromChild={handleTechIdChange}
                              selectedTechIds={state.selectedTechIds}
                              technologyNames={state.selectedTechNames}
                              selectedTech={mentor.skills}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="modal-footer border-top-0">
                <button type="button" className="btn cancel-button fw-bold" onClick={handleClickClear}>
                  <span className="cancel-text">Cancel</span>
                </button>

                <button
                  type="button"
                  className="btn mentor-save-button fw-bold"
                  disabled={state.selectedTechIds.length === 0 || mentor.userId == null}
                  onClick={() => {
                    setCurrentPage(2);
                  }}
                >
                  <span className="save-text">Next</span>
                </button>
              </div>
            </>
          )}
          {currentPage === 2 && (
            <>
              <div className="mentor-step">
                <div className="row">
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <div className="row">{<ProfileDetailsDone />}</div>
                    <div className="row fw-bold permissions-text" style={{ width: "6rem" }}>
                      User Selected
                    </div>
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <HorizontalLine />
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <div className="row">{<PermissionsEnabled />}</div>
                    <div className={`row permissions-text fw-bold`} style={{ width: "6rem" }}>
                      Permissions
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "28rem",
                  }}
                >
                  <h5 className="m-0 modalheading-text" style={{ fontSize: "14px" }}>
                    Activate Mentor
                  </h5>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="toggleButton" name="IsLoginAllowed" checked={mentor.isLoginAllowed} value={mentor.isLoginAllowed} onChange={handleSave} />
                  </div>
                </div>

                <div className="alert alert-info mb-0 box-for-alert-message mt-2 ms-0" role="alert">
                  <div className="d-flex justify-content-center align-items-center">
                    <Info />
                  </div>
                  <div className="text-for-alert">
                    <p className="m-0 mx-2">Activating a mentor will require to select any permission level from below.</p>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="mt-2">
                    <input
                      className="form-check-input me-2 small"
                      type="checkbox"
                      id="checkbox1"
                      name="permission"
                      aria-label="Session"
                      checked={mentor.permissions.includes("Session")}
                      onChange={(e) => {
                        handleSave(e);
                      }}
                      disabled={!mentor.isLoginAllowed}
                    />
                    <label className="form-check-label " style={{ fontWeight: "600", fontSize: "14px" }} htmlFor="checkbox1">
                      Training Session
                    </label>
                    <ul
                      style={{
                        listStyleType: "disc",
                        margin: "0 0 0 20px",
                        paddingLeft: 10,
                        fontSize: "13px",
                        color: mentor.isLoginAllowed ? "inherit" : "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <li>Dashboard access</li>
                      <li>Can conduct training sessions and assign & review tasks</li>
                      <li>Can see the session calendar</li>
                      <li>Know your mentor will be automatically activated</li>
                    </ul>
                  </div>

                  <div className="mt-2">
                    <input
                      className="form-check-input me-2 small"
                      type="checkbox"
                      id="checkbox2"
                      checked={mentor.permissions.includes("Project")}
                      name="permission"
                      aria-label="Project"
                      onChange={(e) => {
                        handleSave(e);
                      }}
                      disabled={!mentor.isLoginAllowed}
                    />
                    <label className="form-check-label" style={{ fontWeight: "600", fontSize: "14px" }} htmlFor="checkbox2">
                      Project
                    </label>
                    <ul
                      style={{
                        listStyleType: "disc",
                        margin: "0 0 0 20px",
                        paddingLeft: 10,
                        fontSize: "13px",
                        color: mentor.isLoginAllowed ? "inherit" : "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <li>Can assign tasks to SDEs</li>
                      <li>Can give ratings as per the task</li>
                      <li>Know your mentor will be automatically activated</li>
                    </ul>
                  </div>

                  <div className="mt-2">
                    <input
                      className="form-check-input me-2 small"
                      type="checkbox"
                      id="checkbox3"
                      checked={mentor.permissions.includes("Review")}
                      name="permission"
                      aria-label="Review"
                      onChange={(e) => {
                        handleSave(e);
                      }}
                      disabled={!mentor.isLoginAllowed}
                    />
                    <label className="form-check-label" style={{ fontWeight: "600", fontSize: "14px" }} htmlFor="checkbox3">
                      Final Review
                    </label>
                    <ul
                      style={{
                        listStyleType: "disc",
                        margin: "0 0 0 20px",
                        paddingLeft: 10,
                        fontSize: "13px",
                        color: mentor.isLoginAllowed ? "inherit" : "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <li>Only review the SDEs</li>
                    </ul>
                  </div>

                  <div className="mt-2">
                    <input
                      className="form-check-input me-2 small"
                      type="checkbox"
                      id="checkbox4"
                      checked={mentor.permissions.includes("Know Your Mentor")}
                      name="permission"
                      aria-label="Know Your Mentor"
                      onChange={(e) => {
                        handleSave(e);
                      }}
                      disabled={!mentor.isLoginAllowed}
                    />
                    <label className="form-check-label" style={{ fontWeight: "600", fontSize: "14px" }} htmlFor="checkbox4">
                      Know Your Mentor - Widget
                    </label>
                    <ul
                      style={{
                        listStyleType: "disc",
                        margin: "0 0 0 20px",
                        paddingLeft: 10,
                        fontSize: "13px",
                        color: mentor.isLoginAllowed ? "inherit" : "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <li>Only the mentor's details will be displayed on user portal</li>
                      <li>Will be automatically activated for Training Session and Project access</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="modal-footer border-top-0">
                <div className="d-flex justify-content-between" style={{ width: "466px" }}>
                  <button
                    type="button"
                    className="btn cancel-button"
                    onClick={() => {
                      setCurrentPage(1);
                    }}
                  >
                    <span className="cancel-text">Back</span>
                  </button>

                  <div>
                    <button type="button" className="btn cancel-button fw-bold me-2" data-bs-dismiss="modal" onClick={handleClickClear}>
                      <span className="cancel-text">Cancel</span>
                    </button>

                    <button type="button" className="btn mentor-save-button fw-bold" onClick={handleFormSubmit} disabled={mentor.permissions.length==0}>
                      <span className="save-text">Add</span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMentorModal;
