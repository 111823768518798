import React from 'react'
import { ArrowForwardIcon, CalendarIcon, FormatDate, LmsNotificationsSkeleton, WallOfFameIcon } from "../../AdminImport";

const WallOfFame = ({ fameData, isLoading }) => {

    const limitTitle = (description, maxLength) => {
        if (description?.length > maxLength) {
          return description.substring(0, maxLength) + "...";
        } else {
          return description;
        }
      };
    return (
        <>
            {isLoading ? (
                Array.from({ length: 4 }).map((_, index) => (
                    <LmsNotificationsSkeleton key={index} isCourseSkeleton={""} />
                ))
            ) : fameData && fameData.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center flex-column h-100">
                    <WallOfFameIcon />
                    <p className="fw-bold mt-3 mb-1 empty-certificate-text">No progress information right now!</p>
                    <p className="empty-certificate-fade-text mt-1">We’ll notify you when something arrives.</p>
                </div>
            ) :
                fameData && fameData.map((user, index) => (
                    <div key={index} className={`d-flex gap-2 p-2 border-bottom ${user?.courseCompletedDate ? "" : "lms-dashboard-notification-inner-enroll-card"}`}>
                        <div>
                            <p className="lms-user-img">
                                {user?.firstName?.split(" ")
                                    .map((name) => name.charAt(0).toUpperCase())
                                    .join("")}
                                {user?.lastName?.split(" ")
                                    .map((name) => name.charAt(0).toUpperCase())
                                    .join("")}
                            </p>
                        </div>
                        <div className="d-grid gap-1 w-100">
                            {user?.courseCompletedDate ? (
                                <div className="lms-notification-name course-upload-heading-name">
                                    {user?.firstName || ""} {user?.lastName || ""}
                                </div>
                            ) : (
                                <div className="d-flex gap-1">
                                    <div className="user-lms-new-course-badge user-lms-new-course-badge-triangular d-flex justify-content-center align-items-center"><p className="user-lms-new-course-text me-3 mb-0">New Course</p></div>
                                    <div className="lms-dashboard-notification-badge">
                                        <p className="lms-dashboard-notification-badge-text m-0">{user?.levelName}</p>
                                    </div>
                                </div>
                            )}
                            {user?.courseCompletedDate ? (
                                <div className="lms-notification-desc">
                                    Successfully completed the ‘{user?.courseLevel} Level’ of <b title={user?.courseTitle}>{limitTitle(user?.courseTitle,50)}</b>
                                </div>
                            ) : (
                                <div className="lms-notification-desc">
                                    <b>{user?.title}</b>
                                </div>
                            )}
                            <div className="d-flex justify-content-between">
                                {user?.courseCompletedDate ? (
                                    <div className="lms-notification-date d-flex gap-1 align-items-center">
                                        <CalendarIcon />
                                        {FormatDate(user?.courseCompletedDate)}
                                    </div>
                                ) : (
                                    <div className="lms-notification-date d-flex gap-1 align-items-center">
                                        <CalendarIcon />
                                        {FormatDate(user?.createdDate)}
                                    </div>
                                )}
                                {!user?.courseCompletedDate && (
                                    <div className="d-flex gap-1 align-items-center lms-notification-enroll pointer"
                                        onClick={() => {
                                            sessionStorage.setItem("courseId", user.id);
                                            // navigate('/lms/course-detail')
                                        }}>
                                        Enroll Now <ArrowForwardIcon className="lms-notification-enroll-chev" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
                )
            }
        </>

    )
}

export default WallOfFame; 