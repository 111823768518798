import { useEffect, useState, useReducer } from "react";
import { ReactComponent as ExpandMore } from "../../../../../Assets/expand_more.svg";
import axios from "axios";
import TechDropdown from "../../../../AdminPortal/Task/AssignTask/TechDropdown";
import { UserActionType, UsersDropdown } from "../../../../AdminPortal/AdminImport";
import CryptoJS from "crypto-js";
import TechActionType from "../../../../_common/TechActionType";
import ProjectMemberDropdown from "../ProjectMemberDropdown";
import ProjectTechDropdown from "../ProjectTechDropdown";

const reducer = (state, action) => {
  switch (action.type) {
    case UserActionType.SET_SELECTED_USER_IDS:
      return { ...state, selectedUserIds: action.payload };
    case UserActionType.SET_SELECTED_USERS_NAME:
      return { ...state, selectedUserName: action.payload };
      case UserActionType.SET_USERS_VALUE:
      return { ...state, usersValue: action.payload };
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
  usersValue: "",
  selectedUserIds: [],
  selectedUserName: [],
  selectAllChecked: false,
};

const EditProjectIdeaModal = ({ taskVersion, setTaskVersion, projectId, projectName, projectDescriptions, indexNumber, projectTechnology, memberName, textInput, setTextInput, projectVersion }) => {
  const [projName, setProjName] = useState("");
  const [proVersion, setProVersion] = useState(false);
  const [membersError, setMembersError] = useState(false);
  const [description, setDescription] = useState("");
  const [techErrors, setTechErrors] = useState(false);
  const [memberNames, setMemberNames] = useState(memberName);
  const [dropDown, setDropDown] = useState({
    techDropdown: false,
    userDropdown: false,
  });
  const [projNameError, setProjNameError] = useState(false);
  const [desError, setDesError] = useState(false);
  const [technologyNames, setTechnologyNames] = useState([]);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isProjectDescriptionValid, setIsProjectDescriptionValid] = useState(true);
  const [editProject, setEditProject] = useState({});
  const [projectToEdit, setProjectToEdit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(()=>{
    setProjName(projectName)
    setDescription(projectDescriptions)
    setTechnologyNames(projectTechnology)
    setMemberNames(memberName)
  },[projectId])

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames);
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({ type: TechActionType.SET_TECH_VALUE, payload: techValueString });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  useEffect(() => {
    const isUserEmpty = Array.isArray(state.selectedUserName) && state.selectedUserName.length !== 0;
    if (isUserEmpty) {
      const userValueString = state.selectedUserName.join(", ");
      dispatch({
        type: UserActionType.SET_USERS_VALUE,
        payload: userValueString,
      });
    }
  }, [state.selectedUserName, state.selectedUserIds, state.selectAllChecked]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleUserIdChange = (userIdData) => {
    dispatch({ type: UserActionType.SET_SELECTED_USER_IDS, payload: userIdData });
  };
  const usersDataComingFrmChild = (data) => {
    dispatch({ type: UserActionType.SET_SELECTED_USERS_NAME, payload: data });
  };

  const handleChangeProjNameError = (event) => {
    event.preventDefault();
    const name = event.target.value;
    setProjName(name);
    setIsProjectNameValid(name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/) ? true : false);
    if (!name || !name.match(/^(?!.*\s\s)[a-zA-Z0-9 ]{1,100}$/)) {
      setProjNameError(true);
    } else {
      setProjNameError(false);
    }
  };

  const handleChangeProjDescriptionError = (event) => {
    event.preventDefault();
    const description = event.target.value;
    setDescription(description);
    setIsProjectDescriptionValid(description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/) ? true : false);
    if (!description || !description.match(/^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{150,750}$/)) {
      setDesError(true);
    } else {
      setDesError(false);
    }
  };

  const truncate = (str, maxLength) => {
    if (str.length > maxLength) return str.slice(0, maxLength) + "...";
    else return str;
  };

  const handleDropdownToggle = (name) => {
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([dropdownName]) => [dropdownName, false])),
      [name]: !prevState[name],
    }));
  };

  const secretkeyUser = process.env.REACT_APP_USER_KEY;
  var parsedObject;
  const data = localStorage.getItem("userData");
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
    const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
    parsedObject = JSON.parse(decryptedJsonString);
  } else {
    console.error("No data found.");
  }

  const handleSubmit = async (e) => {
    setDropDown(false);
    const url = process.env.REACT_APP_API_URL_DOTNET + "/projectIdea/update";
    const body = {
      id: projectId,
      name: projName,
      description,
      techNames: state.selectedTechIds ? state.selectedTechIds : technologyNames,
      memberNames: state.selectedUserIds ? state.selectedUserIds : memberNames,
      members: [],
      technologyUsed: []
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.success == 200) {
        setTaskVersion((prevVersion) => prevVersion + 1);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
    }
    finally{
      setDropDown(false)
    }
  };

  useEffect(() => {
    setEditProject(projectToEdit);
  }, [projectToEdit]);

  useEffect(() => {
    setProjName(editProject?.name);
    setDescription(editProject?.description);
    setMemberNames(editProject?.members);
    setTechnologyNames(editProject?.technologyUsed);
  }, [editProject]);

  useEffect(() => {
    setProjName(projectName);
    setDescription(projectDescriptions);
    setMemberNames(memberName);
    setTechnologyNames(projectTechnology);
    setProVersion(projectVersion);
  }, [indexNumber, proVersion]);

  const handleEditProject = (project) => {
    setProjectToEdit(project);
  };
  const handleEditCloseModal = () => {
    setProjectToEdit(null);
    setProjName(projectName);
    setDescription(projectDescriptions);
    setTechnologyNames(projectTechnology);
    setMemberNames(memberName);
    setProjNameError(false);
    setDesError(false);
    setIsProjectNameValid(true);
    setIsProjectDescriptionValid(true);
    setDropDown(() => ({
      techDropdown: false,
      userDropdown: false,
    }));
  };
  
  return (
    <div className="modal fade" id="editProjectIdeaModal" tabIndex="-1" aria-labelledby="editProjectIdeaModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 add-project-wrapper" id="editProjectIdeaModalLabel">
              Edit your Project Idea
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleEditCloseModal}></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="project-name" className="col-form-label title-text">
                  Project Name<span style={{ color: "red" }}>*</span>
                </label>
                <input type="text" className="form-control" value={projName} id="project-name" placeholder="Enter Project Name" onChange={handleChangeProjNameError} />
                {!isProjectNameValid && projName && <span style={{ color: "red", fontSize: "11px" }}>Please enter a name with only letters and spaces, between 1 and 100 characters.</span>}
              </div>
              <div className="mb-3">
                <label htmlFor="project-description" className="col-form-label title-text">
                  Project Description<span style={{ color: "red" }}>*</span>
                </label>
                <textarea className="form-control" value={description} id="project-description" placeholder="Write Here.." onChange={(e) => handleChangeProjDescriptionError(e)} rows={3}></textarea>
                {!isProjectDescriptionValid && description && <span style={{ color: "red", fontSize: "11px" }}>Please Enter description only with letters, colon, comma and hyphen, between 150 and 750 characters.</span>}
              </div>

              <div className="mb-3">
                <label htmlFor="technology-used" className="col-form-label title-text" required>
                  Technology Used<span style={{ color: "red" }}>*</span>
                </label>
                <div className="container border p-0">
                  <div
                    className="input-with-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDropdownToggle("techDropdown");
                    }}
                  >
                    <input style={{ width: "27.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Technology" value={state.selectedTechNames ? state.selectedTechNames.map((tech) => tech.techName) : technologyNames} disabled />
                    <div
                      className="modal-abs"
                      style={{
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "0",
                        bottom: "0",
                      }}
                    ></div>
                    <ExpandMore />
                  </div>
                  <div>
                  <ul style={{ display: dropDown.techDropdown ? "" : "none" }} className="ul-styling">
                      <ProjectTechDropdown dispatch={dispatch} techDataComingChild={handleTechChange} selectedTechIdsfromChild={handleTechIdChange} selectedTechIds={state.selectedTechIds} technologyNames={state.selectedTechNames} selectedTech={technologyNames} />
                    </ul>
                  </div>
                </div>
                {techErrors && <span style={{ color: "red", fontSize: "11px" }}>Maximum 10 technologies allowed</span>}
              </div>

              <div className="mb-3">
                <label htmlFor="Members(Optional)" className="col-form-label title-text">
                  Members
                </label>
                <div className="container border p-0">
                    <div
                      className="input-with-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDropdownToggle("userDropdown");
                      }}
                    >
                      <input
                        style={{ width: "27.5rem", outline: "none" }}
                        type="text"
                        className="custom-input"
                        placeholder="Select Members"
                        value={state.selectedUserName}
                        disabled
                      />
                      <div
                        className="modal-abs"
                        style={{
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                          bottom: "0",
                        }}
                      ></div>
                      <ExpandMore />
                    </div>
                    <div>
                    <ul style={{ display: dropDown.userDropdown ? "" : "none" }} className="ul-styling">
                    <ProjectMemberDropdown 
                        dispatch={dispatch} 
                        usersDataComingChild={usersDataComingFrmChild} 
                        selectedUserIdsfromChild={handleUserIdChange} 
                        selectedUserIds={state.selectedUserIds} 
                        selectedUserName={state.selectedUserName} 
                        selectAllUsersCheck={state.selectAllChecked} 
                        selectedUsers={memberNames} 
                        />
                      </ul>
                    </div>
                  </div>
                {membersError && <span style={{ color: "red", fontSize: "11px" }}>Please enter upto 10 members only</span>}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={handleEditCloseModal}>
              <span className="cancel-text"> Cancel </span>
            </button>
            <button
              type="button"
              className="btn btn-primary save-button"
              data-bs-target="#myIdeaModal"
              data-bs-dismiss={!projNameError && !desError && state.selectedTechNames?.length !== 0 ? "modal" : ""}
              disabled={projNameError || desError || !isProjectDescriptionValid || state.selectedTechNames.length === 0 || !isProjectNameValid}
              onClick={(e) => {
                handleSubmit(e);
                setIsModalOpen(true);
              }}
            >
              <span className="save-text"> Save </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProjectIdeaModal;
