import { SkeletonTheme } from "react-loading-skeleton";
import { useEffect, useState, decryptData, handleApiError, axios, useNavigate, Skeleton } from "../UploadCoursesImport";
import "react-loading-skeleton/dist/skeleton.css";

const UploadTips = (props) => {
  const { type } = props;
  const [uploadTips, setUploadTips] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Instruction/getByActivityName?ActivityName=${type?.replace(/\s+/g, "")}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success == 200 || response.data.success == 204) {
        return setUploadTips(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    fetchData();
  }, [type]);

  return (
    <div className="card course-upload-tips dp-scroll">
      <p className="fw-bold">{type} Upload Tips</p>
      <div className="card-body p-0 d-inline-grid">
        {uploadTips && uploadTips.length == 0 && (
          <>
            <SkeletonTheme baseColor="#b0aeae">
              <div className="d-flex gap-1">
                <Skeleton count={1} width={20} className="me-2" />
                <Skeleton count={1} width={250} />
              </div>
              <p>
                <Skeleton count={6} width={250} />
              </p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#b0aeae">
              <div className="d-flex gap-1">
                <Skeleton count={1} width={20} className="me-2" />
                <Skeleton count={1} width={250} />
              </div>
              <p>
                <Skeleton count={6} width={250} />
              </p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#b0aeae">
              <div className="d-flex gap-1">
                <Skeleton count={1} width={20} className="me-2" />
                <Skeleton count={1} width={250} />
              </div>
              <p>
                <Skeleton count={6} width={250} />
              </p>
            </SkeletonTheme>
          </>
        )}
        {uploadTips &&
          uploadTips.map((tip) => (
            <div key={tip.id}>
              <div className="d-flex">
                <img src={`data:image/svg+xml;base64,${tip.imageName}`} alt="Tip Image" height={25} width={25} />
                <p className="mx-2 fw-bold">{tip.title}</p>
              </div>
              <p className="mb-3">{tip.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UploadTips;
