import { Pie } from "react-chartjs-2";
import "./topTech.css";
import { decryptData, useEffect, useMemo, useState, useNavigate, EmptyTopTech, PieChartSkeleton,axios,handleApiError } from "../../AdminImport";
const PieChartTopTech = (props) => {
  const { selectedBatches } = props;
  const [pieData, setPieData] = useState([]);
  const [pData, setPData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    let url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/topFiveTech`;
    let body ={
      batchId : selectedBatches
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    }

    try {
      const response = await axios.post(url, body, { headers })
      if (response.status == 200) {
        setPieData(response.data.result);
        setLoading(false);
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  const setDataToPie = () => {
    setPData([]);
    pieData?.forEach((val) => {
      const totalTime = val?.totalTime;
      if (totalTime) {
        const timeComponents = totalTime.split(':');
        if (timeComponents.length === 3) {
          const hours = parseInt(timeComponents[0], 10);
          const minutes = parseInt(timeComponents[1], 10);
          const seconds = parseInt(timeComponents[2], 10);

          const totalSeconds = hours * 3600 + minutes * 60 + seconds;

          setPData((prev) => [
            ...prev,
            {
              techName: val?.techName,
              techCount: totalSeconds,
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [selectedBatches]);

  useMemo(() => {
    setDataToPie();
  }, [pieData]);

  // totaltechcount, techdatawithcounts, techdatawithcolor these needs to be put inside usememo as with each render values keeps on adding in them
  const totalTechCount = pData?.reduce(
    (accumulator, item) => accumulator + parseInt(item.techCount),
    0
  );

  const techDataWithCounts = pData?.reduce((accumulator, item) => {
    const percentage = (
      (parseInt(item.techCount) / totalTechCount) *
      100
    ).toFixed(2);
    accumulator[item.techName] = percentage;
    return accumulator;
  }, {});

  let techDataWithColor = pData ? pData.map((val, ind) => {
      return { ...val };
    }): [];

  const colorArray = ["#28519E", "#3B82F6", "#99CC00", "#E23237", "#FFB81C"];

  colorArray.forEach((val, index) => {
    if (index >= techDataWithColor.length) return;
    techDataWithColor[index]["color"] = val;
    techDataWithColor[index]["techCount"] =
      techDataWithCounts[techDataWithColor[index]["techName"]];
  });

  const data = {
    labels: techDataWithCounts ? Object.keys(techDataWithCounts) : [],
    datasets: [
      {
        radius: "90%",
        data: techDataWithCounts ? Object.values(techDataWithCounts) : [],
        backgroundColor: colorArray,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 17,
          boxHeight: 17,
          // code for options tag in pie chart if needed in future
          generateLabels: (chart) => {
            let array = techDataWithCounts
              ? Object.keys(techDataWithCounts)
              : [];
            return array?.map((val, ind) => {
              const dataset = chart.data.datasets[0];
              return {
                text: `${val} (${techDataWithCounts[val]}%)`,
                fontColor: "#343435",
                fillStyle: chart.data.datasets[0].backgroundColor[ind],
                lineCap: dataset.borderCapStyle,
                lineDash: dataset.borderDash,
                lineDashOffset: dataset.borderDashOffset,
                lineJoin: dataset.borderJoinStyle,
                strokeStyle: "#fff",
                pointStyle: dataset.pointStyle,
                rotation: dataset.rotation,
              };
            });
          },
          fontColor: "#343435",
          responsive: true,
          maintainAspectRatio: false,
          width: 300,
          height: 300,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const currentValue = dataset.data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];
            return `${label}: ${currentValue}%`;
          },
        },
      },
    },
  };

  return (
    <div className="container mt-4 mobile-view" style={{ width: "25rem" }}>
      <div className="row">
        <div className="col">
          <h2
            style={{
              fontFamily: "Roboto",
              textAlign: "start",
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.3rem",
              color: "#002C3F",
              marginLeft: "0.8rem"
            }}
          >
            Top {pData?.length} Technology
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col pie-chart-parent-wrapper">
          {loading ? (
              <PieChartSkeleton/>
            ) : 
          (techDataWithColor?.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center flex-column ">
              <EmptyTopTech className="empty-top-tech"/>
              <p className="fw-bold mt-3 mb-1 empty-text">No technology information right now!</p>
              <p className="empty-fade-text">Active users not found on the portal.</p>
            </div>
          ) : (
            <div>
              <div
                style={{
                  width: "350px",
                  height: "350px",
                }}
              >
                <Pie data={data} options={options} />
              </div>
              <div className="pie-val-wrapper">
                {techDataWithColor.map((val, index) => {
                  return (
                    <div className="pie-value" key={index}>
                      <div className="pie-value-child">
                        <div
                          className="col-wrapper"
                          style={{ backgroundColor: val?.color }}
                        />
                        <div className="val-text">{val?.techName}</div>
                      </div>
                      <div className="val-text">{val?.techCount}%</div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartTopTech;
