import "react-loading-skeleton/dist/skeleton.css";
import "./LogCard.css";
import { useState, useEffect, SearchIcon, useNavigate, axios, handleApiError, decryptData,Skeleton,EmptySdeList } from "../../AdminImport";

function getInitials(name) {
  const names = name?.split(" ");
  const initials = names?.map((n) => n.charAt(0).toUpperCase());
  return initials?.join("");
}

const AssociateConsultant = (props) => {
  const { setSelectedUser, setLogData, selectedBatches } = props;
  const [sdeList, setSdeList] = useState({
    isLoading: true,
    originalData: [],
    filterData: [],
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleOnUserclick = async (id) => {
    const selectedUserData = sdeList.originalData.find((user) => user.id === id);
    setSelectedUser((prevState) => ({
      ...prevState,
      detailLogLoading:true,
      user: selectedUserData,
    }));
    setSelectedCard(id);

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/activityLog/getByUserId?userId=${id}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      setLogData(response.data.result);
      setSelectedUser((prevState) => ({
        ...prevState,
        detailLogLoading:false,
      }));
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleFiltersChange = (e) => {
    var searchValue = e.target.value.toLowerCase();
    const getFilterItems = (items, searchValue, keyValue) => {
      if (searchValue) {
        return items.filter((item) => item?.[keyValue].toLowerCase().startsWith(searchValue.toLowerCase()));
      }
      return items;
    };
    const filters = getFilterItems(sdeList.originalData, searchValue, "name");
    setSdeList((prevState) => ({
      ...prevState,
      filterData: filters,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [selectedBatches]);

  const fetchData = async () => {
    let url = `${process.env.REACT_APP_API_URL_DOTNET}/ActivityLog/getLogsGroupByUserAndBatchName`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const sortedData = response.data.result.sort((a, b) => {
          const firstNameComparison = a.name.localeCompare(b.name);
          if (firstNameComparison !== 0) {
            return firstNameComparison;
          }
          return a.name.localeCompare(b.name);
        });
        setSdeList((prevState) => ({
          ...prevState,
          isLoading: false,
          filterData: sortedData,
          originalData: sortedData,
        }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };
  
  function renderAssociates(userData) {
    const initials = getInitials(userData.name);
    const isSelected = userData.id === selectedCard;
    return (
      <>
        <div key={userData.id} className={`card associate-mapped-card-log me-1 ${isSelected ? 'log-card-selected' : ''}`}>
          <div className=" row mentor-wrapper">
            <div
              onClick={() => {
                handleOnUserclick(userData.id);
              }}
              className="col-4 frame pointer"
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.938rem",
                }}
              >
                {initials}
              </p>
            </div>
            <div
              style={{ width: "19.75rem" }}
              onClick={() => {
                handleOnUserclick(userData.id);
              }}
              className=" col-4 pointer"
            >
              <div className="frame-text">{userData.name}</div>
              <div className="frame-id">{userData.cgiId}</div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderSkeleton() {
    const skeletonItems = Array.from({ length: 5 }).map((_, index) => (
      <div key={index} className="card associate-mapped-card-log skeleton">
        <div className="name-column">
            <Skeleton width={38} height={38} borderRadius={50} highlightColor="#97c0c5" />
            <div className="tags">
              <div className="tag1">
                <Skeleton width={100} height={12} />
              </div>
              <div className="tag2">
                <Skeleton width={50} height={10} />
              </div>
            </div>
        </div>
      </div>
    ));
    return skeletonItems;
  }

  useEffect(() => {
    if (sdeList.filterData.length > 0) {
      handleOnUserclick(sdeList.filterData[0].id);
    }
    else{
      setSelectedUser((prevState) => ({
        ...prevState,
        detailLogLoading:false,
      }));
    }
  }, [sdeList.originalData]);

  return (
    <>
      <div>
        <p className="log-head">Activity Logs</p>
        <div className="card mentor-card pt-0">
          <div className="log-header-nav">
            <div className="nav-btn pointer activated">
              <button className="btn-nav p-0">SDEs</button>
            </div>
          </div>
          <div className="d-flex align-items-center ps-1 associate-search-log-wrapper">
            <SearchIcon />
            <input
              className="search-associate-log "
              type="text"
              placeholder="Search By Name"
              onChange={(e) => {
                e.preventDefault();
                handleFiltersChange(e);
              }}
            />
          </div>
          <div className="dp-scroll" style={{ overflowY: "scroll", overflowX: "hidden" }}>
            {sdeList.isLoading ? renderSkeleton() :
            sdeList.filterData?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <EmptySdeList className="mt-5"/>
                <p className="fw-bold mt-3 mb-1 empty-certificate-text">No User information right now!</p>
                <p className="empty-certificate-fade-text mt-1">Active users not found on the portal.</p>
              </div>
            ) :
            sdeList.filterData.map((userData) => renderAssociates(userData))}
          </div>
        </div>
      </div>
    </>
  );
};
export default AssociateConsultant;
