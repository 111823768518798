import React from "react";
import { ReactComponent as DeleteVector } from "../../../../../Assets/VectordeleteTsk.svg";
import axios from "axios";
import { decryptData, useNavigate } from "../../../UserImport";

const DeleteProjectIdea = ({ lastIndex,projectId, setIsOpen, setShowDeleteTask, setTaskVersion, indexNumber, setIndexNumber }) => {
  const handleCross = (e) => {
    e.preventDefault();
    setIsOpen(false);
    setShowDeleteTask(false);
  };
  const navigate = useNavigate();

  const confirmDelete = async () => {
    var parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    await axios
      .delete(process.env.REACT_APP_API_URL_DOTNET + `/projectIdea/Delete?projectId=${projectId}`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      })
      .then((res) => {
        setIsOpen(false);
        setShowDeleteTask(false);
        setTaskVersion((prevVersion) => prevVersion + 1);
        if (lastIndex === indexNumber) {
          setIndexNumber(indexNumber - 1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className="delete-task-wrapper">
      <div className="pp-popup-wrapper">
        <div className="pp-cross-btn mt-2">
          <button type="button" className="btn-close me-2" onClick={(e) => handleCross(e)}></button>
        </div>
        <div className="row mt-5">
          <DeleteVector />
        </div>
        <div className="row mt-3 d-flex ">
          <p className="d-flex justify-content-center fw-bold">Delete Project Idea </p>
          <p className="d-flex justify-content-center"> Are you sure you want to delete the project idea?</p>
        </div>
        <div className="row d-flex justify-content-center mb-4">
          <button type="button" className="pp-no-btn me-1" onClick={(e) => handleCross(e)}>
            No, Cancel
          </button>
          <button type="button" className="pp-yes-btn ms-1" onClick={() => confirmDelete()}>
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProjectIdea;
