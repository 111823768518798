import { useState, useEffect } from "react";
import "./mentorlist.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { NoIdea } from "../../UserImport";

const MentorComponent = () => {
  const [mentors, setMentors] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Fetch mentors data from the API
    setTimeout(() => {
      fetchMentors();
    }, 1000);
  }, []);

  const fetchMentors = async () => {
    const secretkeyUser = process.env.REACT_APP_USER_KEY;
    var parsedObject;
    const data = localStorage.getItem("userData");
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
      const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
      parsedObject = JSON.parse(decryptedJsonString);
    } else {
      console.error("No data found.");
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/mentorDetails`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setMentors(data.result);
        setIsLoading(false);
        // setTableData(data.result);
      } else if (response.status === 401) {
        navigate("/error/statusCode=401");
      } else if (response.status === 400) {
        navigate("/error/statusCode=400");
      } else if (response.status === 500) {
        navigate("/error/statusCode=500");
      } else if (response.status === 404) {
        navigate("/error/statusCode=404");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching mentors:", error);
    }
  };

  return (
    <>
      <div
        className="card"
        style={{
          height: "370px",
          width: "378px",
          alignContent: "center",
          boxShadow: "0px 4px 20px rgba(40, 52, 73, 0.15)",
        }}
      >
        <div className="border-bottom ">
          <h5 className="card-title dtt-hfs-ml">Know Your Mentors</h5>
        </div>
        <div className="box-shadow d-flex justify-content-center align-item-center">
          {isLoading ? (
            <div className="card-body pt-4" style={{ width: "329px", height: "236px" }}>
              <div>
                <div className="d-block rounded-circle">
                  <Skeleton circle={true} width={80} height={80} />
                </div>
                <div className="mentor-text d-flex flex-column align-items-center">
                  <p className="card-text fs mt-2">
                    <Skeleton width={106.61} height={13} />
                  </p>
                  <p className="role-fs">
                    <Skeleton width={68} height={10} />
                  </p>
                  <p className="mx-0 mt-0 p-0" style={{ marginBottom: "10px" }}>
                    <Skeleton inline={true} width={69.72} height={20.38} borderRadius={6} style={{ marginRight: "13px" }} />
                    <Skeleton inline={true} width={41.41} height={20.38} borderRadius={6} style={{ marginRight: "13px" }} />
                    <Skeleton inline={true} width={84.75} height={20.38} borderRadius={6} />
                  </p>
                  <p>
                    <Skeleton inline={true} width={68} height={20.38} borderRadius={6} style={{ marginRight: "13px" }} />
                    <Skeleton inline={true} width={64} height={20.38} borderRadius={6} style={{ marginRight: "13px" }} />
                    <Skeleton inline={true} width={38.42} height={20.38} borderRadius={6} />
                  </p>
                </div>
              </div>
            </div>
          ) : mentors.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <NoIdea />
                <p className="fw-bold empty-text">No Mentor Yet!</p>
              </div>
          ) : (
            <div id="carouselExampleDark" className="carousel slide " data-bs-ride="carousel">
              <div className="carousel-indicators KYM" style={{}}>
                {mentors?.map((mentor, index) => (
                  <button key={index} type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={index} className={`bg-dark ${index === 0 ? "active" : ""}`} aria-current={index === 0 ? "true" : "false"} aria-label={`Slide ${index + 1}`}></button>
                ))}
              </div>

              <div className="carousel-inner crousal-set " role="listbox">
                {mentors?.map((mentor, index) => (
                  <div key={index} className={`carousel-item carousel-styling ${index === 0 ? "active" : ""} border`}>
                    <div className="card-body pt-4" style={{ width: "329px", height: "236px" }}>
                      <div>
                        {mentor.imageUrl && mentor.imageUrl.includes("https://") ? (
                          <img src={mentor.imageUrl} className="d-block rounded-circle" alt="Mentor" />
                        ) : (
                          <div className="mentor-img">
                            <p style={{ fontSize: "2rem", marginTop: "15px" }}>
                              {mentor.firstName
                                .split(" ")
                                .map((name) => name.charAt(0).toUpperCase())
                                .join("")}
                              {mentor.lastName
                                .split(" ")
                                .map((name) => name.charAt(0).toUpperCase())
                                .join("")}
                            </p>
                          </div>
                        )}
                        <div className="mentor-text">
                          <p className="card-text fs">
                            <b>{`${mentor.firstName} ${mentor.lastName}`}</b>{" "}
                          </p>
                          <p className="role-fs">{mentor.designation}</p>
                          <div className="row">
                            <div className="flex">
                              {mentor.skills.length > 5 ? (
                                <>
                                  {mentor.skills.slice(0, 5).map((skill, skillIndex) => {
                                    return (
                                      <span key={skillIndex} className="badge badge-color">
                                        {skill ? skill.toUpperCase() : ""}{" "}
                                      </span>
                                    );
                                  })}
                                  <span title={mentor.skills.slice(5).join(", ")} className="badge badge-color">
                                    + {mentor.skills.length - 5} more
                                  </span>
                                </>
                              ) : (
                                mentor.skills &&
                                mentor.skills.map((skill, skillIndex) => {
                                  return (
                                    <span key={skillIndex} className="badge badge-color">
                                      {skill ? skill.toUpperCase() : ""}{" "}
                                    </span>
                                  );
                                })
                              )}

                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <br />
      </div>
    </>
  );
};

export default MentorComponent;
