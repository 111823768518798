import {useState,useEffect,EmptyState,CertificateImage,BadgeImage,LinkIcon,FormatDate,BadgeIcon,UsersWithCertificatesSkeleton,EmptyCertificate,BadgeReportEmpty} from "../../UserImport";
import "../UserMilestoneDetails/UserMilestoneDetails.css"
const UserMilestoneDetails = (props) => {
  const { certificateData, certificateDataLoading } = props;
  const [selectedButtons, setSelectedButtons] = useState([]);
 
  const handleButtonClick = (index, button) => {
    setSelectedButtons((prevSelectedButtons) => {
      const newSelectedButtons = [...prevSelectedButtons];
      newSelectedButtons[index] = button;
      return newSelectedButtons;
    });
  };
 
  useEffect(() => {
    setSelectedButtons(certificateData.map(() => "certificates"));
  }, [certificateData]);
 
  return (
    <>
      {certificateDataLoading ? (
        <div className="card empty-user-detailed-certificate p-0" >
          <div className="accordion w-100 cert-accordion ">
            {Array.from({ length: 9 }).map((_, index) => <UsersWithCertificatesSkeleton key={index} />)}
          </div>
        </div>
      ) : certificateData?.length > 0 ? (
        <div className="card empty-user-detailed-certificate p-0">
          <div className="accordion w-100 cert-accordion " id="accordionPanelsStayOpenExample">
            {certificateData?.map((data, index) => {
              const accordionId = `accordionPanel-${data.userId}-${index}`;
              const collapseId = `collapse-${data.userId}-${index}`;
              return (
                <div className="" key={collapseId}>
                    <div className="accordion-body p-0 mt-3">
                      <div className="cert-accordion-body d-flex justify-content-center">
                        <button
                          className={`certificates-btn ${selectedButtons[index] === "certificates" ? "selected-btn" : "non-selected-btn"} d-flex align-items-center border-0 ps-3`}
                          onClick={() => handleButtonClick(index, "certificates")}
                        >
                          <CertificateImage className={`certificate-img-btn ${selectedButtons[index] === "certificates" ? "certificate-img-btn-selected" : "certificate-img-btn-not-selected"}`} />
                          <p className="certificate-text m-0 ms-2">Certificates</p> <p className="p-4 certificate-count-text m-0">{data?.certificateCount.toString().padStart(2, "0")}</p>
                        </button>
                        <button
                          className={`certificates-btn ${selectedButtons[index] === "badge" ? "selected-btn" : "non-selected-btn"} d-flex align-items-center border-0 ps-3`}
                          onClick={() => handleButtonClick(index, "badge")}
                        >
                          <BadgeImage className={`certificate-img-btn  ${selectedButtons[index] === "badge" ? "certificate-img-btn-selected" : "certificate-img-btn-not-selected"}`} />
                          <p className="certificate-text m-0 ms-2">Badges</p> <p className="p-4 certificate-count-text m-0">{data?.badgesCount.toString().padStart(2, "0")}</p>
                        </button>
                      </div>
                      {selectedButtons[index] === "certificates" ? (
                        <div className={`user-certificate-details dp-scroll pt-0 ${data.certificateCount === 0 ? "d-flex align-items-center justify-content-center" : ""}`}>
                          {data.certificateCount === 0 && <h6 className="p-4 fw-bold d-grid justify-content-center gap-3 align-items-center"><EmptyCertificate style={{width:"100%"}}/>No Certificates Currently</h6>}
                          {data.certificates.map(
                            (certificate, index) =>
                              certificate.typeName === "Certificate" && (
                                <div className={`row border-bottom pb-2 pt-3`} key={index}>
                                  <div className="row">
                                    <div className="col">
                                      <p className="certificate-achieved-name">{certificate.certificateName}</p>
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-between">
                                    <div className="col">
                                      <p className="certificate-date">
                                        Issued on: <span className="certificate-date-number ms-1">{FormatDate(certificate.issuedDate)}</span>
                                      </p>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                      <p className="certificate-date">
                                        Expiry: <span className="certificate-date-number ms-1">{certificate.expiryDate!=null ? FormatDate(certificate.expiryDate) : "NA"}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <LinkIcon />
                                      <a className="link-offset-2 link-underline link-underline-opacity-0 ms-2" href={certificate.link} target="_blank" rel="noopener noreferrer">
                                        Certificate Link
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      ) : (
                        <div className={`user-certificate-details dp-scroll pt-0 ${data.badgesCount === 0 ? "d-flex align-items-center justify-content-center" : ""}`}>
                          {data.badgesCount === 0 && <h6 className="p-4 fw-bold d-grid justify-content-center gap-3 align-items-center"><BadgeReportEmpty style={{width:"100%"}}/>No Badges Currently</h6>}
                          {data.certificates.map(
                            (certificate, index) =>
                              certificate.typeName === "Badge" && (
                                <div className={`row border-bottom pb-2 pt-3`} key={index}>
                                  <div className="row">
                                    <div className="col">
                                      <p className="certificate-achieved-name">{certificate.certificateName}</p>
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-between">
                                    <div className="col">
                                      <p className="certificate-date">
                                        Issued on: <span className="certificate-date-number ms-1">{FormatDate(certificate.issuedDate)}</span>
                                      </p>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                      <p className="certificate-date">
                                        Expiry: <span className="certificate-date-number ms-1">{certificate.expiryDate!=null ? FormatDate(certificate.expiryDate) : "NA"}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col d-flex align-items-center justify-content-center">
                                      <img src={certificate.link!=null && certificate.link.includes("https://")  ? certificate.link : BadgeIcon} alt={certificate.certificateName} width={72} height={72} />
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="card empty-user-detailed-certificate d-flex justify-content-center p-0">
          <div className="col-12 d-flex justify-content-center">
            <EmptyState />
          </div>
          <div className="col-12 d-flex justify-content-center assign-task-empty">
            <p>No Certificate Category Selected! </p>
          </div>
        </div>
      )}
    </>
  );
};
 
export default UserMilestoneDetails;