import "../Insights/insights.css";
import { useEffect, useState, useNavigate, EmptyInsights, decryptData, InsightsSkeleton, SearchIcon, Bullet, axios, handleApiError } from '../../AdminImport';

const Insights = (props) => {
  const { selectedBatches } = props;
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [insights, setInsights] = useState({
    originalInsightsData: [],
    filteredData: [],
    loading: true
  });
  const navigate = useNavigate();

  const handleFiltersChange = () => {
    const getFilterItems = (items, searchValue) => {
      if (searchValue) {
        let fitlerData = items.filter((item) =>
          item.techName?.toLowerCase().includes(searchValue.toLowerCase())
        );
        return fitlerData;
      }
      return items;
    };
    const filters = getFilterItems(insights.originalInsightsData, searchFilterValue);
    setInsights({
      ...insights,
      filteredData: filters
    });
  };

  useEffect(() => {
    InsightData();
  }, [selectedBatches]);

  useEffect(() => {
    handleFiltersChange();
  }, [searchFilterValue]);

  const InsightData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    let url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/insights`;
    let body = {
      batchId: selectedBatches
    }

    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        setInsights({
          ...insights,
          originalInsightsData: response.data.result,
          filteredData: response.data.result,
          loading: false
        });
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      setInsights({
        ...insights,
        loading: false,
      });
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  return (
    <div>
      <div className="about-insight col">Insights</div>
      <div className="insights-card">
        {insights.originalInsightsData.length != 0 &&
          <div className="d-flex align-items-center ps-3 insights-search-wrapper mb-0">
            <SearchIcon />
            <input
              className="search-insights"
              type="text"
              value={searchFilterValue}
              placeholder="Search"
              onChange={(event) => {
                event.preventDefault();
                setSearchFilterValue(event.target.value);
              }}
            />
          </div>}

          {insights.loading ? (
           <div
         >
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
              <InsightsSkeleton />
            </div>
          ) : (
            insights?.originalInsightsData?.length == 0 || insights?.filteredData?.length == 0 ?
             ( <>
                <div className="d-flex justify-content-center align-items-center flex-column h-100" >
                  <EmptyInsights className="empty-insights-state" />
                  <p className="fw-bold mt-3 mb-1 empty-insights-text">No recent data right now!</p>
                  <p className="empty-insights-fade-text">We’ll notify you when something arrives.</p>
                </div>
              </> ):(
                <div
                className=" insights p-2 dp-scroll"
              >
              {insights?.filteredData.map((insights, index) => {
                return (
                  <div className="div-insights" key={index}>
                    <div className="row">
                      <div className="col-2 image-div">
                        <img
                          src={insights.techLink}
                          alt="Description of the image"
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <div className="exam-name">{insights.techName}</div>
                        <div className="number-of-test">
                          {insights.beginner} Beginner <Bullet  className="mx-1"/>{" "}
                          {insights.intermediate} Intermediate <Bullet className="mx-1"/>{" "}
                          {insights.advanced} Advanced
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>)
          )}
      </div>
    </div>
  );
}
export default Insights;