import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const AdminAuthGuard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const secretKey = process.env.REACT_APP_USER_KEY;
  const data = localStorage.getItem("userData");
  let decryptedObject;
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
    decryptedObject = JSON.parse(decryptedJsonString);
  } else {
    console.error("No data found.");
  }

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigateAccordingToPermission = (str) => {
    const path = location.pathname;
    const permissionArray = str?.split(",");
    let session, review, project;
    permissionArray?.forEach((element) => {
      if (element === "Session") session = true;
      if (element === "Project") project = true;
      if (element === "Review") review = true;
    });
    if (path === "/lms/dashboard" && session) {
      navigate(path);
    } else if (
      (path === "/mentor/assign-task" || path === "/mentor/project-rating" || path === "/mentor/gradeAssignment") &&
      (project || session)
    ) {
      navigate(path);
    } else if (
      (path === "/mentor/review-associates" ||
        path === "/mentor/review-associates/inter-performance-review") &&
      review
    ) {
      navigate(path);
    } else {
      if (session) navigate("/lms/dashboard");
      else if (project) navigate("/mentor/assign-task");
      else if (review) navigate("/mentor/review-associates");
    }
  };
  const handleAuth = () => {
    if (localStorage.getItem("login")) {
      if (localStorage.getItem("login") === "false") {
        navigate("/");
        setIsAuthenticated(false);
      } else {
        const roleName = decryptedObject.decoded.RoleName;
        const hrmsDesignationName = decryptedObject.decoded.HrmsDesignationName?.toLowerCase();
        const permissionTypeName = decryptedObject.decoded.PermissionTypeName;

        if (roleName === process.env.REACT_APP_USER_DES_ADMIN) {
          navigate(location.pathname);
        } else if (roleName === process.env.REACT_APP_USER_DES_USER) {
          if (hrmsDesignationName === "sde" || hrmsDesignationName === "intern") {
            navigate("/dashboard");
          } else {
            navigate("/lms/dashboard");
          }
        } else {
          navigateAccordingToPermission(permissionTypeName);
        }
        setIsAuthenticated(true);
      }
    } else {
      navigate("/");
      setIsAuthenticated(false);
    }
  };
  useEffect(() => {
    handleAuth();
  }, [isAuthenticated]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default AdminAuthGuard;
