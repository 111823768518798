import { useMemo, useNavigate, CertificateImage, BadgeImage, ArrowForwardIcon, MilestoneCompleteIcon, MilestoneQueueIcon, Skeleton, MilestoneStar } from "../../UserImport";
import "./MilestoneCard.css";

const MilestoneCard = (props) => {
  const { mileStoneData, milestoneDetail, totalTimeSpent, isLoading ,userCourseAchievementPoints} = props;
  const navigate = useNavigate();

  function convertSecondsToHoursAndMinutes(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    return { hours, minutes };
  }

  const { hours: totalHours, minutes: totalMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(totalTimeSpent?.totalTimeWorked || 0), [totalTimeSpent?.totalTimeWorked]);
  const { hours: currentWeekHours, minutes: currentWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(totalTimeSpent?.currentWeekTime || 0), [totalTimeSpent?.currentWeekTime]);
  const { hours: previousWeekHours, minutes: previousWeekMinutes } = useMemo(() => convertSecondsToHoursAndMinutes(totalTimeSpent?.previousWeekTime || 0), [totalTimeSpent?.previousWeekTime]);

  const absoluteDifferenceHours = (Math.abs(currentWeekHours - previousWeekHours) + Math.abs((currentWeekMinutes - previousWeekMinutes) / 60)).toFixed(2);

  return isLoading ? (
    <>
      <div className="col-5 milestone-dashboard-level-part pointer">
        <div className="w-100">
          <div className="milestone-dashboard-text">
            My Achievements <ArrowForwardIcon className="milestone-chev" />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-block milestone-dashboard-sub-section-width">
            <div className="milestone-dashboard-level-text">Milestone</div>
            <Skeleton width={140} height={25} highlightColor="#002C3F" />
          </div>
          <div><Skeleton className="milestone-dashboard-sapphire-badge"/></div>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <div className="d-flex ">
            <Skeleton width={140} height={40} highlightColor="#FFB81C" />
          </div>
          <div className="d-flex ">
            <Skeleton width={140} height={40} highlightColor="#FFB81C" />
          </div>
        </div>
      </div>
      <div className="col-4 milestone-dashboard-level-part">
        <div className="d-grid gap-1">
          <div className="milestone-dashboard-level-text" style={{ color: "#F4F6F5" }}>
            Total Time Spent
          </div>
          <div className="milestone-dashboard-level-text milestone-dashboard-font-eighteen">
            <Skeleton width={150} highlightColor="#002C3F" />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-grid">
            <div className="milestone-dashboard-level-text fw-normal milestone-dashboard-font-twelve">This Week</div>
            <div className="milestone-card-total-hours">
              <Skeleton width={35} highlightColor="#002C3F" />
            </div>
            <div className="milestone-dashboard-level-text fw-normal">Hrs</div>
          </div>
          <div className="d-grid align-items-end gap-1">
            <div className="milestone-dashboard-level-text">
              <Skeleton highlightColor="#002C3F" />
            </div>
            <div className="milestone-dashboard-level-text fw-normal milestone-dashboard-font-twelve">Last Week</div>
          </div>
        </div>
      </div>
      <div className="col-3 d-grid gap-2 p-0 milestone-sub-width">
        <div className="milestone-dashboard-level-part">
          <div className="milestone-dashboard-level-text">Enrolled Courses</div>
          <div className="d-flex justify-content-between">
            <div className="dashboard-milestone-course-count">
              <Skeleton highlightColor="#002C3F" width={30} />
            </div>
            <div>
              <MilestoneQueueIcon />
            </div>
          </div>
        </div>
        <div className="milestone-dashboard-level-part">
          <div className="milestone-dashboard-level-text">Completed Courses</div>
          <div className="d-flex justify-content-between">
            <div className="dashboard-milestone-course-count">
              <Skeleton highlightColor="#002C3F" width={30} />
            </div>
            <div>
              <MilestoneCompleteIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className="col-5 milestone-dashboard-level-part pointer"
        onClick={() => {
          navigate("/lms/milestones");
        }}
      >
        <div className="w-100">
          <div className="milestone-dashboard-text">
            My Achievements <ArrowForwardIcon className="milestone-chev" />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-block milestone-dashboard-sub-section-width">
            <div className="milestone-dashboard-level-text">Milestone</div>
            {(userCourseAchievementPoints === undefined || userCourseAchievementPoints?.length === 0 || userCourseAchievementPoints?.milestoneLevel === null || userCourseAchievementPoints?.totalAchievedPoints ===0) ? (
              <div className="d-flex milestone-dashboard-level-name gap-2">NA
                <div className="d-flex gap-1">
                  <div className="d-flex justify-content-center align-items-center"><MilestoneStar /></div>
                  <div className="d-flex justify-content-center align-items-center milestone-dashboard-achieved-points">0 points</div>
                </div>
              </div>
            ) : (
              <div className="d-flex milestone-dashboard-level-name gap-2">{userCourseAchievementPoints?.milestoneLevel} Belt
                <div className="d-flex gap-1">
                  <div className="d-flex justify-content-center align-items-center"><MilestoneStar /></div>
                  <div className="d-flex justify-content-center align-items-center milestone-dashboard-achieved-points">{userCourseAchievementPoints?.totalAchievedPoints} points</div>
                </div>
              </div>
            )}
          </div>
          {!(userCourseAchievementPoints === undefined || userCourseAchievementPoints?.length === 0) && <div className={`milestone-dashboard-${userCourseAchievementPoints?.milestoneLevel?.toLowerCase()}-badge`}></div>}
        </div>
        <div className="d-flex justify-content-between gap-2">
          {milestoneDetail === undefined ? (
            <div className="d-flex milestone-dashboard-btn">
              <div className="d-flex align-items-center gap-1 cert-badge-text">
                <CertificateImage className="dashboard-cert-image" />
                Certificates
              </div>
              <div className="d-flex justify-content-end align-items-center w-100 cert-badge-text">0</div>
            </div>
          ) : (
            <div className="d-flex milestone-dashboard-btn">
              <div className="d-flex align-items-center gap-1 cert-badge-text">
                <CertificateImage className="dashboard-cert-image" />
                Certificates
              </div>
              <div className="d-flex justify-content-end align-items-center w-100 cert-badge-text">{milestoneDetail && milestoneDetail.certificateCount}</div>
            </div>
          )}

          <div className="d-flex milestone-dashboard-btn">
            <div className="d-flex align-items-center gap-1 cert-badge-text">
              <BadgeImage className="dashboard-badge-image" />
              Badges
            </div>
            {milestoneDetail === undefined ? (
              <div className="d-flex justify-content-end align-items-center w-100 cert-badge-text">0</div>
            ) : (
              <div className="d-flex justify-content-end align-items-center w-100 cert-badge-text">{milestoneDetail && milestoneDetail.badgesCount}</div>
            )}
          </div>
        </div>
      </div>
      <div className="col-4 milestone-dashboard-level-part">
        <div className="d-grid gap-1">
          <div className="milestone-dashboard-level-text" style={{ color: "#F4F6F5" }}>
            Total Time Spent
          </div>
          {totalTimeSpent === undefined ? (
            <div className="milestone-dashboard-level-text milestone-dashboard-font-eighteen">0hrs 0mins</div>
          ) : (
            <div className="milestone-dashboard-level-text milestone-dashboard-font-eighteen">
              {totalHours}hrs {totalMinutes}mins
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-grid">
            <div className="milestone-dashboard-level-text fw-normal milestone-dashboard-font-twelve">This Week</div>
            {totalTimeSpent === undefined ? <div className="milestone-card-total-hours">0</div> : <div className="milestone-card-total-hours">{currentWeekHours}</div>}
            <div className="milestone-dashboard-level-text fw-normal">Hrs</div>
          </div>
          <div className="d-grid align-items-end gap-1">
            {totalTimeSpent === undefined ? (
              <div className="milestone-dashboard-level-text fw-bold">0 hrs</div>
            ) : (
              <div className="milestone-dashboard-level-text fw-bold">{absoluteDifferenceHours} hrs</div>
            )}
            <div className="milestone-dashboard-level-text fw-normal milestone-dashboard-font-twelve">Last Week</div>
          </div>
        </div>
      </div>
      <div className="col-3 d-grid gap-2 p-0 milestone-sub-width">
        <div className="milestone-dashboard-level-part">
          <div className="milestone-dashboard-level-text">Enrolled Courses</div>
          <div className="d-flex justify-content-between">
            {mileStoneData === undefined || mileStoneData?.length === 0 ? (
              <div className="dashboard-milestone-course-count">0</div>
            ) : (
              <div className="dashboard-milestone-course-count">{mileStoneData && mileStoneData[0]?.totalEnrolledCourses}</div>
            )}
            <div>
              <MilestoneQueueIcon />
            </div>
          </div>
        </div>
        <div className="milestone-dashboard-level-part">
          <div className="milestone-dashboard-level-text">Completed Courses</div>
          <div className="d-flex justify-content-between">
            {mileStoneData === undefined || mileStoneData?.length === 0 ? (
              <div className="dashboard-milestone-course-count">0</div>
            ) : (
              <div className="dashboard-milestone-course-count">{mileStoneData && mileStoneData[0]?.totalCompletedCourse}</div>
            )}
            <div>
              <MilestoneCompleteIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MilestoneCard;