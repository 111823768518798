import { useState, useEffect } from "react";
import { Filter } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";
import "./ReportFilter.css";

const ReportFilter = (props) => {
  const { data, filterState, setFilterState, onApplyFilters } = props;

  const [backupFilterState, setBackupFilterState] = useState(null);

  useEffect(() => {
    if (filterState.showFilterBase) {
      setBackupFilterState({ ...filterState });
    }
  }, [filterState.showFilterBase]);

  let uniqueTags = [];
  data?.orgTableData?.forEach((intern) => {
    const { techName } = intern;

    if (techName && Array.isArray(techName)) {
      techName.forEach((tagName) => {
        if (tagName !== null) {
          const existingTag = uniqueTags.find((tag) => tag.techName === tagName);
          if (!existingTag) {
            uniqueTags.push({ techId: uniqueTags.length + 1, techName: tagName });
          }
        }
      });
    }
  });

  const handleCross = (e) => {
    e.preventDefault();
    setFilterState((prevState) => ({
      ...backupFilterState,
      showFilterBase: false,
    }));
  };

  const handleCheckboxChange = (id, name, type) => {
    switch (type) {
      case "status":
        setFilterState((prevState) => ({
          ...prevState,
          selectedOption: {
            ...prevState.selectedOption,
            [id]: !prevState.selectedOption[id],
          },
        }));
        break;
      case "tech":
        setFilterState((prevState) => ({
          ...prevState,
          selectedTechName: prevState?.selectedTechName?.includes(name) ? prevState.selectedTechName.filter((techName) => techName !== name) : [...prevState.selectedTechName, name],
        }));
        break;
      case "level":
        setFilterState((prevState) => ({
          ...prevState,
          selectedLevelName: prevState?.selectedLevelName?.includes(name) ? prevState.selectedLevelName.filter((levelName) => levelName !== name) : [...prevState.selectedLevelName, name],
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectAll = (type) => {
    setFilterState((prevState) => {
      switch (type) {
        case "tech":
          return {
            ...prevState,
            selectedTechName: prevState.selectedTechName.length === uniqueTags.length ? [] : uniqueTags.map((tag) => tag.techName).filter(Boolean),
          };
        default:
          return prevState;
      }
    });
  };

  return (
    <div className="course-filter-wrapper">
      <div className="course-popup-wrapper">
        <div className="course-cross-btn mt-2">
          <div className="course-filter">
            <Filter /> Filter
          </div>
          <button type="button" className="btn-close me-2" onClick={(e) => handleCross(e)}></button>
        </div>
        <div className="course-filter-accordion-body">
          <div className="course-filter-accordion">
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                  <button
                    className="accordion-button course-filter-accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Tags
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="d-flex justify-content-between w-100">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="tagsselectAll"
                          checked={filterState.selectedTechName.length === uniqueTags.length}
                          onChange={() => handleSelectAll("tech")}
                        />
                        <label className="form-check-label fw-bold pointer" htmlFor="tagsselectAll">
                          Select All
                        </label>
                      </div>
                    </div>
                    <div className="instructor-filter-accordion">
                      {uniqueTags?.map((tag) => {
                        return (
                          <div className="form-check small checkbox gap-2 mb-2" key={tag?.techId}>
                            <input
                              className="form-check-input user-checkbox pointer"
                              type="checkbox"
                              id={tag?.techId + tag?.techName}
                              checked={filterState?.selectedTechName?.includes(tag?.techName)}
                              onChange={() => handleCheckboxChange(tag?.techId, tag?.techName, "tech")}
                            />
                            <label className="d-block pointer form-check-label" htmlFor={tag?.techId + tag?.techName}>
                              <span>{tag?.techName}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseSix"
                  >
                    Level
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="instructor-filter-accordion">
                      {data?.apiLevel?.map((level, index) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={index}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={level.id + level.name}
                            checked={filterState?.selectedLevelName?.includes(level?.name)}
                            onChange={() => handleCheckboxChange(level.id, level.name, "level")}
                          />
                          <label className="d-block pointer form-check-label" htmlFor={level.id + level.name}>
                            <span>{level.name === "Advance" ? "Advanced" : level.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    Deployment Status
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="instructor-filter-accordion">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="project-status"
                          checked={filterState.selectedOption.project}
                          onChange={() => handleCheckboxChange("project", "project", "status")}
                        />
                        <label className="d-block pointer form-check-label" htmlFor="project-status">
                          <span>Project</span>
                        </label>
                      </div>
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="bench-status"
                          checked={filterState.selectedOption.onBench}
                          onChange={() => handleCheckboxChange("onBench", "onBench", "status")}
                        />
                        <label className="d-block pointer form-check-label" htmlFor="bench-status">
                          <span>On Bench</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <button
              type="button"
              className="course-archive-cancel-btn me-1"
              onClick={(e) => {
                setFilterState((prevState) => ({
                  ...prevState,
                  showFilterBase: false,
                  selectedOption: {
                    project: false,
                    onBench: false,
                  },
                  selectedTechName: [],
                  selectedLevelName: [],
                }));
                onApplyFilters();
              }}
            >
              Clear All
            </button>
            <button
              type="button"
              className="course-archive-yes-btn ms-1"
              onClick={() => {
                onApplyFilters();
                setFilterState((prevState) => ({
                  ...prevState,
                  showFilterBase: false,
                  filtersApplied: true,
                }));
                setBackupFilterState(null);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
