import { CurrentFLag, MilestoneStar } from "../../UserImport";
import "../MilestoneAchievement/MilestoneAchievement.css";

const MilestoneAchievement = (props) => {
  const { certificateData, handleModalOpen } = props;
  const milestoneLevel = certificateData?.milestoneLevel;

  const allBadges = [
    { name: "Ivory", range: [1, 100] },
    { name: "Moonstone", range: [101, 300] },
    { name: "Graphite", range: [301, 600] },
    { name: "Marigold", range: [601, 1000] },
    { name: "Mandarin", range: [1001, 1500] },
    { name: "Aquamarine", range: [1501, 2100] },
    { name: "Turquoise", range: [2101, 2900] },
    { name: "Indigo", range: [2901, 3900] },
    { name: "Sapphire", range: [3901, 5400] },
    { name: "Infinity", range: [5401, 7800] },
  ];

  const milestoneIndex = allBadges.findIndex((badge) => badge.name === milestoneLevel);
  return (
    <>
      <div className="card milestone-badges-card d-flex p-2 h-100">
        <div>
          <p className="fw-bold ms-2">Milestone</p>
        </div>
        <div className="milestone-badges-scroll dp-scroll">
          {milestoneLevel !== undefined && milestoneLevel !== null && milestoneLevel !== "Ivory" && (
            <div className="d-flex flex-column ms-2 pointer">
              <p className="current-milestone-badge-heading">Achievement</p>
              {allBadges.slice(0, milestoneIndex).map((badge, index) => {
                if (badge.name !== milestoneLevel) {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-column justify-content-center align-items-center milestone-achievement-outer-div mb-3"
                      onClick={() => {
                        if (certificateData.length !== 0) {
                          handleModalOpen(badge.name, (certificateData[0]?.userFirstName || "") + " " + (certificateData[0]?.userLastName || ""));
                        }
                      }}
                    >
                      <div className={`milestone-inner-${badge.name?.toLowerCase()}-div`}>
                        <span className="d-flex justify-content-center align-items-center">{(index + 1).toString().padStart(2, "0")}</span>
                      </div>
                      <div className="milestone-badges-inner-div">
                        <p className="milestone-badge-name m-0">{badge.name} Belt</p>
                        <div className="d-flex justify-content-center align-items-center">
                          <MilestoneStar className="d-flex align-items-center justify-content-center milestone-badge-star-achievement" />
                          <p className="milestone-badge-range m-0 ms-1">
                            {badge?.range[0]} - {badge?.range[1]}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {milestoneLevel !== undefined && milestoneLevel !== null && (
            <div className="d-flex flex-column ms-2 mb-3 pointer">
              <div className="d-flex">
                <CurrentFLag />
                <p className="d-flex justify-content-center align-items-center ms-1 current-milestone-badge-heading">Current</p>
              </div>
              <div
                className="d-flex flex-column justify-content-center align-items-center milestone-outer-div"
                onClick={() => {
                  if (certificateData?.length !== 0) {
                    handleModalOpen(milestoneLevel, (certificateData[0]?.userFirstName || "") + " " + (certificateData[0]?.userLastName || ""));
                  }
                }}
              >
                <div className={`milestone-inner-${milestoneLevel?.toLowerCase()}-div`}>
                  <span className="d-flex justify-content-center align-items-center">{(milestoneIndex + 1).toString().padStart(2, "0")}</span>
                </div>
                <div className="milestone-badges-inner-div">
                  <p className="milestone-badge-name m-0">{milestoneLevel} Belt</p>
                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <MilestoneStar className="d-flex align-items-center justify-content-center milestone-badge-star-achievement" />
                    <p className="milestone-badge-range m-0 ms-1">
                      {allBadges[milestoneIndex]?.range[0]} - {allBadges[milestoneIndex]?.range[1]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {milestoneLevel !== "Infinity" && (
            <div className="d-flex flex-column ms-2 pe-none">
              <p className="current-milestone-badge-heading">Upcoming</p>
              {allBadges.slice(milestoneIndex + 1).map((badge, index) => {
                if (badge.name !== milestoneLevel) {
                  return (
                    <div key={index} className="d-flex flex-column justify-content-center align-items-center milestone-outer-div mb-3">
                      <div className={`milestone-inner-${badge.name?.toLowerCase()}-div`}>
                        <span className="d-flex justify-content-center align-items-center">{badge.name === "Infinity" ? <>&infin;</> : (index + milestoneIndex + 2).toString().padStart(2, "0")}</span>
                      </div>
                      <div className="milestone-badges-inner-div">
                        <p className="milestone-badge-name m-0">{badge.name} Belt</p>
                        <div className="d-flex justify-content-center align-items-center mt-1">
                          <MilestoneStar className="d-flex align-items-center justify-content-center milestone-badge-star-achievement" />
                          <p className="milestone-badge-range m-0 ms-1">
                            {badge?.range[0]} - {badge?.range[1]}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MilestoneAchievement;
