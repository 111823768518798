import { ArrowForwardIcon, TotalEnrolledUsers, TrendUpArrow,useNavigate,DownTrend,ConstantTrend} from "../../AdminImport";
import "./EnrolledUsers.css"

const EnrolledUsers = ({ enrolledUsers }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/admin/lms/total-enrolled-users");
  };
  
  return (
    <>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex pointer" onClick={handleClick}>
            <p
              className="d-flex mb-0 align-items-center enrolled-users-head total-courses-head" 
            >
              Total Enrolled Users
            </p>
            <span className="d-flex align-items-start mx-1">
              <ArrowForwardIcon />
            </span>
            </div>
            <span>
              <TotalEnrolledUsers />
            </span>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center meu-6">
          <div className="d-flex">
            <p className="mb-0 me-1 total-courses-sub-head">
              {enrolledUsers?.totalEnrolledUsers}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-evenly">
            {enrolledUsers?.statusFromLastWeek === "Increased" && (
              <TrendUpArrow />
            )}
            {enrolledUsers?.statusFromLastWeek === "Decreased" && (
              <DownTrend className="user-enrolled-downtrend"/>
            )}
            {enrolledUsers?.statusFromLastWeek === "No change" && (
              <ConstantTrend/>
            )}
              <p className={`mb-0 d-flex justify-content-center mx-1 ${enrolledUsers?.statusFromLastWeek === "Increased" ? "trend-up-text" : enrolledUsers?.statusFromLastWeek === "Decreased" ? "trend-down-text" : "trend-no-change-text"}`}>
              {Math.abs(enrolledUsers?.differenceCount)}
            </p>
            <p className="course-content-text mt-1 mb-0 d-flex justify-content-center">
              Last Week
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrolledUsers;
