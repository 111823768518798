import { axios,useState,useReducer,useRef,useEffect,useNavigate,Header,decryptData,SideNav,CustomModal,handleApiError,Clock, CourseHistoryCalendarIcon,DeleteWarning,Calendar, momentLocalizer,moment,UploadCsvv,Modal,UploadSessionModal} from "../AdminPortal/AdminImport";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./SessionCalendar.css";
 
const initialState = {
  selectedBatches: JSON.parse(sessionStorage.getItem("Batches")) || [],
  events: [],
  showModals: {
    showModal: false,
    showConfimModal: false,
  },
  eventId:0
};
 
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_BATCHES":
      return { ...state, selectedBatches: action.payload };
    case "SET_EVENTS":
      return { ...state, events: action.payload };
    case "SET_SHOW_MODALS":
      return { ...state, showModals: { ...state.showModals, ...action.payload } };
    case "SET_EVENT_ID":
      return { ...state, eventId: action.payload  };
      case "RESET_STATE":
        return initialState;
    default:
      return state;
  }
};
 
const SessionCalendar = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedBatches, events, showModals,eventId } = state;
  const [customView, setCustomView] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredEvent, setHoveredEvent] = useState(null);
 
  const tooltipRef = useRef(null);
  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
 
  useEffect(() => {
    fetchedCal();
  }, [selectedBatches]);
 
  function formatDates(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
 
 
  const fetchedCal = async () => {
    let apiUrl = `${process.env.REACT_APP_API_URL_DOTNET}/SessionCalendar/getSessionDetails`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    };
    try {
      const response = await axios.post(apiUrl, body, { headers });
 
      if (response.status === 200) {
        const fetchedEvents = response.data.result.map((eventData) => {
          // Assuming sessionStartDate and sessionStartTime are in ISO 8601 format
          const startDateTimeString = `${formatDates(eventData.sessionStartDate)}T${eventData.sessionStartTime}`;
          const endDateTimeString = `${formatDates(eventData.sessionEndDate)}T${eventData.sessionEndTime}`;
                   
          return {
            id: eventData.sessionId,
            title: eventData.sessionName,
            start: new Date(startDateTimeString),
            end: new Date(endDateTimeString),
            time: `${eventData.sessionStartTime} - ${eventData.sessionEndTime}`,
            for: eventData.batchDetails.join(", "),
            takenBy: eventData.mentorName,
          };
        });
     
        let allEvents = [];
 
        fetchedEvents.forEach((event) => {
          if (event.start && event.end) {
            const startDate = moment(event.start);
            const endDate = moment(event.end);
            while (startDate.isSameOrBefore(endDate, "day")) {
              const singleDayEvent = {
                ...event,
 
                start: startDate.toDate(),
                end: endDate.toDate(),
              };
              allEvents.push(singleDayEvent);
              startDate.add(1, "day");
            }
          }
        });
        dispatch({ type: "SET_EVENTS", payload: allEvents });
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
 
  const handleClose = () => {
    dispatch({ type: "SET_SHOW_MODALS", payload: { showModal: false, showConfimModal: false } });
  };
 
  const handleConfirm = async() => {
 
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/SessionCalendar/deleteSession?sessionId=${eventId}`;
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
      };
      try {
        const response = await axios.delete(url, { headers });
        if (response.data.success === 200) {
          fetchedCal();        
          dispatch({ type: "RESET_STATE" });        
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        const statusCode = error.response ? error.response : null;
        handleApiError(statusCode, navigate);
      }
        dispatch({ type: "SET_SHOW_MODALS", payload: { showModal: false, showConfimModal: false } });
  };
 
  const localizer = momentLocalizer(moment);
 
  const CustomToolbar = (toolbar) => {
    const { view, label, onView, onNavigate } = toolbar;
    setCustomView(view);
 
    return (
      <div className="custom-toolbar-calendar">
        <div className="rbc-btn-group">
          <button type="button" className="rbc-btn rbc-today-button" onClick={() => onNavigate("TODAY")}>
            Today
          </button>
          <button type="button" className="rbc-btn rbc-nav-btn border" onClick={() => onNavigate("PREV")}>
            &lt;
          </button>
          <button type="button" className="rbc-btn rbc-nav-btn border" onClick={() => onNavigate("NEXT")}>
            &gt;
          </button>
        </div>
        <div className="rbc-toolbar-label">{label}</div>
        <div className="rbc-btn-group">
          <button type="button" className={`rbc-btn border ${view === "month" ? "rbc-active" : ""}`} style={{borderRadius: "0.267rem 0 0 0.267rem"}} onClick={() => onView("month")}>
            Month
          </button>
          <button type="button" className={`rbc-btn border ${view === "week" ? "rbc-active" : ""}`} onClick={() => onView("week")}>
            Week
          </button>
          <button type="button" className={`rbc-btn border ${view === "day" ? "rbc-active" : ""}`} style={{borderRadius: "0px 0.267rem 0.267rem 0px"}} onClick={() => onView("day")}>
            Day
          </button>
        </div>
      </div>
    );
  };
 
  const handleEventMouseEnter = (event, e) => {
    setHoveredEvent(event);
    setShowTooltip(true);
    positionTooltip(e);
  };
 
  const handleReadMore = (event) => {
    handleEventMouseLeave();
    setHoveredEvent(event);
    dispatch({ type: "SET_SHOW_MODALS", payload: { showModal: true } });
  };
 
  const handleEventMouseLeave = () => {
    setHoveredEvent(null);
    setShowTooltip(false);
  };
 
  const positionTooltip = (e) => {
    if (tooltipRef.current) {
      const tooltipWidth = tooltipRef.current.offsetWidth;
      const bodyRect = document.body.getBoundingClientRect();
      const eventRect = e.currentTarget.getBoundingClientRect();
      let top = eventRect.top - bodyRect.top + eventRect.height + 80;
      if (customView === "month") {
        top = eventRect.top - bodyRect.top + eventRect.height - 50;
      } else if (customView === "week") {
        top = eventRect.top - bodyRect.top + eventRect.height + 100;
      } else if (customView === "day") {
        top = eventRect.top - bodyRect.top + eventRect.height + 100;
      }
      let left = eventRect.left - bodyRect.left + eventRect.width / 2 - tooltipWidth / 2;
      if (left + tooltipWidth > window.innerWidth) {
        left = window.innerWidth - tooltipWidth - 10;
      }
      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left + 5}px`;
      tooltipRef.current.style.zIndex = 9999;
    }
  };
 
  return (
    <div>
      <div style={{ marginBottom: "5rem" }}>
        <Header selectedBatches={selectedBatches} setSelectedBatches={(batches) => dispatch({ type: "SET_SELECTED_BATCHES", payload: batches })} />
        <SideNav />
      </div>
      <div className="session-parent-wrapper">
        <div className="session-header-wrapper">
          <div className="calendar-heading-style">
            <span>Session Calendar</span>
          </div>
          {parsedObject?.decoded.RoleName === process.env.REACT_APP_USER_DES_ADMIN && (
            <div>
              <button className="upload-session-calendar" data-bs-toggle="modal" data-bs-target="#UploadSessionCsvModal">
                <UploadCsvv />
                Upload Session Calendar CSV
              </button>
              <UploadSessionModal fetchedCal={fetchedCal} />
            </div>
          )}
        </div>
        <div className="calendar-wrapper">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            titleAccessor="title"
            eventPropGetter={(event) => ({
              className:"event-prop-getter"
            })}
            components={{
              toolbar: CustomToolbar,
              dayWrapper: ({ children }) => <div>{children}</div>,
              eventWrapper: ({ event, children }) => {
                return (
                  <div
                    onMouseEnter={(e) => handleEventMouseEnter(event, e)}
                    onMouseLeave={handleEventMouseLeave}
                    onClick={() => {
                      handleReadMore(event);
                    }}
                  >
                    {children}
                  </div>
                );
              },
            }}
            showMultiDayTimes={false}
          />
          {hoveredEvent && showTooltip && (
            <div ref={tooltipRef} className="event-tooltip">
              <ul className="event-details">
                <li>{hoveredEvent.time}</li>
                <li>{hoveredEvent.for.split(",").length > 1 ? `${hoveredEvent.for.split(",")[0]} ...+${hoveredEvent.for.split(",").length - 1}` : hoveredEvent.for}</li>
                <li>{hoveredEvent.takenBy}</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <Modal
        size="sm"
        dialogClassName="modal-90w"
        centered
        show={showModals.showModal}
        onHide={() =>
          dispatch({
            type: "SET_SHOW_MODALS",
            payload: { showModal: false },
          })
        }
      >
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="session-detail-modal-header">{hoveredEvent?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p className="d-flex align-items-center">
              <span className="calander-clock-icon">
                <CourseHistoryCalendarIcon />
              </span>
              <span className="fw-bold ms-1">
                {(hoveredEvent?.start.getMonth() + 1).toString().padStart(2, "0")}-{hoveredEvent?.start.getDate().toString().padStart(2, "0")}-{hoveredEvent?.start.getFullYear()}
              </span>
            </p>
            <p className="d-flex align-items-center">
              <span className="calander-clock-icon">
                <Clock />
              </span>
              <span className="fw-bold ms-1"> {hoveredEvent?.time}</span>
            </p>
          </div>
          <p>
            <span className="opacity-75">Mentor : </span>
            <br />
            <span className="fw-bold">{hoveredEvent?.takenBy}</span>
          </p>
          <p>
            <span className="opacity-75">Attended By : </span>
            <br /> <span className="fw-bold">{hoveredEvent?.for} </span>
          </p>
          {parsedObject["decoded"].RoleName === "Admin" && (
            <p className="mb-0">
              <button
                type="button"
                class="btn btn-danger w-100"
                onClick={() => {
                  dispatch({
                    type: "SET_SHOW_MODALS",
                    payload: { showModal: false, showConfimModal: true },
                  });
                  dispatch({
                    type: "SET_EVENT_ID",
                    payload: hoveredEvent.id,
                  });
                }}
              >
                Cancel Event
              </button>
            </p>
          )}
        </Modal.Body>
      </Modal>
      {showModals.showConfimModal && (
        <CustomModal
          modalProps={{
            icon: <DeleteWarning />,
            show: showModals.showConfimModal,
            type: "delete",
            title: "Are you sure about cancelling this event",
            message: "This action cannot be undone, but you can always schedule it again!",
            onCancel: handleClose,
            onConfirm: handleConfirm,
            cancelText: "No",
            confirmText: "Yes, Cancel",
          }}
        />
      )}
    </div>
  );
};
 
export default SessionCalendar;