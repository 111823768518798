import React, { useContext } from "react";
import { ReactComponent as Cong } from "../../../../Assets/Cong.svg";
import "./Congo.css";
import { UserContext } from "../../../../Context/Context";
import CryptoJS from "crypto-js";
import html2canvas from "html2canvas";

const Congo = (props) => {
  const {skillAddedData} = props;
  const { score, setScore, examName, examLevel, dateOfTest, timeOfTest } =
    useContext(UserContext);
  const secretkeyUser = process.env.REACT_APP_USER_KEY;
  var parsedObject;
  const data = localStorage.getItem("userData");
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, secretkeyUser);
    const decryptedJsonString = bytes.toString(CryptoJS.enc.Utf8);
    parsedObject = JSON.parse(decryptedJsonString);
  } else {
console.error("No data found.");  }
  var userName = parsedObject.decoded.FirstName + " " + parsedObject.decoded.LastName;
  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const onClickHandler = () => {
    setScore(-1);
    exitFullscreen();
    skillAddedData();
  };

  const handleDownload = () => {
    const congoComponent = document.getElementById("congoComponent");
    congoComponent.classList.add("screenshot-capture");

    html2canvas(congoComponent, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${examName}_${examLevel}.jpeg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      congoComponent.classList.remove("screenshot-capture");
    });
    exitFullscreen();
    skillAddedData();
  };

  return (
    <div>
      <div className="parent-congo-wrapper">
        <div id="congoComponent" className="child-congo-wrapper">
          <div className="row crossBtn">
            <button
              type="button"
              className="btn-close"
              onClick={onClickHandler}
            ></button>
          </div>
          <div className="row cStar">
            <Cong />
          </div>
          <div className="row c1">
            <p>Congratulations !</p>
          </div>
          <p className="d-flex justify-content-center mb-0 congo-username">
            {userName}
          </p>
          <div className="row c2">
            <p>Your Score</p>
          </div>
          <div className="row c3">
            <p>
              {score}
              <span className="text-dark">/100</span>
            </p>
          </div>
          <div className="row c4">
            <p>
              You’ve successfully added{" "}
              <b>
                {examName}: {examLevel} Level Star
              </b>{" "}
              in your skillset at{" "}
              <b>
                {dateOfTest} {timeOfTest}.
              </b>
            </p>
          </div>
          <div className="row Ccontinue">
            <button
              type="button"
              className="continueBtn"
              onClick={handleDownload}
              id="congodownloadButton"
            >
              Download
            </button>
            <button
              type="button"
              className="congo-view-result-btn"
              onClick={onClickHandler}
              id="congocloseButton"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congo;
