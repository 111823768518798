import React, { useContext, useEffect } from "react";
import "./skillmanagement.css";
import { useState, createContext } from "react";
import TakeTest from "./TakeTest/TakeTest";
import SkillsAdded from "./SkillsAdded/SkillsAdded";
import Header from "../../Header/Header";
import { UserContext } from "../../../Context/Context";
import Congo from "../SkillManagement/Modals/Congo";
import Sorry from "../SkillManagement/Modals/Sorry";
import BreadCrumbs from "../../_utilities/BreadCrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { SideNav, decryptData } from "../UserImport";

export const TestContext = createContext();
const SkillManagement = () => {
  const { score } = useContext(UserContext);
  const [resultInfo, setResultInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tests, setTests] = useState([]);
  const navigate = useNavigate();
  const handleDownloadFromChild = (downloadFunction) => {
    downloadFunction();
  }

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const fetchData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL_DOTNET + `/Skill/getSkillByUserId?examResult=3`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });
      const data = await response.json();

      const groupedData = data.result.userSkills.reduce((acc, item) => {
        const existingItem = acc.find((group) => group.techName === item.techName);

        if (existingItem) {
          existingItem.noOfAttempt.push(item.noOfAttempt);
          existingItem.examScorePercentage.push(item.examScorePercentage);
          existingItem.examLevel.push(item.examLevel);
          existingItem.examTakenDate.push(item.examTakenDate);
          existingItem.examTakenTime.push(item.examTakenTime);
          existingItem.levelName.push(item.levelName);
        } else {
          acc.push({
            userId: item.userId,
            techName: item.techName,
            techImageLink: item.techImageLink,
            examName: item.examName,
            noOfAttempt: [item.noOfAttempt],
            examScorePercentage: [item.examScorePercentage],
            examLevel: [item.examLevel],
            examTakenDate: [item.examTakenDate],
            examTakenTime: [item.examTakenTime],
            levelName: [item.levelName],
          });
        }

        return acc;
      }, []);

      groupedData.forEach(item => {
        const indices = [];
        ["Beginner", "Intermediate", "Advance", "Project"].forEach(level => {
          const index = item.levelName.findIndex(name => name === level);
          if (index !== -1) indices.push(index);
        });
        ["noOfAttempt", "examScorePercentage", "examLevel", "examTakenDate", "examTakenTime", "levelName"].forEach(prop => {
          item[prop] = indices.map(i => item[prop][i]);
        });
      });

      setResultInfo(groupedData);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/error/statusCode=401");
      }
      if (error.response.status === 400) {
        navigate("/error/statusCode=400");
      }
      if (error.response.status === 500) {
        navigate("/error/statusCode=500");
      }
      if (error.response.status === 404) {
        navigate("/error/statusCode=404");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TestContext.Provider
        value={{
          resultInfo,
          setResultInfo,
          tests,
          setTests,
          isLoading
        }}
      >
        <div style={{ marginBottom: "3rem" }}>
          <Header />
          <SideNav />
        </div>
        <>
          {score == -1 ? "" : score >= 80 ? <Congo skillAddedData={fetchData} /> : <Sorry skillAddedData={fetchData} />}
          <div className="mainDiv">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12">
                  <div className="SM-breadcrumbs">
                    <BreadCrumbs dashboardLink={"/dashboard"} />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className=" col-md-9 p-0 " style={{ width: "58.125rem" }}>
                  <TakeTest functionFromChild={handleDownloadFromChild} />
                </div>
                <div className=" col-md-3 ps-0 skill-added-card">
                  <SkillsAdded />
                </div>
              </div>
            </div>
          </div>
        </>
      </TestContext.Provider>
    </>
  );
};
export default SkillManagement;
