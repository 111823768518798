import "../UploadCourses/PublishPopup.css";
import { Draft, InfinitySpin, ReactModal } from "../UploadCourses/UploadCoursesImport";


const DraftComp = (props) => {
    const { confirmArchive, isOpen, setIsOpen } = props;
    const handleCross = (e) => {
        setIsOpen(() => ({
            draft: false,
            isLoading: false
        }));
    };
    return (
        <>
            <ReactModal show={isOpen.draft} onHide={(e) => handleCross(e)} backdrop="static" keyboard={false} centered>
                <ReactModal.Header className="border-bottom-0" closeButton></ReactModal.Header>
                <ReactModal.Body className="d-flex justify-content-center flex-column align-items-center pt-1">
                    <Draft className="tt-draft" />
                    <ReactModal.Title id="example-modal-sizes-title-md" className=" fw-bold py-3">
                        Draft Tech Talk Creation
                    </ReactModal.Title>
                    <div className="text-center">
                        Are you sure you want to save as Draft ? <br></br>
                        Tech Talk will be live only  after Publish.
                    </div>
                </ReactModal.Body>
                <ReactModal.Footer className="d-flex justify-content-center border-top-0">
                    {isOpen.isLoading ? (
                        <div className="row d-flex justify-content-center draft-loader">
                            <InfinitySpin className="d-flex align-items-center justify-content-center loader-svg " color="#ffc107" />
                        </div>
                    ) : (
                        <div className="row d-flex justify-content-center mb-3">
                            <button type="button" className="pp-no-btn me-1" onClick={(e) => handleCross(e)}>
                                Cancel
                            </button>
                            <button type="button" className="publish-yes-btn ms-1" onClick={() => confirmArchive()}>
                                Save
                            </button>
                        </div>
                    )}
                </ReactModal.Footer>
            </ReactModal>
        </>
    );
};

export default DraftComp;
