import "../Dashboard/DashboardA.css";
import { ManageSkillSet, Status, PieChartTopTech, AssociateConsultant, Insights, Header, AssignReviewer, TodaySession, SideNav, useState, useNavigate, ArrowForwardIcon, UploadSessionModal } from '../AdminImport';


const DashboardA = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  const [functionFromTodaySession, setFunctionFromTodaySession] = useState(null);
  const navigate = useNavigate();

  const receiveFunctionFromTodaySession = (data) => {
    setFunctionFromTodaySession(() => data);
  };

  return (
    <>
      <div style={{ marginBottom: "2.5rem" }}>
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div>
      <div className="responsivenessA">
        <div className="row ms-2" style={{ marginTop: "4rem" }}>
          <div className="col-xs-12 col-md-8 component-div">
            <div
              className="d-flex justify-content-center row div-staus-upload"
            >
              <div className="col-md-6 col-xs-12 outer-row-info">
                <Status prop={{ batch: selectedBatches }} />
                <div id="Session-For-Mobile">
                  <TodaySession {...{ selectedBatches }} />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/session-calendar")
                  }}
                  className="d-xs-flex justify-content-between btn btn-primary session-button"
                  id="mobile-view-btn"
                >
                  Session Calender
                  <div >
                    <ArrowForwardIcon />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12" id="AssignReviewer">
                <AssignReviewer selectedBatches={selectedBatches} />
              </div>
            </div>
            <div id="ManageSkillSet">
              <ManageSkillSet selectedBatches={selectedBatches} />
            </div>

            <div className="row manage-total-test d-flex">
              <div className="col-md-6 col-xs-12 p-0" >
                <PieChartTopTech selectedBatches={selectedBatches} />
              </div>
              <div id="AssociateConsultant" className="col-md-6 col-xs-12 associate-div p-0" >
                <AssociateConsultant selectedBatches={selectedBatches} />
              </div>
            </div>
          </div>
          <div
            id="Insights"
            className="col-xs-0 col-md-4 "
          >
            <TodaySession
              selectedBatches={selectedBatches}
              sendFunctionToDashboardA={receiveFunctionFromTodaySession}
            />
            <Insights
              selectedBatches={selectedBatches}
            />
          </div>
        </div>
      </div>
      <UploadSessionModal fetchedCal={functionFromTodaySession} />
    </>
  );
};

export default DashboardA;
