import "./AssignTask.css";
import { useState, useEffect, useNavigate, decryptData, EmptyTask, Delete, Edit, Add, Clock, axios, AssignTaskSkeleton, AddNewTask, DeleteTask, EditTaskModal, handleApiError } from "../../AdminImport";
import SuccessfullTask from "../SuccessfullTask";

function formatDate(dateString) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

const AssignTask = (props) => {
  const { selectedBatches } = props;

  const [tasks, setTasks] = useState({
    task: [],
    isLoading: true,
    isOpen: false
  });
  const [taskToEdit, setTaskToEdit] = useState({
    task: {
      taskName: "",
      taskDescription: "",
      startDate: "",
      endDate: "",
    },
    version: 0,
  });
  const [taskToDelete, setTaskToDelete] = useState({
    idToDelete: 0,
    isModalOpen: false,
    showDeleteModal: false,
  });

  const navigate = useNavigate();
  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    let url = `${process.env.REACT_APP_API_URL_DOTNET}/Tasks/mentorAssigned`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    let body = {
      batchId: selectedBatches,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        setTasks((prevState) => ({
          ...prevState,
          task: response.data.result,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedBatches, taskToEdit.version]);

  const handleEditTask = (task) => {
    setTaskToEdit({
      ...taskToEdit,
      task: task,
    });
  };

  const deleteTask = (e, id, index) => {
    e.preventDefault();
    setTaskToDelete({
      idToDelete: id,
      isModalOpen: true,
      showDeleteModal: true
    });
  };
  return (
    <>
      {taskToDelete.isModalOpen && <DeleteTask id={taskToDelete.idToDelete} version={setTaskToEdit} setDeleteTask={setTaskToDelete} />}
      {tasks.isOpen && <SuccessfullTask setTasks={setTasks} />}
      <div className="assign-task-container">
        <p>Assigned Task</p>
        <button type="button" className="add-task-button mb-2" data-bs-toggle="modal" data-bs-target="#addTaskModal">
          <Add />
          Add New Task
        </button>
      </div>
      <div className="task-main-card">
        {tasks.isLoading ? (
          <>
            <AssignTaskSkeleton />
            <AssignTaskSkeleton />
            <AssignTaskSkeleton />
          </>
        ) : tasks.task && tasks.task.length === 0 ? (
          <div className="card empty-task-state d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <EmptyTask />
              <p className="fw-bold mt-3 mb-1 empty-task-text">No tasks have been assigned to the users yet</p>
              <p className="task-link" data-bs-toggle="modal" data-bs-target="#addTaskModal">
                Add New Task
              </p>
            </div>
          </div>
        ) : (
          tasks.task?.map((task, index) => (
            <div className="card task-card" key={task.taskId}>
              <div className="card-title mb-0">
                <div className="d-flex">
                  <h5 className="task-name mb-0">{task.taskName}</h5>
                  <div className="dots">
                    <Edit
                      className="mx-3 pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#editTaskModal"
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditTask(task);
                      }}
                    />
                    <Delete
                      className="pointer"
                      taskId={task.taskId}
                      onClick={(e) => {
                        deleteTask(e, task.id, index);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex mt-2 task-timeStamp">
                  <Clock />
                  {formatDate(task.startDate)} - {formatDate(task.endDate)}
                </div>
              </div>
              <div className="card-body p-0 mt-2">
                <h6>Description</h6>
                <p className="card-text">{task.taskDescription}</p>
                <h6>Technology Tag</h6>
                {task.techNames?.map((skill, skillIndex) => (
                  <span key={skillIndex} className="badge tech-badge">
                    {skill.toUpperCase()}{" "}
                  </span>
                ))}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  <div>
                    <h6>Assigned By</h6>
                    <div className="mentor-wrapper">
                      <div className="image-wrapper1">
                        <div className="assignedBy-img">
                          {`${task.mentorFirstName} ${task.mentorLastName === null ? "" : task.mentorLastName}`
                            .split(" ")
                            .map((name) => name.charAt(0).toUpperCase())
                            .join("")}
                        </div>
                      </div>
                      <div className="text-wrapper1">
                        <p className="m-0">
                          <b>
                            {task.mentorFirstName} {task.mentorLastName}
                          </b>
                        </p>
                        <p className="m-0 pos-wrapper">{task.designation}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6>Assigned To</h6>

                    <div className="assigned-to ml-0">
                      {task?.name?.length > 4 ? (
                        <>
                          {task.name.slice(0, 4).map((name, index) => {
                            const initials = name
                              .split(" ")
                              .map((word) => word[0])
                              .join("")
                              .toUpperCase();
                            return (
                              <div className="project-idea-members" key={index}>
                                <p className="name-of-members" title={name}>
                                  {initials}
                                </p>
                              </div>
                            );
                          })}
                          <div className="count-of-members">
                            <p className="name-of-members" title={task.name.slice(4).join(", ")}>
                              + {task?.name?.length - 4}
                            </p>
                          </div>
                        </>
                      ) : (
                        task?.name?.map((name, index) => {
                          const initials = name
                            .split(" ")
                            .map((word) => word[0])
                            .join("")
                            .toUpperCase();
                          return (
                            <div className="project-idea-members" key={index}>
                              <p className="name-of-members" title={name}>
                                {initials}
                              </p>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <EditTaskModal taskToEdit={taskToEdit.task} setTaskToEdit={setTaskToEdit} selectedBatches={selectedBatches}/>
      <AddNewTask setTaskToEdit={setTaskToEdit} setTasks={setTasks} selectedBatches={selectedBatches}/>
    </>
  );
};

export default AssignTask;
