import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CertificateListSkeleton = () => {
  return (
    <div className="d-flex align-items-center fw-600 milestone-count-card">
      <div className="milestone-image">
        <Skeleton width={36} height={36} circle={true} />
      </div>
      <div className="d-flex milestone-name-count">
        <p className="m-name mb-0 pointer "><Skeleton height={34}/></p>
        <p className="m-count mb-0 pointer"><Skeleton width={34} height={34}/></p>
      </div>
    </div>
  );
};

export default CertificateListSkeleton;
