import { Filter } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";
import "./CourseFilter.css";

const CourseFilter = (props) => {
  const { data, filterState, setFilterState, onApplyFilters } = props;

  let uniqueInstructors = [];
  let uniqueTags = [];
  let uniqueCategories = [];

  data?.orgTableData?.forEach((course) => {
    const instructors = course?.instructorList?.map((instructor) => ({
      id: instructor.instructorId,
      firstName: instructor.firstName,
      lastName: instructor.lastName,
    }));

    instructors?.forEach((instructor) => {
      const existingInstructor = uniqueInstructors?.find((i) => i.id === instructor.id);
      if (!existingInstructor) {
        uniqueInstructors.push(instructor);
      }
    });

    const tags = course?.tags?.map((tag) => ({
      techId: tag.id,
      techName: tag.techName,
    }));

    tags?.forEach((tag) => {
      const existingTag = uniqueTags?.find((i) => i.techId === tag.techId);
      if (!existingTag) {
        uniqueTags.push(tag);
      }
    });

    const { categoryId, categoryName, subCategoryId, subCategoryName } = course?.categoryInfo?.[0] || {};
    if (categoryName && subCategoryName) {
      const existingCategoryIndex = uniqueCategories.findIndex((category) => category.categoryId === categoryId);

      if (existingCategoryIndex === -1) {
        uniqueCategories.push({
          categoryId,
          categoryName,
          subCategories: [
            {
              subCategoryId,
              subCategoryName,
            },
          ],
        });
      } else {
        const existingSubcategoryIndex = uniqueCategories[existingCategoryIndex].subCategories.findIndex((subcategory) => subcategory.subCategoryId === subCategoryId);

        if (existingSubcategoryIndex === -1) {
          uniqueCategories[existingCategoryIndex].subCategories.push({
            subCategoryId,
            subCategoryName,
          });
        }
      }
    }
  });

  const handleCross = (e) => {
    e.preventDefault();
    filterState.filtersApplied
      ? setFilterState((prevState) => ({
          ...prevState,
          showFilterBase: false,
        }))
      : setFilterState((prevState) => ({
          ...prevState,
          showFilterBase: false,
          selectedInsId: [],
          selectedSubCategoryId: [],
          selectedGradeId: [],
          selectedStatus: {
            archive: false,
            draft: false,
            published: false,
          },
          selectedTechId: [],
          selectedLevelId: [],
        }));
  };

  const handleCheckboxChange = (id, type) => {
    switch (type) {
      case "instructor":
        setFilterState((prevState) => ({
          ...prevState,
          selectedInsId: prevState.selectedInsId.includes(id) ? prevState.selectedInsId.filter((instructorId) => instructorId !== id) : [...prevState.selectedInsId, id],
        }));
        break;
      case "subcategory":
        setFilterState((prevState) => ({
          ...prevState,
          selectedSubCategoryId: prevState.selectedSubCategoryId.includes(id)
            ? prevState.selectedSubCategoryId.filter((subCategoryId) => subCategoryId !== id)
            : [...prevState.selectedSubCategoryId, id],
        }));
        break;
      case "grade":
        setFilterState((prevState) => ({
          ...prevState,
          selectedGradeId: prevState.selectedGradeId.includes(id) ? prevState.selectedGradeId.filter((gradeId) => gradeId !== id) : [...prevState.selectedGradeId, id],
        }));
        break;
      case "status":
        setFilterState((prevState) => ({
          ...prevState,
          selectedStatus: {
            ...prevState.selectedStatus,
            [id]: !prevState.selectedStatus[id],
          },
        }));
        break;
      case "tech":
        setFilterState((prevState) => ({
          ...prevState,
          selectedTechId: prevState.selectedTechId.includes(id) ? prevState.selectedTechId.filter((techId) => techId !== id) : [...prevState.selectedTechId, id],
        }));
        break;
      case "level":
        setFilterState((prevState) => ({
          ...prevState,
          selectedLevelId: prevState.selectedLevelId.includes(id) ? prevState.selectedLevelId.filter((levelId) => levelId !== id) : [...prevState.selectedLevelId, id],
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectAll = (type, id) => {
    setFilterState((prevState) => {
      switch (type) {
        case "instructor":
          return {
            ...prevState,
            selectedInsId: prevState.selectedInsId.length === uniqueInstructors.length ? [] : uniqueInstructors.map((instructor) => instructor.id),
          };
        case "subcategory":
          const updatedSelectedSubCategoryId = new Set(prevState.selectedSubCategoryId); // Using Set to prevent duplicates
          const category = uniqueCategories.find((category) => category.categoryId === id);

          if (category) {
            const allSubcategoriesSelected = category.subCategories.every((subcategory) => updatedSelectedSubCategoryId.has(subcategory.subCategoryId));

            if (allSubcategoriesSelected) {
              category.subCategories.forEach((subcategory) => {
                updatedSelectedSubCategoryId.delete(subcategory.subCategoryId);
              });
            } else {
              category.subCategories.forEach((subcategory) => {
                updatedSelectedSubCategoryId.add(subcategory.subCategoryId);
              });
            }
          }

          return {
            ...prevState,
            selectedSubCategoryId: [...updatedSelectedSubCategoryId], // Converting Set back to array
          };
        case "tech":
          return {
            ...prevState,
            selectedTechId: prevState.selectedTechId.length === uniqueTags.length ? [] : uniqueTags.map((tag) => tag.techId),
          };
        default:
          return prevState;
      }
    });
  };

  return (
    <div className="course-filter-wrapper">
      <div className="course-popup-wrapper">
        <div className="course-cross-btn mt-2">
          <div className="course-filter">
            <Filter /> Filter
          </div>
          <button type="button" className="btn-close me-2" onClick={(e) => handleCross(e)}></button>
        </div>
        <div className="course-filter-accordion-body">
          <div className="course-filter-accordion">
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="instructorName">
                  <button
                    className="accordion-button course-filter-accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Instructor Name
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="instructorName">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="d-flex justify-content-between w-100">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="instructorNameselectAll"
                          checked={filterState?.selectedInsId?.length === uniqueInstructors.length}
                          onChange={() => handleSelectAll("instructor")}
                        />
                        <label className="form-check-label fw-bold pointer" htmlFor="instructorNameselectAll">
                          Select All
                        </label>
                      </div>
                    </div>
                    <div className="instructor-filter-accordion"
                    >
                      {uniqueInstructors?.map((instructor) => {
                        return (
                          <div className="form-check small checkbox gap-2 mb-2" key={instructor.id}>
                            <input
                              className="form-check-input user-checkbox pointer"
                              type="checkbox"
                              id={instructor.id + instructor.firstName}
                              onChange={() => handleCheckboxChange(instructor.id, "instructor")} 
                              checked={filterState?.selectedInsId?.includes(instructor.id)} 
                            />
                            <label
                              className="d-block pointer form-check-label"
                              htmlFor={instructor.id + instructor.firstName}
                            >
                              <span>
                                {instructor?.firstName || ""} {instructor?.lastName || ""}
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingCategory">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseCategory"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseCategory"
                  >
                    Category
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseCategory" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingCategory">
                  <div className="accordion-body px-2 pb-2 pt-0">
                    {uniqueCategories?.map((category, index) => (
                      <div className="accordion pb-2" id="accordionCourseFilter" key={index}>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`accordionCourseFilter${index}`}>
                            <button
                              className="accordion-button course-filter-accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapseSubTwo${index}`}
                              aria-expanded="false"
                              aria-controls={`panelsStayOpen-collapseSubTwo${index}`}
                            >
                              {category.categoryName}
                            </button>
                          </h2>
                          <div id={`panelsStayOpen-collapseSubTwo${index}`} className="accordion-collapse collapse" aria-labelledby={`accordionCourseFilter${index}`}>
                            <div className="accordion-body pt-0 pb-2">
                              <div className="d-flex justify-content-between w-100">
                                <div className="form-check small checkbox gap-2 mb-2">
                                  <input
                                    className="form-check-input user-checkbox pointer"
                                    type="checkbox"
                                    id={index}
                                    checked={category.subCategories.every((subCat) => filterState.selectedSubCategoryId.includes(subCat.subCategoryId))}
                                    onChange={() => handleSelectAll("subcategory", category.categoryId)}
                                  />
                                  <label className="form-check-label fw-bold pointer" htmlFor={index}>
                                    Select All
                                  </label>
                                </div>
                              </div>
                              <div
                                className="instructor-filter-accordion"
                              >
                                {category.subCategories?.map((subCategory) => (
                                  <div className="form-check small checkbox gap-2 mb-2">
                                    <input
                                      className="form-check-input user-checkbox pointer"
                                      type="checkbox"
                                      id={`${subCategory.subCategoryId}${subCategory.subCategoryName}`}
                                      onChange={() => handleCheckboxChange(subCategory.subCategoryId, "subcategory")}
                                      checked={filterState.selectedSubCategoryId.includes(subCategory.subCategoryId)}
                                    />
                                    <label
                                      className="d-block pointer form-check-label"
                                      htmlFor={`${subCategory.subCategoryId}${subCategory.subCategoryName}`}
                                    >
                                      <span>{subCategory.subCategoryName}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    Grade Type
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                  <div className="accordion-body pt-0 pb-2">
                    <div
                      className="instructor-filter-accordion"
                    >
                      {data?.apiGrade?.map((grade, index) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={index}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={grade.id + grade.name}
                            onChange={() => handleCheckboxChange(grade.id, "grade")}
                            checked={filterState.selectedGradeId.includes(grade.id)}
                          />
                          <label
                            className="d-block pointer form-check-label"
                            htmlFor={grade.id + grade.name}
                          >
                            <span>{grade.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    Status
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="instructor-filter-accordion"
                    >
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="archive-status"
                          checked={filterState?.selectedStatus?.archive}
                          onChange={() => handleCheckboxChange("archive", "status")}
                        />
                        <label
                          className="d-block pointer form-check-label"
                          htmlFor="archive-status"
                        >
                          <span>Archive</span>
                        </label>
                      </div>
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="draft-status"
                          checked={filterState?.selectedStatus?.draft}
                          onChange={() => handleCheckboxChange("draft", "status")}
                        />
                        <label
                          className="d-block pointer form-check-label"
                          htmlFor="draft-status"
                        >
                          <span>Draft</span>
                        </label>
                      </div>
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="published-status"
                          checked={filterState?.selectedStatus?.published}
                          onChange={() => handleCheckboxChange("published", "status")}
                        />
                        <label
                          className="d-block pointer form-check-label"
                          htmlFor="published-status"
                        >
                          <span>Published</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    Tags
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                  <div className="accordion-body pt-0 pb-2">
                    <div className="d-flex justify-content-between w-100">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <input
                          className="form-check-input user-checkbox pointer"
                          type="checkbox"
                          id="tagsselectAll"
                          checked={filterState?.selectedTechId?.length === uniqueTags?.length}
                          onChange={() => handleSelectAll("tech")}
                        />
                        <label className="form-check-label fw-bold pointer" htmlFor="tagsselectAll">
                          Select All
                        </label>
                      </div>
                    </div>
                    <div
                      className="instructor-filter-accordion"
                    >
                      {uniqueTags?.map((tag) => {
                        return (
                          <div className="form-check small checkbox gap-2 mb-2" key={tag.techId}>
                            <input
                              className="form-check-input user-checkbox pointer"
                              type="checkbox"
                              id={tag.techId + tag.techName}
                              checked={filterState?.selectedTechId?.includes(tag.techId)}
                              onChange={() => handleCheckboxChange(tag.techId, "tech")}
                            />
                            <label
                              className="d-block pointer form-check-label"
                              htmlFor={tag.techId + tag.techName}
                            >
                              <span>{tag.techName}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion pb-2 mb-1" id="accordionCourseFilter">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                  <button
                    className="accordion-button course-filter-accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseSix"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseSix"
                  >
                    Level
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                  <div className="accordion-body pt-0 pb-2">
                    <div
                      className="instructor-filter-accordion"
                    >
                      {data?.apiLevel?.map((level, index) => (
                        <div className="form-check small checkbox gap-2 mb-2" key={index}>
                          <input
                            className="form-check-input user-checkbox pointer"
                            type="checkbox"
                            id={level.id + level.name}
                            onChange={() => handleCheckboxChange(level.id, "level")}
                            checked={filterState?.selectedLevelId?.includes(level.id)}
                          />
                          <label
                            className="d-block pointer form-check-label"
                            htmlFor={level.id + level.name}
                          >
                            <span>{level.name==="Advance"? "Advanced": level.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <button
              type="button"
              className="course-archive-cancel-btn me-1"
              onClick={(e) => {
                setFilterState((prevState) => ({
                  ...prevState,
                  showFilterBase: false,
                  selectedInsId: [],
                  selectedSubCategoryId: [],
                  selectedGradeId: [],
                  selectedStatus: {
                    archive: false,
                    draft: false,
                    published: false,
                  },
                  selectedTechId: [],
                  selectedLevelId: [],
                }));
                onApplyFilters();
              }}
            >
              Clear All
            </button>
            <button
              type="button"
              className="course-archive-yes-btn ms-1"
              onClick={() => {
                onApplyFilters();
                setFilterState((prevState) => ({
                  ...prevState,
                  showFilterBase: false,
                  filtersApplied: true,
                }));
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseFilter;
