import React, { useEffect, useState } from "react";
import CircleRadioButton from "./CircleRadioButton";
const InterPerformanceReviewSection = (props) => {
  const { onDataSent, name, rating, isFirst, heading, text, comment, setComment, submitted } = props

  const handleRadioButtonClick = (value) => {
    setComment((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    onDataSent(comment, rating);
  }, [comment, name, setComment]);

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setComment((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const allowedCharactersRegExp = /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{100,750}$/;
  function isValidString(inputString) {
    return allowedCharactersRegExp.test(inputString);
  }

  return (
    <div
      className="row px-0 mx-0 single-review-param"
      style={{ marginTop: isFirst === "true" ? "" : "24px" }}
    >
      <div className="row productivity-parameter mx-0">
        <h6 className="productivity-parameter-text mb-0 ps-0">{heading}</h6>
      </div>
      <div className="row px-0 mx-0" style={{ width: "45rem" }}>
        <p className="productivity-parameter-subtext px-0">{text}</p>
      </div>
      <div className="row px-0 mx-0">
        <div className="col-8 px-0" style={{ width: "45rem" }}>
          <label
            htmlFor="comment"
            className="form-label productivity-parameter-subtext mx-1"
            style={{ fontWeight: "500" }}
          >
            Add Comment
          </label>
          <textarea
            disabled={submitted}
            defaultValue={comment}
            className="form-control mx-1"
            name={name.slice(0, name.indexOf("Rating"))}
            id="comment"
            cols="30"
            rows="4"
            onChange={handleInputChange}
          ></textarea>
          {comment?.length > 0 && !isValidString(comment) && (
            <span style={{ color: "red", fontSize: "12px" }}>
              Please Enter description only with letters, colon, comma and hyphen, between 100 and 750 characters
            </span>
          )}
        </div>
        <div
          className="col-4 px-0"
          style={{ marginLeft: "105px", width: "343px" }}
        >
          <div className="card rating-card">
            <div >
              <div className="row">
                <p className="rating-card-text">Rating</p>
              </div>
              <div className="row">
                <div className="circle-radio-container">
                  <CircleRadioButton
                    submitted={submitted}
                    value={1}
                    selected={rating == 1}
                    onClick={handleRadioButtonClick}
                  />
                  <CircleRadioButton
                    submitted={submitted}
                    value={2}
                    selected={rating === 2}
                    onClick={handleRadioButtonClick}
                  />
                  <CircleRadioButton
                    submitted={submitted}
                    value={3}
                    selected={rating === 3}
                    onClick={handleRadioButtonClick}
                  />
                  <CircleRadioButton
                    submitted={submitted}
                    value={4}
                    selected={rating === 4}
                    onClick={handleRadioButtonClick}
                  />
                  <CircleRadioButton
                    submitted={submitted}
                    value={5}
                    selected={rating === 5}
                    onClick={handleRadioButtonClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default InterPerformanceReviewSection;
