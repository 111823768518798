import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TopEnrolledCoursesSkeleton = () => {
  return (
    <div className="w-100 h-100">
      <div>
        <div className="d-flex justify-content-center w-100 top-enrolled-pie-height">
          <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5">
            <div>
              <Skeleton width={150} height={150} circle={true}/>
            </div>{" "}
          </SkeletonTheme>
        </div>
        <div className="top-enrolled-pie-val-wrapper">
          {[1, 2, 3, 4, 5].map((val, index) => (
            <div className="top-enrolled-pie-value" key={index}>
              <div className="top-enrolled-pie-value-child">
                <div className="top-enrolled-col-wrapper ms-1">
                  <Skeleton width={30} height={30} />
                </div>
                <div className="top-enrolled-val-text ms-3 mt-2">
                  <Skeleton width={80} />
                </div>
              </div>
              <div className="top-enrolled-val-text me-2">
                <Skeleton width={30} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopEnrolledCoursesSkeleton;
