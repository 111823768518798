import React, { useEffect, useState } from 'react'

const Player = (props) => {
    const { poster, onPlay, onEnd, onPause, onDocMarkCompleted, activityInfo = undefined, } = props;
    const [btn, setBtn] = useState(activityInfo.currentVideoSrc?.isCompleted);
    useEffect(() => {
        if (activityInfo.currentVideoSrc?.isCompleted) {
            setBtn(true)
        } else {
            setBtn(false)
        }
    }, [activityInfo.currentVideoSrc])

    return (
        <>
            {
                activityInfo.currentVideoSrc && activityInfo.currentVideoSrc?.path?.includes(".ppt") || activityInfo.currentVideoSrc?.path?.includes(".pdf") ?
                    <>
                        <iframe
                            src={activityInfo.currentVideoSrc.path}
                            className="iframe-player"
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                            allowTransparency="true"
                            aria-readonly="true"
                        ></iframe>

                        <button className="btn btn-primary iframe-comp" disabled={btn} onClick={(e) => {
                            e.preventDefault();
                            onDocMarkCompleted();
                            setBtn(true);
                        }}>{btn ? "Completed" : "Mark As Complete"}</button>
                    </>
                    :
                    <video
                        id="contentPlayer"
                        src={activityInfo.currentVideoSrc.path}
                        poster={poster}
                        controls={true}
                        onPause={(e) => {
                            e.preventDefault();
                            onPause();
                        }}
                        onReset={() => {
                            alert("Reset ");
                        }}
                        onPlay={(e) => {
                            e.preventDefault();
                            onPlay();
                            onPause();
                        }}
                        controlsList="nodownload noplaybackrate"
                        disablePictureInPicture={true}
                        onContextMenu={(e) => {
                            e.preventDefault(); // Prevent default right-click behavior
                        }}
                    >
                        Your browser does not support the video tag please update your
                        browser.
                    </video>
            }
        </>
    )
}

export default Player