import React from "react";
import { useState, useNavigate, CertificateImage, BadgeImage, CollapseIcon, ExpandIcon, EmptyEnrolledUsers, UserCourseDetails, TotalEnrolledUsersTableSkeleton } from "../../../AdminImport";

const TotalEnrolledUsersTable = (props) => {
  const { tableData, isLoading } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    resultsPerPage: 10,
  });

  const navigate = useNavigate();

  const totalPaginationPages = Math.ceil(tableData?.length / paginationState.resultsPerPage);
  const arrayStartIndex = (paginationState.currentPage - 1) * paginationState.resultsPerPage;
  const arrayEndIndex = arrayStartIndex + paginationState.resultsPerPage;
  const arrayCurrentResults = tableData?.slice(arrayStartIndex, arrayEndIndex);

  const handleRowClick = (index) => {
    const isSelected = selectedRows.includes(index);

    setSelectedRows((prevSelectedRows) => (isSelected ? prevSelectedRows.filter((row) => row !== index) : [...prevSelectedRows, index]));
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="total-enroll-user-table-head total-enroll-arrow-col"></th>
          <th className="total-enroll-user-table-head total-enroll-name-col">User Name</th>
          <th className="total-enroll-user-table-head text-center total-enroll-course-col">Courses Enrolled</th>
          <th className="total-enroll-user-table-head text-center total-enroll-milestone-col">Milestones</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <>
            <TotalEnrolledUsersTableSkeleton />
            <TotalEnrolledUsersTableSkeleton />
            <TotalEnrolledUsersTableSkeleton />
            <TotalEnrolledUsersTableSkeleton />
            <TotalEnrolledUsersTableSkeleton />
          </>
        ) : tableData?.length === 0 ? (
          <tr>
            <td colSpan={5} className="border-0" style={{ backgroundColor: "#fbfbfb" }}>
              <div className="d-flex align-items-center flex-column mb-3 empty-enrolled-margin">
                <EmptyEnrolledUsers />
                <p className="fw-bold mt-3 mb-2 empty-sde-text">No user has been enrolled yet!</p>
                <p className="empty-sde-fade-text">We’ll notify you when new user arrives.</p>
              </div>
            </td>
          </tr>
        ) : (
          arrayCurrentResults && arrayCurrentResults.map((data, index) => (
            <React.Fragment key={index}>
              <tr className="pointer" onClick={() => handleRowClick(index)}>
                <td className={`total-enroll-user-table-height total-enroll-arrow-col text-center ${selectedRows.includes(index) ? "border-bottom-0" : ""}`}>
                  {selectedRows.includes(index) ? <CollapseIcon /> : <ExpandIcon />}
                </td>
                <td className={`total-enroll-user-table-height total-enroll-name-col total-enroll-name-text ${selectedRows.includes(index) ? "border-bottom-0" : ""}`}>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      sessionStorage.setItem("uId", data.userId);
                      navigate("/admin/lms/course-report");
                    }}
                  >
                    {data?.firstName || ""} {data?.lastName || ""}
                  </span>
                </td>
                <td className={`total-enroll-user-table-height total-enroll-course-col total-enroll-course-text text-center ${selectedRows.includes(index) ? "border-bottom-0" : ""}`}>
                  {data?.enrolledCourses?.length?.toString().padStart(2, "0")}
                </td>
                <td className={`total-enroll-user-table-height total-enroll-milestone-col total-enroll-milestone-text ${selectedRows.includes(index) ? "border-bottom-0" : ""}`}>
                  <div className="d-flex align-items-center justify-content-center">
                    <CertificateImage className="me-2" />
                    {data?.certificateCount?.toString().padStart(2, "0")} Certificates
                    <BadgeImage className="ms-4 me-2" />
                    {data?.badgesCount?.toString().padStart(2, "0")} Badges
                  </div>
                </td>
              </tr>
              {selectedRows.includes(index) && (
                <tr>
                  <td colSpan="5" className="p-0 border-0">
                    <UserCourseDetails data={arrayCurrentResults[index]} isDetailed={false} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        )}
      </tbody>
      {tableData?.length !== 0 && (
        <tfoot>
          <tr>
            <td className="fw-bold total-enrolled-users-table-pagination" colSpan={2}>
              Total Items: {tableData?.length}
            </td>
            <td colSpan={6}>
              <div className="d-flex justify-content-end t-items">
                <div className="d-flex align-items-center">
                  <p className="me-2 mb-0 res-per-page">Results per page</p>
                  <select
                    className="form-select m-2 r-page"
                    value={paginationState.resultsPerPage}
                    onChange={(event) => {
                      setPaginationState((prevState) => ({
                        ...prevState,
                        resultsPerPage: event.target.value,
                        currentPage: 1,
                      }));
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                  <ul className="pagination mb-0">
                    <li className={`page-item me-1 ${paginationState.currentPage === 1 ? "page-item disabled" : ""}`}>
                      <button
                        className="page-link"
                        onClick={() => {
                          setPaginationState((prevState) => {
                            if (prevState.currentPage !== 1) {
                              return {
                                ...prevState,
                                currentPage: prevState.currentPage - 1,
                              };
                            }
                            return prevState;
                          });
                          window.scrollTo(0, 0);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1.5L1.5 7L7 12.5" stroke={paginationState.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                        </svg>
                      </button>
                    </li>
                    {Array.from({ length: totalPaginationPages }, (_, i) => i + 1)?.map((page) => (
                      <li key={page} className={`page-item me-1 ${page === paginationState.currentPage ? "active" : ""}`}>
                        <button
                          className="page-link rounded pagination-styling fw-bold"
                          onClick={() => {
                            setPaginationState((prevState) => ({
                              ...prevState,
                              currentPage: page,
                            }));
                            window.scrollTo(0, 0);
                          }}
                        >
                          {page}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${paginationState.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                      <button
                        className="page-link"
                        onClick={() => {
                          setPaginationState((prevState) => {
                            if (prevState.currentPage !== prevState.totalPaginationPages) {
                              return {
                                ...prevState,
                                currentPage: prevState.currentPage + 1,
                              };
                            }
                            return prevState;
                          });
                          window.scrollTo(0, 0);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 12.5L6.5 7L1 1.5" stroke={paginationState.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default TotalEnrolledUsersTable;
