import { useState, useEffect, axios, Link, Calender, TimeLogo, UpcomingSessionsCircle, EmptyMentorSession, decryptData, handleApiError, useNavigate, TrainingCalSkeleton,DefaultTechnology } from "../../MentorImport";
import "./TrainingCal.css";

const TrainingCalender = (props) => {
  const { selectedBatches } = props;

  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const formattedDate = dateString.split("T")[0];
    const [year, month, day] = formattedDate.split("-");
    const formatDate = `${month}-${day}-${year}`;

    return formatDate;
  };

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    const twelveHour = hours % 12 || 12;
    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${twelveHour}:${minutes} ${period}`;

    return formattedTime;
  }

  const calculateWorkingDaysAfter = (startDate, workingDays) => {
    const result = [];
    let currentDate = new Date(startDate);

    while (result.length < workingDays) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        result.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const fiveWorkingDaysAfter = calculateWorkingDaysAfter(today, 6);

  const filteredData = apiData?.filter((item) => {
    const sessionStartDate = new Date(item.sessionStartDate);
    sessionStartDate.setHours(0, 0, 0, 0);

    return fiveWorkingDaysAfter.some((workingDay) => sessionStartDate.getTime() === workingDay.getTime());
  });

  const fetchTrainingCal = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/SessionCalendar/getSessionDetails`;
    let body = {
      batchId: selectedBatches,
    };
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]} `,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        const sortedData = response.data.result.sort((a, b) => {
          if (a.sessionStartDate !== b.sessionStartDate) {
            return new Date(a.sessionStartDate) - new Date(b.sessionStartDate);
          }
          return new Date(a.sessionStartTime) - new Date(b.sessionStartTime);
        });
        setApiData(sortedData);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchTrainingCal();
  }, [selectedBatches]);

  return (
    <>
      <p className="about-link mb-2 mt-4">Upcoming Sessions</p>
      <div className="card cal-card training-cal-scroll">
        {isLoading ? (
          <>
            <TrainingCalSkeleton id={1} />
            <TrainingCalSkeleton id={2} />
            <TrainingCalSkeleton id={3} />
          </>
        ) : filteredData?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center flex-column h-100 p-4">
            <EmptyMentorSession />
            <p className="fw-bold mt-3 mb-1 empty-task-text">No sessions scheduled for today</p>
            <Link to={"/session-calendar"} className="task-link">
              Session Calendar
            </Link>
          </div>
        ) : (
          filteredData?.map((item, index) => {
            const daysDifference = Math.floor((new Date(item.sessionStartDate) - today) / (24 * 60 * 60 * 1000));
            let dayDisplay;
            if (daysDifference === 0) {
              dayDisplay = "Today";
            } else if (daysDifference === 1) {
              dayDisplay = "Tomorrow";
            } else if (daysDifference < 7) {
              dayDisplay = `On ${new Intl.DateTimeFormat("en-US", {
                weekday: "long",
              }).format(new Date(item.sessionStartDate))}`;
            } else {
              dayDisplay = "Next Week";
            }

            return (
              <div className="card cal-inner-card pt-0 pe-0" key={index}>
                <div className="col d-flex justify-content-between w-100">
                  <div className="pt-2 d-flex">
                  {item.techImage =="Default" ? <DefaultTechnology /> : <img key={item.sessionId} src={item.techImage} className="techImage" alt="" />}
                    <h5 className="inner-cal-head fw-bold">{item.sessionName}</h5>
                  </div>
                  <div className="d-flex p-0 justify-content-end">
                    {dayDisplay === "Today" && <div className="d-flex justify-content-center align-items-center training-today-session">{dayDisplay}</div>}
                    {dayDisplay === "Tomorrow" && <div className="d-flex justify-content-center align-items-center training-tomorrow-session">{dayDisplay}</div>}
                    {dayDisplay.startsWith("On") && <div className="d-flex justify-content-center align-items-center training-upcoming-session">{dayDisplay}</div>}
                    {dayDisplay === "Next Week" && <div className="d-flex justify-content-center align-items-center training-next-week-session">{dayDisplay}</div>}
                  </div>
                </div>
                <div className="col d-flex justify-content-between w-100">
                  <span className="session-margin-3">
                    <Calender />
                  </span>
                  <span className="cal-date">{formatDate(item.sessionStartDate)}</span>
                  <span className="session-margin-3">
                    <TimeLogo />
                  </span>
                  <span className="cal-time mx-2">{formatTime(item.sessionStartTime) + " - " + formatTime(item.sessionEndTime)}</span>
                </div>
                <div className="col d-flex w-100">
                  <div className="attended-batch">
                    Attended By:
                    <div className={`batch-list ${item?.batchDetails && item.batchDetails.length > 2 ? "show-ellipsis" : ""}`} data-bs-toggle="tooltip" data-bs-placement="top" title={item?.batchDetails ? item.batchDetails.map((batch) => batch.toString()).join(", ") : ""}>
                      {item?.batchDetails.slice(0, 2)?.map((batch, index) => (
                        <span key={index} className="cal-batch fw-bold">
                          {index !== item.batchDetails.length && <UpcomingSessionsCircle className="ms-2 me-2" />}
                          {batch}
                        </span>
                      ))}
                      {item.batchDetails.slice(2).length != 0 && <span className="batch-tooltip">&nbsp;&nbsp;...+ {item.batchDetails.slice(2).length}</span>}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
export default TrainingCalender;
