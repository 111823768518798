const AdminActionType = {
    TOGGLE_USERS_DROPDOWN: 'TOGGLE_USERS_DROPDOWN',
    TOGGLE_REVIEWER_DROPDOWN: 'TOGGLE_REVIEWER_DROPDOWN',
    SET_USERS: 'SET_USERS',
    SET_REVIEWERS: 'SET_REVIEWERS',
    SET_SELECT_ALL_USERS: 'SET_SELECT_ALL_USERS',
    SET_SELECTED_USER_IDS: 'SET_SELECTED_USER_IDS',
    SET_SELECTED_USERS: 'SET_SELECTED_USERS',
    SET_SELECT_ALL_CHECKED: 'SET_SELECT_ALL_CHECKED',
    SET_SELECTED_REVIEWER_IDS: 'SET_SELECTED_REVIEWER_IDS',
    SET_SELECTED_REVIEWERS: 'SET_SELECTED_REVIEWERS',
    SET_SEARCH_REVIEWER_QUERY: 'SET_SEARCH_REVIEWER_QUERY',
    SET_END_DATE: 'SET_END_DATE',
    SET_USERS_VALUE: 'SET_USERS_VALUE',
    SET_REVIEWER_VALUE: 'SET_REVIEWER_VALUE',
  };

  export default AdminActionType;