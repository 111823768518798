import { handleApiError, decryptData, Call, Detailreportskeleton, Mail, Profile, EmptyState, Download, Clock, DownloadPdf, Usercircle, Chevron, createContext, useEffect, useState, Link, useNavigate, axios, SkillsAchieved, SideNav, Header, ProjectList, PieChart, DailyUpdateTableSection, FormatDate } from "../../AdminImport";
import "./Detailedreport.css";

export const DetailedProvider = createContext();
const Detailedreport = () => {
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [data, setData] = useState([]);
  const [chartState, setChartState] = useState({
    isLoading: true,
    updateData: [],
    pieData: [],
  });

  const navigate = useNavigate();
  const chrumValue = sessionStorage.getItem("chrumValue");
  const idVal = parseInt(sessionStorage.getItem("detailId"));
  const fileName = (data?.userInformation?.firstName || "") + (data?.userInformation?.lastName || "") + "_" + (data?.userInformation?.email || "") + "_" + FormatDate(new Date());

  const fetchData = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Reports/getReportbyId?userId=${idVal}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setData(response.data.result);
        setChartState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const handleSkillsData = (skillsData) => {
    setChartState((prevState) => ({
      ...prevState,
      updateData: skillsData,
    }));
  };

  const handlePieData = (testpieData) => {
    setChartState((prevState) => ({
      ...prevState,
      pieData: testpieData,
    }));
  };

  function combineAndExtractDataWithCertificates() {
    var extractedUserProject = [];
    for (var i = 0; i < data?.userProject?.length; i++) {
      var originalProjectObject = data?.userProject[i];
      var extractedProjectObject = {
        ProjectName: originalProjectObject?.name,
        ProjectDescription: originalProjectObject?.description,
        ProjectLink: originalProjectObject?.projectLink,
        TechnologyUsed: originalProjectObject?.technologyUsed?.join(","),
        ProjectMembers: originalProjectObject?.members?.join(","),
      };
      extractedUserProject.push(extractedProjectObject);
    }

    var extractedUserSkills = [];
    for (var i = 0; i < data?.userSkills?.userSkills?.length; i++) {
      var originalSkillObject = data?.userSkills?.userSkills[i];
      var extractedSkillObject = {
        ExamName: originalSkillObject?.examName,
        ExamTakenDate: FormatDate(originalSkillObject?.examTakenDate),
        ExamTechName: originalSkillObject?.techName,
        ExamImageLink: originalSkillObject?.techImageLink,
        ExamScorePercentage: originalSkillObject?.examScorePercentage,
        ExamStatus: originalSkillObject?.hasPassedExam ? "Passed" : "Failed",
      };
      extractedUserSkills.push(extractedSkillObject);
    }

    var extractedProgressData = [];
    for (var i = 0; i < chartState?.updateData?.length; i++) {
      var originalProgressObject = chartState?.updateData[i];
      var extractedProgressDataObject = {
        TopicName: originalProgressObject?.topicName,
        StartDate: FormatDate(originalProgressObject?.startDate),
        LearningType: originalProgressObject?.LearningType,
        ProgressComments: originalProgressObject?.comment,
        ProgressDuration: originalProgressObject?.totalTime,
      };
      extractedProgressData.push(extractedProgressDataObject);
    }

    var extractedPieSkillData = [];
    var extractedPieSkillDataObject = Object.values(chartState?.pieData).every((value) => value === 0)
      ? []
      : {
          CGLearningVideo: chartState?.pieData["CG Learning Video"],
          Idle: chartState?.pieData.Idle,
          Project: chartState?.pieData.Project,
          SelfLearning: chartState?.pieData["Self Learning"],
          Session: chartState?.pieData.Session,
          TaskByMentor: chartState?.pieData["Task By Mentor"],
        };
    extractedPieSkillData.push(extractedPieSkillDataObject);

    var resultObject = {
      ProjectDetails: extractedUserProject,
      SkillDetails: extractedUserSkills,
      ProgressDetails: extractedProgressData,
      SkillPieDataDetails: extractedPieSkillData,
    };

    return resultObject;
  }
  const DownloadExcel = (base64Data) => {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}_SDE_Report.xlsx`;
    downloadLink.click();
  };

  const DownloadPDF = (base64Data) => {
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}_SDE_Report.pdf`;
    downloadLink.click();
  };

  var combinedDataWithCertificates = combineAndExtractDataWithCertificates();

  const GenerateReport = async (fileType) => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const uploadUrl = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/exportReport`;

    let formData = new FormData();
    formData.append("jsonString", JSON.stringify(combinedDataWithCertificates));
    fileType === "pdf" ? formData.append("fileType", "pdf") : formData.append("fileType", "xlsx");
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.post(uploadUrl, formData, { headers });

      if (response.status === 200) {
        fileType === "pdf" ? DownloadPDF(response.data.result) : DownloadExcel(response.data.result);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alert(`Data not Uploaded: \n${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DetailedProvider.Provider
        value={{
          data,
        }}
      >
        <div style={{ marginBottom: "5rem" }}>
          <Header selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} />
          <SideNav />
        </div>
        <div className="detailedrep-parent-wrapper">
          <div className="detailedrep-child-wrapper">
            <div className="detailrep-header">
              <div className="detailrep-breadcrum">
                <Link to={chrumValue === "Report" ? "/admin/sde/report" : "/admin/sde/dashboard"} className="crumb-parent">
                  {chrumValue}
                </Link>
                <Chevron />
                <span>User Information</span>
              </div>
              {data && (
                <div className="report-download">
                  <span>Download</span>
                  <div
                    onClick={() => {
                      GenerateReport("xlsx");
                    }}
                  >
                    <Download />
                  </div>
                  <div
                    onClick={() => {
                      GenerateReport("pdf");
                    }}
                  >
                    <DownloadPdf />
                  </div>
                </div>
              )}
            </div>
            <div className="detail-info-wrapper">
              {chartState?.isLoading ? (
                <Detailreportskeleton />
              ) : data ? (
                <>
                  <div className="detailrep-user-info">
                    <div className="use-info">
                      <span>User Information</span>
                    </div>
                    <div className="info-detail">
                      <div className="user-name">
                        <Usercircle />
                        <span className="ms-2">{`${data?.userInformation?.firstName} ${data?.userInformation?.lastName != null ? data?.userInformation?.lastName : ""}`}</span>
                      </div>
                      <div className="other-info">
                        <div className="icon-pair">
                          <Profile />
                          <span>{data?.userInformation?.cgEmloyeeId}</span>
                        </div>
                        <div className="det-dot" />
                        <div className="icon-pair">
                          <Clock />
                          <span>{`${data?.userInformation?.durationOfInternship} months`}</span>
                        </div>
                        <div className="other-Info-hide-for-mobile" id="Other-Info-Hide">
                          <div className="det-dot" />
                          <div className="icon-pair">
                            <Mail />
                            <span>{data?.userInformation?.email}</span>
                          </div>
                          <div className="det-dot" />
                          <div className="icon-pair">
                            <Call />
                            <span>{data?.userInformation?.contactNumber}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailrep-second-child">
                    <div className=" detail-child spent-hours">
                      <span>Most Spent Hours</span>
                      <div className="most-skill-hr">
                        <PieChart handlePieData={handlePieData} />
                      </div>
                    </div>
                    <div className="detail-child">
                      <span>Skills Achieved</span>
                      <div className="skills-achieved">
                        <SkillsAchieved />
                      </div>
                    </div>
                    <div className="detail-child" id="third-sub-child-of-second">
                      <span>Project Details</span>
                      <div className="project-details">
                        <ProjectList />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center flex-column height-70">
                    <EmptyState />
                    <p className="pcNoData">No Data Found!!</p>
                  </div>
                </>
              )}
              <div className="detailrep-table">
                <div className="progress-div">
                  <span>Progress Report</span>
                </div>
                <DailyUpdateTableSection userId={idVal} handleSkillsData={handleSkillsData} />
              </div>
            </div>
          </div>
        </div>
      </DetailedProvider.Provider>
    </>
  );
};

export default Detailedreport;
