import { FormatDate, AdditionalInfoInstructorIcon, AdditionalInfoAutoIcon, UserCourseDetailsSkeleton, ProgressBar, EmptyCourseUpload } from "../../../UploadCourses/UploadCoursesImport";
import "./TotalEnrolledUsers.css";

const UserCourseDetails = (props) => {
  const { data, isDetailed, isCourseReportLoading } = props;

  return (
    <div className="user-course-details-scrollable-table">
      <table className="table">
        <thead>
          <tr>
            {isDetailed === false && (
              <>
                <th className="user-course-details-empty-col border-0"></th>
              </>
            )}
            <th className="user-course-details-title-col border-0">Course Title</th>
            {isDetailed !== false && (
              <>
                <th className="user-course-details-cat-col border-0">Category</th>
                <th className="user-course-details-subcat-col border-0">Sub-Category</th>
              </>
            )}
            <th className="user-course-details-progress-col border-0">Course Progress</th>
            <th className="user-course-details-start-col text-center border-0">Start Date</th>
            <th className="user-course-details-end-col border-0 text-center">End Date</th>
            <th className="user-course-details-duration-col border-0 text-center">Duration</th>
            <th className="user-course-details-instructor-col border-0">Instructor</th>
            <th className="user-course-details-grade-col border-0">Course Graded</th>
          </tr>
        </thead>
        <tbody>
          {isCourseReportLoading ? (
            <>
              <UserCourseDetailsSkeleton />
              <UserCourseDetailsSkeleton />
              <UserCourseDetailsSkeleton />
            </>
          ) : (data?.enrolledCourses && data?.enrolledCourses?.length === 0) || data == undefined ? (
            <td colSpan="12" className="text-center">
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <EmptyCourseUpload />
              <p className="fw-bold mt-3 mb-1 empty-insights-text">No enrolled courses currently!</p>
              <p className="empty-insights-fade-text mt-1">We’ll notify you when something arrives.</p>
            </div>
             </td>
          ) : (
            data?.enrolledCourses?.map((course, index) => (
              <tr key={index}>
                {isDetailed === false && (
                  <>
                    <td
                      className={`user-course-details-empty-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                        index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                      }`}
                    ></td>
                  </>
                )}
                <td
                  className={`user-course-details-title-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`} data-toggle="tooltip" data-placement="top" title={course?.courseTitle}
                >
                  {course?.courseTitle.length > 50 ? `${course?.courseTitle.substring(0, 40)}...` : course?.courseTitle}
                </td>
                {isDetailed !== false && (
                  <>
                    <td
                      className={`user-course-details-cat-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                        index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                      }`}
                    >
                      {course?.categoryName}
                    </td>
                    <td
                      className={`user-course-details-subcat-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                        index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                      }`}
                    >
                      {course?.subCategoryName}
                    </td>
                  </>
                )}
                <td
                  className={`user-course-details-progress-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  <ProgressBar completedCount={course?.completedModuleCount} totalCount={course?.moduleCount} courseStatus={course?.courseStatus} />
                </td>
                <td
                  className={`user-course-details-start-col-data text-center ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  {FormatDate(course?.startDate)}
                </td>
                <td
                  className={`user-course-details-end-col-data text-center ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  {FormatDate(course?.endDate)}
                </td>
                <td
                  className={`user-course-details-duration-col-data text-center ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  {course?.duration?.replace(/(\d+) days (\d+) hrs (\d+) mins/, (_, d, h, m) => `${Number(d) * 24 + Number(h)} hrs ${m} mins`)}
                </td>
                <td
                  className={`user-course-details-instructor-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  {course?.instructorName}
                </td>
                <td
                  className={`user-course-details-grade-col-data ${index + 1 === data.enrolledCourses.length ? "" : "border-bottom-0"} ${
                    index % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"
                  }`}
                >
                  {course?.gradeType !== "Not Selected" && (
                    <div className={`badge user-course-details-instructor-grade-badge ${course?.gradeType === "Auto-grade" ? "total-uploaded-courses-instructor-auto-grade" : ""}`}>
                      {course?.gradeType === "Instructor Grade" ? <AdditionalInfoInstructorIcon /> : course?.gradeType === "Auto-grade" ? <AdditionalInfoAutoIcon /> : null}
                      <span className="align-middle ms-1">{course?.gradeType === "Instructor Grade" ? "Instructor" : "Auto"}</span>
                    </div>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserCourseDetails;
