import { useState, useEffect, Papa } from "../UploadCoursesImport";

const CSVPreview = ({ file }) => {
  const [csvData, setCSVData] = useState([]);

  useEffect(() => {
    const parseCSV = () => {
      Papa.parse(file, {
        complete: (result) => {
          setCSVData(result.data);
        },
      });
    };

    parseCSV(); 
  }, [file]);

  return (
    <div className="csv-preview">
      <h3>{file.name}</h3>
      <table>
        <thead>
          <tr className="grade-assign-column-id daily-update-table-head">
            {csvData?.length > 0 &&
              csvData[0]?.map((header, index) => (
                <th className="py-1 px-3" key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {csvData?.slice(1).map((row, rowIndex) => (
            <tr className="grade-assign-table-row" key={rowIndex}>
              {row?.map((cell, cellIndex) => (
                <td className="py-1 px-3" key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CSVPreview;
