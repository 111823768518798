import { useState, useEffect, useNavigate, EmptyMentorList, CustomModal,DownArrow, decryptData, handleApiError, UpArrow, Add, Edit, PermissionsCheck, AdminMentorListSkeleton, EditMentorModal, AddMentorModal, axios } from "../../AdminImport";
import "./AdminMentorList.css";

const MentorList = () => {
  const [mentorData, setMentorData] = useState({
    mentor: [],
    userList: [],
    expandMenotr: 0,
    isLoading: true,
  });
  const [editMentor, setEditMentor] = useState({
    editModal: false,
    selectedMentor: [],
  });
  const [modalData, setModalData] = useState({
    modalShow : false,
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const navigate = useNavigate();

  const fetchMentorList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/mentorDetails`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        const sortedUsers = response.data.result.sort((a, b) => {
          const nameA = a.firstName.toUpperCase();
          const nameB = b.firstName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setMentorData((prevState) => ({
          ...prevState,
          mentor: sortedUsers,
          isLoading: false
        }));
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleClickClear = () => {
    setModalData(() => ({
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };
  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAll`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          designation: user?.designation,
          roleName: user.roleName
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });
        const userList = sortedUsers
          .filter(list => list.designation !== "SDE" && list.designation !== "Intern" && list.roleName.toLowerCase() !== "admin" && list.roleName.toLowerCase() !== "mentor")
          .map(list => {
            if (list.designation != "SDE" && list.designation != "Intern")
              return ({ label: list.name, value: list.id });
          });
        setMentorData((prevState) => ({
          ...prevState,
          userList: userList
        }));
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  }
  useEffect(() => {
    fetchMentorList();
  }, []);

  const handleExpand = (mentorId) => {
    if (mentorData.expandMenotr === mentorId) {
      setMentorData((prevState) => ({
        ...prevState,
        expandMenotr: 0,
      }));
    } else {
      setMentorData((prevState) => ({
        ...prevState,
        expandMenotr: mentorId,
      }));
    }
  };

  return (
    <>
      <div
        className="mentor-list-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>Mentor List</p>
        <button
          type="button"
          className="add-mentor-button"
          onClick={() => {
            setModalData((prevState) => ({
              ...prevState,
              modalShow : true
            }));
          }}
        >
          <Add />
          Assign Role
        </button>
      </div>

      {mentorData.isLoading ? (
        <div className="card mentor-card">
          <div className="card-body p-0">
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
            <AdminMentorListSkeleton />
          </div>
        </div>
      ) : mentorData.mentor && mentorData.mentor.length === 0 ? (
        <div className="card empty-task-state d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center flex-column h-100">
            <EmptyMentorList />
            <p className="fw-bold mt-3 mb-1 empty-task-text">No mentors assigned yet.</p>
            <p
              className="task-link"
              onClick={() => {
                setModalData((prevState) => ({
                  ...prevState,
                  modalShow : true
                }));
              }}
            >
              Assign Mentor
            </p>
          </div>
        </div>
      ) : (
        <div className="card mentor-card dp-scroll">
          <div className="card-body p-0">
            {mentorData.mentor && mentorData.mentor.map((user) => {
              const commaSeparatedPermissions = user.userPermission;
              const permArray = commaSeparatedPermissions ? commaSeparatedPermissions.split(",") : ["No Permissions"];
              if (permArray[0] === "Inactive") {
                permArray.length = 0;
              }
              return (
                <>
                  <div key={user.id} className="card mentor-head">
                    <div onClick={() => handleExpand(user.id)} className="mentor-wrapper">
                      <div
                        className="image-wrapper1"
                      >
                        <div className="cert-image-box1">
                          {user.imageUrl && user.imageUrl.includes("https://") ? (
                            <img key={user.id} src={user.imageUrl} width={38} alt="" />
                          ) : (
                            <div className="d-flex justify-content-center noMentor-img">
                              <span className="initials">
                                {user.firstName
                                  .split(" ")
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join("")}
                                {user.lastName
                                  .split(" ")
                                  .map((name) => name.charAt(0).toUpperCase())
                                  .join("")}
                              </span>
                            </div>
                          )}
                          {user?.userPermission == null || user.userPermission == "Inactive" ? (
                            <>
                              <span className="aml-permission grey">INACTIVE</span>
                            </>
                          ) : (
                            <>
                              <span className="aml-permission teal">ACTIVE</span>

                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="text-wrapper1"
                      >
                        <p key={user.id} className="m-0">
                          <b>
                            {user.firstName} {user.lastName}
                          </b>
                        </p>

                        <p
                          className="m-0 pos-wrapper"
                        >
                          {user.designation}{" "}
                        </p>
                      </div>
                      <div className="arrow-wrapper1">
                        <Edit
                          className="assign-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMentor((prevState) => ({
                              ...prevState,
                              editModal: true,
                              selectedMentor: mentorData.mentor.filter((men) => {
                                return men.id === user.id;
                              }),
                            }));
                          }}
                        />
                        <span className="expand-arrow">{mentorData.expandMenotr === user.id ? <UpArrow /> : <DownArrow />}</span>
                      </div>
                    </div>
                    {mentorData.expandMenotr === user.id && (
                      <div className="row mt-2">
                        <div className="technology">
                          {permArray.length === 0 ? (
                            ""
                          ) : (
                            <div>
                              <p>Permissions:</p>
                              {permArray.map((perm, permIndex) => (
                                <span key={permIndex} className="badge perm-badge">
                                  <PermissionsCheck style={{ marginBottom: "2px" }} /> {perm}
                                </span>
                              ))}
                            </div>
                          )}
                          <p className="tech mt-2">Skilled In:</p>
                          {user.skills.map((skill, skillIndex) => (
                            <span key={skillIndex} className="badge tech-badge">
                              {skill ? skill.toUpperCase() : ""}{" "}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
      <AddMentorModal
        setModalData={setModalData}
        show={modalData.modalShow}
        onHide={() => {
          setModalData((prevState) => ({
            ...prevState,
            modalShow : false
          }));
        }}
        userList={mentorData.userList}
        fetchMentorList={fetchMentorList}
        fetchUserList={fetchUserList}
      />

      {editMentor.selectedMentor && editMentor.editModal && (
        <EditMentorModal
          selectedMentor={editMentor.selectedMentor[0]}
          fetchMentorList={fetchMentorList}
          fetchUserList={fetchUserList}
          show={editMentor.editModal}
          onHide={() => {
            setEditMentor((prevState) => ({
              ...prevState,
              editModal: false,
            }));
          }}
        />
      )}
      {modalData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: modalData.icon,
            show: modalData.isModalOpen,
            type: modalData.modalType,
            title: modalData.modalTitle,
            message: modalData.modalMessage,
            onCancel: handleClickClear,
          }}
        />
      )}
    </>
  );
};

export default MentorList;
