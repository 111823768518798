import { useState, Video, AdditionalInfoEbookIcon, PptIcon, ReactModal, Form } from "../UploadCoursesImport";
import "./TotalUploadedCourses.css";

const CourseContent = (props) => {
  const { row, tableState, truncate } = props;
  const [isReadMoreModal, setIsReadMoreModal] = useState({
    showModal: false,
    selectedItems: null
  });

  const handleReadMore = (item) => {
    setIsReadMoreModal((prevState) => ({
      ...prevState,
      selectedItems: item,
      showModal: true
    }));
  };

  const getExtensionIcon = (content) => {
    const extension = content?.split(".").pop().toLowerCase();

    if (extension === "mp4") {
      return <Video className="additional-info-video-icon" />;
    } else if (extension === "pdf") {
      return <AdditionalInfoEbookIcon className="additional-info-pdf-icon" />;
    }
    else if (extension === "ppt" || extension === "pptx") {
      return <PptIcon className="additional-info-ppt-icon" />;
    }
    return null;
  };

  return (
    <div className="tr accordion accordion-flush total-uploaded-courses-module-accordion" id={`accordion${row.id}`}>
      <div className="accordion-item p-0">
        <h2 className="accordion-header" id={`headingModule${row.id}`}>
          <button
            className="accordion-button module-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseModule${row.id}`}
            aria-expanded="false"
            aria-controls={`collapseModule${row.id}`}
          >
            Module ({tableState?.expandedRowData[row.id]?.modules === null || tableState?.expandedRowData[row.id]?.modules.length === 0 ? 0 : tableState?.expandedRowData[row.id]?.modules?.length})
          </button>
        </h2>
        <div id={`collapseModule${row.id}`} className="accordion-collapse collapse" data-bs-parent={`accordion${row.id}`} aria-labelledby={`headingModule${row.id}`}>
          {tableState.expandedRowData[row.id].modules
            ?.sort((a, b) => a.orderNumber - b.orderNumber) // Sorting modules based on order number
            .map((moduleData) => {
              const quizModules = moduleData.moduleContent.filter((moduleItem) => moduleItem.isQuiz);
              return (
                <div className="accordion-body pe-0 pb-0 total-uploaded-courses-module-table">
                  <div>
                    <h6 className="module-topic-heading">
                      {moduleData?.name} ({moduleData?.moduleContent?.length})
                    </h6>
                  </div>
                  <table className="table table-borderless mt-3 mb-0">
                    <thead>
                      <tr className="module-topic-table">
                        <th className="module-topic-table-head total-uploaded-courses-module-content-width total-uploaded-courses-padding">Content</th>
                        <th className="module-topic-table-head total-uploaded-courses-padding">File Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {moduleData && moduleData.moduleContent?.sort((a, b) => a.orderNumber - b.orderNumber).map((item, index) => {
                        if (!item.isQuiz) {
                          return (
                            <tr key={index}>
                              <td className={index % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}><span className={item.name.length > 50 ? "truncate-text" : ""} title={item.name}>
                                {item.name.length > 50 ? `${item.name.substring(0, 50)}...` : item.name}
                              </span></td>
                              <td className={index % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>
                                {getExtensionIcon(item?.name)}
                              </td>{" "}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                  {quizModules.length > 0 && (
                    <div className="accordion-item p-0 border-top">
                      <h2 className="accordion-header" id={`headingQuiz${row.id}`}>
                        <button
                          className="accordion-button quiz-module-accordion module-accordion collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseQuiz${row.id}`}
                          aria-expanded="false"
                          aria-controls={`collapseQuiz${row.id}`}
                        >
                          Quiz ({quizModules.length})
                        </button>
                      </h2>
                      <div id={`collapseQuiz${row.id}`} className="accordion-collapse collapse" data-bs-parent={`accordion${row.id}`} aria-labelledby={`headingQuiz${row.id}`}>
                        <div className="accordion-body p-0">
                          <table className="table table-borderless mb-0">
                            <thead>
                              <tr className="module-topic-table">
                                <th className="module-topic-table-head total-uploaded-courses-module-content-width total-uploaded-courses-padding">Quiz</th>
                                <th className="module-topic-table-head total-uploaded-courses-padding">No. of Question</th>
                              </tr>
                            </thead>
                            <tbody>
                              {quizModules.map((moduleItem, index) => (
                                <tr key={index}>
                                  <td className={index % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>{moduleItem.name}</td>
                                  <td className={index % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>{moduleItem.numberOfQuestions}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="accordion-item p-0">
        <h2 className="accordion-header" id={`headingAssignment${row.id}`}>
          <button
            className="accordion-button module-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapseAssignment${row.id}`}
            aria-expanded="false"
            aria-controls={`collapseAssignment${row.id}`}
          >
            Assignment (
            {tableState.expandedRowData[row.id]?.assignments === null || tableState.expandedRowData[row.id]?.assignments.length === 0
              ? 0
              : tableState.expandedRowData[row.id]?.assignments?.reduce((count, item) => count + item.assignmentAttachment.length, 0)}
            )
          </button>
        </h2>
        <div id={`collapseAssignment${row.id}`} className="accordion-collapse collapse" data-bs-parent={`accordion${row.id}`} aria-labelledby={`headingAssignment${row.id}`}>
          <div className="accordion-body p-0 bg-white">
            <table className="table table-borderless mb-0">
              <thead>
                <tr className="module-topic-table">
                  <th className="module-topic-table-head total-uploaded-courses-padding total-uploaded-courses-module-quiz-width">Assignment</th>
                  <th className="module-topic-table-head total-uploaded-courses-module-quiz-question-width total-uploaded-courses-padding">Description</th>
                  <th className="module-topic-table-head total-uploaded-courses-assignment-attachment total-uploaded-courses-padding">Attachment</th>
                  <th className="module-topic-table-head total-uploaded-courses-padding">Duration</th>
                </tr>
              </thead>
              <tbody>
                {tableState.expandedRowData[row.id]?.assignments?.map((item, id) => (
                  <>
                    {item.assignmentAttachment.map((attachment, attachmentId) => (
                      <tr key={`${id}-${attachmentId}`} className={(id + attachmentId) % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>
                        <td className={(id + attachmentId) % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>
                          {tableState.expandedRowIndex === `${id}-${attachmentId}` ? (
                            item.title
                          ) : (
                            <>
                              {truncate(item.title, 55)}
                              {item.title.length > 55 && (
                                <span
                                  className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer"
                                  onClick={() => {
                                    handleReadMore(item);
                                  }}
                                >
                                  Read more
                                </span>
                              )}
                            </>
                          )}
                        </td>
                        <td className={(id + attachmentId) % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>
                          {tableState.expandedRowIndex === `${id}-${attachmentId}` ? (
                            item.description
                          ) : (
                            <>
                              {truncate(item.description, 55)}
                              {item.description.length > 55 && (
                                <span
                                  className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer"
                                  onClick={() => {
                                    handleReadMore(item);
                                  }}
                                >
                                  Read more
                                </span>
                              )}
                            </>
                          )}
                        </td>
                        <td className={(id + attachmentId) % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>{attachment.name}</td>
                        <td className={(id + attachmentId) % 2 !== 0 ? "total-uploaded-courses-module-content-odd-row" : "total-uploaded-courses-module-content-even-row"}>{item.completionTime}</td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
              <ReactModal size="lg" dialogClassName="modal-90w" centered show={isReadMoreModal.showModal}
                onHide={() => setIsReadMoreModal((prevState) => ({
                  ...prevState,
                  showModal: false
                }))}>
                <ReactModal.Header
                  closeButton
                  style={{
                    borderBottom: "0.063rem",
                  }}
                >
                  <ReactModal.Title className="fw-bold" style={{ fontSize: "1.1rem" }}>Assignment Info</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                  <p>
                    <span className="fw-bold">Title :</span> <span className="opacity-75">{isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.title}</span>
                  </p>

                  <Form.Group>
                    <Form.Label>
                      <span className="fw-bold">Description : </span>
                    </Form.Label>
                    <Form.Control className="opacity-75" disabled as="textarea" style={{ fontSize: "0.813rem" }} defaultValue={isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.description} rows={4} cols={60}></Form.Control>
                  </Form.Group>
                </ReactModal.Body>
              </ReactModal>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
